import { z } from 'zod'

import {
  amountOfTimeSchema,
  nullableNumber,
  rangeObjectSchema,
} from '../utils/schema'

type DayMonthYear = z.infer<typeof dmySchema>
type FiltersForm = z.infer<typeof filtersFormSchema>
type ServerFilters = z.infer<typeof serverFiltersSchema>

const dmySchema = z.object({
  days: z.number().optional(),
  months: z.number().optional(),
  years: z.number().optional(),
})

const gtltRangeSchema = rangeObjectSchema.pick({ gt: true, lt: true }).partial()

const filtersFormSchema = z
  .object({
    admin: z.boolean(),
    subscribersOnly: z.boolean(),

    hasVerifiedCell: z.boolean(),
    hasPhoneNumber: z.boolean(),
    hasEmail: z.boolean(),
    hasAddress: z.boolean(),
    firstName: z.string(),
    lastName: z.string(),
    selectedClients: z.array(
      z.object({ objectId: z.string(), displayName: z.string() })
    ),
    phoneNumber: z.string(),
    saleBefore: z.string(),
    saleAfter: z.string(),
    firstSaleStart: z.string(),
    firstSaleEnd: z.string(),
    lastContactNever: z.boolean(),
    lastContactBefore: z.string(),
    lastContactAfter: z.string(),
    birthdayMonth: nullableNumber,
    anniversaryMonth: nullableNumber,
    selectedBrands: z.array(z.string()),
    selectedColors: z.array(z.string()),
    selectedMaterials: z.array(z.string()),
    selectedPosClasses: z.array(z.string()),
    selectedSizes: z.array(z.string()),
    selectedPosStyles: z.array(z.string()),
    selectedDescriptions: z.array(z.string()),
    selectedPosGenders: z.array(z.string()),
    selectedPosSellerNames: z.array(z.string()),
    selectedSaleGroups: z.array(z.string()),
    selectedClientTags: z.array(z.string()),
    selectedAssociates: z.array(z.string()),
    selectedGroups: z.array(z.string()),
    totalSalesMin: z.string(),
    totalSalesMax: z.string(),
    notShoppedIn: amountOfTimeSchema.partial(),
    notContacted: amountOfTimeSchema.partial(),
    loyaltyPointsActive: gtltRangeSchema,
    loyaltyPointsLifetime: gtltRangeSchema,
    loyaltyVisitsActive: gtltRangeSchema,
    loyaltyRewardsPosActive: gtltRangeSchema,
    loyaltyVisitsLifetime: gtltRangeSchema,
    saleCount: gtltRangeSchema,
    saleIn: dmySchema.optional(),
    saleFirstIn: dmySchema.optional(),
  })
  .partial()

const serverFiltersSchema = z
  .object({
    admin: z.boolean(),
    subscribersOnly: z.boolean(),

    verifiedMobile: z.boolean(),
    phoneNumberExists: z.boolean(),
    emailExists: z.boolean(),
    addressExists: z.boolean(),

    firstName: z.string(),
    lastName: z.string(),
    phoneNumber: z.string(),
    objectIds: z.array(z.string()),

    saleBefore: z.string(),
    saleAfter: z.string(),
    firstSaleAfter: z.string(),
    firstSaleBefore: z.string(),
    lastContactAfter: z.string(),
    lastContactBefore: z.string(),
    lastContactNever: z.boolean(),
    birthday: z.object({ month: z.number() }),
    anniversary: z.object({ month: z.number() }),

    brand: z.array(z.string()),
    colors: z.array(z.string()),
    materials: z.array(z.string()),
    posClasses: z.array(z.string()),
    size: z.array(z.string()),
    posStyles: z.array(z.string()),
    descriptions: z.array(z.string()),
    posGenders: z.array(z.string()),
    posSellerName: z.array(z.string()),
    saleGroups: z.array(
      z.object({
        __type: z.string(), //z.literal('Pointer'),
        className: z.string(), //z.literal('Group'),
        objectId: z.string(),
      })
    ),
    clientTagIds: z.array(z.string()),
    users: z.array(
      z.object({
        __type: z.string(), //z.literal('Pointer'),
        className: z.string(), //z.literal('_User'),
        objectId: z.string(),
      })
    ),
    unassigned: z.boolean(),

    groups: z.array(
      z.object({
        __type: z.string(), // z.literal('Pointer'),
        className: z.string(), // z.literal('Group'),
        objectId: z.string(),
      })
    ),
    loyaltyPointsActive: gtltRangeSchema,
    loyaltyPointsLifetime: gtltRangeSchema,
    loyaltyVisitsActive: gtltRangeSchema,
    loyaltyRewardsPosActive: gtltRangeSchema,
    loyaltyVisitsLifetime: gtltRangeSchema,

    salesTotalMin: z.string(),
    salesTotalMax: z.string(),

    haveNotShopped: amountOfTimeSchema.partial(),
    notContacted: amountOfTimeSchema.partial(),

    saleCount: gtltRangeSchema,
    saleIn: dmySchema.optional(),
    saleFirstIn: dmySchema.optional(),
  })
  .partial()

export type { DayMonthYear, FiltersForm, ServerFilters }
export { filtersFormSchema, serverFiltersSchema }

export const unlayerTemplates = [
  {
    id: 489281,
    tags: ['Promotions'],
    url: 'https://assets.unlayer.com/projects/97081/_generated/image/1711123031635-VYKwB3GuS2JJKFYc.png',
  },
  {
    id: 489289,
    tags: ['Promotions'],
    url: 'https://assets.unlayer.com/projects/97081/_generated/image/1711123031854-Bthw24esaRPU1gVM.png',
  },
  {
    id: 489288,
    tags: ['Promotions'],
    url: 'https://assets.unlayer.com/projects/97081/_generated/image/1711123032118-KDcOeb1ZFD4dREmZ.png',
  },
  {
    id: 420765,
    tags: ['Promotions'],
    url: 'https://assets.unlayer.com/projects/97081/_generated/image/1711123033129-DfnyMqEE9Ah82URC.png',
  },
  {
    id: 489033,
    tags: ['Welcome!'],
    url: 'https://assets.unlayer.com/projects/97081/_generated/image/1711123031378-h18BqgPeXvVDnEpg.png',
  },
  {
    id: 489270,
    tags: ['Win Back'],
    url: 'https://assets.unlayer.com/projects/97081/_generated/image/1711123031596-6Cud5tPHTBwPoMsR.png',
  },
  {
    id: 489271,
    tags: ['Win Back'],
    url: 'https://assets.unlayer.com/projects/97081/_generated/image/1711123031538-hD5U7XSXfssYrFJj.png',
  },
  {
    id: 488449,
    tags: ['Loyalty'],
    url: 'https://assets.unlayer.com/projects/97081/_generated/image/1711123031554-kpywGvVkJsi6FHvl.png',
  },
  {
    id: 489269,
    tags: ['Loyalty'],
    url: 'https://assets.unlayer.com/projects/97081/_generated/image/1711123031632-LWITyu5I9q0eHrQM.png',
  },
  {
    id: 488470,
    tags: ['Loyalty'],
    url: 'https://assets.unlayer.com/projects/97081/_generated/image/1711123031602-fUeHk0X2TrqE8AoK.png',
  },
  {
    id: 489251,
    tags: ['Events'],
    url: 'https://assets.unlayer.com/projects/97081/_generated/image/1711123033158-v2U7TqwU7WryCLlG.png',
  },
  {
    id: 489247,
    tags: ['Events'],
    url: 'https://assets.unlayer.com/projects/97081/_generated/image/1711123034925-IEpWWk1bUdesLQyl.png',
  },
  {
    id: 489284,
    tags: ['Products'],
    url: 'https://assets.unlayer.com/projects/97081/_generated/image/1711123032981-XSt6XYo10henn2Jy.png',
  },
  {
    id: 489286,
    tags: ['Products'],
    url: 'https://assets.unlayer.com/projects/97081/_generated/image/1711123031565-vsrDNzGER7EENwL8.png',
  },
  {
    id: 489292,
    tags: ['Products'],
    url: 'https://assets.unlayer.com/projects/97081/_generated/image/1711123031790-s8CRgpErXogREfKj.png',
  },
  {
    id: 489278,
    tags: ['Products'],
    url: 'https://assets.unlayer.com/projects/97081/_generated/image/1711123032304-KhqTfx4hMkhJ35dS.png',
  },
  {
    id: 489028,
    tags: ['Products'],
    url: 'https://assets.unlayer.com/projects/97081/_generated/image/1711123032101-lFWh7E7MUjtT1g1t.png',
  },
  {
    id: 489019,
    tags: ['Birthday'],
    url: 'https://assets.unlayer.com/projects/97081/_generated/image/1711123031341-SQdWSfh3VPJz5fbY.png',
  },
  {
    id: 489017,
    tags: ['Birthday'],
    url: 'https://assets.unlayer.com/projects/97081/_generated/image/1711123032731-PFiG7oRPur4Lljdv.png',
  },
]

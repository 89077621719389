import { useInfiniteQuery, useQuery } from '@tanstack/react-query'

import { client } from '../../../services/client'

import type { QueryContextFromKeys } from '../../../utils/react-query'
import type { LookBase } from '../../../types/shopwith'
import type { PaginatedResponse } from '../../../types/queries'
import type { ProfileBase } from '../../../types/general'

type FetchProfileLooksResponse = PaginatedResponse<ProfileLook>
type FetchProfileLookResponse = {
  result: ProfileLook
}

export type ProfileLook = {
  objectId: string
  createdAt: string
  description: string | null
  look: LookBase
  user: ProfileBase
}

export type ProfileLookQueryContexts = QueryContextFromKeys<
  typeof profileLookQueryKeys
>

export const profileLookQueryKeys = {
  all: [{ entity: 'profileLooks' }] as const,
  lists: () => [{ ...profileLookQueryKeys.all[0], scope: 'list' }] as const,
  list: (profileId: string) =>
    [{ ...profileLookQueryKeys.lists()[0], profileId }] as const,
  details: () => [{ ...profileLookQueryKeys.all[0], scope: 'detail' }] as const,
  detail: (id: string) =>
    [{ ...profileLookQueryKeys.details()[0], id }] as const,
}

async function fetchProfileLooks({
  pageParam = 0,
  queryKey: [{ profileId }],
}: ProfileLookQueryContexts['list']) {
  const response: FetchProfileLooksResponse = await client
    .post('functions/getProfileLooks', {
      json: {
        profileId,
        page: pageParam,
        pageSize: 20,
      },
    })
    .json()

  return response.result
}

async function fetchProfileLook({
  queryKey: [{ id }],
}: ProfileLookQueryContexts['detail']) {
  const response: FetchProfileLookResponse = await client
    .post(`functions/getProfileLook/`, {
      json: {
        objectId: id,
      },
    })
    .json()

  return response.result
}

function useProfileLooks(profileId: string) {
  return useInfiniteQuery({
    queryKey: profileLookQueryKeys.list(profileId),
    queryFn: fetchProfileLooks,
    getNextPageParam: (lastPage) =>
      lastPage.hasNextPage ? lastPage.page + 1 : undefined,
  })
}

function useProfileLook(id: string) {
  return useQuery({
    queryKey: profileLookQueryKeys.detail(id),
    queryFn: fetchProfileLook,
  })
}

export { useProfileLooks, useProfileLook }

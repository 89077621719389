import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonButton,
  IonIcon,
} from '@ionic/react'
import { close } from 'ionicons/icons'

import { Card, CardContent } from '../../../components/ui/card'

type Props = {
  onDismiss: () => void
  clientId: string
}

function InvalidTokenModal({ onDismiss }: Props) {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton color="secondary" onClick={onDismiss}>
              <IonIcon icon={close} slot="icon-only" />
            </IonButton>
          </IonButtons>
          <IonTitle>Oops!</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <Card>
          <CardContent>
            <p>This station is not set up correctly.</p>
            <p>Please let your cashier know.</p>
          </CardContent>
        </Card>
      </IonContent>
    </IonPage>
  )
}

export { InvalidTokenModal }

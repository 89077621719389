import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
} from '@ionic/react'

import logo from '../resources/OneShop_Logo_White.png'

function AppStatus() {
  return (
    <IonPage>
      <IonContent>
        <IonGrid className="h-full">
          <IonRow className="h-full items-center justify-center">
            <IonCol size="12" className="text-center">
              <img
                src={logo}
                alt="Oneshop Logo"
                className="my-0 mb-12 ml-2 h-16"
              />

              <IonCard color="secondary">
                <IonCardContent>
                  <p>
                    Your version is out of date. Please update to use the app.
                  </p>
                  <p>
                    You can download the latest{' '}
                    <a
                      href="https://play.google.com/store/apps/details?id=com.clientelier.app&hl=en_US&gl=US"
                      target="_blank"
                      rel="noreferrer"
                      className="text-ion-color-primary underline"
                    >
                      Android version
                    </a>{' '}
                    or{' '}
                    <a
                      href="https://apps.apple.com/us/app/oneshop-retail/id1496421236"
                      target="_blank"
                      rel="noreferrer"
                      className="text-ion-color-primary underline"
                    >
                      iOS version
                    </a>{' '}
                    from their App Stores.
                  </p>
                </IonCardContent>
              </IonCard>

              <div className="ion-text-center">
                version {process.env.REACT_APP_VERSION}
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export default AppStatus

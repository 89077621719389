import { IonLabel, IonRouterLink, IonNote } from '@ionic/react'
import { format, parseISO } from 'date-fns'

import {
  ListItem,
  ListItemHeading,
  ListItemSubHeading,
} from '../../components/ui/list-item'

import type { CallList } from '../../clienteling/outreach/outreach.schema'

function OutreachItem({
  callList,
  routerLink,
}: {
  callList: CallList
  routerLink: string
}) {
  return (
    <ListItem key={callList.objectId}>
      <IonLabel className="ml-4">
        <IonRouterLink routerLink={routerLink}>
          <ListItemHeading secondary>{callList.name}</ListItemHeading>
          <ListItemSubHeading>
            Total Clients: {callList.stats.clientCount}
          </ListItemSubHeading>
          {callList.startDate && callList.endDate ? (
            <ListItemSubHeading>
              {`${format(
                parseISO(callList.startDate.iso),
                'MMM d, yyyy'
              )} - ${format(parseISO(callList.endDate.iso), 'MMM d, yyyy')}`}
            </ListItemSubHeading>
          ) : null}
        </IonRouterLink>
      </IonLabel>
      <IonNote color="secondary" className="text-sm" slot="end">
        <div className="flex">
          <div>
            <p className="font-semibold">Type: Outreach</p>
            <span>
              Created: {format(parseISO(callList.createdAt.iso), 'MM/dd/yy')}
            </span>
          </div>
          {/* Workaround: to get the Notes for outreach to line up with broadcast (which has a delete button) */}
          <div className="w-9" />
        </div>
      </IonNote>
    </ListItem>
  )
}

export { OutreachItem }

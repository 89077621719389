import { IonCol, IonRow, useIonRouter } from '@ionic/react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { useToast } from '../../../../contexts/toastContext'
import { useUpdateClient } from '../../../../hooks/clients/mutations'
import { updateClientInputSchema } from '../../../../marketing/customer-opt-in/customer-opt-in.schema'
import { TextInput } from '../../../../components/ui/form/input'

import type { UpdateClientInput } from '../../../../marketing/customer-opt-in/customer-opt-in.schema'

type Props = {
  customerID: string | null
  querystring: string
  onSuccessPath: string
}

function OptInMoreInfoContent({
  customerID,
  querystring,
  onSuccessPath,
}: Props) {
  const { control, handleSubmit } = useForm<UpdateClientInput>({
    defaultValues: { firstName: '', lastName: '' },
    resolver: zodResolver(updateClientInputSchema),
  })

  const router = useIonRouter()
  const setToast = useToast()
  const updateClient = useUpdateClient()

  function handleFinish(data: UpdateClientInput) {
    if (!customerID)
      return setToast({
        message: "There isn't an available customer to update.",
        color: 'danger',
      })

    updateClient.mutate(
      { objectId: customerID, ...data },
      {
        onSuccess: () => {
          router.push(`${onSuccessPath}${querystring}`, 'none', 'replace')
        },
      }
    )
  }

  return (
    <form onSubmit={handleSubmit(handleFinish)} className="h-full">
      <div className="size-full mx-auto flex max-w-xl flex-col items-center justify-center">
        <h1 className="text-5xl">Welcome!</h1>
        <div className="py-3" />
        <p className="text-3xl">We just need a little more info from you.</p>
        <div className="py-6" />

        <IonRow className="w-full">
          <IonCol size="6">
            <TextInput
              disabled={updateClient.isLoading}
              control={control}
              name="firstName"
              placeholder="First Name"
            />
          </IonCol>
          <IonCol size="6">
            <TextInput
              disabled={updateClient.isLoading}
              control={control}
              name="lastName"
              placeholder="Last Name"
            />
          </IonCol>
        </IonRow>

        <div className="py-10" />
        <button
          disabled={updateClient.isLoading}
          type="submit"
          color="secondary"
          className="bg-ion-color-secondary text-ion-color-primary rounded-full px-20 py-3 text-3xl font-semibold"
        >
          Continue
        </button>
      </div>
    </form>
  )
}

export { OptInMoreInfoContent }

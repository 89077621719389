import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import type { UseFormProps } from 'react-hook-form'
import type { TypeOf, ZodSchema } from 'zod'

interface UseZodFormProps<Z extends ZodSchema>
  extends Exclude<UseFormProps<TypeOf<Z>>, 'resolver'> {
  schema: Z
}

function useZodForm<Z extends ZodSchema>({
  schema,
  ...formProps
}: UseZodFormProps<Z>) {
  return useForm({
    ...formProps,
    resolver: zodResolver(schema),
  })
}

export { useZodForm }

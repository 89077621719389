import { useQuery } from '@tanstack/react-query'

import { client } from '../../services/client'
import { useToast } from '../../contexts/toastContext'

import type { QueryContextFromKeys } from '../../utils/react-query'

export type Tag = {
  name: string
  objectId: string
}

type TagQueryContexts = QueryContextFromKeys<typeof tagQueryKeys>

export const tagQueryKeys = {
  all: [{ entity: 'tags' }] as const,
  lists: () => [{ ...tagQueryKeys.all[0], scope: 'list' }],
  filter: (searchString?: string) =>
    [{ ...tagQueryKeys.lists()[0], searchString }] as const,
  client: (clientId: string) =>
    [{ ...tagQueryKeys.lists()[0], clientId }] as const,
}

async function fetchClientTagsByTag({
  queryKey: [{ searchString }],
}: TagQueryContexts['filter']) {
  const response: { result: Array<Tag> } = await client
    .post('functions/clientTags', {
      json: searchString ? { filter: { name: searchString } } : undefined,
    })
    .json()

  return response.result
}

async function fetchClientTags({
  queryKey: [{ clientId }],
}: TagQueryContexts['client']) {
  const response: { result: { clientTags: Array<Tag> } } = await client
    .post('functions/getClientTagsClient', {
      json: { objectId: clientId },
    })
    .json()

  return response.result
}

function useClientTags(clientId: string) {
  return useQuery({
    queryKey: tagQueryKeys.client(clientId),
    queryFn: fetchClientTags,
  })
}

function useSearchClientTags(searchString?: string) {
  const setToast = useToast()

  return useQuery({
    queryKey: tagQueryKeys.filter(searchString),
    queryFn: fetchClientTagsByTag,
    enabled: typeof searchString === 'string' ? Boolean(searchString) : true,
    refetchOnWindowFocus: false,
    retry: false,
    onError(error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown Error.'

      setToast({
        message: `Something went wrong while fetching the tags: ${errorMessage}`,
        color: 'danger',
      })
    },
  })
}

export { useClientTags, useSearchClientTags }

import { IonButton } from '@ionic/react'

function FahertyTerms({ onAccept }: { onAccept: () => void }) {
  return (
    <section className="ion-padding mx-auto my-0 w-full max-w-2xl pb-6">
      <p className="text-3xl">
        By utilizing the OneShop platform, I acknowledge and agree:
      </p>
      <ol className="list-inside list-[auto] space-y-2 text-xl">
        <li className="pl-3">
          Employee conduct on the platform is governed by the same principles
          and guidelines included in our policies and procedures such as: Code
          of Conduct, Good Vibes Guide, Employee Handbook, Timekeeping, Privacy,
          Security and Social Media. Employees are expected to maintain
          professionalism and exemplify Faherty's core values in the use of the
          platform. Faherty reserves the right to remove content that is not
          consistent with our policies and core values.
        </li>
        <li className="pl-3">
          Conduct in the form of posts, tasks, chats, and/or comments on OneShop
          that adversely affects job performance, the performance of fellow
          employees, the reputation of the Company or otherwise adversely
          impacts customers, employees and business associates is subject to
          disciplinary action up to and including termination. Employees are
          expected to report inappropriate or questionable conduct to their
          Leader and/or the People Team.
        </li>
        <li className="pl-3">
          Accessing the platform on your personal cell phone or other electronic
          device is not required by Faherty. However, if you choose to access
          the platform on your personal device, Faherty is not responsible for
          data usage.
        </li>
        <li className="pl-3">
          This platform shall not be utilized or accessed outside of work time
          by hourly, nonexempt employees. If you have questions as to when and
          how this platform should be accessed please contact your Leader for
          clarification.
        </li>
        <li className="pl-3">
          Employee use of the platform in the following ways is prohibited:
          <ol className="mt-2 list-inside list-[lower-alpha]">
            <li className="pl-3">
              Sharing content outside Faherty except as otherwise authorized
            </li>
            <li className="pl-3">
              Posts or comments with political content except as otherwise
              authorized
            </li>
            <li className="pl-3">
              Use of trademark or copyrighted images/content, such as logos and
              photos without prior permission
            </li>
            <li className="pl-3">
              Soliciting, posting or distributing materials except for company
              related purposes and events including related to contests, raffles
              or charitable events
            </li>
            <li className="pl-3">
              Conduct designed to instigate conflict, hostility or inflammatory
              behavior
            </li>
          </ol>
        </li>
      </ol>
      <div className="mt-4 flex justify-center">
        <IonButton color="yellow" onClick={onAccept}>
          I Accept
        </IonButton>
      </div>
    </section>
  )
}

export default FahertyTerms

import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { useLocation } from 'react-router-dom'
import { z } from 'zod'

import { makeSearchParamsObjSchema } from '../../utils/schema'
import { client } from '../../services/client'
import { useIsAdminRoute } from '../../hooks/routing'
import {
  fetchPostResponseSchema,
  fetchPostsResponseSchema,
} from '../../social-sharing/posts/posts.schema'
import { filterMap } from './components/post-list'

import type { QueryContextFromKeys } from '../../utils/react-query'

type PostFilters = {
  admin?: boolean
  requiresApproval?: boolean
  list?: 'all' | 'posted' | 'pending_approval' | 'scheduled' | 'failed'
}

const postFiltersSchema = z.object({
  admin: z.boolean().optional(),
  requiresApproval: z.boolean().optional(),
  scheduled: z.boolean().optional(),
  filter: z.object({
    status: z
      .enum(['all', 'posted', 'pending_approval', 'scheduled', 'failed'])
      .optional(),
  }),
  sort: z.object({
    date: z.literal(1).or(z.literal(-1)),
  }),
})

const postFiltersSchema2 = z.object({
  admin: z.boolean().optional(),
  requiresApproval: z.boolean().optional(),
  list: z
    .enum(['all', 'posted', 'pending_approval', 'scheduled', 'failed'])
    .optional(),
})

type PostQueryContexts = QueryContextFromKeys<typeof postQueryKeys>

const filterParamsSchema = makeSearchParamsObjSchema(postFiltersSchema2)

export const postQueryKeys = {
  all: [{ entity: 'posts' }] as const,
  lists: () => [{ ...postQueryKeys.all[0], scope: 'list' }] as const,
  list: ({ filters }: { filters?: PostFilters } = {}) =>
    [{ ...postQueryKeys.lists()[0], filters }] as const,
  details: () => [{ ...postQueryKeys.all[0], scope: 'detail' }] as const,
  detail: (id: string) => [{ ...postQueryKeys.details()[0], id }] as const,
}

async function fetchPost({ queryKey: [{ id }] }: PostQueryContexts['detail']) {
  const response = await client
    .post('functions/getPost', { json: { objectId: id } })
    .json()

  return fetchPostResponseSchema.parse(response).result
}

async function fetchPosts({
  pageParam = 0,
  queryKey: [{ filters }],
}: PostQueryContexts['list']) {
  const pageSize = 10
  const { list, ...otherFilters } = filters ?? {}

  const postListFilters =
    filters && filters['list'] ? filterMap[filters['list']] : filterMap.all

  const response = await client
    .post('functions/getPosts', {
      json: {
        page: pageParam,
        pageSize,
        filter: {
          ...otherFilters,
          ...postListFilters['filter'],
        },
        sort: postListFilters['sort'],
      },
    })
    .json()

  const parsed = fetchPostsResponseSchema.safeParse(response)
  if (!parsed.success) throw new Error(`fetchPosts\n\n${parsed.error}`)

  return parsed.data.result
}
function usePostFilters() {
  const location = useLocation()
  const { search } = location

  return filterParamsSchema.parse(new URLSearchParams(search))
}

function usePosts() {
  const filters = usePostFilters()

  return useInfiniteQuery({
    queryKey: postQueryKeys.list({ filters }),
    queryFn: fetchPosts,
    getNextPageParam: (lastPage) =>
      lastPage.hasNextPage ? lastPage.page + 1 : undefined,
    useErrorBoundary: true,
    onError: (err) => console.log('error', err),
  })
}

function usePostsForApproval() {
  const isAdminRoute = useIsAdminRoute()

  return useInfiniteQuery({
    queryKey: postQueryKeys.list({
      filters: { requiresApproval: true, admin: isAdminRoute },
    }),
    queryFn: fetchPosts,
    getNextPageParam: (lastPage) =>
      lastPage.hasNextPage ? lastPage.page + 1 : undefined,
    useErrorBoundary: true,
  })
}

function usePostDetail(id: string) {
  return useQuery({
    queryKey: postQueryKeys.detail(id),
    queryFn: fetchPost,
    useErrorBoundary: true,
  })
}

export type { PostFilters }
export { usePosts, usePostsForApproval, usePostDetail }

import { IonContent, IonPage } from '@ionic/react'

import AdminHeader from '../../../components/header/admin-header'
import { ApprovalsListWrapper } from '../../../admin/approvals/components/approvals-list-wrapper'
import { BroadcastApprovalItem } from '../../../admin/approvals/components/broadcast-approval-item'
import { useApprovals } from '../../../admin/approvals/queries'

function CampaignApprovalsPage() {
  const broadcasts = useApprovals({ permission: 'admin', filter: 'broadcast' })

  return (
    <IonPage>
      <AdminHeader title="Broadcast Approvals" showMenu />
      <IonContent>
        <ApprovalsListWrapper>
          {broadcasts.data?.pages
            .flatMap((d) => d.data)
            .map((approval) => (
              <BroadcastApprovalItem
                key={approval.objectId}
                broadcast={approval}
              />
            ))}
        </ApprovalsListWrapper>
      </IonContent>
    </IonPage>
  )
}

export { CampaignApprovalsPage }

import { IonLabel } from '@ionic/react'

function DropDownInput({
  label,
  step,
  type,
  value,
  onChange,
}: {
  label: string
} & Pick<
  React.ComponentProps<'input'>,
  'onChange' | 'step' | 'type' | 'value'
>) {
  return (
    <>
      <IonLabel color="primary">{label}</IonLabel>
      <input
        className="focus:ring-ion-color-yellow form-input block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
        type={type}
        value={value}
        step={step}
        onChange={(e) => onChange && onChange(e)}
      />
    </>
  )
}

export { DropDownInput }

import * as React from 'react'
import { Redirect, Route, useLocation } from 'react-router'
import {
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
} from '@ionic/react'
import { home, bag, personCircle } from 'ionicons/icons'

import { page } from '../analytics'
import Home from '../pages/home'
import ShopWith from '../pages/shopwith/shopwith'
import ProductDetail from '../pages/shopwith/product-detail'
import LookDetail from '../pages/shopwith/look-detail'
import DeliveryPage from '../pages/home/delivery-page'
import ShareAssetPage from '../pages/home/share-asset-page'
import ProfilePage from '../pages/account/profile-page'
import EditProfilePage from '../pages/account/edit-profile-page'
import ProfileLookPage from '../pages/account/profile/profile-look-page'
import ProfileProductPage from '../pages/account/profile/profile-product-page'
import ShareLookPage from '../pages/shopwith/look/share-look-page'
import CreateLookPage from '../pages/shopwith/look/create-look-page'
import CreateCollagePage from '../pages/shopwith/look/create-collage-page'

const SocialTabs = () => {
  const location = useLocation()

  React.useEffect(() => {
    page()
  }, [location])

  return (
    <IonTabs>
      <IonRouterOutlet>
        <Redirect exact path="/tabs" to="/tabs/home" />
        <Route path="/tabs/home" component={Home} exact />
        <Route path="/tabs/home/delivery/:deliveryId" exact>
          <DeliveryPage />
        </Route>
        <Route path="/tabs/home/asset/:assetId/share" exact>
          <ShareAssetPage />
        </Route>

        {/* ShopWith Routes */}
        <Route path="/tabs/shopwith" component={ShopWith} exact />
        <Route path="/*/product/:pid" exact>
          <ProductDetail />
        </Route>
        <Route path="/tabs/shopwith/look/create" exact>
          <CreateLookPage />
        </Route>
        <Route path="/tabs/shopwith/look/create-collage" exact>
          <CreateCollagePage />
        </Route>
        <Route
          path="/tabs/shopwith/look/detail/:lookId"
          component={LookDetail}
          exact
        />
        <Route path="/tabs/shopwith/look/detail/:lookId/share" exact>
          <ShareLookPage />
        </Route>

        {/* Profile Tab Routes */}
        <Route path="/tabs/profile" exact>
          <ProfilePage />
        </Route>
        <Route path="/tabs/profile/edit" exact>
          <EditProfilePage />
        </Route>
        <Route path="/tabs/profile/post/:profileLookId" exact>
          <ProfileLookPage />
        </Route>
        <Route path="/tabs/profile/top-pick/:profileProductId" exact>
          <ProfileProductPage />
        </Route>
      </IonRouterOutlet>

      <IonTabBar slot="bottom">
        <IonTabButton tab="home" href="/tabs/home">
          <IonIcon icon={home} />
          <IonLabel>HOME</IonLabel>
        </IonTabButton>

        <IonTabButton tab="shopwith" href="/tabs/shopwith">
          <IonIcon icon={bag} />
          <IonLabel>SHOPWITH</IonLabel>
        </IonTabButton>

        <IonTabButton tab="profile" href="/tabs/profile">
          <IonIcon icon={personCircle} />
          <IonLabel>PROFILE</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  )
}

export default SocialTabs

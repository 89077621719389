import { IonImg } from '@ionic/react'

import { useShopWithLinks } from '../../hooks/shopwith/use-shopwith-links'

import type {
  Template,
  TemplateAttachments,
} from '../../hooks/templates/queries'
import { Card, CardContent } from './card'

export type TemplateMessage = {
  smsMessage: {
    body: string
  }
  emailMessage: {
    subject: string
    body: string
  }
  shopWithLink?: string
  attachments?: TemplateAttachments
}

function useTemplateShopWithLink(template: Template | null) {
  const { generateShopWithLinkPreview } = useShopWithLinks()

  return Boolean(template?.collections?.length)
    ? generateShopWithLinkPreview(
        'collectionIds',
        template?.collections?.map((c) => c.objectId) ?? []
      )
    : Boolean(template?.looks?.length)
    ? generateShopWithLinkPreview(
        'lookIds',
        template?.looks?.map((l) => l.objectId) ?? []
      )
    : Boolean(template?.products?.length)
    ? generateShopWithLinkPreview(
        'productIds',
        template?.products?.map((p) => p.objectId) ?? []
      )
    : undefined
}

function TemplateMessageDisplay({ template }: { template: Template | null }) {
  const link = useTemplateShopWithLink(template)

  const message: TemplateMessage = {
    smsMessage: { body: template?.smsTemplate?.body ?? '' },
    emailMessage: {
      subject: template?.emailTemplate?.subject ?? '',
      body: template?.emailTemplate?.body ?? '',
    },
    shopWithLink: link,
    // TODO: HACK!!! SHOULD EVENTUALLY MOVE TO SAME STRUCTURE AS TEMPLATES WITH ATTACHMENTS ON EACH "TEMPLATE" TYPE
    attachments: template?.smsTemplate?.attachments ?? null,
  }

  return (
    <div className="space-y-4">
      {message.smsMessage.body ? (
        <div className="space-y-2">
          <h2>Text Message</h2>
          <Card>
            <CardContent>
              <p>{message.smsMessage.body}</p>
            </CardContent>
          </Card>
        </div>
      ) : null}

      {message.emailMessage.subject || message.emailMessage.body ? (
        <div className="space-y-2">
          <h2>Email</h2>
          <Card>
            <CardContent>
              <p className="text-lg">
                <span className="font-semibold">Subject:</span>{' '}
                {message.emailMessage.subject}
              </p>
              <p>{message.emailMessage.body}</p>
            </CardContent>
          </Card>
        </div>
      ) : null}
      {message.shopWithLink ? (
        <p>
          <span className="font-semibold">ShopWith Link: </span>
          {message.shopWithLink}
        </p>
      ) : null}
      {message.attachments ? (
        <>
          <p>
            <span className="font-semibold">Attachment: </span>
          </p>
          {message.attachments?.map((photo) => (
            <div
              key={typeof photo === 'string' ? photo : photo.pic}
              className="flex items-center justify-between"
            >
              <IonImg
                src={typeof photo === 'string' ? photo : photo.pic}
                style={{ height: '5rem' }}
              />
            </div>
          ))}
        </>
      ) : null}
    </div>
  )
}

export default TemplateMessageDisplay

import {
  IonButton,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { Browser } from '@capacitor/browser'

import { useAuth } from '../contexts/authContext'
import { useHistory } from 'react-router-dom'

function UpgradePlanPage() {
  const auth = useAuth()
  const history = useHistory()

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Upgrade Plan</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonCard color="secondary" className="mx-auto max-w-sm">
          <IonCardContent>
            Your free trial has expired. If you feel this is an error or would
            like to upgrade to a paid plan, please contact{' '}
            <a href="mailto:sales@oneshopretail.com" className="underline">
              sales@oneshopretail.com
            </a>{' '}
            or book a call with us{' '}
            <a
              href="https://calendly.com/nickmchenry/demo"
              className="underline"
            >
              here
            </a>
            .
          </IonCardContent>
        </IonCard>

        <div className="ion-text-center flex items-center justify-center gap-x-2">
          <IonButton
            style={{
              '--padding-start': 0,
              '--padding-end': 0,
            }}
            fill="clear"
            className=" text-ion-color-secondary underline"
            onClick={() => auth.handleLogOut(history)}
          >
            {`<< Back to login.`}
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  )
}

export { UpgradePlanPage }

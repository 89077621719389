import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react'

import { useClientLoyalty } from '../../loyalty/queries'
import { CustomerLoyaltyInfo } from '../../rotate/components/customer-loyalty-info'

function CustomerLoyaltyInfoPage() {
  const router = useIonRouter()
  const loyalty = useClientLoyalty()

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Loyalty Rewards</IonTitle>
          <IonButtons slot="end">
            <IonButton
              color="yellow"
              fill="solid"
              onClick={() =>
                router.push('/rotate/customer/check-in', 'none', 'replace')
              }
            >
              Finish
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <>
          {loyalty.data ? (
            <CustomerLoyaltyInfo data={loyalty.data} />
          ) : loyalty.error ? (
            <p>
              Error:{' '}
              {loyalty.error instanceof Error
                ? loyalty.error.message
                : 'Unknown Error.'}
            </p>
          ) : (
            <IonLoading message="Loading Rewards..." isOpen />
          )}
        </>
      </IonContent>
    </IonPage>
  )
}

export { CustomerLoyaltyInfoPage }

import {
  IonPage,
  IonContent,
  IonHeader,
  IonLoading,
  useIonViewWillEnter,
} from '@ionic/react'

import { useQLists4DayFollowUp } from '../../hooks/outreach/queries'
import AddFab from '../../components/fab/add-fab'
import Header from '../../components/header'
import {
  ListPageDetail,
  ListPageHeader,
  ListPageFilters,
  ListPageClientList,
} from '../../clienteling/list-page-detail'

function QOutreach4Day() {
  const qListQuery = useQLists4DayFollowUp()

  // TODO: Better optimize this with other lists possibly in communication sending logic
  useIonViewWillEnter(() => qListQuery.refetch())

  return (
    <IonPage>
      <AddFab />
      <IonHeader>
        <Header backRef="/tabs/clients" title="Outreach" />
      </IonHeader>

      <IonContent>
        {qListQuery.data ? (
          <ListPageDetail clientList={qListQuery.data}>
            <ListPageHeader title="3rd Reach Out" />
            <ListPageFilters />
            <ListPageClientList />
          </ListPageDetail>
        ) : (
          <IonLoading isOpen message={'Loading the client list...'} />
        )}
      </IonContent>
    </IonPage>
  )
}

export default QOutreach4Day

import { Listbox } from '@headlessui/react'
import { useController } from 'react-hook-form'

import { ayrsharePlatforms } from '../ayrshare/ayrshare-platforms'
import { OneshopLogo } from '../ayrshare/social-logos'

import type { UseControllerProps } from 'react-hook-form'
import type { Ayrshare } from '../ayrshare/ayrshare.schema'

function SocialChannelSelector<T>({
  ayrshareConnection,
  shareToOneShop,
  ...props
}: {
  ayrshareConnection?: Ayrshare
  shareToOneShop?: boolean
} & UseControllerProps<T>) {
  const {
    field: { value, onChange },
  } = useController(props)

  if (ayrshareConnection) {
    return (
      <Listbox multiple value={value ?? []} onChange={onChange}>
        <Listbox.Options static className="flex items-start gap-4">
          {ayrsharePlatforms.map((platform) => {
            const Icon = platform.icon
            const disabledSocialAccount =
              !ayrshareConnection.activeSocialAccounts?.includes(platform.name)

            return (
              <Listbox.Option
                key={platform.name}
                value={platform.name}
                className="ui-selected:opacity-100 ui-not-selected:opacity-30"
                disabled={disabledSocialAccount}
              >
                <Icon />
              </Listbox.Option>
            )
          })}
          {shareToOneShop ? (
            <Listbox.Option
              value="oneshop"
              className="ui-selected:opacity-100 ui-not-selected:opacity-30"
            >
              <OneshopLogo />
            </Listbox.Option>
          ) : null}
        </Listbox.Options>
      </Listbox>
    )
  }

  return (
    <Listbox multiple value={value ?? []} onChange={onChange}>
      <Listbox.Options static className="flex items-start gap-4">
        {ayrsharePlatforms.map((platform) => {
          const Icon = platform.icon

          return (
            <Listbox.Option
              key={platform.name}
              value={platform.name}
              disabled
              className="ui-selected:opacity-100 ui-not-selected:opacity-30"
            >
              <Icon />
            </Listbox.Option>
          )
        })}

        <Listbox.Option
          value="oneshop"
          className="ui-selected:opacity-100 ui-not-selected:opacity-30"
        >
          <OneshopLogo />
        </Listbox.Option>
      </Listbox.Options>
    </Listbox>
  )
}

export default SocialChannelSelector

import * as store from '../stores'

const OAUTH_PARAMS = 'OAUTH_PARAMS'

function saveOAuthParams(params) {
  window.localStorage.setItem(OAUTH_PARAMS, JSON.stringify(params))
}

function getOAuthParams(params) {
  return JSON.parse(window.localStorage.getItem(OAUTH_PARAMS)) || {}
}

function saveLocalUser(user) {
  window.localStorage.setItem('authUser', JSON.stringify(user))
}

function fetchAuth(token) {
  var url = `${store.getEndpoint()}users/me`

  return window
    .fetch(url, {
      method: 'GET',
      headers: {
        'X-Parse-Application-Id': '15eaa65a6d14458698fffb72bec53dd1',
        'X-Parse-REST-API-Key': '1234',
        'X-Parse-Session-token': token,
      },
    })
    .then(handleResponse)
    .then((response) => {
      saveLocalUser(response)
      return response
    })
}

function logout() {
  const options = {
    method: 'POST',
    headers: store.getHeader(),
  }

  window
    .fetch(`${store.getEndpoint()}/logout`, options)
    .then(handleResponse)
    .then(
      (response) => {},
      (error) => {
        console.log('logout error', error)
      }
    )

  window.localStorage.removeItem('token')
  window.localStorage.removeItem('authUser')
}

function getToken() {
  return window.localStorage.getItem('token')
}

function getAuthUser() {
  return JSON.parse(window.localStorage.getItem('authUser'))
}

function getLoggedIn() {
  const token = window.localStorage.getItem('token')
  return token !== null
}

function getUserId() {
  return getAuthUser()?.objectId
}

function clientIsFavorite(client) {
  return getAuthUser().favorites
    ? getAuthUser().favorites.filter(
        (favorite) => favorite.objectId === client.objectId
      ).length > 0
    : false
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        // TODO: log out when appropriate, inform the user
        // logout()
      }

      const error = (data && data.message) || response.statusText

      const reason = {
        error,
        status: response.status,
      }
      return Promise.reject(reason)
    }

    return data
  })
}

export const authService = {
  logout,
  fetchAuth,
  getToken,
  getAuthUser,
  getLoggedIn,
  getUserId,
  saveLocalUser,
  clientIsFavorite,
  getOAuthParams,
  saveOAuthParams,
}

import {
  IonButton,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLoading,
  IonRefresher,
  IonRefresherContent,
  IonRouterLink,
  IonRow,
  RefresherEventDetail,
} from '@ionic/react'
import { chevronDownCircleOutline } from 'ionicons/icons'
import clsx from 'clsx'

import { useAccount } from '../../../contexts/authContext'
import { useFeed, usePendingPostCount } from '../../../hooks/shopwith/use-feed'
import { useNewDeliveries } from '../../../hooks/shopwith/products/queries'
import { useInfiniteData } from '../../../hooks/use-infinite-data'
import { isSinglePlayerMode } from '../../../auth/utils'
import { useUserSocialConnections } from '../../../ayrshare/queries'
import ScrollingList from '../../ui/scrolling-list'
import FeedItem from './feed-item'
import DeliveryItem from './delivery-item'

function Feed() {
  const account = useAccount()

  const newDeliveriesQuery = useNewDeliveries()
  const feedQuery = useFeed()

  const pendingPostCountQuery = usePendingPostCount()
  const pendingPostCount = pendingPostCountQuery.data?.count

  const [ionInfiniteScrollRef, loadMorePosts] = useInfiniteData(
    feedQuery.fetchNextPage
  )

  const userConnections = useUserSocialConnections()

  const queriesLoaded =
    !!newDeliveriesQuery.data &&
    !!feedQuery.data &&
    !!userConnections.data &&
    !!pendingPostCountQuery.data

  async function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    await Promise.all([feedQuery.refetch(), pendingPostCountQuery.refetch()])
    event.detail.complete()
  }

  if (queriesLoaded) {
    return (
      <>
        {isSinglePlayerMode(account) &&
        !userConnections.data.ayrshares.length ? (
          <IonRow className="ion-padding-horizontal h-full items-center justify-center">
            <IonButton
              color="yellow"
              routerLink="/account/connections"
              routerDirection="none"
            >
              Connect Your Social Accounts
            </IonButton>
          </IonRow>
        ) : (
          <>
            <IonRefresher
              className=""
              slot="fixed"
              onIonRefresh={handleRefresh}
            >
              <IonRefresherContent
                pullingIcon={chevronDownCircleOutline}
                refreshingSpinner="circles"
              ></IonRefresherContent>
            </IonRefresher>

            {/* Loading Indicator for manual refetch button */}
            {feedQuery.isRefetching && !feedQuery.isFetchingNextPage ? (
              <IonLoading isOpen />
            ) : null}

            <div className="mx-auto max-w-xl space-y-6 overflow-hidden">
              <IonRow
                className={clsx(
                  `ion-padding-horizontal ${
                    pendingPostCount ? 'justify-between' : 'justify-center'
                  }`
                )}
              >
                {pendingPostCount ? (
                  <div>{`${pendingPostCount} pending post${
                    pendingPostCount === 1 ? '' : 's'
                  }`}</div>
                ) : null}

                <IonButton
                  color="secondary"
                  size="small"
                  fill="outline"
                  onClick={() =>
                    Promise.all([
                      feedQuery.refetch(),
                      pendingPostCountQuery.refetch(),
                    ])
                  }
                >
                  Refresh Feed
                </IonButton>
              </IonRow>

              {newDeliveriesQuery.data?.length ? (
                <>
                  <h2 className="ion-padding-horizontal text-2xl font-bold">
                    New Deliveries
                  </h2>
                  <ScrollingList>
                    {newDeliveriesQuery.data.map((delivery) => (
                      <IonRouterLink
                        key={delivery.objectId}
                        style={{ '--color': 'var(--ion-color-secondary' }}
                        routerLink={`/tabs/home/delivery/${delivery.objectId}`}
                      >
                        <DeliveryItem
                          key={delivery.objectId}
                          delivery={delivery}
                        />
                      </IonRouterLink>
                    ))}
                  </ScrollingList>
                </>
              ) : null}

              <div className="ion-padding-vertical space-y-7">
                {feedQuery.data.pages
                  .flatMap((x) => x.data)
                  .map((feedItem) => (
                    <FeedItem key={feedItem.objectId} feedItem={feedItem} />
                  ))}
              </div>
            </div>
            <IonInfiniteScroll
              ref={ionInfiniteScrollRef}
              onIonInfinite={loadMorePosts}
              threshold="100px"
              disabled={!feedQuery.hasNextPage}
            >
              <IonInfiniteScrollContent
                loadingSpinner="bubbles"
                loadingText="loading more posts..."
              />
            </IonInfiniteScroll>
          </>
        )}
      </>
    )
  }

  return <IonLoading isOpen message="Loading Feed..." />
}

export default Feed

import React, { useEffect, useReducer } from 'react'
import {
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonCard,
  IonCardContent,
  IonItem,
  IonLabel,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonPopover,
} from '@ionic/react'
import produce from 'immer'
import { useQuery } from '@tanstack/react-query'

import { cn } from '../../utils/cn'
import * as commonStrings from '../../utils/common-strings'
import { formatName } from '../../utils/format-helpers'
import { searchService } from '../../services/searchService'
import { useStylistGroup, useUser } from '../../contexts/authContext'
import { useToast } from '../../contexts/toastContext'
import useNextContact from '../../hooks/use-next-contact'
import { useQueryParams } from '../../hooks/use-query-params'

const initialState = {
  template: null,
  popover: {
    isOpen: false,
    event: null,
  },
  form: {
    firstName: '',
    lastName: '',
    street1: '',
    street2: '',
    city: '',
    addressState: '',
    zip: '',
    country: '',
    message: '',
  },
  loading: false,
}

const MAX_LETTER_LENGTH = 500

function getInitialState() {
  return initialState
}

const reducer = produce((draft, action) => {
  const { type } = action

  switch (type) {
    case 'UPDATE_FIELD':
      const { name, value } = action
      draft.form[name] = value
      break
    case 'SET_FORM_VALUES':
      const { client } = action
      const form = draft.form
      form.firstName = client.firstName
      form.lastName = client.lastName
      form.street1 = client.street1
      form.street2 = client.street2
      form.city = client.city
      form.addressState = client.state
      form.zip = client.zip
      form.country = client.country
      break
    case 'SELECT_TEMPLATE':
      const { id, template } = action
      if (template) {
        draft.template = id
        draft.form.message = template.body
      }
      break
    case 'SUBMIT':
      draft.loading = true
      break
    case 'SUCCESS':
      draft.form = getInitialState().form
      draft.template = null
      draft.loading = false
      break
    case 'FAILURE':
      draft.loading = false
      break
    case 'TOGGLE_POPOVER':
      const { show, event } = action
      draft.popover = {
        event,
        isOpen: show,
      }
      break
    default:
      break
  }
})

function LetterCard({ clientId, onLetterSend, handleCommunicationCancel }) {
  const [length, setLength] = React.useState(0)

  const user = useUser()
  const stylistGroup = useStylistGroup()

  const URLquery = useQueryParams()
  const callListId = URLquery.get('callListId')
  const [state, dispatch] = useReducer(reducer, initialState)
  const { template, popover, form, loading } = state

  const setToast = useToast()
  const { nextContactDate, setNextContactDate, dates, saveNextContactDate } =
    useNextContact()

  const { data: { client = {}, letterTemplates: templates = [] } = {} } =
    useQuery({
      queryKey: ['clientLetter', clientId],
      queryFn: () =>
        searchService.getClient({
          objectId: clientId,
          letterTemplates: true,
        }),
    })

  const { data: { count: creditCount = '' } = {} } = useQuery({
    queryKey: ['credits', clientId],
    queryFn: () => searchService.getLetterCredits(),
  })

  useEffect(() => {
    dispatch({ type: 'SET_FORM_VALUES', client })
  }, [client])

  function togglePopover(e) {
    dispatch({ type: 'TOGGLE_POPOVER', show: true, event: e.nativeEvent })
  }

  async function handleSubmit(e) {
    e.preventDefault()
    dispatch({ type: 'SUBMIT' })

    /*
    This order is important...
    The server has to save the message before the NextContact
    */
    try {
      const letter = {
        client: {
          __type: 'Pointer',
          className: 'Client',
          objectId: client.objectId,
        },

        firstName: form.firstName,
        lastName: form.lastName,
        street1: form.street1,
        street2: form.street2,
        city: form.city,
        state: form.addressState,
        country: form.country,
        zip: form.zip,
        body: form.message,
      }

      if (template) {
        letter.letterTemplate = {
          __type: 'Pointer',
          className: 'LetterTemplate',
          objectId: template,
        }
      }
      if (callListId) {
        letter.callList = {
          __type: 'Pointer',
          className: 'CallList',
          objectId: callListId,
        }
      }

      await searchService.sendLetter(letter)
      await saveNextContactDate(clientId)
      dispatch({ type: 'SUCCESS' })
      handleCommunicationCancel()
      if (onLetterSend) onLetterSend()
    } catch (err) {
      dispatch({ type: 'FAILURE' })
      console.log('error: ', err)
      setToast({ message: 'Oops, something went wrong.', color: 'danger' })
    }
  }

  function handleTemplateSelect(e) {
    const id = e.target.value
    const template = templates.find((t) => t.objectId === id)

    template.body = template.body
      .replaceAll('<Stylist>', formatName(user.firstName))
      .replaceAll('<Store>', stylistGroup.name)
      .replaceAll('<StylistId>', user.objectId)

    dispatch({ type: 'SELECT_TEMPLATE', id, template })
  }

  function handleFieldUpdate(e) {
    const { name, value } = e.target
    dispatch({ type: 'UPDATE_FIELD', name, value })
  }

  return (
    <>
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonButton
              className="float-right"
              color="secondary"
              onClick={togglePopover}
            >
              Credits: {creditCount}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonCard className="followup-card">
        <IonCardContent>
          <form onSubmit={handleSubmit}>
            <IonItem color="secondary">
              <IonLabel position="stacked">Name</IonLabel>
              <IonGrid>
                <IonRow>
                  <IonCol size="6">
                    <IonInput
                      name="firstName"
                      placeholder="First Name"
                      value={form.firstName}
                      onIonChange={handleFieldUpdate}
                      required
                    />
                  </IonCol>
                  <IonCol size="6">
                    <IonInput
                      name="lastName"
                      placeholder="Last Name"
                      value={form.lastName}
                      onIonChange={handleFieldUpdate}
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonItem>
            <IonItem color="secondary">
              <IonLabel position="stacked">Address</IonLabel>
              <IonInput
                name="street1"
                placeholder="Street"
                value={form.street1}
                onIonChange={handleFieldUpdate}
                required
              />
              <IonInput
                name="street2"
                placeholder="Street"
                value={form.street2}
                onIonChange={handleFieldUpdate}
              />
              <IonRow className="w-full">
                <IonCol size="6" className="p-0">
                  <IonInput
                    name="city"
                    placeholder="City"
                    value={form.city}
                    onIonChange={handleFieldUpdate}
                    required
                  />
                </IonCol>
                <IonCol size="6" className="p-0">
                  <IonInput
                    name="addressState"
                    placeholder="State"
                    value={form.addressState}
                    onIonChange={handleFieldUpdate}
                    required
                  />
                </IonCol>
              </IonRow>
              <IonRow className="w-full">
                <IonCol size="6" className="p-0">
                  <IonInput
                    name="zip"
                    placeholder="Zip Code"
                    value={form.zip}
                    onIonChange={handleFieldUpdate}
                    required
                  />
                </IonCol>
                <IonCol size="6" className="p-0">
                  <IonInput
                    name="country"
                    placeholder="Country"
                    value={form.country}
                    onIonChange={handleFieldUpdate}
                  />
                </IonCol>
              </IonRow>
            </IonItem>

            <IonItem color="secondary">
              <div tabIndex={0} />
              <IonLabel>Select a Template</IonLabel>
              <IonSelect
                interfaceOptions={{ cssClass: 'dark-ionic-filter' }}
                value={template}
                onIonChange={handleTemplateSelect}
                okText={commonStrings.Okay}
                cancelText={commonStrings.Dismiss}
              >
                {templates.map((template, index) => (
                  <IonSelectOption key={index} value={template.objectId}>
                    {template.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
            <IonItem color="secondary">
              <IonLabel position="floating">Type Your Message Here:</IonLabel>
              <IonTextarea
                name="message"
                value={form.message}
                onIonChange={(e) => {
                  handleFieldUpdate(e)
                  setLength(e.detail.value.length)
                }}
                autoGrow
                autocapitalize="on"
                rows={Math.max(
                  (form.message || '').split(/\r\n|\r|\n/).length,
                  5
                )}
                required
                maxlength={MAX_LETTER_LENGTH}
              />
            </IonItem>
            <div
              className={cn('flex justify-end text-sm font-semibold', {
                'text-red-600': length > 475,
              })}
            >
              <span className="ml-1">
                {length} / {MAX_LETTER_LENGTH}
              </span>
            </div>

            <IonItem className="ion-margin-top p-0" color="secondary">
              <IonLabel>Next Contact:</IonLabel>
              <IonSelect
                id="next-contact-select"
                value={nextContactDate}
                onIonChange={(e) => setNextContactDate(e.target.value)}
                okText={commonStrings.Okay}
                cancelText={commonStrings.Dismiss}
                interfaceOptions={{ cssClass: 'dark-ionic-filter' }}
              >
                {dates.map(({ label, value }) => (
                  <IonSelectOption key={value} value={value}>
                    {label}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>

            <div className="buttons mt-1.5">
              <div>
                <IonButton fill="outline" onClick={handleCommunicationCancel}>
                  CANCEL
                </IonButton>
              </div>
              <div>
                <IonButton
                  type="submit"
                  disabled={loading || !creditCount || !client}
                >
                  SEND
                </IonButton>
              </div>
            </div>
          </form>
        </IonCardContent>
      </IonCard>
      <IonPopover
        color="secondary"
        isOpen={popover.isOpen}
        event={popover.event}
        onDidDismiss={() =>
          dispatch({ type: 'TOGGLE_POPOVER', isOpen: false, event: null })
        }
      >
        <IonItem color="secondary" href="mailto:support@oneshopretail.com">
          Contact support@oneshopretail.com to get more credits
        </IonItem>
      </IonPopover>
    </>
  )
}

export default LetterCard

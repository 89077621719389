import { z } from 'zod'
import { makeServerResponseSchema } from '../utils/schema'

const createAccountInputsSchema = z.object({
  companyName: z.string(),
  settings: z
    .object({
      clienteling: z.boolean(),
      marketing: z.boolean(),
      social: z.boolean(),
      loyalty: z.boolean(),
    })
    .partial()
    .optional(),
})

const createAccountResponseSchema = makeServerResponseSchema(z.object({}))

export type CreateAccountInputs = z.infer<typeof createAccountInputsSchema>
export { createAccountInputsSchema, createAccountResponseSchema }

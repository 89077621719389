import clsx from 'clsx'

type Props = React.PropsWithChildren<
  Omit<React.ComponentPropsWithoutRef<'button'>, 'className'>
> & { variant?: 'primary' | 'secondary' }
function Button({ children, variant = 'primary', ...props }: Props) {
  return (
    <button
      className={clsx(
        'text-ion-color-primary h-full w-fit px-2.5 text-sm font-semibold',
        variant === 'secondary'
          ? 'bg-[#0087ed] text-white'
          : 'bg-ion-color-yellow text-ion-color-primary'
      )}
      {...props}
    >
      {children}
    </button>
  )
}

export { Button }

import { IonCol, IonRow } from '@ionic/react'

import { PhoneKeypadInput } from '../../../../components/ui/phone-keypad-input'

import type { PhoneKeypadOnConfirm } from '../../../../components/ui/phone-keypad-input'

type Props = {
  handleSignUp: ({ phoneNumber, country }: PhoneKeypadOnConfirm) => void
}

function OptInContent({ handleSignUp }: Props) {
  return (
    <IonRow className="h-full">
      <IonCol
        size="6"
        className="flex h-full flex-1 flex-col items-center justify-center space-y-6"
      >
        <p className="text-center text-3xl">
          Enter your phone number to join our VIP text list!
        </p>
      </IonCol>
      <IonCol
        size="6"
        className="flex h-full flex-1 flex-col items-center justify-center gap-6"
      >
        <PhoneKeypadInput onConfirm={handleSignUp} />
      </IonCol>
    </IonRow>
  )
}

export { OptInContent }

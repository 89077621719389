import * as React from 'react'
import { IonButton, IonIcon, IonModal } from '@ionic/react'
import { paperPlaneOutline } from 'ionicons/icons'

import { useUser } from '../../contexts/authContext'
import ShareShopWithModal from './share-shop-with-modal'

type Props = {
  disabled: boolean
  collections: Array<string>
}

function ShareCollectionButton({ disabled, collections }: Props) {
  const modalRef = React.useRef<HTMLIonModalElement>(null)

  const user = useUser()

  const close = () => modalRef.current?.dismiss()

  const link = collections
    .map(
      (collection) =>
        `https://oneshop.me/collection/${collection}?stylist=${user.objectId}`
    )
    .join('\n')

  return (
    <>
      <IonButton
        id="open-send-modal"
        size="small"
        expand="block"
        disabled={disabled}
        color="yellow"
        className="uppercase tracking-wide"
      >
        SEND
        <IonIcon slot="end" icon={paperPlaneOutline} />
      </IonButton>

      <IonModal ref={modalRef} trigger="open-send-modal">
        <ShareShopWithModal
          title="Send Collection"
          items={collections}
          link={link}
          pointers={{ collectionIds: collections }}
          onClose={close}
          onSend={close}
        />
      </IonModal>
    </>
  )
}

export default ShareCollectionButton

import { z } from 'zod'

import { messageTypes, units } from '../constants/misc'
import {
  nullableString,
  rangeObjectSchema,
  serverItemSchema,
} from '../utils/schema'
import { profileBaseSchema } from '../schema/profile.schema'
import { unlayerDesignSchema } from '../unlayer/unlayer.schema'
import {
  automationStates,
  conditions,
  fromUserRules,
  triggerTypes,
} from './constants'

type Automation = z.infer<typeof automationSchema>
type TriggerRule = z.infer<typeof triggerRuleSchema>
type TriggerStep = z.infer<typeof triggerStepSchema>

const triggerRuleConditionSchema = z.object({
  type: z.enum(conditions),
  value: rangeObjectSchema.partial(),
})

const triggerRuleBaseSchema = z.object({
  description: nullableString,
  state: z.enum(automationStates),
  title: z.string(),
  type: z.enum(triggerTypes),
})

const triggerRuleSchema = triggerRuleBaseSchema.merge(
  serverItemSchema.merge(
    z.object({
      conditions: z.array(triggerRuleConditionSchema),
      createdAt: z.string(),
      fromUser: z.any(),
      fromName: nullableString,
      fromEmail: nullableString,
      fromUserRule: z.enum(fromUserRules),
      updatedAt: nullableString,
      user: profileBaseSchema,
    })
  )
)

const triggerStepSchema = serverItemSchema.merge(
  z.object({
    attachmentsEmail: z.array(z.string()).optional(),
    attachmentsSms: z.array(z.string()).optional(),
    delayAmount: z.number(),
    delayUnit: z.enum(units),
    emailMessage: nullableString,
    emailSubject: nullableString,
    messageType: z.enum(messageTypes),
    position: z.number(),
    smsMessage: nullableString,
    unlayerDesign: unlayerDesignSchema.nullable(),
    unlayerImage: nullableString,
    unlayerImageFull: nullableString,
    unlayerTemplateId: nullableString,
  })
)

const triggerRulesSchema = z.array(triggerRuleSchema)

const automationSchema = triggerRuleSchema.merge(
  z.object({ steps: z.array(triggerStepSchema) })
)
const automationsSchema = z.array(automationSchema.omit({ steps: true }))

export type { Automation, TriggerRule, TriggerStep }
export {
  automationSchema,
  automationsSchema,
  triggerRuleBaseSchema,
  triggerRuleConditionSchema,
  triggerRuleSchema,
  triggerRulesSchema,
  triggerStepSchema,
}

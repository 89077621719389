import { IonLoading } from '@ionic/react'

import { useMyTemplates } from '../../hooks/templates/queries'
import SequenceTemplateForm from './SequenceTemplateForm'

function CreateSequenceTemplate() {
  const templatesQuery = useMyTemplates()

  return (
    <>
      <h1 className="ion-text-center">Create Your Message Template</h1>
      {templatesQuery.data ? (
        <SequenceTemplateForm
          templates={templatesQuery.data
            .map((t) => t.smsTemplate)
            .filter(Boolean)}
        />
      ) : (
        <IonLoading
          isOpen={templatesQuery.status === 'loading'}
          message={'Loading ...'}
        />
      )}
    </>
  )
}

export default CreateSequenceTemplate

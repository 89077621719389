import {
  format,
  formatDistance,
  isAfter,
  isBefore,
  isEqual,
  parseISO,
  subDays as subtractDays,
} from 'date-fns'

import { isTaskType } from '../constants/classConstants'
import { MONTHS } from '../constants/misc'
import { hasDefinedProp } from './typescript-helpers'
import type { DateObject, IsoDate } from '../types/general'

type CreatedDate = {
  createdAt?: IsoDate
  date?: never
}
type DateDate = {
  createdAt?: never
  date?: IsoDate
}

type DateCompare = {
  className: string
  date?: { iso: string }
  createdAt?: { iso: string } | null
}

function getOrdinal(number: number) {
  const ordinals = ['th', 'st', 'nd', 'rd']
  const remainder = number % 100
  return `${number}${
    ordinals[(remainder - 20) % 10] || ordinals[remainder] || ordinals[0]
  }`
}

function getDateString(date: CreatedDate | DateDate) {
  const iso = date.createdAt
    ? date.createdAt.iso
    : date.date
    ? date.date.iso
    : null

  return iso ? format(parseISO(iso), 'MMM d, yyyy h:mm a') : 'Not Available'
}

function formatDistanceFromNow(date: string) {
  const parsedIsoDate = parseISO(date)

  const isWithinOneDay = isBefore(parsedIsoDate, subtractDays(parsedIsoDate, 1))
  return formatDistance(parsedIsoDate, Date.now(), {
    addSuffix: !isWithinOneDay,
  })
}

function formatDateObject(date: DateObject) {
  const hasMonth = hasDefinedProp(date, 'month')

  if (date.day && hasMonth && date.year) {
    return format(new Date(date.year, date.month, date.day), 'MMM dd, yyyy')
  }

  if (date.day && hasMonth && !date.year) {
    return `${MONTHS[date.month]} ${date.day.toString().padStart(2, '0')}`
  }
  if (date.day && !hasMonth && date.year) {
    return `${date.day} ${date.year}`
  }
  if (!date.day && hasMonth && date.year) {
    return format(new Date(date.year, date.month), 'MMM yyyy')
  }
  if (date.day && !hasMonth && !date.year) {
    return `the ${getOrdinal(date.day)}`
  }
  if (!date.day && hasMonth && !date.year) {
    return MONTHS[date.month]
  }
  if (!date.day && !date.month && date.year) {
    return date.year.toString()
  }
}

function dateCompare(a: DateCompare, b: DateCompare) {
  // Special case, follow ups use a combination of due and completed at,
  // we dont want to fall back to created at
  if (isTaskType.includes(a.className) && !a.date) {
    return -1
  }
  if (isTaskType.includes(b.className) && !b.date) {
    return 1
  }

  const dateStringA = a.date ? a.date.iso : a.createdAt ? a.createdAt.iso : null
  const dateStringB = b.date ? b.date.iso : b.createdAt ? b.createdAt.iso : null

  // handle null dates
  if (!dateStringA) return -1
  if (!dateStringB) return 1

  if (isAfter(parseISO(dateStringA), parseISO(dateStringB))) return -1
  if (isBefore(parseISO(dateStringA), parseISO(dateStringB))) return 1

  return 0
}

function isSameOrBefore(start: number | Date, end: number | Date) {
  return isEqual(start, end) || isBefore(start, end)
}

export {
  dateCompare,
  formatDateObject,
  formatDistanceFromNow,
  getDateString,
  isSameOrBefore,
}

import * as React from 'react'
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonCol,
  IonContent,
  IonHeader,
  IonImg,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import clsx from 'clsx'
import { ResourceType } from '@capawesome/capacitor-cloudinary'

import placeholder from '../../resources/no-image-product.png'
import {
  resetPost,
  usePostContent,
} from '../../social-sharing/posts/post-store'
import { useAccount, useUser } from '../../contexts/authContext'
import { useToast } from '../../contexts/toastContext'
import { useCreateBaseLook } from '../../hooks/shopwith/looks/mutations'
import {
  useCreateVideoPost,
  useShareLook,
} from '../../social-sharing/posts/mutations'
import { useDefaultSocialConnections } from '../../ayrshare/queries'
import { SchedulePicker } from '../../components/ui/form/schedule-picker'
import { createPostInputSchema } from '../../social-sharing/posts/posts.schema'
import SocialChannelSelector from '../../social-sharing/social-channel-selector'
import {
  getAccountChannels,
  getSocialConnections,
  getUserChannels,
} from '../../social-sharing/utils'
import VideoPreview from '../../components/cloudinary/video-preview'
import CollageItem from '../../components/shopwith/collage-item'
import SelectProductsModal from '../../components/shopwith/select-products-modal'
import CloseButton from '../../components/ui/buttons/close'
import { TextArea } from '../../components/ui/form/textarea'

import type { Product } from '../../types/shopwith'
import type { CreatePostInput } from '../../social-sharing/posts/posts.schema'

type FormState = CreatePostInput

const defaultValues: FormState = {
  description: '',
  isPublic: true,
  date: '',
  selectedChannels: {},
  selectedProducts: [],
}

function CreatePostPage() {
  const user = useUser()
  const account = useAccount()

  const router = useIonRouter()
  const setToast = useToast()

  const ayrshareConnectionsQuery = useDefaultSocialConnections()

  const { postMedia } = usePostContent()

  const accountChannels = getAccountChannels(ayrshareConnectionsQuery.data)
  const userChannels = getUserChannels(ayrshareConnectionsQuery.data)

  const lookForm = useForm<FormState>({
    defaultValues,
    values: {
      ...defaultValues,
      selectedChannels: {
        ...accountChannels,
        ...(Object.keys(userChannels).length
          ? userChannels
          : { uninitUser: ['oneshop'] }),
      },
    },
    resolver: zodResolver(createPostInputSchema),
  })
  const { fields, remove, replace } = useFieldArray({
    control: lookForm.control,
    name: 'selectedProducts',
  })

  const createBaseLook = useCreateBaseLook()
  const createVideoPost = useCreateVideoPost()
  const shareLook = useShareLook()

  const isShareDisabled =
    createVideoPost.isLoading ||
    (postMedia?.type === 'collage' && fields.length === 0)

  function handleProductRemove(id: number) {
    remove(id)
  }

  async function handleCreatePost(data: FormState) {
    const { description, isPublic, date, selectedChannels, selectedProducts } =
      data

    const noSocialChannelsSelected = Object.values(selectedChannels).every(
      (sc) => !sc.length
    )

    if (noSocialChannelsSelected)
      return setToast({ message: 'You must share to at least one channel.' })

    if (!postMedia)
      return setToast({
        message:
          'Something went wrong - there is nothing to post - no media is associated with this post.',
      })

    if (
      postMedia.type !== 'video' &&
      Object.values(selectedChannels).some((platforms) =>
        platforms.includes('tiktok')
      )
    ) {
      return setToast({
        message:
          'You cannot share an image to TikTok. Please deselct that channel.',
      })
    }

    // Notify user immediately that a post is in process
    setToast({
      message:
        'Congrats! Your post has started sharing and should be done shortly!',
      color: 'yellow',
    })

    const products = selectedProducts.map((p) => p.objectId)
    const isPrivate = !isPublic
    const resource_type =
      postMedia.type === 'video' ? ResourceType.Video : ResourceType.Image

    const socialConnections = getSocialConnections(selectedChannels)

    createBaseLook.mutate(
      {
        isPrivate,
        cloudinary: { resource_type },
        products,
        ...(postMedia.type === 'photo' && { image: postMedia.coverPhoto }),
      },
      {
        onSuccess: (data) => {
          const { objectId: lookId } = data

          router.push('/tabs/home', 'back', 'pop')

          if (postMedia.type === 'video') {
            createVideoPost.mutate({
              lookId,
              description,
              date,
              socialConnections,
              video: postMedia.video,
            })
          } else {
            shareLook.mutate({
              lookId,
              description,
              date,
              socialConnections,
            })
          }
        },
      }
    )
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>New Post</IonTitle>
          <IonButtons slot="start">
            <IonButton
              color="secondary"
              onClick={() => {
                resetPost()
                router.push('/tabs/home', 'back', 'pop')
              }}
            >
              Cancel
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton
              color="yellow"
              disabled={isShareDisabled}
              onClick={lookForm.handleSubmit(handleCreatePost)}
            >
              Share
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      {Object.keys(account).length ? (
        <IonContent>
          {/* Post Form */}
          <IonRow className="ion-padding-horizontal">
            <IonCol size="5" sizeLg="3">
              {postMedia?.type === 'collage' ? (
                <div className="aspect-h-4 aspect-w-3 w-full">
                  <CollageItem items={fields} />
                </div>
              ) : postMedia?.type === 'video' ? (
                <div className="aspect-h-16 aspect-w-9 w-full">
                  <VideoPreview video={postMedia.video} />
                </div>
              ) : postMedia?.type === 'photo' ? (
                <div className="aspect-h-4 aspect-w-3 w-full">
                  <IonImg src={postMedia.coverPhoto.pic ?? placeholder} />
                </div>
              ) : null}
            </IonCol>
            <IonCol size="7" sizeLg="5" offsetLg="1">
              <IonItem style={{ '--padding-start': 0 }} lines="none">
                <div className="px-2 pb-2">
                  <IonLabel
                    position="stacked"
                    color={
                      lookForm.formState.errors.description
                        ? 'danger'
                        : 'secondary'
                    }
                  >
                    Description (required)
                  </IonLabel>
                  <Controller
                    name="description"
                    control={lookForm.control}
                    render={({ field: { value, onChange } }) => (
                      <TextArea
                        style={{
                          '--background': '#343434',
                          '--padding-end': '.5rem',
                          '--padding-start': '.5rem',
                        }}
                        className={clsx([
                          'overflow-y-auto',
                          lookForm.formState.errors.description &&
                            'ring-ion-color-danger ring-2',
                        ])}
                        rows={4}
                        value={value}
                        onIonChange={onChange}
                        autocapitalize="on"
                      />
                    )}
                  />
                </div>
              </IonItem>

              <IonItem lines="none" style={{ '--padding-start': 0 }}>
                <Controller
                  name="isPublic"
                  control={lookForm.control}
                  render={({ field: { value, onChange } }) => (
                    <IonCheckbox
                      checked={value}
                      onIonChange={(e) => onChange(e.detail.checked)}
                      style={{ '--size': '1rem' }}
                    />
                  )}
                />
                <IonLabel>Create Team Asset</IonLabel>
              </IonItem>
            </IonCol>
          </IonRow>

          {/* Product List */}
          <ul className="ion-padding-horizontal flex flex-wrap gap-2">
            {fields.map((field, index) => (
              <li key={field.id} className="relative h-28 w-20">
                <IonImg
                  src={field.featuredImage ?? placeholder}
                  className="object-cover object-top"
                />
                <CloseButton inner onClick={() => handleProductRemove(index)} />
              </li>
            ))}
          </ul>
          <div className="ion-padding-horizontal ion-padding-top space-y-4">
            <AddProducts
              fields={fields}
              onClose={(selectedProducts) => {
                replace(selectedProducts)
              }}
            />

            {/* Schedule Post */}
            {!account.roleSocial.requiresApproval ? (
              <Controller
                name="date"
                control={lookForm.control}
                render={({ field: { value, onChange } }) => (
                  <SchedulePicker
                    label="Schedule Post"
                    value={value}
                    onChange={onChange}
                    onReset={() => lookForm.setValue('date', '')}
                  />
                )}
              />
            ) : null}
          </div>

          {/* <SocialChannels /> */}
          <>
            {ayrshareConnectionsQuery.data ? (
              <div className="ion-padding-horizontal ion-margin-top">
                {ayrshareConnectionsQuery.data
                  .filter(
                    (ayrshare) =>
                      ayrshare.level === 'account' || ayrshare.level === 'group'
                  )
                  .map((ayrshareConnection) => (
                    <React.Fragment key={ayrshareConnection.objectId}>
                      <h2>{ayrshareConnection.name}</h2>
                      <div className="flex space-x-4">
                        <SocialChannelSelector
                          ayrshareConnection={ayrshareConnection}
                          control={lookForm.control}
                          name={`selectedChannels.${ayrshareConnection.objectId}`}
                        />
                      </div>
                    </React.Fragment>
                  ))}

                <h2>{user.displayName} - Business</h2>
                {ayrshareConnectionsQuery.data.filter(
                  (ayrshare) =>
                    ayrshare.type === 'business' && ayrshare.level === 'user'
                ).length > 0 ? (
                  <>
                    {ayrshareConnectionsQuery.data
                      .filter(
                        (ayrshare) =>
                          ayrshare.type === 'business' &&
                          ayrshare.level === 'user'
                      )
                      .map((ayrshareConnection, index) => (
                        <React.Fragment key={ayrshareConnection.objectId}>
                          <SocialChannelSelector
                            ayrshareConnection={ayrshareConnection}
                            control={lookForm.control}
                            name={`selectedChannels.${ayrshareConnection.objectId}`}
                            shareToOneShop={index === 0}
                          />
                        </React.Fragment>
                      ))}
                  </>
                ) : (
                  <SocialChannelSelector
                    control={lookForm.control}
                    name="selectedChannels.uninitUser"
                    shareToOneShop
                  />
                )}
              </div>
            ) : null}
          </>
        </IonContent>
      ) : (
        <IonLoading isOpen message="Loading..." />
      )}

      <IonLoading
        isOpen={createBaseLook.isLoading}
        message="Creating Your Post..."
      />
    </IonPage>
  )
}

function useProductList() {
  const [isProductListOpen, setIsProductListOpen] = React.useState(false)

  const handlers = React.useMemo(
    () => ({
      open: () => setIsProductListOpen(true),
      close: () => setIsProductListOpen(false),
    }),
    []
  )

  return [isProductListOpen, handlers] as const
}

function AddProducts({
  fields,
  onClose,
}: {
  fields: Array<Product>
  onClose: (p: Array<Product>) => void
}) {
  const [isProductListOpen, { open, close }] = useProductList()

  return (
    <>
      <IonButton expand="block" color="tertiary" onClick={open}>
        Add Products
      </IonButton>
      <IonModal isOpen={isProductListOpen} onDidDismiss={close}>
        <SelectProductsModal
          products={fields}
          onCancel={close}
          onClose={(stuff) => {
            onClose(stuff)
            close()
          }}
        />
      </IonModal>
    </>
  )
}

export default CreatePostPage

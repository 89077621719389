import * as React from 'react'
import {
  IonPage,
  IonContent,
  IonLoading,
  IonSegment,
  IonSegmentButton,
  IonGrid,
  IonRow,
  IonCol,
  IonHeader,
  IonButton,
  IonModal,
  IonToolbar,
  IonButtons,
  IonIcon,
} from '@ionic/react'
import { useParams } from 'react-router-dom'
import { ellipsisHorizontal } from 'ionicons/icons'
import { Tab } from '@headlessui/react'

import { useClientDetail } from '../../hooks/clients/queries'
import { useClipboard } from '../../hooks/use-clipboard'
import { useShopWithLinks } from '../../hooks/shopwith/use-shopwith-links'
import { useQueryParams } from '../../hooks/use-query-params'
import Header from '../../components/header'
import ClientDetailFeed from '../../components/feed/ClientDetailFeed'
import AddFab from '../../components/fab/add-fab'
import Item from '../../components/item/Item'
import ClientInfoDisplay from '../../components/header/ClientInfoDisplay'
import ClientDetailHeader from '../../components/client/ClientDetailHeader'
import ProductItem from '../../components/shopwith/product-item'
import { Chip } from '../../components/ui/buttons/chip'

const clientViews = ['closet', 'feed', 'wishlist'] as const
type ClientView = typeof clientViews[number]

const isClientView = (str: string | null): str is ClientView =>
  str !== null && clientViews.some((view) => view === str)

function useCurrentView() {
  const queryParams = useQueryParams()

  const currentView = queryParams.get('currentview')
  return React.useMemo(
    () => (isClientView(currentView) ? currentView : null),
    [currentView]
  )
}

const ClientDetail = () => {
  const currentView = useCurrentView()

  const [view, setView] = React.useState<ClientView>(currentView ?? 'feed')
  const [showContactDetails, setShowContactDetails] = React.useState(false)
  const [isWishlistLinkDisabled, setIsWishlistLinkDisabled] =
    React.useState(false)

  const { id } = useParams<{ id: string }>()
  const clientDetailQuery = useClientDetail(id)

  const { copyToClipboard } = useClipboard()
  const { generateShopWithLinkPreview } = useShopWithLinks()

  // only reset segment to closet if no view set in search query
  React.useEffect(() => {
    if (clientDetailQuery.data?.closet.length && !currentView) {
      // hack - w/o timeout sometimes segment doesn't properly set
      setTimeout(() => setView('closet'), 10)
    }
  }, [clientDetailQuery.data?.closet.length, currentView])

  function copyWishlistLink() {
    if (!clientDetailQuery.data?.likes.map((like) => like.product.objectId))
      return

    copyToClipboard(
      generateShopWithLinkPreview(
        'productIds',
        clientDetailQuery.data?.likes.map((like) => like.product.objectId)
      ),
      'Wishlist link copied to clipboard.'
    )
  }

  return (
    <IonPage>
      <AddFab />
      <IonHeader>
        <Header
          backRef="/tabs/clients"
          title={`${
            clientDetailQuery.data?.ClientDetailsData.firstName || ''
          } ${clientDetailQuery.data?.ClientDetailsData.lastName || ''}`}
          btn={
            <IonButton onClick={() => setShowContactDetails(true)}>
              <IonIcon
                slot="icon-only"
                color="secondary"
                icon={ellipsisHorizontal}
              />
            </IonButton>
          }
        />
      </IonHeader>
      {clientDetailQuery.data && (
        <>
          <IonContent>
            <ClientDetailHeader
              client={clientDetailQuery.data.ClientDetailsData}
              mode="dark"
            />

            <div className="ion-padding">
              <IonSegment
                value={view}
                onIonChange={(e) => setView(e.detail.value as ClientView)}
              >
                {clientViews.map((view) => {
                  if (
                    view === 'closet' &&
                    !clientDetailQuery.data.closet.length
                  ) {
                    return null
                  }

                  return (
                    <IonSegmentButton key={view} value={view}>
                      {view.toUpperCase()}
                    </IonSegmentButton>
                  )
                })}
              </IonSegment>
            </div>

            {view === 'feed' && (
              <ClientDetailFeed
                purchases={clientDetailQuery.data.purchases.slice(0, 20)}
                messages={clientDetailQuery.data.messages.slice(0, 20)}
                notes={clientDetailQuery.data.notes.slice(0, 20)}
                tasks={clientDetailQuery.data.tasks.slice(0, 20)}
                posNotes={clientDetailQuery.data.posNotes.slice(0, 20)}
              />
            )}

            {view === 'closet' && (
              <IonGrid>
                <IonRow>
                  {clientDetailQuery.data.closet.map((item: any) => (
                    <IonCol
                      style={{ display: 'flex', flexDirection: 'column' }}
                      key={item.objectId}
                      sizeXs="6"
                      sizeSm="4"
                      sizeMd="3"
                    >
                      <Item item={item} />
                    </IonCol>
                  ))}
                </IonRow>
              </IonGrid>
            )}

            {view === 'wishlist' && (
              <Tab.Group
                defaultIndex={0}
                onChange={(index) => setIsWishlistLinkDisabled(index === 1)}
              >
                <div className="ion-padding-horizontal flex items-center justify-between">
                  <Tab.List className="flex gap-x-2">
                    <Tab as={React.Fragment}>
                      {({ selected }) => (
                        <Chip fill={selected ? 'solid' : 'outline'}>Likes</Chip>
                      )}
                    </Tab>
                    <Tab as={React.Fragment}>
                      {({ selected }) => (
                        <Chip fill={selected ? 'solid' : 'outline'}>
                          Dislikes
                        </Chip>
                      )}
                    </Tab>
                  </Tab.List>
                  <IonButton
                    disabled={
                      isWishlistLinkDisabled ||
                      !clientDetailQuery.data.likes.map(
                        (like) => like.product.objectId
                      ).length
                    }
                    color="yellow"
                    fill="outline"
                    onClick={copyWishlistLink}
                  >
                    Copy Link
                  </IonButton>
                </div>
                <Tab.Panels>
                  <Tab.Panel>
                    <IonGrid>
                      <IonRow>
                        {clientDetailQuery.data.likes.length ? (
                          <>
                            {clientDetailQuery.data.likes
                              .filter((like) => like.like)
                              .map((like) => (
                                <IonCol
                                  key={like.objectId}
                                  className="flex flex-col"
                                  sizeXs="6"
                                  sizeSm="4"
                                  sizeMd="3"
                                >
                                  <ProductItem product={like.product} />
                                </IonCol>
                              ))}
                          </>
                        ) : (
                          <IonCol>
                            This client has not started a wishlist yet.
                          </IonCol>
                        )}
                      </IonRow>
                    </IonGrid>
                  </Tab.Panel>
                  <Tab.Panel>
                    <IonGrid>
                      <IonRow>
                        {clientDetailQuery.data.likes.length ? (
                          <>
                            {clientDetailQuery.data.likes
                              .filter((like) => !like.like)
                              .map((like) => (
                                <IonCol
                                  key={like.objectId}
                                  className="flex flex-col"
                                  sizeXs="6"
                                  sizeSm="4"
                                  sizeMd="3"
                                >
                                  <ProductItem product={like.product} />
                                </IonCol>
                              ))}
                          </>
                        ) : (
                          <IonCol>
                            This client has not started a wishlist yet.
                          </IonCol>
                        )}
                      </IonRow>
                    </IonGrid>
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            )}
          </IonContent>

          <IonModal
            isOpen={showContactDetails}
            onDidDismiss={() => setShowContactDetails(false)}
          >
            <IonHeader>
              <IonToolbar>
                <IonButtons>
                  <IonButton
                    onClick={() => setShowContactDetails(false)}
                    color="secondary"
                    slot="end"
                  >
                    CLOSE
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <ClientInfoDisplay clientId={id} />
            </IonContent>
          </IonModal>
        </>
      )}

      <IonLoading
        isOpen={clientDetailQuery.isLoading}
        message={'Loading Client...'}
      />
    </IonPage>
  )
}

export default ClientDetail

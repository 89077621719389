import * as React from 'react'
import { useRouteMatch } from 'react-router-dom'
import {
  IonInput,
  IonRow,
  IonCol,
  IonButton,
  IonImg,
  IonIcon,
  IonItem,
  IonLabel,
  useIonRouter,
} from '@ionic/react'
import { close } from 'ionicons/icons'
import { RadioGroup } from '@headlessui/react'
import { useFormContext, useWatch, Controller } from 'react-hook-form'
import { InformationCircleIcon } from '@heroicons/react/24/solid'

import { useSmsMaxCharacterCount } from '../../utils/forms'
import { useToast } from '../../contexts/toastContext'
import SimpleSelectPhoto from '../../components/communication/SimpleSelectPhoto'
import TakePhoto from '../../components/communication/TakePhoto'
import { Card, CardContent } from '../../components/ui/card'
import {
  MergeFieldButton,
  MergeFieldButtons,
} from '../../components/ui/form/merge-field-buttons'
import { TextArea } from '../../components/ui/form/textarea'

import { cn } from '../../utils/cn'
import { useDeleteTriggerStepHtmlEmail } from '../mutations'

import type { TriggerStep as TTriggerStep } from '../automations-base.schema'
import type { UpdateAutomationInput } from '../automations-form.schema'

type RadioOption = React.PropsWithChildren<{ value: string }>

function TriggerStep({
  disabled,
  index,
  step,
}: {
  disabled: boolean
  index: number
  step?: TTriggerStep
}) {
  const smsMessageRef = React.useRef<HTMLIonTextareaElement | null>(null)
  const emailMessageRef = React.useRef<HTMLIonTextareaElement | null>(null)

  const { url } = useRouteMatch()
  const router = useIonRouter()

  const { getSmsMaxCharacterCount } = useSmsMaxCharacterCount()

  const { control, setValue, getValues } =
    useFormContext<UpdateAutomationInput>()
  const triggerStepId = getValues().steps[index].objectId

  const watchAutomationState = useWatch({ control, name: 'state' })
  const watchSmsAttachments = useWatch({
    control,
    name: `steps.${index}.attachmentsSms`,
  })
  const watchEmailAttachments = useWatch({
    control,
    name: `steps.${index}.attachmentsEmail`,
  })

  const setToast = useToast()

  const resetEmail = useDeleteTriggerStepHtmlEmail()

  const canUpdateEmail = watchAutomationState !== 'active'
  const htmlPreview = step?.unlayerImage

  return (
    <>
      {/* Delay */}
      <div className="flex items-center justify-start gap-3">
        <p className="font-semibold">Delay</p>
        <Controller
          name={`steps.${index}.delayAmount`}
          control={control}
          render={({ field: { value, onChange } }) => (
            <IonInput
              disabled={disabled}
              type="number"
              style={{
                '--padding-start': '.25rem',
                '--padding-top': '.25rem',
                '--padding-end': 0,
                '--padding-bottom': '.25rem',
              }}
              className="bg-ion-color-step-400 rounded-md"
              value={value}
              onIonChange={(e) => onChange(+e.detail.value!)}
            />
          )}
        />
        <Controller
          name={`steps.${index}.delayUnit`}
          control={control}
          render={({ field: { value, onChange } }) => (
            <RadioGroup
              disabled={disabled}
              value={value}
              onChange={onChange}
              className="border-ion-color-step-950 flex items-center justify-center rounded border"
            >
              <DelayOption value="minutes">Minutes</DelayOption>
              <DelayOption value="hours">Hours</DelayOption>
              <DelayOption value="days">Days</DelayOption>
            </RadioGroup>
          )}
        />
      </div>

      {/* Message Type */}
      <div className="flex flex-col gap-2">
        <p className="font-semibold">Send Via</p>
        <Controller
          name={`steps.${index}.messageType`}
          control={control}
          render={({ field: { value, onChange } }) => (
            <RadioGroup
              disabled={disabled}
              value={value}
              onChange={onChange}
              className="border-ion-color-step-950 flex items-center gap-5"
            >
              <SendViaOption value="sms">Text</SendViaOption>
              <SendViaOption value="email">Email</SendViaOption>
              <SendViaOption value="prioSmsEmail">Text or Email</SendViaOption>
            </RadioGroup>
          )}
        />

        <InfoGraphic>
          When "Text or Email" is selected, text message is prioritized.
        </InfoGraphic>
      </div>

      {/* SMS */}
      <div>
        <h2>Text Message</h2>
        <div className="py-3">
          <Card>
            <CardContent>
              <Controller
                name={`steps.${index}.smsMessage`}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <>
                    <TextArea
                      ref={smsMessageRef}
                      className="min-h-16.5"
                      placeholder="Write your text message template here..."
                      autoGrow
                      autocapitalize="on"
                      value={value}
                      onIonChange={(e) => onChange(e.detail.value ?? '')}
                      disabled={disabled}
                    />

                    <IonRow className="ion-align-items-center ion-justify-content-between">
                      <IonCol size="auto">
                        <MergeFieldButtons
                          mergeRef={smsMessageRef}
                          onInsert={onChange}
                        >
                          <MergeFieldButton placeholder="<First Name>">
                            First Name
                          </MergeFieldButton>
                          <MergeFieldButton placeholder="<Last Name>">
                            Last Name
                          </MergeFieldButton>
                          <MergeFieldButton placeholder="<Lifetime Loyalty Points>">
                            Lifetime Loyalty Points
                          </MergeFieldButton>
                          <MergeFieldButton placeholder="<Active Loyalty Points>">
                            Active Loyalty Points
                          </MergeFieldButton>
                          <MergeFieldButton placeholder="<Active Loyalty Visits>">
                            Active Loyalty Visits
                          </MergeFieldButton>
                          <MergeFieldButton placeholder="<Active Loyalty Rewards>">
                            Active Loyalty Rewards
                          </MergeFieldButton>
                        </MergeFieldButtons>
                      </IonCol>
                      <IonCol size="auto">
                        <p
                          className={cn(
                            value &&
                              value.length > getSmsMaxCharacterCount(value)
                              ? 'text-ion-color-danger'
                              : 'text-ion-color-dark'
                          )}
                        >
                          {value ? value.length : 0}/
                          {getSmsMaxCharacterCount(value ?? '')}
                        </p>
                      </IonCol>
                    </IonRow>
                  </>
                )}
              />

              {watchSmsAttachments?.length ? (
                <div className="flex items-center justify-between">
                  <IonImg
                    src={
                      typeof watchSmsAttachments[0] === 'string'
                        ? watchSmsAttachments[0]
                        : watchSmsAttachments[0].pic
                    }
                    className="h-20"
                  />
                  <IonButton
                    fill="clear"
                    size="small"
                    onClick={() =>
                      setValue(`steps.${index}.attachmentsSms`, [])
                    }
                  >
                    <IonIcon icon={close} />
                  </IonButton>
                </div>
              ) : null}
            </CardContent>
          </Card>
          <Controller
            name={`steps.${index}.attachmentsSms`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <div className="flex justify-end gap-x-3 py-3">
                <TakePhoto onTakePhoto={(e) => onChange([e])} />
                <SimpleSelectPhoto onSelectPhoto={(e) => onChange([e])} />
              </div>
            )}
          />
        </div>
      </div>

      {/* EMAIL */}
      <div>
        <div className="py-3">
          <div className="flex items-center justify-between py-1">
            <div>
              <h2>Email</h2>
            </div>
            <div className="justify-center">
              <IonButton
                size="small"
                color="yellow"
                onClick={() => {
                  if (!triggerStepId) {
                    return setToast({
                      message:
                        'You must save the automation before you can design an email.',
                    })
                  }
                  if (!canUpdateEmail)
                    return setToast({
                      message:
                        'You must stop the automation before making changes to the email message.',
                    })

                  router.push(
                    `${url}/step/${triggerStepId}/html-email?displayConditions=${
                      getValues().fromUserRule === 'assignedTo'
                        ? 'show'
                        : 'hide'
                    }`,
                    'forward',
                    'push'
                  )
                }}
              >
                {htmlPreview ? 'Edit Email' : 'Design Email'}
              </IonButton>
            </div>
          </div>
          <Card>
            <CardContent>
              <IonItem style={{ '--padding-start': 0 }} color="secondary">
                <IonLabel>Subject: </IonLabel>
                <Controller
                  name={`steps.${index}.emailSubject`}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <IonInput
                      value={value}
                      onIonChange={(e) => onChange(e.detail.value ?? '')}
                      autocapitalize="on"
                      disabled={disabled}
                    />
                  )}
                />
              </IonItem>

              <div className="ion-padding-top">
                <div className="flex justify-end">
                  <IonButton
                    size="small"
                    fill="outline"
                    onClick={() => {
                      if (!canUpdateEmail)
                        return setToast({
                          message:
                            'You must stop the automation before making changes to the email message.',
                        })

                      setValue(`steps.${index}.emailMessage`, '')
                      resetEmail.mutate({
                        objectId: triggerStepId,
                      })
                    }}
                  >
                    Clear
                  </IonButton>
                </div>
                {htmlPreview ? (
                  <IonImg alt="Email Preview" src={htmlPreview} />
                ) : (
                  <>
                    <Controller
                      name={`steps.${index}.emailMessage`}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <>
                          <TextArea
                            ref={emailMessageRef}
                            className="min-h-16.5"
                            placeholder="Write your email message template here..."
                            autoGrow
                            autocapitalize="on"
                            value={value}
                            onIonChange={(e) => onChange(e.detail.value ?? '')}
                            disabled={disabled}
                          />

                          <MergeFieldButtons
                            mergeRef={emailMessageRef}
                            onInsert={onChange}
                          >
                            <MergeFieldButton placeholder="<First Name>">
                              First Name
                            </MergeFieldButton>
                            <MergeFieldButton placeholder="<Last Name>">
                              Last Name
                            </MergeFieldButton>
                            <MergeFieldButton placeholder="<Lifetime Loyalty Points>">
                              Lifetime Loyalty Points
                            </MergeFieldButton>
                            <MergeFieldButton placeholder="<Active Loyalty Points>">
                              Active Loyalty Points
                            </MergeFieldButton>
                            <MergeFieldButton placeholder="<Active Loyalty Visits>">
                              Active Loyalty Visits
                            </MergeFieldButton>
                            <MergeFieldButton placeholder="<Active Loyalty Rewards>">
                              Active Loyalty Rewards
                            </MergeFieldButton>
                          </MergeFieldButtons>
                        </>
                      )}
                    />
                    {watchEmailAttachments?.length ? (
                      <div className="flex items-center justify-between">
                        <IonImg
                          src={
                            typeof watchEmailAttachments[0] === 'string'
                              ? watchEmailAttachments[0]
                              : watchEmailAttachments[0].pic
                          }
                          className="h-20"
                        />
                        <IonButton
                          fill="clear"
                          size="small"
                          onClick={() =>
                            setValue(`steps.${index}.attachmentsEmail`, [])
                          }
                        >
                          <IonIcon icon={close} />
                        </IonButton>
                      </div>
                    ) : null}
                  </>
                )}
              </div>
            </CardContent>
          </Card>

          {!htmlPreview ? (
            <Controller
              name={`steps.${index}.attachmentsEmail`}
              control={control}
              render={({ field: { value, onChange } }) => (
                <div className="flex justify-end gap-x-3 py-3">
                  <TakePhoto onTakePhoto={(e) => onChange([e])} />
                  <SimpleSelectPhoto onSelectPhoto={(e) => onChange([e])} />
                </div>
              )}
            />
          ) : null}
        </div>
      </div>
    </>
  )
}

function DelayOption({ children, value }: RadioOption) {
  return (
    <RadioGroup.Option
      value={value}
      className="ui-checked:bg-ion-color-yellow cursor-pointer rounded py-2 ui-disabled:cursor-default"
    >
      <span className="text-ion-color-secondary ui-checked:text-ion-color-primary flex px-3 leading-3">
        {children}
      </span>
    </RadioGroup.Option>
  )
}

function SendViaOption({ children, value }: RadioOption) {
  return (
    <RadioGroup.Option
      value={value}
      className="flex cursor-pointer items-center rounded-full ui-disabled:cursor-default"
    >
      <span className="border-ion-color-secondary ui-checked:bg-ion-color-yellow size-4 rounded-full border" />
      <span className="flex cursor-pointer items-center px-1.5 leading-3">
        {children}
      </span>
    </RadioGroup.Option>
  )
}

function InfoGraphic({ children }: { children: React.ReactNode }) {
  return (
    <div className="border-ion-color-secondary rounded-lg border px-3 py-2">
      <div className="flex items-center gap-2">
        <InformationCircleIcon className="text-ion-color-secondary size-5 flex-shrink-0" />
        <p className="italic leading-none">{children}</p>
      </div>
    </div>
  )
}

export { TriggerStep }

import { z } from 'zod'

import {
  makePaginatedServerResponseCountSchema,
  nullableBoolean,
  nullableString,
  serverItemSchema,
} from '../../utils/schema'

type UserRoleSocial = z.infer<typeof userRoleSocialSchema>

const userRoleSocialSchema = serverItemSchema.extend({
  displayName: nullableString,
  firstName: nullableString,
  lastName: nullableString,
  photo: nullableString,
  roleSocial: z.nullable(
    z.object({
      objectId: nullableString,
      role: nullableString,
      requiresApproval: nullableBoolean,
    })
  ),
})

const fetchUserRoleSocialsSchema = makePaginatedServerResponseCountSchema(
  z.array(userRoleSocialSchema)
)

export type { UserRoleSocial }
export { fetchUserRoleSocialsSchema, userRoleSocialSchema }

import * as React from 'react'
import { IonSelect, IonSelectOption } from '@ionic/react'
import { AsYouType } from 'libphonenumber-js'
import {
  getCountries,
  getCountryCallingCode,
} from 'react-phone-number-input/input'
import en from 'react-phone-number-input/locale/en.json'

import { cn } from '../../utils/cn'
import { useIsStore } from '../../auth/utils'
import {
  examplePhoneNumbers,
  touchScreenPad,
} from '../../marketing/customer-opt-in/constants'

import type { CountryCode } from 'libphonenumber-js/types'
import type { TouchPad } from '../../marketing/customer-opt-in/constants'
import { CheckInButton } from './buttons/check-in-button'

type PhoneKeypadOnConfirm = {
  phoneNumber: string
  country: CountryCode
}

type Props = {
  altLabel?: string
  disabled?: boolean
  onConfirm: ({ phoneNumber, country }: PhoneKeypadOnConfirm) => void
}

function PhoneKeypadInput({ altLabel, disabled, onConfirm }: Props) {
  const { isTwb } = useIsStore()
  const [country, setCountry] = React.useState<CountryCode>(
    isTwb() ? 'CA' : 'US'
  )
  const [phoneNumber, setPhoneNumber] = React.useState('')

  const formattedPhoneNumber = new AsYouType(country).input(phoneNumber)

  function handleButtonClick(value: TouchPad) {
    if (disabled) return

    switch (value) {
      case '0':
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '6':
      case '7':
      case '8':
      case '9': {
        if (phoneNumber.length >= examplePhoneNumbers[country].length) return
        if (phoneNumber !== '') {
          setPhoneNumber((prev) => prev + value)
        } else {
          setPhoneNumber(value)
        }
        break
      }
      case '←':
        const newNumber =
          phoneNumber.length > 1
            ? phoneNumber.slice(0, phoneNumber.length - 1)
            : ''
        setPhoneNumber(newNumber)
        break
    }
  }

  return (
    <div className="bg-ion-color-secondary flex flex-col items-center gap-y-3 rounded-2xl p-2">
      <div className="flex w-96 gap-4">
        {/* Country Select */}
        <div className="bg-ion-color-secondary relative size-12 flex-shrink-0 rounded-lg pl-2 pr-1">
          <div className="relative flex size-full">
            <IonSelect
              disabled={disabled}
              className="absolute inset-0 size-full rounded bg-transparent"
              value={country}
              onIonChange={(e) => setCountry(e.detail.value)}
              selectedText=" "
            >
              {getCountries().map((country) => (
                <IonSelectOption key={country} value={country}>
                  {en[country]} +{getCountryCallingCode(country)}
                </IonSelectOption>
              ))}
            </IonSelect>
            <div className="text-ion-color-primary flex w-full items-center gap-1">
              <img
                className=""
                alt=""
                src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${country}.svg`}
              />
            </div>
          </div>
        </div>
        {/* Phone Number Display */}
        <div className="text-ion-color-primary flex h-12 flex-full items-center justify-start overflow-hidden rounded-lg px-2 text-4xl">
          {formattedPhoneNumber}
        </div>
      </div>

      {/* Keypad */}
      <div className="grid w-80 grid-cols-3 flex-wrap justify-center gap-x-4 gap-y-4">
        {touchScreenPad.map((n) => (
          <div key={n.id} className="flex flex-col items-center justify-center">
            <button
              disabled={disabled}
              className={cn(
                n.id === 'empty' && 'pointer-events-none opacity-0',
                'border-ion-color-primary text-ion-color-primary size-20 rounded-full border py-2 text-2xl disabled:opacity-30'
              )}
              onClick={() => handleButtonClick(n.value)}
            >
              {n.value}
            </button>
          </div>
        ))}
      </div>

      <CheckInButton
        onClick={() => onConfirm({ phoneNumber, country })}
        disabled={disabled}
        label={altLabel ?? 'Sign Up'}
      />
    </div>
  )
}

export type { PhoneKeypadOnConfirm }
export { PhoneKeypadInput }

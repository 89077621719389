import { IonSkeletonText } from '@ionic/react'
import { Link } from 'react-router-dom'

import { useOutreachByFilter } from '../../../engage/campaigns/queries'
import { Card, CardTitle, CardContent, CardHeader } from '../../ui/card'
import ScrollingList from '../../ui/scrolling-list'
import { SectionContent, SectionHeader, SectionWrapper } from '../section'
import { OutreachCard } from './outreach-card'

import type { Filter } from '../../../engage/campaigns/queries'
import type { Campaign } from '../../../engage/campaigns/campaigns.schema'

type Props = {
  filter: Filter
  title: string
}

function OutreachListSkeleton() {
  return (
    <div className="ml-3 flex">
      {Array(10)
        .fill(null)
        .map((_i, idx) => (
          <div key={idx} className="mr-3 w-36">
            <Card>
              <CardHeader>
                <CardTitle>
                  <IonSkeletonText
                    animated
                    style={{
                      '--background': 'rgba(0,0,0,.65)',
                      '--background-rgb': '0,0,0',
                    }}
                    className="m-0 size-full"
                  />
                </CardTitle>
              </CardHeader>
              <CardContent>
                <p className="mx-auto mt-auto w-2/5 pt-2 text-center text-sm leading-none">
                  <IonSkeletonText
                    animated
                    style={{
                      '--background': 'rgba(0,0,0,.65)',
                      '--background-rgb': '0,0,0',
                    }}
                    className="m-0 size-full"
                  ></IonSkeletonText>
                </p>
              </CardContent>
            </Card>
          </div>
        ))}
    </div>
  )
}

function OutreachSection({ filter, title }: Props) {
  const outreachList = useOutreachByFilter({ filter, permission: 'associate' })
  const flatOutreachList = outreachList.data?.pages.flatMap((d) => d.data) ?? []

  return (
    <>
      {outreachList.data ? (
        <>
          {Boolean(flatOutreachList?.length) ? (
            <SectionWrapper>
              <SectionHeader title={title} />
              <SectionContent>
                {filter === 'onetime' ? (
                  <OneTimeOutreach outreachList={flatOutreachList} />
                ) : filter === 'ongoing' ? (
                  <OngoingOutreach outreachList={flatOutreachList} />
                ) : null}
              </SectionContent>
            </SectionWrapper>
          ) : null}
        </>
      ) : (
        <OutreachListSkeleton />
      )}
    </>
  )
}

function OneTimeOutreach({ outreachList }: { outreachList: Array<Campaign> }) {
  return (
    <ScrollingList>
      {outreachList.map((outreach) => {
        // required since we don't discriminate campaign return
        if (outreach.className === 'Broadcast') return null

        return (
          <Link
            key={outreach.objectId}
            to={`/tabs/clients/outreach/${outreach.objectId}`}
            className="flex w-36"
          >
            <OutreachCard key={outreach.objectId} outreach={outreach} />
          </Link>
        )
      })}
    </ScrollingList>
  )
}

function OngoingOutreach({ outreachList }: { outreachList: Array<Campaign> }) {
  return (
    <div className="grid grid-cols-2 gap-3">
      {outreachList.map((outreach) => {
        // required since we don't discriminate campaign return
        if (outreach.className === 'Broadcast') return null

        return (
          <Link
            key={outreach.objectId}
            to={`/tabs/clients/outreach/${outreach.objectId}`}
            className=""
          >
            <OutreachCard key={outreach.objectId} outreach={outreach} />
          </Link>
        )
      })}
    </div>
  )
}

export default OutreachSection

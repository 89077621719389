import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonRow,
} from '@ionic/react'

type Props = {
  outreach1: number
  outreach7: number
  outreach30: number
}

function RecapCard({ outreach1, outreach7, outreach30 }: Props) {
  return (
    <IonCard color="secondary" style={{ margin: '16px' }}>
      <IonCardHeader style={{ textAlign: 'center' }}>
        <IonCardTitle>COMMUNICATION</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonRow className="ion-justify-content-around">
          <h1>TODAY</h1>
          <h1>7 DAYS</h1>
          <h1>30 DAYS</h1>
        </IonRow>
        <IonRow className="ion-justify-content-around">
          <h1>{outreach1}</h1>
          <h1>{outreach7}</h1>
          <h1>{outreach30}</h1>
        </IonRow>
      </IonCardContent>
    </IonCard>
  )
}

export { RecapCard }

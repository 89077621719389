import { cn } from '../utils/cn'

type Props = { children: React.ReactNode; invert?: boolean }

function Badge({ children, invert = false }: Props) {
  return (
    <div className="absolute -right-1 top-0">
      <div
        className={cn(
          'text-ion-color-primary flex h-[18px] min-w-[18px] items-center justify-center rounded-full text-[11px] font-semibold',
          invert ? 'bg-ion-color-secondary' : 'bg-ion-color-yellow'
        )}
      >
        <span className="px-1 py-0">{children}</span>
      </div>
    </div>
  )
}

export default Badge

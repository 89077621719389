import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { zodResolver } from '@hookform/resolvers/zod'

import { useToast } from '../../contexts/toastContext'
import { StyledError } from '../../components/ui/form/errors'
import { useCustomerOptInClientId } from '../../marketing/customer-opt-in/utils'
import { formDataSchema } from '../actions'
import { useCreateSale } from '../mutations'
import { LoyaltyInput } from './loyalty-input'

import type { FormData } from '../actions'

type FormInputs = FormData
function NonIntegratedSaleForm() {
  const clientId = useCustomerOptInClientId()

  const setToast = useToast()

  const createSale = useCreateSale()

  const {
    formState: { errors },
    reset,
    ...formMethods
  } = useForm<FormInputs>({
    defaultValues: {
      value: '',
      posId: '',
    },
    resolver: zodResolver(formDataSchema.pick({ posId: true, value: true })),
  })

  function handleCreateSale(data: FormInputs) {
    const { value: finalValue, posId: posId1 } = data

    if (!clientId)
      return setToast({
        message: "There isn't an available id to update.",
        color: 'danger',
      })

    if (typeof finalValue === 'string')
      return setToast({ message: 'the amount must be a number' })

    createSale.mutate(
      { clientId, finalValue, posId1 },
      { onSuccess: () => reset() }
    )
  }

  return (
    <div className="bg-ion-color-step-100 pt-4">
      <form className="space-y-3 p-5">
        <LoyaltyInput
          control={formMethods.control}
          name="posId"
          label="Transaction #"
          size="lg"
          disabled={createSale.isLoading}
        />
        <ErrorMessage as={StyledError} errors={errors} name="posId" />
        <LoyaltyInput
          control={formMethods.control}
          name="value"
          label="Amount"
          size="lg"
          type="number"
          disabled={createSale.isLoading}
        />
        <ErrorMessage as={StyledError} errors={errors} name="value" />
      </form>
      <div className="border-t border-white/20">
        <button
          type="button"
          onClick={formMethods.handleSubmit(handleCreateSale)}
          className="text-ion-color-yellow w-full p-1 text-3xl"
          disabled={createSale.isLoading}
        >
          Save
        </button>
      </div>
    </div>
  )
}

export { NonIntegratedSaleForm }

import { IonItem } from '@ionic/react'
import { cn } from '../../utils/cn'

type ListItemProps = { children: React.ReactNode } & React.ComponentProps<
  typeof IonItem
>

type HeadingProps = {
  children: React.ReactNode
  secondary?: boolean
}

type SubHeadingProps = React.ComponentPropsWithoutRef<'p'> & {
  secondary?: boolean
}

function ListItem({ children, ...props }: ListItemProps) {
  return (
    <IonItem
      style={{
        '--inner-padding-bottom': '0.25rem',
        '--inner-padding-top': '0.25rem',
      }}
      {...props}
    >
      {children}
    </IonItem>
  )
}

function ListItemHeading({ children, secondary }: HeadingProps) {
  return (
    <h3
      className={cn('text-lg font-semibold', {
        'text-ion-color-secondary': secondary,
      })}
    >
      {children}
    </h3>
  )
}

// TODO: Look at narrowing props/use variants instead of className for automation list page, alerts
function ListItemSubHeading({ ...props }: SubHeadingProps) {
  return <p className="text-ion-color-secondary text-sm" {...props} />
}

export { ListItem, ListItemHeading, ListItemSubHeading }

import * as React from 'react'
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { endOfDay, parseISO, startOfDay } from 'date-fns'
import { chevronDown, chevronUp } from 'ionicons/icons'
import { useImmer } from 'use-immer'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { dequal } from 'dequal/lite'
import { useToggle } from 'rooks'

import { HAVE_NOT_SHOPPED_OPTIONS } from '../../utils/common-strings'
import { useAccount } from '../../contexts/authContext'
import { useToast } from '../../contexts/toastContext'
import {
  useAdminAssociates,
  useAdminGroups,
} from '../../hooks/queries/use-associate'
import { useSearchClientTags } from '../../hooks/tags/queries'
import { useDescriptionsSearch } from '../../hooks/queries/use-descriptions'
import { usePosStylesSearch } from '../../hooks/queries/use-pos-styles'
import { AsyncSearchSelect } from '../form/async-search-select'
import { useIsStore } from '../../auth/utils'
import SearchableSelect from '../ui/form/searchable-select'
import DatePicker from '../ui/ionic/date-picker'
import MonthPicker from '../ui/form/month-picker'
import { NameSearchSelect } from '../ui/form/name-search-select'
import { AmountOfTimeInput } from './client-filters/amount-of-time-input'
import BrandSelect from './client-filters/brand-select'
import ColorSelect from './client-filters/color-select'
import { DropDownInput } from './client-filters/dropdown-input'
import MaterialSelect from './client-filters/material-select'
import CategorySelect from './client-filters/category-select'
import SizeSelect from './client-filters/size-select'
import GenderSelect from './client-filters/gender-select'
import PosSellerNameSelect from './client-filters/pos-seller-name-select'

import type { FiltersForm } from '../../clients/client-filters.schema'
import type { AmountOfTime } from '../../utils/schema'

type Props = {
  filters: FiltersForm | null
  onSearch: (filters: FormState) => void
  onCancel: () => void
  isAdmin?: boolean
}

type FormState = Omit<
  Required<FiltersForm>,
  'admin' | 'subscribersOnly' | 'notContacted'
>

const initialFormState: FormState = {
  hasVerifiedCell: false,
  hasPhoneNumber: false,
  hasEmail: false,
  hasAddress: false,

  firstName: '',
  lastName: '',
  phoneNumber: '',

  saleBefore: '',
  saleAfter: '',
  firstSaleStart: '',
  firstSaleEnd: '',
  lastContactAfter: '',
  lastContactBefore: '',
  lastContactNever: false,
  birthdayMonth: null,
  anniversaryMonth: null,

  selectedBrands: [],
  selectedColors: [],
  selectedMaterials: [],
  selectedPosClasses: [],
  selectedSizes: [],
  selectedPosStyles: [],
  selectedDescriptions: [],
  selectedPosGenders: [],
  selectedPosSellerNames: [],
  selectedSaleGroups: [],
  selectedClientTags: [],
  selectedAssociates: [],
  selectedGroups: [],
  selectedClients: [],

  totalSalesMin: '',
  totalSalesMax: '',

  notShoppedIn: {},

  loyaltyVisitsActive: {},
  loyaltyPointsActive: {},
  loyaltyPointsLifetime: {},
  loyaltyRewardsPosActive: {},
  loyaltyVisitsLifetime: {},

  saleCount: {},
  saleIn: {},
  saleFirstIn: {},
}

function PullClientsModal({ filters, isAdmin, onSearch, onCancel }: Props) {
  const [selectKey, resetSelectKey] = React.useReducer((c) => c + 1, 0)

  const setToast = useToast()

  const [formState, updateFormState] = useImmer<FormState>({
    ...initialFormState,
    ...filters,
  })

  const account = useAccount()
  const { isTristan, isUno } = useIsStore()

  const associatesQuery = useAdminAssociates()
  const groupsQuery = useAdminGroups()
  const searchClientTags = useSearchClientTags()

  const canShowTotalSalesFilter =
    !account.isEnterprise ||
    (formState.selectedGroups.length > 0 &&
      !formState.selectedGroups?.some((group) =>
        `${group}`.toLowerCase().includes('all')
      )) ||
    formState.selectedBrands.length > 0 ||
    formState.selectedColors.length > 0 ||
    formState.selectedMaterials.length > 0 ||
    formState.selectedSizes.length > 0 ||
    formState.selectedPosSellerNames.length > 0 ||
    formState.selectedPosClasses.length > 0 ||
    formState.selectedPosStyles.length > 0 ||
    formState.selectedSaleGroups.length > 0 ||
    formState.selectedDescriptions.length > 0

  function resetForm() {
    updateFormState(initialFormState)
    resetSelectKey()
  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton color="secondary" onClick={onCancel}>
              Cancel
            </IonButton>
          </IonButtons>
          <IonTitle>Filter</IonTitle>
          <IonButtons slot="end">
            <IonButton fill="outline" color="secondary" onClick={resetForm}>
              RESET
            </IonButton>
            <IonButton
              color="yellow"
              fill="solid"
              onClick={() => {
                // TODO: quick phone validation workaround. Should get hookform integrated
                if (
                  formState.phoneNumber !== '' &&
                  !isValidPhoneNumber(formState.phoneNumber)
                ) {
                  return setToast({
                    message: 'Please enter a valid phone number.',
                    color: 'danger',
                  })
                }
                onSearch(formState)
              }}
            >
              Confirm
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard color="secondary" className="ion-margin-horizontal">
          <IonCardContent>
            {/* Contact Info */}
            <section className="mt-2 flex justify-between">
              {/* Phone */}
              <div className="flex flex-1 flex-col items-center">
                {account.hasPhoneVerificationCredits ? (
                  <>
                    <IonCheckbox
                      id="hasVerifiedCell"
                      name="hasVerifiedCell"
                      color="secondary"
                      checked={formState.hasVerifiedCell}
                      onIonChange={(e) =>
                        updateFormState((draft) => {
                          draft.hasVerifiedCell = e.detail.checked
                        })
                      }
                    />
                    <IonLabel>Verified Cell</IonLabel>
                  </>
                ) : (
                  <>
                    <IonCheckbox
                      id="hasPhoneNumber"
                      name="hasPhoneNumber"
                      color="secondary"
                      checked={formState.hasPhoneNumber}
                      onIonChange={(e) =>
                        updateFormState((draft) => {
                          draft.hasPhoneNumber = e.detail.checked
                        })
                      }
                    />
                    <IonLabel>Phone Number</IonLabel>
                  </>
                )}
              </div>
              {/* Email */}
              <div className="flex flex-1 flex-col items-center">
                <IonCheckbox
                  id="hasEmail"
                  name="hasEmail"
                  color="secondary"
                  checked={formState.hasEmail}
                  onIonChange={(e) =>
                    updateFormState((draft) => {
                      draft.hasEmail = e.detail.checked
                    })
                  }
                />
                <IonLabel>Email</IonLabel>
              </div>
              {/* Address */}
              <div className="flex flex-1 flex-col items-center">
                <IonCheckbox
                  id="hasAddress"
                  name="hasAddress"
                  color="secondary"
                  checked={formState.hasAddress}
                  onIonChange={(e) =>
                    updateFormState((draft) => {
                      draft.hasAddress = e.detail.checked
                    })
                  }
                />
                <IonLabel>Address</IonLabel>
              </div>
            </section>

            {/* Name */}
            {formState.firstName ? (
              <IonItem color="secondary">
                <IonLabel>First Name</IonLabel>
                <IonInput
                  name="firstName"
                  type="text"
                  value={formState.firstName}
                  onIonChange={(e) =>
                    updateFormState((draft) => {
                      if (typeof e.detail.value === 'string')
                        draft.firstName = e.detail.value
                    })
                  }
                />
              </IonItem>
            ) : null}
            {formState.lastName ? (
              <IonItem color="secondary">
                <IonLabel>Last Name</IonLabel>
                <IonInput
                  name="lastName"
                  type="text"
                  value={formState.lastName}
                  onIonChange={(e) =>
                    updateFormState((draft) => {
                      if (typeof e.detail.value === 'string')
                        draft.lastName = e.detail.value
                    })
                  }
                />
              </IonItem>
            ) : null}

            <NameSearchSelect
              key={`nameKey${selectKey}`}
              label="Customer Name"
              onConfirm={(selectedItems) =>
                updateFormState((draft) => {
                  draft.selectedClients = selectedItems
                })
              }
              selectedItems={formState.selectedClients}
              isAdmin={isAdmin}
            />

            <IonItem color="secondary">
              <IonLabel>PhoneNumber</IonLabel>
              <PhoneInput
                value={formState.phoneNumber}
                onChange={(e) => {
                  updateFormState((draft) => {
                    draft.phoneNumber = e ? e.toString() : ''
                  })
                }}
                defaultCountry="US"
              />
            </IonItem>

            {/* Purchase Dates */}
            <DatePicker
              label="Sale After"
              value={formState.saleAfter}
              onChange={(e) =>
                updateFormState((draft) => {
                  draft.saleAfter =
                    typeof e.detail.value === 'string' && e.detail.value.length
                      ? endOfDay(parseISO(e.detail.value)).toISOString()
                      : ''
                })
              }
              onReset={() =>
                updateFormState((draft) => {
                  draft.saleAfter = ''
                })
              }
            />
            <DatePicker
              label="Sale Before"
              value={formState.saleBefore}
              onChange={(e) =>
                updateFormState((draft) => {
                  draft.saleBefore =
                    typeof e.detail.value === 'string' && e.detail.value.length
                      ? startOfDay(parseISO(e.detail.value)).toISOString()
                      : ''
                })
              }
              onReset={() =>
                updateFormState((draft) => {
                  draft.saleBefore = ''
                })
              }
            />

            {/* Number of Sales */}
            <GtLtDropDownToggle
              key={`saleCountKey${selectKey}`}
              label="Number Of Sales"
            >
              <div className="flex-1">
                <DropDownInput
                  label="Greater Than"
                  step="1"
                  type="number"
                  value={formState.saleCount.gt}
                  onChange={(e) =>
                    updateFormState((draft) => {
                      const { value } = e.currentTarget
                      draft.saleCount.gt = value.length
                        ? parseInt(e.currentTarget.value)
                        : undefined
                    })
                  }
                />
              </div>
              <div className="flex-1">
                <DropDownInput
                  label="Less Than"
                  step="1"
                  type="number"
                  value={formState.saleCount.lt}
                  onChange={(e) =>
                    updateFormState((draft) => {
                      const { value } = e.currentTarget
                      draft.saleCount.lt = value.length
                        ? parseInt(e.currentTarget.value)
                        : undefined
                    })
                  }
                />
              </div>
            </GtLtDropDownToggle>

            {/* Sale in Last X */}
            <GtLtDropDownToggle
              key={`saleInKey${selectKey}`}
              label="Sale In Last"
            >
              <AmountOfTimeInput
                initialValue={formState.saleIn}
                onChange={(val) => {
                  updateFormState((draft) => {
                    draft.saleIn = val
                  })
                }}
              />
            </GtLtDropDownToggle>

            {/* First Sale in Last X */}
            <GtLtDropDownToggle
              key={`saleFirstInKey${selectKey}`}
              label="First Sale In Last"
            >
              <AmountOfTimeInput
                initialValue={formState.saleFirstIn}
                onChange={(val) => {
                  console.log(val)
                  updateFormState((draft) => {
                    draft.saleFirstIn = val
                  })
                }}
              />
            </GtLtDropDownToggle>

            {/* First Sale */}
            <DropDownToggle label="First Sale">
              <div className="ion-padding-start">
                <DatePicker
                  label="After"
                  value={formState.firstSaleStart}
                  onChange={(e) =>
                    updateFormState((draft) => {
                      draft.firstSaleStart =
                        typeof e.detail.value === 'string' &&
                        e.detail.value.length
                          ? endOfDay(parseISO(e.detail.value)).toISOString()
                          : ''
                    })
                  }
                  onReset={() =>
                    updateFormState((draft) => {
                      draft.firstSaleStart = ''
                    })
                  }
                />
                <DatePicker
                  label="Before"
                  value={formState.firstSaleEnd}
                  onChange={(e) =>
                    updateFormState((draft) => {
                      draft.firstSaleEnd =
                        typeof e.detail.value === 'string' &&
                        e.detail.value.length
                          ? startOfDay(parseISO(e.detail.value)).toISOString()
                          : ''
                    })
                  }
                  onReset={() =>
                    updateFormState((draft) => {
                      draft.firstSaleEnd = ''
                    })
                  }
                />
              </div>
            </DropDownToggle>

            {/* Brands */}
            {!isTristan() && (
              <BrandSelect
                key={`brandsKey${selectKey}`}
                isAdmin={isAdmin}
                selectedItems={formState.selectedBrands}
                setSelectedItems={(selectedItems) =>
                  updateFormState((draft) => {
                    draft.selectedBrands = selectedItems
                  })
                }
              />
            )}

            {/* Color/Attribute */}
            <ColorSelect
              key={`colorsKey-${selectKey}`}
              isAdmin={isAdmin}
              selectedItems={formState.selectedColors}
              setSelectedItems={(selectedItems) =>
                updateFormState((draft) => {
                  draft.selectedColors = selectedItems
                })
              }
            />

            {/* Materials */}
            {isUno() && (
              <MaterialSelect
                key={`materials-${selectKey}`}
                isAdmin={isAdmin}
                selectedItems={formState.selectedMaterials}
                setSelectedItems={(selectedItems) =>
                  updateFormState((draft) => {
                    draft.selectedMaterials = selectedItems
                  })
                }
              />
            )}

            {/* Categories */}
            <CategorySelect
              key={`categories-${selectKey}`}
              isAdmin={isAdmin}
              selectedItems={formState.selectedPosClasses}
              setSelectedItems={(selectedItems) =>
                updateFormState((draft) => {
                  draft.selectedPosClasses = selectedItems
                })
              }
            />

            {/* Sizes */}
            <SizeSelect
              key={`sizes-${selectKey}`}
              isAdmin={isAdmin}
              selectedItems={formState.selectedSizes}
              setSelectedItems={(selectedItems) =>
                updateFormState((draft) => {
                  draft.selectedSizes = selectedItems
                })
              }
            />

            {/* Styles */}
            <AsyncSearchSelect
              label="Styles"
              selectedItems={formState.selectedPosStyles}
              setSelectedItems={(selectedItems) =>
                updateFormState((draft) => {
                  draft.selectedPosStyles = selectedItems
                })
              }
              isAdmin={isAdmin}
              useSearchQuery={usePosStylesSearch}
            />

            {/* Descriptions */}
            <AsyncSearchSelect
              label="Descriptions"
              selectedItems={formState.selectedDescriptions}
              setSelectedItems={(selectedItems) =>
                updateFormState((draft) => {
                  draft.selectedDescriptions = selectedItems
                })
              }
              isAdmin={isAdmin}
              useSearchQuery={useDescriptionsSearch}
            />

            {/* Genders */}
            {isTristan() && (
              <GenderSelect
                key={`genders-${selectKey}`}
                isAdmin={isAdmin}
                selectedItems={formState.selectedPosGenders}
                setSelectedItems={(selectedItems) =>
                  updateFormState((draft) => {
                    draft.selectedPosGenders = selectedItems
                  })
                }
              />
            )}

            {/* SoldBy - ADMIN ONLY */}
            {isAdmin ? (
              <PosSellerNameSelect
                key={`posSellerNames-${selectKey}`}
                isAdmin={isAdmin}
                selectedItems={formState.selectedPosSellerNames}
                setSelectedItems={(selectedItems) =>
                  updateFormState((draft) => {
                    draft.selectedPosSellerNames = selectedItems
                  })
                }
              />
            ) : null}

            {/* Shopped At  */}
            <IonItem color="secondary">
              <IonLabel>Shopped at</IonLabel>
              <IonSelect
                color="secondary"
                value={formState.selectedSaleGroups}
                onIonChange={(e) =>
                  updateFormState((draft) => {
                    draft.selectedSaleGroups = e.detail.value
                  })
                }
                multiple
              >
                {groupsQuery.data?.map((item) => (
                  <IonSelectOption key={item.objectId} value={item.objectId}>
                    {item.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>

            {/* Total Sales */}
            {canShowTotalSalesFilter && (
              <GtLtDropDownToggle label="Total Sales">
                <div className="flex-1">
                  <DropDownInput
                    label="Greater Than"
                    step="100"
                    type="number"
                    value={formState.totalSalesMin}
                    onChange={(e) =>
                      updateFormState((draft) => {
                        draft.totalSalesMin = e.currentTarget.value
                      })
                    }
                  />
                </div>

                <div className="flex-1">
                  <DropDownInput
                    label="Less Than"
                    step="100"
                    type="number"
                    value={formState.totalSalesMax}
                    onChange={(e) =>
                      updateFormState((draft) => {
                        draft.totalSalesMax = e.currentTarget.value
                      })
                    }
                  />
                </div>
              </GtLtDropDownToggle>
            )}

            {/* Not Shopped In */}
            <IonItem color="secondary">
              <IonLabel>Not Shopped In</IonLabel>
              <IonSelect
                id="next-contact-select"
                value={formState.notShoppedIn}
                compareWith={(o1: AmountOfTime, o2: AmountOfTime) =>
                  dequal(o1, o2)
                }
                onIonChange={(e) =>
                  updateFormState((draft) => {
                    draft.notShoppedIn = e.detail.value
                  })
                }
                okText="Okay"
                cancelText="Dismiss"
                interfaceOptions={{ cssClass: 'dark-ionic-filter' }}
              >
                {HAVE_NOT_SHOPPED_OPTIONS.map(({ label, value }) => (
                  <IonSelectOption key={label} value={value}>
                    {label}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>

            {/* Tags */}
            <SearchableSelect
              key={`tagsKey${selectKey}`}
              items={searchClientTags.data ?? []}
              label="Tags"
              selectedItems={formState.selectedClientTags}
              setSelectedItems={(selectedItems) =>
                updateFormState((draft) => {
                  draft.selectedClientTags = selectedItems
                })
              }
            />

            {/* Last Contacted */}
            <DropDownToggle label="Last Contacted">
              <div className="ion-padding-start">
                <IonItem color="secondary">
                  <IonLabel>Never Contacted</IonLabel>
                  <IonCheckbox
                    color="secondary"
                    checked={formState.lastContactNever}
                    onIonChange={(e) => {
                      updateFormState((draft) => {
                        draft.lastContactNever = e.detail.checked
                        draft.lastContactBefore = e.detail.checked
                          ? ''
                          : draft.lastContactBefore
                        draft.lastContactAfter = e.detail.checked
                          ? ''
                          : draft.lastContactAfter
                      })
                    }}
                  />
                </IonItem>
                <DatePicker
                  label="Last Contact After"
                  value={formState.lastContactAfter}
                  onChange={(e) =>
                    updateFormState((draft) => {
                      draft.lastContactAfter =
                        typeof e.detail.value === 'string' &&
                        e.detail.value.length
                          ? endOfDay(parseISO(e.detail.value)).toISOString()
                          : ''
                      draft.lastContactNever = e.detail.value
                        ? false
                        : draft.lastContactNever
                    })
                  }
                  onReset={() =>
                    updateFormState((draft) => {
                      draft.lastContactAfter = ''
                    })
                  }
                />

                <DatePicker
                  label="Last Contact Before"
                  value={formState.lastContactBefore}
                  onChange={(e) => {
                    updateFormState((draft) => {
                      draft.lastContactBefore =
                        typeof e.detail.value === 'string' &&
                        e.detail.value.length
                          ? startOfDay(parseISO(e.detail.value)).toISOString()
                          : ''
                      draft.lastContactNever = e.detail.value
                        ? false
                        : draft.lastContactNever
                    })
                  }}
                  onReset={() =>
                    updateFormState((draft) => {
                      draft.lastContactBefore = ''
                    })
                  }
                />
              </div>
            </DropDownToggle>

            {/* Birthday */}
            <MonthPicker
              label="Birthday Month"
              value={formState.birthdayMonth}
              placeholder="Select a month..."
              onChange={(e) =>
                updateFormState((draft) => {
                  draft.birthdayMonth = e.detail.value
                })
              }
            />

            {/* Anniversary Month */}
            <MonthPicker
              label="Anniversary Month"
              value={formState.anniversaryMonth}
              placeholder="Select a month..."
              onChange={(e) =>
                updateFormState((draft) => {
                  draft.anniversaryMonth = e.detail.value
                })
              }
            />

            {/* Assigned Stylist - ADMIN ONLY*/}
            {isAdmin ? (
              <IonItem color="secondary">
                <IonLabel>Assigned To</IonLabel>
                <IonSelect
                  value={formState.selectedAssociates}
                  onIonChange={(e) =>
                    updateFormState((draft) => {
                      draft.selectedAssociates = e.detail.value
                    })
                  }
                  multiple
                  color="secondary"
                >
                  <IonSelectOption key="unassigned" value="unassigned">
                    Unassigned
                  </IonSelectOption>
                  {associatesQuery.data?.map((item) => (
                    <IonSelectOption key={item.objectId} value={item.objectId}>
                      {item.name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
            ) : null}

            {/* Store Location */}
            <IonItem color="secondary">
              <IonLabel>Store Location</IonLabel>
              <IonSelect
                color="secondary"
                value={formState.selectedGroups}
                onIonChange={(e) =>
                  updateFormState((draft) => {
                    draft.selectedGroups = e.detail.value
                  })
                }
                multiple
              >
                {groupsQuery.data?.map((item) => (
                  <IonSelectOption key={item.objectId} value={item.objectId}>
                    {item.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>

            {/* Loyalty Points - Active */}
            <GtLtDropDownToggle label="Active Loyalty Points">
              <div className="flex-1">
                <DropDownInput
                  label="Greater Than"
                  step="1"
                  type="number"
                  value={formState.loyaltyPointsActive.gt}
                  onChange={(e) =>
                    updateFormState((draft) => {
                      const { value } = e.currentTarget
                      draft.loyaltyPointsActive.gt = value.length
                        ? parseInt(e.currentTarget.value)
                        : undefined
                    })
                  }
                />
              </div>
              <div className="flex-1">
                <DropDownInput
                  label="Less Than"
                  step="1"
                  type="number"
                  value={formState.loyaltyPointsActive.lt}
                  onChange={(e) =>
                    updateFormState((draft) => {
                      const { value } = e.currentTarget
                      draft.loyaltyPointsActive.lt = value.length
                        ? parseInt(e.currentTarget.value)
                        : undefined
                    })
                  }
                />
              </div>
            </GtLtDropDownToggle>

            {/* Loyalty Points - Lifetime */}
            <GtLtDropDownToggle label="Lifetime Loyalty Points">
              <div className="flex-1">
                <DropDownInput
                  label="Greater Than"
                  step="1"
                  type="number"
                  value={formState.loyaltyPointsLifetime.gt}
                  onChange={(e) =>
                    updateFormState((draft) => {
                      const { value } = e.currentTarget
                      draft.loyaltyPointsLifetime.gt = value.length
                        ? parseInt(e.currentTarget.value)
                        : undefined
                    })
                  }
                />
              </div>
              <div className="flex-1">
                <DropDownInput
                  label="Less Than"
                  step="1"
                  type="number"
                  value={formState.loyaltyPointsLifetime.lt}
                  onChange={(e) =>
                    updateFormState((draft) => {
                      const { value } = e.currentTarget
                      draft.loyaltyPointsLifetime.lt = value.length
                        ? parseInt(e.currentTarget.value)
                        : undefined
                    })
                  }
                />
              </div>
            </GtLtDropDownToggle>

            {/* Loyalty Visits - Active */}
            <GtLtDropDownToggle label="Active Loyalty Visits">
              <div className="flex-1">
                <DropDownInput
                  label="Greater Than"
                  step="1"
                  type="number"
                  value={formState.loyaltyVisitsActive.gt}
                  onChange={(e) =>
                    updateFormState((draft) => {
                      const { value } = e.currentTarget
                      draft.loyaltyVisitsActive.gt = value.length
                        ? parseInt(e.currentTarget.value)
                        : undefined
                    })
                  }
                />
              </div>
              <div className="flex-1">
                <DropDownInput
                  label="Less Than"
                  step="1"
                  type="number"
                  value={formState.loyaltyVisitsActive.lt}
                  onChange={(e) =>
                    updateFormState((draft) => {
                      const { value } = e.currentTarget
                      draft.loyaltyVisitsActive.lt = value.length
                        ? parseInt(e.currentTarget.value)
                        : undefined
                    })
                  }
                />
              </div>
            </GtLtDropDownToggle>

            {/* Loyalty Visits - Lifetime */}
            <GtLtDropDownToggle label="Lifetime Loyalty Visits">
              <div className="flex-1">
                <DropDownInput
                  label="Greater Than"
                  step="1"
                  type="number"
                  value={formState.loyaltyVisitsLifetime.gt}
                  onChange={(e) =>
                    updateFormState((draft) => {
                      const { value } = e.currentTarget
                      draft.loyaltyVisitsLifetime.gt = value.length
                        ? parseInt(e.currentTarget.value)
                        : undefined
                    })
                  }
                />
              </div>
              <div className="flex-1">
                <DropDownInput
                  label="Less Than"
                  step="1"
                  type="number"
                  value={formState.loyaltyVisitsLifetime.lt}
                  onChange={(e) =>
                    updateFormState((draft) => {
                      const { value } = e.currentTarget
                      draft.loyaltyVisitsLifetime.lt = value.length
                        ? parseInt(e.currentTarget.value)
                        : undefined
                    })
                  }
                />
              </div>
            </GtLtDropDownToggle>

            {/* External Loyalty Rewards */}
            <GtLtDropDownToggle label="External Loyalty Rewards">
              <div className="flex-1">
                <DropDownInput
                  label="Greater Than"
                  step="1"
                  type="number"
                  value={formState.loyaltyRewardsPosActive.gt}
                  onChange={(e) =>
                    updateFormState((draft) => {
                      const { value } = e.currentTarget
                      draft.loyaltyRewardsPosActive.gt = value.length
                        ? parseInt(e.currentTarget.value)
                        : undefined
                    })
                  }
                />
              </div>
              <div className="flex-1">
                <DropDownInput
                  label="Less Than"
                  step="1"
                  type="number"
                  value={formState.loyaltyRewardsPosActive.lt}
                  onChange={(e) =>
                    updateFormState((draft) => {
                      const { value } = e.currentTarget
                      draft.loyaltyRewardsPosActive.lt = value.length
                        ? parseInt(e.currentTarget.value)
                        : undefined
                    })
                  }
                />
              </div>
            </GtLtDropDownToggle>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </>
  )
}

function DropDownToggle({
  label,
  children,
}: {
  label: string
  children: React.ReactNode
}) {
  const [show, onToggle] = useToggle(false)

  return (
    <>
      <IonItem
        color="secondary"
        onClick={onToggle}
        lines={show ? 'none' : 'inset'}
      >
        <IonLabel>{label}</IonLabel>
        <IonIcon slot="end" icon={show ? chevronDown : chevronUp} />
      </IonItem>

      {show ? children : null}
    </>
  )
}

function GtLtDropDownToggle({
  children,
  label,
}: {
  children: React.ReactNode
  label: string
}) {
  return (
    <DropDownToggle label={label}>
      <div className="pl-5">
        <div className="border-ion-color-shade flex gap-x-3 border-b-[.5px] pb-3 pl-3">
          {children}
        </div>
      </div>
    </DropDownToggle>
  )
}

export default PullClientsModal

import numeral from 'numeral'

import type { ProfileBase } from '../types/general'

function composeDollarAmount(
  number: number,
  {
    locale,
    currency,
  }: {
    locale?: string
    currency?: string
  } = { locale: 'en-US', currency: 'USD' }
) {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    maximumFractionDigits: 0,
  }).format(number)
}

function composeNumber(
  number: number,
  {
    locale,
  }: {
    locale?: string
  } = { locale: 'en-US' }
) {
  return new Intl.NumberFormat(locale).format(number)
}

function formatCurrency(num: number, { abbreviate } = { abbreviate: false }) {
  if (!abbreviate) {
    // toFixed is decimal places
    return num
      ? '$' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      : '$0.00'
  }

  if (num >= 0 && num < 1000) return numeral(num).format('$0a')

  if (num >= 1000 && num < 10000) {
    return numeral(num).format('$0.0a')
  }

  if (num >= 10000 && num < 1000000) {
    return numeral(num).format('$0a')
  }

  return numeral(num).format('$0.0a')
}

function formatName(name: unknown) {
  if (typeof name !== 'string') return ''

  const lowerCase = name.toLowerCase()
  return `${lowerCase.charAt(0).toUpperCase()}${lowerCase.slice(1)}`
}

function getFullName(
  profileName: Pick<ProfileBase, 'firstName' | 'lastName'> | null
) {
  if (!profileName) return 'No Name'

  if (profileName.firstName && profileName.lastName) {
    return `${profileName.firstName} ${profileName.lastName}`
  }
  if (profileName.firstName && !profileName.lastName) {
    return profileName.firstName
  }
  if (!profileName.firstName && profileName.lastName) {
    return profileName.lastName
  }

  return 'No Name'
}

function roundTo(num: number, precision: number = 2) {
  return Number(Math.round(+(num + 'e+' + precision)) + 'e-' + precision)
}

const camelToKebabCase = (str: string) =>
  str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()

const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

function strToTitleCase(str: string) {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(' ')
}

function replacePlaceholders(
  str: string,
  replacers: { [placeholder: string]: string }
) {
  return Object.entries(replacers).reduce(
    (accumulator, [placeholder, replaceValue]) =>
      accumulator.replaceAll(placeholder, replaceValue),
    str
  )
}

export {
  camelToKebabCase,
  camelToSnakeCase,
  composeDollarAmount,
  composeNumber,
  formatCurrency,
  formatName,
  getFullName,
  replacePlaceholders,
  roundTo,
  strToTitleCase,
}

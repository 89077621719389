import * as React from 'react'
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonContent,
} from '@ionic/react'
import { RadioGroup } from '@headlessui/react'

import { UnlayerDisplayCondition } from './unlayer-editor'

function SelectConditionModal({
  onDismiss,
  stylistConditions,
}: {
  onDismiss: (data?: any, role?: string) => void
  stylistConditions: Array<UnlayerDisplayCondition>
}) {
  const [condition, setCondition] = React.useState<
    UnlayerDisplayCondition | undefined
  >()

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              color="secondary"
              onClick={() => onDismiss(null, 'cancel')}
            >
              Cancel
            </IonButton>
          </IonButtons>
          <IonTitle>Select a Display Condition</IonTitle>
          <IonButtons slot="end">
            <IonButton
              disabled={!condition}
              color="yellow"
              onClick={() => onDismiss(condition, 'confirm')}
            >
              Confirm
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <RadioGroup value={condition} onChange={setCondition}>
          {stylistConditions.map((sc) => (
            <RadioGroup.Option
              key={sc.before}
              value={sc}
              className="border-ion-color-step-150 flex cursor-pointer items-center gap-x-3 border-b py-5 ui-disabled:cursor-default"
            >
              <span className="border-ion-color-secondary ui-checked:bg-ion-color-yellow size-4 rounded-full border" />
              <div className="flex flex-col">
                <span className="flex cursor-pointer items-center text-lg font-semibold">
                  {sc.label}
                </span>
                <span className="text-sm text-white/60">{sc.description}</span>
              </div>
            </RadioGroup.Option>
          ))}
        </RadioGroup>
      </IonContent>
    </IonPage>
  )
}

export { SelectConditionModal }

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Cloudinary, ResourceType } from '@capawesome/capacitor-cloudinary'

import { camelToSnakeCase } from '../../../utils/format-helpers'
import { client } from '../../../services/client'
import { useUser } from '../../../contexts/authContext'
import { useToast } from '../../../contexts/toastContext'
import { lookQueryKeys } from './queries'

import type { UploadResourceResult } from '@capawesome/capacitor-cloudinary'
import type { PickedFile } from '@capawesome/capacitor-file-picker'
import type { SetRequired } from 'type-fest'
import type { LookResponse } from './queries'
import type { PhotoData } from '../../use-photo'
import { feedQueryKeys } from '../use-feed'
import { profileLookQueryKeys } from '../../profile/profile-looks/queries'

type CreatePhotoLookInput = CreateBaseLookInput & {
  image: PhotoData | null
}

type CreateCollageInput = SetRequired<CreateBaseLookInput, 'products'>

type CreateBaseLookInput = {
  title?: string
  isPrivate: boolean
  products?: Array<string>
  cloudinary?: { resource_type: ResourceType }
  image?: PhotoData | null
}

type UpdateVideoLookInput = {
  objectId: string
  video: PickedFile
}

function getBaseLookInputs(look: CreateBaseLookInput) {
  const { title = '', isPrivate, products, cloudinary } = look

  return {
    title,
    isPublic: !isPrivate,
    products,
    cloudinary,
  }
}

function convertCloudinaryObjectKeys(cloudinaryObj: UploadResourceResult) {
  return Object.fromEntries(
    Object.entries(cloudinaryObj).map(([k, v]) => {
      return [`${camelToSnakeCase(k)}`, v]
    })
  )
}

async function uploadVideoToCloudinary(video: PickedFile, publicId: string) {
  const { path, blob } = video

  // initialize Cloudinary
  await Cloudinary.initialize({ cloudName: 'oneshop' })

  // upload Video
  const cloudinaryResponse = await Cloudinary.uploadResource({
    path,
    blob,
    publicId,
    resourceType: ResourceType.Video,
    uploadPreset: 'oneshop_empty',
  })

  return convertCloudinaryObjectKeys(cloudinaryResponse)
}

async function createBaseLook(variables: CreateBaseLookInput) {
  const { image, ...baseInputs } = variables
  const { title, isPublic, products, cloudinary } =
    getBaseLookInputs(baseInputs)

  const response = await client
    .post('functions/createLook', {
      json: { title, isPublic, products, cloudinary, image },
    })
    .json<LookResponse>()

  return response.result.look
}

async function updateVideoLook({
  objectId,
  video,
  accountId,
  userId,
}: UpdateVideoLookInput & {
  accountId: string
  userId: string
}) {
  const publicId = `${accountId}/look/${userId}_${Date.now().toString()}`

  const cloudinary = await uploadVideoToCloudinary(video, publicId)

  const response = await client
    .post('functions/updateLook', {
      json: { objectId, cloudinary },
    })
    .json<LookResponse>()

  return response.result.look
}

function useUpdateVideoLook() {
  const queryClient = useQueryClient()
  const setToast = useToast()
  const user = useUser()

  return useMutation(
    ({ objectId, video }: UpdateVideoLookInput) =>
      updateVideoLook({
        objectId,
        video,
        accountId: user.account.objectId,
        userId: user.objectId,
      }),
    {
      onSuccess: (data, variables) => {
        queryClient.setQueryData(lookQueryKeys.detail(variables.objectId), data)
        setToast({
          message: 'Your Video Asset was successfully created.',
          color: 'yellow',
        })
      },
      onError: async (err) => {
        const errorMessage =
          err instanceof Error ? err.message : 'Unknown error.'

        setToast({
          message: `Oops, something went wrong while creating your Asset: ${errorMessage}`,
          color: 'danger',
        })
      },
      onSettled(_data, _error, variables) {
        queryClient.invalidateQueries(lookQueryKeys.list())
      },
    }
  )
}

async function createCollage(post: CreateCollageInput) {
  const response = await client
    .post('functions/createLook', {
      json: {
        title: post.title,
        isPublic: !post.isPrivate,
        products: post.products,
      },
    })
    .json<LookResponse>()

  return response.result.look
}

async function createPhotoLook(look: CreatePhotoLookInput) {
  const { title, isPublic, products } = getBaseLookInputs(look)
  const { image } = look

  const response = await client
    .post('functions/createLook', {
      json: { title, isPublic, products, image },
    })
    .json<LookResponse>()

  return response.result.look
}

function useCreatePhotoLook() {
  const queryClient = useQueryClient()
  const setToast = useToast()

  return useMutation(createPhotoLook, {
    onSuccess: (data) => {
      queryClient.setQueryData(lookQueryKeys.detail(data.objectId), data)

      setToast({
        message: 'Your Asset was successfully created.',
        color: 'yellow',
      })
    },
    onError: async (err) => {
      const errorMessage = err instanceof Error ? err.message : 'Unknown error.'

      setToast({
        message: `Oops, something went wrong while creating your Asset: ${errorMessage}`,
        color: 'danger',
      })
    },

    onSettled() {
      queryClient.invalidateQueries(lookQueryKeys.list())
    },
  })
}

function useCreateBaseLook() {
  const queryClient = useQueryClient()
  const setToast = useToast()

  return useMutation(createBaseLook, {
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(lookQueryKeys.lists())
      queryClient.invalidateQueries(profileLookQueryKeys.lists())
      queryClient.invalidateQueries(feedQueryKeys.lists())
      queryClient.invalidateQueries(feedQueryKeys.pendingCount())
    },
    onError: async (err) => {
      const errorMessage = err instanceof Error ? err.message : 'Unknown error.'
      setToast({
        message: `Oops, something went wrong while creating your Asset: ${errorMessage}`,
        color: 'danger',
      })
    },
  })
}

function useCreateCollage() {
  const queryClient = useQueryClient()
  const setToast = useToast()

  return useMutation(createCollage, {
    onSuccess: (data) => {
      queryClient.setQueryData(lookQueryKeys.detail(data.objectId), data)
      setToast({
        message: 'Your Collage was successfully created.',
        color: 'yellow',
      })
    },

    onError: async (err) => {
      const errorMessage = err instanceof Error ? err.message : 'Unknown Error.'

      setToast({
        message: `Oops, something went wrong while creating your Collage: ${errorMessage}`,
        color: 'danger',
      })
    },

    onSettled() {
      queryClient.invalidateQueries(lookQueryKeys.list())
    },
  })
}

export {
  createBaseLook,
  createCollage,
  createPhotoLook,
  updateVideoLook,
  useCreateBaseLook,
  useCreateCollage,
  useCreatePhotoLook,
  useUpdateVideoLook,
}

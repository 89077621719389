import { cn } from '../../../utils/cn'

type StyledButtonProps = Omit<
  React.ComponentPropsWithoutRef<'button'>,
  'className'
>

interface ApprovalButtonProps extends StyledButtonProps {
  children: React.ReactNode
  danger?: boolean
}

function ApprovalButton({ children, danger, ...props }: ApprovalButtonProps) {
  return (
    <button
      className={cn([
        'rounded-full px-8 py-2 text-sm font-semibold',
        danger
          ? 'bg-ion-color-danger text-white'
          : 'text-ion-color-primary bg-white',
      ])}
      {...props}
    >
      {children}
    </button>
  )
}

export { ApprovalButton }

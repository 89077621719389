import { useQuery } from '@tanstack/react-query'
import { client } from '../../services/client'

import type { IsoDate } from '../../types/general'
import type { ResponseData } from '../../types/queries'

type FetchProfileResponse = ResponseData<Profile>

type ProfileBase = {
  objectId: string
  displayName: string
  bio: string | null
  photo: string | null
  profileId: string
}

export type Profile = {
  user: ProfileBase
}

export type User = {
  objectId: string
  account: { objectId: string }
  bio?: string
  displayName?: string | null
  email: string | null
  firstName: string
  lastName: string | null
  optInTermsAcceptedAt: IsoDate | null
  personalDeviceTermsAcceptedAt: IsoDate | null
  photo?: { name: string; url: string }
  profileId?: string
  twilioProxyAddress: string | null
  twilioProxyEmail: string | null
  username: string
  version: string
}

type FetchSmsCreditsResponse = ResponseData<{
  smsCredits: number
  smsCreditsRemaining: number
}>

const accountQueryKeys = {
  all: [{ entity: 'account' }] as const,
  profile: () => [{ ...accountQueryKeys.all[0], scope: 'profile' }] as const,
  credits: (role: 'admin' | 'associate' = 'associate') =>
    [{ ...accountQueryKeys.all[0], scope: 'credits', role }] as const,
}

async function fetchMyProfile() {
  return client.post('functions/getMyProfile').json<FetchProfileResponse>()
}

// TODO: ANY DIFF BETWEEN ADMIN AND ASSOCIATE CREDITS???
async function fetchSmsCredits() {
  const response: FetchSmsCreditsResponse = await client
    .post('functions/getSmsCredits')
    .json()

  return response.result
}

function useMyProfile() {
  return useQuery({
    queryKey: accountQueryKeys.profile(),
    queryFn: fetchMyProfile,
    select: (data) => data.result.user,
  })
}

function useSmsCredits(type: 'admin' | 'associate' = 'associate') {
  return useQuery({
    queryKey: accountQueryKeys.credits(type),
    queryFn: fetchSmsCredits,
  })
}

export { accountQueryKeys, useMyProfile, useSmsCredits }

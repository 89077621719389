const settings = ['social', 'marketing', 'clienteling'] as const

const storeNames = [
  '6 Box Test',
  'Bahama Mama',
  'Cabin Creations',
  'Cedar Chest',
  'clientelier',
  "Dolly's Gaming Cafe",
  'fabric bash',
  'faherty brand',
  'guys and co',
  'Hippie Hut',
  'ladder',
  'Lucky Joes',
  'morley',
  'oneshop',
  'OneShop Vend Test',
  'penelope t',
  'periwinkle',
  'q clothier',
  'Raby Hardware & Outdoors',
  'Scout & Mollys Plano',
  'Scout & Mollys (Annapolis, Mosaic, & Reston Stores)',
  "sharla's boutique",
  'skirt',
  'Slots By The Fox',
  'Snooty Fox',
  'TWB Home Decor',
  'tristan',
  'unode50',
  'whimzy',
] as const

const unoFrenchStores = ['Carrefour Laval'] as const
const unoSpanishStores = [
  'Plaza Carolina',
  'Plaza Del Caribe',
  'Plaza Del Sol',
  'Plaza Las Americas',
  'Puerto Rico Pop Up Plaza',
  'Pop Up Plaza Del Caribe',
] as const

export { settings, storeNames, unoFrenchStores, unoSpanishStores }

import { IonContent, IonPage } from '@ionic/react'

import { OptInMoreInfoContent } from '../../integrations/lightspeed/components/optin/opt-in-more-info-content'
import { useLightspeedParams } from '../../integrations/lightspeed/utils'

function LightspeedOptInMoreInfoPage() {
  const { querystring, customerID } = useLightspeedParams()

  return (
    <IonPage>
      <IonContent>
        <OptInMoreInfoContent
          onSuccessPath="/lightspeed/opt-in/success"
          querystring={querystring}
          customerID={customerID}
        />
      </IonContent>
    </IonPage>
  )
}

export default LightspeedOptInMoreInfoPage

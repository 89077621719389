import * as React from 'react'
import { IonIcon } from '@ionic/react'
import { chevronUpCircle, chevronDownCircle } from 'ionicons/icons'

type Props = {
  isOpen: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

function Toggle({ isOpen, onClick }: Props) {
  return (
    <button
      type="button"
      className="size-6 bg-ion-color-primary flex items-center justify-center rounded-full"
      onClick={onClick}
    >
      <IonIcon
        className="size-full"
        color="secondary"
        icon={isOpen ? chevronUpCircle : chevronDownCircle}
      />
    </button>
  )
}

export default Toggle

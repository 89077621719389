import { useRef } from 'react'

import { EditManualBoxForm } from './edit-manual-box-form'
import { LoyaltyButton } from './loyalty-button'
import { LoyaltyModal } from './loyalty-modal'

import type { RewardSet } from '../loyalty.schema'

function EditManualBox({
  id,
  loyaltyPoint,
}: {
  id: string
  loyaltyPoint: Extract<
    RewardSet['rewardBoxes'][number],
    { kind: 'manual' }
  >['loyaltyPoint']
}) {
  const modalRef = useRef<HTMLIonModalElement>(null)

  return (
    <>
      <LoyaltyButton id={`open-modal-${id}`} color="secondary" fill="solid">
        Edit
      </LoyaltyButton>

      <LoyaltyModal ref={modalRef} trigger={`open-modal-${id}`}>
        <div className="bg-ion-color-step-100 w-80 pt-4">
          <h2 className="text-center">Edit Loyalty Points</h2>
          <EditManualBoxForm
            loyaltyPoint={loyaltyPoint}
            onSubmit={() => modalRef.current?.dismiss()}
          />
        </div>
        <div className="bg-ion-color-step-100 border-t border-white/20">
          <button
            onClick={() => modalRef.current?.dismiss()}
            type="button"
            className="text-ion-color-secondary w-full p-1"
          >
            Cancel
          </button>
        </div>
      </LoyaltyModal>
    </>
  )
}

export { EditManualBox }

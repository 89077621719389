import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import type { FiltersForm } from './client-filters.schema'

// todo: revisit and delete catch-all Record
type StateFilters = FiltersForm & Record<string, any>

type State = {
  currentAdminClientFilters: StateFilters
  currentClientFilters: StateFilters
}

type Actions = {
  setAdminClientFilters: (filters: StateFilters) => void
  setClientFilters: (filters: StateFilters) => void
}

const devtoolsInProd = process.env.NODE_ENV === 'production'

const useClientFiltersStore = create(
  devtools(
    immer<State & { actions: Actions }>((set) => ({
      currentAdminClientFilters: {},
      currentClientFilters: {},
      actions: {
        setAdminClientFilters: (filters) => {
          return set((state) => {
            state.currentAdminClientFilters = filters
          })
        },
        setClientFilters: (filters) => {
          return set((state) => {
            state.currentClientFilters = filters
          })
        },
      },
    })),
    { enabled: !devtoolsInProd }
  )
)

const useAdminClientFilters = () =>
  useClientFiltersStore((s) => s.currentAdminClientFilters)
const useClientFilters = () =>
  useClientFiltersStore((s) => s.currentClientFilters)
const useClientFiltersActions = () => useClientFiltersStore((s) => s.actions)

export { useAdminClientFilters, useClientFilters, useClientFiltersActions }

import * as React from 'react'
import { Redirect, Route, useLocation } from 'react-router'
import {
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
} from '@ionic/react'
import { home, people, bag, personCircle, paperPlane } from 'ionicons/icons'

import { page } from '../analytics'
import { useAccount } from '../contexts/authContext'
import Home from '../pages/home'
import ClientDetail from '../pages/client/ClientDetail'
import { RecentSalesListPage } from '../pages/client/recent-sales-list-page'
import CallListDetail from '../pages/client/CallListDetail'
import { FollowUpsListPage } from '../pages/client/follow-ups-list-page'
import ShopWith from '../pages/shopwith/shopwith'
import ProductDetail from '../pages/shopwith/product-detail'
import LookDetail from '../pages/shopwith/look-detail'
import Clients from '../pages/client/clients'
import CreateClientList from '../pages/client/create-client-list'
import QOutreachReEngage from '../pages/client/q-outreach-re-engage'
import QOutreach2Day from '../pages/client/q-outreach-2-day'
import QOutreach4Day from '../pages/client/q-outreach-4-day'
import QOutreach7Day from '../pages/client/q-outreach-7-day'
import QOutreachWithAppt from '../pages/client/q-outreach-with-appt'
import QOutreachWithoutAppt from '../pages/client/q-outreach-without-appt'
import AdminClients from '../pages/admin/clients'
import { DynamicListDetailPage } from '../pages/outreach/dynamic-list-detail-page'
import DeliveryPage from '../pages/home/delivery-page'
import ShareAssetPage from '../pages/home/share-asset-page'
import ProfilePage from '../pages/account/profile-page'
import EditProfilePage from '../pages/account/edit-profile-page'
import ProfileLookPage from '../pages/account/profile/profile-look-page'
import ProfileProductPage from '../pages/account/profile/profile-product-page'
import ShareLookPage from '../pages/shopwith/look/share-look-page'
import CreateLookPage from '../pages/shopwith/look/create-look-page'
import CreateCollagePage from '../pages/shopwith/look/create-collage-page'
import PostDetailPage from '../pages/posts/post-detail-page'
import { PrivateRoute } from '../auth/private-route'
import AdminBroadcastDetailPage from '../pages/admin/broadcasts/admin-broadcast-detail'
import AdminBroadcastDetailClientsPage from '../pages/admin/broadcasts/admin-broadcast-detail-clients'
import AdminBroadcastList from '../pages/admin/broadcasts/admin-broadcast-list'
import CreateHtmlEmailPage from '../pages/admin/broadcasts/create-html-email-page'
import NoAccess from '../pages/no-access'
import { useIsStore } from '../auth/utils'
import { ClientLoyaltyDetailPage } from '../pages/client/client-loyalty-detail-page'

const TempAppTabs = () => {
  const account = useAccount()
  const location = useLocation()
  const { isUno } = useIsStore()

  const isAssociate = account.isAssociate && !account.isAdmin
  const isAdmin = account.isAdmin

  React.useEffect(() => {
    page()
  }, [location])

  return (
    <IonTabs>
      <IonRouterOutlet>
        <Redirect exact path="/tabs" to="/tabs/home" />
        <Route path="/tabs/home" component={Home} exact />
        <Route path="/tabs/home/outreach/dynamic/:outreachId" exact>
          <DynamicListDetailPage backRef="/tabs/home" />
        </Route>
        <Route path="/tabs/home/delivery/:deliveryId" exact>
          <DeliveryPage />
        </Route>
        <Route path="/tabs/home/asset/:assetId/share" exact>
          <ShareAssetPage />
        </Route>
        <Route path="/tabs/home/post/:postId" exact>
          <PostDetailPage />
        </Route>
        <Route path="/tabs/home/asset/:lookId" exact>
          <LookDetail />
        </Route>
        <Route path="/tabs/home/profileLook/:profileLookId" exact>
          <ProfileLookPage />
        </Route>

        <PrivateRoute
          exact
          path="/tabs/clients"
          requiredSettings={['clienteling']}
        >
          <Clients />
        </PrivateRoute>
        <Route exact path="/tabs/clients/client/:clientId/redeem-loyalty">
          <ClientLoyaltyDetailPage />
        </Route>

        <PrivateRoute
          exact
          path="/tabs/clients/list/create"
          requiredSettings={['clienteling']}
        >
          <CreateClientList />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/tabs/clients/list/recent-sales"
          requiredSettings={['clienteling']}
        >
          <RecentSalesListPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/tabs/clients/list/follow-ups"
          requiredSettings={['clienteling']}
        >
          <FollowUpsListPage />
        </PrivateRoute>
        <Route
          path="/tabs/clients/list/q-with-appointment"
          component={QOutreachWithAppt}
          exact
        />
        <Route
          path="/tabs/clients/list/q-without-appointment"
          component={QOutreachWithoutAppt}
          exact
        />
        <Route
          path="/tabs/clients/list/q-re-engage"
          component={QOutreachReEngage}
          exact
        />
        <Route
          path="/tabs/clients/list/q-2day-follow-up"
          component={QOutreach2Day}
          exact
        />
        <Route
          path="/tabs/clients/list/q-4day-follow-up"
          component={QOutreach4Day}
          exact
        />
        <Route
          path="/tabs/clients/list/q-7day-follow-up"
          component={QOutreach7Day}
          exact
        />
        <PrivateRoute
          exact
          path="/tabs/clients/outreach/:callListId"
          requiredSettings={['clienteling']}
        >
          <CallListDetail />
        </PrivateRoute>

        {/* ShopWith Routes */}
        <PrivateRoute
          exact
          path="/tabs/shopwith"
          requiredSettings={['clienteling', 'social']}
        >
          <ShopWith />
        </PrivateRoute>
        <Route path="/*/product/:pid" exact>
          <ProductDetail />
        </Route>
        <Route path="/tabs/shopwith/look/create" exact>
          <CreateLookPage />
        </Route>
        <Route path="/tabs/shopwith/look/create-collage" exact>
          <CreateCollagePage />
        </Route>
        <Route
          path="/tabs/shopwith/look/detail/:lookId"
          component={LookDetail}
          exact
        />
        <Route path="/tabs/shopwith/look/detail/:lookId/share" exact>
          <ShareLookPage />
        </Route>

        {/* Profile Tab Routes */}
        <Route path="/tabs/profile" exact>
          <ProfilePage />
        </Route>
        <Route path="/tabs/profile/edit" exact>
          <EditProfilePage />
        </Route>
        <Route path="/tabs/profile/post/:profileLookId" exact>
          <ProfileLookPage />
        </Route>
        <Route path="/tabs/profile/top-pick/:profileProductId" exact>
          <ProfileProductPage />
        </Route>

        <Route path="/tabs/*/client/:id" exact>
          <ClientDetail />
        </Route>

        {/**********
         * ADMIN TAB ROUTES *
         ***********/}
        <Route path="/tabs/admin/clients" component={AdminClients} />
        {/* ********* */}
        {/* MARKETING */}
        {/* ********* */}
        {/* Broadcasts */}
        <PrivateRoute
          exact
          path="/tabs/admin/broadcasts"
          requiredSettings={['marketing']}
        >
          {isUno() ? (
            <NoAccess title="Store Broadcasts" />
          ) : (
            <AdminBroadcastList />
          )}
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/tabs/admin/broadcasts/detail/:broadcastId"
          requiredSettings={['marketing']}
        >
          <AdminBroadcastDetailPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/tabs/admin/broadcasts/detail/:broadcastId/clients"
          requiredSettings={['marketing']}
        >
          <AdminBroadcastDetailClientsPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/tabs/admin/broadcasts/detail/:broadcastId/clients/client/:id"
          requiredSettings={['marketing']}
        >
          <ClientDetail />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/tabs/admin/broadcasts/detail/:broadcastId/html-email"
          requiredSettings={['marketing']}
        >
          <CreateHtmlEmailPage />
        </PrivateRoute>
      </IonRouterOutlet>
      {isAssociate ? (
        <IonTabBar slot="bottom">
          <IonTabButton tab="home" href="/tabs/home">
            <IonIcon icon={home} />
            <IonLabel className="uppercase">Home</IonLabel>
          </IonTabButton>
          <IonTabButton tab="clients" href="/tabs/clients">
            <IonIcon icon={people} />
            <IonLabel className="uppercase">Clients</IonLabel>
          </IonTabButton>
          <IonTabButton tab="shopwith" href="/tabs/shopwith">
            <IonIcon icon={bag} />
            <IonLabel className="uppercase">ShopWith</IonLabel>
          </IonTabButton>
          <IonTabButton tab="profile" href="/tabs/profile">
            <IonIcon icon={personCircle} />
            <IonLabel className="uppercase">Profile</IonLabel>
          </IonTabButton>
        </IonTabBar>
      ) : isAdmin ? (
        <IonTabBar slot="bottom">
          <IonTabButton tab="/tabs/home" href="/tabs/home">
            <IonIcon icon={home} />
            <IonLabel className="uppercase">Home</IonLabel>
          </IonTabButton>
          <IonTabButton tab="stylist" href="/tabs/admin/clients">
            <IonIcon icon={people} />
            <IonLabel className="uppercase">Clients</IonLabel>
          </IonTabButton>
          <IonTabButton tab="adminClients" href="/tabs/admin/broadcasts">
            <IonIcon icon={paperPlane} />
            <IonLabel className="uppercase">Broadcasts</IonLabel>
          </IonTabButton>
          <IonTabButton tab="shopwith" href="/tabs/shopwith">
            <IonIcon icon={bag} />
            <IonLabel className="uppercase">ShopWith</IonLabel>
          </IonTabButton>
        </IonTabBar>
      ) : (
        <IonTabBar slot="bottom"></IonTabBar>
      )}
    </IonTabs>
  )
}

export default TempAppTabs

import { ComponentProps } from 'react'

type Props = Pick<ComponentProps<'button'>, 'disabled' | 'type' | 'onClick'> & {
  label?: string
}

function CheckInButton({ disabled, label, onClick, type = 'button' }: Props) {
  return (
    <button
      disabled={disabled}
      type={type}
      onClick={onClick}
      className="bg-ion-color-primary text-ion-color-secondary rounded-full px-20 py-3 text-3xl font-semibold disabled:opacity-20"
    >
      {label}
    </button>
  )
}

export { CheckInButton }

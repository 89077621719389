import Avatar from '../../components/avatar'

import type { MRT_ColumnDef } from 'material-react-table'
import type { Stat } from '../../hooks/use-stats'

const columns: Array<MRT_ColumnDef<Stat>> = [
  {
    id: 'photo',
    header: '',
    columnDefType: 'display',

    Cell: ({ row }) => <Avatar user={row.original} />,
    muiTableBodyCellProps: {
      className: 'w-[80px] max-w-[80px] min-w-[80px]',
    },
    muiTableHeadCellProps: {
      className: 'w-[80px] max-w-[80px] min-w-[80px]',
    },
  },
  { accessorKey: 'firstName', header: 'First Name' },
  { accessorKey: 'lastName', header: 'Last Name' },
  { accessorKey: 'storeNames', header: 'Stores' },
  {
    accessorKey: 'outreach1',
    header: 'Outreach Today',
    muiTableBodyCellProps: { className: 'bg-slate-200' },
  },
  {
    accessorKey: 'outreach7',
    header: 'Outreach 7 Days',
    muiTableBodyCellProps: { className: 'bg-slate-200' },
  },
  {
    accessorKey: 'outreach30',
    header: 'Outreach 30 Days',
    muiTableBodyCellProps: { className: 'bg-slate-200' },
  },
  { accessorKey: 'smsCount', header: 'Text' },
  { accessorKey: 'emailCount', header: 'Email' },
  { accessorKey: 'letterCount', header: 'Letter' },
  { accessorKey: 'phoneCount', header: 'Phone' },
  { accessorKey: 'outreachCount', header: 'Outreach Total' },
]

export { columns }

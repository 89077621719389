function composeFromEmail({
  name,
  domain,
}: {
  name?: string | null
  domain?: string | null
}) {
  if (!name || !domain) return null
  return `${name.toLowerCase()}@${domain}`
}

export { composeFromEmail }

import { useIonAlert } from '@ionic/react'

import { ApprovalButton } from './approval-button'

function ApprovalButtons({
  disabled,
  onApprove,
  onDeny,
  onSchedule,
}: {
  disabled?: boolean
  onApprove: () => void
  onDeny: (note: string) => void
  onSchedule: () => void
}) {
  const [presentAlert] = useIonAlert()

  return (
    <div className="flex justify-center gap-3">
      <ApprovalButton onClick={onApprove} disabled={disabled}>
        Approve
      </ApprovalButton>
      <ApprovalButton disabled={disabled} onClick={onSchedule}>
        Schedule
      </ApprovalButton>
      <ApprovalButton
        danger
        onClick={() =>
          presentAlert({
            header: 'Share your feedback',
            buttons: [
              {
                text: 'Cancel',
                role: 'cancel',
              },
              {
                text: 'Deny',
                cssClass: 'text-ion-color-danger',
                handler: (value) => onDeny(value.note),
              },
            ],
            inputs: [
              {
                name: 'note',
                label: 'Feedback',
              },
            ],
            backdropDismiss: false,
          })
        }
        disabled={disabled}
      >
        Deny
      </ApprovalButton>
    </div>
  )
}
export { ApprovalButtons }

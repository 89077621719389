import {
  serverItemSchema,
  isoDateSchema,
  nullableString,
} from '../utils/schema'
import { profileBaseSchema } from './profile.schema'

const noteDetailSchema = serverItemSchema.extend({
  createdAt: isoDateSchema,
  note: nullableString,
  user: profileBaseSchema,
})

export { noteDetailSchema }

import { IonSpinner } from '@ionic/react'
import { useParams } from 'react-router-dom'

import { useBroadcastClientCounts } from '../queries'

function SubscriberCount() {
  const { broadcastId } = useParams<{ broadcastId: string }>()

  const clientCounts = useBroadcastClientCounts(broadcastId)

  return (
    <p className="text-ion-color-secondary flex items-center text-lg font-semibold">
      Total Subscribers:{' '}
      {clientCounts.data ? (
        clientCounts.data.totalCount
      ) : (
        <IonSpinner name="dots" duration={1000} />
      )}
    </p>
  )
}

export { SubscriberCount }

import { useInfiniteQuery, useQuery } from '@tanstack/react-query'

import { usePushToken } from '../utils/use-push-token'
import { fetchActiveStations, fetchActiveStation, fetchStations } from './api'

import type { TerminalType } from './rotate.schema'

const checkInQueryKeys = {
  all: [{ entity: 'checkIn' }],
  terminals: () => [{ ...checkInQueryKeys.all[0], scope: 'terminals' }],
  activeTerminals: () => [
    { ...checkInQueryKeys.all[0], scope: 'terminals', status: 'active' },
  ],
  terminal: ({
    deviceToken,
    type,
  }: {
    deviceToken: string | undefined
    type: TerminalType
  }) => [{ ...checkInQueryKeys.terminals()[0], deviceToken, type }],
}

function useActiveStations() {
  const deviceToken = usePushToken()

  return useInfiniteQuery({
    queryKey: checkInQueryKeys.activeTerminals(),
    queryFn: () => fetchActiveStations({ deviceToken: deviceToken.data }),
    enabled: Boolean(deviceToken.data),
  })
}

function useMyTerminal(type: TerminalType) {
  const deviceToken = usePushToken()

  return useQuery({
    queryKey: checkInQueryKeys.terminal({
      deviceToken: deviceToken.data,
      type,
    }),
    queryFn: () => fetchActiveStation({ deviceToken: deviceToken.data, type }),
    enabled: Boolean(deviceToken.data),
  })
}

function useTerminals() {
  return useQuery({
    queryKey: checkInQueryKeys.terminals(),
    queryFn: fetchStations,
  })
}

export { checkInQueryKeys, useActiveStations, useMyTerminal, useTerminals }

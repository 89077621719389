import * as React from 'react'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
} from '@ionic/react'
import { format, formatISO, parseISO } from 'date-fns'

import type { DatetimeChangeEventDetail } from '@ionic/react'

type Props = {
  disabled?: boolean
  label: string
  value: string | null
  onChange: (e: CustomEvent<DatetimeChangeEventDetail>) => void
  onReset: () => void
}

function SchedulePicker({ disabled, label, value, onChange, onReset }: Props) {
  const ionDateTimeRef = React.useRef<HTMLIonDatetimeElement>(null)
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <div className="flex flex-nowrap items-center justify-between gap-2 md:gap-10">
      {value ? (
        <p className="text-ion-color-yellow grow text-lg">{`Scheduled for ${format(
          parseISO(value),
          "MMM dd, yyyy 'at' h:mm aaa"
        )}`}</p>
      ) : null}
      {!disabled ? (
        <>
          <IonButton
            className="grow"
            expand="block"
            size="small"
            color="tertiary"
            onClick={() => setIsOpen(true)}
            style={{
              '--border-radius': '8px',
              '--background': 'var(--ion-color-base)',
            }}
          >
            {value ? 'Choose A Different Date' : label}
          </IonButton>
          <IonModal
            isOpen={isOpen}
            onDidDismiss={() => setIsOpen(false)}
            style={{
              '--width': '290px',
              '--height': '350px',
              '--border-radius': '8px',
            }}
          >
            <IonContent>
              <IonDatetime
                ref={ionDateTimeRef}
                style={{
                  '--background': 'var(--ion-color-primary)',
                  '--background-rgb': '0, 0, 0',
                }}
                min={formatISO(Date.now())}
                value={value && formatISO(new Date(value))} // convert to date and reformat as ISO otherwise day after is highlighted (related to Z instead of offset hours??)
                color="yellow"
                presentation="date-time"
                onIonChange={(e) => {
                  onChange && onChange(e)
                }}
              >
                <IonButtons slot="buttons">
                  <IonButton
                    onClick={() => {
                      ionDateTimeRef.current?.cancel(true)
                    }}
                  >
                    Cancel
                  </IonButton>
                  <div>
                    <IonButton
                      onClick={() => {
                        onReset()
                        ionDateTimeRef.current?.reset('')
                        ionDateTimeRef.current?.cancel(true)
                      }}
                    >
                      Reset
                    </IonButton>
                    <IonButton
                      color="yellow"
                      onClick={async () => {
                        if (!ionDateTimeRef.current) return
                        await ionDateTimeRef.current.confirm(true)
                      }}
                    >
                      Confirm
                    </IonButton>
                  </div>
                </IonButtons>
              </IonDatetime>
            </IonContent>
          </IonModal>
        </>
      ) : null}
    </div>
  )
}

export { SchedulePicker }

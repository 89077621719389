import type { JsonValue } from 'type-fest'
import type { DefaultTheme, CustomTheme } from './custom/unlayer-theme-defaults'
import type { ServerUnlayerDesign } from './unlayer.schema'

function convertServerUnlayerDesign(unlayerDesign: ServerUnlayerDesign) {
  return {
    ...unlayerDesign,
    body: {
      ...unlayerDesign.body,
      headers: unlayerDesign.body.headers ?? [],
      footers: unlayerDesign.body.footers ?? [],
    },
  }
}
// HACK: Type guard to check if a value is an object - Totally faking a custom typeguard to make it work
// HACK: more extensive typeguard ideal so will look into some methods to accomplish
const isObject = (val: unknown): val is DefaultTheme => {
  return val !== null && typeof val === 'object' && !Array.isArray(val)
}

function isLogo(str: string) {
  return str.toLowerCase().startsWith('oneshop')
}

function updateDesignString(
  designStr: string,
  defaultTheme: DefaultTheme,
  customTheme: CustomTheme
) {
  if (!customTheme) return designStr

  for (const key in defaultTheme) {
    if (defaultTheme.hasOwnProperty(key)) {
      const defaultValue = (defaultTheme as Record<string, any>)[key]
      const customValue = customTheme
        ? (customTheme as Record<string, any>)[key]
        : undefined

      if (isObject(defaultValue) && isObject(customValue)) {
        // If the value is an object, recurse
        designStr = updateDesignString(designStr, defaultValue, customValue)
      } else if (customValue !== undefined) {
        // If the value is not an object and there's a custom value, replace it
        const regex = isLogo(defaultValue)
          ? new RegExp(`https://assets.unlayer.com.*?${defaultValue}`, 'gi')
          : new RegExp(defaultValue, 'gi')

        designStr = designStr.replace(regex, customValue)
      }
    }
  }

  return designStr
}

function replaceThemeValues(
  template: JsonValue,
  config: { defaultTheme: DefaultTheme; customTheme: CustomTheme }
) {
  let strTemplate = JSON.stringify(template)

  const updatedDesignStr = updateDesignString(
    strTemplate,
    config.defaultTheme,
    config.customTheme
  )
  return JSON.parse(updatedDesignStr)
}

export { convertServerUnlayerDesign, replaceThemeValues }

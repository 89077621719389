import { useIonActionSheet } from '@ionic/react'

function useShareShopWith() {
  const [p] = useIonActionSheet()

  function present({
    onSend,
    onShare,
  }: {
    onSend: () => void
    onShare: () => void
  }) {
    p({
      cssClass: 'action-sheet',
      buttons: [
        {
          text: 'Share',
          handler: onShare,
        },
        { text: 'Send To Client', handler: onSend },
        {
          text: 'Cancel',
          role: 'cancel',
        },
      ],
    })
  }

  return [present] as const
}

export { useShareShopWith }

import { useIonRouter } from '@ionic/react'

import { useCurrentSequence } from '../../../stores/useSequence'
import { Chip } from '../../ui/buttons/chip'
import { Card, CardContent } from '../../ui/card'
import { SectionContent, SectionHeader, SectionWrapper } from '../section'

function SequenceButton() {
  const router = useIonRouter()

  return (
    <Chip
      size="sm"
      fill="outline"
      color="secondary"
      onClick={() => router.push('/sequence')}
    >
      Continue
    </Chip>
  )
}

function OutreachCard({ sequence }: any) {
  return (
    <Card>
      <CardContent>
        <p className="line-clamp-2 leading-none">{sequence.template.message}</p>
        <p className="mt-auto pt-2 text-sm leading-none">
          {`${sequence.currentIndex} / ${sequence.list.length} Client${
            sequence.list.length === 1 ? '' : 's'
          }`}
        </p>
      </CardContent>
    </Card>
  )
}

function CurrentOutreachSection() {
  const currentSequence = useCurrentSequence()

  return (
    <SectionWrapper>
      <SectionHeader title="Last Outreach" action={<SequenceButton />} />
      <SectionContent>
        <OutreachCard sequence={currentSequence} />
      </SectionContent>
    </SectionWrapper>
  )
}
export default CurrentOutreachSection

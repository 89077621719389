import { IonCard, IonCardContent, IonCol, IonGrid, IonRow } from '@ionic/react'

import * as commonStrings from '../../utils/common-strings'
import { getDateString } from '../../utils/date-helpers'
import CardItemHeader from './CardItemHeader'
import CardItemPhotos from './CardItemPhotos'

function NoteContent({ meta, photos }) {
  return (
    <IonCardContent>
      <IonGrid style={{ marginTop: 0 }}>
        <IonRow>
          <IonCol size={12}>
            {meta.map((item, index) => {
              if (!item.label || !item.value) return null

              return (
                <div key={index}>
                  <span style={{ fontWeight: 'bold' }}>{item.label}</span>
                  {item.value}
                </div>
              )
            })}
          </IonCol>
          <CardItemPhotos photosArray={photos} />
        </IonRow>
      </IonGrid>
    </IonCardContent>
  )
}

function CardItemNote({ note }) {
  const dateString = getDateString(note)

  return (
    <IonCard color={commonStrings.secondary}>
      <CardItemHeader
        user={note}
        date={dateString}
        subtitle={commonStrings.Note}
      />
      <NoteContent
        photos={note.photos}
        meta={[{ label: 'Note: ', value: note.body }]}
      />
    </IonCard>
  )
}

export default CardItemNote

import { useIonRouter } from '@ionic/react'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import * as R from 'remeda'
import { format } from 'date-fns'

import { getFullName } from '../../utils/format-helpers'
import { composeFromEmail } from '../../utils/misc'
import { client } from '../../services/client'
import { useAccount, useUser } from '../../contexts/authContext'
import { useToast } from '../../contexts/toastContext'
import { approvalQueryKeys } from '../../admin/approvals/queries'
import { useIsStore } from '../../auth/utils'
import { campaignQueryKeys } from '../../engage/campaigns/queries'
import { createBroadcastResponseSchema } from './broadcast.schema'
import { broadcastQueryKeys } from './queries'

import type {
  BroadcastDetail,
  CreateBroadcastInputs,
  FromUserRule,
  TestBroadcastInputs,
  UpdateBroadcastInputs,
} from './broadcast.schema'
import type { ServerFilters } from '../../clients/client-filters.schema'

export type QueueBroadcastInput = {
  title: string
  date?: string | null
  smsClientIds?: Array<string> // associateOnly
  emailClientIds?: Array<string> // associateOnly
  smsMessage?: string
  emailSubject?: string
  emailMessage?: string
  smsTemplateId?: string
  emailTemplateId?: string
  collectionIdsSms?: Array<string>
  collectionIdsEmail?: Array<string>
  lookIdsSms?: Array<string>
  lookIdsEmail?: Array<string>
  productIdsSms?: Array<string>
  productIdsEmail?: Array<string>
  setAttachmentsSms?:
    | Array<{ content: string; name: string }>
    | Array<string>
    | null
  setAttachmentsEmail?:
    | Array<{ content: string; name: string }>
    | Array<string>
    | null
  type:
    | 'associate'
    | 'adminSharedOnly'
    | 'adminAssignedOnly'
    | 'adminAll'
    | 'admin'
  callListId?: string
  callListDynamicId?: string
  filter?: ServerFilters
  unlayerDesign?: any // TODO: TEMP UNTIL SCHEMA IS USED AS SOURCE OF TRUTH
  fromUserRule: FromUserRule
  fromUserId: string
  fromName: string | null
  fromEmailName: string | null
  fromEmailDomain: string | null
}

async function createAdminBroadcast(params: Partial<CreateBroadcastInputs>) {
  const {
    messageType = 'prioSmsEmail',
    fromUserId: fromUserIdParam,
    filter: filterParam,
    fromEmailDomain,
    fromEmailName,
    ...rest
  } = params

  const fromUserId = fromUserIdParam ?? null
  const fromEmail = composeFromEmail({
    name: fromEmailName,
    domain: fromEmailDomain,
  })

  const filter = {
    admin: true,
    subscribersOnly: true,
    notContacted: { day: 1 },
    ...filterParam,
  }

  const response = await client
    .post('functions/createBroadcast', {
      json: {
        filter,
        fromUserId,
        messageType,
        type: 'admin',
        fromEmail,
        ...rest,
      },
    })
    .json()

  return createBroadcastResponseSchema.parse(response).result.broadcast
}

async function deleteBroadcast(id: string) {
  return client
    .post('functions/deleteBroadcast', {
      json: {
        objectId: id,
      },
    })
    .json()
}

async function createBroadcast(params: Partial<CreateBroadcastInputs>) {
  const {
    filter: filterParam,
    fromEmailDomain,
    fromEmailName,
    messageType = 'prioSmsEmail',
    title = `Broadcast ${format(new Date(), 'MM/dd/yy')}`,
  } = params

  const fromEmail = composeFromEmail({
    name: fromEmailName,
    domain: fromEmailDomain,
  })

  const filter = {
    subscribersOnly: true,
    notContacted: { day: 1 },
    ...filterParam,
  }

  const response = await client
    .post('functions/createBroadcast', {
      json: {
        ...params,
        filter,
        fromEmail,
        messageType,
        title,
        type: 'associate',
      },
    })
    .json()

  return createBroadcastResponseSchema.parse(response).result.broadcast
}

async function sendTestBroadcast({
  broadcastId,
  emailList,
  smsList,
}: {
  broadcastId: string
  emailList: TestBroadcastInputs['emailList']
  smsList: TestBroadcastInputs['smsList']
}) {
  return client.post('functions/sendBroadcastTest', {
    json: {
      objectId: broadcastId,
      emails: emailList.map((email) => email.email).filter(Boolean).length
        ? emailList.map((email) => email.email).filter(Boolean)
        : null,
      e164s: smsList.map((sms) => sms.e164).filter(Boolean).length
        ? smsList.map((sms) => sms.e164).filter(Boolean)
        : null,
    },
  })
}

async function updateBroadcast({
  objectId,
  broadcast,
  isPartial = false,
}: {
  objectId: string
  broadcast: UpdateBroadcastInputs
  isPartial?: boolean
}) {
  const { fromEmailDomain, fromEmailName, ...rest } = broadcast
  const updatedBroadcast = isPartial
    ? {
        fromEmail: composeFromEmail({
          name: fromEmailName,
          domain: fromEmailDomain,
        }),
        ...rest,
      }
    : {
        ...rest,
        fromEmail: composeFromEmail({
          name: fromEmailName,
          domain: fromEmailDomain,
        }),
        filter: broadcast.filter ? broadcast.filter : undefined,
        emailMessage: broadcast.emailMessage || null,
        unlayerDesign: broadcast.unlayerDesign ?? null,
        smsTemplateId: broadcast.smsTemplateId ?? null,
        emailTemplateId: broadcast.emailTemplateId ?? null,
        collectionIdsSms: broadcast.collectionIdsSms ?? null,
        lookIdsSms: broadcast.lookIdsSms ?? null,
        productIdsSms: broadcast.productIdsSms ?? null,
        collectionIdsEmail: broadcast.collectionIdsEmail ?? null,
        lookIdsEmail: broadcast.lookIdsEmail ?? null,
        productIdsEmail: broadcast.productIdsEmail ?? null,
        setAttachmentsSms: broadcast.setAttachmentsSms,
        setAttachmentsEmail: broadcast.setAttachmentsEmail,
      }

  const response = await client
    .post('functions/updateBroadcast', {
      json: { objectId, ...updatedBroadcast },
    })
    .json()

  return createBroadcastResponseSchema.parse(response).result.broadcast
}

// TODO: TYPES ETC FROM AUTH CONTEXT
function composeFromFields({ user, account }: { user: any; account: any }) {
  let fromName: string | null = null
  let fromEmailName: string | null = null
  let fromEmailDomain: string | null = null

  if (user.hasSendGrid && account.sendgridDomains.length > 0) {
    fromName = user.displayName ?? getFullName(user)
    fromEmailName = user.twilioProxyEmail.split('@')[0]
    fromEmailDomain = user.twilioProxyEmail.split('@')[1]
  }

  if (user.hasSendGrid) {
    fromName = user.displayName ?? getFullName(user)
  }

  return { fromName, fromEmailName, fromEmailDomain }
}

function useCreateAdminBroadcast() {
  const queryClient = useQueryClient()

  const setToast = useToast()
  const user = useUser()
  const account = useAccount()

  return useMutation(
    (broadcastData: Partial<CreateBroadcastInputs>) =>
      createAdminBroadcast({
        fromUserRule: 'fromUser',
        fromUserId: user.objectId,
        ...composeFromFields({ user, account }),
        ...broadcastData,
      }),
    {
      onSettled: (data) => {
        queryClient.invalidateQueries(broadcastQueryKeys.list('admin'))
        if (data?.objectId)
          queryClient.invalidateQueries(
            broadcastQueryKeys.detail(data.objectId)
          )
        queryClient.invalidateQueries(campaignQueryKeys.lists())
      },
      onError: (error) => {
        if (
          error instanceof Error &&
          error.message === 'invalid email domain'
        ) {
          setToast({
            message:
              'You have not finished setting up your email. Please contact your OneShop account manager.',
          })
        }
      },
    }
  )
}

function useCreateMyBroadcast() {
  const queryClient = useQueryClient()
  const router = useIonRouter()

  const user = useUser()
  const account = useAccount()

  const { isFaherty } = useIsStore()

  return useMutation(
    (variables: Partial<CreateBroadcastInputs>) =>
      createBroadcast({
        ...variables,
        fromUserRule: 'fromUser',
        fromUserId: user.objectId,
        ...composeFromFields({ user, account }),
        requiresApproval: isFaherty() ? true : false,
      }),
    {
      onSettled: () => {
        queryClient.invalidateQueries()
      },
      onSuccess: (data) => {
        router.push(`/broadcasts/detail/${data.objectId}`)
      },
    }
  )
}

function useDeleteBroadcast() {
  const queryClient = useQueryClient()

  const setToast = useToast()

  return useMutation(deleteBroadcast, {
    onSettled: () => {
      queryClient.invalidateQueries(campaignQueryKeys.lists())
      queryClient.invalidateQueries(broadcastQueryKeys.lists())
    },
    onSuccess: () => {
      setToast({
        message: 'Your broadcast draft was successfully deleted.',
        color: 'yellow',
      })
    },
    onError: (error, variables, context) => {
      const errorMessage =
        error instanceof Error ? error.message : 'unknown error.'

      setToast({
        message: `Something went wrong trying to delete the draft: ${errorMessage}`,
      })
    },
  })
}

function useSendBroadcast() {
  const queryClient = useQueryClient()
  const setToast = useToast()

  return useMutation(
    async (broadcastId: string) =>
      client
        .post('functions/sendBroadcast', { json: { objectId: broadcastId } })
        .json<BroadcastDetail>(),
    {
      onSettled: (data, _error, variables) => {
        queryClient.invalidateQueries(broadcastQueryKeys.lists())
        queryClient.invalidateQueries(broadcastQueryKeys.detail(variables))
        queryClient.invalidateQueries(campaignQueryKeys.lists())

        queryClient.invalidateQueries({
          predicate: (q) =>
            R.equals(
              q.queryKey,
              broadcastQueryKeys.meta({ id: variables, meta: 'counts' })
            ) && data?.status === 'draft'
              ? true
              : false,
        })
      },
      onSuccess: () => {
        setToast({
          message: 'Your broadcast has been queued up to be sent!',
          color: 'yellow',
          duration: 5000,
        })
      },
      onError: (error) => {
        const errorMessage =
          error instanceof Error ? error.message : 'Unknown Error'

        setToast({
          message: `Something went wrong while saving your draft: ${errorMessage}.`,
          color: 'danger',
        })
      },
    }
  )
}

function useTestBroadcast() {
  const setToast = useToast()

  return useMutation({
    mutationFn: sendTestBroadcast,
    onSuccess: () =>
      setToast({
        message: 'You should receive your test shortly.',
        color: 'yellow',
      }),
    onError: (error) => {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown Error'
      setToast({
        message: `Something went wrong: ${errorMessage}`,
        color: 'danger',
      })
    },
  })
}

function useUpdateBroadcast() {
  const queryClient = useQueryClient()
  const setToast = useToast()

  return useMutation(updateBroadcast, {
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(approvalQueryKeys.lists())
      queryClient.invalidateQueries(campaignQueryKeys.lists())
      queryClient.invalidateQueries(broadcastQueryKeys.lists())
      queryClient.invalidateQueries(
        broadcastQueryKeys.detail(variables.objectId)
      )
    },
    onError: (error) => {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown Error'

      setToast({
        message: `Something went wrong while saving your draft: ${errorMessage}.`,
        color: 'danger',
      })
    },
  })
}

function useUpdateBroadcastFilter() {
  const queryClient = useQueryClient()
  const setToast = useToast()
  const updateBroadcast = useUpdateBroadcast()

  return useMutation(
    async ({ id, filter }: { id: string; filter: ServerFilters }) => {
      try {
        await updateBroadcast.mutateAsync({
          objectId: id,
          broadcast: { filter },
        })
      } catch (e) {
        return Promise.reject(
          new Error('Something went wrong updating the filters.')
        )
      }
    },
    {
      onSettled: (_data, _error, variables) => {
        queryClient.invalidateQueries(broadcastQueryKeys.lists())
        queryClient.invalidateQueries(broadcastQueryKeys.detail(variables.id))
      },

      onError: (error) => {
        const errorMessage =
          error instanceof Error ? error.message : 'Unknown Error'

        setToast({
          message: `Something went wrong while saving your draft: ${errorMessage}.`,
          color: 'danger',
        })
      },
    }
  )
}

export {
  useCreateAdminBroadcast,
  useCreateMyBroadcast,
  useDeleteBroadcast,
  useSendBroadcast,
  useTestBroadcast,
  useUpdateBroadcast,
  useUpdateBroadcastFilter,
}

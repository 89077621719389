import { cn } from '../../../utils/cn'

type Props = {
  bold?: boolean
  children: React.ReactNode
}

function ShopWithCardLineItem({ bold, children }: Props) {
  return (
    <div className={cn('text-sm leading-tight', { 'font-semibold': bold })}>
      {children}
    </div>
  )
}

export { ShopWithCardLineItem }

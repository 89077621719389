import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useIonRouter } from '@ionic/react'

import { client } from '../../services/client'
import { useToast } from '../../contexts/toastContext'
import { createClientOptInResponseSchema } from '../../marketing/customer-opt-in/customer-opt-in.schema'
import { heartlandQueryKeys } from './queries'
import { useHeartlandParams } from './utils'

import type { E164Number } from 'libphonenumber-js'
import type { ApplyLoyaltyReward } from '../../loyalty/loyalty.schema'

type ApplyLoyaltyParams = {
  groupId: string
  ticketId: string
  clientId: string
  rewards: Array<ApplyLoyaltyReward>
}
type RemoveRewardsParams = {
  groupId: string
  ticketId: string
}

async function applyLoyalty(params: ApplyLoyaltyParams) {
  const { groupId, ticketId, clientId, rewards } = params

  return client.post('functions/applyLoyaltyPaymentHeartland', {
    json: {
      groupId,
      ticketId,
      clientId,
      rewards,
    },
  })
}

async function createHeartlandClientOptIn(params: {
  e164: E164Number
  groupId: string
  clientId: string
}) {
  const { clientId, e164, groupId } = params

  const response = await client
    .post('functions/addClientSignUpPhoneHeartland', {
      json: {
        e164,
        clientId,
        groupId,
      },
    })
    .json()

  try {
    createClientOptInResponseSchema.parse(response)
  } catch (e) {
    return Promise.reject(new Error('Something went wrong.'))
  }
  return createClientOptInResponseSchema.parse(response).result
}

async function removeRewards(params: RemoveRewardsParams) {
  const { groupId, ticketId } = params

  return client.post('functions/removeLoyaltyRewardHeartland', {
    json: {
      groupId,
      ticketId,
    },
  })
}

function useApplyHeartlandRewards() {
  const queryClient = useQueryClient()
  const { groupId, ticketId, clientId, posUrl } = useHeartlandParams()

  const setToast = useToast()

  if (!ticketId || !clientId) throw new Error('missing parameters')

  return useMutation(
    (rewards: ApplyLoyaltyParams['rewards']) =>
      applyLoyalty({ groupId, ticketId, clientId, rewards }),
    {
      onSettled: () => {
        queryClient.invalidateQueries(
          heartlandQueryKeys.loyalty({ clientId, groupId, ticketId })
        )
      },
      onSuccess: () => {
        if (posUrl) window.location.replace(posUrl)
      },
      onError: (error) => {
        const message =
          error instanceof Error ? error.message : 'Unknown error.'

        setToast({
          message,
          color: 'danger',
        })
      },
    }
  )
}

function useCreateHeartlandOptIn() {
  const { clientId, groupId, querystring } = useHeartlandParams()

  const router = useIonRouter()
  const setToast = useToast()

  if (!clientId) throw new Error('missing parameters')

  return useMutation(
    (e164: E164Number) =>
      createHeartlandClientOptIn({ clientId, e164, groupId }),
    {
      onSuccess: ({ client }) => {
        if (client.firstName || client.lastName) {
          router.push(
            `/heartland/${groupId}/opt-in/success${querystring}`,
            'none',
            'replace'
          )
        } else {
          router.push(
            `/heartland/${groupId}/opt-in/more-info${querystring}`,
            'none',
            'replace'
          )
        }
      },

      onError: (error) => {
        const message =
          error instanceof Error
            ? error.message.toLowerCase() === 'invalid parameter e164'
              ? 'Please enter a valid phone number.'
              : error.message
            : 'Unknown error.'

        setToast({
          message,
          color: 'danger',
        })
      },
    }
  )
}

function useRemoveHeartlandRewards() {
  const queryClient = useQueryClient()
  const { clientId, groupId, ticketId } = useHeartlandParams()

  const setToast = useToast()

  if (!ticketId) throw new Error('missing parameters')

  return useMutation(() => removeRewards({ groupId, ticketId }), {
    onSettled: () => {
      queryClient.invalidateQueries(
        heartlandQueryKeys.loyalty({ clientId, groupId, ticketId })
      )
    },
    onSuccess: () => {
      window.location.reload()
    },
    onError: (error) => {
      const message = error instanceof Error ? error.message : 'Unknown error.'

      setToast({
        message,
        color: 'danger',
      })
    },
  })
}

export {
  useApplyHeartlandRewards,
  useCreateHeartlandOptIn,
  useRemoveHeartlandRewards,
}

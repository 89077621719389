import { useAccount } from '../contexts/authContext'

type Account = {
  groups?: Array<{
    clientSearchAll?: boolean
  }>
}

function useGlobalSearch() {
  const account: Account = useAccount()

  return account?.groups?.some((group) => group.clientSearchAll)
}

export { useGlobalSearch }

import { useEffect, useRef } from 'react'
import { useImmer } from 'use-immer'
import * as R from 'remeda'
import { dequal } from 'dequal/lite'

import { ayrsharePlatforms } from '../ayrshare/ayrshare-platforms'

import type { PostPlatforms } from './posts/posts.schema'
import type { Ayrshare } from '../ayrshare/ayrshare.schema'

function useMemoCompare<T>(next: T, compare: (...args: any) => boolean) {
  // Ref for storing previous value
  const previousRef = useRef<T>()
  const previous = previousRef.current
  // Pass previous and next value to compare function
  // to determine whether to consider them equal.
  const isEqual = compare(previous, next)
  // If not equal update previousRef to next value.
  // We only update if not equal so that this hook continues to return
  // the same old value if compare keeps returning true.
  useEffect(() => {
    if (!isEqual) {
      previousRef.current = next
    }
  })
  // Finally, if equal then return the previous value
  return isEqual ? previous : next
}

function useSocialChannels(ayrshares: Array<Ayrshare> | undefined) {
  const [selectedChannels, updateSelectedChannels] = useImmer<
    Record<string, Array<PostPlatforms>>
  >({})

  const queryCached = useMemoCompare(ayrshares, (prevQuery) => {
    return prevQuery && ayrshares && dequal(ayrshares, prevQuery)
  })

  useEffect(() => {
    if (!queryCached) return

    const obj: Record<string, Array<PostPlatforms>> = R.mapToObj(
      queryCached.filter((a: any) => a.type === 'business'),
      (x: any) => [
        x.objectId,
        R.intersection(
          ayrsharePlatforms.map((platform) => platform.name),
          x.activeSocialAccounts ?? []
        ),
      ]
    )

    updateSelectedChannels(obj)
  }, [queryCached, updateSelectedChannels])

  return [selectedChannels, updateSelectedChannels] as const
}

export { useSocialChannels }

import { z } from 'zod'

import {
  isoDateSchema,
  makeServerResponseSchema,
  nullableArray,
  nullableString,
  serverItemSchema,
} from '../utils/schema'

type AyrshareConnectionInput = z.infer<typeof ayrshareConnectionInputSchema>
type AyrshareSocials = z.infer<typeof ayrshareSocialsSchema>
type Ayrshare = z.infer<typeof ayrshareOverviewSchema>
type GroupConnections = z.infer<typeof groupSocialConnectionSchema>
type UserConnection = z.infer<typeof userSocialConnectionSchema>

const ayrshareSocials = [
  'instagram',
  'facebook',
  'fbg',
  'gmb',
  'tiktok',
  'linkedin',
  'twitter',
  'youtube',
] as const
const ayrshareSocialsSchema = z.enum(ayrshareSocials)

const ayrshareConnectionInputSchema = z.object({
  id: z.string(),
  type: z.enum(['account', 'group', 'user']),
})

const ayrshareErrorSchema = z.object({
  action: z.string(), // "post" what else?
  code: z.number(),
  details: z.string().optional(),
  message: z.string(),
  platform: ayrshareSocialsSchema,
  status: z.string(), // "error" what else?
  verifyCheck: z.boolean().optional(),
})

const ayrsharePostIdSchema = z.object({
  id: z.string(),
  platform: ayrshareSocialsSchema,
  postUrl: z.string().optional(), // TODO: WHY SOME RETURN UNDEFINED?
  status: z.string(),
})

const ayrshareOverviewSchema = serverItemSchema.extend({
  createdAt: isoDateSchema,
  activeSocialAccounts: nullableArray(ayrshareSocialsSchema),
  name: z.string(),
  refId: nullableString, // ??
  title: nullableString, //??
  type: z.enum(['business', 'personal']),
  level: z.enum(['account', 'group', 'user']),
})

const ayrsharesSchema = nullableArray(ayrshareOverviewSchema)

const accountSocialConnectionsSchema = serverItemSchema.extend({
  ayrshares: ayrsharesSchema,
  companyName: z.string(),
})

const groupSocialConnectionSchema = serverItemSchema.extend({
  ayrshares: ayrsharesSchema,
  companyName: z.string(),
  logo: nullableString,
  name: z.string(),
  parent: z.object({ objectId: z.string() }).nullable(),
})

const userSocialConnectionSchema = serverItemSchema.extend({
  ayrshares: ayrsharesSchema,
  displayName: z.string(),
  firstName: nullableString,
  lastName: nullableString,
  photo: nullableString,
})

const accountSocialConnectionsResponseSchema = makeServerResponseSchema(
  z.object({ data: accountSocialConnectionsSchema })
)
const defaultSocialConnectionsResponseSchema = makeServerResponseSchema(
  z.object({ data: z.array(ayrshareOverviewSchema) })
)

const groupsSocialConnectionsResponseSchema = makeServerResponseSchema(
  z.object({ data: z.array(groupSocialConnectionSchema) })
)

const userSocialConnectionResponseSchema = makeServerResponseSchema(
  z.object({ data: userSocialConnectionSchema })
)

const usersSocialConnectionResponseSchema = makeServerResponseSchema(
  z.object({ data: z.array(userSocialConnectionSchema) })
)

const ayrshareUrlResponseSchema = makeServerResponseSchema(
  z.object({ url: z.string() })
)

export type {
  Ayrshare,
  AyrshareConnectionInput,
  AyrshareSocials,
  GroupConnections,
  UserConnection,
}
export {
  accountSocialConnectionsResponseSchema,
  ayrshareErrorSchema,
  ayrshareOverviewSchema,
  ayrsharePostIdSchema,
  ayrshareSocials,
  ayrshareUrlResponseSchema,
  defaultSocialConnectionsResponseSchema,
  groupsSocialConnectionsResponseSchema,
  userSocialConnectionResponseSchema,
  usersSocialConnectionResponseSchema,
}

import * as React from 'react'
import { useQuery } from '@tanstack/react-query'

import { userSetupQueryKeys, fetchUsers } from './queries'
import { useToast } from '../../contexts/toastContext'

// todo: large number to return all users to allow better local search
const defaultPageSize = 1000

function useUserSetup() {
  const [{ pageIndex, pageSize }, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: defaultPageSize,
  })

  const setToast = useToast()

  const users = useQuery({
    queryKey: userSetupQueryKeys.setup({ page: pageIndex, pageSize }),
    queryFn: fetchUsers,
    keepPreviousData: true,
    onError(error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown Error.'

      setToast({
        message: `Something went wrong while loading users: ${errorMessage}`,
        color: 'danger',
      })
    },
  })

  const pagination = React.useMemo(
    () => ({ pageIndex, pageSize }),
    [pageIndex, pageSize]
  )

  return {
    data: users.data?.data,
    isError: users.isError,
    isPreviousData: users.isPreviousData,
    isPending: users.isLoading,
    totalCount: users.data?.count ?? 0,
    pagination,
    setPagination,
  }
}

export { useUserSetup }

import {
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonButton,
} from '@ionic/react'
import { startOfDay, subDays, subMonths, subYears } from 'date-fns'

import DatePicker from '../../components/ui/ionic/date-picker'

type Props = {
  endDate: string
  showSm?: boolean
  setEndDate: (date: string) => void
  setStartDate: (date: string) => void
  startDate: string
}
const DateSelect = ({
  showSm,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: Props) => {
  const today = new Date()

  return (
    <IonRow
      className={`ion-no-padding ion-no-margin ${
        showSm ? 'ion-hide-xl-up' : 'ion-hide-xl-down'
      }`}
    >
      <IonCol>
        <IonCard color="secondary" style={{ margin: '16px' }}>
          <IonCardHeader style={{ textAlign: 'center' }}>
            <IonCardTitle>SETTINGS</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(e) =>
                typeof e.detail.value === 'string'
                  ? setStartDate(e.detail.value)
                  : null
              }
              min={subYears(today, 5)}
              max={today}
            />

            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(e) =>
                typeof e.detail.value === 'string'
                  ? setEndDate(e.detail.value)
                  : null
              }
              min={subYears(today, 5)}
              max={today}
            />

            <IonRow className="ion-justify-content-around ion-padding-top ">
              <IonButton
                onClick={(e) => {
                  setStartDate(startOfDay(today).toISOString())
                  setEndDate(today.toISOString())
                }}
              >
                TODAY
              </IonButton>
              <IonButton
                onClick={(e) => {
                  setStartDate(subDays(today, 7).toISOString())
                  setEndDate(today.toISOString())
                }}
              >
                7 Days
              </IonButton>
              <IonButton
                onClick={(e) => {
                  setStartDate(subMonths(today, 1).toISOString())
                  setEndDate(today.toISOString())
                }}
              >
                30 Days
              </IonButton>
              <IonButton
                onClick={(e) => {
                  setStartDate(subYears(today, 1).toISOString())
                  setEndDate(today.toISOString())
                }}
              >
                1 Year
              </IonButton>
            </IonRow>
          </IonCardContent>
        </IonCard>
      </IonCol>
    </IonRow>
  )
}

export { DateSelect }

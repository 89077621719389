import type { Children } from '../../../types'

function ApprovalsListWrapper({ children }: Children) {
  return (
    <div className="mx-auto max-w-xl space-y-6 overflow-hidden">
      <div className="ion-padding-vertical space-y-7">{children}</div>
    </div>
  )
}

export { ApprovalsListWrapper }

import * as React from 'react'
import {
  IonSegment,
  IonSegmentButton,
  IonGrid,
  IonRow,
  IonCol,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from '@ionic/react'
import { isAfter, parseISO, subDays } from 'date-fns'

import OneShopIcon from '../resources/OneShop_Icon.png'
import { getOptInStatus, hasVerifiedCell } from '../utils/client-helpers'
import { isSameOrBefore } from '../utils/date-helpers'
import { useAccount } from '../contexts/authContext'
import { Client } from '../hooks/clients/queries'
import { useInfiniteList } from '../hooks/use-infinite-list'
import { useClientSorter } from '../hooks/use-sorter-action-sheet'
import Avatar from '../components/avatar'
import ClientItem from '../components/client/client-item'
import BulkMessageButton from '../components/ui/buttons/bulk-message-button'
import { Chip } from '../components/ui/buttons/chip'
import { SortButton } from '../components/ui/buttons/sort-button'
import ScrollingList from '../components/ui/scrolling-list'

type Segment = 'contacted' | 'not-contacted'
type ClientShortcut = typeof clientShortcuts[number]
const clientShortcuts = [
  'All Clients',
  'Opted-In',
  'Verified Cell',
  'Has Phone',
  'Email Only',
  'Never Contacted',
] as const

type Props = {
  clientList: Array<Client>
  children: React.ReactNode
}

function getVisibleClients(clientList: Array<Client>, segment: Segment) {
  const startDate = subDays(new Date(), 1)

  if (!clientList) return []

  if (segment === 'not-contacted') {
    return clientList.filter(
      (client) =>
        !client.lastContact ||
        isSameOrBefore(parseISO(client.lastContact.iso), startDate)
    )
  }

  return clientList.filter(
    (client) =>
      client.lastContact && isAfter(parseISO(client.lastContact.iso), startDate)
  )
}

const listPageContext = React.createContext<{
  segment: Segment
  setSegment: (segment: Segment) => void
  clientShortcut: ClientShortcut
  setClientShortcut: (shortcut: ClientShortcut) => void
  openSorterActionSheet: () => void
  clientList: Array<Client>
} | null>(null)

function useListPageContext() {
  const context = React.useContext(listPageContext)
  if (!context) throw new Error('This must be used within a ListPageDetail')

  return context
}
function ListPageDetail({ clientList, children }: Props) {
  const [segment, setSegment] = React.useState<Segment>('not-contacted')
  const [clientShortcut, setClientShortcut] =
    React.useState<ClientShortcut>('All Clients')

  const { sortCallback, openSorterActionSheet } = useClientSorter({
    property: 'lastSale',
    isDescending: true,
  })

  const visibleClients = React.useMemo(
    () =>
      [...getVisibleClients(clientList, segment)]
        .sort(sortCallback)
        .filter((client) => {
          if (clientShortcut === 'Opted-In') {
            return getOptInStatus(client.phoneNumbers) === 'OPTED_IN'
          }
          if (clientShortcut === 'Verified Cell') {
            return hasVerifiedCell(client.phoneNumbers)
          }
          if (clientShortcut === 'Has Phone') {
            return client.phoneNumbers.length > 0
          }
          if (clientShortcut === 'Email Only') {
            return client.email && client.phoneNumbers.length === 0
          }

          if (clientShortcut === 'Never Contacted') {
            return !client.lastContact
          }
          return client
        }),

    [clientShortcut, clientList, segment, sortCallback]
  )

  const value = React.useMemo(
    () => ({
      segment,
      setSegment,
      clientShortcut,
      setClientShortcut,
      openSorterActionSheet,
      clientList: visibleClients,
    }),
    [clientShortcut, openSorterActionSheet, segment, visibleClients]
  )

  return (
    <listPageContext.Provider value={value}>
      <div className="ion-padding-vertical">{children}</div>
    </listPageContext.Provider>
  )
}

function ListPageHeader({
  description,
  title,
}: {
  description?: string
  title: string
}) {
  return (
    <div className="ion-padding-horizontal">
      <h1 className="text-center text-2xl">{title}</h1>
      {description ? <p>{description}</p> : null}
      <div className="w-full py-4">
        <div className="flex items-center gap-x-3">
          <Avatar
            user={{
              photo: OneShopIcon,
              firstName: 'One',
              lastName: 'Shop',
            }}
          />
          <div>
            <p className="text-sm">Created By</p>
            <p className="text-lg font-semibold">OneShop</p>
          </div>
        </div>
      </div>
    </div>
  )
}

function ListPageSegment() {
  const { segment, setSegment } = useListPageContext()

  return (
    <div className="ion-padding-horizontal">
      <IonSegment
        value={segment}
        onIonChange={(e) => setSegment(e.detail.value as any)}
      >
        <IonSegmentButton value="not-contacted">NOT CONTACTED</IonSegmentButton>
        <IonSegmentButton value="contacted">CONTACTED</IonSegmentButton>
      </IonSegment>
    </div>
  )
}

function ListPageFilters() {
  const {
    clientList,
    clientShortcut,
    openSorterActionSheet,
    setClientShortcut,
  } = useListPageContext()
  const account = useAccount()

  return (
    <>
      <div className="mt-3">
        <ScrollingList>
          {clientShortcuts.map((filter) => {
            if (
              !account.hasPhoneVerificationCredits &&
              filter === 'Verified Cell'
            )
              return null
            if (account.hasPhoneVerificationCredits && filter === 'Has Phone')
              return null
            if (!account.optinRequired && filter === 'Opted-In') return null

            return (
              <Chip
                key={filter}
                fill={clientShortcut !== filter ? 'outline' : 'solid'}
                onClick={() => setClientShortcut(filter)}
              >
                {filter}
              </Chip>
            )
          })}
        </ScrollingList>
      </div>

      <div className="ion-margin-top">
        <IonGrid
          style={{ '--ion-grid-padding': 0 }}
          className="ion-padding-horizontal"
        >
          <IonRow className="flex-nowrap items-center">
            <IonCol className="flex-full">
              <div className="flex items-center gap-x-2">
                {clientList.length > 0 && (
                  <BulkMessageButton clientList={clientList} />
                )}
                <h3 className="text-ion-color-yellow">
                  {clientList.length} Client
                  {clientList.length === 1 ? '' : 's'}
                </h3>
              </div>
            </IonCol>

            <IonCol>
              <SortButton onClick={openSorterActionSheet} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </>
  )
}

function ListPageClientList() {
  const { clientList } = useListPageContext()
  const { infiniteListCount, doInfiniteStuff } = useInfiniteList()

  return (
    <div>
      {clientList.length > 0 ? (
        <>
          {clientList.slice(0, infiniteListCount).map((client) => (
            <ClientItem key={client.objectId} client={client} />
          ))}
          <IonInfiniteScroll
            onIonInfinite={doInfiniteStuff}
            disabled={infiniteListCount >= clientList.length}
          >
            <IonInfiniteScrollContent loadingSpinner="bubbles" />
          </IonInfiniteScroll>
        </>
      ) : (
        <div className="ion-padding-horizontal">
          <p>You have no clients on this list.</p>
        </div>
      )}
    </div>
  )
}

export {
  ListPageDetail,
  ListPageClientList,
  ListPageFilters,
  ListPageHeader,
  ListPageSegment,
}

import { IonIcon, IonItem, IonMenuToggle } from '@ionic/react'
import { openOutline } from 'ionicons/icons'

import { qRoutes } from '../menu.config'
import { MenuLabel } from './menu-label'

function Qmenu() {
  return (
    <>
      {qRoutes.map((p) => (
        <IonMenuToggle key={p.title} auto-hide="false">
          <IonItem detail={false} href={p.path} target="_blank">
            <IonIcon slot="start" icon={openOutline} />
            <MenuLabel>{p.title}</MenuLabel>
          </IonItem>
        </IonMenuToggle>
      ))}
    </>
  )
}

export { Qmenu }

import * as React from 'react'
import {
  IonPage,
  IonHeader,
  IonContent,
  IonLabel,
  IonNote,
  IonCol,
  IonGrid,
  IonRow,
  IonSegment,
  IonSegmentButton,
  useIonActionSheet,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSearchbar,
  IonSkeletonText,
} from '@ionic/react'
import { radioButtonOff, radioButtonOn } from 'ionicons/icons'
import { format, formatDistance, isBefore, parseISO } from 'date-fns'
import { matchSorter } from 'match-sorter'

import { cn } from '../../utils/cn'
import { useUser } from '../../contexts/authContext'
import { useTasks } from '../../hooks/tasks/queries'
import { useInfiniteList } from '../../hooks/use-infinite-list'
import { useTaskSorter } from '../../hooks/use-sorter-action-sheet'
import Header from '../../components/header'
import Avatar from '../../components/avatar'
import AddFab from '../../components/fab/add-fab'
import { Chip } from '../../components/ui/buttons/chip'
import { FilterButton } from '../../components/ui/buttons/filter-button'
import ScrollingList from '../../components/ui/scrolling-list'
import { SortButton } from '../../components/ui/buttons/sort-button'
import {
  ListItem,
  ListItemHeading,
  ListItemSubHeading,
} from '../../components/ui/list-item'
import { StickyHeader } from '../../components/ui/sticky-header'

type Task = NonNullable<ReturnType<typeof useTasks>['data']>[number]

const today = new Date()

function getDueString(task: Task) {
  if (task.completedAt) {
    return 'COMPLETED'
  }
  return task.due
    ? formatDistance(parseISO(task.due.iso), new Date(), {
        addSuffix: true,
      }).toUpperCase()
    : 'NO DUE DATE'
}

function TaskItem({ task }: { task: Task }) {
  const overdue = task.completedAt
    ? false
    : task.due
    ? isBefore(parseISO(task.due.iso), today)
    : false

  const color = task.completedAt ? 'success' : overdue ? 'danger' : 'secondary'

  return (
    <ListItem routerLink={`/tasks/${task.className}/${task.objectId}`}>
      <Avatar
        user={{
          firstName: task.firstName,
          lastName: task.lastName,
          photo: task.photo,
        }}
      />
      <IonLabel className="ml-4">
        <ListItemHeading>{`${task.firstName} ${task.lastName}`}</ListItemHeading>

        <ListItemSubHeading>
          {`${task.className === 'FollowUp' ? 'Task' : task.className}`}:{' '}
          {task.title ? <span>{task.title}</span> : ''}
        </ListItemSubHeading>

        <ListItemSubHeading>
          Created On: {format(parseISO(task.createdAt.iso), 'MM/dd/yyyy')}
        </ListItemSubHeading>
      </IonLabel>
      <IonNote className="ml-2" slot="end" color={color}>
        <div className="text-xs">{getDueString(task)}</div>
      </IonNote>
    </ListItem>
  )
}

function TaskItemSkeleton() {
  return (
    <ListItem>
      <div className="border-ion-color-secondary flex size-9 flex-col items-center justify-center overflow-hidden rounded-full border">
        <IonSkeletonText animated className="m-0 size-full"></IonSkeletonText>
      </div>

      <IonLabel className="ml-2">
        <h3 className="w-2/3">
          <IonSkeletonText className="m-0 w-full leading-none"></IonSkeletonText>
        </h3>

        <p className="w-2/5">
          <IonSkeletonText className="m-0 w-full leading-none"></IonSkeletonText>
        </p>

        <p className="w-2/5">
          <IonSkeletonText className="m-0 w-full leading-none"></IonSkeletonText>
        </p>
      </IonLabel>
    </ListItem>
  )
}

const taskShortcuts = [
  'All',
  'With Due Date',
  'No Due Date',
  'General Tasks',
  'Appointments',
  'Alterations',
  'Special Orders',
  'MTM',
] as const
type TaskShortcut = typeof taskShortcuts[number]

const taskFilters = ['Incomplete', 'Overdue', 'Completed'] as const
type TaskFilter = typeof taskFilters[number]

function Tasks() {
  const [segment, setSegment] = React.useState<'mine' | 'store'>('mine')

  const [taskShortcut, setTaskShortcut] = React.useState<TaskShortcut>('All')
  const [taskFilter, setTaskFilter] = React.useState<TaskFilter>('Incomplete')
  const [searchString, setSearchString] = React.useState('')

  const user = useUser()

  const { sortCallback, openSorterActionSheet } = useTaskSorter()
  const [present] = useIonActionSheet()

  const tasksQuery = useTasks()

  const { infiniteListCount, doInfiniteStuff } = useInfiniteList()

  const visibleTasks = React.useMemo(() => {
    const filteredTasks = [...(tasksQuery.data ?? [])]
      .filter((task) => {
        if (segment === 'store') return task.isPublic === true
        return task.user.objectId === user.objectId
      })
      .filter((task) => {
        if (taskShortcut === 'With Due Date') return Boolean(task.due)
        if (taskShortcut === 'No Due Date') return !Boolean(task.due)
        if (taskShortcut === 'General Tasks')
          return task.className === 'FollowUp'
        if (taskShortcut === 'Appointments')
          return task.className === 'Appointment'
        if (taskShortcut === 'Alterations')
          return task.className === 'Alteration'
        if (taskShortcut === 'Special Orders')
          return task.className === 'SpecialOrder'
        if (taskShortcut === 'MTM') return task.className === 'MTM'
        return task
      })
      .filter((task) => {
        if (taskFilter === 'Incomplete') return !task.completedAt
        if (taskFilter === 'Completed') return task.completedAt
        if (taskFilter === 'Overdue')
          return task.completedAt
            ? false
            : task.due
            ? isBefore(parseISO(task.due.iso), today)
            : false

        return task
      })
      .sort(sortCallback)
    return matchSorter(filteredTasks, searchString, {
      keys: ['ticket'],
    })
  }, [
    user.objectId,
    searchString,
    segment,
    sortCallback,
    taskFilter,
    taskShortcut,
    tasksQuery.data,
  ])

  return (
    <IonPage id="tasks">
      <AddFab />
      <IonHeader>
        <Header showMenu title="Tasks" />
      </IonHeader>
      <IonContent>
        <section>
          <StickyHeader>
            <div className="ion-padding-top ion-padding-horizontal">
              <IonSegment
                value={segment}
                onIonChange={(e) => setSegment(e.detail.value as any)}
              >
                <IonSegmentButton value="mine">MINE</IonSegmentButton>
                <IonSegmentButton value="store">STORE</IonSegmentButton>
              </IonSegment>
            </div>

            <div
              className={cn('mt-3', {
                'pointer-events-none': tasksQuery.isLoading,
              })}
            >
              <IonSearchbar
                placeholder="Search by ticket number"
                onIonChange={(e) => setSearchString(e.detail.value ?? '')}
              />
              <ScrollingList>
                {taskShortcuts.map((type) => {
                  if (
                    type === 'MTM' &&
                    tasksQuery.data?.every((task) => task.className !== 'MTM')
                  ) {
                    return null
                  }

                  return (
                    <Chip
                      key={type}
                      fill={taskShortcut !== type ? 'outline' : 'solid'}
                      onClick={() => setTaskShortcut(type)}
                    >
                      {type}
                    </Chip>
                  )
                })}
              </ScrollingList>
            </div>

            <div className="ion-margin-top">
              <IonGrid
                style={{ '--ion-grid-padding': 0 }}
                className="ion-padding-horizontal"
              >
                <IonRow className="ion-nowrap ion-align-items-center">
                  <IonCol className="flex-full">
                    <div className="flex items-center gap-x-2">
                      <h2 className="text-ion-color-yellow ml-2">
                        {visibleTasks.length} Tasks
                      </h2>
                    </div>
                  </IonCol>

                  <IonCol>
                    <SortButton onClick={openSorterActionSheet} />
                  </IonCol>
                  <IonCol>
                    <FilterButton
                      onClick={() =>
                        present({
                          cssClass: 'action-sheet',
                          buttons: [
                            ...taskFilters.map((filter) => ({
                              text: filter,
                              cssClass: '*:justify-start',
                              icon:
                                taskFilter === filter
                                  ? radioButtonOn
                                  : radioButtonOff,
                              handler: () => setTaskFilter(filter),
                            })),
                            {
                              text: 'Cancel',
                              role: 'cancel',
                            },
                          ],
                        })
                      }
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </StickyHeader>

          <div className="ion-padding-bottom">
            {tasksQuery.data ? (
              <>
                {visibleTasks.length > 0 ? (
                  <>
                    {visibleTasks.slice(0, infiniteListCount).map((task) => (
                      <TaskItem key={task.objectId} task={task} />
                    ))}
                    <IonInfiniteScroll
                      onIonInfinite={doInfiniteStuff}
                      disabled={infiniteListCount >= visibleTasks.length}
                    >
                      <IonInfiniteScrollContent loadingSpinner="bubbles" />
                    </IonInfiniteScroll>
                  </>
                ) : (
                  <div className="ion-padding">
                    <p>You have no tasks on this list.</p>
                  </div>
                )}
              </>
            ) : (
              Array(10)
                .fill(null)
                .map((_item, idx) => <TaskItemSkeleton key={idx} />)
            )}
          </div>
        </section>
      </IonContent>
    </IonPage>
  )
}

export default Tasks

import * as React from 'react'
import { IonCol, IonContent, IonPage, IonRow, useIonRouter } from '@ionic/react'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

import { useToast } from '../../contexts/toastContext'
import { PhoneKeypadInput } from '../../components/ui/phone-keypad-input'
import { useCheckInActions } from '../../check-in/check-in-store'
import { getClientFromE164 } from '../../rotate/api'
import { RotateInfoContainer } from '../../rotate/components/rotate-info-container'
import { RotateInfoIntro } from '../../rotate/components/rotate-info-intro'

import type { CountryCode } from 'libphonenumber-js'

function CashierCheckInPage() {
  const [isLoading, setisLoading] = React.useState(false)
  const [error, setError] = React.useState<string | null>(null)

  const { resetClientInfo, setCheckInMethod, setClientInfo } =
    useCheckInActions()

  const router = useIonRouter()
  const setToast = useToast()

  async function handleCheckIn({
    phoneNumber,
    country,
  }: {
    phoneNumber: string
    country: CountryCode
  }) {
    const parsedNumber = parsePhoneNumberFromString(phoneNumber, country)

    if (!parsedNumber?.isValid())
      return setToast({
        message: 'You have entered an invalid phone number.',
        color: 'danger',
      })
    setCheckInMethod({ phone: parsedNumber.number.toString() })
    setisLoading(true)

    // CHECK IN E164
    try {
      const response = await getClientFromE164(parsedNumber.number)
      setClientInfo({ phoneNumber: parsedNumber.number as string })

      if (response.client)
        return router.push(
          `/rotate/cashier/loyalty-info/${response.client.objectId}`,
          'forward',
          'pop'
        )

      return router.push(`/rotate/cashier/add-customer`, 'forward', 'pop')
    } catch (error) {
      console.error(error)
      resetClientInfo()
      if (error instanceof Error) setError(error.message)
    } finally {
      setisLoading(false)
    }
  }

  return (
    <IonPage>
      <IonContent className="ion-padding">
        <IonRow className="h-full">
          <IonCol
            size="6"
            className="flex h-full flex-1 flex-col items-center justify-center space-y-6"
          >
            <RotateInfoContainer>
              <div></div>
              <div className="space-y-3 self-center text-center">
                <RotateInfoIntro>
                  Enter phone number to check in customer.
                </RotateInfoIntro>
                <p className="text-ion-color-danger">{error}</p>
              </div>
            </RotateInfoContainer>
          </IonCol>
          <IonCol
            size="6"
            className="flex h-full flex-1 flex-col items-center justify-center gap-6"
          >
            <PhoneKeypadInput
              disabled={isLoading}
              altLabel="Check In"
              onConfirm={handleCheckIn}
            />
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  )
}

export { CashierCheckInPage }

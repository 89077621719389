import { Link } from 'react-router-dom'

import { cn } from '../../utils/cn'

function HeaderWrapper({
  children,
  disabled,
}: {
  children: React.ReactNode
  disabled: boolean
}) {
  return (
    <div className={cn({ 'pointer-events-none': disabled })}>{children}</div>
  )
}

function ItemButton({
  children,
  onClick,
}: {
  children: React.ReactNode
} & Pick<React.ComponentPropsWithoutRef<'button'>, 'onClick'>) {
  return (
    <button
      onClick={onClick}
      className="text-ion-color-primary p-0 [background:unset] [text-align:unset] focus:outline-none"
    >
      {children}
    </button>
  )
}

function ItemFooterLink({
  children,
  to,
}: {
  children: React.ReactNode
  to: string
}) {
  return (
    <Link to={to} className="border-ion-color-dark rounded-md border px-3 py-1">
      {children}
    </Link>
  )
}

function ItemsMeta({
  children,
  highlight,
}: {
  children: React.ReactNode
  highlight?: boolean
}) {
  return (
    <h2 className={cn('text-base', { 'text-ion-color-yellow': highlight })}>
      {children}
    </h2>
  )
}

function SelectionsWrapper({ children }: { children: React.ReactNode }) {
  return <div className="flex">{children}</div>
}

export {
  HeaderWrapper,
  ItemButton,
  ItemFooterLink,
  ItemsMeta,
  SelectionsWrapper,
}

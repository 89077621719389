import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import { parseServerFilters } from '../../utils/compose-search-filters'
import { makeServerResponseSchema } from '../../utils/schema'
import { client } from '../../services/client'
import { useIsAdminRoute } from '../../hooks/routing'
import { filtersFormSchema } from '../../clients/client-filters.schema'
import {
  broadcastDetailBaseSchema,
  broadcastEmailOpenRateSchema,
  broadcastRoiSchema,
  fetchBroadcastClientCountResponseSchema,
  fetchBroadcastsResponseSchema,
} from './broadcast.schema'

import type { QueryContextFromKeys } from '../../utils/react-query'

type BroadcastQueryContexts = QueryContextFromKeys<typeof broadcastQueryKeys>

export const broadcastQueryKeys = {
  all: [{ entity: 'broadcast' }] as const,
  lists: () => [{ ...broadcastQueryKeys.all[0], scope: 'list' }] as const,
  list: (role: 'admin' | 'associate' = 'associate') =>
    [{ ...broadcastQueryKeys.lists()[0], role }] as const,
  details: () => [{ ...broadcastQueryKeys.all[0], scope: 'detail' }] as const,
  detail: (id: string) => [{ ...broadcastQueryKeys.details()[0], id }] as const,
  meta: ({ id, meta }: { id: string; meta: 'counts' | 'roi' | 'open rate' }) =>
    [
      {
        ...broadcastQueryKeys.details()[0],
        id,
        meta,
      },
    ] as const,
}

async function fetchBroadcasts({
  queryKey: [{ role }],
  pageParam = 0,
}: BroadcastQueryContexts['list']) {
  const pageSize = 20

  const isAdmin = role === 'admin'

  const response = await client
    .post('functions/getBroadcasts', {
      json: { page: pageParam, pageSize, admin: isAdmin },
    })
    .json()

  const parsedResult = fetchBroadcastsResponseSchema.safeParse(response)

  if (!parsedResult.success) throw new Error(parsedResult.error.message)
  return parsedResult.data.result
}

async function fetchBroadcast({
  queryKey: [{ id }],
}: BroadcastQueryContexts['detail']) {
  const response = await client
    .post('functions/getBroadcastBasicDetail', { json: { objectId: id } })
    .json()

  const parsedResult = makeServerResponseSchema(
    broadcastDetailBaseSchema
  ).safeParse(response)

  if (!parsedResult.success) throw new Error(parsedResult.error.message)

  const filters = parsedResult.data.result.broadcast.filter
  const parsedFilters = parseServerFilters(filters)

  if (parsedResult.data.result.broadcast.filter?.objectIds) {
    const selectedClients = await client
      .post('functions/getClients', {
        json: {
          objectIds: parsedResult.data.result.broadcast.filter.objectIds,
        },
      })
      .json()
    const r = makeServerResponseSchema(
      filtersFormSchema.shape.selectedClients
    ).safeParse(selectedClients)

    if (!r.success) throw new Error(r.error.message)

    parsedFilters.selectedClients = r.data.result //.selectedClients
  }

  return { ...parsedResult.data.result.broadcast, filters: parsedFilters }
}

async function fetchBroadcastClientCounts({
  queryKey: [{ id }],
}: BroadcastQueryContexts['meta']) {
  const r = await client
    .post('functions/getBroadcastDraftClientCounts', {
      json: { objectId: id },
      timeout: 600000,
    })
    .json()

  const parsedResult = fetchBroadcastClientCountResponseSchema.safeParse(r)

  if (!parsedResult.success) throw new Error(parsedResult.error.message)
  return parsedResult.data.result
}

async function fetchBroadcastRoi({
  queryKey: [{ id }],
}: BroadcastQueryContexts['meta']) {
  const response = await client
    .extend({ timeout: 60000 })
    .post('functions/getBroadcastRoi', {
      json: { objectId: id },
    })
    .json()

  const parsedResult =
    makeServerResponseSchema(broadcastRoiSchema).safeParse(response)

  if (!parsedResult.success) throw new Error(parsedResult.error.message)
  return parsedResult.data.result
}

async function fetchBroadcastEmailOpenRate({
  queryKey: [{ id }],
}: BroadcastQueryContexts['meta']) {
  const response = await client
    .post('functions/getBroadcastEmailEngagement', {
      json: { objectId: id },
    })
    .json()

  const parsedResult = makeServerResponseSchema(
    broadcastEmailOpenRateSchema
  ).safeParse(response)

  if (!parsedResult.success) throw new Error(parsedResult.error.message)
  return parsedResult.data.result
}

function useBroadcasts() {
  const isAdminRoute = useIsAdminRoute()

  return useInfiniteQuery({
    queryKey: broadcastQueryKeys.list(isAdminRoute ? 'admin' : 'associate'),
    queryFn: fetchBroadcasts,
    getNextPageParam: (lastPage) =>
      lastPage.hasNextPage ? lastPage.page + 1 : undefined,
  })
}

function useBroadcastClientCounts(broadcastId: string) {
  return useQuery({
    queryKey: broadcastQueryKeys.meta({ id: broadcastId, meta: 'counts' }),
    queryFn: fetchBroadcastClientCounts,
    select: (data) => ({
      smsClientCount: data.smsClientCount ?? 0,
      emailClientCount: data.emailClientCount ?? 0,
      totalCount:
        data.smsClientCount != null && data.emailClientCount != null
          ? data.smsClientCount + data.emailClientCount
          : null,
    }),
  })
}

function useBroadcastDetail(id: string) {
  return useQuery({
    queryKey: broadcastQueryKeys.detail(id),
    queryFn: fetchBroadcast,
    select: ({
      filters,
      attachments,
      collections,
      looks,
      products,
      ...data
    }) => ({
      ...data,
      attachmentsSms: data.attachmentsSms ?? attachments,
      attachmentsEmail: data.attachmentsEmail ?? attachments,
      collectionsSms: data.collectionsSms ?? collections,
      collectionsEmail: data.collectionsEmail ?? collections,
      looksSms: data.looksSms ?? looks,
      looksEmail: data.looksEmail ?? looks,
      productsSms: data.productsSms ?? products,
      productsEmail: data.productsEmail ?? products,
      filters,
    }),
  })
}

function useBroadcastRoi(id: string) {
  return useQuery({
    queryKey: broadcastQueryKeys.meta({ id, meta: 'roi' }),
    queryFn: fetchBroadcastRoi,
    retry: false,
  })
}

function useBroadcastOpenRate(id: string) {
  return useQuery({
    queryKey: broadcastQueryKeys.meta({ id, meta: 'open rate' }),
    queryFn: fetchBroadcastEmailOpenRate,
  })
}

function useBroadcastStats() {
  const { broadcastId } = useParams<{ broadcastId: string }>()
  const roi = useBroadcastRoi(broadcastId)
  const openRate = useBroadcastOpenRate(broadcastId)

  return {
    openRate,
    roi,
  }
}

export {
  useBroadcasts,
  useBroadcastClientCounts,
  useBroadcastDetail,
  useBroadcastOpenRate,
  useBroadcastRoi,
  useBroadcastStats,
}

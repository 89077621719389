import { useQuery } from '@tanstack/react-query'
import { klona } from 'klona/json'
import * as R from 'remeda'

import { client } from '../services/client'
import { useUser } from '../contexts/authContext'
import {
  accountSocialConnectionsResponseSchema,
  groupsSocialConnectionsResponseSchema,
  usersSocialConnectionResponseSchema,
  userSocialConnectionResponseSchema,
  Ayrshare,
  defaultSocialConnectionsResponseSchema,
} from './ayrshare.schema'

import type { QueryContextFromKeys } from '../utils/react-query'

type AyrshareQueryContexts = QueryContextFromKeys<typeof ayrshareQueryKeys>

export const ayrshareQueryKeys = {
  all: [{ entity: 'ayrshare' }] as const,
  aggregate: () => [{ entity: 'ayrshare', scope: 'aggregate' }] as const,
  account: () => [{ ...ayrshareQueryKeys.all[0], scope: 'account' }] as const,
  groups: () => [{ ...ayrshareQueryKeys.all[0], scope: 'groups' }] as const,
  group: (groupId: string) =>
    [{ ...ayrshareQueryKeys.groups()[0], scope: 'groups', groupId }] as const,
  users: (groupId?: string) =>
    [{ ...ayrshareQueryKeys.all[0], scope: 'users', groupId }] as const,
  user: (id: string) => [{ ...ayrshareQueryKeys.users()[0], id }] as const,
}

async function fetchSocialConnectionsAccount() {
  const response = await client
    .post('functions/getSocialConnectionsAccount')
    .json()

  return accountSocialConnectionsResponseSchema.parse(response).result.data
}

async function fetchDefaultSocialConnections() {
  const response = await client
    .post('functions/getSocialConnectionsDefault')
    .json()

  return defaultSocialConnectionsResponseSchema.parse(response).result.data
}

async function fetchSocialConnectionsGroups() {
  const response = await client
    .post('functions/getSocialConnectionsGroups')
    .json()

  return groupsSocialConnectionsResponseSchema.parse(response).result.data
}

async function fetchSocialConnectionsUsers({
  queryKey: [{ groupId }],
}: AyrshareQueryContexts['users']) {
  const response = await client
    .post('functions/getSocialConnectionsUsers', {
      json: { filter: { groupId } },
    })
    .json()

  return usersSocialConnectionResponseSchema.parse(response).result.data
}

async function fetchSocialConnectionsUser() {
  const response = await client
    .post('functions/getSocialConnectionsUser')
    .json()

  return userSocialConnectionResponseSchema.parse(response).result.data
}

function selectSortedAyrshares(ayrshares: Array<Ayrshare> | null) {
  if (!ayrshares) return []
  return R.sortBy(klona(ayrshares), [(x) => x.createdAt.iso, 'asc'])
}

function useAccountSocialConnections() {
  return useQuery({
    queryKey: ayrshareQueryKeys.account(),
    queryFn: fetchSocialConnectionsAccount,
    select: (data) => ({
      ...data,
      ayrshares: selectSortedAyrshares(data.ayrshares),
    }),
    useErrorBoundary: true,
  })
}

function useDefaultSocialConnections<T = Array<Ayrshare>>(
  select?: (d: Array<Ayrshare>) => T
) {
  return useQuery({
    queryKey: ayrshareQueryKeys.aggregate(),
    queryFn: fetchDefaultSocialConnections,
    select,
    useErrorBoundary: true,
  })
}

function useDefaultAdminSocialConnections() {
  return useDefaultSocialConnections((d) =>
    d.filter((a) => a.level === 'account' || a.level === 'group')
  )
}

function useGroupsSocialConnections() {
  return useQuery({
    queryKey: ayrshareQueryKeys.groups(),
    queryFn: fetchSocialConnectionsGroups,
    select: (data) =>
      data.map((group) => ({
        ...group,
        ayrshares: selectSortedAyrshares(group.ayrshares),
      })),
    useErrorBoundary: true,
  })
}

function useUsersSocialConnections(groupId?: string) {
  return useQuery({
    queryKey: ayrshareQueryKeys.users(groupId),
    queryFn: fetchSocialConnectionsUsers,
    select: (data) =>
      data.map((user) => ({
        ...user,
        ayrshares: selectSortedAyrshares(user.ayrshares),
      })),
    useErrorBoundary: true,
  })
}

function useUserSocialConnections() {
  const user = useUser()

  return useQuery({
    queryKey: ayrshareQueryKeys.user(user.objectId),
    queryFn: fetchSocialConnectionsUser,
    select: (data) => ({
      ...data,
      ayrshares: selectSortedAyrshares(data.ayrshares),
    }),
    useErrorBoundary: true,
  })
}

export type { AyrshareQueryContexts }
export {
  useAccountSocialConnections,
  useDefaultSocialConnections,
  useDefaultAdminSocialConnections,
  useGroupsSocialConnections,
  useUserSocialConnections,
  useUsersSocialConnections,
}

import { z } from 'zod'
import isSameDay from 'date-fns/isSameDay'
import parseISO from 'date-fns/parseISO'

import { nullableString } from '../utils/schema'
import { filtersFormSchema } from '../clients/client-filters.schema'

type CallListFormState = z.infer<typeof callListFormSchema>

const callListFormSchema = z.object({
  name: z.string().min(1, { message: 'You must provide an outreach name.' }),
  description: z.string(),
  groupIds: z
    .array(z.string())
    .min(1, { message: 'You must select at least one store.' }),
  dateRange: z
    .object({
      startDate: z
        .string()
        .datetime({
          message: 'You must choose a valid start date.',
        })
        .nullable(),
      endDate: z
        .string()
        .datetime({
          message: 'You must choose a valid end date.',
        })
        .nullable(),
    })
    .superRefine((val, ctx) => {
      if ((val.startDate && !val.endDate) || (!val.startDate && val.endDate)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            'Both start and end date must be set (or you can reset both dates to make the outreach ongoing).',
        })
      }
      if (
        val.startDate &&
        val.endDate &&
        isSameDay(parseISO(val.startDate), parseISO(val.endDate))
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'The start and end dates cannot be the same',
        })
      }
      if (val.startDate && val.endDate && val.startDate > val.endDate) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'The start date must be before the end date.',
        })
      }
    }),
  filter: filtersFormSchema.nullable(),
  templateId: nullableString,
  campaignType: z.enum(['associate', 'admin', 'personal']),
})

export type { CallListFormState }
export { callListFormSchema }

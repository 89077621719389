import { Redirect, Route } from 'react-router-dom'

import { useAccount } from '../contexts/authContext'
import { hasRequiredSettings } from './utils'

import type { RouteProps } from 'react-router-dom'
import type { Account } from './auth.schema'
import type { Setting } from './utils'

// export function getUserSettings(
//   settings: Record<string, any>,
//   requiredSettings: Array<AppSetting>
// ) {
//   const userSettings = settings
//   const appSettingKeys = R.intersection(appSettings, typedKeys(userSettings))
//   const picked = R.pick(userSettings, appSettingKeys)
//   const theSettingsTheUserHas = Object.entries(picked)
//     .filter(([k, v]) => v)
//     .map(([k, v]) => k)

//   return Boolean(R.intersection(requiredSettings, theSettingsTheUserHas).length)
// }

function PrivateRoute(
  props: RouteProps & { requiredSettings: Array<Setting> }
) {
  const { children, requiredSettings, ...rest } = props
  const account: Account = useAccount()

  // if (account.isAdmin) {
  // }

  const hasSetting = hasRequiredSettings(
    account?.account.settings,
    requiredSettings
  )

  // console.log(R.intersection([requiredSetting], appSettingKeys).length)

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    (<Route
      {...rest}
      render={() => {
        return hasSetting ? children : <Redirect to="/access-denied" />
      }}
    />)
  );
}

export { PrivateRoute }

import * as React from 'react'
import { cva } from 'class-variance-authority'

import { cn } from '../../../utils/cn'

import type { VariantProps } from 'class-variance-authority'

type ChipProps = VariantProps<typeof chipVariants> & {
  children: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

const chipVariants = cva(
  ['overflow-visible h-auto flex whitespace-nowrap rounded-full'],
  {
    variants: {
      color: {
        secondary: [],
        light: [],
      },
      fill: {
        outline: ['border'],
        solid: ['border'],
        transparent: ['border border-transparent bg-transparent'],
      },
      size: {
        sm: ['text-xs py-1 px-2'],
        base: ['text-sm py-2 px-4'],
        lg: ['text-lg py-3 px-5'],
      },
    },
    compoundVariants: [
      {
        fill: 'solid',
        color: 'secondary',
        className: ['bg-ion-color-secondary text-ion-color-primary'],
      },
      {
        fill: 'solid',
        color: 'light',
        className: [
          'bg-ion-color-step-100 text-ion-color-secondary border-ion-color-step-100',
        ],
      },
      {
        fill: 'outline',
        color: 'secondary',
        className: ['border-ion-color-secondary text-ion-color-secondary'],
      },
      {
        fill: 'outline',
        color: 'light',
        className: ['bg-ion-color-step-100 text-ion-color-step-100'],
      },
      {
        fill: 'transparent',
        color: 'secondary',
        className: ['text-ion-color-secondary'],
      },
      {
        fill: 'transparent',
        color: 'light',
        className: ['text-ion-color-step-100'],
      },
    ],
    defaultVariants: { color: 'secondary', fill: 'solid', size: 'base' },
  }
)

const Chip = React.forwardRef<HTMLButtonElement, ChipProps>(
  ({ children, onClick, fill, size, color }, ref) => {
    return (
      <button
        ref={ref}
        className={cn(chipVariants({ color, fill, size }))}
        onClick={onClick}
      >
        <div className={cn('whitespace-nowrap')}>{children}</div>
      </button>
    )
  }
)

export { Chip }

import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { useToast } from '../../contexts/toastContext'
import { formDataSchema } from '../actions'
import { useCreateAdjustment } from '../mutations'
import { LoyaltyInput } from './loyalty-input'
import { useLoyalty } from './loyalty-redemption-detail'
import { SaveFooter } from './save-footer'

import type { FormData } from '../actions'

function AdjustCardForm({
  onCancel,
  onSubmit,
}: {
  onCancel: () => void
  onSubmit: () => void
}) {
  const setToast = useToast()
  const { loyaltyRuleId, clientId } = useLoyalty()

  const createAdjustment = useCreateAdjustment()

  const formMethods = useForm<Pick<FormData, 'value'>>({
    defaultValues: { value: '' },
    resolver: zodResolver(formDataSchema.pick({ value: true })),
  })

  function submitForm(data: Pick<FormData, 'value'>) {
    if (!loyaltyRuleId)
      return setToast({
        message: 'There is no loyaltyRuleId present.',
        color: 'danger',
      })

    createAdjustment.mutate(
      {
        value: data.value,
        clientId,
        loyaltyRuleId,
      },
      { onSuccess: () => onSubmit() }
    )
  }

  return (
    <>
      <h2 className="text-center">Add or Subtract Points</h2>
      <form className="space-y-3 p-5">
        <LoyaltyInput
          disabled={createAdjustment.isLoading}
          control={formMethods.control}
          name="value"
          label="Points"
        />
        <p className="text-ion-color-secondary text-center text-sm">
          (use negative number to subtract points)
        </p>
      </form>
      <SaveFooter
        onCancel={onCancel}
        onSuccess={() => {
          formMethods.handleSubmit(submitForm)()
        }}
      />
    </>
  )
}

export { AdjustCardForm }

import * as React from 'react'
import {
  IonChip,
  IonLabel,
  IonIcon,
  IonItem,
  IonInput,
  IonList,
  IonSpinner,
  IonButton,
} from '@ionic/react'
import { addCircleOutline, closeCircle } from 'ionicons/icons'

import { useClientTags, useSearchClientTags } from '../../hooks/tags/queries'
import {
  useCreateClientTag,
  useUpdateClientTags,
  useDeleteClientTag,
} from '../../hooks/tags/mutations'

type Props = {
  clientId: string
  mode?: 'dark' | 'light'
}

function ClientTags({ clientId, mode = 'light' }: Props) {
  const [searchString, setSearchString] = React.useState('')

  const { data: tags } = useClientTags(clientId)
  const searchTagsResult = useSearchClientTags(searchString.trim())

  const createClientTag = useCreateClientTag()
  const updateClientTags = useUpdateClientTags()
  const deleteClientTag = useDeleteClientTag()

  function handleDeleteClientTag(id: string) {
    deleteClientTag.mutate({ clientId, tagId: id })
  }

  function handleCreateNewClientTag() {
    const newTag = searchString?.trim()

    createClientTag.mutate(newTag, {
      onSuccess: (data: { objectId: string; name: string }) => {
        updateClientTags.mutate({
          clientId,
          tag: { objectId: data.objectId, name: data.name },
        })
      },
    })
    setSearchString('')
  }

  function handleSelectClientTag(tag: { objectId: string; name: string }) {
    setSearchString('')
    updateClientTags.mutate({ clientId, tag })
  }

  if (tags) {
    return (
      <>
        <div className="m-0 inline-flex w-full list-none flex-wrap p-0">
          {tags.clientTags.map((tag) => (
            <span key={tag.objectId}>
              <IonChip
                className="mb-2 border"
                disabled={tag.objectId.includes('adding')}
                key={tag.objectId}
                color={mode === 'light' ? 'secondary' : 'white'}
                outline
              >
                <IonLabel>{tag.name}</IonLabel>
                <IonIcon
                  icon={closeCircle}
                  onClick={() => handleDeleteClientTag(tag.objectId)}
                />
              </IonChip>
            </span>
          ))}

          <div className="ml-2 flex-1">
            <div className="flex items-center">
              <IonInput
                className="min-w-[120px]"
                placeholder="Add Tags"
                type="text"
                value={searchString}
                onIonChange={(e) => setSearchString(e.detail.value ?? '')}
              />
              <IonButton
                color={mode === 'light' ? 'dark' : 'light'}
                fill="clear"
                size="small"
                slot="end"
                onClick={() => handleCreateNewClientTag()}
                hidden={
                  searchString === '' ||
                  searchTagsResult.data?.some(
                    (tag) => tag.name?.trim() === searchString?.trim()
                  )
                }
              >
                <IonIcon slot="icon-only" icon={addCircleOutline} />
              </IonButton>
            </div>
          </div>
        </div>
        {searchTagsResult.isInitialLoading && (
          <div className="ion-text-center ion-padding">
            <IonSpinner color={mode === 'light' ? 'primary' : 'light'} />
          </div>
        )}
        <IonList>
          {searchTagsResult.data
            ?.filter(
              (tag) =>
                !tags.clientTags.some(
                  (existingTag) => existingTag.objectId === tag.objectId
                )
            )
            .map((clientTag) => (
              <IonItem
                color="secondary"
                key={clientTag.objectId}
                onClick={() => handleSelectClientTag(clientTag)}
              >
                <IonLabel>{clientTag.name}</IonLabel>
              </IonItem>
            ))}
        </IonList>
      </>
    )
  }

  return <IonSpinner name="dots" />
}

export { ClientTags }

import React from 'react'

const CurrentClientStateContext = React.createContext()
const CurrentClientUpdateContext = React.createContext()

function CurrentClientProvider({ children }) {
  const [currentClient, setCurrentClient] = React.useState(null)

  return (
    <CurrentClientStateContext.Provider value={currentClient}>
      <CurrentClientUpdateContext.Provider value={setCurrentClient}>
        {children}
      </CurrentClientUpdateContext.Provider>
    </CurrentClientStateContext.Provider>
  )
}

function useCurrentClient() {
  const context = React.useContext(CurrentClientStateContext)
  if (context === undefined) {
    throw new Error(
      'useCurrentClient must be used within a CurrentClientProvider'
    )
  }

  return context
}

function useSetCurrentClient() {
  const context = React.useContext(CurrentClientUpdateContext)
  if (context === undefined) {
    throw new Error(
      'useSetCurrentClient must be used within a CurrentClientProvider'
    )
  }

  return context
}

export { CurrentClientProvider, useCurrentClient, useSetCurrentClient }

import * as React from 'react'
import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLoading,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
} from '@ionic/react'
import { useParams } from 'react-router-dom'

import { getOptInStatus, hasVerifiedCell } from '../../utils/client-helpers'
import { useAccount } from '../../contexts/authContext'
import { useMyDynamicCallListDetail } from '../../hooks/outreach/queries'
import { useInfiniteList } from '../../hooks/use-infinite-list'
import { useClientSorter } from '../../hooks/use-sorter-action-sheet'
import Avatar from '../../components/avatar'
import ClientItem from '../../components/client/client-item'
import Header from '../../components/header'
import BulkMessageButton from '../../components/ui/buttons/bulk-message-button'
import { Chip } from '../../components/ui/buttons/chip'
import { SortButton } from '../../components/ui/buttons/sort-button'
import Toggle from '../../components/ui/buttons/toggle'
import ScrollingList from '../../components/ui/scrolling-list'
import { StickyHeader } from '../../components/ui/sticky-header'

type Props = {
  backRef: string
}

type Segment = 'contacted' | 'not-contacted'
type ClientShortcut = typeof clientShortcuts[number]

const clientShortcuts = [
  'All Clients',
  'Opted-In',
  'Verified Cell',
  'Has Phone',
  'Email Only',
  'Never Contacted',
] as const

function getVisibleClients(
  clientList: NonNullable<
    ReturnType<typeof useMyDynamicCallListDetail>['data']
  >['clients'],
  segment: Segment
) {
  if (segment === 'not-contacted') {
    return clientList.filter((client) => !client.callListDynamicContacted)
  }

  return clientList.filter((client) => client.callListDynamicContacted)
}

function DynamicListDetailPage({ backRef }: Props) {
  const [isOtherInfoOpen, setIsOtherInfoOpen] = React.useState(false)
  const [segment, setSegment] = React.useState<Segment>('not-contacted')
  const [clientShortcut, setClientShortcut] =
    React.useState<ClientShortcut>('All Clients')

  const account = useAccount()

  const { infiniteListCount, doInfiniteStuff } = useInfiniteList()
  const { sortCallback, openSorterActionSheet } = useClientSorter()
  const { outreachId } = useParams<{ outreachId: string }>()

  const dynamicCallListDetailQuery = useMyDynamicCallListDetail(outreachId)
  const dynamicDetail = dynamicCallListDetailQuery.data

  const visibleClients = React.useMemo(
    () =>
      [...getVisibleClients(dynamicDetail?.clients ?? [], segment)]
        .sort(sortCallback)
        .filter((client) => {
          if (clientShortcut === 'Opted-In') {
            return getOptInStatus(client.phoneNumbers) === 'OPTED_IN'
          }
          if (clientShortcut === 'Verified Cell') {
            return hasVerifiedCell(client.phoneNumbers)
          }
          if (clientShortcut === 'Has Phone') {
            return client.phoneNumbers.length > 0
          }
          if (clientShortcut === 'Email Only') {
            return client.email && client.phoneNumbers.length === 0
          }

          if (clientShortcut === 'Never Contacted') {
            return !client.lastContact
          }
          return client
        }),

    [clientShortcut, dynamicDetail?.clients, segment, sortCallback]
  )

  return (
    <IonPage>
      <IonHeader>
        <Header title="Outreach" backRef={backRef} />
      </IonHeader>
      <IonContent>
        {dynamicDetail ? (
          <>
            <div className="ion-padding">
              <h1 className="text-center">{dynamicDetail.displayName}</h1>
              <DateWrapper>
                {dynamicDetail.trigger === 'LAST_SALE' ? (
                  <p>
                    Last Sale is between {dynamicDetail.filter.end} and{' '}
                    {dynamicDetail.filter.start} days ago.
                  </p>
                ) : dynamicDetail.trigger === 'BIRTHDAY' ? (
                  <p>
                    Clients on this list have a birthday in{' '}
                    {dynamicDetail.filter.daysBefore}days.
                  </p>
                ) : null}
              </DateWrapper>

              <UserWrapper>
                {dynamicDetail.user ? (
                  <UserInfo>
                    <Avatar user={dynamicDetail.user}></Avatar>
                    <div className="ion-margin-start">
                      <p className="text-sm">Created By</p>
                      <p className="font-semibold">
                        {dynamicDetail.user.firstName}{' '}
                        {dynamicDetail.user.lastName}
                      </p>
                    </div>
                  </UserInfo>
                ) : null}

                <div className="ml-2">
                  <Toggle
                    isOpen={isOtherInfoOpen}
                    onClick={() => setIsOtherInfoOpen((prev) => !prev)}
                  />
                </div>
              </UserWrapper>

              {isOtherInfoOpen ? (
                <OtherInfoWrapper>
                  <p>{dynamicDetail.description}</p>
                </OtherInfoWrapper>
              ) : null}
            </div>
            <StickyHeader>
              <div className="ion-padding-top ion-padding-horizontal">
                <IonSegment
                  value={segment}
                  onIonChange={(e) => setSegment(e.detail.value as any)}
                >
                  <IonSegmentButton value="not-contacted">
                    NOT CONTACTED
                  </IonSegmentButton>
                  <IonSegmentButton value="contacted">
                    CONTACTED
                  </IonSegmentButton>
                </IonSegment>
              </div>

              <div className="mt-3">
                <ScrollingList>
                  {clientShortcuts.map((filter) => {
                    if (
                      !account.hasPhoneVerificationCredits &&
                      filter === 'Verified Cell'
                    )
                      return null
                    if (
                      account.hasPhoneVerificationCredits &&
                      filter === 'Has Phone'
                    )
                      return null
                    if (!account.optinRequired && filter === 'Opted-In')
                      return null

                    return (
                      <Chip
                        key={filter}
                        fill={clientShortcut !== filter ? 'outline' : 'solid'}
                        onClick={() => setClientShortcut(filter)}
                      >
                        {filter}
                      </Chip>
                    )
                  })}
                </ScrollingList>
              </div>

              <div className="ion-margin-top">
                <IonGrid
                  style={{ '--ion-grid-padding': 0 }}
                  className="ion-padding-horizontal"
                >
                  <IonRow className="ion-nowrap ion-align-items-center">
                    <IonCol className="flex-full">
                      <div className="flex items-center space-x-2">
                        {visibleClients.length > 0 && (
                          <BulkMessageButton
                            clientList={visibleClients}
                            filter={dynamicDetail.filter}
                            template={dynamicDetail.template}
                            pointers={{
                              callListDynamicId: dynamicDetail.objectId,
                            }}
                          />
                        )}
                        <h3 className="text-ion-color-secondary text-xl font-semibold">
                          {visibleClients.length} Client
                          {visibleClients.length === 1 ? '' : 's'}
                        </h3>
                      </div>
                    </IonCol>

                    <IonCol>
                      <SortButton onClick={openSorterActionSheet} />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </StickyHeader>

            <div className="ion-padding-bottom">
              {visibleClients.length > 0 ? (
                <>
                  {visibleClients.slice(0, infiniteListCount).map((client) => (
                    <ClientItem key={client.objectId} client={client} />
                  ))}
                  <IonInfiniteScroll
                    onIonInfinite={doInfiniteStuff}
                    disabled={infiniteListCount >= visibleClients.length}
                  >
                    <IonInfiniteScrollContent loadingSpinner="bubbles" />
                  </IonInfiniteScroll>
                </>
              ) : (
                <div className="ion-padding-horizontal">
                  <p>You have no clients on this list.</p>
                </div>
              )}
            </div>
          </>
        ) : dynamicCallListDetailQuery.error ? (
          <div className="ion-padding">
            <p>
              {dynamicCallListDetailQuery.error instanceof Error
                ? dynamicCallListDetailQuery.error.message
                : 'An unknown error occurred.'}
            </p>
          </div>
        ) : (
          <IonLoading isOpen message={'Loading outreach...'} />
        )}
      </IonContent>
    </IonPage>
  )
}

function DateWrapper({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex w-full items-center justify-center text-sm">
      {children}
    </div>
  )
}

function UserWrapper({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex w-full items-center justify-between">{children}</div>
  )
}

function UserInfo({ children }: { children: React.ReactNode }) {
  return <div className="flex items-center">{children}</div>
}

function OtherInfoWrapper({ children }: { children: React.ReactNode }) {
  return <div className="mt-4 space-y-2">{children}</div>
}

export { DynamicListDetailPage }

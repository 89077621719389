import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react'
import { useParams } from 'react-router-dom'

import { composeSearchFilters } from '../../../utils/compose-search-filters'
import { canEdit } from '../../../utils/forms'
import { useAccount } from '../../../contexts/authContext'
import BroadcastDetail from '../../../marketing/broadcasts/components/broadcast-detail'
import { EditBroadcastForm } from '../../../marketing/broadcasts/components/edit-broadcast-form'
import { useCreateAdminBroadcast } from '../../../marketing/broadcasts/mutations'
import { useBroadcastDetail } from '../../../marketing/broadcasts/queries'
import { convertServerUnlayerDesign } from '../../../unlayer/utils'

import type { ClonedBroadcastInput } from '../../../marketing/broadcasts/broadcast.schema'

function CampaignBroadcastPage() {
  const { broadcastId } = useParams<{ broadcastId: string }>()
  const { isAdmin } = useAccount()

  const router = useIonRouter()

  const broadcastQuery = useBroadcastDetail(broadcastId)
  const createBroadcast = useCreateAdminBroadcast()

  const showForm = isAdmin && canEdit(broadcastQuery.data?.status)

  function cloneBroadcast() {
    const broadcast = broadcastQuery.data

    if (!broadcast) throw new Error('There is no broadcast loaded.')

    const clonedBroadcast: ClonedBroadcastInput = {
      setAttachmentsSms: broadcast.attachmentsSms,
      setAttachmentsEmail: broadcast.attachmentsEmail,
      emailMessage: broadcast.emailMessage ?? '',
      emailSubject: broadcast.emailSubject ?? '',
      filter: composeSearchFilters(broadcast.filters),
      messageType: broadcast.messageType || 'prioSmsEmail',
      smsMessage: broadcast.smsMessage ?? '',
      title: `${broadcast.title} copy`,
      date: null,
      collectionIdsSms:
        broadcast.collectionsSms?.map((c) => c.objectId) || undefined,
      lookIdsSms: broadcast.looksSms?.map((l) => l.objectId) || undefined,
      productIdsSms: broadcast.productsSms?.map((p) => p.objectId) || undefined,
      collectionIdsEmail:
        broadcast.collectionsEmail?.map((c) => c.objectId) || undefined,
      lookIdsEmail: broadcast.looksEmail?.map((l) => l.objectId) || undefined,
      productIdsEmail:
        broadcast.productsEmail?.map((p) => p.objectId) || undefined,
      unlayerDesign: broadcast.unlayerDesign
        ? convertServerUnlayerDesign(broadcast.unlayerDesign)
        : undefined,
      requiresApproval: broadcast.requiresApproval,
    }

    createBroadcast.mutate(clonedBroadcast, {
      onSuccess: (data) => {
        router.push(
          `/admin/campaigns/broadcast/${data.objectId}`,
          'forward',
          'replace'
        )
      },
    })
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {router.canGoBack() ? (
              <IonBackButton color="secondary" />
            ) : (
              <IonMenuButton color="secondary" />
            )}
          </IonButtons>
          <IonTitle>{broadcastQuery.data?.title}</IonTitle>
          <IonButtons slot="end">
            <IonButton color="yellow" fill="solid" onClick={cloneBroadcast}>
              Clone
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {broadcastQuery.data ? (
          <>
            {showForm ? (
              <EditBroadcastForm
                admin
                backUrl="/admin/campaigns"
                broadcast={broadcastQuery.data}
              />
            ) : (
              <BroadcastDetail broadcast={broadcastQuery.data} />
            )}
          </>
        ) : (
          <IonLoading message="Loading Broadcast..." isOpen />
        )}
      </IonContent>
    </IonPage>
  )
}

export { CampaignBroadcastPage }

import {
  call,
  statsChart,
  document,
  home,
  people,
  calendar,
  person,
  analytics,
  peopleCircleOutline,
  peopleCircle,
  paperPlane,
  cloudUpload,
  list,
  idCard,
  bag,
  time,
  chatbubbleEllipses,
  card,
  desktop,
} from 'ionicons/icons'
import { storeNames } from '../auth/constants'

import type { Account } from '../auth/utils'

type GetRoleNames<T> = T extends `role${infer Name}` ? `role${Name}` : never

type AccountKeys = keyof Account
type RoleKey = GetRoleNames<AccountKeys>

type RoleNames = {
  [RK in RoleKey]: `${RK}_${Account[RK]['role']}`
}[RoleKey]

type StoreName = typeof storeNames[number]

type Route = {
  title: string
  path: string
  icon: string
  requiresBlast?: boolean
  roles?: Array<RoleNames>
  appSettings?: Array<'social' | 'marketing' | 'clienteling'>
  flag?: {
    exclude?: Array<StoreName>
    include?: Array<StoreName>
    user?: string
    only?: Array<StoreName>
  }
  user?: 'admin' | 'associate'
}

type Routes = Record<typeof routeSections[number], Array<Route>>
const routeSections = [
  'appPages',
  'engagePages',
  'socialPages',
  'loyaltyPages',
  'analyticsPages',
  'associatePages',
  'checkInPages',
  'accountPages',
] as const

const qRoutes: Array<Pick<Route, 'title' | 'path'>> = [
  {
    title: 'Brand Guide',
    path: 'https://drive.google.com/drive/folders/18s0WUOPBp-BRwhDFsyS3HmDr9Z3sxWVT?usp=sharing',
  },
  {
    title: 'Special Order',
    path: 'https://docs.google.com/forms/d/e/1FAIpQLSdrpthYuWp6Gx0UgavHzVz49KtxStBfVCwWSvscfk8eIFhGdA/viewform?vc=0&c=0&w=1&flr=0',
  },
  {
    title: 'Referrals',
    path: 'https://referrals.qclothier.com/login/',
  },
]

const routes: Routes = {
  appPages: [
    { title: 'Home', path: '/tabs/home', icon: home },
    {
      title: 'Clients',
      path: '/tabs/admin/clients',
      icon: people,
      appSettings: ['clienteling', 'marketing'],
      user: 'admin',
    },
    {
      title: 'Tasks',
      path: '/tasks',
      icon: calendar,
      appSettings: ['clienteling'],
      user: 'admin',
    },
    {
      title: 'Admin Inbox',
      path: '/admin/chat/inbox',
      icon: chatbubbleEllipses,
      appSettings: ['clienteling', 'marketing'],
      user: 'admin',
    },
  ],
  engagePages: [
    {
      title: 'Campaigns',
      path: '/admin/campaigns',
      icon: paperPlane,
      appSettings: ['clienteling', 'marketing'],
      user: 'admin',
    },
    {
      title: 'Approvals',
      path: '/admin/campaign-approvals',
      icon: paperPlane,
      appSettings: ['clienteling', 'marketing'],
      user: 'admin',
      flag: { only: ['faherty brand'] },
    },
    {
      title: 'Templates',
      path: '/admin/templates',
      icon: document,
      appSettings: ['clienteling', 'marketing'],
      user: 'admin',
    },
    {
      title: 'Automations',
      path: '/admin/automations',
      icon: time,
      appSettings: ['marketing'],
      user: 'admin',
    },
    {
      title: 'Dynamic Lists',
      path: '/admin/dynamic-lists',
      icon: call,
      appSettings: ['clienteling'],
      user: 'admin',
    },
    {
      title: 'Import',
      path: '/admin/import',
      icon: cloudUpload,
      appSettings: ['clienteling', 'marketing'],
      user: 'admin',
    },
  ],
  socialPages: [
    {
      title: 'Posts',
      path: '/admin/posts',
      icon: list,
      appSettings: ['social'],
      user: 'admin',
    },
    {
      title: 'Approvals',
      path: '/admin/approvals',
      icon: list,
      appSettings: ['social'],
      roles: ['roleSocial_admin'],
      user: 'admin',
    },
    {
      title: 'ShopWith',
      path: '/tabs/shopwith',
      icon: bag,
      appSettings: ['social'],
      user: 'admin',
    },
  ],
  loyaltyPages: [
    {
      title: 'Loyalty Programs',
      path: '/admin/loyalty-programs',
      icon: card,
      user: 'admin',
      appSettings: ['marketing'],
    },
  ],
  analyticsPages: [
    {
      title: 'Dashboard',
      path: '/admin/dashboard',
      icon: analytics,
      appSettings: ['clienteling', 'marketing'],
      user: 'admin',
    },
    {
      title: 'Stylist Stats',
      path: '/admin/stats/stylist',
      icon: statsChart,
      appSettings: ['clienteling'],
      user: 'admin',
    },
  ],
  associatePages: [
    {
      title: 'My Clients',
      path: '/tabs/clients',
      icon: people,
      appSettings: ['clienteling'],
      user: 'associate',
    },
    {
      title: 'My Templates',
      path: '/templates',
      icon: document,
      appSettings: ['clienteling'],
      user: 'associate',
    },
    {
      title: 'My Tasks',
      path: '/tasks',
      icon: calendar,
      appSettings: ['clienteling'],
      user: 'associate',
    },
    {
      title: 'My Broadcasts',
      path: '/broadcasts',
      icon: paperPlane,
      requiresBlast: true,
      appSettings: ['clienteling'],
      user: 'associate',
      flag: { exclude: ['unode50'] },
    },

    {
      title: 'ShopWith',
      path: '/tabs/shopwith',
      icon: bag,
      appSettings: ['clienteling'],
      user: 'associate',
    },
    {
      title: 'My Posts',
      path: '/posts',
      icon: list,
      appSettings: ['social'],
      user: 'associate',
    },
    {
      title: 'My Stats',
      path: '/stats',
      icon: statsChart,
      appSettings: ['clienteling'],
      user: 'associate',
    },
  ],
  checkInPages: [
    {
      title: 'Rotate (Loyalty)',
      path: '/rotate/opt-in?loyalty=true',
      icon: person,
      appSettings: ['clienteling', 'marketing'],
      user: 'admin',
    },
    {
      title: 'Rotate (Opt-In)',
      path: '/rotate/opt-in',
      icon: person,
      appSettings: ['clienteling', 'marketing'],
      user: 'admin',
    },
    {
      title: 'Cashier',
      path: '/rotate/cashier/check-in',
      icon: person,
      appSettings: ['clienteling', 'marketing'],
      user: 'admin',
    },
    {
      title: 'Customer Check-In',
      path: '/rotate/customer/check-in',
      icon: person,
      appSettings: ['clienteling', 'marketing'],
      user: 'admin',
    },
  ],
  accountPages: [
    {
      title: 'Account',
      path: '/account',
      icon: person,
    },
    {
      title: 'Switch Account',
      path: '/account/switch-accounts',
      icon: peopleCircle,
    },
    {
      title: 'User Setup',
      path: '/admin/user-setup',
      icon: peopleCircleOutline,
      user: 'admin',
    },
    {
      title: 'Terminal Setup',
      path: '/admin/terminal-setup',
      icon: desktop,
      user: 'admin',
    },
    {
      title: 'Social Roles',
      path: '/admin/roles',
      icon: idCard,
      appSettings: ['social'],
      roles: ['roleSocial_admin'],
      user: 'admin',
    },
    {
      title: 'Social Setup',
      path: '/account/connections',
      icon: person,
      appSettings: ['social'],
    },
  ],
}

export type { RoleNames, Route }
export { qRoutes, routes }

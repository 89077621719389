import * as React from 'react'

import type { SearchClient } from '../hooks/clients/queries'

function useWishlistSearch() {
  const [searchString, setSearchString] = React.useState('')
  const [selectedClient, setSelectedClient] =
    React.useState<SearchClient | null>(null)

  const handlers = React.useMemo(
    () => ({
      updateWishlistSearch: ({
        searchString = '',
        client,
      }: {
        searchString?: string
        client?: SearchClient | null
      }) => {
        setSearchString(searchString)
        if (client !== undefined) setSelectedClient(client)
      },
      setSearchString: (str: string) => setSearchString(str),
      setSelectedClient,
      resetSearch: () => {
        setSearchString('')
        setSelectedClient(null)
      },
    }),
    []
  )

  return {
    searchString,
    selectedClient,
    handlers,
  }
}

export { useWishlistSearch }

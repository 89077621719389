import { useQuery } from '@tanstack/react-query'

import { client } from '../services/client'

type checkAppStatusResponse = {
  result: {
    minimumReactAppVersion: string
  }
}

const statusQueryKeys = {
  all: [{ entity: 'status' }] as const,
  status: () => [{ ...statusQueryKeys.all[0], scope: 'app-version' }],
}

async function fetchStatus() {
  const response: checkAppStatusResponse = await client
    .post('functions/status')
    .json()

  return response.result
}

export function useStatus() {
  return useQuery({
    queryKey: statusQueryKeys.status(),
    queryFn: fetchStatus,
    select: (data) => ({
      ...data,
      isBeforeMinimum:
        (data.minimumReactAppVersion || '0').localeCompare(
          process.env.REACT_APP_VERSION!,
          undefined,
          { numeric: true, sensitivity: 'base' }
        ) === 1,
    }),
  })
}

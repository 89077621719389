import { useEffect } from 'react'

const event = 'DOMContentLoaded'

function useShowExtension(posUrl: string | null, show?: boolean) {
  useEffect(() => {
    console.log('useShowExtension', posUrl, show)
    console.log(document.readyState)

    function iFrameLoaded() {
      console.log('iFrameLoaded')

      if (posUrl) {
        window.parent?.postMessage(
          JSON.stringify({ command: show ? 'show' : 'hide' }),
          {
            targetOrigin: posUrl,
          }
        )
      }
    }

    if (document.readyState === 'complete') {
      iFrameLoaded()
    } else {
      document.addEventListener(event, iFrameLoaded)
    }

    return () => document.removeEventListener(event, iFrameLoaded)
  }, [posUrl, show])
}

export { useShowExtension }

import {
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonItem,
  IonText,
} from '@ionic/react'

import Avatar from '../avatar'

function CardItemHeader({
  user,
  date = undefined,
  dateColor = undefined,
  subtitle = undefined,
  otherSellers = undefined,
  onClick = undefined,
}) {
  const name = `${user?.firstName ?? '...'} ${user?.lastName ?? ''}`

  return (
    <IonCardHeader className="pb-0 pl-0 pr-2 pt-2">
      <IonItem
        color="secondary"
        lines="none"
        style={{ '--padding-start': '8px' }}
        onClick={onClick}
      >
        <div slot="start" className="mr-2 self-start">
          <Avatar user={user} />
        </div>
        <div className="mr-2">
          <IonCardTitle className="text-start leading-none">
            {name}
          </IonCardTitle>
          {otherSellers && (
            <IonCardSubtitle>
              Other Associates: {otherSellers.join(', ')}
            </IonCardSubtitle>
          )}
          <IonCardSubtitle>
            <IonText color={dateColor || undefined}>{date || '...'}</IonText>
          </IonCardSubtitle>
        </div>
        <div className="flex-1 self-start">
          <IonCardSubtitle className="float-right" color="secondary">
            {subtitle}
          </IonCardSubtitle>
        </div>
      </IonItem>
    </IonCardHeader>
  )
}

export default CardItemHeader

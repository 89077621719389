import * as React from 'react'
import {
  IonContent,
  IonButton,
  IonRow,
  IonPage,
  IonLabel,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonIcon,
  IonGrid,
  IonCol,
  IonHeader,
  IonSegment,
  IonSegmentButton,
  IonText,
} from '@ionic/react'
import { filterOutline } from 'ionicons/icons'

import OneShopIcon from '../../resources/OneShop_Icon.png'
import { getOptInStatus, hasVerifiedCell } from '../../utils/client-helpers'
import { cn } from '../../utils/cn'
import { useAccount } from '../../contexts/authContext'
import { useMyClients } from '../../hooks/clients/queries'
import { useFollowUpsList, useMyCallLists } from '../../hooks/outreach/queries'
import { useInfiniteList } from '../../hooks/use-infinite-list'
import { useClientSorter } from '../../hooks/use-sorter-action-sheet'
import Header from '../../components/header'
import Avatar from '../../components/avatar'
import ClientItem, {
  ClientItemSkeleton,
} from '../../components/client/client-item'
import AddFab from '../../components/fab/add-fab'
import { Chip } from '../../components/ui/buttons/chip'
import ScrollingList from '../../components/ui/scrolling-list'
import BulkMessageButton from '../../components/ui/buttons/bulk-message-button'
import { SortButton } from '../../components/ui/buttons/sort-button'
import { StickyHeader } from '../../components/ui/sticky-header'

const clientShortcuts = [
  'All Clients',
  'Opted-In',
  'Email Outreach',
  'Leads',
  'Verified Cell',
  'Has Phone',
  'Email Only',
  'Contacted',
  'Never Contacted',
] as const
type ClientShortcut = typeof clientShortcuts[number]
type ListShortcut = 'all' | 'manager' | 'suggested'

function Clients() {
  const [segment, setSegment] = React.useState<'clients' | 'lists'>('clients')

  const [clientShortcut, setClientShortcut] =
    React.useState<ClientShortcut>('All Clients')
  const [listShortcut, setListShortcut] = React.useState<ListShortcut>('all')

  const { sortCallback, openSorterActionSheet } = useClientSorter()

  const account = useAccount()

  const myClientsQuery = useMyClients()
  const callListsQuery = useMyCallLists()
  const followUpsQuery = useFollowUpsList()

  const { doInfiniteStuff, infiniteListCount } = useInfiniteList()

  const listCount = React.useMemo(() => {
    if (!callListsQuery.data) return '...'

    const callListLength = callListsQuery.data?.length

    return listShortcut === 'manager'
      ? callListLength
      : listShortcut === 'suggested'
      ? 1 // only showing followups list
      : callListLength + 1
  }, [callListsQuery.data, listShortcut])

  const visibleClients = [...(myClientsQuery.data ?? [])]
    .sort(sortCallback)
    .filter((client) => {
      if (clientShortcut === 'Opted-In') {
        return getOptInStatus(client.phoneNumbers) === 'OPTED_IN'
      }
      if (clientShortcut === 'Email Outreach') {
        return (
          client.email &&
          getOptInStatus(client.phoneNumbers) !== 'OPTED_IN' &&
          getOptInStatus(client.phoneNumbers) !== 'OPTED_OUT'
        )
      }
      if (clientShortcut === 'Leads') {
        return !Boolean(client.salesTotal)
      }
      if (clientShortcut === 'Verified Cell') {
        return hasVerifiedCell(client.phoneNumbers)
      }
      if (clientShortcut === 'Has Phone') {
        return client.phoneNumbers.length > 0
      }
      if (clientShortcut === 'Email Only') {
        return client.email && client.phoneNumbers.length === 0
      }
      if (clientShortcut === 'Contacted') {
        return client.lastContact
      }
      if (clientShortcut === 'Never Contacted') {
        return !client.lastContact
      }
      return client
    })

  return (
    <IonPage>
      <AddFab />
      <IonHeader>
        <Header showMenu title="Clients" />
      </IonHeader>
      <IonContent>
        <section>
          <StickyHeader>
            <div className="ion-padding-top ion-padding-horizontal">
              <IonSegment
                value={segment}
                onIonChange={(e) => setSegment(e.detail.value as any)}
              >
                <IonSegmentButton value="clients">CLIENTS</IonSegmentButton>
                <IonSegmentButton
                  disabled={[callListsQuery, followUpsQuery].some(
                    (query) => query.isLoading
                  )}
                  value="lists"
                >
                  LISTS
                </IonSegmentButton>
              </IonSegment>
            </div>

            {segment === 'clients' ? (
              <>
                <div
                  className={cn('mt-3', {
                    'pointer-events-none': myClientsQuery.isLoading,
                  })}
                >
                  <ScrollingList>
                    {clientShortcuts.map((filter) => {
                      if (
                        !account.hasPhoneVerificationCredits &&
                        filter === 'Verified Cell'
                      )
                        return null
                      if (
                        account.hasPhoneVerificationCredits &&
                        filter === 'Has Phone'
                      )
                        return null
                      if (
                        !account.optinRequired &&
                        (filter === 'Opted-In' || filter === 'Email Outreach')
                      )
                        return null

                      return (
                        <Chip
                          key={filter}
                          fill={clientShortcut === filter ? 'solid' : 'outline'}
                          onClick={() => setClientShortcut(filter)}
                        >
                          {filter}
                        </Chip>
                      )
                    })}
                  </ScrollingList>
                </div>

                <div className="ion-margin-top">
                  <IonGrid
                    style={{ '--ion-grid-padding': 0 }}
                    className="ion-padding-horizontal space-y-2"
                  >
                    <IonRow className="gap-x-2">
                      {/* <div className="flex items-center gap-x-2"> */}
                      <BulkMessageButton clientList={visibleClients} />
                      <h2 className="text-ion-color-yellow ml-2">
                        {visibleClients.length} Clients
                      </h2>
                      {/* </div> */}
                    </IonRow>
                    <IonRow className="gap-x-2">
                      {/* <div className="flex items-center gap-x-2"> */}
                      <SortButton onClick={openSorterActionSheet} />

                      <IonButton
                        routerLink="/tabs/clients/list/create"
                        style={{ '--padding-end': 0 }}
                        size="small"
                        fill="clear"
                        color="light"
                      >
                        <IonIcon slot="start" icon={filterOutline} />
                        Filter
                      </IonButton>
                      {/* </div> */}
                    </IonRow>
                  </IonGrid>
                </div>
              </>
            ) : (
              <>
                <div
                  className={cn('mt-2', {
                    'pointer-events-none': [
                      callListsQuery,
                      followUpsQuery,
                    ].some((query) => query.isLoading),
                  })}
                >
                  <ScrollingList>
                    <Chip
                      fill={listShortcut !== 'all' ? 'solid' : 'outline'}
                      onClick={() => setListShortcut('all')}
                    >
                      All Lists
                    </Chip>
                    <Chip
                      fill={listShortcut !== 'manager' ? 'solid' : 'outline'}
                      onClick={() => setListShortcut('manager')}
                    >
                      Manager Lists
                    </Chip>
                    <Chip
                      fill={listShortcut !== 'suggested' ? 'solid' : 'outline'}
                      onClick={() => setListShortcut('suggested')}
                    >
                      Suggested Lists
                    </Chip>
                  </ScrollingList>
                </div>

                <div className="ion-margin-top">
                  <IonGrid
                    style={{ '--ion-grid-padding': 0 }}
                    className="ion-padding-horizontal"
                  >
                    <IonRow className="ion-nowrap ion-align-items-center">
                      <IonCol className="flex-full">
                        <h2 className="text-ion-color-yellow ml-2">
                          {listCount} List{listCount === 1 ? '' : 's'}
                        </h2>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>
              </>
            )}
          </StickyHeader>

          <div className="ion-padding-bottom">
            {segment === 'clients' ? (
              myClientsQuery.data ? (
                <>
                  {visibleClients.length > 0 ? (
                    <>
                      {visibleClients
                        .slice(0, infiniteListCount)
                        .map((client) => (
                          <ClientItem key={client.objectId} client={client} />
                        ))}

                      <IonInfiniteScroll
                        onIonInfinite={doInfiniteStuff}
                        disabled={infiniteListCount >= visibleClients.length}
                      >
                        <IonInfiniteScrollContent loadingSpinner="bubbles" />
                      </IonInfiniteScroll>
                    </>
                  ) : (
                    <div className="ion-padding">
                      <p>You have no clients on this list.</p>
                    </div>
                  )}
                </>
              ) : (
                Array(10)
                  .fill(null)
                  .map((_item, idx) => <ClientItemSkeleton key={idx} />)
              )
            ) : null}
            {segment === 'lists' ? (
              <>
                {listShortcut === 'all' || listShortcut === 'manager' ? (
                  <>
                    {callListsQuery.data?.map((callList) => (
                      <IonItem
                        style={{
                          '--inner-padding-bottom': '.25rem',
                          '--inner-padding-top': '.25rem',
                        }}
                        routerLink={`/tabs/clients/outreach/${callList.objectId}`}
                        key={callList.objectId}
                      >
                        <Avatar user={callList.user} />

                        <IonLabel className="ml-4">
                          <h3 className="text-lg font-semibold">{`${callList.name}`}</h3>
                          <p className="text-ion-color-light text-sm">
                            {` ${callList.stats.clientCount} Client${
                              callList.stats.clientCount === 1 ? '' : 's'
                            }`}
                          </p>
                        </IonLabel>
                      </IonItem>
                    ))}
                  </>
                ) : null}
                {listShortcut === 'all' || listShortcut === 'suggested' ? (
                  <IonItem
                    style={{
                      '--inner-padding-bottom': '.25rem',
                      '--inner-padding-top': '.25rem',
                    }}
                    routerLink="/tabs/clients/list/follow-ups"
                  >
                    <Avatar
                      user={{
                        photo: OneShopIcon,
                        firstName: 'One',
                        lastName: 'Shop',
                      }}
                    />

                    <IonLabel className="ml-4">
                      <h3 className="text-lg font-semibold">Follow Ups</h3>
                      <p className="text-sm">
                        <IonText color="light">
                          {followUpsQuery.data &&
                            `${followUpsQuery.data.clients.length} Client${
                              followUpsQuery.data.clients.length === 1
                                ? ''
                                : 's'
                            }`}
                        </IonText>
                      </p>
                    </IonLabel>
                  </IonItem>
                ) : null}
              </>
            ) : null}
          </div>
        </section>
      </IonContent>
    </IonPage>
  )
}

export default Clients

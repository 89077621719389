import React, { useState } from 'react'
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonCardSubtitle,
} from '@ionic/react'
import RoiList from './RoiList'
import RoiChart, { DataSet } from './RoiChart'

interface RoiCardProps {
  data?: {
    labels: Array<string>
    datasets: Array<DataSet>
  }
  title?: string
  subtitle?: string
  addTotalRow?: boolean
  showAll?: boolean
}

export function dataSetSort(a: DataSet, b: DataSet) {
  if (a.total === b.total) {
    return a.label.localeCompare(b.label, undefined, {
      numeric: true,
      sensitivity: 'base',
    })
  }
  return a.total < b.total ? 1 : -1
}

const RoiCard: React.FC<RoiCardProps> = ({
  data,
  title,
  subtitle,
  addTotalRow,
  showAll,
}) => {
  const [roiTypesAll, setRoiTypesAll] = useState(false)
  const filteredDataSets = (data?.datasets || [])
    .sort(dataSetSort)
    .filter((data) => {
      if (data.label === 'All') return true
      if (showAll) return true
      return data.total > 0
    })
    .slice(0, 10)

  return (
    <IonCard color="secondary">
      {(title || subtitle) && (
        <IonCardHeader style={{ textAlign: 'center' }}>
          <IonCardTitle>{title}</IonCardTitle>
          <IonCardSubtitle style={{ textAlign: 'center' }}>
            {subtitle}
          </IonCardSubtitle>
        </IonCardHeader>
      )}
      <IonCardContent>
        <RoiList
          datasets={filteredDataSets}
          label="TYPE"
          onToggleViewAll={setRoiTypesAll}
          toggle={roiTypesAll}
          addTotalRow={addTotalRow}
        />
        {roiTypesAll && (
          <RoiChart datasets={filteredDataSets} labels={data?.labels} />
        )}
      </IonCardContent>
    </IonCard>
  )
}

export default RoiCard

import { IonContent, IonPage, useIonRouter } from '@ionic/react'

import { useCreateClient } from '../../hooks/clients/mutations'
import { useCheckInValues } from '../../check-in/check-in-store'
import { useOptInClient } from '../../marketing/customer-opt-in/mutations'
import { CustomerCheckInForm } from '../../rotate/components/customer-check-in-form'

import type { CheckInFormInputs } from '../../schema/add-client.schema'

function PairedAddCustomerPage() {
  const router = useIonRouter()

  const initialValues = useCheckInValues()
  const createClient = useCreateClient()
  const optInClient = useOptInClient()

  function handleSubmit(formData: CheckInFormInputs) {
    createClient.mutate(formData, {
      onSuccess: (data) => {
        // if customer got here from email check-in, do not opt-in
        if (initialValues.email)
          return router.push(
            `/check-in/paired/loyalty-info?clientId=${data.result.client.objectId}`,
            'none',
            'replace'
          )

        if (!initialValues.phoneNumber) return
        optInClient.mutate(initialValues.phoneNumber, {
          onSuccess: () =>
            router.push(
              `/check-in/paired/loyalty-info?clientId=${data.result.client.objectId}`,
              'none',
              'replace'
            ),
        })
      },
    })
  }

  return (
    <IonPage>
      <IonContent>
        <div className="mx-auto flex max-w-xl flex-col items-center">
          <CustomerCheckInForm
            disabled={createClient.isLoading}
            initialValues={initialValues}
            onSubmit={handleSubmit}
          />
        </div>
      </IonContent>
    </IonPage>
  )
}

export { PairedAddCustomerPage }

import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import produce from 'immer'
import { forEachObj } from 'remeda'

// Turn the set method into an immer proxy
const immer = (config) => (set, get, api) =>
  config(
    (partial, replace) => {
      const nextState =
        typeof partial === 'function' ? produce(partial) : partial
      return set(nextState, replace)
    },
    get,
    api
  )

const createSequence = (
  set,
  { list = [], backRef = '', callListId, callListDynamicId, template }
) => ({
  callListId,
  callListDynamicId,
  list,
  currentIndex: 0,
  template: template
    ? {
        message: template.body,
        photos: template.attachments,
        optInMessage: '',
        smsTemplateId: template.objectId,
      }
    : {
        message: '',
        photos: [],
        optInMessage: '',
      },
  followUpDate: null,
  backRef,
  status: 'isUninitialized',
  setTemplate: ({
    message,
    nextContactDate,
    photos,
    smsTemplateId,
    optInMessage,
  }) => {
    return set((state) => {
      state.template = {
        message,
        photos,
        smsTemplateId,
        optInMessage,
      }
      state.followUpDate = nextContactDate
      state.status = 'isInitialized'
    })
  },
  setFollowUpDate: (nextContactDate) => {
    return set((state) => void (state.followUpDate = nextContactDate))
  },
  setBackRef: (link) => {
    return set((state) => void (state.backRef = link))
  },
  incrementCurrentIndex() {
    return set((state) => void state.currentIndex++)
  },
  resetSequence: () => {
    return set((state) => {
      state.list = []
      state.currentIndex = 0
      state.template = {
        message: '',
        photos: [],
        optInMessage: '',
      }
      state.followUpDate = null
      state.backRef = ''
      state.status = 'isUnitialized'
    })
  },
})

// const createState =
const useSequence = create(
  devtools(
    immer((set, get) => ({
      sequences: {},
      currentSequenceName: '',
      initializeSequence: (
        sequenceName,
        { list, backRef, callListId, callListDynamicId, template }
      ) => {
        // Set method passed to child passes the sequence itself as state
        function childSet(fn) {
          set((state) => {
            fn(state.sequences[sequenceName])
          })
        }
        const newSequence = createSequence(childSet, {
          list,
          backRef,
          callListId,
          callListDynamicId,
          template,
        })
        set((state) => {
          state.currentSequenceName = sequenceName
          state.sequences[sequenceName] = newSequence
        })
      },
      setCurrentSequenceName: (sequenceName) => {
        return set((state) => {
          state.currentSequenceName = sequenceName
        })
      },
      updateSequenceClientSubscription: (id, value) => {
        const seqs = get().sequences
        Object.keys(seqs).forEach((seqName) => {
          const clientIndex = seqs[seqName].list.findIndex(
            (client) => client.objectId === id
          )
          if (clientIndex !== -1) {
            return set((state) => {
              state.sequences[seqName].list[clientIndex].unsubscribedAt = value
            })
          }
        })
      },

      updateSequenceClient: (clientId, prop, data) => {
        set((state) => {
          forEachObj(state.sequences, (sequenceObj) => {
            const idx = sequenceObj.list.findIndex(
              (client) => client.objectId === clientId
            )

            if (idx !== -1) {
              sequenceObj.list[idx][prop] = data
            }
          })
        })
      },
    })),
    { enabled: !process.env.NODE_ENV === 'production' }
  )
)

const useCurrentSequence = () =>
  useSequence((s) => s.sequences[s.currentSequenceName]) || {}
const useCurrentSequenceClient = () =>
  useSequence(
    (s) =>
      s.sequences[s.currentSequenceName].list[
        s.sequences[s.currentSequenceName].currentIndex
      ]
  )
const useSequenceBackRef = () =>
  useSequence((state) => {
    const currentSequence = state.sequences[state.currentSequenceName]
    return currentSequence.backRef || '/'
  })

export {
  useSequence,
  useCurrentSequence,
  useCurrentSequenceClient,
  useSequenceBackRef,
}

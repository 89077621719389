import React from 'react'
import { IonCol, IonImg } from '@ionic/react'

import avatar from '../../resources/avatar-placeholder.png'
import { associateAddedImage } from '../../utils/common-strings'

function CardItemPhotos({ photosArray, photoSrc }) {
  return (
    <>
      {photoSrc ? (
        <IonCol size="6" class="ion-justify-content-center">
          <IonImg src={photoSrc || avatar} alt={associateAddedImage} />
        </IonCol>
      ) : null}
      {photosArray
        ? photosArray.map((photo, index) => (
            <IonCol size="6" key={index} class="ion-justify-content-center">
              <IonImg
                src={photo.url ? photo.url : photo}
                alt={associateAddedImage}
              />
            </IonCol>
          ))
        : null}
    </>
  )
}

export default CardItemPhotos

import * as React from 'react'

import { useSetCurrentClient } from '../../contexts/clientContext'
import ClientInfo from './ClientInfo'
import { ClientTags } from './client-tags'
import ClientCommunication from '../communication/ClientCommunication'

import type { SelectClientDetail } from '../../hooks/clients/queries'

type DetailProps = {
  client: NonNullable<SelectClientDetail>['ClientDetailsData']
  onCommunicationComplete?: () => void
  mode?: 'light' | 'dark'
}
const ClientDetailHeader = ({
  client,
  onCommunicationComplete,
  mode = 'light',
}: DetailProps) => {
  const setCurrentClient = useSetCurrentClient()

  const isUnsubscribed = client.unsubscribedAt || client.posActive === false

  React.useEffect(() => {
    setCurrentClient(client)
  }, [client, setCurrentClient])

  return (
    <section>
      <div className="mx-auto my-0 max-w-xl">
        <ClientInfo client={client} />
        <div className="ion-padding-horizontal">
          <ClientTags clientId={client.objectId} mode={mode} />
        </div>
      </div>
      {isUnsubscribed ? null : (
        <ClientCommunication
          client={client}
          onCommunicationComplete={onCommunicationComplete}
        />
      )}
    </section>
  )
}

export default ClientDetailHeader

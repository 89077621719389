import { IonRow, IonCol } from '@ionic/react'
import { ResourceType } from '@capawesome/capacitor-cloudinary'

import CldVideoPlayer from '../../cloudinary/cld-video-player'
import { ShopWithCard, ShopWithCardContent, ShopWithCardMedia } from '../card'

import type { LookDetail } from '../../../hooks/shopwith/looks/queries'

function LookCover({ look }: { look: LookDetail }) {
  return (
    <>
      {look.cloudinary?.data?.resource_type === ResourceType.Video ? (
        <div className="mx-auto w-full max-w-sm">
          <div className="aspect-h-16 aspect-w-9 relative w-full">
            <CldVideoPlayer publicId={look.cloudinary.data.public_id} />
          </div>
        </div>
      ) : look.cloudinary?.data?.resource_type === ResourceType.Image ? (
        <IonRow className="ion-justify-content-center">
          <IonCol
            className="flex items-center justify-center"
            sizeXs="6"
            sizeSm="4"
            sizeMd="3"
          >
            <ShopWithCard>
              <ShopWithCardContent>
                <ShopWithCardMedia
                  src={look.cloudinary.data.secure_url}
                  ratio
                />
              </ShopWithCardContent>
            </ShopWithCard>
          </IonCol>
        </IonRow>
      ) : look.coverPhoto ? (
        <IonRow className="ion-justify-content-center">
          <IonCol
            className="flex items-center justify-center"
            sizeXs="6"
            sizeSm="4"
            sizeMd="3"
          >
            <ShopWithCard>
              <ShopWithCardContent>
                <ShopWithCardMedia src={look.coverPhoto.originalSrc} ratio />
              </ShopWithCardContent>
            </ShopWithCard>
          </IonCol>
        </IonRow>
      ) : null}
    </>
  )
}

export { LookCover }

import * as React from 'react'
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonRow,
  useIonAlert,
} from '@ionic/react'
import { checkmarkCircle, ellipseOutline } from 'ionicons/icons'
import { useImmer } from 'use-immer'
import { formatPhoneNumber } from 'react-phone-number-input'

import { getOptInStatusDisplay } from '../../utils/client-helpers'
import { useAccount, useAuth } from '../../contexts/authContext'
import { useToast } from '../../contexts/toastContext'
import { useCreateOptIn } from '../../hooks/opt-in'
import { useClientContactInfo } from '../../hooks/clients/queries'
import SubscriptionEditForm from '../client/SubscriptionEditForm'
import PhoneEditForm from '../client/PhoneEditForm'

function ClientInfoDisplay({ clientId }: { clientId: string }) {
  const clientContactInfoQuery = useClientContactInfo(clientId)
  const account = useAccount()
  const auth = useAuth()

  const [present] = useIonAlert()
  const setToast = useToast()

  const [state, updateState] = useImmer({
    showDncEdit: false,
    showPhoneEdit: false,
    showEmailEdit: false,
  })

  const createOptInMutation = useCreateOptIn()

  const isEditDisabled = Object.values(state).some((item) => item === true)
  const canOptInClient =
    account.allowUserOptIn &&
    (clientContactInfoQuery.data?.defaultPhoneNumber?.optInState ===
      'NOT_INITIALIZED' ||
      clientContactInfoQuery.data?.defaultPhoneNumber?.optInState ===
        'PENDING') &&
    clientContactInfoQuery.data?.defaultPhoneNumber?.e164 &&
    clientContactInfoQuery.data?.posActive !== false &&
    !Boolean(clientContactInfoQuery.data?.unsubscribedAt)

  if (clientContactInfoQuery.data) {
    return (
      <>
        <div className="ion-padding-horizontal">
          <h2>Contact Info</h2>
        </div>
        <InfoCard>
          <IonCardContent>
            {state.showPhoneEdit ? (
              <PhoneEditForm
                clientId={clientContactInfoQuery.data.objectId}
                phoneNumbers={clientContactInfoQuery.data.phoneNumbers}
                onCancel={() =>
                  updateState((draft) => {
                    draft.showPhoneEdit = false
                  })
                }
                onSave={() =>
                  updateState((draft) => {
                    draft.showPhoneEdit = false
                  })
                }
              />
            ) : (
              <section>
                {clientContactInfoQuery.data.phoneNumbers.length === 0 ? (
                  <div className="text-ion-color-primary">
                    This client currently has no phone numbers saved.
                  </div>
                ) : (
                  <IonList>
                    {clientContactInfoQuery.data.phoneNumbers.map(
                      (phoneNumber, index) => (
                        <IonItem
                          color="secondary"
                          key={phoneNumber.objectId}
                          lines={
                            index ===
                            clientContactInfoQuery.data.phoneNumbers.length - 1
                              ? 'none'
                              : 'inset'
                          }
                        >
                          <IonLabel>
                            <div>
                              <div className="text-ion-color-primary font-semibold">
                                {phoneNumber.posName ?? 'No Label'}
                              </div>
                              <span>
                                {phoneNumber.e164
                                  ? formatPhoneNumber(
                                      phoneNumber.e164.toString()
                                    )
                                  : phoneNumber.phoneNumber}
                              </span>
                              <span>
                                {phoneNumber.verified
                                  ? ` (Verified${
                                      phoneNumber.type
                                        ? `: ${phoneNumber.type}`
                                        : ''
                                    })`
                                  : null}
                              </span>
                            </div>
                          </IonLabel>
                          {phoneNumber.default && (
                            <IonIcon
                              color={
                                clientContactInfoQuery.data.unsubscribedAt
                                  ? 'tertiary'
                                  : 'primary'
                              }
                              slot="end"
                              icon={checkmarkCircle}
                            />
                          )}
                        </IonItem>
                      )
                    )}
                  </IonList>
                )}
                <div className="flex justify-end">
                  <IonButton
                    size="small"
                    fill="outline"
                    disabled={isEditDisabled}
                    onClick={() =>
                      updateState((draft) => {
                        draft.showPhoneEdit = true
                      })
                    }
                  >
                    Edit
                  </IonButton>
                </div>
              </section>
            )}
          </IonCardContent>
        </InfoCard>

        {clientContactInfoQuery.data.email && (
          <InfoCard>
            <IonCardContent>
              <section>
                <IonList>
                  <IonItem color="secondary" lines="none">
                    <IonLabel>
                      <div>
                        <div className="text-ion-color-primary font-semibold">
                          Email
                        </div>
                        <span>{clientContactInfoQuery.data.email}</span>
                      </div>
                    </IonLabel>
                  </IonItem>
                </IonList>
              </section>
            </IonCardContent>
          </InfoCard>
        )}

        <div className="ion-padding-horizontal">
          <h2>Subscription Info</h2>
        </div>
        <InfoCard>
          <IonCardContent>
            {state.showDncEdit ? (
              <SubscriptionEditForm
                clientId={clientContactInfoQuery.data.objectId}
                unsubscribedAt={clientContactInfoQuery.data.unsubscribedAt}
                onCancel={() =>
                  updateState((draft) => {
                    draft.showDncEdit = false
                  })
                }
                onSave={() =>
                  updateState((draft) => {
                    draft.showDncEdit = false
                  })
                }
              />
            ) : (
              <section>
                <IonItem color="secondary" lines="none">
                  <IonLabel
                    color={
                      clientContactInfoQuery.data.unsubscribedAt
                        ? 'danger'
                        : 'tertiary'
                    }
                  >
                    Do Not Contact
                  </IonLabel>
                  <IonIcon
                    slot="end"
                    color={
                      clientContactInfoQuery.data.unsubscribedAt
                        ? 'danger'
                        : 'tertiary'
                    }
                    icon={
                      clientContactInfoQuery.data.unsubscribedAt
                        ? checkmarkCircle
                        : ellipseOutline
                    }
                  />
                </IonItem>
                <div className="flex justify-end">
                  <IonButton
                    size="small"
                    fill="outline"
                    disabled={isEditDisabled}
                    onClick={() =>
                      updateState((draft) => {
                        draft.showDncEdit = true
                      })
                    }
                  >
                    Edit
                  </IonButton>
                </div>
              </section>
            )}
          </IonCardContent>
        </InfoCard>

        <InfoCard>
          <IonCardContent>
            <InfoSection>
              <SectionHeading>Subscription Status:</SectionHeading>
              <div>
                <div className="text-ion-color-primary">
                  {getOptInStatusDisplay(
                    clientContactInfoQuery.data.phoneNumbers
                  )}
                </div>
              </div>
            </InfoSection>
          </IonCardContent>
        </InfoCard>

        {clientContactInfoQuery.data.posActive === false ? (
          <InfoCard>
            <IonCardContent>
              <InfoSection>
                <SectionHeading>POS Status:</SectionHeading>
                <div className="text-ion-color-danger">Unsubscribed</div>
              </InfoSection>
            </IonCardContent>
          </InfoCard>
        ) : null}
        {canOptInClient ? (
          <IonRow className="ion-justify-content-center">
            <IonCol size="auto">
              <IonButton
                color="yellow"
                disabled={createOptInMutation.isLoading}
                onClick={async () => {
                  if (account.optInTermsRequired) {
                    present({
                      message:
                        'You are about to automatically opt-in a client. By clicking "Accept" you acknowledge that you have their written permission to do so.',
                      buttons: [
                        'Cancel',
                        {
                          text: 'Accept',
                          handler: async () => {
                            if (
                              !clientContactInfoQuery.data.defaultPhoneNumber
                            ) {
                              return setToast({
                                message:
                                  'This client does not have a valid phone number to opt-in.',
                              })
                            }
                            await auth.acceptOptInClientTerms()
                            createOptInMutation.mutate(
                              {
                                defaultPhoneNumber:
                                  clientContactInfoQuery.data
                                    .defaultPhoneNumber,
                                client: {
                                  objectId: clientId,
                                },
                                manualAccept: true,
                              },
                              {
                                onSuccess: () => {
                                  setToast({
                                    message: `This client was successfully opted-in.`,
                                    color: 'yellow',
                                  })
                                },
                              }
                            )
                          },
                        },
                      ],
                    })
                  } else {
                    if (!clientContactInfoQuery.data.defaultPhoneNumber) {
                      return setToast({
                        message:
                          'This client does not have a valid phone number to opt-in.',
                      })
                    }
                    createOptInMutation.mutate({
                      defaultPhoneNumber:
                        clientContactInfoQuery.data.defaultPhoneNumber,
                      client: {
                        objectId: clientId,
                      },
                      manualAccept: true,
                    })
                  }
                }}
              >
                Opt-In Client
              </IonButton>
            </IonCol>
          </IonRow>
        ) : null}
      </>
    )
  }

  return <IonLoading isOpen message="Loading Contact Info..." />
}

function InfoCard({ children }: { children: React.ReactNode }) {
  return <IonCard className="mt-0">{children}</IonCard>
}

function InfoSection({ children }: { children: React.ReactNode }) {
  return (
    <section className="flex items-center justify-between">{children}</section>
  )
}

function SectionHeading({ children }: { children: React.ReactNode }) {
  return (
    <div className="text-ion-color-primary ml-5 font-semibold">{children}</div>
  )
}

export default ClientInfoDisplay

import React, { useState, useEffect } from 'react'
import {
  IonSearchbar,
  IonItem,
  IonList,
  IonRow,
  IonSpinner,
  IonLabel,
  IonIcon,
} from '@ionic/react'
import { checkmarkCircle } from 'ionicons/icons'

import * as commonStrings from '../utils/common-strings'
import { searchService } from '../services/searchService'

const SearchBar = ({
  clientIdCallback,
  clientCallback,
  clearOnSelect,
  initialText,
}) => {
  const [searchValue, setSearchValue] = useState()
  const [selectedValue, setSelectedValue] = useState()
  const [searchResults, setSearchResults] = useState([])
  const [loading, setLoading] = useState(false)

  // initialize with initialText - this is the Client if it exists
  useEffect(() => {
    setSelectedValue(initialText)
    setSearchValue(initialText)
    setSearchResults([])
  }, [initialText])

  useEffect(() => {
    if (searchValue && (!selectedValue || selectedValue !== searchValue)) {
      setLoading(true)
      /**
       * TODO:
       * Commenting out the below two lines seems to fix bug
       * where search field resets on select if initialText is prepopulated
       */
      // if (clientIdCallback) clientIdCallback(null)
      // if (clientCallback) clientCallback(null)
      searchService
        .searchClients({
          filter: {
            name: searchValue,
          },
        })
        .then(
          (results) => {
            if (results.filter && results.filter.name === searchValue) {
              setLoading(false)
              setSearchResults(results.clients)
            }
          },
          (error) => {
            setLoading(false)
          }
        )
    }
  }, [searchValue, selectedValue])

  const handleSelect = (object) => {
    setSelectedValue(object.firstName + ' ' + object.lastName)
    setSearchResults([])
    setSearchValue(
      clearOnSelect ? '' : object.firstName + ' ' + object.lastName
    )
    if (clientIdCallback) clientIdCallback(object.objectId)
    if (clientCallback) clientCallback(object)
  }

  return (
    <>
      <IonRow>
        <IonSearchbar
          debounce={0}
          type="text"
          color="light"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          animated
          placeholder={commonStrings.SearchClient}
          value={searchValue}
          onIonChange={(e) => {
            setSearchValue(e.detail.value)
          }}
          onIonClear={(e) => {
            setSearchValue(null)
            setSearchResults([])
            setSearchValue(null)
            if (clientIdCallback) clientIdCallback(null)
            if (clientCallback) clientCallback(null)
          }}
        />
      </IonRow>
      <IonRow
        class="ion-justify-content-center"
        hidden={!loading}
        style={{ marginBottom: '12px' }}
      >
        <IonSpinner />
      </IonRow>
      <IonList style={{ margin: '0px 32px 0 32px' }}>
        {searchResults.slice().map((object, index) => (
          <IonItem
            color="secondary"
            key={index}
            onClick={() => handleSelect(object)}
          >
            <div tabIndex={0} />
            <IonLabel>
              {object.firstName} {object.lastName}
            </IonLabel>
            <IonIcon hidden={!object.isMine} icon={checkmarkCircle} />
          </IonItem>
        ))}
      </IonList>
    </>
  )
}

export default SearchBar

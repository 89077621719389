import { IonRow, IonCol, IonButton, IonIcon, IonAvatar } from '@ionic/react'
import { copyOutline } from 'ionicons/icons'

import placeholder from '../../resources/avatar-placeholder.png'
import { useClipboard } from '../../hooks/use-clipboard'
import ExpandableText from '../ui/expandable-text'
import { Profile as TProfile } from '../../hooks/account/queries'

function Profile({ profile }: { profile: TProfile['user'] }) {
  const { copyToClipboard } = useClipboard()

  const profileLink = `https://oneshop.me/${profile.profileId}`

  async function handleCopyLink() {
    copyToClipboard(profileLink, 'Link copied to clipboard.')
  }

  return (
    <>
      <IonRow className="justify-center">
        <IonCol size="auto">
          <IonAvatar className="size-20">
            <img
              className="size-full object-cover"
              src={profile.photo ?? placeholder}
              alt=""
            />
          </IonAvatar>
        </IonCol>
      </IonRow>
      <IonRow className="ion-no-margin justify-center text-center">
        <IonCol size="auto" className="space-y-1">
          <p className="font-bold">{profile.displayName}</p>
          {profile.bio ? (
            <div className="max-w-prose text-left text-sm">
              <ExpandableText>{profile.bio}</ExpandableText>
            </div>
          ) : null}
        </IonCol>
      </IonRow>
      <IonRow className="items-center">
        <IonCol className="flex-1">
          <p>{profileLink}</p>
        </IonCol>
        <IonCol size="auto">
          <IonButton
            style={{
              '--padding-end': 0,
              '--padding-start': 0,
            }}
            size="small"
            fill="clear"
            color="secondary"
            onClick={handleCopyLink}
          >
            <IonIcon slot="icon-only" icon={copyOutline} />
          </IonButton>
        </IonCol>
      </IonRow>
      <IonRow className="justify-center">
        <IonCol size="auto">
          <IonButton
            style={{
              '--border-radius': '9999px',
              '--padding-start': '3rem',
              '--padding-end': '3rem',
            }}
            className="font-bold"
            size="small"
            fill="solid"
            color="secondary"
            routerLink="/tabs/profile/edit"
          >
            Edit Profile
          </IonButton>
        </IonCol>
        <IonCol size="auto">
          <IonButton
            style={{
              '--border-radius': '9999px',
              '--padding-start': '3rem',
              '--padding-end': '3rem',
            }}
            className="font-bold"
            size="small"
            fill="outline"
            color="secondary"
            routerLink="/posts"
          >
            All Posts
          </IonButton>
        </IonCol>
      </IonRow>
    </>
  )
}

export default Profile

import { useIsStore } from '../auth/utils'

function useFormatNumber() {
  const { isTristan } = useIsStore()

  return function formatNumber(number?: number) {
    const regEx = /(\d)(?=(\d{3})+(?!\d))/g

    if (isTristan()) {
      return number
        ? number.toFixed(number >= 1 ? 0 : 2).replace(regEx, '$1 ')
        : '0'
    }

    return number
      ? number.toFixed(number >= 1 ? 0 : 2).replace(regEx, '$1,')
      : '0'
  };
}

export { useFormatNumber }

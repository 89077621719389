import { useIsStore } from '../../auth/utils'
import Currency from '../Currency'
import {
  ShopWithCard,
  ShopWithCardContent,
  ShopWithCardFooter,
  ShopWithCardLineItem,
  ShopWithCardMedia,
} from './card'
import { ItemButton, ItemFooterLink } from './ui'

import type { Product } from '../../types/shopwith'

type Props = {
  product: Product
  onProductSelect?: (key: 'products', id: string) => void
  isSelected?: boolean
  shouldHideDetail?: boolean
}

function ProductItem(props: Props) {
  const {
    product,
    onProductSelect,
    isSelected,
    shouldHideDetail,
    ...restProps
  } = props
  const { isTristan } = useIsStore()

  function handleProductSelect() {
    onProductSelect && onProductSelect('products', product.objectId)
  }

  return (
    <ShopWithCard isSelected={isSelected}>
      <ItemButton onClick={handleProductSelect} {...restProps}>
        <ShopWithCardContent>
          <ShopWithCardMedia src={product.featuredImage} ratio />
          <div className="mt-2">
            <ShopWithCardLineItem bold>{product.brand}</ShopWithCardLineItem>
            {isTristan() && (
              <ShopWithCardLineItem>
                Style Code: {product.posId ?? 'N/A'}
              </ShopWithCardLineItem>
            )}
            <ShopWithCardLineItem>{product.title}</ShopWithCardLineItem>
            {product.price ? (
              <ShopWithCardLineItem bold>
                <Currency value={product.price} />
              </ShopWithCardLineItem>
            ) : null}
          </div>
        </ShopWithCardContent>
      </ItemButton>
      {shouldHideDetail !== true && (
        <ShopWithCardFooter>
          <ItemFooterLink to={`/tabs/shopwith/product/${product.objectId}`}>
            View Detail
          </ItemFooterLink>
        </ShopWithCardFooter>
      )}
    </ShopWithCard>
  )
}

export default ProductItem

import { useMutation } from '@tanstack/react-query'
import { useToast } from '../contexts/toastContext'
import { client } from '../services/client'
import {
  CreateAccountInputs,
  createAccountResponseSchema,
} from './account.schema'

async function createAccount(params: CreateAccountInputs) {
  const response = await client
    .post('functions/createAccount', {
      json: { ...params },
    })
    .json()

  return createAccountResponseSchema.parse(response).result
}

function useCreateAccount() {
  const setToast = useToast()

  return useMutation(createAccount, {
    onSuccess: () => {
      setToast({
        message: 'Account created',
        color: 'yellow',
      })
    },
    onError: (error) => {
      const errorMsg = error instanceof Error ? error.message : 'Unknown error.'
      setToast({
        message: `Something went wrong while creating your account: ${errorMsg}`,
        color: 'danger',
      })
    },
  })
}

export { useCreateAccount }

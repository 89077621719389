import * as React from 'react'
import { IonButton, IonIcon, IonModal, useIonRouter } from '@ionic/react'
import { paperPlaneOutline } from 'ionicons/icons'

import { useUser } from '../../contexts/authContext'
import { useToast } from '../../contexts/toastContext'
import { useShareShopWith } from '../../hooks/shopwith/use-share-shop-with'
import ShareShopWithModal from './share-shop-with-modal'

type Props = {
  disabled: boolean
  looks: Array<string>
}

function ShareLookButton({ disabled, looks }: Props) {
  const [isOpen, setIsOpen] = React.useState(false)

  const router = useIonRouter()
  const setToast = useToast()

  const [present] = useShareShopWith()

  const user = useUser()

  const openSendModal = () => setIsOpen(true)
  const closeSendModal = () => setIsOpen(false)

  const link =
    looks.length > 1
      ? `https://oneshop.me/collection?looks=${looks.join(',')}&stylist=${
          user.objectId
        }`
      : `https://oneshop.me/look/${looks}?stylist=${user.objectId}`

  function handleShareToProfile() {
    if (looks.length > 1)
      return setToast({
        message: `You can only share one asset at a time. Please deselect ${
          looks.length - 1
        } assets.`,
        duration: 4000,
      })

    router.push(`/tabs/shopwith/look/detail/${looks[0]}/share`)
  }

  return (
    <>
      <IonButton
        onClick={() =>
          present({
            onSend: openSendModal,
            onShare: handleShareToProfile,
          })
        }
        size="small"
        expand="block"
        disabled={disabled}
        color="yellow"
        className="uppercase tracking-wide"
      >
        Share
        <IonIcon slot="end" icon={paperPlaneOutline} />
      </IonButton>

      <IonModal isOpen={isOpen} onDidDismiss={closeSendModal}>
        <ShareShopWithModal
          title={`Send Asset${looks.length > 1 ? 's' : ''}`}
          items={looks}
          link={link}
          pointers={{ lookIds: looks }}
          onClose={closeSendModal}
          onSend={closeSendModal}
        />
      </IonModal>
    </>
  )
}

export default ShareLookButton

import { z } from 'zod'

import { callListSchema } from '../../clienteling/outreach/outreach.schema'
import { broadcastBaseSchema } from '../../marketing/broadcasts/broadcast.schema'

type Campaign = z.infer<typeof campaignSchema>

const campaignSchema = z.discriminatedUnion('className', [
  callListSchema,
  broadcastBaseSchema,
])

export type { Campaign }
export { campaignSchema }

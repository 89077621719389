import { z } from 'zod'

import { serverItemSchema } from '../utils/schema'
import { baseClientDetailSchema } from '../clients/clients.schema'

type CheckedInClient = z.infer<typeof checkedInClientSchema>
type ClientStationStatus = z.infer<typeof clientStationStatus>
type EmailCheckIn = z.infer<typeof emailCheckInSchema>
type TerminalType = 'client' | 'pos'

const clientStationStatus = z.enum(['canceled', 'completed'])
const emailCheckInSchema = z.object({
  email: z
    .string()
    .trim()
    .toLowerCase()
    .email({ message: 'Please enter a valid email.' }),
})

const checkedInClientSchema = z.object({
  client: baseClientDetailSchema.nullable(),
})

const stationSchema = serverItemSchema.extend({
  name: z.string(),
})

const clientStationSchema = serverItemSchema.extend({
  active: z.boolean(),
})

const activeStationsResponseSchema = z.array(
  stationSchema.and(z.object({ isClient: z.boolean(), isPos: z.boolean() }))
)
const stationResponseSchema = z.object({ station: stationSchema.nullable() })
const stationsResponseSchema = z.array(stationSchema)
const clientStationResponseSchema = z.object({
  stationClient: clientStationSchema,
})

export type { CheckedInClient, ClientStationStatus, EmailCheckIn, TerminalType }
export {
  activeStationsResponseSchema,
  checkedInClientSchema,
  clientStationResponseSchema,
  emailCheckInSchema,
  stationResponseSchema,
  stationsResponseSchema,
}

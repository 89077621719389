import { IonContent, IonHeader, IonLoading, IonPage } from '@ionic/react'
import { useParams } from 'react-router-dom'

import { useProduct } from '../../hooks/shopwith/products/queries'
import Header from '../../components/header'
import ProductDetail from '../../components/shopwith/products/product-detail'

function Product() {
  const { pid } = useParams<{ pid: string }>()
  const productQuery = useProduct(pid)

  return (
    <IonPage>
      <IonHeader>
        <Header backRef="/tabs/shopwith" title="Product Detail" />
      </IonHeader>
      <IonContent className="ion-padding-vertical">
        {productQuery.data ? (
          <ProductDetail product={productQuery.data} />
        ) : (
          <IonLoading isOpen message="Loading Product..." />
        )}
      </IonContent>
    </IonPage>
  )
}

export default Product

import { useAccount } from '../../contexts/authContext'

function RotateInfoLegal() {
  const account = useAccount()

  return (
    <div className="bg-ion-color-secondary text-ion-color-primary rounded-2xl p-2">
      <p className="max-w-lg text-sm">
        By pressing "Check In" you consent to receive calls and text messages,
        including by automatic telephone dialing system, from{' '}
        {account?.account.companyName ?? 'This Store'} to the phone number
        provided for informational and/or marketing purposes. Consent to receive
        marketing calls and texts is not a condition form purchase. Message and
        data rates may apply.
      </p>
      <div className="flex justify-center gap-x-3">
        <a
          href="https://oneshopretail.com/privacy-policy/"
          target="_blank"
          rel="noreferrer"
          className="text-ion-color-primary underline"
        >
          Privacy Policy
        </a>{' '}
        |{' '}
        <a
          href="https://oneshopretail.com/terms-of-service/"
          target="_blank"
          rel="noreferrer"
          className="text-ion-color-primary underline"
        >
          Terms and Conditions
        </a>
      </div>
    </div>
  )
}

export { RotateInfoLegal }

import { useQuery } from '@tanstack/react-query'
import { klona } from 'klona/json'

import { client } from '../../services/client'

type NotificationsResponse = {
  result: {
    notifications: Array<ParseNotification>
  }
}

type ApprovalEvent =
  | {
      event: 'broadcastRequiresApproval'
      broadcast: { objectId: string }
    }
  | { event: 'broadcastApproved'; broadcast: { objectId: string } }

export type ParseNotification = {
  objectId: string
  body: string
  createdAt: string
  sent: boolean
  title: string
  readAt?: string | null
  alteration?: { objectId: string }
  callList?: { objectId: string }
  clientId?: string
  conversationSid?: string
  followUp?: { objectId: string }
  likes?: Array<{ objectId: string; client?: { objectId: string } }>
  specialOrder?: { objectId: string }
} & ApprovalEvent

export const notificationQueryKeys = {
  all: [{ entity: 'notifications' }] as const,
  lists: () => [{ ...notificationQueryKeys.all[0], scope: 'list' }] as const,
  list: () => [{ ...notificationQueryKeys.lists()[0] }] as const,
}

async function fetchNotifications() {
  const response: NotificationsResponse = await client
    .post('functions/notifications')
    .json()

  return response.result.notifications
}

function selectNotifications(notifications: Array<ParseNotification>) {
  return klona(notifications).sort((a, b) =>
    b.createdAt.localeCompare(a.createdAt)
  )
}

function useNotifications() {
  return useQuery({
    queryKey: notificationQueryKeys.list(),
    queryFn: fetchNotifications,
    select: selectNotifications,
  })
}

export { useNotifications }

import { MaterialReactTable } from 'material-react-table'

import { useUserRoleSocials } from '../../../social-sharing/roles/queries'
import { useUpdateUserRoleSocial } from '../../../social-sharing/roles/mutations'
import { columns } from './columns'

import type { MaterialReactTableProps } from 'material-react-table'
import type { UserRoleSocial } from '../../../social-sharing/roles/userRoleSocial.schema'

function SocialRolesTable() {
  const {
    isLoading,
    isPreviousData,
    pagination,
    setPagination,
    setSearch,
    ...userRolesQuery
  } = useUserRoleSocials()
  const updateUserRoleSocial = useUpdateUserRoleSocial()

  const isFetching = isLoading || isPreviousData

  const handleSaveRow: MaterialReactTableProps<UserRoleSocial>['onEditingRowSave'] =
    async ({ exitEditingMode, row, values }) => {
      updateUserRoleSocial.mutate({
        objectId: row.id,
        role: values['roleSocial.role'],
        requiresApproval: values['roleSocial.requiresApproval'],
      })
      exitEditingMode()
    }

  return (
    <MaterialReactTable
      columns={columns}
      data={userRolesQuery.data}
      initialState={{ density: 'comfortable' }}
      state={{
        pagination,
        isLoading,
        showProgressBars: isFetching || updateUserRoleSocial.isLoading,
      }}
      defaultColumn={{ size: 0 }}
      enableColumnActions={false}
      enableColumnFilters={false}
      enableColumnOrdering
      enableDensityToggle={false}
      enableEditing // not all tables have
      editingMode="row" // not all tables have
      enableFullScreenToggle={false}
      enableHiding={false} // not all tables have
      enableSorting={false}
      getRowId={(originalRow) => originalRow.objectId} // not all tables have (useful with edit)
      manualFiltering
      manualPagination
      onEditingRowSave={handleSaveRow} // not all tables have
      onGlobalFilterChange={setSearch}
      onPaginationChange={setPagination}
      rowCount={userRolesQuery.count}
    />
  )
}

export { SocialRolesTable }

import { z } from 'zod'

import { nullableString } from '../utils/schema'
import { units } from '../constants/misc'
import { photoDataSchema } from '../hooks/use-photo'
import { triggerRuleSchema, triggerStepSchema } from './automations-base.schema'
import { fromUserRules } from './constants'

type CreateAutomationInput = z.infer<typeof createAutomationSchema>
type UpdateAutomationInput = z.infer<typeof updateAutomationSchema>
type FromUserRule = typeof fromUserRules[number]

const formAttachmentsSchema = z.object({
  attachmentsEmail: z.array(z.string().or(photoDataSchema)).optional(),
  attachmentsSms: z.array(z.string().or(photoDataSchema)).optional(),
})

// New rangeObjectSchema - specific for form so allows string and transforms to number for server
const rangeItemSchema = z
  .string()
  .transform((data) => (data.trim() === '' ? null : parseFloat(data)))
  .nullable()
  .or(z.number().nullable())

const rangeObjectSchema = z.object({
  gt: rangeItemSchema,
  gte: rangeItemSchema,
  lt: rangeItemSchema,
  lte: rangeItemSchema,
  eq: rangeItemSchema,
  delayUnit: z.enum(units).optional(),
})

function checkRangesRefinement(
  range: Partial<z.infer<typeof rangeObjectSchema>>,
  ctx: z.RefinementCtx
) {
  const nonNegativeCheck =
    (range.gt != null && range.gt <= 0) || (range.lt != null && range.lt <= 0)
  const notEqualCheck =
    range.gt != null && range.lt != null && range.gt === range.lt
  const greaterThanCheck =
    range.gt != null && range.lt != null && range.gt > range.lt

  if (nonNegativeCheck)
    ctx.addIssue({
      code: z.ZodIssueCode.too_small,
      minimum: 0,
      type: 'number',
      inclusive: true,
      message: 'The condition cannot be a negative number.',
    })

  if (notEqualCheck)
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'The conditions cannot be the same number.',
    })

  if (greaterThanCheck)
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message:
        'The greater than condition must be smaller than the less than condition.',
    })

  return true
}

const formConditionsSchema = z
  .object({
    sale: rangeObjectSchema
      .pick({ gt: true, lt: true })
      .partial()
      .superRefine(checkRangesRefinement),
    noSales: rangeObjectSchema
      .pick({ delayUnit: true, gt: true })
      .partial()
      .superRefine((data, ctx) => {
        if (!data.gt) return
        // no negative numbers
        if (data.gt < 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.too_small,
            minimum: 0,
            type: 'number',
            inclusive: true,
            message:
              'The time since the last sale cannot be a negative number.',
          })
        }
      }),

    loyaltyPointsActive: rangeObjectSchema
      .pick({ gt: true, lt: true })
      .partial()
      .superRefine(checkRangesRefinement),
    loyaltyPointsLifetime: rangeObjectSchema
      .pick({ gt: true, lt: true })
      .partial()
      .superRefine(checkRangesRefinement),
    loyaltyVisitsActive: rangeObjectSchema
      .pick({ gt: true, lt: true })
      .partial()
      .superRefine(checkRangesRefinement),
  })
  .partial()

const createTriggerRuleSchema = triggerRuleSchema
  .pick({
    description: true,
    fromUserRule: true,
    state: true,
    title: true,
    type: true,
  })
  .merge(
    z.object({
      conditions: formConditionsSchema,
      fromUserId: nullableString.optional(),
      fromName: nullableString,
      fromEmailName: z
        .string()
        .transform((value) => (value ? value.toLowerCase() : null))
        .nullable(),
      fromEmailDomain: nullableString,
    })
  )

const createTriggerStepSchema = triggerStepSchema
  .omit({ objectId: true })
  .merge(formAttachmentsSchema)

const createAutomationSchema = createTriggerRuleSchema.merge(
  z.object({ steps: z.array(createTriggerStepSchema) })
)

const updateTriggerRuleSchema = triggerRuleSchema
  .pick({
    objectId: true,
    description: true,
    fromUserRule: true,
    state: true,
    title: true,
    type: true,
  })
  .merge(
    z.object({
      conditions: formConditionsSchema,
      fromUserId: nullableString.optional(),
      fromName: nullableString,
      fromEmailName: z
        .string()
        .transform((value) => (value ? value.toLowerCase() : null))
        .nullable(),
      fromEmailDomain: nullableString,
    })
  )
const updateTriggerStepSchema = triggerStepSchema.merge(formAttachmentsSchema)

const updateAutomationSchema = updateTriggerRuleSchema.merge(
  z.object({ steps: z.array(updateTriggerStepSchema) })
)

export type { CreateAutomationInput, FromUserRule, UpdateAutomationInput }
export { createAutomationSchema, updateAutomationSchema }

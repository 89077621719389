const forbiddenWords = [
  'Cannabis',
  'Weed',
  'Pot',
  'Mary Jane',
  'Grass',
  'Ganja',
  'Herb',
  'Dope',
  'Bud',
  'Reefer',
  'Joint',
  'Blunt',
  'Bong',
  'Hash',
  'THC',
  'Tetrahydrocannabinol',
  'Kush',
  'Sativa',
  'Indica',
  'Hybrid',
  'Stoner',
  'Toke',
  'Smoke',
  'Dab',
  'Shatter',
  'Wax',
  'Edibles',
  'Hemp',
  '420',
  'Dispensary',
  'Chronic',
  'Skunk',
  'Spliff',
  'Nugs',
  'Kratom',
  'Sinsemilla',
  'Cannabidiol',
  'CBD',
  'Cannabinoids',
  'Endocannabinoid',
  'Tincture',
  'Capsules',
  'Gummies',
  'Topicals',
  'Balms',
  'Creams',
  'Lotions',
  'Salves',
  'Drops',
  'Vape juice',
  'Infused products',
  'Non-psychoactive',
  'Terpenes',
  'Phytocannabinoids',
  'CBD-rich',
  'Hemp-derived',
  'Cannaflavins',
  'Cannabinoid',
  'Poker',
  'Blackjack',
  'Roulette',
  'Craps',
  'Baccarat',
  'Slots',
  'Keno',
  'Bingo',
  'Pai Gow',
  'Sic Bo',
  'Faro',
  'Klondike',
  'Patience',
  'Hearts',
  'Spades',
  'Euchre',
  'Canasta',
  'Pinochle',
  'Gin',
  'Cribbage',
  'Backgammon',
  'Dominoes',
  'Pachinko',
  'Hanafuda',
  'Pontoon',
  'Bet',
  'Wager',
  'Gamble',
  'Jackpot',
  'Lottery',
  'Casino',
  'Bookmaker',
  'Betting',
  'Sweepstakes',
  'Gaming',
  'Risk',
  'Stake',
  'Parlay',
  'sex',
  'nude',
  'nudes',
  'stripper',
  'strip',
  'OnlyFans',
  'naked',
  'drug',
  'drugs',
  'dealer',
]

export { forbiddenWords }

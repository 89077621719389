import { useIonModal } from '@ionic/react'
import { Capacitor } from '@capacitor/core'
import { Device } from '@capacitor/device'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { fromZodError } from 'zod-validation-error'

import { client } from '../services/client'
import { makeServerResponseSchema } from '../utils/schema'
import { usePushToken } from '../utils/use-push-token'
import { InvalidTokenModal } from '../check-in/paired/components/invalid-token-modal'
import { BusyStationModal } from './components/busy-station-modal'
import { checkInQueryKeys } from './queries'
import { clientStationResponseSchema } from './rotate.schema'

import type { ClientStationStatus, TerminalType } from './rotate.schema'
import type { CheckInMethod } from '../check-in/check-in-store'

function useCheckIn(clientId: string | null) {
  const queryClient = useQueryClient()
  const { data: deviceToken } = usePushToken()

  const [presentBusyModal, dismissBusyModal] = useIonModal(BusyStationModal, {
    onDismiss: () => dismissBusyModal(),
  })
  const [presentInvalidTokenModal, dismissInvalidTokenModal] = useIonModal(
    InvalidTokenModal,
    {
      onDismiss: () => dismissInvalidTokenModal(),
    }
  )

  function openBusyModal() {
    presentBusyModal({ backdropDismiss: false })
  }
  function openTokenModal() {
    presentInvalidTokenModal({
      onDidDismiss: () =>
        queryClient.invalidateQueries(
          checkInQueryKeys.terminal({ type: 'client', deviceToken })
        ),
    })
  }

  const controller = new AbortController()
  const { signal } = controller

  const mutation = useMutation({
    mutationFn: async (checkInMethod: CheckInMethod | null) => {
      if (!deviceToken)
        throw new Error('There is no station associated with this device.')

      if (signal.aborted) throw new Error('abort')
      if (!checkInMethod)
        throw new Error(
          'You are trying to check-in without providing a check-in method.'
        )

      const response = await client
        .post('functions/createStationClient', {
          json: {
            clientId,
            deviceToken,
            e164: checkInMethod.phone,
            email: checkInMethod.email,
          },
          signal,
        })
        .json()

      const parsedResponse = makeServerResponseSchema(
        clientStationResponseSchema
      ).safeParse(response)

      if (!parsedResponse.success)
        throw new Error(fromZodError(parsedResponse.error).message)
      return parsedResponse.data.result
    },
    onError: (error) => {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown Error'
      // invalid device token
      if (errorMessage.includes('invalid deviceToken')) {
        openTokenModal()
      }
      if (errorMessage.includes('station busy')) {
        openBusyModal()
      }
    },

    retry(_failureCount, error) {
      return error instanceof Error
        ? error.message.includes('invalid sale') &&
            !error.message.includes('abort')
        : false
    },
  })

  return { ...mutation, abortCheckIn: () => controller.abort() }
}

function useClearActiveStations() {
  const { data: deviceToken } = usePushToken()

  return useMutation({
    mutationFn: () =>
      client.post('functions/clearStationsActive', {
        json: { deviceToken },
      }),
  })
}

function useResetActiveClientStations() {
  const queryClient = useQueryClient()
  const { data: deviceToken } = usePushToken()

  return useMutation({
    mutationFn: () =>
      client.post('functions/stationActiveClearActiveClients', {
        json: {
          deviceToken,
          deviceType: 'web',
          type: 'client',
        },
      }),
    onSettled: () =>
      queryClient.invalidateQueries(
        checkInQueryKeys.terminal({ deviceToken, type: 'client' })
      ),
  })
}

function useUpdateClientStation() {
  const queryClient = useQueryClient()
  const { data: deviceToken } = usePushToken()

  return useMutation({
    mutationFn: async ({
      objectId,
      status,
    }: {
      objectId: string
      status: ClientStationStatus
    }) => {
      const response = await client
        .post('functions/updateStationClient', {
          json: {
            objectId,
            status,
          },
        })
        .json()

      return response
    },
    onSettled: () =>
      queryClient.invalidateQueries(
        checkInQueryKeys.terminal({ type: 'client', deviceToken })
      ),
  })
}

function useUpdateTerminal() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({
      deviceToken,
      stationId,
      type,
    }: {
      deviceToken: string
      stationId: string
      type: TerminalType
    }) => {
      const deviceInfo = await Device.getInfo()
      const os = deviceInfo.operatingSystem
      const name = deviceInfo.name
      const appVersion = process.env.REACT_APP_VERSION

      return client
        .post('functions/updateStationActive', {
          json: {
            deviceToken,
            deviceType: Capacitor.getPlatform(),
            type,
            stationId,
            os,
            name,
            appVersion,
          },
        })
        .json()
    },
    onSettled: (_data, _error, { deviceToken, type }) => {
      queryClient.invalidateQueries(
        checkInQueryKeys.terminal({ deviceToken, type })
      )
      queryClient.invalidateQueries(checkInQueryKeys.activeTerminals())
    },
  })
}

export {
  useCheckIn,
  useClearActiveStations,
  useResetActiveClientStations,
  useUpdateClientStation,
  useUpdateTerminal,
}

import { z } from 'zod'

import {
  nullableString,
  nullableNumber,
  isoDateSchema,
  serverItemSchema,
} from '../utils/schema'
import { profileBaseSchema } from '../schema/profile.schema'

type BaseClientDetail = z.infer<typeof baseClientDetailSchema>

const e164Schema = z.string()

const optInStateSchema = z.enum([
  'NOT_INITIALIZED',
  'PENDING',
  'OPTED_IN',
  'OPTED_OUT',
])

const posNameOrderSchema = z.enum([
  'Sign Up',
  'Cell',
  'Mobile',
  'Primary Phone',
  'Mobile 1',
  'Mobile 2',
  'Phone Number',
  'Phone',
  'Phone 1',
  'phone1',
  'Phone 2',
  'Phone 3',
  'Phone 4',
  'Address Phone',
  'Work Phone',
  'Home Phone',
  'Unknown',
])

const phoneNumberSchema = serverItemSchema.extend({
  default: z.boolean(),
  e164: e164Schema.nullable(),
  optInState: optInStateSchema,
  phoneNumber: z.string(),
  posName: posNameOrderSchema.or(z.undefined()).nullable(),
  type: nullableString,
  verified: z.boolean(),
})

const baseClientDetailSchema = profileBaseSchema.extend({
  allowSms: z.boolean(),
  birthday: nullableNumber,
  dobObject: z
    .object({
      day: z.number().optional().nullable(), // some errant bad 'null' data on server
      month: z.number().optional().nullable(), // some errant bad 'null' data on server
      year: z.number().optional().nullable(), // some errant bad 'null' data on server
    })
    .nullable(),
  email: nullableString,
  isMine: z.boolean(),
  phoneNumbers: z.array(phoneNumberSchema),
  posLanguage: z.string(),
  unsubscribedAt: isoDateSchema.nullable(),
})

export type { BaseClientDetail }
export { baseClientDetailSchema, phoneNumberSchema }

import * as React from 'react'
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonLabel,
  IonLoading,
  IonModal,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonToolbar,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonTitle,
  IonList,
  IonRadioGroup,
  IonListHeader,
  IonItem,
  IonRadio,
} from '@ionic/react'
import { useImmer } from 'use-immer'
import { Controller, useForm } from 'react-hook-form'

import { getRadioBoolean, getRadioValue } from '../../utils/shopwith-helpers'
import {
  useProductFilters,
  useProducts,
} from '../../hooks/shopwith/products/queries'
import { useLooks } from '../../hooks/shopwith/looks/queries'
import { useCollections } from '../../hooks/shopwith/collections/queries'
import {
  productShortcuts,
  lookShortcuts,
  collectionShortcuts,
  useCollectionShortcuts,
  useLookShortcuts,
  useProductShortcuts,
} from '../../hooks/shopwith/shopwith-shortcuts'
import { useInfiniteData } from '../../hooks/use-infinite-data'
import SearchableSelect from '../../components/ui/form/searchable-select'
import ProductItem from '../../components/shopwith/product-item'
import LookItem from '../../components/shopwith/look-item'
import CollectionItem from '../../components/shopwith/collection-item'
import { StickyHeader } from '../../components/ui/sticky-header'
import ShopWithShortcuts from '../../components/shopwith/shopwith-shortcuts'
import { FilterButton } from '../ui/buttons/filter-button'
import { HeaderWrapper, ItemsMeta, SelectionsWrapper } from './ui'

import type { RadioFilters } from '../../utils/shopwith-helpers'
import type { ProductListFilters } from '../../hooks/shopwith/products/queries'
import type { ProductShortcut } from '../../hooks/shopwith/shopwith-shortcuts'

type ShopWithSection = typeof shopWithSections[number]['label']
type SelectedItems = Record<
  typeof shopWithSections[number]['value'],
  Array<string>
>

type SearchFilterState = {
  brands: Filters
  categories: Filters
  hasImages: RadioFilters
  hasInventory: RadioFilters
}

type Filters = Array<string>

type Props = {
  onClose: () => void
  onConfirm: (
    type: 'productIds' | 'lookIds' | 'collectionIds',
    values: Array<string>
  ) => void
}

const shopWithSections = [
  { label: 'Products', value: 'products' },
  { label: 'Assets', value: 'looks' },
  { label: 'Collections', value: 'collections' },
] as const

const initialSearchFilters: SearchFilterState = {
  brands: [],
  categories: [],
  hasImages: undefined,
  hasInventory: undefined,
}

function SelectShopWithModal({ onClose, onConfirm }: Props) {
  const [section, setSection] = React.useState<ShopWithSection>('Products')

  const [isFilterModalOpen, setIsFilterModalOpen] = React.useState(false)

  const [selectedItems, updateSelectedItems] = useImmer<SelectedItems>({
    products: [],
    looks: [],
    collections: [],
  })

  const [filters, updateFilters] = useImmer<
    Required<ProductListFilters>['filters']
  >({
    brands: [],
    categories: [],
    hasImage: undefined,
    hasInventory: undefined,
  })

  const { control, handleSubmit, reset } = useForm<SearchFilterState>({
    defaultValues: initialSearchFilters,
  })

  const [productShortcut, setProductShortcut] = useProductShortcuts()
  const [lookShortcut, setLookShortcut] = useLookShortcuts()
  const [collectionShortcut, setCollectionShortcut] = useCollectionShortcuts()

  const productsQuery = useProducts({ filters })
  const looksInfo = useLooks({ enabled: !!productsQuery.data })
  const collectionsInfo = useCollections({ enabled: !!looksInfo.data })
  const productFiltersQuery = useProductFilters()

  const [ionInfiniteScrollRef, loadMoreProducts] = useInfiniteData(
    productsQuery.fetchNextPage
  )

  const shopwithIsLoading = [
    productsQuery,
    looksInfo,
    collectionsInfo,
    productFiltersQuery,
  ].some((query) => query.isLoading)

  function isItemSelected(key: keyof SelectedItems, item: string) {
    return selectedItems[key].includes(item)
  }

  function handleItemClick(key: keyof SelectedItems, id: string) {
    if (isItemSelected(key, id)) {
      // remove item
      updateSelectedItems((draft) => {
        const index = draft[key].findIndex((item) => item === id)
        if (index !== -1) draft[key].splice(index, 1)
      })
    } else {
      updateSelectedItems((draft) => {
        if (key === 'collections') {
          draft.collections = [id]
          return
        }
        draft[key].push(id)
      })
    }
  }

  function handleProductSearch(data: SearchFilterState) {
    setIsFilterModalOpen(false)
    updateFilters((draft) => {
      draft.brands = data.brands
      draft.categories = data.categories
      draft.hasImage = getRadioBoolean(data.hasImages)
      draft.hasInventory = getRadioBoolean(data.hasInventory)
    })
  }

  function handleSelectProductShortcut(shortcut: ProductShortcut) {
    setProductShortcut(shortcut)
    const { shoppableOnly, createdByMe, ...searchFilters } = filters

    if (shortcut === 'all') {
      updateFilters({
        ...searchFilters,
      })
      return
    }
    updateFilters({ ...searchFilters, [shortcut]: true })
  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons>
            <IonButton color="secondary" onClick={() => onClose()}>
              CANCEL
            </IonButton>
          </IonButtons>
          <IonTitle>SHOP WITH</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <StickyHeader>
          <div className="ion-padding-top ion-padding-horizontal">
            <IonSegment
              onIonChange={(e) => setSection(e.detail.value as ShopWithSection)}
              value={section}
            >
              {shopWithSections.map((section) => (
                <IonSegmentButton value={section.label} key={section.value}>
                  <IonLabel>{section.label.toUpperCase()}</IonLabel>
                </IonSegmentButton>
              ))}
            </IonSegment>
          </div>

          {section === 'Products' ? (
            <>
              <HeaderWrapper disabled={productsQuery.isLoading}>
                <ShopWithShortcuts
                  shortcuts={productShortcuts}
                  currentShortcut={productShortcut}
                  onChange={handleSelectProductShortcut}
                />
              </HeaderWrapper>

              <div className="ion-margin-top">
                <IonGrid
                  style={{ '--ion-grid-padding': 0 }}
                  className="ion-margin-top ion-padding-horizontal"
                >
                  <IonRow className="ion-nowrap ion-align-items-center">
                    <IonCol>
                      <SelectionsWrapper>
                        <IonButton
                          size="small"
                          expand="block"
                          disabled={selectedItems.products.length === 0}
                          color="yellow"
                          onClick={() =>
                            onConfirm('productIds', selectedItems.products)
                          }
                        >
                          Confirm
                        </IonButton>
                      </SelectionsWrapper>
                    </IonCol>
                    <IonCol className="flex-full">
                      <ItemsMeta highlight>
                        Selected: {selectedItems.products.length}
                      </ItemsMeta>
                    </IonCol>

                    <IonCol>
                      <FilterButton
                        onClick={() => setIsFilterModalOpen(true)}
                      />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </>
          ) : section === 'Assets' ? (
            <>
              <HeaderWrapper disabled={looksInfo.isLoading}>
                <ShopWithShortcuts
                  shortcuts={lookShortcuts}
                  currentShortcut={lookShortcut}
                  onChange={setLookShortcut}
                />
              </HeaderWrapper>

              <div className="ion-margin-top">
                <IonGrid
                  style={{ '--ion-grid-padding': 0 }}
                  className="ion-margin-top ion-padding-horizontal"
                >
                  <IonRow className="ion-nowrap ion-align-items-center">
                    <IonCol>
                      <SelectionsWrapper>
                        <IonButton
                          size="small"
                          expand="block"
                          disabled={selectedItems.looks.length === 0}
                          color="yellow"
                          onClick={() =>
                            onConfirm('lookIds', selectedItems.looks)
                          }
                        >
                          Confirm
                        </IonButton>
                      </SelectionsWrapper>
                    </IonCol>
                    <IonCol className="flex-full">
                      <ItemsMeta>
                        {looksInfo.data?.length} Look
                        {looksInfo.data?.length === 1 ? '' : 's'}
                      </ItemsMeta>
                      <ItemsMeta highlight>
                        Selected: {selectedItems.looks.length}
                      </ItemsMeta>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </>
          ) : section === 'Collections' ? (
            <>
              <HeaderWrapper disabled={collectionsInfo.isLoading}>
                <ShopWithShortcuts
                  shortcuts={collectionShortcuts}
                  currentShortcut={collectionShortcut}
                  onChange={setCollectionShortcut}
                />
              </HeaderWrapper>

              <IonGrid
                style={{ '--ion-grid-padding': 0 }}
                className="ion-margin-top ion-padding-horizontal"
              >
                <IonRow className="flex-nowrap items-center">
                  <IonCol>
                    <SelectionsWrapper>
                      <IonButton
                        size="small"
                        expand="block"
                        disabled={selectedItems.collections.length === 0}
                        color="yellow"
                        onClick={() =>
                          onConfirm('collectionIds', selectedItems.collections)
                        }
                      >
                        Confirm
                      </IonButton>
                    </SelectionsWrapper>
                  </IonCol>

                  <IonCol className="flex-full">
                    <h2 className="ml-2">
                      {collectionsInfo.data?.length} Collection
                      {collectionsInfo.data?.length === 1 ? '' : 's'}
                    </h2>
                    <ItemsMeta>
                      Selected: {selectedItems.collections.length}
                    </ItemsMeta>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </>
          ) : null}
        </StickyHeader>
        {shopwithIsLoading ? (
          <IonLoading
            isOpen={shopwithIsLoading}
            message={'Loading your SHOPWITH products'}
          />
        ) : (
          <>
            <section className={section === 'Products' ? 'block' : 'hidden'}>
              <IonGrid>
                <IonRow>
                  {productsQuery.data?.pages.map((productPage, index) => (
                    <React.Fragment key={index}>
                      {productPage.data.map((product) => (
                        <IonCol
                          key={product.objectId}
                          className="flex flex-col"
                          sizeXs="6"
                          sizeSm="4"
                          sizeMd="3"
                        >
                          <ProductItem
                            product={product}
                            onProductSelect={handleItemClick}
                            isSelected={isItemSelected(
                              'products',
                              product.objectId
                            )}
                          />
                        </IonCol>
                      ))}
                    </React.Fragment>
                  ))}
                </IonRow>
              </IonGrid>
              <IonInfiniteScroll
                ref={ionInfiniteScrollRef}
                onIonInfinite={loadMoreProducts}
                threshold="100px"
                disabled={!productsQuery.hasNextPage}
              >
                <IonInfiniteScrollContent
                  loadingSpinner="bubbles"
                  loadingText="loading more products..."
                />
              </IonInfiniteScroll>
            </section>
            <section className={section === 'Assets' ? 'block' : 'none'}>
              <IonGrid>
                <IonRow>
                  {looksInfo.data?.map((look) => {
                    return (
                      <IonCol
                        key={look.objectId}
                        className="flex flex-col"
                        sizeXs="6"
                        sizeSm="4"
                        sizeMd="3"
                      >
                        <LookItem
                          look={look}
                          onLookSelect={handleItemClick}
                          isSelected={isItemSelected('looks', look.objectId)}
                          shouldHideDetail={true}
                        />
                      </IonCol>
                    )
                  })}
                </IonRow>
              </IonGrid>
            </section>
            <section className={section === 'Collections' ? 'block' : 'none'}>
              <IonGrid>
                <IonRow>
                  {collectionsInfo.data?.map((collection) => {
                    return (
                      <IonCol
                        key={collection.objectId}
                        className="flex flex-col"
                        sizeXs="6"
                        sizeSm="4"
                        sizeMd="3"
                      >
                        <CollectionItem
                          collection={collection}
                          onCollectionSelect={handleItemClick}
                          isSelected={isItemSelected(
                            'collections',
                            collection.objectId
                          )}
                        />
                      </IonCol>
                    )
                  })}
                </IonRow>
              </IonGrid>
            </section>{' '}
          </>
        )}
      </IonContent>

      {/* OPEN PRODUCT FILTER */}
      <IonModal
        isOpen={isFilterModalOpen}
        onDidDismiss={() => setIsFilterModalOpen(false)}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons>
              <IonButton
                color="secondary"
                onClick={() => {
                  reset(
                    {
                      brands: filters.brands,
                      categories: filters.categories,
                      hasImages: getRadioValue(filters.hasImage),
                      hasInventory: getRadioValue(filters.hasInventory),
                    },
                    { keepDefaultValues: true }
                  )
                  setIsFilterModalOpen(false)

                  setIsFilterModalOpen(false)
                }}
              >
                CANCEL
              </IonButton>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton
                fill="outline"
                color="secondary"
                onClick={() => reset()}
              >
                RESET
              </IonButton>
              <IonButton
                fill="solid"
                color="yellow"
                onClick={handleSubmit(handleProductSearch)}
              >
                SEARCH
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonCard color="secondary">
            <IonCardContent>
              <Controller
                name="brands"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <SearchableSelect
                    items={productFiltersQuery.data?.brands ?? []}
                    label="Brands"
                    selectedItems={value ?? []}
                    setSelectedItems={onChange}
                  />
                )}
              />
              <Controller
                name="categories"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <SearchableSelect
                    items={productFiltersQuery.data?.categories ?? []}
                    label="Categories"
                    selectedItems={value ?? []}
                    setSelectedItems={onChange}
                  />
                )}
              />

              <IonList>
                <Controller
                  name="hasImages"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <IonRadioGroup value={value} onIonChange={onChange}>
                      <IonListHeader
                        color="secondary"
                        className="ion-margin-top"
                      >
                        Has Photos
                      </IonListHeader>
                      <IonItem color="secondary">
                        <IonLabel>N/A</IonLabel>

                        <IonRadio mode="md" slot="end" value="n/a" />
                      </IonItem>
                      <IonItem color="secondary">
                        <IonLabel>Yes</IonLabel>

                        <IonRadio mode="md" slot="end" value="yes" />
                      </IonItem>
                      <IonItem color="secondary">
                        <IonLabel>No</IonLabel>

                        <IonRadio mode="md" slot="end" value="no" />
                      </IonItem>
                    </IonRadioGroup>
                  )}
                />
              </IonList>

              <IonList>
                <Controller
                  name="hasInventory"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <IonRadioGroup value={value} onIonChange={onChange}>
                      <IonListHeader
                        color="secondary"
                        className="ion-margin-top"
                      >
                        Has Inventory
                      </IonListHeader>
                      <IonItem color="secondary">
                        <IonLabel>N/A</IonLabel>
                        <IonRadio mode="md" slot="end" value="n/a" />
                      </IonItem>
                      <IonItem color="secondary">
                        <IonLabel>Yes</IonLabel>
                        <IonRadio mode="md" slot="end" value="yes" />
                      </IonItem>
                      <IonItem color="secondary">
                        <IonLabel>No</IonLabel>
                        <IonRadio mode="md" slot="end" value="no" />
                      </IonItem>
                    </IonRadioGroup>
                  )}
                />
              </IonList>
            </IonCardContent>
          </IonCard>
        </IonContent>
      </IonModal>
    </>
  )
}

export default SelectShopWithModal

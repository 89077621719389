import ky from 'ky'

import { getEndpoint, APPLICATION_ID } from '../stores'

const config = {
  prefixUrl: getEndpoint(),
  timeout: 30000,
}

const client = ky.extend({
  hooks: {
    beforeRequest: [
      (request) => {
        const token = window.localStorage.getItem('token')
        request.headers.set('X-Parse-Application-Id', APPLICATION_ID)
        token && request.headers.set('X-Parse-Session-Token', `${token}`)
      },
    ],
    afterResponse: [
      async (_input, _options, response) => {
        const body = await response.json()

        if (body.error) {
          console.error('error:', body.error)
          throw new Error(body.error)
        }
      },
    ],
  },
  ...config,
})

export { client }

import { useIonRouter } from '@ionic/react'
import { IonSkeletonText, IonText } from '@ionic/react'
import { Link } from 'react-router-dom'
import { format, parseISO } from 'date-fns'

import { isNewClient } from '../../../utils/client-helpers'
import { getFullName } from '../../../utils/format-helpers'
import { useRecentSalesList } from '../../../hooks/outreach/queries'
import { useIsStore } from '../../../auth/utils'
import Badge from '../../Badge'
import Currency from '../../Currency'
import ScrollingList from '../../ui/scrolling-list'
import { SectionContent, SectionHeader, SectionWrapper } from '../section'
import { SeeMore } from '../see-more'

const Icon = () => (
  <svg
    className="size-full"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 398.73 398.73"
  >
    <path
      strokeWidth={14}
      strokeMiterlimit={10}
      d="M111.21,118.26h171.62c0,0,15.86-1.06,16.56,21.85s7.05,149.42,7.05,149.42s-0.35,17.97-17.27,18.32
	c-16.92,0.35-180.78,0-180.78,0s-16.21-0.7-16.56-20.44c-0.35-19.73,5.29-152.94,5.29-152.94S98.87,120.38,111.21,118.26z"
    />
    <g>
      <path
        strokeWidth={14}
        strokeMiterlimit={10}
        d="M141.33,136.13c0,0,5.29-73.24,57.79-72.8"
      />
      <path
        strokeWidth={14}
        strokeMiterlimit={10}
        d="M256.92,136.13c0,0-5.29-73.24-57.79-72.8"
      />
    </g>
  </svg>
)

function RecentSalesListSkeleton() {
  return (
    <div className="flex flex-col">
      <div className="size-32 relative">
        <IonSkeletonText animated className="size-full m-0 leading-inherit" />
      </div>
      <div className="relative flex flex-col">
        <p className="mt-1 w-5/6 font-semibold">
          <IonSkeletonText animated className="size-full m-0 leading-inherit" />
        </p>
        <div className="flex items-center justify-between">
          <p className="mt-0.5 w-7/12 text-sm">
            <IonSkeletonText
              animated
              className="size-full m-0 leading-inherit"
            />
          </p>
        </div>
      </div>
    </div>
  )
}

function RecentSalesSection() {
  const recentSalesQuery = useRecentSalesList(25)
  const router = useIonRouter()

  const { isQ } = useIsStore()

  return (
    <SectionWrapper>
      <SectionHeader
        title={isQ() ? 'Recent Sales - Thank You Note Needed' : 'Recent Sales'}
        action={
          Boolean(recentSalesQuery.data?.clients.length) ? (
            <SeeMore
              onClick={() => {
                router.push('/tabs/clients/list/recent-sales')
              }}
            />
          ) : null
        }
      />
      <SectionContent>
        {recentSalesQuery.data ? (
          <>
            {recentSalesQuery.data.clients.length > 0 ? (
              <ScrollingList asChild gap="lg">
                <ul>
                  {recentSalesQuery.data.clients.map((client) => {
                    const fullName = getFullName(client)
                    return (
                      <li key={client.objectId} className="w-32">
                        <Link to={`/tabs/clients/client/${client.objectId}`}>
                          <div className="flex flex-col">
                            <div className="bg-ion-color-secondary size-32 relative">
                              {client.saleThumb ? (
                                <img
                                  src={client.saleThumb}
                                  className="size-full object-cover object-center object-top"
                                  alt=""
                                />
                              ) : (
                                <span className="block h-4/5 text-neutral-600">
                                  <Icon />
                                </span>
                              )}
                              <p className="absolute bottom-2 left-0 right-0 bg-white/60 p-1 text-center font-semibold">
                                <IonText color="primary">
                                  <Currency value={client.saleTotal} />
                                </IonText>
                              </p>
                              {isNewClient(client) && <Badge>New</Badge>}
                            </div>
                            <div className="relative flex flex-col">
                              <p className="mt-1 font-semibold">
                                <IonText color="secondary">{fullName}</IonText>
                              </p>
                              <div className="flex items-center justify-between">
                                {client.lastSale ? (
                                  <p className="text-xm">
                                    <IonText color="secondary">
                                      {format(
                                        parseISO(client.lastSale.iso),
                                        'MM/dd/yy'
                                      )}
                                    </IonText>
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </ScrollingList>
            ) : (
              <div className="ion-padding-horizontal">
                <p>You have no clients that have recently made purchases.</p>
              </div>
            )}
          </>
        ) : (
          <ul className="ml-4 flex">
            {Array(10)
              .fill(null)
              .map((i, idx) => (
                <li key={idx} className="mr-4">
                  <RecentSalesListSkeleton />
                </li>
              ))}
          </ul>
        )}
      </SectionContent>
    </SectionWrapper>
  )
}
export default RecentSalesSection

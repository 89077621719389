import {
  IonContent,
  IonHeader,
  IonLoading,
  IonPage,
  useIonRouter,
} from '@ionic/react'
import { useParams } from 'react-router-dom'

import { PageHeader } from '../../../unlayer/components/page-header'
import UnlayerEditor from '../../../unlayer/components/unlayer-editor'
import { useBroadcastHtmlEmail } from '../../../marketing/broadcasts/broadcast-store'
import { useUpdateBroadcast } from '../../../marketing/broadcasts/mutations'

import type { UnlayerTemplate } from '../../../unlayer/unlayer.schema'

// TODO: ABSTRACTIONS WILL BE NEEDED WHEN INCORPORATING INTO TEMPLATES
function CreateHtmlEmailPage() {
  const params = useParams<{ broadcastId: string }>()
  const broadcastId = params.broadcastId

  const htmlEmail = useBroadcastHtmlEmail()
  const router = useIonRouter()

  const updateBroadcast = useUpdateBroadcast()

  const queryParams = new URLSearchParams(window.location.search)
  const showDisplayConditions =
    queryParams.get('displayConditions') === 'show' ? 'show' : 'hide'

  function handleConfirm({ html, json }: UnlayerTemplate) {
    updateBroadcast.mutate(
      {
        objectId: broadcastId,
        broadcast: {
          unlayerDesign: json,
          emailMessage: html,
        },
      },
      {
        onSuccess: () =>
          router.canGoBack()
            ? router.goBack()
            : router.push('.', 'back', 'pop'),
      }
    )
  }

  return (
    <IonPage>
      <IonHeader>
        <PageHeader backRef="." />
      </IonHeader>
      <IonContent>
        <UnlayerEditor
          displayConditions={showDisplayConditions}
          unlayerEmail={htmlEmail?.json}
          onConfirm={handleConfirm}
        />
      </IonContent>

      <IonLoading
        isOpen={updateBroadcast.isLoading}
        message="Saving Email Preview..."
      />
    </IonPage>
  )
}

export default CreateHtmlEmailPage

import {
  IonButton,
  IonCol,
  IonGrid,
  IonImg,
  IonItem,
  IonLabel,
  IonRow,
  IonSelect,
  IonSelectOption,
} from '@ionic/react'
import { ErrorMessage } from '@hookform/error-message'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useImmer } from 'use-immer'

import { dynamicCallListSchema } from '../../schema/dynamic-outreach.schema'
import { useCreateDynamicCallList } from '../../hooks/outreach/mutations'
import { useShopWithLinks } from '../../hooks/shopwith/use-shopwith-links'
import { useTemplates } from '../../hooks/templates/queries'
import { StyledError } from '../ui/form/errors'
import { TextInput, NumericInput } from '../ui/form/input'

import type { DynamicCallListSchemaState } from '../../schema/dynamic-outreach.schema'
import type { TemplateAttachments } from '../../hooks/templates/queries'

const triggers = [
  { label: 'Last Sale', value: 'LAST_SALE' },
  { label: 'Birthday', value: 'BIRTHDAY' },
] as const

type TemplateMessage = {
  smsMessage: {
    body: string
  }
  emailMessage: {
    subject: string
    body: string
  }
  shopWithLink?: string
  attachments?: TemplateAttachments
}

const defaultValues: DynamicCallListSchemaState = {
  name: '',
  description: '',
  filter: {
    start: 0,
    end: 0,
  },
  templateId: null,
  trigger: 'LAST_SALE',
}

function CreateDynamicOutreachForm() {
  const [message, updateMessage] = useImmer<TemplateMessage>({
    smsMessage: { body: '' },
    emailMessage: { subject: '', body: '' },
  })

  const templatesQuery = useTemplates()
  const createDynamicCallList = useCreateDynamicCallList()

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<DynamicCallListSchemaState>({
    defaultValues,
    resolver: zodResolver(dynamicCallListSchema),
  })

  const { generateShopWithLinkPreview } = useShopWithLinks()

  const watchTemplate = watch('templateId')
  const watchTrigger = watch('trigger')

  function handleTemplateSelect(id: string) {
    const selectedTemplate = templatesQuery.data?.find(
      (template) => template.objectId === id
    )

    if (!selectedTemplate) return

    const link = Boolean(selectedTemplate.collections?.length)
      ? generateShopWithLinkPreview(
          'collectionIds',
          selectedTemplate.collections?.map((c) => c.objectId) ?? []
        )
      : Boolean(selectedTemplate.looks?.length)
      ? generateShopWithLinkPreview(
          'lookIds',
          selectedTemplate.looks?.map((l) => l.objectId) ?? []
        )
      : Boolean(selectedTemplate.products?.length)
      ? generateShopWithLinkPreview(
          'productIds',
          selectedTemplate.products?.map((p) => p.objectId) ?? []
        )
      : undefined

    updateMessage((draft) => {
      draft.smsMessage.body = selectedTemplate.smsTemplate?.body ?? ''
      draft.emailMessage.subject = selectedTemplate.emailTemplate?.subject ?? ''
      draft.emailMessage.body = selectedTemplate.emailTemplate?.body ?? ''
      draft.shopWithLink = link
      // TODO: HACK!!! SHOULD EVENTUALLY MOVE TO SAME STRUCTURE AS TEMPLATES WITH ATTACHMENTS ON EACH "TEMPLATE" TYPE
      draft.attachments = selectedTemplate.smsTemplate?.attachments ?? null
    })
  }

  function saveOutreach(data: DynamicCallListSchemaState) {
    createDynamicCallList.mutate(data)
  }

  return (
    <form onSubmit={handleSubmit(saveOutreach)}>
      <IonRow className="ion-justify-content-center ion-padding-top">
        <IonCol size="auto">
          <IonButton type="submit" color="yellow" size="small">
            Save
          </IonButton>
        </IonCol>
      </IonRow>
      <IonGrid>
        <IonRow className="ion-padding-vertical">
          <IonCol size="12" sizeMd="3" sizeLg="2">
            <h2>Basic Info:</h2>
          </IonCol>
          <IonCol size="12" sizeMd="9" sizeLg="10" className="space-y-4">
            <div>
              <TextInput control={control} name="name" label="Title:" />
              {watchTrigger === 'BIRTHDAY'
                ? 'The current month will be added to the beginning of the title for display.'
                : null}
              <ErrorMessage name="name" errors={errors} as={StyledError} />
            </div>
            <TextInput
              control={control}
              name="description"
              label="Description:"
            />
          </IonCol>
        </IonRow>

        <IonRow className="ion-padding-vertical">
          <IonCol size="12" sizeMd="3" sizeLg="2">
            <h2>Trigger:</h2>
          </IonCol>
          <IonCol size="12" sizeMd="9" sizeLg="10" className="space-y-4">
            <IonItem
              lines="none"
              style={{
                '--padding-start': '.5rem',
                '--background': 'var(--ion-color-step-100)',
                '--border-radius': '4px',
              }}
            >
              <IonLabel>Trigger</IonLabel>
              <Controller
                name="trigger"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <IonSelect
                    interfaceOptions={{ cssClass: 'dark-ionic-filter' }}
                    className="bg-transparent"
                    value={value}
                    onIonChange={(e) => onChange(e.detail.value)}
                  >
                    {triggers.map((trigger) => (
                      <IonSelectOption
                        key={trigger.value}
                        value={trigger.value}
                      >
                        {trigger.label}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                )}
              />
            </IonItem>
            {watchTrigger === 'LAST_SALE' ? (
              <div>
                <p>Last sale is between:</p>
                <div className="flex items-center gap-4">
                  <NumericInput control={control} name="filter.end" />
                  and
                  <NumericInput control={control} name="filter.start" />
                  days ago
                </div>
                <ErrorMessage name="filter" errors={errors} as={StyledError} />
                <ErrorMessage
                  name="filter.start"
                  errors={errors}
                  as={StyledError}
                />
                <ErrorMessage
                  name="filter.end"
                  errors={errors}
                  as={StyledError}
                />
              </div>
            ) : watchTrigger === 'BIRTHDAY' ? (
              <div>
                <div className="flex items-end gap-x-4">
                  <NumericInput
                    control={control}
                    name="filter.daysBefore"
                    label=""
                  />{' '}
                  <span>days before birthday month</span>
                </div>
                <ErrorMessage
                  name="filter.daysBefore"
                  errors={errors}
                  as={StyledError}
                />
              </div>
            ) : null}
          </IonCol>
        </IonRow>

        <IonRow className="ion-padding-vertical">
          <IonCol size="12" sizeMd="3" sizeLg="2">
            <h2>Messaging:</h2>
          </IonCol>
          <IonCol size="12" sizeMd="9" sizeLg="10" className="space-y-4">
            <IonItem
              lines="none"
              style={{
                '--padding-start': '.5rem',
                '--background': 'var(--ion-color-step-100)',
                '--border-radius': '4px',
              }}
            >
              <IonLabel>Select a saved template</IonLabel>
              <Controller
                name="templateId"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <IonSelect
                    interfaceOptions={{ cssClass: 'dark-ionic-filter' }}
                    className="bg-transparent"
                    value={value}
                    onIonChange={(e) => {
                      onChange(e.detail.value)
                      handleTemplateSelect(e.detail.value)
                    }}
                  >
                    {templatesQuery.data?.map((template) => (
                      <IonSelectOption
                        key={template.objectId}
                        value={template.objectId}
                      >
                        {template.name}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                )}
              />
            </IonItem>
            <ErrorMessage name="templateId" errors={errors} as={StyledError} />
            {watchTemplate ? (
              <>
                <h2>Text Message</h2>
                <p>
                  <strong>Message: </strong>
                  {message.smsMessage.body}
                </p>
                <h2>Email</h2>
                <div className="flex flex-col gap-2">
                  <div>
                    <p>
                      <strong>Subject: </strong>
                      {message.emailMessage.subject}
                    </p>
                  </div>
                  <p>
                    <strong>Message: </strong>
                    {message.emailMessage.body}
                  </p>
                </div>
                {message.shopWithLink ? (
                  <p>
                    <strong>ShopWith Link: </strong>
                    {message.shopWithLink}
                  </p>
                ) : null}
                {message.attachments ? (
                  <>
                    <p>
                      <strong>Attachment: </strong>
                    </p>
                    {message.attachments?.map((photo) => (
                      <div
                        key={typeof photo === 'string' ? photo : photo.pic}
                        className="flex items-center justify-between"
                      >
                        <IonImg
                          src={typeof photo === 'string' ? photo : photo.pic}
                          className="h-20"
                        />
                      </div>
                    ))}
                  </>
                ) : null}
              </>
            ) : null}
          </IonCol>
        </IonRow>
      </IonGrid>
    </form>
  )
}

export default CreateDynamicOutreachForm

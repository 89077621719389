import { z } from 'zod'

import { nullableString, serverItemSchema } from '../utils/schema'

type ProfileBase = z.infer<typeof profileBaseSchema>

const profileBaseSchema = serverItemSchema.extend({
  displayName: z.string(),
  firstName: nullableString,
  lastName: nullableString,
  photo: nullableString,
})

export type { ProfileBase }
export { profileBaseSchema }

import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  IonLoading,
  IonPage,
  IonRow,
} from '@ionic/react'

import Header from '../../components/header'
import { BroadcastItem } from '../../marketing/broadcasts/components/broadcast-item'
import { useCreateMyBroadcast } from '../../marketing/broadcasts/mutations'
import { useMyBroadcasts } from '../../marketing/broadcasts/utils'

function MyBroadcastsPage() {
  const { broadcasts, ref, loadMoreBroadcasts, ...broadcastsQuery } =
    useMyBroadcasts()

  const createBroadcast = useCreateMyBroadcast()

  return (
    <IonPage>
      <IonHeader>
        <Header title="Broadcasts" showMenu btn={null} />
      </IonHeader>
      <IonContent>
        <div className="ion-margin-top">
          <IonGrid
            style={{ '--ion-grid-padding': 0 }}
            className="ion-padding-horizontal"
          >
            <IonRow className="items-center whitespace-nowrap">
              <IonCol className="flex-full">
                <div className="flex items-center gap-x-2">
                  <IonButton
                    size="small"
                    color="yellow"
                    fill="solid"
                    onClick={() => createBroadcast.mutate({})}
                  >
                    Create Broadcast
                  </IonButton>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>

        {broadcasts?.length ? (
          <>
            <IonList>
              {broadcasts.map((broadcast) => (
                <BroadcastItem
                  key={broadcast.objectId}
                  broadcast={broadcast}
                  routerLink={`/broadcasts/detail/${broadcast.objectId}`}
                />
              ))}
            </IonList>
            <IonInfiniteScroll
              ref={ref}
              onIonInfinite={loadMoreBroadcasts}
              threshold="100px"
              disabled={!broadcastsQuery.hasNextPage}
            >
              <IonInfiniteScrollContent
                loadingSpinner="bubbles"
                loadingText="loading more broadcasts..."
              />
            </IonInfiniteScroll>
          </>
        ) : (
          <div className="ion-padding-horizontal flex justify-center">
            <p>
              You have not created any broadcasts. Click "Create Broadcast" to
              get started!
            </p>
          </div>
        )}
      </IonContent>
      <IonLoading
        isOpen={broadcastsQuery.isLoading}
        message="Loading broadcasts..."
      />
    </IonPage>
  )
}

export { MyBroadcastsPage }

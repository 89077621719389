import { IonContent, IonHeader, IonLoading, IonPage } from '@ionic/react'

import {
  useApplyLightspeedRewards,
  useRemoveLightspeedRewards,
} from '../../integrations/lightspeed/mutations'
import { useLightspeedClientLoyalty } from '../../integrations/lightspeed/queries'
import { useLightspeedParams } from '../../integrations/lightspeed/utils'
import { LoyaltyRedemptionDetail } from '../../loyalty/components/loyalty-redemption-detail'
import { Header } from '../../loyalty/components/header'
import { MissingItemsModal } from '../../loyalty/components/missing-items-modal'

function LightspeedRedemptionPage() {
  const { accountID, saleID, customerID, returnURL } = useLightspeedParams()

  const { data, isInitialLoading } = useLightspeedClientLoyalty({
    accountID,
    saleID,
    customerID,
  })

  const isTicketEmpty = !data?.lightspeedSale.SaleLines?.SaleLine?.length

  const applyRewards = useApplyLightspeedRewards()
  const removeRewards = useRemoveLightspeedRewards()

  return (
    <IonPage>
      <IonHeader>
        <Header returnUrl={returnURL} title="Redeem Discount" />
      </IonHeader>
      <IonContent>
        {data ? (
          <LoyaltyRedemptionDetail
            data={data}
            disabled={isInitialLoading}
            onApplyRewards={(rewards) => applyRewards.mutate(rewards)}
            onRemoveRewards={() => removeRewards.mutate()}
          />
        ) : (
          <IonLoading isOpen message="Getting Rewards..." />
        )}
      </IonContent>

      <MissingItemsModal
        isOpen={isTicketEmpty && !isInitialLoading}
        returnUrl={returnURL}
      />

      <IonLoading
        isOpen={applyRewards.isLoading || removeRewards.isLoading}
        message="Applying Discount..."
      />
    </IonPage>
  )
}

export default LightspeedRedemptionPage

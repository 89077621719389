import { useMutation } from '@tanstack/react-query'

import { client } from '../services/client'
import { authService } from '../services/authService'
import { searchService } from '../services/searchService'
import { localStorageKeys } from './constants'
import { useToast } from '../contexts/toastContext'
import { identify } from '../analytics'
import {
  createUserResponseSchema,
  resetPasswordResponseSchema,
  verificationEmailResponseSchema,
} from './user.schema'

import type {
  CreateUserInputs,
  LoginInputs,
  ResetPasswordInputs,
  VerificationEmailRequest,
} from './user.schema'

async function createUser(params: CreateUserInputs) {
  const response = await client
    .post('users', {
      json: { ...params },
    })
    .json()

  return createUserResponseSchema.parse(response)
}

function useCreateUser() {
  const setToast = useToast()

  return useMutation(createUser, {
    onError: (error) => {
      const errorMsg = error instanceof Error ? error.message : 'Unknown error.'
      setToast({
        message: `Something went wrong while creating your user: ${errorMsg}`,
        color: 'danger',
      })
    },
  })
}

async function login(params: LoginInputs) {
  const response = await client
    .post('login', {
      json: { ...params },
    })
    .json()

  return createUserResponseSchema.parse(response)
}

function useLogin() {
  const setToast = useToast()

  return useMutation(login, {
    onSuccess: (data) => {
      const { sessionToken, objectId, email, firstName, lastName } = data

      window.localStorage.setItem(localStorageKeys.token, sessionToken)
      window.localStorage.setItem(
        localStorageKeys.authUser,
        JSON.stringify(data)
      )

      if (objectId && email && firstName && lastName) {
        identify(objectId, email, firstName, lastName)
      }

      searchService.updateObject('_User', authService.getUserId(), {
        version: process.env.REACT_APP_VERSION,
      })
    },
    onError: (error) => {
      const errorMsg = error instanceof Error ? error.message : 'Unknown error.'
      setToast({
        message: `Something went wrong while logging in: ${errorMsg}`,
        color: 'danger',
      })
    },
  })
}

async function resetPassword(params: ResetPasswordInputs) {
  const response = await client
    .post('requestPasswordReset', {
      json: { ...params },
    })
    .json()

  return resetPasswordResponseSchema.parse(response)
}

function useResetPassword() {
  const setToast = useToast()

  return useMutation(resetPassword, {
    onSuccess: () => {
      setToast({
        message: 'Reset password email sent',
        color: 'yellow',
      })
    },
    onError: (error) => {
      const errorMsg = error instanceof Error ? error.message : 'Unknown error.'
      setToast({
        message: `Something went wrong while resetting your password: ${errorMsg}`,
        color: 'danger',
      })
    },
  })
}

async function verificationEmailRequest(params: VerificationEmailRequest) {
  const response = await client
    .post('verificationEmailRequest', {
      json: { ...params },
    })
    .json()

  return verificationEmailResponseSchema.parse(response)
}

function useVerificationEmailRequest() {
  const setToast = useToast()

  return useMutation(verificationEmailRequest, {
    onSuccess: () => {
      setToast({
        message: 'Verification email sent',
        color: 'yellow',
      })
    },
    onError: (error) => {
      const errorMsg = error instanceof Error ? error.message : 'Unknown error.'
      setToast({
        message: `Something went wrong while sending your verification email: ${errorMsg}`,
        color: 'danger',
      })
    },
  })
}

export {
  useCreateUser,
  useLogin,
  useResetPassword,
  useVerificationEmailRequest,
}

import { useRef } from 'react'

import type { CreateAutomationInput } from './automations-form.schema'

type ConditionValue = Record<string, any>

type ServerConditionItem = {
  type: string
  value: ConditionValue
}

type ResultObject = Record<string, ConditionValue>

function useAutomationFormRefs() {
  const messageItemRef = useRef<HTMLDivElement | null>(null)

  return { messageItemRef }
}

type T = 'sale' | 'brands' | 'discount' | 'noSales'
type C = {
  [key in T]?: Record<string, any>
}

function convertToObjectArray(original: C) {
  // TODO: Possibly refine type to be less general
  const output: Array<ServerConditionItem> = []

  Object.entries(original).forEach(([type, values]) => {
    // noSales
    if ('delayUnit' in values) {
      if (Object.values(values).some((v) => v == null)) return
      output.push({ type, value: values })
    } else {
      // other range conditions
      Object.entries(values).forEach(([operator, value]) => {
        if (value != null) {
          const item = { type, value: { [operator]: value } }
          output.push(item)
        }
      })
    }
  })

  return output
}

function composeConditions(conditions: CreateAutomationInput['conditions']) {
  return convertToObjectArray(conditions)
}

function parseConditions(conditions: Array<ServerConditionItem>) {
  const resultObject: ResultObject = {}

  conditions.forEach((item) => {
    if (!resultObject[item.type]) {
      resultObject[item.type] = {}
    }

    Object.assign(resultObject[item.type], item.value)
  })

  return resultObject
}

export type { ServerConditionItem, ResultObject, ConditionValue }
export { composeConditions, parseConditions, useAutomationFormRefs }

import { useIonActionSheet } from '@ionic/react'

import { usePhoto } from '../use-photo'

type Props = {
  header?: string
}

function useChooseProfilePhoto({ header }: Props = {}) {
  const [present] = useIonActionSheet()
  const { takePhoto, selectPhotos } = usePhoto()

  function presentSheet(
    onChange: ({
      name,
      photo,
      base64String,
    }: {
      name: string
      photo?: string
      base64String: string
    }) => void
  ) {
    present({
      header,
      buttons: [
        {
          text: 'Take Photo',
          handler: () => {
            takePhoto((p) =>
              onChange({
                name: p.name,
                photo: p.pic,
                base64String: p.base64String,
              })
            )
          },
        },
        {
          text: 'Select Photo',
          handler: () => {
            selectPhotos((p) =>
              onChange({
                name: p.name,
                photo: p.pic,
                base64String: p.base64String,
              })
            )
          },
        },
        { text: 'Cancel', role: 'cancel' },
      ],
      cssClass: 'action-sheet',
    })
  }

  return presentSheet
}

export { useChooseProfilePhoto }

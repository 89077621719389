import type { ReactNode } from 'react'

function BroadcastFormSection({ children }: { children: ReactNode }) {
  return (
    <div className="border-ion-color-secondary mt-3 rounded-lg border px-3 py-2">
      {children}
    </div>
  )
}

export { BroadcastFormSection }

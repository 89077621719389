import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonLabel,
  IonList,
  IonRow,
  IonItem,
  IonNote,
  IonCardContent,
} from '@ionic/react'
import { format, parseISO } from 'date-fns'

import Currency from '../Currency'
import Avatar from '../avatar'

const compare = (a, b) => {
  if (a.finalValueSum > b.finalValueSum) return -1
  return 1
}

const TopClientCard = ({ list = [] }) => {
  return (
    <IonCard color="secondary" style={{ margin: '16px' }}>
      <IonCardHeader style={{ textAlign: 'center' }}>
        <IonCardTitle>TOP CLIENTELE</IonCardTitle>
      </IonCardHeader>
      <IonCardContent class="dahsboard-long-list">
        <IonList>
          <IonRow
            color="secondary"
            lines="none"
            class="ion-justify-content-between ion-padding"
          >
            <IonLabel style={{ paddingLeft: '56px' }}>NAME</IonLabel>
            <IonLabel style={{ whiteSpace: 'pre' }}>TOTAL LAST SALE</IonLabel>
          </IonRow>
          {list
            .slice()
            .sort(compare)
            .map((object, index) => (
              <IonItem key={index} color="secondary">
                <div slot="start">
                  <Avatar user={object} />
                </div>
                {object.firstName || object.lastName ? (
                  <IonLabel>
                    {object.firstName} {object.lastName}
                  </IonLabel>
                ) : (
                  <IonLabel> {object.posCustomerId} </IonLabel>
                )}
                <IonNote
                  slote="end"
                  style={{
                    whiteSpace: 'pre',
                    paddingRight:
                      object.lastSale && object.lastSale.iso ? '0px' : '0px',
                  }}
                >
                  <table>
                    <tbody>
                      <tr style={{ textAlign: 'right', whiteSpace: 'pre' }}>
                        <td>
                          <b>
                            <Currency value={object.finalValueSum} />
                          </b>
                        </td>
                        <td style={{ minWidth: '72px' }}>
                          {object.lastSale && object.lastSale.iso
                            ? ` ${format(
                                parseISO(object.lastSale.iso),
                                'M-d-yy'
                              )}`
                            : ''}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </IonNote>
              </IonItem>
            ))}
        </IonList>
      </IonCardContent>
    </IonCard>
  )
}

export default TopClientCard

import { clsx } from 'clsx'

type Props = {
  checked: boolean
  disabled?: boolean
  id: string
  label: string
  onChange: (checked: boolean) => void
}

function Checkbox({ checked, disabled, id, label, onChange }: Props) {
  return (
    <div className="relative flex items-start">
      <label
        htmlFor={id}
        className={clsx('w-full select-none font-medium text-gray-50', {
          'cursor-pointer': !disabled,
          'opacity-50': disabled,
        })}
      >
        <div
          className={clsx('flex items-center gap-x-3', {
            'opacity-50': disabled,
          })}
        >
          <span className="size-5 flex items-center justify-center text-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              className={clsx(['size-5', { hidden: checked }])}
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M12 2.25A9.75 9.75 0 0 0 2.25 12 9.75 9.75 0 0 0 12 21.75 9.75 9.75 0 0 0 21.75 12 9.75 9.75 0 0 0 12 2.25z"
                clipRule="evenodd"
              />
            </svg>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className={clsx(['size-5', { hidden: !checked }])}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </span>

          <span className="text-lg">{label}</span>
          <input
            type="checkbox"
            id={id}
            className="hidden"
            disabled={disabled}
            checked={checked}
            onChange={(e) => onChange(e.currentTarget.checked)}
            name={id}
          />
        </div>
      </label>
    </div>
  )
}

export { Checkbox }

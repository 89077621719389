import * as R from 'remeda'

import { typedBoolean, typedKeys } from '../utils/typescript-helpers'
import { useAccount } from '../contexts/authContext'
import {
  settings,
  storeNames,
  unoFrenchStores,
  unoSpanishStores,
} from './constants'

import type { Account } from './auth.schema'

type Setting = typeof settings[number]

function hasRequiredSettings(
  userSettings: Record<string, any>,
  requiredSettings: Array<Setting>
) {
  if (!userSettings) return false

  const appSettingKeys = R.intersection(settings, typedKeys(userSettings))
  const picked = R.pick(userSettings, appSettingKeys)
  const theSettingsTheUserHas = Object.entries(picked)
    .filter(([k, v]) => v)
    .map(([k, v]) => k)

  return Boolean(R.intersection(requiredSettings, theSettingsTheUserHas).length)
}

const isSinglePlayerMode = (account: Account) =>
  !account.account ? true : false

function isSocialOnly(account: Account) {
  if (!account.account) return true

  const settings = account?.account?.settings ?? {}
  return typedKeys(settings)
    .filter((key) => settings[key])
    .every((setting) => setting === 'social')
}

// TODO: try to find a cleaner util
function makeRoles(account: Account) {
  return Object.entries(account)
    .map(([k, v]) => {
      if (
        k.startsWith('role') &&
        typeof v === 'object' &&
        v != null && // added due to subscriptionStatus nullable
        'role' in v
      ) {
        return `${k}_${v.role}`
      }
      return null
    })
    .filter(typedBoolean)
}

function useIsAuthorized({
  roles,
  appSettings,
}: {
  roles?: Array<string>
  appSettings?: Array<Setting>
}) {
  const account = useAccount()

  if (!Object.keys(account).length) return false
  if (!roles && !appSettings) return true

  return checkUserIsAllowed(account, roles, appSettings)
}

function checkUserIsAllowed(
  account: Account,
  roles?: Array<string>,
  appSettings?: Array<'social' | 'marketing' | 'clienteling'>
) {
  let hasRoles: boolean = false
  let hasSetting: boolean = false

  // HACK WORKAROUND FOR SinglePlayerMode USERS WITHOUT AN ACCOUNT (ONE THAT IS SET UP IN APP and has no "account" settings)
  let settings = account?.account?.settings

  if (!settings)
    settings = { social: true, clienteling: false, marketing: false }

  // permission checking
  if (settings && appSettings) {
    const userSettings = settings
    const keys = typedKeys(userSettings).filter((key) => userSettings[key])

    const intersection = keys.filter((key) => appSettings.includes(key))
    hasSetting = intersection.length > 0
  }

  if (!roles) {
    return hasSetting
  }

  const userRoles = makeRoles(account)
  // role checking
  if (userRoles && roles && userRoles.length > 0) {
    const intersection = userRoles.filter((role) => roles.includes(role))
    hasRoles = intersection.length > 0
  }
  return hasSetting && hasRoles
}
function isStore(account: Account, stores: Array<typeof storeNames[number]>) {
  return R.intersectionWith(
    account.groups ?? [],
    stores,
    (a, b) => a.companyName?.toLowerCase() === b.toLowerCase()
  )
}

function useHasRole(role?: 'admin' | 'associate') {
  const account = useAccount()

  if (!role) return true

  if (role === 'admin') return account.isAdmin
  if (role === 'associate') return account.isAssociate

  return false
}

function useIsStore() {
  const account: Account = useAccount()
  const isStore = (storeName: typeof storeNames[number]) =>
    account?.groups?.some((group) =>
      group.companyName?.toLowerCase().includes(storeName.toLowerCase())
    ) ?? false

  const isFabricBash = () => isStore('fabric bash')

  const isFaherty = () => isStore('faherty brand')

  const isGuysAndCo = () => isStore('guys and co')

  const isLadder = () => isStore('ladder')

  const isMorley = () => isStore('morley')

  const isOneShop = () => isStore('clientelier') || isStore('oneshop')

  const isPenelopeT = () => isStore('penelope t')

  const isPeriwinkle = () => isStore('periwinkle')

  const isQ = () => isStore('q clothier')

  const isSkirt = () => isStore('skirt')

  const isTristan = () => isStore('tristan')

  const isTwb = () => isStore('TWB Home Decor')

  const isUno = () => isStore('unode50')

  const isScoutMollysPlano = () => isStore('Scout & Mollys Plano')
  const isScoutMollysReston = () =>
    isStore('Scout & Mollys (Annapolis, Mosaic, & Reston Stores)')

  const isSharlas = () => isStore("sharla's boutique")

  // spanish stores for Uno are either in the list or begin with PDH
  const isUnoSpanish = () =>
    isUno() &&
    account.groups?.some((group) =>
      group.name
        ? unoSpanishStores.some((store) => store === group.name) ||
          group.name.toLowerCase().startsWith('pdh')
        : false
    )
  const isUnoFrench = () =>
    isUno() &&
    account.groups?.some((group) =>
      group.name ? unoFrenchStores.some((store) => store === group.name) : false
    )

  return {
    isFabricBash,
    isFaherty,
    isGuysAndCo,
    isLadder,
    isMorley,
    isOneShop,
    isPenelopeT,
    isPeriwinkle,
    isQ,
    isScoutMollysPlano,
    isScoutMollysReston,
    isSharlas,
    isSkirt,
    isTristan,
    isTwb,
    isUno,
    isUnoSpanish,
    isUnoFrench,
  }
}

export type { Account, Setting }
export {
  hasRequiredSettings,
  isSinglePlayerMode,
  isSocialOnly,
  isStore,
  useHasRole,
  useIsAuthorized,
  useIsStore,
}

import React from 'react'
import {
  IonContent,
  IonBackButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSearchbar,
  IonSpinner,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
} from '@ionic/react'
import { useQuery } from '@tanstack/react-query'
import { useDebounce } from 'use-debounce'

import { searchService } from '../services/searchService'
import { useGlobalSearch } from '../hooks/use-global-search'
import { ellipse } from 'ionicons/icons'
import { useAccount } from '../contexts/authContext'

type ClientSearchResponse = {
  clients: Array<{
    objectId: string | null
    firstName: string | null
    lastName: string | null
    isMine: boolean
  }>
}

const debounceTime = 700

function Search() {
  const searchBarEl = React.useRef<HTMLIonSearchbarElement | null>(null)

  const [searchString, setSearchString] = React.useState('')

  const account = useAccount()

  // debounce the search string value for useQuery calls
  const [debouncedSearchString] = useDebounce(searchString, debounceTime)

  const canSearchAll = useGlobalSearch()

  const clientSearchQuery = useQuery({
    queryKey: ['search', debouncedSearchString],
    queryFn: async () => {
      const response: ClientSearchResponse = await searchService.searchClients({
        filter: { name: searchString, list: canSearchAll ? undefined : 'mine' },
      })
      return response.clients
    },
    enabled: Boolean(debouncedSearchString.length),
  })

  useIonViewDidEnter(() => {
    searchBarEl && searchBarEl.current && searchBarEl.current.setFocus()
  }, [])

  const backRef = account.isAdmin ? '/tabs/admin/clients' : '/tabs/clients'

  return (
    <IonPage id="search-clients">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Search For Clients</IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={backRef} color="secondary" />
          </IonButtons>
          <IonSearchbar
            ref={searchBarEl}
            type="search"
            color="light"
            showCancelButton="focus"
            cancelButtonText="Cancel"
            value={searchString}
            onIonInput={(e) => setSearchString(e.target.value!)}
          />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {clientSearchQuery.isInitialLoading && (
          <div className="ion-text-center ion-padding">
            <IonSpinner />
          </div>
        )}

        <IonList inset>
          {clientSearchQuery.data?.map((client) => (
            <IonItem
              color="secondary"
              key={client.objectId}
              routerLink={`/tabs/clients/client/${client.objectId}`}
            >
              <div tabIndex={0} />
              <IonLabel>
                {client.firstName} {client.lastName}
              </IonLabel>
              <IonIcon
                size="small"
                icon={ellipse}
                color="primary"
                hidden={!client.isMine}
              />
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonPage>
  )
}

export default Search

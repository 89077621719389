import { IonSkeletonText, useIonRouter } from '@ionic/react'

import { useMyDynamicCallLists } from '../../../hooks/outreach/queries'
import {
  ScrollingClientList,
  ScrollingClientListSkeleton,
} from '../scrolling-client-list'
import { SectionContent, SectionHeader, SectionWrapper } from '../section'
import { SeeMore } from '../see-more'

function DynamicOutreachSection() {
  const dynamicCallListQuery = useMyDynamicCallLists()
  const router = useIonRouter()

  return (
    <>
      {dynamicCallListQuery.data ? (
        <>
          {dynamicCallListQuery.data.map((callList) => (
            <SectionWrapper key={callList.objectId}>
              <SectionHeader
                title={callList.displayName}
                action={
                  <SeeMore
                    onClick={() => {
                      router.push(
                        `/tabs/home/outreach/dynamic/${callList.objectId}`
                      )
                    }}
                  />
                }
              />
              <SectionContent>
                {callList.clients.length > 0 ? (
                  <ScrollingClientList list={callList.clients} />
                ) : (
                  <div className="ion-padding-horizontal">
                    <p>You have no clients in this outreach.</p>
                  </div>
                )}
              </SectionContent>
            </SectionWrapper>
          ))}
        </>
      ) : dynamicCallListQuery.isLoading ? (
        <>
          <div className="ion-padding-horizontal">
            <h2 className="text-2xl">
              <IonSkeletonText animated className="w-7/12 leading-inherit" />
            </h2>
          </div>
          <SectionContent>
            <ScrollingClientListSkeleton />
          </SectionContent>
        </>
      ) : null}
    </>
  )
}

export default DynamicOutreachSection

import { IonContent, IonPage, IonHeader, IonLoading } from '@ionic/react'

import AdminHeader from '../../components/header/admin-header'
import { UserSetupTable } from '../../admin/user-setup/components/user-setup-table'
import { useUserSetup } from '../../admin/user-setup/utils'
import { Card, CardContent } from '../../components/ui/card'

function UserSetupPage() {
  const { data, isError, isPending, isPreviousData, totalCount } =
    useUserSetup()
  const isSetupFetching = isPending || isPreviousData

  return (
    <IonPage>
      <IonHeader>
        <AdminHeader title="User Setup" showMenu />
      </IonHeader>
      <IonContent className="ion-padding">
        {data ? (
          <UserSetupTable
            data={data}
            disabled={isPending}
            rowCount={totalCount}
            state={{
              isLoading: isPending,
              showProgressBars: isSetupFetching,
            }}
          />
        ) : isError ? (
          <Card>
            <CardContent>Something went wrong loading the users.</CardContent>
          </Card>
        ) : (
          <IonLoading isOpen message="Loading users..." />
        )}
      </IonContent>
    </IonPage>
  )
}

export { UserSetupPage }

import { IonButton } from '@ionic/react'

import { useAccount, useUser } from '../../../contexts/authContext'
import { useShowBroadcast } from '../../../hooks/broadcast/use-show-broadcast'
import { useInitSequence } from '../../../hooks/use-init-sequence'
import { useIsStore } from '../../../auth/utils'
import { useCreateMyBroadcast } from '../../../marketing/broadcasts/mutations'

import type { Client } from '../../../hooks/clients/queries'
import type { Template } from '../../../hooks/templates/queries'
import type { ServerFilters } from '../../../clients/client-filters.schema'
import type { Exact } from 'type-fest'

// typehelper since typescript isn't smart enough to throw runtime error when
// passing an object with overlapping properties (like in filters server expects and filters from form)
type BulkMessageButtonProps<T extends Exact<ServerFilters, T>> = {
  clientList: Array<Client>
  filter?: T
  template?: Template | null
  pointers?: {
    callListId?: string
    callListDynamicId?: string
  }
}

function BulkMessageButton<T extends Exact<ServerFilters, T>>({
  clientList,
  filter,
  template,
  pointers = {},
}: BulkMessageButtonProps<T>) {
  const user = useUser()
  const account = useAccount()
  const { isSkirt } = useIsStore()

  const { hasBroadcastSettings } = useShowBroadcast()

  const { initializeSequence } = useInitSequence()
  const createBroadcast = useCreateMyBroadcast()

  const hasSequencePermissions = isSkirt()
    ? false
    : !user.hasTwilio ||
      (user.hasTwilio && user.smsManual) ||
      (user.hasTwilio && !account.optinRequired)

  return (
    <>
      {hasSequencePermissions ? (
        <IonButton
          disabled={clientList?.length === 0}
          size="small"
          color="yellow"
          fill="solid"
          onClick={() => {
            initializeSequence({
              list: clientList,
              backRef: '/tabs/clients',
              template,
              ...pointers,
            })
          }}
        >
          Start Sequence
        </IonButton>
      ) : null}
      {hasBroadcastSettings() ? (
        <IonButton
          disabled={clientList.length === 0}
          size="small"
          color="yellow"
          fill="solid"
          onClick={() => {
            createBroadcast.mutate({
              filter: filter
                ? { ...filter, admin: false, users: undefined }
                : { objectIds: clientList.map(({ objectId }) => objectId) },
              smsMessage: template?.smsTemplate?.body ?? '',
              emailSubject: template?.emailTemplate?.subject ?? '',
              emailMessage: template?.emailTemplate?.body ?? '',
              photo: template?.smsTemplate?.attachments
                ? template?.smsTemplate?.attachments[0]
                : null,
              productIdsSms: template?.products?.map((p) => p.objectId),
              lookIdsSms: template?.looks?.map((l) => l.objectId),
              collectionIdsSms: template?.collections?.map((c) => c.objectId),
              productIdsEmail: template?.products?.map((p) => p.objectId),
              lookIdsEmail: template?.looks?.map((l) => l.objectId),
              collectionIdsEmail: template?.collections?.map((c) => c.objectId),
              ...pointers,
            })
          }}
        >
          Start Broadcast
        </IonButton>
      ) : null}
    </>
  )
}

export default BulkMessageButton

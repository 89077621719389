import * as React from 'react'
import { IonImg, IonRouterLink } from '@ionic/react'
import { Tab } from '@headlessui/react'
import clsx from 'clsx'
import { name } from '@cloudinary/url-gen/actions/namedTransformation'
import { ResourceType } from '@capawesome/capacitor-cloudinary'

import placeholder from '../../resources/no-image-product.png'
import { oneshopCld } from '../../utils/cloudinary'
import { useProfileLooks } from '../../hooks/profile/profile-looks/queries'
import { useProfileProducts } from '../../hooks/profile/profile-products/queries'
import CldVideoThumbnail from '../cloudinary/cld-video-thumbnail'
import CollageItem from '../shopwith/collage-item'

function ProfileContent({ profileId }: { profileId: string }) {
  const profileLooksQuery = useProfileLooks(profileId)
  const profileProductsQuery = useProfileProducts(profileId)

  return (
    <div className="pt-6">
      <Tab.Group>
        <Tab.List className="mx-auto flex w-full items-center justify-center sm:gap-8">
          <Tab as={React.Fragment}>
            <button
              className={clsx([
                'w-full py-2',
                'focus:shadow-tab-highlight focus:outline-none',
                'focus-visible:shadow-tab-highlight focus-visible:outline-none',
                'sm:w-auto sm:px-4',
                // fancy workaround to account for layout shift when bolding text
                'after:pointer-events-none after:invisible after:h-0 after:select-none after:overflow-hidden after:font-bold after:content-[attr(data-label)]',
                'inline-flex flex-col items-center justify-between',
                'ui-selected:shadow-tab-highlight ui-selected:font-bold',
                'ui-not-selected:shadow-tab-border ui-not-selected:font-normal',
              ])}
            >
              OneShop Posts
            </button>
          </Tab>
          <Tab as={React.Fragment}>
            <button
              className={clsx([
                'w-full py-2 ',
                'focus:shadow-tab-highlight focus:outline-none',
                'focus-visible:shadow-tab-highlight focus-visible:outline-none',
                'sm:w-auto sm:px-4',
                // fancy workaround to account for layout shift when bolding text
                'after:pointer-events-none after:invisible after:h-0 after:select-none after:overflow-hidden after:font-bold after:content-[attr(data-label)]',
                'inline-flex flex-col items-center justify-between',
                'ui-selected:shadow-tab-highlight ui-selected:font-bold',
                'ui-not-selected:shadow-tab-border ui-not-selected:font-normal',
              ])}
            >
              Top Picks
            </button>
          </Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            <div className="grid grid-cols-3 gap-0.5 md:gap-4">
              {profileLooksQuery.data?.pages
                .flatMap((x) => x.data)
                .map((profileLook) => {
                  const cldImage = oneshopCld
                    .video(profileLook.look.cloudinary?.data?.public_id)
                    .namedTransformation(name('1x1_video_poster'))
                    .format('auto:image')

                  return (
                    <IonRouterLink
                      routerLink={`/tabs/profile/post/${profileLook.objectId}`}
                      key={profileLook.objectId}
                    >
                      <div className="aspect-w-1 aspect-h-1">
                        {profileLook.look.cloudinary?.data?.resource_type ===
                        ResourceType.Video ? (
                          <CldVideoThumbnail cldImage={cldImage} />
                        ) : profileLook.look.coverPhoto ? (
                          <IonImg
                            className="w-full object-cover"
                            src={profileLook.look.coverPhoto.originalSrc}
                            alt=""
                          />
                        ) : (
                          <CollageItem items={profileLook.look.products} />
                        )}
                      </div>
                    </IonRouterLink>
                  )
                })}
            </div>
          </Tab.Panel>
          <Tab.Panel>
            <div className="grid grid-cols-3 gap-0.5 md:gap-4">
              {profileProductsQuery.data?.pages
                .flatMap((x) => x.data)
                .map((profileProduct) => (
                  <IonRouterLink
                    key={profileProduct.objectId}
                    routerLink={`/tabs/profile/top-pick/${profileProduct.objectId}`}
                  >
                    <div className="aspect-w-1 aspect-h-1">
                      <IonImg
                        className="w-full object-cover"
                        src={
                          profileProduct.product.featuredImage ?? placeholder
                        }
                        alt=""
                      />
                    </div>
                  </IonRouterLink>
                ))}
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}

export default ProfileContent

import * as React from 'react'

import { useMaterials } from '../../../hooks/queries/use-materials'
import SearchableSelect from '../../ui/form/searchable-select'

import type { SelectProps } from './types'

function MaterialSelect({
  isAdmin,
  selectedItems,
  setSelectedItems,
}: SelectProps) {
  const [isOpen, setIsOpen] = React.useState(false)

  const materialsQuery = useMaterials({ admin: isAdmin, enabled: isOpen })

  return (
    <SearchableSelect
      onOpen={() => setIsOpen(true)}
      label="Materials"
      items={materialsQuery.data ?? []}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
    />
  )
}

export default MaterialSelect

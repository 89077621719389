import {
  IonContent,
  IonPage,
  IonHeader,
  IonGrid,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRow,
  IonCol,
} from '@ionic/react'
import { useParams } from 'react-router-dom'

import { getListType } from '../../utils/typescript-helpers'
import { useBroadcastDetail } from '../../hooks/broadcast/queries'
import { useFormatNumber } from '../../hooks/use-format-number'
import { useInfiniteList } from '../../hooks/use-infinite-list'
import { useQueryParams } from '../../hooks/use-query-params'
import { useClientSorter } from '../../hooks/use-sorter-action-sheet'
import ClientItem from '../../components/client/client-item'
import Header from '../../components/header'
import { SortButton } from '../../components/ui/buttons/sort-button'

function BroadcastDetailClientsPage() {
  const { broadcastId } = useParams<{ broadcastId: string }>()

  const formatNumber = useFormatNumber()
  const { openSorterActionSheet, sortCallback } = useClientSorter()
  const queryParams = useQueryParams()

  const broadcastQuery = useBroadcastDetail(broadcastId)

  const { infiniteListCount, doInfiniteStuff } = useInfiniteList()

  const queryList = getListType(queryParams)
  const title =
    queryList === 'email'
      ? 'Email List'
      : queryList === 'sms'
      ? 'Text List'
      : 'Total List'
  const list =
    queryList === 'email'
      ? broadcastQuery.data?.emailClients ?? []
      : queryList === 'sms'
      ? broadcastQuery.data?.smsClients ?? []
      : []
  const sortedList = [...list].sort(sortCallback)

  return (
    <IonPage>
      <IonHeader>
        <Header
          backRef={`/broadcasts/detail/${broadcastId}`}
          title={title}
          btn={null}
        />
      </IonHeader>

      <IonContent>
        <div className="ion-margin-top">
          <IonGrid
            style={{ '--ion-grid-padding': 0 }}
            className="ion-padding-horizontal"
          >
            <IonRow className="ion-nowrap ion-align-items-center">
              <IonCol className="flex-full">
                <div className="flex">
                  <h2 className="text-ion-color-yellow">
                    {formatNumber(sortedList.length)} Client
                    {sortedList.length === 1 ? '' : 's'}
                  </h2>
                </div>
              </IonCol>
              <IonCol>
                <SortButton onClick={openSorterActionSheet} />
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
        <IonGrid>
          {sortedList.slice(0, infiniteListCount).map((client) => (
            <ClientItem key={client.objectId} client={client} />
          ))}
          <IonInfiniteScroll
            onIonInfinite={doInfiniteStuff}
            disabled={infiniteListCount >= sortedList.length}
          >
            <IonInfiniteScrollContent loadingSpinner="bubbles" />
          </IonInfiniteScroll>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export default BroadcastDetailClientsPage

import * as React from 'react'
import {
  IonContent,
  IonPage,
  IonHeader,
  IonSegment,
  IonSegmentButton,
  IonRow,
  IonLoading,
  IonImg,
} from '@ionic/react'
import { Browser } from '@capacitor/browser'

import newUpdates from '../resources/new-updates.png'
import { useAccount } from '../contexts/authContext'
import Header from '../components/header'
import { FahertyRoi } from '../components/home/fahery-roi'
import Feed from '../components/home/feed/feed'
import Lists from '../components/home/lists/lists'
import CreatePostButton from '../components/home/create-post-button'
import { isSocialOnly, useIsStore } from '../auth/utils'
import { AdminLoyaltyInfo } from './home/admin-loyalty-info'
import { useFlatAccountGroups } from '../hooks/queries/use-groups'

type CurrentSegment = 'home' | 'lists' | 'feed'

const newUpdatesLink = 'https://youtu.be/DwLGYEnS7iE'

function HomePage() {
  const account = useAccount()
  const isAdmin = account.isAdmin

  const [currentSegment, setCurrentSegment] = React.useState<CurrentSegment>(
    isAdmin && !account.isEnterprise ? 'home' : 'lists'
  )

  const { isFaherty } = useIsStore()

  // HACK: ACCOUNT EMPTY OBJECT WORKAROUND
  if (!Object.keys(account).length) return null

  return (
    <IonPage id="home">
      <IonHeader>
        <Header showMenu title="Home" />
      </IonHeader>
      <IonContent className="ion-padding-vertical space-y-6">
        <>
          {isSocialOnly(account) ? (
            <Feed />
          ) : (
            <>
              {isFaherty() ? <FahertyRoi /> : null}
              <div className="ion-padding-horizontal mx-auto max-w-xl">
                <IonSegment
                  value={currentSegment}
                  onIonChange={(e) =>
                    setCurrentSegment(e.detail.value as CurrentSegment)
                  }
                >
                  {isAdmin && !account.isEnterprise ? (
                    <IonSegmentButton value="home">Home</IonSegmentButton>
                  ) : null}
                  <IonSegmentButton value="lists">Lists</IonSegmentButton>
                  <IonSegmentButton value="feed">Feed</IonSegmentButton>
                </IonSegment>
              </div>

              {currentSegment === 'lists' ? <Lists /> : null}

              {currentSegment === 'feed' ? <Feed /> : null}

              {currentSegment === 'home' ? <AdminHome /> : null}
            </>
          )}
        </>
      </IonContent>
    </IonPage>
  )
}

function AdminHome() {
  const account = useAccount()
  const groups = useFlatAccountGroups()

  return (
    <>
      {groups.isLoading ? (
        <IonLoading isOpen message="Loading..." />
      ) : (
        <>
          <IonRow className="items-center justify-center gap-x-3">
            <CreatePostButton />
          </IonRow>
          {account.account.settings.loyalty ? <AdminLoyaltyInfo /> : null}
          <IonRow className="mx-auto max-w-lg">
            <button onClick={() => Browser.open({ url: newUpdatesLink })}>
              <IonImg src={newUpdates} />
            </button>
          </IonRow>
        </>
      )}
    </>
  )
}

export default HomePage

import { IonContent, IonHeader, IonLoading, IonPage } from '@ionic/react'
import { useParams } from 'react-router-dom'

import { useProduct } from '../../hooks/shopwith/products/queries'
import Header from '../../components/header'
import ProductDetail from '../../components/shopwith/products/product-detail'

function DeliveryPage() {
  const { deliveryId } = useParams<{ deliveryId: string }>()
  const deliveryQuery = useProduct(deliveryId)

  return (
    <IonPage>
      <IonHeader>
        <Header title="Delivery" btn={null} />
      </IonHeader>
      <IonContent className="ion-padding-vertical">
        {deliveryQuery.data ? (
          <ProductDetail product={deliveryQuery.data} />
        ) : (
          <IonLoading isOpen message="Loading Delivery..." />
        )}
      </IonContent>
    </IonPage>
  )
}

export default DeliveryPage

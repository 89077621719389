import { mapValues } from 'remeda'

import { hasRequiredSettings, isStore } from '../auth/utils'
import { routes as routesConfig } from './menu.config'

import type { Account } from '../auth/auth.schema'

type AppSetting = typeof appSettings[number]
const appSettings = ['social', 'marketing', 'clienteling'] as const

function getUserRoutes(account: Account) {
  return mapValues(routesConfig, (v, k) => {
    return v.filter((val) => {
      const isUserAllowed = isAllowed(account, val.user)
      const accountHasSetting = hasSetting(account, val.appSettings)

      return isUserAllowed && accountHasSetting
        ? val.flag?.exclude
          ? !isStore(account, val.flag.exclude).length
          : isUserAllowed && accountHasSetting
        : val.flag?.include
        ? isStore(account, val.flag.include).length
        : isUserAllowed && accountHasSetting
    })
  })
}

function hasSetting(account: Account, routeSettings?: Array<AppSetting>) {
  if (!routeSettings) return true

  return hasRequiredSettings(account?.account?.settings, routeSettings)
}

function isAllowed(account: Account, allowedRouteUser?: 'admin' | 'associate') {
  if (!allowedRouteUser) return true

  if (allowedRouteUser === 'admin') return account.isAdmin as boolean
  if (allowedRouteUser === 'associate') return account.isAssociate as boolean

  return false
}

export { getUserRoutes, hasSetting, isAllowed }

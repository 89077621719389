import { z } from 'zod'

import { makeServerResponseSchema } from '../../utils/schema'

const shopifyAddStoreInputsSchema = z.object({
  shop: z.string(),
})

const shopifyAddStoreResponseSchema = makeServerResponseSchema(
  z
    .object({
      redirect: z.string().optional(),
    })
    .optional()
)

export type ShopifyAddStoreInputs = z.infer<typeof shopifyAddStoreInputsSchema>
export { shopifyAddStoreInputsSchema, shopifyAddStoreResponseSchema }

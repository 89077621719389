import * as React from 'react'
import {
  IonButton,
  IonCol,
  IonContent,
  IonPage,
  IonRow,
  useIonRouter,
} from '@ionic/react'
import { useLocation } from 'react-router-dom'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

import { cn } from '../../utils/cn'
import { useAccount } from '../../contexts/authContext'
import { useToast } from '../../contexts/toastContext'
import { useCheckInActions } from '../../check-in/check-in-store'
import { useOptInClient } from '../../marketing/customer-opt-in/mutations'
import { PhoneKeypadInput } from '../../components/ui/phone-keypad-input'
import { getClientFromE164 } from '../../rotate/api'
import { RotateInfoContainer } from '../../rotate/components/rotate-info-container'
import { RotateInfoIntro } from '../../rotate/components/rotate-info-intro'
import { RotateInfoLegal } from '../../rotate/components/rotate-info-legal'

import type { CountryCode } from 'libphonenumber-js'

function RotateOptInPage() {
  const account = useAccount()

  return (
    <IonPage
      style={{
        '--image-url': `url(${account.account.checkInSettings?.background?.image})`,
      }}
    >
      <IonContent>
        <CustomerOptIn />
      </IonContent>
    </IonPage>
  )
}

function CustomerOptIn() {
  const [isLoading, setIsLoading] = React.useState(false)
  const [error, setError] = React.useState<string | null>(null)

  const account = useAccount()
  const checkInSettings = account.account.checkInSettings

  const router = useIonRouter()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const { resetClientInfo, setCheckInMethod, setClientInfo } =
    useCheckInActions()
  const setToast = useToast()

  const optInClient = useOptInClient()

  async function handleSignUp({
    phoneNumber,
    country,
  }: {
    phoneNumber: string
    country: CountryCode
  }) {
    const parsedNumber = parsePhoneNumberFromString(phoneNumber, country)
    if (!parsedNumber?.isValid())
      return setToast({
        message: 'You have entered an invalid phone number.',
        color: 'danger',
      })
    setCheckInMethod({ phone: parsedNumber.number.toString() })
    setIsLoading(true)

    try {
      const response = await getClientFromE164(parsedNumber.number)
      setClientInfo({ phoneNumber: parsedNumber.number as string })

      if (response.client) {
        optInClient.mutate(parsedNumber.number, {
          onSuccess: ({ client }) => {
            const phoneNumber = client.phoneNumbers?.length
              ? client.phoneNumbers[0].e164
              : ''
            setClientInfo({
              ...client,
              dobObject: {
                month: client.dobObject.month ?? null,
                day: client.dobObject.day ?? null,
              },
              phoneNumber,
            })
            searchParams.append('clientId', client.objectId)

            router.push(
              `/rotate/opt-in/success?${searchParams}`,
              'none',
              'replace'
            )
          },
        })
      } else {
        router.push(`/rotate/add-customer?${searchParams}`, 'none', 'replace')
      }
    } catch (error) {
      console.error(error)
      resetClientInfo()
      if (error instanceof Error) setError(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div
      className={cn('ion-padding h-full', {
        'bg-[image:var(--image-url)] bg-cover':
          checkInSettings?.background?.image,
      })}
    >
      <IonRow className="h-full">
        <IonCol
          size="6"
          className="flex h-full flex-1 flex-col items-center justify-center space-y-6"
        >
          <RotateInfoContainer>
            {searchParams.get('loyalty') ? (
              <IonButton
                disabled={isLoading}
                fill="solid"
                color="secondary"
                onClick={() =>
                  router.push(
                    `/rotate/email-check-in?${searchParams}`,
                    'none',
                    'replace'
                  )
                }
              >
                Check-In With Email
              </IonButton>
            ) : (
              // todo: empty div for grid spacing (mebbe refactor container to allow for only 2 items)
              <div />
            )}
            <div className="space-y-3 self-center text-center">
              {checkInSettings?.body?.text ? (
                <RotateInfoIntro>{checkInSettings?.body?.text}</RotateInfoIntro>
              ) : (
                <>
                  <RotateInfoIntro>
                    Welcome to {account.account.companyName}.
                  </RotateInfoIntro>
                  <RotateInfoIntro>
                    Enter your phone number to join our VIP text list!
                  </RotateInfoIntro>
                </>
              )}
              <p className="text-ion-color-danger">{error}</p>
            </div>
            <RotateInfoLegal />
          </RotateInfoContainer>
        </IonCol>
        <IonCol
          size="6"
          className="flex h-full flex-1 flex-col items-center justify-center gap-6"
        >
          <PhoneKeypadInput
            disabled={isLoading}
            onConfirm={handleSignUp}
            altLabel="Check In"
          />
        </IonCol>
      </IonRow>
    </div>
  )
}

export { RotateOptInPage }

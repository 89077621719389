import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from '../../../../components/ui/card'

type Props = {
  children: React.ReactNode
  title: string
}

function HeartlandCard({ children, title }: Props) {
  return (
    <Card narrow>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
      </CardHeader>
      <CardContent>
        <p className="text-center">{children}</p>
      </CardContent>
    </Card>
  )
}

export { HeartlandCard }

import React from 'react'
import { useImmerReducer } from 'use-immer'

const MetaStateContext = React.createContext()
const MetaDispatchContext = React.createContext()

const initialState = {
  type: 'dailyMeta',
  dailyMeta: ['lastContact', 'lastSale'],
  callListMeta: ['lastContact', 'lastSale', 'salesTotal'],
  clientsMeta: ['lastContact', 'lastSale', 'salesTotal', 'filterSalesTotal'],
  adminClientsMeta: [
    'lastContact',
    'lastSale',
    'salesTotal',
    'associates',
    'posSellerNames',
    'filterSalesTotal',
  ],
}

function MetaReducer(draft, action) {
  switch (action.type) {
    case 'setMetaType': {
      draft.type = action.payload.type
      break
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function MetaProvider({ children }) {
  const [state, dispatch] = useImmerReducer(MetaReducer, initialState)

  return (
    <MetaStateContext.Provider value={state}>
      <MetaDispatchContext.Provider value={dispatch}>
        {children}
      </MetaDispatchContext.Provider>
    </MetaStateContext.Provider>
  )
}

function useMetaState() {
  const context = React.useContext(MetaStateContext)

  if (context === undefined) {
    throw new Error('useMetaState must be used within a MetaProvider')
  }

  return context
}

function useMetaUpdate() {
  const context = React.useContext(MetaDispatchContext)

  if (context === undefined) {
    throw new Error('useMetaUpdate must be used within a MetaProvider')
  }

  return context
}

export { MetaProvider, useMetaState, useMetaUpdate }

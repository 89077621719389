import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { addDays, format } from 'date-fns'
import { fromZodError } from 'zod-validation-error'

import { sortFields } from '../../utils/array-helpers'
import { composeSearchFilters } from '../../utils/compose-search-filters'
import { makeServerResponseSchema } from '../../utils/schema'
import { hasDefinedProp } from '../../utils/typescript-helpers'
import { client } from '../../services/client'
import { useToast } from '../../contexts/toastContext'
import { outreachClientCountsSchema } from '../../clienteling/outreach/outreach.schema'

import type {
  CallList,
  CallListBase,
} from '../../clienteling/outreach/outreach.schema'
import type { Group, IsoDate, ProfileBase } from '../../types/general'
import type { Client } from '../clients/queries'
import type { DataSet } from '../../components/roi/RoiChart'
import type { Template } from '../templates/queries'
import type { QueryContextFromKeys } from '../../utils/react-query'
import type {
  FiltersForm,
  ServerFilters,
} from '../../clients/client-filters.schema'

type FetchCallListsResponse = {
  result: {
    data: Array<CallList>
    hasNextPage: boolean
    page: number
    pageSize: number
  }
}

type FetchCallListDetailResponse = {
  result: CallListDetail
}

type FetchDynamicCallListsResponse = {
  result: {
    data: Array<DynamicCallList>
    hasNextPage: boolean
    page: number
    pageSize: number
  }
}

type FetchDynamicCallListDetailResponse = {
  result: DynamicCallListDetail
}

type FetchGoalsResponse = {
  result: {
    goal: number
    thisMonthMessageCount: number
  }
}

type FetchAdminCallList = {
  result: {
    callList: CallListSimple
    userStats: Array<{
      clientCount: number
      contactedClientsCount: number
      user: ProfileBase | null
    }>
  }
}

type CallListSimple = Omit<CallList, 'stats'> & {
  groups: Array<Group>
  template: Template | null
}
type OutreachType = 'One Time' | 'Dynamic' | 'Suggested'

type CallListDetail = CallList & {
  clients: Array<Client & { users: Array<ProfileBase> }>
  groups: Array<Group>
  filter?: ServerFilters
  stats: {
    clientCount: number
    contactedClientsCount: number
    roi: {
      labels: Array<string>
      datasets: DataSet
    }
  }
  stylistStats: Array<CallListStat>
  template: Template | null
}

//TODO: revisit, this actually returns ClientBase but BulkMessageButton expects Client(mebbe can change that to base too)
type DynamicClient = Client & {
  updatedAt: IsoDate
  callListDynamicContacted: boolean
}

type DynamicCallList = Omit<CallListBase, 'user'> & {
  clients: Array<DynamicClient>
  user: ProfileBase | null
} & (
    | { trigger: 'LAST_SALE'; filter: { start: number; end: number } }
    | { trigger: 'BIRTHDAY'; filter: { daysBefore: number } }
  )

export type DynamicCallListDetail = DynamicCallList & {
  template: Template | null
}

type CallListStat = {
  objectId: string
  firstName?: string
  lastName?: string
  photo: string | null
  clientCount: number
  contactedClientsCount: number
}

type RecentSalesClient = Client & {
  recentSales: Array<{
    posPhotoUrls: Array<string>
    finalValue?: number | null
  }>
}

type QListsResponse = {
  result: {
    clientsWithNoMessage: Array<Client>
    clientsWithOneMessage: Array<Client>
    clientsWithTwoMessages: Array<Client>
    clientsWithThreeMessages: Array<Client>
  }
}

type QLists = QListsResponse['result'] & {
  clientsWithAppt: Array<Client>
  clientsWithoutAppt: Array<Client>
}

type OutreachQueryContexts = QueryContextFromKeys<typeof outreachQueryKeys>

const outreachQueryKeys = {
  all: [{ entity: 'outreach' }] as const,
  lists: () => [{ ...outreachQueryKeys.all[0], scope: 'list' }] as const,
  list: ({ type, admin }: { type: OutreachType; admin?: true }) =>
    [
      { ...outreachQueryKeys.lists()[0], type, ...(admin && { admin }) },
    ] as const,
  details: () => [{ ...outreachQueryKeys.all[0], scope: 'detail' }] as const,
  detail: ({ id, admin }: { id: string; admin?: true }) =>
    [
      { ...outreachQueryKeys.details()[0], id, ...(admin && { admin }) },
    ] as const,
  qAggregate: () =>
    [{ ...outreachQueryKeys.all[0], scope: 'q-aggregate' }] as const,
  goals: () => [{ ...outreachQueryKeys.all[0], scope: 'goals' }],
  counts: (filter: FiltersForm) =>
    [{ ...outreachQueryKeys.all[0], scope: 'counts', filter }] as const,
}

async function fetchCallLists({
  pageParam = 0,
  queryKey,
}: OutreachQueryContexts['list']) {
  const admin = queryKey[0].admin
  const pageSize = 10

  const response: FetchCallListsResponse = await client
    .post('functions/getCallLists', {
      json: {
        page: pageParam,
        pageSize,
        admin,
      },
    })
    .json()

  return response.result
}

async function fetchCallListDetail({
  queryKey,
}: OutreachQueryContexts['detail']) {
  const admin = queryKey[0].admin

  const response: FetchCallListDetailResponse = await client
    .post('functions/getCallList', {
      json: { objectId: queryKey[0].id, admin },
    })
    .json()

  return response.result
}

async function fetchOutreachClientCounts(filter: FiltersForm) {
  const filters = composeSearchFilters(filter)
  const response = await client
    .post('functions/clientsCount', {
      json: {
        filter: { ...filters, admin: true },
      },
    })
    .json()

  const parsedResponse = makeServerResponseSchema(
    outreachClientCountsSchema
  ).safeParse(response)
  if (!parsedResponse.success)
    throw new Error(fromZodError(parsedResponse.error).message)

  return parsedResponse.data.result
}

function useOutreachClientCounts(filters: FiltersForm) {
  const setToast = useToast()

  return useQuery({
    queryKey: ['test', { filters }],
    queryFn: () => fetchOutreachClientCounts(filters),
    onError: (error) => {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown Error.'
      setToast({ message: errorMessage, color: 'danger' })
    },
  })
}

async function fetchSimpleCallLists() {
  const response: {
    result: Array<Pick<CallList, 'objectId' | 'name'>>
  } = await client
    .post('functions/getCallListsSimple', { json: { admin: true } })
    .json()

  return response.result
}

async function fetchDynamicCallLists() {
  const response: FetchDynamicCallListsResponse = await client
    .post('functions/getCallListsDynamic')
    .json()

  return response.result.data
}

async function fetchDynamicCallListDetail({
  queryKey,
}: OutreachQueryContexts['detail']) {
  const response: FetchDynamicCallListDetailResponse = await client
    .post('functions/getCallListDynamic', {
      json: { objectId: queryKey[0].id },
    })
    .json()

  return response.result
}

async function fetchClientsRecentSales() {
  const data: {
    result: { clientsRecentSales: Array<RecentSalesClient> }
  } = await client.post('functions/getClientsRecentSales').json()

  return {
    name: 'Recent Sales',
    description:
      'Clients assigned to you that have purchased in the last 7 days (from you or from someone else).',
    clients: data.result.clientsRecentSales,
  }
}

async function fetchClientsFollowUps() {
  const data: {
    result: { clientsNextContact: Array<Client> }
  } = await client.post('functions/getClientsNextContact').json()

  return {
    name: 'Follow Ups',
    description:
      'Clients with a follow up date for today or prior who have not been reached out to yet. There is no time better than now!',
    clients: data.result.clientsNextContact,
  }
}

async function fetchQLists() {
  const [aggregate, withAppt, withoutAppt] = await Promise.allSettled([
    client.post('functions/getCLientsQLists').json<QListsResponse>(),
    client
      .post('functions/getClientsRecentSalesAppointmentCompleted')
      .json<{ result: { clients: Array<Client> } }>(),
    client
      .post('functions/getClientsRecentSalesDoneNoAppointment')
      .json<{ result: { clients: Array<Client> } }>(),
  ])

  // TODO: Clean up and handle possible errors better?
  return {
    ...(isFulfilled(aggregate)
      ? aggregate.value.result
      : {
          clientsWithNoMessage: [],
          clientsWithOneMessage: [],
          clientsWithTwoMessages: [],
          clientsWithThreeMessages: [],
        }),
    ...(isFulfilled(withAppt)
      ? { clientsWithAppt: withAppt.value.result.clients }
      : { clientsWithAppt: [] }),
    ...(isFulfilled(withoutAppt)
      ? {
          clientsWithoutAppt: withoutAppt.value.result.clients,
        }
      : { clientsWithoutAppt: [] }),
  }
}

async function fetchGoals() {
  const response: FetchGoalsResponse = await client
    .post('functions/getMessagesGoal', { json: { groupsGoal: true } })
    .json()

  return response.result
}

function composeDynamicCallListName(
  dynamicCallList: DynamicCallList | DynamicCallListDetail
) {
  if (dynamicCallList.trigger === 'BIRTHDAY') {
    const month = format(
      addDays(Date.now(), dynamicCallList.filter.daysBefore),
      'MMMM'
    )

    return `${month} ${dynamicCallList.name}`
  }

  return dynamicCallList.name
}

function qListSortGenerator(
  prop: keyof Pick<Client, 'updatedAt' | 'lastContact'>,
  direction: 'asc' | 'desc'
) {
  return function qListSorter(a: Client, b: Client) {
    // drop undefineds to the top or bottom
    const sortWeight = direction === 'desc' ? 1 : -1
    if (!hasDefinedProp(a, prop)) return -sortWeight
    if (!hasDefinedProp(b, prop)) return sortWeight

    return direction === 'desc'
      ? b[prop].iso.localeCompare(a[prop].iso)
      : a[prop].iso.localeCompare(b[prop].iso)
  }
}

function isFulfilled(
  result: PromiseFulfilledResult<unknown> | PromiseRejectedResult
): result is PromiseFulfilledResult<unknown> {
  return result.status === 'fulfilled'
}

function useCallLists() {
  const setToast = useToast()

  return useInfiniteQuery({
    queryKey: outreachQueryKeys.list({ type: 'One Time', admin: true }),
    queryFn: fetchCallLists,
    getNextPageParam: (lastPage) =>
      lastPage.hasNextPage ? lastPage.page + 1 : undefined,

    onError: (error) => {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown error.'

      setToast({
        message: `Something went wrong loading the outreach: ${errorMessage}`,
        color: 'danger',
      })
    },
  })
}

function useCallListDetail(id: string) {
  const query = useQuery({
    queryKey: outreachQueryKeys.detail({ id, admin: true }),
    queryFn: async () => {
      const response: FetchAdminCallList = await client
        .post('functions/getCallListSimple', {
          json: { objectId: id, admin: true },
        })
        .json()

      return response.result
    },
    select: (data) => ({
      ...data,
      callList: {
        ...data.callList,
        groups: data.callList.groups
          .map((group) => group.name ?? '')
          .sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' }))
          .join(', '),
      },
      totalStats: {
        clientCount: data.userStats.reduce((a, c) => c.clientCount + a, 0),
        contactedClientsCount: data.userStats.reduce(
          (a, c) => c.contactedClientsCount + a,
          0
        ),
      },
    }),
  })

  return {
    ...query,
    callList: query.data?.callList,
    totalStats: query.data?.totalStats,
    userStats: query.data?.userStats,
  }
}

function useCallListsSelect() {
  return useQuery({
    queryKey: outreachQueryKeys.list({ type: 'One Time', admin: true }),
    queryFn: fetchSimpleCallLists,
    select: (data) => data.map(({ objectId, name }) => ({ objectId, name })),
  })
}

function useMyCallLists() {
  return useQuery({
    queryKey: outreachQueryKeys.list({ type: 'One Time' }),
    queryFn: fetchCallLists,
    select: (data) => data.data,
  })
}

function useMyCallListDetail(id: string) {
  return useQuery({
    queryKey: outreachQueryKeys.detail({ id }),
    queryFn: fetchCallListDetail,
    select: (data) =>
      data.startDate && data.endDate
        ? {
            ...data,
            startDate: data.startDate,
            endDate: data.endDate,
            callListType: 'onetime' as const,
          }
        : {
            ...data,
            startDate: null,
            endDate: null,
            callListType: 'ongoing' as const,
          },
  })
}

function useDynamicCallLists(limit: number = 25) {
  return useQuery({
    queryKey: outreachQueryKeys.list({ type: 'Dynamic', admin: true }),
    queryFn: fetchDynamicCallLists,
    select: (data) =>
      data.map((dynamicCallList) => ({
        ...dynamicCallList,
        displayName: composeDynamicCallListName(dynamicCallList),
        clients: sortFields(
          dynamicCallList.clients.slice(0, limit),
          'lastSale',
          {
            direction: 'desc',
            includeNull: true,
          }
        ),
      })),
  })
}

function useDynamicCallListDetail(id: string) {
  return useQuery({
    queryKey: outreachQueryKeys.detail({ id, admin: true }),
    queryFn: fetchDynamicCallListDetail,
    select: (data) => ({
      ...data,
      displayName: composeDynamicCallListName(data),
    }),
  })
}

function useMyDynamicCallLists(limit: number = 25) {
  return useQuery({
    queryKey: outreachQueryKeys.list({ type: 'Dynamic' }),
    queryFn: fetchDynamicCallLists,
    select: (data) =>
      data.map((dynamicCallList) => ({
        ...dynamicCallList,
        displayName: composeDynamicCallListName(dynamicCallList),
        clients: sortFields(
          dynamicCallList.clients
            .filter((client) => !client.callListDynamicContacted)
            .slice(0, limit),
          'lastSale',
          {
            direction: 'desc',
            includeNull: true,
          }
        ),
      })),
  })
}

function useMyDynamicCallListDetail(id: string) {
  return useQuery({
    queryKey: outreachQueryKeys.detail({ id }),
    queryFn: fetchDynamicCallListDetail,
    select: (data) => ({
      ...data,
      displayName: composeDynamicCallListName(data),
    }),
  })
}

function useRecentSalesList(limit?: number) {
  return useQuery({
    queryKey: outreachQueryKeys.detail({ id: 'recent-sales' }),
    queryFn: fetchClientsRecentSales,
    select: (data) => {
      return {
        ...data,
        clients: [...data.clients]
          .map((client) => {
            return {
              ...client,
              saleThumb: client.recentSales[0]?.posPhotoUrls[0],
              saleTotal: client.recentSales.reduce(
                (acc, curr) => acc + (curr.finalValue ?? 0),
                0
              ),
            }
          })
          .sort((a, b) => {
            if (!hasDefinedProp(a, 'lastSale')) return 1
            if (!hasDefinedProp(b, 'lastSale')) return -1

            return b.lastSale.iso.localeCompare(a.lastSale.iso)
          })
          .slice(0, limit),
      }
    },
  })
}

function useFollowUpsList() {
  return useQuery({
    queryKey: outreachQueryKeys.detail({ id: 'follow-ups' }),
    queryFn: fetchClientsFollowUps,
  })
}

function useAggregatedQFollowUps<T = QLists>(select?: (data: QLists) => T) {
  return useQuery({
    queryKey: outreachQueryKeys.qAggregate(),
    queryFn: fetchQLists,
    select,
  })
}

function useQListsReEngage() {
  // sort by oldest first (ascending lastContact iso)
  return useAggregatedQFollowUps((data) =>
    [...data.clientsWithNoMessage].sort(
      qListSortGenerator('lastContact', 'asc')
    )
  )
}

function useQLists2DayFollowUp() {
  // sort by oldest first (ascending lastContact iso)
  return useAggregatedQFollowUps((data) =>
    [...data.clientsWithOneMessage].sort(
      qListSortGenerator('lastContact', 'asc')
    )
  )
}

function useQLists4DayFollowUp() {
  // sort by oldest first (ascending lastContact iso)
  return useAggregatedQFollowUps((data) =>
    [...data.clientsWithTwoMessages].sort(
      qListSortGenerator('lastContact', 'asc')
    )
  )
}

function useQLists7DayFollowUp() {
  return useAggregatedQFollowUps((data) =>
    // sort by oldest first (ascending lastContact iso)
    [...data.clientsWithThreeMessages].sort(
      qListSortGenerator('lastContact', 'asc')
    )
  )
}

// Appointment Follow Up
function useQListsRecentSalesWithAppointment() {
  // sort by newest first (descending lastContact iso)
  return useAggregatedQFollowUps((data) =>
    [...data.clientsWithAppt].sort(qListSortGenerator('updatedAt', 'desc'))
  )
}

// New Delivery
function useQListsRecentSalesWithoutAppointment() {
  // sort by newest first (descending lastContact iso)
  return useAggregatedQFollowUps((data) =>
    [...data.clientsWithoutAppt].sort(qListSortGenerator('updatedAt', 'desc'))
  )
}

function useGoals() {
  return useQuery({ queryKey: [{ entity: 'uno goals' }], queryFn: fetchGoals })
}

export {
  outreachQueryKeys,
  qListSortGenerator,
  useDynamicCallLists,
  useDynamicCallListDetail,
  useMyDynamicCallLists,
  useMyDynamicCallListDetail,
  useCallLists,
  useCallListsSelect,
  useCallListDetail,
  useMyCallLists,
  useMyCallListDetail,
  useRecentSalesList,
  useFollowUpsList,
  useAggregatedQFollowUps,
  useOutreachClientCounts,
  useQListsReEngage,
  useQLists2DayFollowUp,
  useQLists4DayFollowUp,
  useQLists7DayFollowUp,
  useQListsRecentSalesWithAppointment,
  useQListsRecentSalesWithoutAppointment,
  useGoals,
}
export type { CallListStat, DynamicCallList }

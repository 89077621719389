import { z } from 'zod'
import { isValidPhoneNumber } from 'libphonenumber-js'

type AddClientSchema = z.infer<typeof addClientSchema>
type CheckInFormInputs = z.infer<typeof checkInSchema>

const addClientSchema = z.object({
  firstName: z.string().min(1, { message: 'First Name is required.' }),
  lastName: z.string(),
  email: z
    .string()
    .email({ message: 'Please enter a valid email.' })
    .or(z.literal('')),
  phoneNumber: z.literal('').or(
    z
      .string()
      .refine((phone) => (phone?.length ? isValidPhoneNumber(phone) : false), {
        message: 'Please enter a valid phone number.',
      })
  ),
  address: z.object({
    street1: z.string(),
    street2: z.string(),
    city: z.string(),
    state: z.string(),
    zip: z.string(),
    country: z.string(),
  }),
  tagNames: z.array(z.string()),
  manualOptIn: z.boolean(),
  message: z.string(),
  birthday: z
    .object({
      month: z
        .string()
        .transform((data) => (data.trim() === '' ? null : parseFloat(data)))
        .nullable(),
      day: z
        .string()
        .transform((data) => (data.trim() === '' ? null : parseFloat(data)))
        .nullable(),
    })
    .superRefine((arg, ctx) => {
      if (Number.isNaN(arg.month))
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_type,
          message: 'The month must be a valid number between 1 and 12',
          expected: 'number',
          received: 'nan',
          path: ['month'],
        })
      if (arg.month && (+arg.month < 1 || +arg.month > 12)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'The month must be a number between 1 and 12',
          path: ['month'],
        })
      }
      if (Number.isNaN(arg.day))
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_type,
          message: 'The day must be a valid number between 1 and 31',
          expected: 'number',
          received: 'nan',
          path: ['day'],
        })
      if (arg.day && (+arg.day < 1 || +arg.day > 31)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'The day must be a number between 1 and 31',
          path: ['day'],
        })
      }
    }),
})

const checkInSchema = addClientSchema.pick({
  birthday: true,
  email: true,
  firstName: true,
  lastName: true,
  phoneNumber: true,
})

export type { AddClientSchema, CheckInFormInputs }
export { addClientSchema, checkInSchema }

export const secondary = 'secondary'
export const avtarImage = 'avtarImage'
export const padding = 'ion-padding'
export const touchBaseTtile = 'touchBaseTtile'
export const paraTitle = 'paraTitle'
export const btnDesign = 'btnDesign'
export const mailIcon = 'mailIcon'
export const touchBase = 'Touch Base'
export const addClient = 'ADD CLIENT'
export const WELCOMEABOARD = 'WELCOME ABOARD'
export const ionContent = 'ionContent'
export const COMPLETED = 'COMPLETED'
export const MONTHLYGOAL = 'MONTHLY GOAL'
export const BEGIN = 'BEGIN'
export const SEGMENT_TODO = 'today'
export const SEGMENT_COMPLETE = 'complete'

export const SPECIAORDER = 'SPECIAL ORDER'
export const addClientFromPhone = 'addClientFromPhone'
export const Template = 'Select template'
export const emailTemplate = 'Add New email template'
export const Todo = 'Todo'
export const icons = 'icons'
export const primary = 'primary'
export const todoBackground = 'todoBackground'
export const noData = 'No Data Available'
export const backHome = '/tabs/home'
export const backTasks = '/tasks'
export const WhatHappened = '/WhatHappened'
export const forwordWelcomeAboard = '/WelcomeAboard'
export const details = 'details'
export const lastContact = 'Last Contact'
export const notAvailable = 'Not Available'
export const notAvailableShort = '-'
export const Okay = 'Okay'
export const Dismiss = 'Dismiss'
export const defaultMessage = 'Default message'
export const TO = 'TO'
export const SUBJECT = 'SUBJECT'
export const BODY = 'BODY'
export const truevalue = 'true'
export const SEND = 'SEND'
export const FINISH = 'FINISH'
export const Notes = 'Notes'
export const notesContent = 'notesContent'
export const SALE = 'SALE'
export const notesContent1 = 'notesContent1'
export const EMAIL = 'EMAIL'
export const Subject = 'Subject'
export const subjectContent = 'subjectContent'
export const wait = 'Please wait...'
export const col50 = 'col col-50'
export const size12 = '12'
export const size4 = '4'
export const size3 = '3'
export const size7 = '7'
export const size5 = '5'
export const size6 = '6'
export const size8 = '8'
export const floating = 'floating'
export const SKU = 'SKU'
export const VENDOR = 'VENDOR'
export const dueDate = 'DUE DATE'
export const DESCRIPTION = 'DESCRIPTION'
export const Price = 'Price'
export const Description = 'Description'

// emailTemplates
export const emailTemplateValue = 'EmailTemplate'
export const firstName = '<First Name>'
export const lastName = '<Last Name>'
export const lastPurchase = '<Last Purchase>'
export const firstNameTempate = '<First Name>'
export const lastNameTempate = '<Last Name>'
export const lastPurchaseTempate = '<Last Purchase>'
export const authUser = 'authUser'
export const addTemplates = 'ADD EMAIL TEMPLATES'
export const itemValue = 'itemValue'
export const inputClass = 'inputClass'
export const TEMPLATENAME = 'TEMPLATE NAME'
export const cardClass = 'cardClass'
export const textValue = 'textValue'
export const buttonClass = 'buttonClass'
export const bodyButtonClass = 'bodyButtonClass'
export const FIRSTNAME = 'FIRST NAME'
export const LASTNAME = 'LAST NAME'
export const LASTPURCHASE = 'LAST PURCHASE'
export const SAVE = 'SAVE'
export const CANCEL = 'CANCEL'
export const DELETE = 'DELETE'
export const TITLE = 'TITLE'

// client
export const clientsTitle = 'clients'
export const FIRSTNAMEVALUE = 'FIRST NAME'
export const Client = 'Client'
export const ALL = 'ALL'
export const InnerCircle = 'Inner Circle'
export const LosingTouch = 'Losing Touch'
export const head = 'head'
export const CLIENTS = 'CLIENTS'
export const ADDCLIENTS = 'ADD TO ONESHOP'

// Filters
export const FILTER_OPTIONS = {
  ALL: 'ALL',
  MINE: 'MINE',
  TOP_CLIENTS: 'TOP CLIENTS',
  FAVORITES: 'FAVORITES',
  RECENT_SALES: 'RECENT SALES',
  NEXT_CONTACT: 'NEXT CONTACT',
  EXPLORE: 'EXPLORE',
  CLIENT_PAGE: 'CLIENT_PAGE',
  ALL_CLIENTS_PAGE: 'ALL_CLIENTS_PAGE',
}

export const HAVE_NOT_SHOPPED_OPTIONS = [
  {
    label: '7 Days',
    value: { days: 7 },
  },
  {
    label: '14 Days',
    value: { days: 14 },
  },
  {
    label: '30 Days',
    value: { days: 30 },
  },
  {
    label: '60 Days',
    value: { days: 60 },
  },
  {
    label: '90 Days',
    value: { days: 90 },
  },
  {
    label: '6 Months',
    value: { months: 6 },
  },
  {
    label: '1 Year',
    value: { years: 1 },
  },
]

export const CLIENT_DISPLAY_OPTIONS = {
  SELECT_ALL: 'ALL',
  SELECT_MINE: 'MINE',
}

// Sort Options
export const SORT_OPTIONS = {
  AZ: 'A-Z',
  TOTAL_SALES: 'TOTAL SALES',
  FILTERED_SALES: 'FILTERED SALES',
  LAST_CONTACT: 'LAST CONTACT',
  LAST_SHOPPED: 'LAST SHOPPED',
}

// clientDetails
export const Note = 'Note'
export const Message = 'Message'
export const Sale = 'Sale'
export const CLIENT = 'CLIENT'
export const goBackClient = '/tabs/clients'

// Tasks
export const TASKS = 'TASKS'
export const ALTERATIONS = 'ALTERATIONS'
export const SEPECIALORDERS = 'SPECIAL ORDERS'
export const MTM = 'MTM'
export const CONSIGNMENT = 'CONSIGNMENT'

// What happened
export const noAnswer = 'no answer'
export const voiceMail = 'voicemail'
export const connected = 'connected'

// 'ADD SPECIAL ORDER'
export const specialOrder = 'ADD SPECIAL ORDER'
export const SearchClient = 'Search Client'
export const SearchResults = 'Search Results'
export const vendorList = 'vendorList'
export const DueDate = 'Due Date'
export const SpecialOrderNote = 'Special order note here...'
export const vendor = 'Vendor'

// Feed
export const result = 'RESULT'
export const NOTE = 'NOTE'

export const associateAddedImage = 'stylist added image'

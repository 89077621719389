import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useToast } from '../../contexts/toastContext'
import { client } from '../../services/client'
import { userRoleSocialKeys } from './queries'

type UpdateUserRoleSocialInput = {
  objectId: string
  role: string
  requiresApproval: boolean
}

async function updateUserRoleSocial(variables: UpdateUserRoleSocialInput) {
  const response: { result: { user: { objectId: string } } } = await client
    .post('functions/updateUserRoleSocial', { json: variables })
    .json()

  return response.result
}

function useUpdateUserRoleSocial() {
  const queryClient = useQueryClient()
  const setToast = useToast()

  return useMutation(updateUserRoleSocial, {
    onSettled: () => {
      queryClient.invalidateQueries(userRoleSocialKeys.all)
    },
    onError: (error) => {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown error.'

      setToast({
        message: `Oops, something went wrong when updating the role: ${errorMessage}`,
        color: 'danger',
        duration: 10000,
      })
    },
  })
}

export { useUpdateUserRoleSocial }

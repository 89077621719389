import Avatar from '../avatar'
import { ProfileBase } from '../../types/general'

import type { ReactNode } from 'react'

type Props = {
  user: ProfileBase | null
  action?: ReactNode
}

function AvatarHeader({ user, action }: Props) {
  return (
    <header className="flex items-center justify-between px-3 py-2 sm:col-span-1 sm:row-span-1">
      <div className="flex items-center space-x-2">
        <Avatar user={user} />
        <div className="flex flex-1 flex-col items-center sm:items-start">
          {user ? (
            <div className="font-semibold">{user.displayName}</div>
          ) : null}
        </div>
      </div>

      {action ? <div>{action}</div> : null}
    </header>
  )
}

export { AvatarHeader }

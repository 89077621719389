import * as React from 'react'
import { IonButton } from '@ionic/react'
import { Transition, Dialog } from '@headlessui/react'
import { Stack, TextField } from '@mui/material'
import * as R from 'remeda'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

import type { MRT_ColumnDef } from 'material-react-table'
import type { ClientImport } from './columns'

type Props = {
  columns: Array<MRT_ColumnDef<ClientImport>>
  onClose: () => void
  onSubmit: (values: ClientImport) => void
  open: boolean
}

function AddClientModal({ open, columns, onClose, onSubmit }: Props) {
  const [values, setValues] = React.useState<ClientImport>(() =>
    R.mapToObj(columns, (x) => [x.accessorKey ?? '', ''])
  )

  const handleSubmit = () => {
    onSubmit(values)
    onClose()
  }

  return (
    <Transition appear show={open} as={React.Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <form onSubmit={(e) => e.preventDefault()}>
                  <Stack
                    sx={{
                      width: '100%',
                      minWidth: { xs: '300px', sm: '360px', md: '400px' },
                      gap: '1.5rem',
                    }}
                  >
                    {columns.map((column) => {
                      if (column.id === 'Phone') {
                        return (
                          <div
                            key={column.id}
                            className="form-input relative rounded border-slate-400"
                          >
                            <span className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">
                              Phone
                            </span>
                            <PhoneInput
                              onChange={(value) => setValues({ ...values })}
                              placeholder="Phone Number"
                              defaultCountry="US"
                            />
                          </div>
                        )
                      }

                      return !column.enableEditing ? null : (
                        <TextField
                          key={column.id}
                          label={column.header}
                          name={column.accessorKey}
                          onChange={(e) =>
                            setValues({
                              ...values,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      )
                    })}
                  </Stack>
                  <div className="flex gap-3 py-3">
                    <IonButton fill="outline" onClick={onClose}>
                      Cancel
                    </IonButton>
                    <IonButton color="yellow" onClick={handleSubmit}>
                      Add Client
                    </IonButton>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export { AddClientModal }

export const classConstants = {
  CLASSNAME_ALTERATION: 'Alteration',
  CLASSNAME_APPOINTMENT: 'Appointment',
  CLASSNAME_CLIENT: 'Client',
  CLASSNAME_COMMENT: 'Comment',
  CLASSNAME_CONSIGNMENT: 'Consignment',
  CLASSNAME_EMAIL_TEMPLATE: 'EmailTemplate',
  CLASSNAME_FOLLOW_UP: 'FollowUp',
  CLASSNAME_GROUP: 'Group',
  CLASSNAME_GROUP_MEMBER: 'GroupMember',
  CLASSNAME_MTM: 'MTM',
  CLASSNAME_MESSAGE: 'Message',
  CLASSNAME_NOTE: 'Note',
  CLASSNAME_SALE: 'Sale',
  CLASSNAME_SHIPMENT: 'Shipment',
  CLASSNAME_SPECIAL_ORDER: 'SpecialOrder',
  CLASSNAME_TODO: 'Todo',
}

export const getClassDisplayString = (className) => {
  switch (className) {
    case classConstants.CLASSNAME_SPECIAL_ORDER:
      return 'Special Order'
    case classConstants.CLASSNAME_FOLLOW_UP:
      return 'Task'
    default:
      return className
  }
}

export const isTaskType = [
  classConstants.CLASSNAME_ALTERATION,
  classConstants.CLASSNAME_CONSIGNMENT,
  classConstants.CLASSNAME_FOLLOW_UP,
  classConstants.CLASSNAME_MTM,
  classConstants.CLASSNAME_SHIPMENT,
  classConstants.CLASSNAME_SPECIAL_ORDER,
]

export const hasTitleArray = [
  classConstants.CLASSNAME_FOLLOW_UP,
  classConstants.CLASSNAME_APPOINTMENT,
]

export const hasVendorArray = [
  classConstants.CLASSNAME_MTM,
  classConstants.CLASSNAME_SPECIAL_ORDER,
]

export const hasTrackingNumberArray = [classConstants.CLASSNAME_SHIPMENT]

export const hasDueDateArray = [
  classConstants.CLASSNAME_ALTERATION,
  classConstants.CLASSNAME_APPOINTMENT,
  classConstants.CLASSNAME_CONSIGNMENT,
  classConstants.CLASSNAME_MTM,
  classConstants.CLASSNAME_FOLLOW_UP,
  classConstants.CLASSNAME_SHIPMENT,
  classConstants.CLASSNAME_SPECIAL_ORDER,
]

export const hasNoteArray = [
  classConstants.CLASSNAME_ALTERATION,
  classConstants.CLASSNAME_APPOINTMENT,
  classConstants.CLASSNAME_CONSIGNMENT,
  classConstants.CLASSNAME_MTM,
  classConstants.CLASSNAME_NOTE,
  classConstants.CLASSNAME_FOLLOW_UP,
  classConstants.CLASSNAME_SHIPMENT,
  classConstants.CLASSNAME_SPECIAL_ORDER,
]

export const hasClientArray = [
  classConstants.CLASSNAME_ALTERATION,
  classConstants.CLASSNAME_APPOINTMENT,
  classConstants.CLASSNAME_CONSIGNMENT,
  classConstants.CLASSNAME_MTM,
  classConstants.CLASSNAME_NOTE,
  classConstants.CLASSNAME_FOLLOW_UP,
  classConstants.CLASSNAME_SHIPMENT,
  classConstants.CLASSNAME_SPECIAL_ORDER,
]

export const hasPhotos = [
  classConstants.CLASSNAME_ALTERATION,
  classConstants.CLASSNAME_APPOINTMENT,
  classConstants.CLASSNAME_CONSIGNMENT,
  classConstants.CLASSNAME_MTM,
  classConstants.CLASSNAME_NOTE,
  classConstants.CLASSNAME_FOLLOW_UP,
  classConstants.CLASSNAME_SPECIAL_ORDER,
]

import * as React from 'react'
import {
  IonPage,
  IonHeader,
  IonContent,
  IonLoading,
  useIonRouter,
  IonButton,
  IonCol,
  IonRow,
} from '@ionic/react'
import { useParams } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'

import { getFullName } from '../../utils/format-helpers'
import { useUser } from '../../contexts/authContext'
import {
  useCreateTemplate,
  useDeleteTemplate,
  useTemplateDeleteWarning,
  useUpdateTemplate,
} from '../../hooks/templates/mutations'
import { useTemplateDetail } from '../../hooks/templates/queries'
import { useReplacePlaceholders } from '../../hooks/use-replace-placeholders'
import Header from '../../components/header'
import Avatar from '../../components/avatar'
import CloneTemplateForm from '../../components/templates/clone-template-form'
import TemplateForm from '../../components/templates/template-form'
import { StickyHeader } from '../../components/ui/sticky-header'
import type { CreateTemplateInput } from '../../hooks/templates/mutations'

const defaultFormValues: CreateTemplateInput = {
  name: '',
  description: '',
  smsTemplate: { body: '', attachments: null },
  emailTemplate: { subject: '', body: '', attachments: null },
  letterTemplate: { body: '' },
  type: 'general',
  groupIds: [],
  shopWithIds: {},
}

function TemplateDetail() {
  const router = useIonRouter()
  const { tid } = useParams<{ tid: string }>()

  const user = useUser()

  const { replacePlaceholders } = useReplacePlaceholders()

  const templateQuery = useTemplateDetail(tid)

  const updateTemplate = useUpdateTemplate()
  const cloneTemplate = useCreateTemplate()
  const deleteTemplate = useDeleteTemplate()
  const { showDeleteWarning } = useTemplateDeleteWarning()

  const { reset, ...formMethods } = useForm<CreateTemplateInput>({
    defaultValues: defaultFormValues,
  })
  const { dirtyFields } = formMethods.formState
  const isDirty = Boolean(Object.keys(dirtyFields).length)

  const hasEditPrivileges =
    templateQuery.data?.user?.objectId === user.objectId &&
    !templateQuery.data?.groups

  React.useEffect(() => {
    if (templateQuery.data) {
      // conditionally set shopwith fields
      const shopWithIds = {
        ...(templateQuery.data.products && {
          productIds: [...templateQuery.data.products.map((p) => p.objectId)],
        }),
        ...(templateQuery.data.looks && {
          lookIds: [...templateQuery.data.looks.map((l) => l.objectId)],
        }),
        ...(templateQuery.data.collections && {
          collectionIds: [
            ...templateQuery.data.collections.map((c) => c.objectId),
          ],
        }),
      }

      // TODO: forcing string for attachments since that is what comes in for a detail and currently this is all typed as a "CREATE" - def wanna clean up
      reset({
        name: templateQuery.data.name,
        description: templateQuery.data.description,
        type: 'general',
        smsTemplate: {
          body: replacePlaceholders(templateQuery.data.smsTemplate?.body ?? ''),
          attachments: templateQuery.data.smsTemplate
            ?.attachments as unknown as Array<string>,
        },
        emailTemplate: {
          subject: replacePlaceholders(
            templateQuery.data.emailTemplate?.subject ?? ''
          ),
          body: replacePlaceholders(
            templateQuery.data.emailTemplate?.body ?? ''
          ),
          attachments: templateQuery.data.emailTemplate
            ?.attachments as unknown as Array<string>,
        },
        letterTemplate: {
          body: replacePlaceholders(
            templateQuery.data.letterTemplate?.body ?? ''
          ),
        },
        shopWithIds,
      })
    }
  }, [replacePlaceholders, reset, templateQuery.data])

  function handleUpdateTemplate(data: CreateTemplateInput) {
    updateTemplate.mutate({ objectId: tid, template: data })
    router.canGoBack()
      ? router.goBack()
      : router.push('/templates', 'back', 'pop')
  }

  function handleCloneTemplate(data: CreateTemplateInput) {
    const name = dirtyFields.name ? data.name : data.name?.concat(' COPY') ?? ''

    cloneTemplate.mutate({ ...data, name })
    router.push(router.routeInfo.lastPathname ?? '/templates', 'back', 'pop')
  }

  function handleDeleteTemplate() {
    deleteTemplate.mutate({ objectId: tid })
    router.push(router.routeInfo.lastPathname ?? '/templates', 'back', 'pop')
  }

  return (
    <IonPage>
      <IonHeader>
        <Header backRef="/templates" title="Template" />
      </IonHeader>
      <IonContent>
        <section>
          {templateQuery.data ? (
            <>
              <StickyHeader>
                <div className="ion-padding">
                  <h1 className="text-center text-2xl">
                    {templateQuery.data.name}
                  </h1>
                  <div className="flex w-full items-center justify-between">
                    <div className="flex items-center">
                      <Avatar
                        user={
                          templateQuery.data.user ?? {
                            firstName: '',
                            lastName: '',
                            photo: null,
                          }
                        }
                      ></Avatar>
                      <div className="ion-margin-start">
                        <p className="text-sm">Created By</p>
                        <p className="font-semibold">
                          {getFullName(
                            templateQuery.data.user ?? {
                              firstName: '',
                              lastName: '',
                            }
                          )}
                        </p>
                      </div>
                    </div>
                  </div>{' '}
                  <IonRow className="ion-justify-content-center">
                    <IonCol size="auto">
                      <IonButton
                        disabled={!isDirty}
                        size="small"
                        color="yellow"
                        onClick={
                          hasEditPrivileges
                            ? formMethods.handleSubmit(handleUpdateTemplate)
                            : formMethods.handleSubmit(handleCloneTemplate)
                        }
                      >
                        {hasEditPrivileges ? 'Save' : 'Save My Version'}
                      </IonButton>
                    </IonCol>
                    {hasEditPrivileges ? (
                      <IonCol size="auto">
                        <IonButton
                          size="small"
                          color="danger"
                          fill="outline"
                          onClick={() => {
                            showDeleteWarning(
                              templateQuery.data?.name ?? 'this template',
                              handleDeleteTemplate
                            )
                          }}
                        >
                          Delete
                        </IonButton>
                      </IonCol>
                    ) : null}
                  </IonRow>
                </div>
              </StickyHeader>

              <div className="ion-padding-horizontal ion-padding-bottom">
                <FormProvider reset={reset} {...formMethods}>
                  {hasEditPrivileges ? <TemplateForm /> : <CloneTemplateForm />}
                </FormProvider>
              </div>
            </>
          ) : (
            <IonLoading isOpen message={'Loading your template...'} />
          )}
        </section>
      </IonContent>
    </IonPage>
  )
}

export default TemplateDetail

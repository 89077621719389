import { useIonModal } from '@ionic/react'

import { useToast } from '../../contexts/toastContext'
import { ScheduleModal } from './components/schedule-modal'

import type { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces'

function useScheduleApproval(onSchedule: (date: string) => void) {
  const [presentModal, dismissModal] = useIonModal(ScheduleModal, {
    onDismiss: (data: string, role: string) => dismissModal(data, role),
  })
  const setToast = useToast()

  function onDidDismiss(ev: CustomEvent<OverlayEventDetail<string>>) {
    if (ev.detail.role === 'confirm') {
      if (!ev.detail.data)
        return setToast({
          message:
            'A valid date was not chosen, please try schduling again. If the problem persists, contact support.',
          color: 'danger',
        })
      onSchedule(ev.detail.data)
    }
  }

  return {
    presentModal: () => presentModal({ onDidDismiss }),
  }
}

export { useScheduleApproval }

import * as React from 'react'

import usePosGenders from '../../../hooks/queries/use-pos-genders'
import SearchableSelect from '../../ui/form/searchable-select'

import type { SelectProps } from './types'

function GenderSelect({
  isAdmin = false,
  selectedItems,
  setSelectedItems,
}: SelectProps) {
  const [isOpen, setIsOpen] = React.useState(false)

  const gendersQuery = usePosGenders({ admin: isAdmin, enabled: isOpen })

  return (
    <SearchableSelect
      onOpen={() => setIsOpen(true)}
      label="Genders"
      items={gendersQuery.data ?? []}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
    />
  )
}

export default GenderSelect

import { useMutation, useQueryClient } from '@tanstack/react-query'
import produce from 'immer'
import { useIonRouter } from '@ionic/react'

import { client } from '../../../services/client'
import { useToast } from '../../../contexts/toastContext'
import { useMyProfile } from '../../account/queries'
import { profileLookQueryKeys } from './queries'

import type { InfiniteData } from '@tanstack/react-query'
import type { PaginatedResponse } from '../../../types/queries'
import type { ProfileLook } from './queries'

type DeleteProfileLookInput = {
  profileLookId: string
}

type ProfileLooksCache = InfiniteData<PaginatedResponse<ProfileLook>['result']>

async function deleteProfileLook(variables: { profileLookId: string }) {
  const { profileLookId } = variables

  return client.post('functions/deleteProfileLook', {
    json: {
      objectId: profileLookId,
    },
  })
}

function useDeleteProfileLook() {
  const queryClient = useQueryClient()
  const profileQuery = useMyProfile()
  const profileId = profileQuery.data?.profileId ?? ''

  const setToast = useToast()
  const router = useIonRouter()

  return useMutation(deleteProfileLook, {
    onMutate: async (variables: DeleteProfileLookInput) => {
      await queryClient.cancelQueries(profileLookQueryKeys.all)

      const previousProfileLooks = queryClient.getQueryData<ProfileLooksCache>(
        profileLookQueryKeys.list(profileId)
      )

      if (previousProfileLooks) {
        queryClient.setQueryData<ProfileLooksCache>(
          profileLookQueryKeys.list(profileId),
          (old) => {
            if (old) {
              return produce(old, (draft) =>
                draft.pages.forEach((page) => {
                  page.data = page.data.filter(
                    (profileLook) =>
                      profileLook.objectId !== variables.profileLookId
                  )
                })
              )
            }

            return previousProfileLooks
          }
        )
      }

      router.canGoBack()
        ? router.goBack()
        : router.push('/tabs/profile', 'back', 'pop')

      return { previousProfileLooks }
    },
    onSuccess: () => {
      setToast({
        message: 'The post was successfully deleted.',
        color: 'yellow',
        duration: 10000,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries(profileLookQueryKeys.lists())
    },
    onError: (error, _variables, context) => {
      if (context?.previousProfileLooks) {
        queryClient.setQueryData<ProfileLooksCache>(
          profileLookQueryKeys.list(profileId),
          context.previousProfileLooks
        )
      }

      const errorMessage =
        error instanceof Error ? error.message : 'Unknown error.'
      setToast({
        message: `Oops, something went wrong when deleting the post: ${errorMessage}`,
        color: 'danger',
        duration: 10000,
      })
    },
  })
}

export { useDeleteProfileLook }

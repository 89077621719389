import * as React from 'react'
import {
  IonButton,
  IonContent,
  IonHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLoading,
  IonPage,
  IonRouterLink,
  IonRow,
  useIonRouter,
} from '@ionic/react'
import { format } from 'date-fns'

import { useInfiniteData } from '../../../hooks/use-infinite-data'
import Header from '../../../components/header'
import { Chip } from '../../../components/ui/buttons/chip'
import ScrollingList from '../../../components/ui/scrolling-list'
import { StickyHeader } from '../../../components/ui/sticky-header'
import { OutreachItem } from '../../../engage/campaigns/outreach-item'
import { useCampaignsByCategory } from '../../../engage/campaigns/queries'
import { useCampaignShortcuts } from '../../../engage/campaigns/utils'
import { BroadcastItem } from '../../../marketing/broadcasts/components/broadcast-item'
import { useCreateAdminBroadcast } from '../../../marketing/broadcasts/mutations'
import { useAccount } from '../../../contexts/authContext'
import { Redirect } from 'react-router'
import { useIsStore } from '../../../auth/utils'

function AdminCampaignsPage() {
  const [canCreateBroadcast, setCanCreateBroadcast] = React.useState(true)

  const account = useAccount()
  const { isUno } = useIsStore()

  const router = useIonRouter()

  const campaigns = useCampaignsByCategory('admin')
  const { shortcuts, parsedShortcut } = useCampaignShortcuts()
  const flatCampaigns = campaigns.data?.pages.flatMap((x) => x.data) ?? []

  const createAdminBroadcast = useCreateAdminBroadcast()

  const [ref, loadMore] = useInfiniteData(campaigns.fetchNextPage)

  const loadingMessage = `Loading ${
    parsedShortcut === 'broadcasts'
      ? 'Broadcasts'
      : parsedShortcut === 'callLists'
      ? 'Outreach'
      : 'Campaigns'
  }...`

  function handleCreateBroadcast() {
    if (!account.account.settings.marketing) return setCanCreateBroadcast(false)

    createAdminBroadcast.mutate(
      {
        title: `Broadcast ${format(new Date(), 'MM/dd/yy')}`,
      },
      {
        onSuccess: (data) => {
          router.push(
            `/tabs/admin/broadcasts/detail/${data.objectId}`,
            'forward',
            'push'
          )
        },
      }
    )
  }

  if (!canCreateBroadcast) return <Redirect to="/access-denied" />

  return (
    <IonPage>
      <IonHeader>
        <Header title="Campaigns" btn={null} showMenu />
      </IonHeader>
      <IonContent>
        <StickyHeader>
          <IonRow className="ion-padding-vertical flex-col gap-y-6">
            <div className="ion-padding-horizontal">
              <ScrollingList>
                {shortcuts.map((shortcut) => (
                  <IonRouterLink
                    key={shortcut}
                    routerLink={`/admin/campaigns?type=${shortcut}`}
                    routerDirection="none"
                  >
                    <Chip
                      fill={shortcut !== parsedShortcut ? 'outline' : 'solid'}
                    >
                      <span className="capitalize">
                        {shortcut === 'callLists' ? 'Outreach' : shortcut}
                      </span>
                    </Chip>
                  </IonRouterLink>
                ))}
              </ScrollingList>
            </div>
            <IonRow className="ion-padding-horizontal gap-x-4">
              {!isUno() || account.isParentGroupAdmin ? (
                <IonButton
                  color="yellow"
                  size="small"
                  onClick={handleCreateBroadcast}
                >
                  Create Broadcast
                </IonButton>
              ) : null}
              <IonButton
                color="yellow"
                size="small"
                routerLink="/admin/outreach/create"
              >
                Create Outreach List
              </IonButton>
            </IonRow>
          </IonRow>
        </StickyHeader>
        {flatCampaigns?.length ? (
          <>
            <div>
              {flatCampaigns.map((d) => {
                if (d.className === 'Broadcast')
                  return (
                    <BroadcastItem
                      key={d.objectId}
                      broadcast={d}
                      routerLink={`/admin/campaigns/broadcast/${d.objectId}`}
                      showType
                    />
                  )
                else if (d.className === 'CallList')
                  return (
                    <OutreachItem
                      key={d.objectId}
                      callList={d}
                      routerLink={`/admin/outreach/detail/${d.objectId}`}
                    />
                  )
                return null
              })}
            </div>
            <IonInfiniteScroll
              ref={ref}
              onIonInfinite={loadMore}
              threshold="100px"
              disabled={!campaigns.hasNextPage}
            >
              <IonInfiniteScrollContent
                loadingSpinner="bubbles"
                loadingText="loading more campaigns..."
              />
            </IonInfiniteScroll>
          </>
        ) : null}
      </IonContent>
      <IonLoading isOpen={campaigns.isLoading} message={loadingMessage} />
    </IonPage>
  )
}

export { AdminCampaignsPage }

import { IonLabel, IonText, IonSkeletonText } from '@ionic/react'
import { useRouteMatch } from 'react-router-dom'

import { getFullName } from '../../utils/format-helpers'
import { ListItem, ListItemHeading, ListItemSubHeading } from '../ui/list-item'

import type { Template } from '../../hooks/templates/queries'

function TemplateItem({ template }: { template: Template }) {
  const { url } = useRouteMatch()

  return (
    <ListItem routerLink={`${url}/detail/${template.objectId}`}>
      <IonLabel>
        <ListItemHeading>{template.name}</ListItemHeading>
        {template.user ? (
          <ListItemSubHeading>
            Created by: {getFullName(template.user)}
          </ListItemSubHeading>
        ) : null}
        <ListItemSubHeading>
          <span className="capitalize">Type: {template.type ?? 'general'}</span>
        </ListItemSubHeading>
      </IonLabel>
    </ListItem>
  )
}

function TemplateItemSkeleton() {
  return (
    <ListItem>
      <IonLabel>
        <h3 className="w-2/3">
          <IonSkeletonText
            animated
            className="mt-0 w-full leading-normal"
          ></IonSkeletonText>
        </h3>

        <p className="w-2/5">
          <IonSkeletonText
            animated
            className="mt-0 w-full leading-normal"
          ></IonSkeletonText>
        </p>

        <p className="w-2/5">
          <IonSkeletonText
            animated
            className="mt-0 w-full leading-normal"
          ></IonSkeletonText>
        </p>
      </IonLabel>
    </ListItem>
  )
}

export { TemplateItem, TemplateItemSkeleton }

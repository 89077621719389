import { z } from 'zod'

import {
  isoDateSchema,
  nullableNumber,
  nullableString,
  serverItemSchema,
} from '../utils/schema'
import { profileBaseSchema } from '../schema/profile.schema'

import type { HeartlandLoyalty } from '../integrations/heartland/heartland.schema'
import type { LightspeedLoyalty } from '../integrations/lightspeed/lightspeed.schema'

type ManualLoyalty = z.infer<typeof manualLoyaltySchema>
type LoyaltyBonusRule = z.infer<typeof loyaltyBonusRuleSchema>
type LoyaltyData = HeartlandLoyalty | LightspeedLoyalty | ManualLoyalty
type ApplyLoyaltyReward = RewardSet
type LoyaltyRuleBase = z.infer<typeof loyaltyRuleBaseSchema>
type LoyaltyRuleDetail = z.infer<typeof loyaltyRuleDetailSchema>
type RewardSet = z.infer<typeof rewardSetSchema>
type RuleState = z.infer<typeof ruleStateSchema>
type CreateLoyaltyProgramParams = z.infer<typeof createLoyaltyRuleSchema> & {
  steps: Array<z.infer<typeof createLoyaltyStepSchema>>
}
type UpdateLoyaltyProgramParams = z.infer<typeof updateLoyaltyRuleSchema> & {
  steps?: Array<Partial<z.infer<typeof updateLoyaltyStepSchema>>>
}

type LoyaltyProgramFormState = z.infer<typeof loyaltyProgramFormSchema>

const ruleStates = ['active', 'draft', 'stopped'] as const
const ruleTypes = ['tier', 'linear'] as const

const ruleStateSchema = z.enum(ruleStates)
const ruleTypeSchema = z.enum(ruleTypes)

const optInStatusStrings = [
  'No Phone Number',
  'Not Opted In',
  'Pending',
  'Opted In',
  'Opted Out',
  'Unknown',
] as const
type OptInStatusString = typeof optInStatusStrings[number]
const optInStatusSchema = z.enum(optInStatusStrings)

const loyaltyRuleMetaSchema = z.object({
  visitsUntilReward: z.number(),
  pointsBalance: z.number(),
  rewardsAvailable: z.number(),
})

const loyaltyBonusRuleSchema = serverItemSchema
  .merge(
    z.object({
      endDate: nullableString,
      expirationPointDays: nullableString,
      isManual: z.boolean(),
      name: nullableString,
      startDate: nullableString,
      state: ruleStateSchema,
      text: nullableString,
      value: nullableNumber,
    })
  )
  .partial()

const receiptDetailSchema = serverItemSchema.extend({
  canEdit: z.boolean(),
  date: z.string(),
  posId: nullableString,
  value: nullableNumber,
})

const loyaltyPointDetail = serverItemSchema.extend({
  type: nullableString, // TODO
  value: nullableNumber,
})

const rewardBoxSchema = z
  .union([
    z.object({
      dateString: z.string(),
      receipts: z.array(receiptDetailSchema),
    }),

    z.object({ dateString: z.string(), loyaltyPoint: loyaltyPointDetail }),
  ])
  .transform((data) => {
    if ('receipts' in data) return { ...data, kind: 'receipt' as const }
    return { ...data, kind: 'manual' as const }
  })

const loyaltyAdjustmentSchema = z.object({
  objectId: nullableString,
  value: nullableNumber,
  date: nullableString,
  user: profileBaseSchema,
})

const rewardSetSchema = loyaltyRuleMetaSchema.extend({
  loyaltyAdjustments: z.array(loyaltyAdjustmentSchema),
  loyaltyAdjustmentIds: z.array(z.string()),
  loyaltyBonusRuleId: nullableString,
  loyaltyPointIds: z.array(z.string()),
  loyaltyPoints: z.array(loyaltyPointDetail),
  loyaltyReward: loyaltyBonusRuleSchema.nullable(), //TODO: revisit since this is only part of bonus reward
  loyaltyRuleId: nullableString,
  receiptIds: z.array(z.string()),
  receipts: z.array(receiptDetailSchema),
  rewardBoxes: z.array(rewardBoxSchema),
  text: z.string(),
})

const loyaltySettingsSchema = z.object({
  visits: z.coerce.number(),
})

const loyaltyStepSchema = serverItemSchema.extend({
  settings: z.object({
    percent: z.coerce.number().refine((val) => val > 0, {
      message: 'Percent must be a positive number.',
    }),
    points: z.coerce.number().refine((val) => val > 0, {
      message: 'Points must be a positive number.',
    }),
  }),
})

const loyaltyRuleBaseSchema = serverItemSchema.extend({
  createdAt: isoDateSchema,
  state: ruleStateSchema,
  type: ruleTypeSchema,
})

const loyaltyRuleDetailSchema = loyaltyRuleBaseSchema.merge(
  z.object({
    settings: loyaltySettingsSchema, // TODO: IS THIS .nullable() LIKE TRAVIS SAID IN HIS TYPES?,
    steps: z.array(loyaltyStepSchema),
  })
)

const loyaltyRuleSchema = loyaltyRuleMetaSchema
  .extend({
    loyaltyBonusRule: loyaltyBonusRuleSchema.nullable(),
    loyaltyRule: loyaltyRuleDetailSchema.omit({ steps: true }).nullable(), // TODO: MIGHT NEED TO DIFFERENTIATE BETWEEN LOYALTY RULE FOR REDEMTION AND RULE IN THE PROGRAM
    rewards: z.array(rewardSetSchema),
  })
  .transform((schema) => {
    const { loyaltyRule, loyaltyBonusRule, ...rest } = schema

    if (loyaltyBonusRule)
      return { ruleType: 'bonus' as const, loyaltyBonusRule, ...rest }

    if (loyaltyRule)
      return { ruleType: 'reward' as const, loyaltyRule, ...rest }

    throw new Error('NO')
  })

const clientLoyaltySchema = loyaltyRuleMetaSchema.extend({
  client: profileBaseSchema,
  loyaltyRules: z.array(loyaltyRuleSchema),
  loyaltyVisitsLifetime: z.number(),
})

const posLoyaltySchema = clientLoyaltySchema.extend({
  show: z.boolean(),
  rewardApplied: z.boolean(),
})

const manualLoyaltySchema = clientLoyaltySchema.transform((schema) => ({
  ...schema,
  loyaltyType: 'manual' as const,
}))

const createLoyaltyRuleSchema = loyaltyRuleDetailSchema
  .pick({
    type: true,
    settings: true,
    state: true,
  })
  .partial()
const updateLoyaltyRuleSchema = serverItemSchema.merge(createLoyaltyRuleSchema)

const createLoyaltyStepSchema = loyaltyStepSchema.omit({ objectId: true })
const updateLoyaltyStepSchema = z
  .object({
    loyaltyRuleId: z.string(),
  })
  .merge(loyaltyStepSchema)
// const createLoyaltyStepSchema = z
//   .object({
//     loyaltyRuleId: z.string(),
//   })
//   .merge(loyaltyStepSchema.omit({ objectId: true }))
// const updateLoyaltyStepSchema = serverItemSchema.merge(createLoyaltyStepSchema)

const loyaltyProgramFormSchema = z
  .object({
    programStyle: z.enum(['box', 'points']),
    type: z.enum(['linear', 'tier']),
    steps: z.array(
      loyaltyStepSchema
        .omit({ objectId: true })
        .merge(z.object({ objectId: z.string().optional() }))
    ),
    settings: loyaltySettingsSchema,
  })
  .superRefine((val, ctx) => {
    if (val.programStyle === 'box') {
      if (val.settings.visits <= 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            'Box program requires a number of visits that is greater than 0.',
          path: ['settings.visits'],
        })
      }
    }
    if (val.programStyle === 'points') {
      if (val.settings.visits !== 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            'The visits for points system must be 0. This means something went wrong on our end. Please report the bug to your oneshop rep.',
          path: ['settings.visits'],
        })
      }
    }
  })

export type {
  ApplyLoyaltyReward,
  CreateLoyaltyProgramParams,
  LoyaltyBonusRule,
  LoyaltyData,
  LoyaltyProgramFormState,
  LoyaltyRuleBase,
  LoyaltyRuleDetail,
  OptInStatusString,
  RewardSet,
  RuleState,
  UpdateLoyaltyProgramParams,
}
export {
  createLoyaltyRuleSchema,
  createLoyaltyStepSchema,
  updateLoyaltyRuleSchema,
  loyaltyBonusRuleSchema,
  loyaltyProgramFormSchema,
  loyaltyRuleBaseSchema,
  loyaltyRuleDetailSchema,
  loyaltySettingsSchema,
  loyaltyStepSchema,
  manualLoyaltySchema,
  optInStatusSchema,
  posLoyaltySchema,
  updateLoyaltyStepSchema,
}

import { z } from 'zod'

import {
  amountOfTimeSchema,
  nullableString,
  serverItemSchema,
} from '../../utils/schema'
import {
  baseClientDetailSchema,
  phoneNumberSchema,
} from '../../clients/clients.schema'

import { isValidPhoneNumber, type E164Number } from 'libphonenumber-js'

type UpdateClientInput = z.infer<typeof updateClientInputSchema>
type CreateClientResponse = z.infer<typeof createClientOptInResponseSchema>

type CreateOptInParams = {
  e164: E164Number
}

const createClientOptInResponseSchema = z.object({
  result: z.object({
    client: serverItemSchema.extend({
      firstName: nullableString,
      lastName: nullableString,
      email: nullableString,
      phoneNumbers: z.array(phoneNumberSchema).nullable(),
      dobObject: z
        .object({
          day: z.number().optional().nullable(), // some errant bad 'null' data on server
          month: z.number().optional().nullable(), // some errant bad 'null' data on server
          year: z.number().optional().nullable(), // some errant bad 'null' data on server
        })
        .nullable(),
    }),
  }),
})

const updateClientResponseSchema = z.object({
  result: baseClientDetailSchema,
})

const updateClientInputSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email().or(z.literal('')),
  addE164: z
    .string()
    .refine((phone) => (phone?.length ? isValidPhoneNumber(phone) : false), {
      message: 'Please enter a valid phone number.',
    })
    .or(z.literal('')),
  birthday: z
    .object({
      month: z
        .string()
        .transform((data) => (data.trim() === '' ? null : parseFloat(data)))
        .or(z.number().nullable())
        .nullable(),
      day: z
        .string()
        .transform((data) => (data.trim() === '' ? null : parseFloat(data)))
        .or(z.number().nullable())
        .nullable(),
      year: z
        .string()
        .transform((data) => (data.trim() === '' ? null : parseFloat(data)))
        .or(z.number().nullable())
        .nullable(),
    })
    .partial()
    .superRefine((arg, ctx) => {
      if (Number.isNaN(arg.month))
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_type,
          message: 'The month must be a number between 1 and 12',
          expected: 'number',
          received: 'nan',
          path: ['month'],
        })
      if (arg.month && (+arg.month < 1 || +arg.month > 12)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'The month must be a number between 1 and 12',
          path: ['month'],
        })
      }
      if (Number.isNaN(arg.day))
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_type,
          message: 'The day must be a number between 1 and 31',
          expected: 'number',
          received: 'nan',
          path: ['day'],
        })
      if (arg.day && (+arg.day < 1 || +arg.day > 31)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'The day must be a number between 1 and 31',
          path: ['day'],
        })
      }
    }),
})

export type { UpdateClientInput, CreateClientResponse, CreateOptInParams }
export {
  createClientOptInResponseSchema,
  updateClientInputSchema,
  updateClientResponseSchema,
}

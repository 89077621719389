import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonIcon,
  IonMenuButton,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { chatbubbleEllipses } from 'ionicons/icons'
import { useUser } from '../../contexts/authContext'

import { useAdminInboxUnreadCount } from '../../hooks/chat/queries'
import Badge from '../Badge'

type ShowMenu = { showMenu?: boolean; backRef?: never }
type BackRef = { showMenu?: never; backRef?: string }
type MenuOrBack = ShowMenu | BackRef
type Props = {
  title?: string
  showInbox?: boolean
} & MenuOrBack

function AdminHeader({
  title = '',
  showInbox = false,
  showMenu = false,
  backRef = '/',
}: Props) {
  const user = useUser()

  const unreadCountQuery = useAdminInboxUnreadCount()

  return (
    <IonToolbar>
      <IonButtons slot="start">
        {showMenu ? (
          <IonMenuButton color="secondary" />
        ) : (
          <IonBackButton color="secondary" defaultHref={backRef} />
        )}
      </IonButtons>
      <IonTitle>
        <h2 className="text-xl capitalize">{title}</h2>
      </IonTitle>
      <IonButtons slot="end">
        {showInbox && user.hasTwilio ? (
          <IonButton
            style={{
              '--padding-start': 0,
              '--padding-end': 0,
              '--overflow': 'initial',
            }}
            className="relative"
            routerLink={`/admin/chat/inbox`}
            color="secondary"
          >
            <IonIcon slot="icon-only" icon={chatbubbleEllipses} />
            {unreadCountQuery.data?.unreadConversationCount ? (
              <Badge invert>
                {unreadCountQuery.data.unreadConversationCount}
              </Badge>
            ) : null}
          </IonButton>
        ) : null}
      </IonButtons>
    </IonToolbar>
  )
}

export default AdminHeader

import {
  IonButton,
  IonButtons,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react'

import { EditUserForm } from './edit-user-form'

import type { UserSetup } from '../user-setup.schema'

function EditUserModal({
  onClose,
  open,
  userData,
}: {
  onClose: () => void
  open: boolean
  userData: UserSetup | null
}) {
  return (
    <IonModal
      isOpen={open}
      onDidDismiss={onClose}
      style={{ '--height': 'fit-content' }}
    >
      <IonHeader>
        <IonToolbar color="secondary">
          {!userData ? (
            <IonButtons slot="start">
              <IonButton onClick={onClose}>Cancel</IonButton>
            </IonButtons>
          ) : null}
          <IonTitle>Edit User</IonTitle>
        </IonToolbar>
      </IonHeader>
      <div className="ion-padding bg-ion-color-secondary">
        {userData ? (
          <EditUserForm
            userData={userData}
            onSuccess={onClose}
            onCancel={onClose}
          />
        ) : null}
      </div>
    </IonModal>
  )
}

export { EditUserModal }

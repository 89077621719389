import { z } from 'zod'

import { formDataSchema } from '../actions'
import { useLogForm } from '../use-log-form'
import { LoyaltyInput } from './loyalty-input'
import { SaveFooter } from './save-footer'

function LogSixBoxForm({
  onCancel,
  onSubmit,
}: {
  onCancel: () => void
  onSubmit: () => void
}) {
  const { formMethods, submitForm } = useLogForm(
    formDataSchema.superRefine((data, ctx) => {
      if (data.posId && data.visits !== 1) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Visits must be 1 when a transaction id is present.',
          path: ['visits'],
        })
      }

      if (data.value && data.value < 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_small,
          minimum: 1,
          type: 'number',
          inclusive: false,
          message: 'The points cannot be negative.',
          path: ['value'],
        })
      }
    }),
    { onSuccess: onSubmit }
  )

  return (
    <>
      <form className="space-y-3 p-5">
        <LoyaltyInput
          control={formMethods.control}
          name="posId"
          label="Transaction #"
        />

        <LoyaltyInput
          control={formMethods.control}
          name="value"
          label="Points"
        />

        <LoyaltyInput
          control={formMethods.control}
          name="visits"
          label="Number Of Visits"
        />
        <p className="text-ion-color-secondary text-sm">
          *(must be 1 if transaction # present)
        </p>
      </form>
      <SaveFooter
        onCancel={onCancel}
        onSuccess={() => {
          formMethods.handleSubmit(submitForm)()
        }}
      />
    </>
  )
}

export { LogSixBoxForm }

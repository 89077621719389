import { useFormatNumber } from '../../hooks/use-format-number'
import { OnboardingStatus } from './components/onboarding-status'

import type { MRT_ColumnDef } from 'material-react-table'
import type { UserSetup } from './user-setup.schema'

const columns: Array<MRT_ColumnDef<UserSetup>> = [
  // {
  //   id: 'photo',
  //   header: '',
  //   columnDefType: 'display',
  //   Cell: ({ row }) => <Avatar user={row.original} />,
  //   muiTableBodyCellProps: {
  //     className: 'w-[80px] max-w-[80px] min-w-[80px]',
  //   },
  //   muiTableHeadCellProps: {
  //     className: 'w-[80px] max-w-[80px] min-w-[80px]',
  //   },
  // },

  { accessorKey: 'user.firstName', header: 'First Name', enableEditing: false },
  { accessorKey: 'user.lastName', header: 'Last Name', enableEditing: false },
  {
    accessorKey: 'user.displayName',
    header: 'Display Name',
    enableEditing: false,
  },
  { accessorKey: 'user.email', header: 'Login Email', enableEditing: false },
  {
    accessorKey: 'user.twilioProxyEmail',
    header: 'Sending Email',
    enableEditing: false,
  },
  {
    accessorKey: 'user.goal',
    header: 'Goal/Day',
    muiTableBodyCellEditTextFieldProps: { type: 'number' },
  },
  {
    accessorKey: 'user.workDays',
    header: 'Work Days/Week',
    muiTableBodyCellEditTextFieldProps: { type: 'number' },
  },
  {
    accessorKey: 'clientCount',
    header: 'Clients',
    enableEditing: false,
    Cell: ({ row }) => {
      const formatNumber = useFormatNumber()
      return <p>{formatNumber(row.original.clientCount ?? 0)}</p>
    },
  },
  {
    accessorKey: 'onboardingStatus',
    header: 'Onboarding Status',
    Cell: ({ row }) => (
      <OnboardingStatus
        status={row.original.onboardingStatus}
        userId={row.original.user.objectId}
      />
    ),
  },
]

export { columns }

import { useQuery } from '@tanstack/react-query'
import { arrayToTree, TreeItem } from 'performant-array-to-tree'

import { client } from '../../services/client'
import { useToast } from '../../contexts/toastContext'
import type { Group } from '../../types/general'
import type { QueryContextFromKeys } from '../../utils/react-query'

type FetchGroupsResponse = {
  result: Array<Group>
}
type FetchPaginatedGroupsResponse = {
  result: {
    data: Array<Group>
    hasNextPage: boolean
    page: number
    pageSize: number
  }
}

type GroupQueryContexts = QueryContextFromKeys<typeof groupQueryKeys>

const groupQueryKeys = {
  all: [{ entity: 'groups' }] as const,
  lists: () => [{ ...groupQueryKeys.all[0], scope: 'list' }],
  list: ({ admin }: { admin: boolean }) =>
    [{ ...groupQueryKeys.lists()[0], admin }] as const,
  allLocations: () => [{ ...groupQueryKeys.lists()[0], level: 'Account' }],
}

async function fetchAccountGroups({
  pageSize = 20,
}: {
  pageSize: FetchPaginatedGroupsResponse['result']['pageSize']
}) {
  const data: FetchPaginatedGroupsResponse = await client
    .post('functions/getAccountGroups', { json: { pageSize } })
    .json()

  return data.result
}

async function fetchGroups({
  queryKey: [{ admin }],
}: GroupQueryContexts['list']) {
  const data: FetchGroupsResponse = await client
    .post('functions/groups', {
      json: { admin },
    })
    .json()

  return data.result
}

function transformToCheckboxTree(tree: Array<TreeItem>): Array<TreeItem> {
  return tree.map((node) => {
    if (!node.children || node.children.length === 0) {
      return {
        ...node,
        label: node.name,
        value: node.objectId,
        children: undefined,
      }
    }
    if (node.children.length > 0) {
      return {
        ...node,
        label: node.name,
        value: node.objectId,
        children: transformToCheckboxTree(node.children),
      }
    }

    return {
      ...node,
      label: node.name,
      value: node.objectId,
    }
  })
}

const selectGroupsToTree = (data: Array<Group>) => {
  const groupIds = data.map((group) => group.objectId)

  const groupsWithSimpleParent = data.map((group) => {
    let parent = group.parent
      ? groupIds.includes(group.parent.objectId)
        ? group.parent.objectId
        : null
      : null

    return {
      ...group,
      parent,
    }
  })

  const groupsTree = arrayToTree(groupsWithSimpleParent, {
    parentId: 'parent',
    id: 'objectId',
    dataField: null,
  })

  return transformToCheckboxTree(groupsTree)
}

function useFlatAccountGroups() {
  const setToast = useToast()

  return useQuery({
    queryKey: groupQueryKeys.allLocations(),
    queryFn: () => fetchAccountGroups({ pageSize: 1000 }),
    refetchOnWindowFocus: false,
    retry: false,
    select: (data) => data.data,
    onError(error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown error.'

      setToast({
        message: `Something went wrong while loading the locations: ${errorMessage}`,
        color: 'danger',
      })
    },
  })
}

function useAllGroups<T = Array<Group>>({
  onSuccess,
  select,
  isAdmin = false,
}: {
  onSuccess?: (data: T) => void
  select?: (data: Array<Group>) => T
  isAdmin?: boolean
} = {}) {
  const setToast = useToast()

  return useQuery({
    queryKey: groupQueryKeys.list({ admin: isAdmin }),
    queryFn: fetchGroups,
    refetchOnWindowFocus: false,
    retry: false,
    select,
    onSuccess,
    onError(error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown error.'

      setToast({
        message: `Something went wrong while loading the groups: ${errorMessage}`,
        color: 'danger',
      })
    },
  })
}

// currently admin only - implementation cannot set isAdmin false
function useGroupsTree({
  onSuccess,
}: {
  onSuccess?: (data: Array<TreeItem>) => void
} = {}) {
  return useAllGroups({
    isAdmin: true,
    select: selectGroupsToTree,
    onSuccess,
  })
}

// return stores that do not contain "ALL" or "ALL STORES" in name - includes null
function useStores({ isAdmin }: { isAdmin?: boolean } = {}) {
  return useAllGroups({
    isAdmin,
    select: (data) =>
      data.filter((group) =>
        ['all', 'all stores'].every((filter) => {
          return group.name?.toLowerCase() !== filter
        })
      ),
  })
}

export { useAllGroups, useFlatAccountGroups, useStores, useGroupsTree }

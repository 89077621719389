import { constants } from '../constants/authConstants'
import { authService } from '../services/authService'

function request(user) {
  return {
    type: constants.AUTH_LOGIN_REQUEST,
    user,
  }
}

function success(user) {
  return {
    type: constants.AUTH_LOGIN_SUCCESS,
    user,
  }
}

function failure(error) {
  return {
    type: constants.AUTH_LOGIN_FAILURE,
    error,
  }
}

const fetchAuth = function (token, dispatch) {
  dispatch(request({}))
  return authService.fetchAuth(token).then(
    (user) => {
      dispatch(success(user))
    },
    (error) => {
      dispatch(failure(error.toString()))
    }
  )
}

const logout = function (history) {
  authService.logout()
  history.replace('/login')
  return { type: constants.AUTH_LOGOUT }
}

export const authActions = {
  logout,
  fetchAuth,
}

import * as React from 'react'
import {
  IonPage,
  IonHeader,
  IonContent,
  IonButton,
  IonCol,
  IonGrid,
  IonRow,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from '@ionic/react'

import { useStoreTemplates } from '../../../hooks/templates/queries'
import { useInfiniteList } from '../../../hooks/use-infinite-list'
import { useTemplateSorter } from '../../../hooks/use-sorter-action-sheet'
import AdminHeader from '../../../components/header/admin-header'
import {
  TemplateItem,
  TemplateItemSkeleton,
} from '../../../components/templates/template-item'
import { Chip } from '../../../components/ui/buttons/chip'
import { SortButton } from '../../../components/ui/buttons/sort-button'
import ScrollingList from '../../../components/ui/scrolling-list'
import { StickyHeader } from '../../../components/ui/sticky-header'

const templateShortcuts = ['All'] as const
type TemplateShortcut = typeof templateShortcuts[number]

function Templates() {
  const [templateShortcut, setTemplateShortcut] =
    React.useState<TemplateShortcut>('All')

  const { openSorterActionSheet, sortCallback } = useTemplateSorter()
  const templatesQuery = useStoreTemplates()

  const { infiniteListCount, doInfiniteStuff } = useInfiniteList()

  const visibleTemplates = [...(templatesQuery.data ?? [])].sort(sortCallback)

  return (
    <IonPage>
      <IonHeader>
        <AdminHeader showMenu title="Templates" />
      </IonHeader>
      <IonContent>
        <section>
          <StickyHeader>
            <div className="ion-padding-top ion-padding-horizontal">
              <ScrollingList>
                {templateShortcuts.map((filter) => {
                  return (
                    <Chip
                      key={filter}
                      fill={templateShortcut !== filter ? 'outline' : 'solid'}
                      onClick={() => setTemplateShortcut(filter)}
                    >
                      {filter}
                    </Chip>
                  )
                })}
              </ScrollingList>
            </div>

            <div className="ion-margin-top">
              <IonGrid
                style={{ '--ion-grid-padding': 0 }}
                className="ion-padding-horizontal"
              >
                <IonRow className="ion-nowrap ion-align-items-center">
                  <IonCol className="flex-full">
                    <div className="flex items-center gap-x-2">
                      <IonButton
                        size="small"
                        color="yellow"
                        fill="solid"
                        routerLink="/admin/templates/create"
                      >
                        New Template
                      </IonButton>

                      <h2 className="text-ion-color-yellow ml-2">
                        {visibleTemplates.length} Template
                        {visibleTemplates.length === 1 ? '' : 's'}
                      </h2>
                    </div>
                  </IonCol>

                  <IonCol>
                    <SortButton onClick={openSorterActionSheet} />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </StickyHeader>

          <div className="ion-padding-bottom">
            {templatesQuery.data ? (
              <>
                {visibleTemplates.length > 0 ? (
                  <>
                    {visibleTemplates
                      .slice(0, infiniteListCount)
                      .map((template) => (
                        <TemplateItem
                          key={template.objectId}
                          template={template}
                        />
                      ))}
                    <IonInfiniteScroll
                      onIonInfinite={doInfiniteStuff}
                      disabled={infiniteListCount >= visibleTemplates.length}
                    >
                      <IonInfiniteScrollContent loadingSpinner="bubbles" />
                    </IonInfiniteScroll>
                  </>
                ) : (
                  <div className="ion-padding">
                    <p>You have not created any store-wide templates.</p>
                  </div>
                )}
              </>
            ) : (
              Array(15)
                .fill(null)
                .map((_item, idx) => <TemplateItemSkeleton key={idx} />)
            )}
          </div>
        </section>
      </IonContent>
    </IonPage>
  )
}

export default Templates

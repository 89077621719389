import { fromZodError } from 'zod-validation-error'
import { z } from 'zod'

import { client } from '../../services/client'
import { makePaginatedServerResponseSchema } from '../../utils/schema'
import { broadcastBaseSchema } from '../../marketing/broadcasts/broadcast.schema'
import { approvalQueryKeys } from './queries'

import type { QueryFunctionContext } from '@tanstack/react-query'

async function fetchApprovals({
  pageParam = 0,
  queryKey: [{ filter, permission }],
}: QueryFunctionContext<ReturnType<typeof approvalQueryKeys['list']>>) {
  const response = await client
    .post('functions/getApprovals', {
      json: {
        page: pageParam,
        admin: permission === 'admin',
        broadcasts: filter === 'broadcast',
      },
    })
    .json()

  const parsedResponse = makePaginatedServerResponseSchema(
    z.array(broadcastBaseSchema)
  ).safeParse(response)

  if (!parsedResponse.success)
    throw new Error(fromZodError(parsedResponse.error).message)

  return parsedResponse.data.result
}

export { fetchApprovals }

import * as React from 'react'
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonCardTitle,
  IonCardHeader,
  IonSelect,
  IonSelectOption,
  IonCardSubtitle,
  IonSegment,
  IonSegmentButton,
} from '@ionic/react'
import {
  endOfDay,
  endOfWeek,
  format,
  parseISO,
  startOfWeek,
  subWeeks as subtractWeeks,
} from 'date-fns'

import { useDashboard } from '../../hooks/dashboard'
import { useAllGroups } from '../../hooks/queries/use-groups'
import { useFormatNumber } from '../../hooks/use-format-number'
import DashboardCard from '../card/DashboardCard'
import TopClientCard from '../card/TopClientCard'
import Currency from '../Currency'
import RoiCard from '../roi/RoiCard'

function DashboardCardColumn({ children }) {
  return <IonCol className="flex flex-col">{children}</IonCol>
}

function Settings({
  groupData,
  groupsData,
  setGroupData,
  startDate,
  endDate,
  setDate,
  loading,
}) {
  const [currentView, setCurrentView] = React.useState('week to date')

  function handleChangeDateRange(e) {
    setCurrentView(e.detail.value)
    if (e.detail.value === 'last week') {
      setDate({
        startDate: startOfWeek(subtractWeeks(new Date(), 1)).toISOString(),
        endDate: endOfWeek(subtractWeeks(new Date(), 1)).toISOString(),
      })
    } else if (e.detail.value === 'week to date') {
      setDate({
        startDate: startOfWeek(new Date()).toISOString(),
        endDate: endOfDay(new Date()).toISOString(),
      })
    }
  }

  return (
    <IonCard color="secondary">
      <IonCardHeader style={{ textAlign: 'center' }}>
        <IonCardTitle>SETTINGS</IonCardTitle>
        <IonCardSubtitle>
          {`${format(parseISO(startDate), 'MMM dd, yyyy')} - ${format(
            parseISO(endDate),
            'MMM dd, yyyy'
          )}`}
        </IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        <IonSelect
          onIonChange={(e) => setGroupData(e.detail.value)}
          interface="popover"
          value={groupData}
          disabled={loading}
        >
          {groupsData?.map((group) => {
            return (
              <IonSelectOption value={group.objectId} key={group.objectId}>
                {group.name}
              </IonSelectOption>
            )
          })}
        </IonSelect>
        <IonSegment
          style={{ '--background': 'var(--ion-color-primary)' }}
          onIonChange={handleChangeDateRange}
          value={currentView}
        >
          <IonSegmentButton
            style={{
              '--indicator-color': 'var(--ion-color-yellow)',
              '--color': 'white',
            }}
            value="last week"
            disabled={loading}
          >
            LAST WEEK
          </IonSegmentButton>
          <IonSegmentButton
            style={{
              '--indicator-color': 'var(--ion-color-yellow',
              '--color': 'white',
            }}
            value="week to date"
            disabled={loading}
          >
            WEEK TO DATE
          </IonSegmentButton>
        </IonSegment>
      </IonCardContent>
    </IonCard>
  )
}

const selectNonEnterprise = (data) =>
  data.filter((group) => group.type !== 'enterprise')

function DashboardSales() {
  const [{ startDate, endDate }, setDate] = React.useState({
    startDate: startOfWeek(new Date()).toISOString(),
    endDate: endOfDay(new Date()).toISOString(),
  })

  const [selectedGroup, setSelectedGroup] = React.useState()

  const groupsQuery = useAllGroups({
    isAdmin: true,
    select: selectNonEnterprise,
    onSuccess: (data) => {
      setSelectedGroup(data[0].objectId)
    },
  })

  const {
    allClientStatsQuery,
    singleVsMultiPurchaseClientStatsQuery,
    lostClientStatsQuery,
    newClientStatsQuery,
    roiStatsQuery,
    topClientsQuery,
  } = useDashboard({ startDate, endDate, groupId: selectedGroup })

  const formatNumber = useFormatNumber()

  return (
    <IonGrid className="ion-no-padding">
      <IonRow>
        <IonCol size="12" sizeXl="6">
          <Settings
            groupsData={groupsQuery.data}
            groupData={selectedGroup}
            setGroupData={setSelectedGroup}
            startDate={startDate}
            endDate={endDate}
            setDate={setDate}
            loading={groupsQuery.isLoading}
          />
        </IonCol>
        <IonCol size="12" sizeXl="6">
          <RoiCard
            data={roiStatsQuery.data?.roi?.roiTypes}
            title="OneShop Sales"
            subtitle="OneShop Sales After Outreach"
            showAll={true}
          />
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol size="12" sizeXl="6">
          <IonGrid className="ion-no-padding">
            <IonRow>
              <DashboardCardColumn size="12" sizeSm="6">
                <DashboardCard
                  title="SALES"
                  value={
                    <Currency value={allClientStatsQuery.data?.salesAll} />
                  }
                  percentChange={allClientStatsQuery.data?.salesAllChange}
                  isRed={allClientStatsQuery.data?.salesAllChange < 0}
                  isLoading={
                    groupsQuery.isLoading || allClientStatsQuery.isLoading
                  }
                />
              </DashboardCardColumn>
              <DashboardCardColumn size="12" sizeSm="6">
                <DashboardCard
                  title="CLIENTS"
                  value={formatNumber(allClientStatsQuery.data?.clientsAll)}
                  percentChange={allClientStatsQuery.data?.clientsAllChange}
                  isRed={allClientStatsQuery.data?.clientsAllChange < 0}
                  isLoading={
                    groupsQuery.isLoading || allClientStatsQuery.isLoading
                  }
                />
              </DashboardCardColumn>

              <DashboardCardColumn size="12" sizeSm="6">
                <DashboardCard
                  title="TOTAL LOST CLIENTS(#)"
                  value={formatNumber(
                    lostClientStatsQuery.data?.lostClientCount
                  )}
                  percentChange={lostClientStatsQuery.data?.lostClientsChange}
                  isRed={lostClientStatsQuery.data?.lostClientsChange > 0}
                  isLoading={
                    groupsQuery.isLoading || lostClientStatsQuery.isLoading
                  }
                />
              </DashboardCardColumn>
              <DashboardCardColumn size="12" sizeSm="6">
                <DashboardCard
                  title="TOTAL LOST CLIENTS($)"
                  value={
                    <Currency
                      value={lostClientStatsQuery.data?.lostClientSales}
                    />
                  }
                  percentChange={
                    lostClientStatsQuery.data?.lostClientSalesChange
                  }
                  isRed={lostClientStatsQuery.data?.lostClientSalesChange > 0}
                  isLoading={
                    groupsQuery.isLoading || lostClientStatsQuery.isLoading
                  }
                />
              </DashboardCardColumn>

              <DashboardCardColumn size="12" sizeSm="6">
                <DashboardCard
                  title="TOTAL NEW CLIENTS(#)"
                  value={formatNumber(newClientStatsQuery.data?.newClientCount)}
                  percentChange={
                    newClientStatsQuery.data?.newClientsChange || 0
                  }
                  isRed={newClientStatsQuery.data?.newClientsChange < 0}
                  isLoading={
                    groupsQuery.isLoading || newClientStatsQuery.isLoading
                  }
                />
              </DashboardCardColumn>
              <DashboardCardColumn size="12" sizeSm="6">
                <DashboardCard
                  title="TOTAL NEW CLIENTS($)"
                  value={
                    <Currency
                      value={newClientStatsQuery.data?.newClientSales}
                    />
                  }
                  percentChange={
                    newClientStatsQuery.data?.newClientSalesChange || 0
                  }
                  isRed={newClientStatsQuery.data?.newClientSalesChange < 0}
                  isLoading={
                    groupsQuery.isLoading || newClientStatsQuery.isLoading
                  }
                />
              </DashboardCardColumn>

              <DashboardCardColumn size="12" sizeSm="6">
                <DashboardCard
                  title="SINGLE PURCHASE CLIENT(#)"
                  value={`${formatNumber(
                    singleVsMultiPurchaseClientStatsQuery.data
                      ?.singlePurchaseClientCount
                  )}`}
                  value2={`${singleVsMultiPurchaseClientStatsQuery.data?.singlePurchaseClientCountPercent}%`}
                  percentChange={
                    singleVsMultiPurchaseClientStatsQuery.data
                      ?.singlePurchaseClientCountChange
                  }
                  isRed={
                    singleVsMultiPurchaseClientStatsQuery.data
                      ?.singlePurchaseClientCountChange < 0
                  }
                  isLoading={
                    groupsQuery.isLoading ||
                    singleVsMultiPurchaseClientStatsQuery.isLoading
                  }
                />
              </DashboardCardColumn>
              <DashboardCardColumn size="12" sizeSm="6">
                <DashboardCard
                  title="SINGLE PURCHASE CLIENT($)"
                  value={
                    <Currency
                      value={
                        singleVsMultiPurchaseClientStatsQuery.data
                          ?.singlePurchaseClientSales
                      }
                    />
                  }
                  value2={`${singleVsMultiPurchaseClientStatsQuery.data?.singlePurchaseClientSalesPercent}%`}
                  percentChange={
                    singleVsMultiPurchaseClientStatsQuery.data
                      ?.singlePurchaseClientSalesChange
                  }
                  isRed={
                    singleVsMultiPurchaseClientStatsQuery.data
                      ?.singlePurchaseClientSalesChange < 0
                  }
                  isLoading={
                    groupsQuery.isLoading ||
                    singleVsMultiPurchaseClientStatsQuery.isLoading
                  }
                />
              </DashboardCardColumn>

              <DashboardCardColumn size="12" sizeSm="6">
                <DashboardCard
                  title="MULTI PURCHASE CLIENT(#)"
                  value={`${formatNumber(
                    singleVsMultiPurchaseClientStatsQuery.data
                      ?.multiPurchaseClientCount
                  )}`}
                  value2={`${singleVsMultiPurchaseClientStatsQuery.data?.multiPurchaseClientCountPercent}%`}
                  percentChange={
                    singleVsMultiPurchaseClientStatsQuery.data
                      ?.multiPurchaseClientCountChange
                  }
                  isRed={
                    singleVsMultiPurchaseClientStatsQuery.data
                      ?.multiPurchaseClientCountChange < 0
                  }
                  isLoading={
                    groupsQuery.isLoading ||
                    singleVsMultiPurchaseClientStatsQuery.isLoading
                  }
                />
              </DashboardCardColumn>
              <DashboardCardColumn size="12" sizeSm="6">
                <DashboardCard
                  title="MULTI PURCHASE CLIENT($)"
                  value={
                    <Currency
                      value={
                        singleVsMultiPurchaseClientStatsQuery.data
                          ?.multiPurchaseClientSales
                      }
                    />
                  }
                  value2={`${singleVsMultiPurchaseClientStatsQuery.data?.multiPurchaseClientSalesPercent}%`}
                  percentChange={
                    singleVsMultiPurchaseClientStatsQuery.data
                      ?.multiPurchaseClientSalesChange
                  }
                  isRed={
                    singleVsMultiPurchaseClientStatsQuery.data
                      ?.multiPurchaseClientSalesChange < 0
                  }
                  isLoading={
                    groupsQuery.isLoading ||
                    singleVsMultiPurchaseClientStatsQuery.isLoading
                  }
                />
              </DashboardCardColumn>
            </IonRow>
          </IonGrid>
        </IonCol>

        <IonCol size="12" sizeXl="6">
          <TopClientCard
            list={topClientsQuery.data?.topClients}
            totalSales={topClientsQuery.data?.salesAll}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

export default DashboardSales

import { useLocation } from 'react-router-dom'

import { useQueryParams } from '../../hooks/use-query-params'

function useLightspeedParams() {
  const { search: querystring } = useLocation()

  const queryParams = useQueryParams()

  const type = queryParams.get('type')
  const accountID = queryParams.get('accountID')
  const systemUserID = queryParams.get('systemUserID')
  const employeeID = queryParams.get('employeeID')
  const saleID = queryParams.get('saleID')
  const customerID = queryParams.get('customerID')
  const shopID = queryParams.get('shopID')
  const registerID = queryParams.get('registerID')
  const returnURL = queryParams.get('returnURL')

  return {
    type,
    accountID,
    systemUserID,
    employeeID,
    saleID,
    customerID,
    shopID,
    registerID,
    returnURL,
    querystring,
  }
}

export { useLightspeedParams }

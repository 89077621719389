import { z } from 'zod'
import isLowercase from 'validator/es/lib/isLowercase'
import isAlphaNumeric from 'validator/es/lib/isAlphanumeric'

import { serverItemSchema, nullableString } from '../utils/schema'

const groupBaseSchema = serverItemSchema.extend({
  name: nullableString,
  logo: nullableString,
  // TODO: add settings key
})

const groupOverviewSchema = groupBaseSchema.merge(
  z.object({
    companyName: nullableString,
    parent: serverItemSchema.nullable(),
    type: z.literal('enterprise').or(z.null()),
  })
)

const updateProfileSchema = z.object({
  profileId: z
    .string()
    .regex(/^\S+$/g, { message: 'Your profile id cannot contain spaces.' })
    .superRefine((val, ctx) => {
      if (val.length && val.length < 3) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Your profile id must be at least 3 characters.',
        })
      }
      if (!isLowercase(val) || !isAlphaNumeric(val)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            'Your profile id must only contain numbers or lowercase letters',
        })
      }
    }),
  displayName: z.string(),
  bio: z.string(),
  photo: z.object({
    name: z.string(),
    photo: z.string(),
    base64String: z.string(),
  }),
})

export type UpdateProfileSchema = z.infer<typeof updateProfileSchema>
export { groupBaseSchema, updateProfileSchema }

import {
  IonItem,
  IonLabel,
  IonInput,
  IonSelect,
  IonSelectOption,
} from '@ionic/react'
import { Controller } from 'react-hook-form'

import { useFlatAccountGroups } from '../../hooks/queries/use-groups'
import { alterationSteps } from '../constants'

import type { Control } from 'react-hook-form'
import type { CreateAlterationInput } from '../../hooks/tasks/mutations'

export function AlterationItemInputs({
  control,
  index,
  groups,
}: {
  control: Control<CreateAlterationInput>
  index: number
  groups: ReturnType<typeof useFlatAccountGroups>
}) {
  return (
    <div className="rounded bg-gray-200 p-3">
      <h2 className="text-ion-color-primary font-semibold">Alteration Item:</h2>
      <div className="flex-1">
        <IonItem color="secondary">
          <IonLabel>Title</IonLabel>
          <Controller
            name={`items.${index}.title`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <IonInput
                value={value}
                onIonChange={(e) => onChange(e.detail.value)}
              />
            )}
          />
        </IonItem>
        <IonItem color="secondary">
          <IonLabel>Current Step</IonLabel>
          <Controller
            name={`items.${index}.step`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <IonSelect
                color="secondary"
                value={value}
                onIonChange={(e) => onChange(e.detail.value)}
              >
                {alterationSteps.map((step) => (
                  <IonSelectOption key={step} value={step}>
                    {step}
                  </IonSelectOption>
                ))}
              </IonSelect>
            )}
          />
        </IonItem>
        <IonItem color="secondary" lines="none">
          <IonLabel>Current Location</IonLabel>
          <Controller
            name={`items.${index}.groupId`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <IonSelect
                color="secondary"
                value={value}
                onIonChange={(e) => {
                  // HACK some reason revalidation causes reset to empty string
                  if (!e.detail.value) return
                  onChange(e.detail.value)
                }}
              >
                {groups.data?.map((group) => (
                  <IonSelectOption key={group.objectId} value={group.objectId}>
                    {group.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            )}
          />
        </IonItem>
      </div>
    </div>
  )
}

import React from 'react'

import * as commonStrings from '../../utils/common-strings'
import { isTaskType } from '../../constants/classConstants'
import CardPurchase from './CardPurchase'
import CardItemNote from './CardItemNote'
import CardItemFollowUp from './CardItemFollowUp'
import CardItemMessage from './CardItemMessage'

const ClientDetailFeedItem = ({ feedItem }) => {
  if (feedItem.className === commonStrings.Note) {
    return <CardItemNote note={feedItem} />
  } else if (feedItem.className === commonStrings.Message) {
    return <CardItemMessage message={feedItem} />
  } else if (feedItem.className === commonStrings.Sale) {
    return <CardPurchase purchase={feedItem} />
  } else if (isTaskType.includes(feedItem.className)) {
    return <CardItemFollowUp task={feedItem} />
  }

  return null
}

export default ClientDetailFeedItem

import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import * as R from 'remeda'
import { klona } from 'klona/json'
import { useParams } from 'react-router-dom'

import {
  makePaginatedServerResponseSchema,
  makeServerResponseSchema,
} from '../utils/schema'
import { client } from '../services/client'
import { automationSchema, automationsSchema } from './automations-base.schema'
import { triggers } from './constants'
import { parseConditions } from './utils'

import type { QueryContextFromKeys } from '../utils/react-query'
import type { Automation } from './automations-base.schema'

type AutomationsQueryContext = QueryContextFromKeys<typeof automationsQueryKeys>

const triggerTypeWhitelist = triggers.map((t) => t.value)
const conditionTypeWhitelist = [
  'sale',
  'noSales',
  'loyaltyPointsActive',
  'loyaltyPointsLifetime',
  'loyaltyVisitsActive',
]

const automationsQueryKeys = {
  all: [{ entity: 'automations' }] as const,
  lists: () => [{ ...automationsQueryKeys.all[0], scope: 'list' }] as const,
  list: () => [{ ...automationsQueryKeys.lists()[0] }] as const,
  details: () => [{ ...automationsQueryKeys.all[0], scope: 'detail' }] as const,
  detail: (automationId: string) =>
    [{ ...automationsQueryKeys.details()[0], automationId }] as const,
}

async function fetchTriggerRules() {
  const response = await client.post('functions/getTriggerRules').json()

  const result =
    makePaginatedServerResponseSchema(automationsSchema).parse(response).result

  return {
    ...result,
    data: result.data
      .filter((d) => triggerTypeWhitelist.some((w) => w === d.type))
      .filter(
        (d) =>
          !Boolean(d.conditions?.length) ||
          d.conditions?.some((c) => conditionTypeWhitelist.includes(c.type))
      ),
  }
}

async function fetchTriggerRule({
  queryKey: [{ automationId }],
}: AutomationsQueryContext['detail']) {
  const response = await client
    .post('functions/getTriggerRule', { json: { objectId: automationId } })
    .json()

  return makeServerResponseSchema(automationSchema).parse(response).result
}

function useAutomations() {
  return useInfiniteQuery({
    queryKey: automationsQueryKeys.list(),
    queryFn: fetchTriggerRules,
  })
}

function useAutomationBase<TResult = Automation>(
  select?: (data: Automation) => TResult
) {
  const { automationId } = useParams<{ automationId: string | undefined }>()
  if (!automationId)
    throw new Error('You can only get automation data from an automation page.')

  return useQuery({
    queryKey: automationsQueryKeys.detail(automationId),
    queryFn: fetchTriggerRule,
    select,
  })
}

function useAutomation() {
  return useAutomationBase((data) => ({
    ...data,
    conditions: parseConditions(data.conditions ?? []),
    steps: R.sortBy(klona(data.steps), (x) => x.position),
  }))
}

function useTriggerStep(triggerStepId: string) {
  return useAutomationBase((data) =>
    data.steps.find((step) => step.objectId === triggerStepId)
  )
}

function useAutomationState() {
  return useAutomationBase((data) => data.state)
}

export {
  automationsQueryKeys,
  useAutomations,
  useAutomation,
  useAutomationState,
  useTriggerStep,
}

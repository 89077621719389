import { z } from 'zod'
import { oneshopStatuses } from '../../constants/misc'

import { nullableString, serverItemSchema } from '../../utils/schema'

type CloudinaryOverview = z.infer<typeof cloudinaryOverviewSchema>

const cloudinaryOverviewSchema = serverItemSchema.extend({
  data: z.object({
    public_id: z.string(),
    resource_type: z.enum(['image', 'video']),
    secure_url: z.string(),
  }),
  status: nullableString, // TODO: Get Literals and WHY nullable ALLOWED
})

const lookOverviewSchema = serverItemSchema.extend({
  cloudinary: cloudinaryOverviewSchema,
  coverPhoto: serverItemSchema.extend({ originalSrc: z.string() }).nullable(),
  description: nullableString,
  products: z.array(z.any()),
  status: z.enum(oneshopStatuses).nullable(),
})

export type { CloudinaryOverview }
export { lookOverviewSchema }

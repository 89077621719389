import { IonContent, IonHeader, IonLoading, IonPage } from '@ionic/react'
import { useAutomation } from '../../../automations/queries'

import AdminHeader from '../../../components/header/admin-header'
import AutomationDetail from '../../../automations/components/automation-detail'

function AutomationDetailPage() {
  const automationQuery = useAutomation()

  return (
    <IonPage>
      <IonHeader>
        <AdminHeader backRef="/admin/automations" title="Automation Detail" />
      </IonHeader>
      <IonContent className="ion-padding">
        {automationQuery.data ? (
          <AutomationDetail automation={automationQuery.data} />
        ) : (
          <IonLoading isOpen message="Loading Automation..." />
        )}
      </IonContent>
    </IonPage>
  )
}

export default AutomationDetailPage

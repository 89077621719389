import * as React from 'react'
import {
  IonCard,
  IonCardContent,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonButton,
  isPlatform,
} from '@ionic/react'
import produce from 'immer'

import { Okay, Dismiss } from '../../utils/common-strings'
import { formatName } from '../../utils/format-helpers'
import saveManualCommunication from '../../utils/save-communication'
import { useStylistGroup, useUser } from '../../contexts/authContext'
import { useToast } from '../../contexts/toastContext'
import useNextContact from '../../hooks/use-next-contact'
import { usePhoto } from '../../hooks/use-photo'
import { useQueryParams } from '../../hooks/use-query-params'
import useSendCommunication from '../../hooks/use-send-communication'
import AddPhotos from '../AddPhotos'

const reducer = produce((draft, action) => {
  const { type } = action

  switch (type) {
    case 'UPDATE_FIELD':
      const { name, value } = action
      draft[name] = value
      break
    case 'SELECT_TEMPLATE':
      const { id, template } = action

      if (template) {
        draft.template = id
        draft.message = template.body
      }
      break
    default:
      break
  }
})

function TextCard({
  templates: smsTemplates,
  clientId,
  phoneNumber,
  handleCommunicationCancel,
  onTextSend,
}) {
  const user = useUser()
  const stylistGroup = useStylistGroup()
  const { getPhotoFromUrl } = usePhoto()

  const URLquery = useQueryParams()
  const callListId = URLquery.get('callListId')
  const [photos, setPhotos] = React.useState([])

  const [state, dispatch] = React.useReducer(reducer, {
    message: '',
    template: null,
  })
  const { nextContactDate, setNextContactDate, dates, saveNextContactDate } =
    useNextContact()
  const setToast = useToast()
  const { sendSMS } = useSendCommunication()

  // TODO: workaround to filter out empty templates
  const templates = smsTemplates.filter((template) => template.body)

  const isBrowser = !isPlatform('capacitor')
  const canAddPhotos = !isBrowser

  function handleFormUpdate(e) {
    const { name, value } = e.target
    dispatch({ type: 'UPDATE_FIELD', name, value })
  }

  async function handleTemplateSelect(e) {
    const id = e.target.value
    const template = templates.find((t) => t.objectId === id)

    if (template) {
      template.body =
        template.body
          ?.replaceAll('<Stylist>', formatName(user.firstName))
          ?.replaceAll('<Store>', stylistGroup.name)
          ?.replaceAll('<StylistId>', user.objectId) ?? ''

      dispatch({ type: 'SELECT_TEMPLATE', id, template })

      // reset photos and exit early if no template attachments
      if (!template.attachments) {
        setPhotos([])
        return
      }

      // add template attachments if user is on an ios device
      if (canAddPhotos) {
        const convertedAttachments = await Promise.all(
          template.attachments.map(async (p) => await getPhotoFromUrl(p))
        )
        setPhotos(convertedAttachments)
      }
    }
  }

  async function handleSendMessage({ log }) {
    const attachments = []
    if (canAddPhotos) {
      photos.forEach((photo) => {
        if (photo.fileUri) {
          attachments.push(photo.fileUri)
        }
      })
    }

    try {
      if (!log) {
        await sendSMS(phoneNumber, { message: state.message, attachments })
      }
      await saveManualCommunication('sms', {
        message: state.message,
        clientId,
        log,
        pointers: {
          smsTemplateId: state.template,
          callListId,
        },
      })

      await saveNextContactDate(clientId)
      handleCommunicationCancel()
      if (onTextSend) onTextSend()
    } catch (e) {
      setToast({
        message: 'Message canceled',
      })
    }
  }

  return (
    <IonCard className="followup-card" color="secondary">
      <IonCardContent>
        <IonItem color="secondary">
          <div tabIndex={0} />
          <IonLabel>Select a Template</IonLabel>
          <IonSelect
            id="sms"
            interfaceOptions={{ cssClass: 'dark-ionic-filter' }}
            value={state.template}
            onIonChange={handleTemplateSelect}
            okText={Okay}
            cancelText={Dismiss}
          >
            {templates.map((template, index) => (
              <IonSelectOption key={index} value={template.objectId}>
                {template.name}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>

        <IonItem color="secondary">
          <IonLabel position="floating">Type Your Message Here:</IonLabel>
          <IonTextarea
            name="message"
            value={state.message}
            onIonChange={handleFormUpdate}
            autoGrow
            autocapitalize="on"
            rows={Math.max((state.message || '').split(/\r\n|\r|\n/).length, 3)}
          />
        </IonItem>

        <IonItem className="ion-margin-top p-0" color="secondary">
          <IonLabel>Next Contact:</IonLabel>
          <IonSelect
            id="next-contact-select"
            value={nextContactDate}
            onIonChange={(e) => setNextContactDate(e.target.value)}
            okText={Okay}
            cancelText={Dismiss}
            interfaceOptions={{ cssClass: 'dark-ionic-filter' }}
          >
            {dates.map(({ label, value }) => (
              <IonSelectOption key={value} value={value}>
                {label}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>

        {canAddPhotos && (
          <AddPhotos photos={photos} onPhotosUpdate={setPhotos} showPhotos />
        )}

        <div className="buttons mt-1.5">
          <div>
            <IonButton fill="outline" onClick={handleCommunicationCancel}>
              CANCEL
            </IonButton>
          </div>
          <div className="space-x-2">
            <IonButton
              fill="outline"
              onClick={() => handleSendMessage({ log: true })}
            >
              LOG
            </IonButton>
            <IonButton onClick={() => handleSendMessage({ log: false })}>
              SEND
            </IonButton>
          </div>
        </div>
      </IonCardContent>
    </IonCard>
  )
}

export default TextCard

import * as React from 'react'
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonLabel,
  IonModal,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  // useIonRouter,
  IonRouterContext,
  UseIonRouterResult,
  IonImg,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonTextarea,
} from '@ionic/react'
import { Controller, useForm } from 'react-hook-form'
import { useImmer } from 'use-immer'

import {
  getDefaultPhoneNumber,
  useCanMessageClient,
} from '../../utils/client-helpers'
import { replacePlaceholders } from '../../utils/format-helpers'
import { hasDefinedProp, typedBoolean } from '../../utils/typescript-helpers'
import { useUser } from '../../contexts/authContext'
import { useToast } from '../../contexts/toastContext'
import { useOptInMessage } from '../../hooks/opt-in'
import { useShopWithLinks } from '../../hooks/shopwith/use-shopwith-links'
import { useTemplates } from '../../hooks/templates/queries'
import { useReplacePlaceholders } from '../../hooks/use-replace-placeholders'
import { usePrimaryClientConversation } from '../../hooks/chat/queries'
import { useSendMessage, useSendOptInMessage } from '../../hooks/chat/mutations'
import { useIsStore } from '../../auth/utils'
import { TextArea } from '../ui/form/textarea'

import type { SelectClientDetail } from '../../hooks/clients/queries'

/********** */
/**
 * TEMPORARY FIX
 * //TODO: REMOVE THIS ONCE IONIC HAS FIXED THE UNSTABLE REFERENCE BUG
 * A hook for more direct control over routing in an Ionic React application. Allows you to pass additional meta-data to the router before the call to the native router.
 */
function useIonRouter(): UseIonRouterResult {
  const context = React.useContext(IonRouterContext)
  return React.useMemo(
    () => ({
      back: context.back,
      push: context.push,
      goBack: context.back,
      canGoBack: context.canGoBack,
      routeInfo: context.routeInfo,
    }),
    [context.back, context.canGoBack, context.push, context.routeInfo]
  )
}

type UnoMessage = {
  message: string
  link: string
  attachments: Array<string>
  smsTemplateId?: string
  productIds?: Array<string>
  lookIds?: Array<string>
  collectionIds?: Array<string>
}

type Props = {
  client: NonNullable<SelectClientDetail>['ClientDetailsData']
}

function OptInButton({ client }: { client: Props['client'] }) {
  const [showOptInMessage, setShowOptInMessage] = React.useState(false)

  const { replacePlaceholders } = useReplacePlaceholders()

  const { getConversationId } = usePrimaryClientConversation()
  const sendOptInMessageMutation = useSendOptInMessage()
  const optInMessage = useOptInMessage()

  const { handleSubmit, control } = useForm({
    defaultValues: {
      optInMessage: replacePlaceholders(optInMessage),
    },
  })
  const setToast = useToast()

  async function handleOptInSubmit(data: { optInMessage: string }) {
    const conversationId = await getConversationId(client.objectId)

    if (!conversationId) {
      return setToast({
        message:
          'Something went wrong setting up the conversation for this client.',
        color: 'danger',
      })
    }
    setShowOptInMessage(false)

    sendOptInMessageMutation.mutate({
      conversationId,
      client,
      optInMessage: data.optInMessage,
    })
  }

  return (
    <>
      <IonButton
        size="small"
        expand="block"
        color="yellow"
        fill="outline"
        disabled={sendOptInMessageMutation.isLoading}
        onClick={() => {
          setShowOptInMessage(true)
        }}
      >
        Send Opt In
      </IonButton>

      <IonModal
        isOpen={showOptInMessage}
        onDidDismiss={() => setShowOptInMessage(false)}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>Send Opt-In</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonCard>
            <IonCardContent>
              <IonText color="primary">
                You are about to send an opt in link to this client. You can
                customize your message below:
              </IonText>
              <form onSubmit={handleSubmit(handleOptInSubmit)}>
                <div className="text-ion-color-primary mt-4">
                  <IonLabel position="stacked">Opt In Message</IonLabel>
                  <Controller
                    control={control}
                    name="optInMessage"
                    render={({ field: { value, onChange } }) => {
                      return (
                        <TextArea
                          value={value}
                          onIonChange={onChange}
                          autoGrow
                        />
                      )
                    }}
                  />
                </div>
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <IonButton
                        expand="block"
                        color="primary"
                        fill="outline"
                        onClick={() => setShowOptInMessage(false)}
                      >
                        Cancel
                      </IonButton>
                    </IonCol>
                    <IonCol>
                      <IonButton
                        disabled={sendOptInMessageMutation.isLoading}
                        expand="block"
                        color="yellow"
                        type="submit"
                      >
                        Send Opt In
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </form>
            </IonCardContent>
          </IonCard>
        </IonContent>
      </IonModal>
    </>
  )
}

function OneShopMessaging({ client }: Props) {
  //  TODO GET USER MANUAL ONLY FLAG AND ADD TO !HASTWILIO CHECK
  const { hasTwilio, smsManual } = useUser()

  const { isUno, isScoutMollysPlano } = useIsStore()

  const sendMessage = useSendMessage()

  const isUnsubscribed = Boolean(client.unsubscribedAt) // internal DO NOT CONTACT option
  const defaultPhoneNumber = getDefaultPhoneNumber(client.phoneNumbers)
  const canMessageClient = useCanMessageClient(client.users)

  const hasAvailablePhoneNumber =
    defaultPhoneNumber?.optInState === 'NOT_INITIALIZED' ||
    defaultPhoneNumber?.optInState === 'PENDING' ||
    defaultPhoneNumber?.optInState === 'OPTED_IN'

  const canMessage = hasAvailablePhoneNumber && canMessageClient

  const canOptIn =
    defaultPhoneNumber?.optInState === 'NOT_INITIALIZED' && canMessageClient

  const [isLoadingConversation, setIsLoadingConversation] =
    React.useState(false)
  const router = useIonRouter()
  const setToast = useToast()

  const { getConversationId } = usePrimaryClientConversation()

  async function handleMessageButtonClick() {
    // Just in case - tho technically button should be disabled
    if (!defaultPhoneNumber || !defaultPhoneNumber.e164) {
      return setToast({
        message:
          'This client does not have a default phone number that can be messaged.',
      })
    }

    // loading state to disable button
    setIsLoadingConversation(true)
    const conversationId = await getConversationId(client.objectId)
    setIsLoadingConversation(false)

    if (conversationId) {
      router.push(`/chat/c/${conversationId}`)
    }
  }

  async function handleUnoSend(unoMessage: UnoMessage) {
    const { message, ...rest } = unoMessage

    // Just in case - tho technically button should be disabled
    if (!defaultPhoneNumber || !defaultPhoneNumber.e164) {
      return setToast({
        message:
          'This client does not have a default phone number that can be messaged.',
      })
    }

    // loading state to disable button
    setIsLoadingConversation(true)
    const conversationId = await getConversationId(client.objectId)
    setIsLoadingConversation(false)

    if (!conversationId) {
      return setToast({
        message: "There was a problem loading this client's conversation.",
      })
    }

    sendMessage.mutate(
      { conversationId, message: { data: message, ...rest } },
      {
        onSuccess: () =>
          setToast({ message: 'Your message was sent!', color: 'yellow' }),
      }
    )
  }

  // manual sms accounts
  if (!hasTwilio || smsManual) return null

  // unsubscribed client
  if (isUnsubscribed || client.posActive === false) return null

  // cannot send sms to phone number
  if (
    !defaultPhoneNumber ||
    defaultPhoneNumber.isLandLine ||
    !hasDefinedProp(defaultPhoneNumber, 'e164')
  )
    return null

  return (
    <>
      <div className="mt-2 flex gap-4">
        <IonButton
          size="small"
          expand="block"
          color="yellow"
          disabled={isLoadingConversation || !canMessage}
          onClick={handleMessageButtonClick}
          fill="solid"
        >
          Send Message
        </IonButton>
        {canOptIn ? <OptInButton client={client} /> : null}
      </div>
      {isUno() || isScoutMollysPlano() ? (
        <div>
          <SendMessageWithTemplateButton
            client={client}
            disabled={!canMessage}
            onSend={handleUnoSend}
          />
        </div>
      ) : null}
    </>
  )
}

function SendMessageWithTemplateButton({
  client,
  disabled,
  onSend,
}: {
  client: Props['client']
  disabled: boolean
  onSend: (message: UnoMessage) => void
}) {
  const modalRef = React.useRef<HTMLIonModalElement>(null)

  const [smsMessage, updateSmsMessage] = useImmer<UnoMessage>({
    message: '',
    link: '',
    attachments: [],
  })

  const { generateShopWithLinkPreview } = useShopWithLinks()
  const { replacePlaceholders: replaceStylistPlaceholders } =
    useReplacePlaceholders()

  const templatesQuery = useTemplates((data) =>
    data.map((t) => t.smsTemplate).filter(typedBoolean)
  )

  function reset() {
    updateSmsMessage({
      message: '',
      link: '',
      attachments: [],
    })
  }

  function handleTemplateSelect(id: string) {
    const selectedTemplate = templatesQuery.data?.find(
      (template) => template.objectId === id
    )

    if (!selectedTemplate) return

    const link = Boolean(selectedTemplate.products?.length)
      ? generateShopWithLinkPreview(
          'productIds',
          selectedTemplate.products?.map((p) => p.objectId) ?? []
        )
      : Boolean(selectedTemplate.looks?.length)
      ? generateShopWithLinkPreview(
          'lookIds',
          selectedTemplate.looks?.map((l) => l.objectId) ?? []
        )
      : Boolean(selectedTemplate.collections?.length)
      ? generateShopWithLinkPreview(
          'collectionIds',
          selectedTemplate.collections?.map((c) => c.objectId) ?? []
        )
      : undefined

    updateSmsMessage((draft) => {
      draft.message = replaceStylistPlaceholders(
        replacePlaceholders(selectedTemplate.body ?? '', {
          '<First Name>': client.firstName ?? '',
          '<Last Name>': client.lastName ?? '',
        })
      )
      draft.link = link ?? ''
      draft.attachments = selectedTemplate.attachments ?? []
      draft.smsTemplateId = selectedTemplate.objectId
      draft.productIds = selectedTemplate.products?.map((c) => c.objectId)
      draft.lookIds = selectedTemplate.looks?.map((l) => l.objectId)
      draft.collectionIds = selectedTemplate.collections?.map((p) => p.objectId)
    })
  }

  function handleSendMessage() {
    modalRef.current?.dismiss()
    onSend(smsMessage)
  }

  return (
    <>
      <IonButton
        id="send-message"
        color="yellow"
        size="small"
        disabled={disabled}
      >
        Send Template
      </IonButton>
      <IonModal
        ref={modalRef}
        trigger="send-message"
        onDidDismiss={() => reset()}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>Send Message With Template</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonItem>
            <IonLabel>Select A Template</IonLabel>
            <IonSelect
              interfaceOptions={{
                cssClass: 'dark-ionic-filter',
              }}
              onIonChange={(e) => handleTemplateSelect(e.detail.value)}
              okText="OK"
              cancelText="Cancel"
              className="bg-transparent"
            >
              {templatesQuery.data?.map((template) => (
                <IonSelectOption
                  key={template.objectId}
                  value={template.objectId}
                >
                  {template.name}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
          <IonCard className="ion-no-margin">
            <IonCardContent>
              <IonItem color="secondary" style={{ '--padding-start': 0 }}>
                <IonLabel position="stacked">Message: </IonLabel>
                <IonTextarea value={smsMessage.message} readonly />
              </IonItem>
              <IonRow className="ion-margin-top">
                {smsMessage.link ? (
                  <p>
                    <IonText color="primary">
                      <strong>ShopWith Link:</strong> {smsMessage.link}
                    </IonText>
                  </p>
                ) : null}
                {smsMessage.attachments.length ? (
                  <>
                    <p>
                      <IonText color="primary">
                        <strong>Attachments:</strong>{' '}
                      </IonText>
                    </p>
                    <IonRow>
                      {smsMessage.attachments.map((a) => (
                        <IonCol key={a} size="3">
                          <IonImg src={a} />
                        </IonCol>
                      ))}
                    </IonRow>
                  </>
                ) : null}
              </IonRow>
            </IonCardContent>
          </IonCard>
          <IonRow className="ion-justify-content-between ion-margin-top">
            <IonCol size="auto" className="ion-no-padding">
              <IonButton
                color="secondary"
                fill="outline"
                onClick={() => modalRef.current?.dismiss()}
              >
                Cancel
              </IonButton>
            </IonCol>
            <IonCol size="auto" className="ion-no-padding">
              <IonButton color="yellow" onClick={handleSendMessage}>
                Send
              </IonButton>
            </IonCol>
          </IonRow>
        </IonContent>
      </IonModal>
    </>
  )
}

export default OneShopMessaging

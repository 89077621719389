import { useState } from 'react'
import { addDays, addWeeks, addMonths, startOfDay } from 'date-fns'

import { searchService } from '../services/searchService'

const dates = [
  {
    label: 'Tomorrow',
    value: startOfDay(addDays(new Date(), 1)).toISOString(),
  },
  {
    label: 'Next Week',
    value: startOfDay(addWeeks(new Date(), 1)).toISOString(),
  },
  {
    label: 'Two Weeks',
    value: startOfDay(addWeeks(new Date(), 2)).toISOString(),
  },
  {
    label: 'Three Weeks',
    value: startOfDay(addWeeks(new Date(), 3)).toISOString(),
  },
  {
    label: 'Next Month',
    value: startOfDay(addMonths(new Date(), 1)).toISOString(),
  },
  {
    label: 'Three Months',
    value: startOfDay(addMonths(new Date(), 3)).toISOString(),
  },
  {
    label: 'Six Months',
    value: startOfDay(addMonths(new Date(), 6)).toISOString(),
  },
]

export default function useNextContact(
  defaultNextContactDate: string | null = null
) {
  const [nextContactDate, setNextContactDate] = useState()

  async function saveNextContactDate(clientId: string) {
    let body
    if (!nextContactDate && !defaultNextContactDate) {
      body = {
        nextContact: {
          __op: 'Delete',
        },
      }
    } else {
      body = {
        nextContact: {
          __type: 'Date',
          iso: nextContactDate ? nextContactDate : defaultNextContactDate,
        },
      }
    }

    await searchService.updateObject('Client', clientId, body)
  }

  return { dates, nextContactDate, setNextContactDate, saveNextContactDate }
}

import * as React from 'react'
import {
  IonButton,
  IonCol,
  IonContent,
  IonPage,
  IonRow,
  useIonRouter,
} from '@ionic/react'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

import { cn } from '../../utils/cn'
import { useAccount } from '../../contexts/authContext'
import { useToast } from '../../contexts/toastContext'
import { PhoneKeypadInput } from '../../components/ui/phone-keypad-input'
import { useCheckInActions } from '../../check-in/check-in-store'
import { useOptInClient } from '../../marketing/customer-opt-in/mutations'
import { getClientFromE164 } from '../../rotate/api'
import { RotateInfoContainer } from '../../rotate/components/rotate-info-container'
import { RotateInfoIntro } from '../../rotate/components/rotate-info-intro'
import { RotateInfoLegal } from '../../rotate/components/rotate-info-legal'

import type { CountryCode } from 'libphonenumber-js'

function PairedPhoneCheckInPage() {
  const [isLoading, setisLoading] = React.useState(false)
  const [error, setError] = React.useState<string | null>(null)

  const { resetClientInfo, setClientInfo, setCheckInMethod } =
    useCheckInActions()

  const account = useAccount()
  const checkInSettings = account.account.checkInSettings
  const optInClient = useOptInClient()

  const router = useIonRouter()
  const setToast = useToast()

  function checkInCustomer(clientId: string) {
    router.push(
      `/check-in/paired/loyalty-info?clientId=${clientId}`,
      'forward',
      'pop'
    )
  }

  function createNewCustomer() {
    return router.push(`/check-in/paired/add-customer`, 'forward', 'pop')
  }

  async function handleCheckIn({
    phoneNumber,
    country,
  }: {
    phoneNumber: string
    country: CountryCode
  }) {
    const parsedNumber = parsePhoneNumberFromString(phoneNumber, country)
    if (!parsedNumber?.isValid())
      return setToast({
        message: 'You have entered an invalid phone number.',
        color: 'danger',
      })
    setisLoading(true)
    setCheckInMethod({ phone: parsedNumber.number.toString() })

    // CHECK IN E164
    try {
      const response = await getClientFromE164(parsedNumber.number)
      setClientInfo({ phoneNumber: parsedNumber.number as string })

      if (response.client) {
        optInClient.mutate(parsedNumber.number, {
          onSuccess: ({ client }) => {
            const phoneNumber = client.phoneNumbers?.length
              ? client.phoneNumbers[0].e164
              : ''
            setClientInfo({
              ...client,
              dobObject: {
                month: client.dobObject.month ?? null,
                day: client.dobObject.day ?? null,
              },
              phoneNumber,
            })
            checkInCustomer(client.objectId)
          },
        })
      } else {
        createNewCustomer()
      }
    } catch (error) {
      console.error(error)
      resetClientInfo()
      if (error instanceof Error) setError(error.message)
    } finally {
      setisLoading(false)
    }
  }

  return (
    <IonPage>
      <IonContent>
        <div
          style={{
            '--image-url': `url(${checkInSettings?.background?.image})`,
          }}
          className={cn('ion-padding h-full', {
            'bg-[image:var(--image-url)] bg-cover':
              checkInSettings?.background?.image,
          })}
        >
          <IonRow className="h-full">
            <IonCol
              size="6"
              className="flex h-full flex-1 flex-col items-center justify-center space-y-6"
            >
              <RotateInfoContainer>
                <IonButton
                  disabled={isLoading}
                  fill="solid"
                  color="secondary"
                  onClick={() =>
                    router.push(
                      `/check-in/paired/email-check-in`,
                      'none',
                      'replace'
                    )
                  }
                >
                  Check-In With Email
                </IonButton>

                <div className="w-full space-y-3 self-center text-center">
                  {checkInSettings?.body?.text ? (
                    <RotateInfoIntro>
                      {checkInSettings?.body?.text}
                    </RotateInfoIntro>
                  ) : (
                    <>
                      <RotateInfoIntro>
                        Welcome to {account.account.companyName}.
                      </RotateInfoIntro>
                      <RotateInfoIntro>
                        Enter your phone number to check-in!
                      </RotateInfoIntro>
                    </>
                  )}
                  <p className="text-ion-color-danger">{error}</p>
                </div>
                <RotateInfoLegal />
              </RotateInfoContainer>
            </IonCol>
            <IonCol
              size="6"
              className="flex h-full flex-1 flex-col items-center justify-center gap-6"
            >
              <PhoneKeypadInput
                disabled={isLoading}
                onConfirm={handleCheckIn}
                altLabel="Check-In"
              />
            </IonCol>
          </IonRow>
        </div>
      </IonContent>
    </IonPage>
  )
}

export { PairedPhoneCheckInPage }

import { IonIcon } from '@ionic/react'
import { image } from 'ionicons/icons'

import { PhotoData, usePhoto } from '../../hooks/use-photo'

type Props = {
  disabled?: boolean
  onSelectPhoto: (data: PhotoData) => void
  dark?: boolean
}

function SimpleSelectPhoto({ disabled, onSelectPhoto, dark = false }: Props) {
  const { selectPhotos } = usePhoto()

  return (
    <button
      type="button"
      className="size-8 ml-1 flex items-center justify-center bg-transparent disabled:opacity-50"
      onClick={() => selectPhotos(onSelectPhoto)}
      disabled={disabled}
    >
      <IonIcon
        className="size-full"
        color={dark ? 'primary' : 'secondary'}
        icon={image}
      />
    </button>
  )
}

export default SimpleSelectPhoto

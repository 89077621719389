import {
  IonPage,
  IonHeader,
  IonContent,
  useIonRouter,
  IonButton,
  IonCol,
  IonRow,
} from '@ionic/react'
import { FormProvider, useForm } from 'react-hook-form'

import { useCreateTemplate } from '../../../hooks/templates/mutations'
import AdminHeader from '../../../components/header/admin-header'
import AdminTemplateForm from '../../../components/templates/admin-template-form'
import { StickyHeader } from '../../../components/ui/sticky-header'

import type { CreateTemplateInput } from '../../../hooks/templates/mutations'

const defaultFormValues: CreateTemplateInput = {
  name: '',
  description: '',
  smsTemplate: { body: '', attachments: null },
  emailTemplate: { subject: '', body: '', attachments: null },
  letterTemplate: { body: '' },
  type: 'general',
  groupIds: [],
  shopWithIds: {},
}

function CreateStoreTemplate() {
  const router = useIonRouter()

  const createTemplate = useCreateTemplate()

  const methods = useForm<CreateTemplateInput>({
    defaultValues: defaultFormValues,
  })
  const { dirtyFields } = methods.formState
  const isDirty = Boolean(Object.keys(dirtyFields).length)

  function handleCreateSubmit(data: CreateTemplateInput) {
    createTemplate.mutate(data, {
      onSettled: () =>
        router.canGoBack()
          ? router.goBack()
          : router.push('/admin/templates', 'back', 'pop'),
    })
  }

  return (
    <IonPage>
      <IonHeader>
        <AdminHeader backRef="/admin/templates" title="Create Template" />
      </IonHeader>
      <IonContent>
        <section>
          <StickyHeader>
            <div className="ion-padding">
              <IonRow className="ion-justify-content-center">
                <IonCol size="auto">
                  <IonButton
                    disabled={!isDirty}
                    size="small"
                    color="yellow"
                    onClick={methods.handleSubmit(handleCreateSubmit)}
                  >
                    Save
                  </IonButton>
                </IonCol>
              </IonRow>
            </div>
          </StickyHeader>
          <div className="ion-padding-horizontal ion-padding-bottom">
            <FormProvider {...methods}>
              <AdminTemplateForm />
            </FormProvider>
          </div>
        </section>
      </IonContent>
    </IonPage>
  )
}

export default CreateStoreTemplate

import { IonCard } from '@ionic/react'
import { cn } from '../../../utils/cn'

function ShopWithCard({
  children,
  isSelected,
}: {
  children: React.ReactNode
  isSelected?: boolean
}) {
  return (
    <IonCard
      className={cn(
        'text-ion-color-primary m-0 flex flex-1 flex-col rounded-none',
        { 'shadow-shopwith': isSelected }
      )}
    >
      {children}
    </IonCard>
  )
}

export { ShopWithCard }

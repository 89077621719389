import { useRef } from 'react'

import { EditReceiptBoxForm } from './edit-receipt-box-form'
import { LoyaltyButton } from './loyalty-button'
import { LoyaltyModal } from './loyalty-modal'

import type { RewardSet } from '../loyalty.schema'

function EditReceiptBox({
  id,
  receipts,
}: {
  id: string
  receipts: RewardSet['receipts']
}) {
  const modalRef = useRef<HTMLIonModalElement>(null)

  return (
    <>
      <LoyaltyButton id={`open-modal-${id}`} color="secondary" fill="solid">
        Edit
      </LoyaltyButton>

      <LoyaltyModal ref={modalRef} trigger={`open-modal-${id}`}>
        <div className="bg-ion-color-step-100 w-80 pt-4">
          <h2 className="text-center">
            Edit Receipt{receipts.length > 1 ? 's' : ''}
          </h2>
          {receipts.map((receipt) => (
            <EditReceiptBoxForm
              key={receipt.objectId}
              receipt={receipt}
              onSubmit={() => modalRef.current?.dismiss()}
            />
          ))}
        </div>
        <div className="bg-ion-color-step-100 border-t border-white/20">
          <button
            onClick={() => modalRef.current?.dismiss()}
            type="button"
            className="text-ion-color-secondary w-full p-1"
          >
            Cancel
          </button>
        </div>
      </LoyaltyModal>
    </>
  )
}

export { EditReceiptBox }

import * as React from 'react'
import { IonCol, IonContent, IonPage, IonRow, useIonRouter } from '@ionic/react'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

import { cn } from '../../utils/cn'
import { useAccount } from '../../contexts/authContext'
import { useToast } from '../../contexts/toastContext'
import { PhoneKeypadInput } from '../../components/ui/phone-keypad-input'
import { useCheckInActions } from '../../check-in/check-in-store'
import { getClientFromE164 } from '../../rotate/api'
import { RotateInfoContainer } from '../../rotate/components/rotate-info-container'
import { RotateInfoIntro } from '../../rotate/components/rotate-info-intro'

import type { CountryCode } from 'libphonenumber-js'

function CustomerCheckInPage() {
  const [isLoading, setisLoading] = React.useState(false)
  const [error, setError] = React.useState<string | null>(null)

  const { resetClientInfo, setCheckInMethod, setClientInfo } =
    useCheckInActions()

  const account = useAccount()
  const checkInSettings = account.account.checkInSettings

  const router = useIonRouter()
  const setToast = useToast()

  async function handleCheckIn({
    phoneNumber,
    country,
  }: {
    phoneNumber: string
    country: CountryCode
  }) {
    const parsedNumber = parsePhoneNumberFromString(phoneNumber, country)

    if (!parsedNumber?.isValid())
      return setToast({
        message: 'You have entered an invalid phone number.',
        color: 'danger',
      })
    setCheckInMethod({ phone: parsedNumber.number.toString() })
    setisLoading(true)

    // CHECK IN E164
    try {
      const response = await getClientFromE164(parsedNumber.number)
      setClientInfo({ phoneNumber: parsedNumber.number as string })

      if (response.client)
        return router.push(
          `/rotate/customer/loyalty-info/${response.client.objectId}`,
          'forward',
          'pop'
        )

      return router.push(`/rotate/customer/add-customer`, 'forward', 'pop')
    } catch (error) {
      console.error(error)
      resetClientInfo()
      if (error instanceof Error) setError(error.message)
    } finally {
      setisLoading(false)
    }
  }

  return (
    <IonPage
      style={{
        '--image-url': `url(${checkInSettings?.background?.image})`,
      }}
    >
      <IonContent>
        <div
          className={cn('ion-padding h-full', {
            'bg-[image:var(--image-url)] bg-cover':
              checkInSettings?.background?.image,
          })}
        >
          <IonRow className="h-full">
            <IonCol
              size="6"
              className="flex h-full flex-1 flex-col items-center justify-center space-y-6"
            >
              <RotateInfoContainer>
                <div></div>
                <div className="space-y-3 self-center text-center">
                  {checkInSettings?.body?.text ? (
                    <RotateInfoIntro>
                      {checkInSettings?.body?.text}
                    </RotateInfoIntro>
                  ) : (
                    <>
                      <RotateInfoIntro>
                        Welcome to {account.account.companyName}.
                      </RotateInfoIntro>
                      <RotateInfoIntro>
                        Enter your phone number to check-in!
                      </RotateInfoIntro>
                    </>
                  )}
                </div>
                <p className="text-ion-color-danger">{error}</p>
              </RotateInfoContainer>
            </IonCol>
            <IonCol
              size="6"
              className="flex h-full flex-1 flex-col items-center justify-center gap-6"
            >
              <PhoneKeypadInput
                disabled={isLoading}
                onConfirm={handleCheckIn}
                altLabel="Check-In"
              />
            </IonCol>
          </IonRow>
        </div>
      </IonContent>
    </IonPage>
  )
}

export { CustomerCheckInPage }

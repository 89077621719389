import * as React from 'react'
import {
  IonContent,
  IonHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLabel,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonSkeletonText,
  useIonViewWillEnter,
} from '@ionic/react'
import { Redirect } from 'react-router-dom'
import { format, parseISO } from 'date-fns'
import clsx from 'clsx'

import { typedKeys } from '../../../utils/typescript-helpers'
import { useAccount } from '../../../contexts/authContext'
import { useInfiniteData } from '../../../hooks/use-infinite-data'
import AdminHeader from '../../../components/header/admin-header'
import {
  ListItem,
  ListItemHeading,
  ListItemSubHeading,
} from '../../../components/ui/list-item'
import { useAutomations } from '../../../automations/queries'
import { automationTemplates } from '../../../automations/templates'

import type { Automation } from '../../../automations/automations-base.schema'

function getTextColorClass(status: Automation['state']) {
  if (status === 'draft') return 'text-ion-color-medium'
  if (status === 'stopped') return 'text-ion-color-danger'
  return 'text-ion-color-yellow'
}

function AutomationsListPage() {
  const [currentSegment, setCurrentSegment] = React.useState('list')

  const account = useAccount()

  const automationsQuery = useAutomations()
  const automations = automationsQuery.data?.pages.flatMap((page) => page.data)

  const [ionInfiniteScrollRef, loadMoreAutomations] = useInfiniteData(
    automationsQuery.fetchNextPage
  )

  useIonViewWillEnter(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const tab = queryParams.get('tab')

    switch (tab) {
      case 'list':
        setCurrentSegment('list')
        break
      case 'templates':
        setCurrentSegment('templates')
        break
      default:
        setCurrentSegment('list')
        break
    }
  })

  if (!account.isParentGroupAdmin) return <Redirect to="/access-denied" />

  return (
    <IonPage>
      <IonHeader>
        <AdminHeader showMenu title="Automations" />
      </IonHeader>
      <IonContent className="ion-padding">
        <div className="flex flex-col">
          <div className="ion-padding-horizontal mx-auto w-full max-w-xl">
            <IonSegment
              value={currentSegment}
              onIonChange={(e) => setCurrentSegment(e.detail.value!)}
            >
              <IonSegmentButton value="list">List</IonSegmentButton>
              <IonSegmentButton value="templates">Templates</IonSegmentButton>
            </IonSegment>
          </div>
          {currentSegment === 'list' ? (
            <>
              {automations ? (
                <>
                  {automations.length ? (
                    automations.map((automationItem) => (
                      <ListItem
                        key={automationItem.objectId}
                        routerLink={`/admin/automations/detail/${automationItem.objectId}`}
                      >
                        <IonLabel>
                          <ListItemHeading>
                            {automationItem.title}
                          </ListItemHeading>
                          <ListItemSubHeading
                            className={clsx(
                              'capitalize',
                              getTextColorClass(automationItem.state)
                            )}
                          >
                            {automationItem.state}
                          </ListItemSubHeading>
                          <ListItemSubHeading>
                            {automationItem.state === 'draft'
                              ? 'Created On: '
                              : 'Started: '}
                            {format(
                              parseISO(automationItem.createdAt),
                              'MM/dd/yy'
                            )}
                          </ListItemSubHeading>
                          {automationItem.updatedAt ? (
                            <ListItemSubHeading>
                              Last Edited:{' '}
                              {format(
                                parseISO(automationItem.updatedAt),
                                'MM/dd/yy'
                              )}
                            </ListItemSubHeading>
                          ) : null}
                        </IonLabel>
                      </ListItem>
                    ))
                  ) : (
                    <div className="mt-24 flex flex-1 flex-col items-center justify-center">
                      <div className="bg-ion-color-secondary max-w-md rounded px-6 py-4">
                        <p className="text-ion-color-primary text-xl">
                          You currently have no automations created. Check out
                          the templates to get started!
                        </p>
                      </div>
                    </div>
                  )}
                  <IonInfiniteScroll
                    ref={ionInfiniteScrollRef}
                    onIonInfinite={loadMoreAutomations}
                    threshold="100px"
                    disabled={!automationsQuery.hasNextPage}
                  >
                    <IonInfiniteScrollContent
                      loadingSpinner="bubbles"
                      loadingText="loading more posts..."
                    />
                  </IonInfiniteScroll>
                </>
              ) : (
                Array(15)
                  .fill(null)
                  .map((_item, idx) => <AutomationItemSkeleton key={idx} />)
              )}
            </>
          ) : null}
          {currentSegment === 'templates' ? (
            <>
              {typedKeys(automationTemplates).map((key) => (
                <AutomationTemplateItem
                  key={key}
                  link={`/admin/automations/create/${key}`}
                >
                  {automationTemplates[key].title}
                </AutomationTemplateItem>
              ))}
              <div className="mt-6 flex flex-col items-center justify-center">
                <p className="text-xl">
                  More automation templates coming soon!
                </p>
              </div>
            </>
          ) : null}
        </div>
      </IonContent>
    </IonPage>
  )
}

function AutomationTemplateItem({
  children,
  link,
}: {
  children: React.ReactNode
  link: string
}) {
  return (
    <ListItem routerLink={link}>
      <IonLabel>
        <ListItemHeading>{children}</ListItemHeading>
      </IonLabel>
    </ListItem>
  )
}

function AutomationItemSkeleton() {
  return (
    <ListItem>
      <IonLabel>
        <h3 className="w-8/12">
          <IonSkeletonText animated className="m-0 w-full leading-normal" />
        </h3>

        <p className="w-2/5">
          <IonSkeletonText animated className="m-0 w-full leading-normal" />
        </p>

        <p className="w-2/5">
          <IonSkeletonText animated className="m-0 w-full leading-normal" />
        </p>
      </IonLabel>
    </ListItem>
  )
}

export default AutomationsListPage

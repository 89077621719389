/* eslint-disable react/jsx-pascal-case */
import * as React from 'react'
import {
  IonCard,
  IonCardContent,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonSelect,
  IonSelectOption,
  isPlatform,
  useIonAlert,
} from '@ionic/react'
import { read, utils } from 'xlsx'
import { useImmer } from 'use-immer'
import { klona } from 'klona/json'

import { updateService } from '../services/updateService'
import { useUser } from '../contexts/authContext'
import { useToast } from '../contexts/toastContext'
import { useAdminGroups } from '../hooks/queries/use-associate'
import Header from '../components/header'
import { ClientImportTable } from '../admin/client-import/client-import-table'
import { isDataValid, isRowValid } from '../admin/client-import/validation'

import type { ClientImport as TClientImport } from '../admin/client-import/columns'

const DEFAULT_WKBK_DATA: TClientImport = {
  'First Name': '',
  'Last Name': '',
  Email: '',
  Phone: '',
  Tag: '',
  Note: '',
  'Loyalty Points': '',
  'Loyalty Visits': '',
}

const initialState = {
  optIn: false,
  createCallList: false,
  selectedGroupId: '',
}

function ClientImport() {
  const ref = React.useRef<HTMLInputElement>(null)

  const [isLoading, setIsLoading] = React.useState(false)

  const [data, updateData] = useImmer<Array<TClientImport>>([])

  const [formState, updateFormState] = useImmer(initialState)
  const [present] = useIonAlert()

  const user = useUser()
  const setToast = useToast()

  const groupsQuery = useAdminGroups()

  const isSaveDisabled = Boolean(isLoading || !formState.selectedGroupId)

  React.useEffect(() => {
    if (!groupsQuery.data) return

    if (groupsQuery.data.length === 1) {
      updateFormState((draft) => {
        draft.selectedGroupId = groupsQuery.data[0].objectId
      })
    }
  }, [groupsQuery.data, updateFormState])

  function handleFileChange(e: React.FormEvent<HTMLInputElement>) {
    if (!e.currentTarget.files) return

    const file = e.currentTarget.files[0]

    if (file) {
      const reader = new FileReader()
      reader.onload = (evt) => {
        const bstr = evt.target?.result
        const workbook = read(bstr, { type: 'binary' })

        const ws = workbook.Sheets[workbook.SheetNames[0]] // get the first worksheet
        const xlsxData: Array<Partial<TClientImport>> =
          utils.sheet_to_json<Partial<TClientImport>>(ws)

        const cleanedData = xlsxData.map((importData) => ({
          ...DEFAULT_WKBK_DATA,
          ...importData,
        }))
        updateData((draft) => cleanedData.concat(draft))
      }

      reader.readAsBinaryString(file)
    }
  }

  async function handleSaveTable() {
    if (!isDataValid(data))
      return present({
        backdropDismiss: false,
        message: 'Please fix the errors and try again.',
        buttons: [
          {
            text: 'OK',
            handler: () => {
              const sortedData = klona(data).sort((a, b) =>
                isRowValid(a).isValid === isRowValid(b).isValid
                  ? 0
                  : isRowValid(a).isValid
                  ? 1
                  : -1
              )

              updateData(sortedData)
            },
          },
        ],
      })

    try {
      setIsLoading(true)
      const filename =
        ref.current?.files && ref.current.files[0]
          ? ref.current.files[0].name
          : null

      await updateService.importClients({
        groupId: formState.selectedGroupId,
        clients: data.filter((row) => Object.keys(row).length),
        optIn: formState.optIn,
        createCallList: formState.createCallList,
        filename,
      })
      updateData([])
      // Clear input file name
      if (ref.current?.value) {
        ref.current.value = ''
      }
      setToast({
        message: `Clients are saving${
          user?.email
            ? ', a confirmation email will be sent to ' + user.email
            : ''
        }`,
        color: 'yellow',
      })
    } catch (error) {
      setToast({
        message: 'Oops, something went wrong',
        color: 'danger',
      })
    } finally {
      setIsLoading(false)
    }
  }

  function handleOptIn(checked: boolean) {
    updateFormState((draft) => {
      draft.optIn = checked
    })
    if (!formState.optIn) {
      present({
        message:
          'You are about to automatically opt-in clients. By clicking "Accept" you acknowledge that you have their written permission to do so.',
        buttons: [
          {
            text: 'Cancel',
            handler: () =>
              updateFormState((draft) => {
                draft.optIn = false
              }),
            role: 'cancel',
          },
          {
            text: 'Accept',
            handler: () =>
              updateFormState((draft) => {
                draft.optIn = true
              }),
          },
        ],
      })
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <Header title="Imports" />
      </IonHeader>
      <IonContent className="ion-padding">
        <IonCard
          color="secondary"
          className="ion-no-margin ion-margin-vertical"
        >
          <IonCardContent color="secondary">
            <IonItem color="secondary">
              {!isPlatform('capacitor') ? (
                <input
                  type="file"
                  onChange={handleFileChange}
                  accept=".xlsx"
                  ref={ref}
                />
              ) : null}
            </IonItem>

            <IonItem color="secondary">
              <IonLabel>Store Location (* required)</IonLabel>
              <IonSelect
                color="secondary"
                interfaceOptions={{ cssClass: 'dark-ionic-filter' }}
                onIonChange={(e) =>
                  updateFormState((draft) => {
                    draft.selectedGroupId = e.detail.value
                  })
                }
                value={formState.selectedGroupId}
              >
                {groupsQuery.data?.map((item) => (
                  <IonSelectOption key={item.objectId} value={item.objectId}>
                    {item.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
            <IonItem color="secondary">
              <IonLabel>Opt In Clients</IonLabel>
              <IonCheckbox
                checked={formState.optIn}
                onIonChange={(e) => {
                  handleOptIn(e.detail.checked)
                }}
              />
            </IonItem>
            <IonItem color="secondary">
              <IonLabel>Create Outreach</IonLabel>
              <IonCheckbox
                checked={formState.createCallList}
                onIonChange={(e) =>
                  updateFormState((draft) => {
                    draft.createCallList = e.detail.checked
                  })
                }
              />
            </IonItem>
          </IonCardContent>
        </IonCard>
        <ClientImportTable
          data={data}
          disabled={isSaveDisabled}
          onClientAdd={(user) =>
            updateData((draft) => void draft.unshift(user))
          }
          onRowDelete={(id) => {
            updateData((draft) => {
              draft.splice(id, 1)
            })
          }}
          onRowSave={({ exitEditingMode, row, values }) => {
            updateData((draft) => {
              draft[row.index] = values
            })
            exitEditingMode()
          }}
          onTableSave={handleSaveTable}
        />
      </IonContent>
    </IonPage>
  )
}

export default ClientImport

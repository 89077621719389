import { IonCard, IonCardContent } from '@ionic/react'

function MessageCard({ children }: { children: React.ReactNode }) {
  return (
    <IonCard>
      <IonCardContent>
        <p className="text-ion-color-dark">{children}</p>
      </IonCardContent>
    </IonCard>
  )
}

export { MessageCard }

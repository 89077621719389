import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useIonAlert } from '@ionic/react'
import { format } from 'date-fns'

import { isStringArray } from '../../utils/typescript-helpers'
import { client } from '../../services/client'
import { useToast } from '../../contexts/toastContext'
import { templateQueryKeys } from './queries'
import type { Group, IsoDate, ProfileBase } from '../../types/general'
import type { ShopWithPointers, Template, TemplateAttachments } from './queries'

export type CreateTemplateInput = Omit<
  CreateTemplate,
  'objectId' | 'createdAt' | 'groups' | 'user'
> & { groupIds: Array<string>; shopWithIds: Partial<ShopWithIds> }

export type UpdateTemplateInput = {
  objectId: string
  template: CreateTemplateInput
}

export type DeleteTemplateInput = Pick<Template, 'objectId'>

export type ShopWithIds = {
  productIds: Array<string>
  lookIds: Array<string>
  collectionIds: Array<string>
}

type SMSCreateTemplate = Omit<
  NonNullable<Template['smsTemplate']>,
  'objectId' | 'name' | 'attachments' | keyof ShopWithPointers
> & { attachments: TemplateAttachments }

type EmailCreateTemplate = Omit<
  NonNullable<Template['emailTemplate']>,
  'objectId' | 'name' | 'attachments' | keyof ShopWithPointers
> & { attachments: TemplateAttachments }

type LetterCreateTemplate = Omit<
  NonNullable<Template['letterTemplate']>,
  'objectId' | 'name' | keyof ShopWithPointers
>

type CreateTemplateBase = {
  objectId: string
  name: string | null
  description: string | null
  type: Template['type'] | null
  createdAt: IsoDate
  smsTemplate: SMSCreateTemplate
  emailTemplate: EmailCreateTemplate
  letterTemplate: LetterCreateTemplate
}

type UserCreateTemplate = CreateTemplateBase & {
  user: ProfileBase
  groups: null
}

type StoreCreateTemplate = CreateTemplateBase & {
  user: ProfileBase | null
  groups: Array<Group> | null
}

export type CreateTemplate = UserCreateTemplate | StoreCreateTemplate

// helper to do photo conversions for create and update
function convertPhotoForAPI(photos: TemplateAttachments) {
  if (!photos) return null

  if (isStringArray(photos)) {
    return photos
  }

  return photos.map((photo) => ({
    name: photo.name,
    content: photo.base64String,
  }))
}

async function createTemplate(newTemplate: CreateTemplateInput) {
  const { shopWithIds, smsTemplate, emailTemplate, ...templateData } =
    newTemplate

  const name = templateData.name || format(Date.now(), 'MMM dd, yy')

  client
    .post('functions/v2_createTemplate', {
      json: {
        ...templateData,
        name,
        smsTemplate: {
          ...smsTemplate,
          attachments: convertPhotoForAPI(smsTemplate.attachments),
        },
        emailTemplate: {
          ...emailTemplate,
          attachments: convertPhotoForAPI(emailTemplate.attachments),
        },
        ...shopWithIds,
      },
    })
    .json()
}

async function updateTemplate({ objectId, template }: UpdateTemplateInput) {
  const { shopWithIds, smsTemplate, emailTemplate, ...templateData } = template

  const name = templateData.name || format(Date.now(), 'MMM dd, yy')

  await client
    .post('functions/v2_updateTemplate', {
      json: {
        objectId,
        ...templateData,
        name,
        smsTemplate: {
          ...smsTemplate,
          attachments: convertPhotoForAPI(smsTemplate.attachments),
        },
        emailTemplate: {
          ...emailTemplate,
          attachments: convertPhotoForAPI(emailTemplate.attachments),
        },
        ...shopWithIds,
      },
    })
    .json()
}

async function deleteTemplate({ objectId }: DeleteTemplateInput) {
  return client.post('functions/v2_deleteTemplate', { json: { objectId } })
}

function useCreateTemplate() {
  const queryClient = useQueryClient()
  const setToast = useToast()

  return useMutation(createTemplate, {
    onSettled: () => {
      queryClient.invalidateQueries(templateQueryKeys.list('all'))
    },
    onError: (err, variables) => {
      const errorMessage = err instanceof Error ? err.message : 'Unknown Error.'

      setToast({
        message: `Something went wrong while creating ${variables.name}. Please try again later.\n\n${errorMessage}`,
        color: 'danger',
      })
    },
  })
}

function useUpdateTemplate() {
  const queryClient = useQueryClient()
  const setToast = useToast()

  return useMutation(updateTemplate, {
    onSettled: () => {
      queryClient.invalidateQueries(templateQueryKeys.all)
    },
    onError: (err, variables) => {
      const errorMessage = err instanceof Error ? err.message : 'Unknown Error.'

      setToast({
        message: `Something went wrong while updating ${variables.template.name}. Please try again later.\n\n${errorMessage}`,
        color: 'danger',
      })
    },
  })
}

function useDeleteTemplate() {
  const queryClient = useQueryClient()
  const setToast = useToast()

  return useMutation(deleteTemplate, {
    onMutate: (variables: DeleteTemplateInput) => {
      const previousTemplates = queryClient.getQueryData<Array<Template>>(
        templateQueryKeys.list('all')
      )

      if (previousTemplates) {
        queryClient.setQueryData<Array<Template>>(
          templateQueryKeys.list('all'),
          previousTemplates.filter(
            (template) => template.objectId !== variables.objectId
          )
        )
      }

      return { previousTemplates }
    },
    onSettled: () => {
      queryClient.invalidateQueries(templateQueryKeys.list('all'))
    },
    onError: (err, _variables, context) => {
      const errorMessage = err instanceof Error ? err.message : 'Unknown Error.'

      if (context?.previousTemplates) {
        queryClient.setQueryData<Array<Template>>(
          templateQueryKeys.list('all'),
          context?.previousTemplates
        )
      }
      setToast({
        message: `Something went wrong while deleting this template. Please try again later.\n\n${errorMessage}`,
        color: 'danger',
      })
    },
  })
}

function useTemplateDeleteWarning() {
  const [present] = useIonAlert()

  function showDeleteWarning(templateName: string, handler: () => void) {
    return present({
      header: 'Warning',
      message: `You are about to delete ${templateName}. This action cannot be undone. Are you sure?`,
      buttons: [
        'Cancel',
        {
          text: 'Delete',
          role: 'destructive',
          handler,
        },
      ],
    })
  }

  return { showDeleteWarning }
}
export {
  useCreateTemplate,
  useUpdateTemplate,
  useDeleteTemplate,
  useTemplateDeleteWarning,
}

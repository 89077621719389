import { z } from 'zod'

import type { Editor } from 'react-email-editor'
import { nullableString } from '../utils/schema'

type UnlayerTemplate = Partial<{
  html: string
  json: UnlayerJSONTemplate
  img: string
}>
type UnlayerJSONTemplate = Parameters<Editor['loadDesign']>[0]
type ServerUnlayerDesign = z.infer<typeof serverUnlayerDesignSchema>
type DisplayConditions = z.infer<typeof displayConditionsSchema>

const displayConditionsSchema = z.object({
  user: nullableString,
})

const bodyItemSchema = z.array(
  z.object({
    id: z.string(),
    cells: z.array(z.number()),
    columns: z.array(
      z.object({
        id: z.string(),
        contents: z.array(z.any()),
        values: z.object({}),
      })
    ),
    values: z.object({}),
  })
)
// Known issue: https://github.com/colinhacks/zod/issues/2464
// z.undefined() marks field as optional, transform as workaround
const unlayerDesignSchema = z.any()
const serverUnlayerDesignSchema = z.any()

const unlayerDesignSchema_v16 = z.object({
  counters: z.record(z.number()),
  body: z
    .object({
      id: z.string().optional(),
      rows: bodyItemSchema,
      headers: bodyItemSchema,
      footers: bodyItemSchema,
      values: z.object({}),
    })
    .transform((o) => ({
      ...o,
      id: o.id,
    })),
  schemaVersion: z.union([z.number(), z.undefined()]).optional(),
})
const serverUnlayerDesignSchema_v16 = z.object({
  counters: z.record(z.number()),
  body: z
    .object({
      id: z.string().optional(),
      rows: bodyItemSchema,
      headers: bodyItemSchema.optional(),
      footers: bodyItemSchema.optional(),
      values: z.object({}),
    })
    .transform((o) => ({
      ...o,
      id: o.id,
    })),
  schemaVersion: z.union([z.number(), z.undefined()]).optional(),
})

const unlayerDesignSchema_LEGACY = z.object({
  counters: z.union([z.any(), z.undefined()]), //.optional(),
  body: z.object({
    rows: z.array(z.any()),
    values: z.union([z.any(), z.undefined()]).optional(),
  }),
})

const unlayerTemplateSchema = z
  .object({
    html: z.string(),
    json: unlayerDesignSchema,
    img: z.string(),
  })
  .partial()

const unlayerTemplateRequestSchema = z.discriminatedUnion('success', [
  z.object({
    success: z.literal(true),
    data: z.object({
      id: z.number(),
      createdAt: z.string(),
      design: unlayerDesignSchema,
      displayMode: z.string(),
      name: z.string(),
      updatedAt: z.string(),
    }),
  }),
  z.object({ success: z.literal(false), error: z.string() }),
])

export type {
  DisplayConditions,
  ServerUnlayerDesign,
  UnlayerJSONTemplate,
  UnlayerTemplate,
}
export {
  serverUnlayerDesignSchema,
  unlayerDesignSchema,
  unlayerTemplateRequestSchema,
}

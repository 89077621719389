import {
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonPage,
} from '@ionic/react'

import Header from '../../components/header'

import { CreateAlterationForm } from '../../tasks/components/create-alteration-form'
import { useCurrentClient } from '../../contexts/clientContext'

function CreateAlterationPage() {
  const currentClient = useCurrentClient()

  return (
    <IonPage>
      <IonHeader>
        <Header showMenu btn={null} title="Create Alteration" />
      </IonHeader>
      <IonContent>
        <IonCard>
          <IonCardContent>
            <CreateAlterationForm client={currentClient} />
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  )
}

export { CreateAlterationPage }

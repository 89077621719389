import { IonImg } from '@ionic/react'
import { format, parseISO } from 'date-fns'
import { fill } from '@cloudinary/url-gen/actions/resize'
import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity'
import clsx from 'clsx'
import { ResourceType } from '@capawesome/capacitor-cloudinary'

import { oneshopCld } from '../../utils/cloudinary'
import CldVideoThumbnail from '../cloudinary/cld-video-thumbnail'
import {
  ShopWithCard,
  ShopWithCardContent,
  ShopWithCardFooter,
  ShopWithCardLineItem,
} from './card'
import CollageItem from './collage-item'
import { ItemButton, ItemFooterLink } from './ui'

import type { Look } from '../../types/shopwith'

type LookItemProps = {
  look: Look
  onLookSelect: (key: 'looks', id: string) => void
  isSelected: boolean
  shouldHideDetail?: boolean
}

function LookItem({
  look,
  onLookSelect,
  isSelected,
  shouldHideDetail,
}: LookItemProps) {
  const createdAt = format(parseISO(look.createdAt), 'MM/dd/yy')

  const cldImage = oneshopCld
    .video(look.cloudinary?.data?.public_id)
    .resize(fill().aspectRatio('3:4').gravity(autoGravity()))
    .quality('auto:good')
    .format('auto:image')

  function handleLookSelect() {
    onLookSelect('looks', look.objectId)
  }

  return (
    <ShopWithCard isSelected={isSelected}>
      <ItemButton onClick={handleLookSelect}>
        <ShopWithCardContent>
          {look.cloudinary?.data?.resource_type === ResourceType.Video ? (
            <div className="aspect-h-4 aspect-w-3 w-full">
              <CldVideoThumbnail cldImage={cldImage} />
            </div>
          ) : (
            <div className="aspect-h-4 aspect-w-3 w-full">
              {look.coverPhoto ? (
                <IonImg
                  src={look.coverPhoto.originalSrc}
                  className="object-cover object-top"
                />
              ) : (
                <CollageItem items={look.products} />
              )}
            </div>
          )}

          <div className="mt-2">
            {look.title && (
              <ShopWithCardLineItem bold>{look.title}</ShopWithCardLineItem>
            )}
            <ShopWithCardLineItem>
              Date Created: {createdAt}
            </ShopWithCardLineItem>
            {look.products ? (
              <ShopWithCardLineItem bold>
                Products: {look.products.length}
              </ShopWithCardLineItem>
            ) : null}
          </div>
        </ShopWithCardContent>
      </ItemButton>

      {shouldHideDetail !== true && (
        <ShopWithCardFooter>
          <ItemFooterLink to={`/tabs/shopwith/look/detail/${look.objectId}`}>
            View Detail
          </ItemFooterLink>
        </ShopWithCardFooter>
      )}
    </ShopWithCard>
  )
}

export default LookItem

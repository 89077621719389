import { Clipboard } from '@capacitor/clipboard'

import { useToast } from '../contexts/toastContext'

function useClipboard() {
  const setToast = useToast()

  async function copyToClipboard(str: string, message: string) {
    try {
      await Clipboard.write({ string: str })
      setToast({ message, duration: 2000, position: 'bottom' })
    } catch {
      setToast({
        message: 'Something went wrong copying to the clipboard.',
        color: 'danger',
      })
    }
  }

  return { copyToClipboard }
}

export { useClipboard }

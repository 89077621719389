import { IonCard, IonCardContent } from '@ionic/react'
import { format, parseISO } from 'date-fns'

import { useIsStore } from '../../auth/utils'
import Currency from '../Currency'

export default function Item({ item }) {
  const { isTristan } = useIsStore()
  const date = item.date ? format(parseISO(item.date.iso), 'MM/dd/yy') : null

  return (
    <IonCard
      style={{ flex: 1, borderRadius: 0 }}
      color="secondary"
      className="ion-no-margin item-card"
    >
      <IonCardContent>
        <div style={{ position: 'relative' }}>
          <div style={{ height: 0, paddingBottom: '166%' }} />
          <img
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              height: '100%',
              objectFit: 'contain',
              objectPosition: 'top',
            }}
            src={item.posPhotoUrls[0]}
            alt=""
          />
        </div>

        {date && (
          <p>
            <strong>DATE: </strong>
            {date}
          </p>
        )}
        {item.brand && (
          <p>
            <strong>BRAND: </strong>
            {item.brand}
          </p>
        )}
        {isTristan() && (
          <p>
            <strong>STYLE CODE: </strong>
            {item.posStyle ?? 'N/A'}
          </p>
        )}
        {item.value && (
          <p>
            <strong>PRICE: </strong>
            <Currency value={item.value} />
          </p>
        )}
        {item.size && (
          <p>
            <strong>SIZE: </strong>
            {item.size}
          </p>
        )}
        <p>
          <strong>SOLD BY: </strong>
          {item.posSellerName ?? 'N/A'}
        </p>
        <p>
          <strong>SOLD AT: </strong>
          {item.group?.name ?? 'N/A'}
        </p>
        {isTristan() && (
          <p>
            <strong>TRANSACTION: </strong>
            {item.posId1?.indexOf('_') > 0
              ? item.posId1.slice(0, item.posId1.indexOf('_'))
              : item.posId1 ?? 'N/A'}
          </p>
        )}
      </IonCardContent>
    </IonCard>
  )
}

type Props = { children: React.ReactNode }

function Wrapper({ children }: Props) {
  return (
    <div className="flex h-[66px] items-center justify-center gap-6">
      {children}
    </div>
  )
}

function CardWrapper({ children }: Props) {
  return <div className="flex h-full w-52 py-2">{children}</div>
}

function ButtonWrapper({ children }: Props) {
  return <div className="flex h-full py-2">{children}</div>
}

function Divider() {
  return <div className="h-0.5 w-full bg-ion-color-secondary" />
}

export { ButtonWrapper, CardWrapper, Divider, Wrapper }

import { IonPage, IonContent, IonHeader, IonLoading } from '@ionic/react'

import { useRecentSalesList } from '../../hooks/outreach/queries'
import AddFab from '../../components/fab/add-fab'
import Header from '../../components/header'
import { StickyHeader } from '../../components/ui/sticky-header'
import { useIsStore } from '../../auth/utils'
import {
  ListPageClientList,
  ListPageDetail,
  ListPageFilters,
  ListPageHeader,
  ListPageSegment,
} from '../../clienteling/list-page-detail'

function RecentSalesListPage() {
  const { isQ } = useIsStore()

  const recentSalesListQuery = useRecentSalesList()

  const title = isQ() ? 'Recent Sales - Thank You Note Needed' : 'Recent Sales'
  const description =
    'Clients assigned to you that have purchased in the last 7 days (from you or from someone else).'

  return (
    <IonPage>
      <AddFab />
      <IonHeader>
        <Header backRef="/tabs/clients" title="Outreach" />
      </IonHeader>

      <IonContent>
        {recentSalesListQuery.data ? (
          <ListPageDetail clientList={recentSalesListQuery.data.clients}>
            <ListPageHeader title={title} description={description} />
            <StickyHeader>
              <ListPageSegment />
              <ListPageFilters />
              <ListPageClientList />
            </StickyHeader>
          </ListPageDetail>
        ) : (
          <IonLoading isOpen message={'Loading the client list...'} />
        )}
      </IonContent>
    </IonPage>
  )
}

export { RecentSalesListPage }

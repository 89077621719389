import { useQuery } from '@tanstack/react-query'

import { strToTitleCase } from '../../utils/format-helpers'
import { client } from '../../services/client'
import { useToast } from '../../contexts/toastContext'

import type { QueryContextFromKeys } from '../../utils/react-query'
import type { FetchSelectFilterResponse, SelectFilterParams } from './types'

type ColorQueryContexts = QueryContextFromKeys<typeof colorQueryKeys>

const colorQueryKeys = {
  all: [{ entity: 'colors' }] as const,
  lists: () => [{ ...colorQueryKeys.all[0], scope: 'list' }] as const,
  list: ({ admin }: Pick<SelectFilterParams, 'admin'>) =>
    [{ ...colorQueryKeys.lists()[0], admin }] as const,
}

async function fetchColors({
  queryKey: [{ admin }],
}: ColorQueryContexts['list']) {
  const response: FetchSelectFilterResponse = await client
    .post('functions/colors', {
      json: admin ? { filter: { admin } } : undefined,
    })
    .json()

  return response.result
}

function selectColors(data: Awaited<ReturnType<typeof fetchColors>>) {
  return [...Array.from(new Set(data.map((item) => item.toLowerCase())))]
    .map((item) => strToTitleCase(item))
    .sort((a, b) =>
      a.localeCompare(b, undefined, {
        numeric: true,
        ignorePunctuation: true,
      })
    )
}

function useColors({ admin, enabled }: SelectFilterParams) {
  const setToast = useToast()

  return useQuery({
    queryKey: colorQueryKeys.list({ admin }),
    queryFn: fetchColors,
    enabled,
    refetchOnWindowFocus: false,
    retry: false,
    select: selectColors,
    onError: (error) => {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown Error.'

      setToast({
        message: `Something went wrong while loading colors: ${errorMessage}`,
        color: 'danger',
      })
    },
  })
}

export { useColors }

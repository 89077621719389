import * as React from 'react'

import { useColors } from '../../../hooks/queries/use-colors'
import SearchableSelect from '../../ui/form/searchable-select'

import type { SelectProps } from './types'

function ColorSelect({
  isAdmin,
  selectedItems,
  setSelectedItems,
}: SelectProps) {
  const [isOpen, setIsOpen] = React.useState(false)

  const colorsQuery = useColors({ admin: isAdmin, enabled: isOpen })

  return (
    <SearchableSelect
      onOpen={() => setIsOpen(true)}
      label="Colors"
      items={colorsQuery.data ?? []}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
    />
  )
}

export default ColorSelect

import { z } from 'zod'

import { client } from '../services/client'
import { assertUnreachable } from '../utils/typescript-helpers'

type FormData = z.input<typeof formDataSchema>
type AdjustmentInput = Omit<z.input<typeof adjustmentInputSchema>, 'objectId'>
type DeleteAdjustment = Pick<
  z.input<typeof adjustmentInputSchema>,
  'clientId' | 'objectId'
>
type UpdateAdjustment = Omit<
  z.input<typeof adjustmentInputSchema>,
  'loyaltyRuleId'
>
type LoyaltyPointInput = z.input<typeof loyaltyInputSchema>
type ReceiptInput = z.input<typeof receiptInputSchema>

const formDataSchema = z.object({
  visits: z
    .string()
    .min(1, { message: 'This field is required.' })
    .transform((data) => parseFloat(data))
    .or(z.number().positive({ message: 'Visits must be a positive number.' })),
  value: z
    .string()
    .min(1, { message: 'This field is required.' })
    .transform((data) => parseFloat(data))
    .or(z.number()),
  posId: z.string().transform((data) => (data === '' ? null : data)),
})

const adjustmentInputSchema = formDataSchema.pick({ value: true }).merge(
  z.object({
    clientId: z.string(),
    loyaltyRuleId: z.string(),
    objectId: z.string(),
  })
)

const loyaltyInputSchema = formDataSchema.omit({ posId: true }).merge(
  z.object({
    type: z.literal('loyaltyPointInput'),
    clientId: z.string(),
  })
)
const receiptInputSchema = formDataSchema.omit({ visits: true }).merge(
  z.object({
    type: z.literal('receiptInput'),
    clientId: z.string(),
    groupId: z.string(),
  })
)

async function createAdjustment(params: AdjustmentInput) {
  const { clientId, loyaltyRuleId, value } = params

  return client
    .post('functions/createLoyaltyAdjustment', {
      json: {
        value,
        clientId,
        loyaltyRuleId,
      },
    })
    .json()
}

async function createReceipt({
  clientId,
  groupId,
  posId,
  value,
}: ReceiptInput) {
  const response = await client
    .post('functions/createReceipt', {
      json: {
        clientId,
        groupId,
        posId,
        value,
      },
    })
    .json()

  return response
}

async function createLoyaltyPoint({
  clientId,
  value,
  visits,
}: LoyaltyPointInput) {
  const response = await client
    .post('functions/createLoyaltyPoint', {
      json: { clientId, value, visits },
    })
    .json()

  return response
}

async function createSale(params: {
  clientId: string
  finalValue: number
  posId1: string | null
}) {
  const { clientId, finalValue, posId1 } = params
  return client
    .post('functions/createSale', {
      json: {
        clientId,
        finalValue,
        posId1,
      },
    })
    .json()
}

async function deleteAdjustment(params: DeleteAdjustment) {
  const { objectId } = params

  return client
    .post('functions/deleteLoyaltyAdjustment', {
      json: {
        objectId,
      },
    })
    .json()
}

async function deleteLoyaltyPoint({ objectId }: { objectId: string }) {
  return client
    .post('functions/deleteLoyaltyPoint', { json: { objectId } })
    .json()
}

async function deleteReceipt({ objectId }: { objectId: string }) {
  return client.post('functions/deleteReceipt', { json: { objectId } }).json()
}

async function logPoints(input: LoyaltyPointInput | ReceiptInput) {
  switch (input.type) {
    case 'loyaltyPointInput':
      return createLoyaltyPoint(input)
    case 'receiptInput':
      return createReceipt(input)
    default:
      assertUnreachable(input)
  }
}

async function updateAdjustment(params: UpdateAdjustment) {
  const { objectId, value } = params

  return client
    .post('functions/updateLoyaltyAdjustment', {
      json: {
        objectId,
        value,
      },
    })
    .json()
}

async function updateLoyaltyPoint(
  input: Pick<FormData, 'value'> & { objectId: string }
) {
  const { objectId, value } = input

  return client
    .post('functions/updateLoyaltyPoint', { json: { objectId, value } })
    .json()
}

async function updateReceipt(
  input: Pick<FormData, 'value'> & { objectId: string }
) {
  const { objectId, value } = input

  return client
    .post('functions/updateReceipt', {
      json: {
        objectId,
        value,
      },
    })
    .json()
}

export type { FormData }
export {
  createAdjustment,
  createSale,
  deleteAdjustment,
  deleteLoyaltyPoint,
  deleteReceipt,
  formDataSchema,
  logPoints,
  updateAdjustment,
  updateLoyaltyPoint,
  updateReceipt,
}

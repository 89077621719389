import { z } from 'zod'

import { units } from '../constants/misc'

import type { ZodSchema } from 'zod'

type AmountOfTime = z.infer<typeof amountOfTimeSchema>
type Range = z.infer<typeof rangeObjectSchema>

function getZodSchemaFieldsShallow(schema: ZodSchema) {
  const fields: Record<string, true> = {}
  const proxy = new Proxy(fields, {
    get(_, key) {
      if (key === 'then' || typeof key !== 'string') {
        return
      }
      fields[key] = true
    },
  })
  schema.safeParse(proxy)

  return fields
}

/**
 * A way to parse URLSearchParams
 * https://gist.github.com/JacobWeisenburger/9256eae415f6b0a04b718d633266a4e0
 * Usage:
 *
 * const schema = zu.useURLSearchParams(
 *     z.object( {
 *         string: z.string(),
 *         number: z.number(),
 *         boolean: z.boolean(),
 *     } )
 * )
 *
 */
function safeParseJSON(string: string): any {
  try {
    return JSON.parse(string)
  } catch {
    return string
  }
}

function searchParamsToValues(searchParams: URLSearchParams) {
  return Array.from(searchParams.keys()).reduce((record, key) => {
    const values = searchParams.getAll(key).map(safeParseJSON)
    return { ...record, [key]: values.length > 1 ? values : values[0] }
  }, {} as Record<string, any>)
}

function makeSearchParamsObjSchema<Schema extends z.ZodObject<z.ZodRawShape>>(
  schema: Schema
) {
  return z
    .instanceof(URLSearchParams)
    .transform(searchParamsToValues)
    .pipe(schema)
}

function makeServerResponseSchema<T extends z.ZodTypeAny>(schema: T) {
  return z.object({ result: schema })
}

function makePaginatedServerResponseSchema<T extends z.ZodTypeAny>(schema: T) {
  return makeServerResponseSchema(
    z.object({
      data: schema,
      hasNextPage: z.boolean(),
      page: z.number(),
      pageSize: z.number(),
    })
  )
}

function makePaginatedServerResponseCountSchema<T extends z.ZodTypeAny>(
  schema: T
) {
  return makeServerResponseSchema(
    z.object({
      data: schema,
      hasNextPage: z.boolean(),
      page: z.number(),
      pageSize: z.number(),
      count: z.number(),
    })
  )
}

const amountOfTimeSchema = z.object({
  day: z.number(),
  days: z.number(),
  month: z.number(),
  months: z.number(),
  year: z.number(),
  years: z.number(),
})

const rangeObjectSchema = z.object({
  gt: z.coerce.number(),
  gte: z.coerce.number(),
  lt: z.coerce.number(),
  lte: z.coerce.number(),
  eq: z.coerce.number(),
  delayUnit: z.enum(units),
})

const isoDateSchema = z.object({ iso: z.string() })
const serverItemSchema = z.object({ objectId: z.string().min(1) })

const nullableArray = <T extends z.ZodTypeAny>(schema: T) =>
  z.nullable(z.array(schema))
const nullableString = z.nullable(z.string())
const nullableNumber = z.nullable(z.number())
const nullableBoolean = z.nullable(z.boolean())

export type { AmountOfTime, Range }
export {
  amountOfTimeSchema,
  getZodSchemaFieldsShallow,
  makePaginatedServerResponseSchema,
  makePaginatedServerResponseCountSchema,
  makeSearchParamsObjSchema,
  makeServerResponseSchema,
  isoDateSchema,
  nullableArray,
  nullableBoolean,
  nullableNumber,
  nullableString,
  rangeObjectSchema,
  serverItemSchema,
}

import * as React from 'react'

export function createContext<TContext>(displayName: string) {
  const ctx = React.createContext<TContext | undefined>(undefined)
  ctx.displayName = displayName

  function useCtx() {
    const c = React.useContext(ctx)

    if (c === undefined)
      throw new Error(
        `The ${displayName} useCtx must be inside a Provider with a value`
      )

    return c
  }

  return [useCtx, ctx.Provider] as const
}

import * as React from 'react'
import {
  IonAccordionGroup,
  IonAccordion,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonContent,
  IonItem,
  IonLabel,
  IonCard,
  IonCardContent,
  IonModal,
} from '@ionic/react'
import { z } from 'zod'
import { useImmer } from 'use-immer'

import { createAutomationSchema } from '../automations-form.schema'

type ConditionsInput = z.input<typeof createAutomationSchema>['conditions']
type Props = {
  conditions: ConditionsInput
  onSearch: (conditions: ConditionsInput) => void
}

const initialFormState = {}

function ConditionsModal({ conditions, onSearch }: Props) {
  const modalRef = React.useRef<HTMLIonModalElement>(null)

  const [formState, updateFormState] = useImmer<ConditionsInput>(conditions)

  function cancel() {
    updateFormState(conditions)
    modalRef.current?.dismiss()
  }

  function addConditions() {
    onSearch(formState)
    modalRef.current?.dismiss()
  }

  function resetForm() {
    updateFormState(initialFormState)
  }

  return (
    <>
      <IonButton id="open-modal" type="button" color="secondary">
        {Object.keys(conditions).length ? 'Edit' : 'Add'} Conditions
      </IonButton>
      <IonModal trigger="open-modal" ref={modalRef}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton color="secondary" onClick={cancel}>
                Cancel
              </IonButton>
            </IonButtons>
            <IonTitle>Conditions</IonTitle>
            <IonButtons slot="end">
              <IonButton fill="outline" color="secondary" onClick={resetForm}>
                Reset
              </IonButton>
              <IonButton color="yellow" fill="solid" onClick={addConditions}>
                {Object.keys(conditions).length ? 'Update' : 'Add'} Conditions
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding-bottom">
          <div className="ion-padding h-full overflow-y-scroll pr-2 scrollbar:h-1.5 scrollbar:w-1.5 scrollbar:bg-transparent scrollbar-track:rounded scrollbar-track:bg-[#1a1a1a] scrollbar-thumb:rounded scrollbar-thumb:bg-slate-500">
            <IonCard className="m-0">
              <IonCardContent className="space-y-4">
                <IonAccordionGroup>
                  <IonAccordion value="lastSale">
                    <AccordionItem label="Last Sale" />
                    <AccordionContent>
                      <div className="grid grid-cols-3 items-center py-0.5">
                        <label className="col-span-2" color="primary">
                          Greater Than
                        </label>
                        <Input
                          type="number"
                          step="1"
                          value={formState.sale?.gt ?? ''}
                          onChange={(value) => {
                            updateFormState((draft) => {
                              draft.sale = { ...draft.sale, gt: value }
                            })
                          }}
                        />
                      </div>
                      <div className="grid grid-cols-3 items-center">
                        <label className="col-span-2" color="primary">
                          Less Than
                        </label>
                        <Input
                          type="number"
                          step="1"
                          value={formState.sale?.lt ?? ''}
                          onChange={(value) => {
                            updateFormState((draft) => {
                              draft.sale = { ...draft.sale, lt: value }
                            })
                          }}
                        />
                      </div>
                    </AccordionContent>
                  </IonAccordion>
                </IonAccordionGroup>
                <div className="grid grid-cols-3 items-center py-2.5">
                  <label
                    className="text-ion-color-primary col-span-2 text-xl"
                    color="primary"
                  >
                    No Sale In
                  </label>
                  <div className="flex w-full items-end gap-x-2">
                    <Input
                      type="number"
                      step="1"
                      value={formState.noSales?.gt ?? ''}
                      onChange={(value) => {
                        updateFormState((draft) => {
                          draft.noSales = { gt: value, delayUnit: 'days' }
                        })
                      }}
                    />
                    <span className="text-ion-color-primary">Days</span>
                  </div>
                </div>
                <IonAccordionGroup>
                  <IonAccordion value="activeLoyaltyPoints">
                    <AccordionItem label="Active Loyalty Points" />
                    <AccordionContent>
                      <div className="grid grid-cols-3 items-center">
                        <label className="col-span-2" color="primary">
                          Greater Than
                        </label>
                        <Input
                          type="number"
                          step="1"
                          value={formState.loyaltyPointsActive?.gt ?? ''}
                          onChange={(value) =>
                            updateFormState((draft) => {
                              draft.loyaltyPointsActive = {
                                ...draft.loyaltyPointsActive,
                                gt: value,
                              }
                            })
                          }
                        />
                      </div>
                      <div className="grid grid-cols-3 items-center">
                        <label className="col-span-2" color="primary">
                          Less Than
                        </label>
                        <Input
                          type="number"
                          step="1"
                          value={formState.loyaltyPointsActive?.lt ?? ''}
                          onChange={(value) => {
                            updateFormState((draft) => {
                              draft.loyaltyPointsActive = {
                                ...draft.loyaltyPointsActive,
                                lt: value,
                              }
                            })
                          }}
                        />
                      </div>
                    </AccordionContent>
                  </IonAccordion>
                </IonAccordionGroup>
                <IonAccordionGroup>
                  <IonAccordion value="lifetimeLoyaltyPoints">
                    <AccordionItem label="Lifetime Loyalty Points" />
                    <AccordionContent>
                      <div className="grid grid-cols-3 items-center">
                        <label className="col-span-2" color="primary">
                          Greater Than
                        </label>
                        <Input
                          type="number"
                          step="1"
                          value={formState.loyaltyPointsLifetime?.gt ?? ''}
                          onChange={(value) => {
                            updateFormState((draft) => {
                              draft.loyaltyPointsLifetime = {
                                ...draft.loyaltyPointsLifetime,
                                gt: value,
                              }
                            })
                          }}
                        />
                      </div>
                      <div className="grid grid-cols-3 items-center">
                        <label className="col-span-2" color="primary">
                          Less Than
                        </label>
                        <Input
                          type="number"
                          step="1"
                          value={formState.loyaltyPointsLifetime?.lt ?? ''}
                          onChange={(value) => {
                            updateFormState((draft) => {
                              draft.loyaltyPointsLifetime = {
                                ...draft.loyaltyPointsLifetime,
                                lt: value,
                              }
                            })
                          }}
                        />
                      </div>
                    </AccordionContent>
                  </IonAccordion>
                </IonAccordionGroup>
                <IonAccordionGroup>
                  <IonAccordion value="activeLoyaltyVisits">
                    <AccordionItem label="Active Loyalty Visits" />
                    <AccordionContent>
                      <div className="grid grid-cols-3 items-center">
                        <label className="col-span-2" color="primary">
                          Greater Than
                        </label>
                        <Input
                          type="number"
                          step="1"
                          value={formState.loyaltyVisitsActive?.gt ?? ''}
                          onChange={(value) => {
                            updateFormState((draft) => {
                              draft.loyaltyVisitsActive = {
                                ...draft.loyaltyVisitsActive,
                                gt: value,
                              }
                            })
                          }}
                        />
                      </div>
                      <div className="grid grid-cols-3 items-center">
                        <label className="col-span-2" color="primary">
                          Less Than
                        </label>
                        <Input
                          type="number"
                          step="1"
                          value={formState.loyaltyVisitsActive?.lt ?? ''}
                          onChange={(value) => {
                            updateFormState((draft) => {
                              draft.loyaltyVisitsActive = {
                                ...draft.loyaltyVisitsActive,
                                lt: value,
                              }
                            })
                          }}
                        />
                      </div>
                    </AccordionContent>
                  </IonAccordion>
                </IonAccordionGroup>
              </IonCardContent>
            </IonCard>
          </div>
        </IonContent>
      </IonModal>
    </>
  )
}

function AccordionItem({ label }: { label: string }) {
  return (
    <IonItem style={{ '--padding-start': 0 }} slot="header" color="secondary">
      <IonLabel className="text-xl">{label}</IonLabel>
    </IonItem>
  )
}

function AccordionContent({ children }: { children: React.ReactNode }) {
  return (
    <div
      slot="content"
      className="bg-ion-color-secondary text-ion-color-primary ion-padding-horizontal ion-padding-bottom"
    >
      {children}
    </div>
  )
}

function Input({
  value,
  onChange,
  type,
  step,
}: Pick<React.ComponentProps<'input'>, 'type' | 'step' | 'value'> & {
  onChange: (str: string) => void
}) {
  return (
    <input
      type={type}
      step={step}
      className="form-input block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      value={value}
      onChange={(e) => {
        onChange && onChange(e.currentTarget.value)
      }}
    />
  )
}

export { ConditionsModal }

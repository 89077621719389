function AppUrlListener() {
  // let history = useHistory()

  // useEffect(() => {
  //   App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
  //     // https://capacitorjs.com/docs/guides/deep-links
  //     // Example url: https://beerswift.app/tabs/tab2
  //     // slug = /tabs/tab2
  //     // const slug = event.url.split('.app').pop()
  //     // if (slug) {
  //     //   history.push(slug)
  //     // }
  //     // If no match, do nothing - let regular routing
  //     // logic take over
  //   })
  // }, [])

  return null
}

export default AppUrlListener

import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  IonPage,
  IonContent,
  IonItem,
  IonButton,
  IonTextarea,
  IonCard,
  IonRadioGroup,
  IonRadio,
  IonLabel,
  IonCardContent,
  IonGrid,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonList,
  IonListHeader,
  IonSelectOption,
  IonSelect,
  IonRow,
  IonCol,
  useIonRouter,
} from '@ionic/react'

import * as commonStrings from '../utils/common-strings'
import saveManualCommunication from '../utils/save-communication'
import { useToast } from '../contexts/toastContext'
import useNextContact from '../hooks/use-next-contact'
import { useQueryParams } from '../hooks/use-query-params'

const WhatHappened = () => {
  const [whatHappened, setWhatHappened] = useState()
  const [note, setNote] = useState()
  const [loading, setLoading] = useState(false)

  const setToast = useToast()

  const router = useIonRouter()
  const URLquery = useQueryParams()
  const { clientId } = useParams()

  const { nextContactDate, setNextContactDate, dates, saveNextContactDate } =
    useNextContact()

  const onSubmit = async (e) => {
    e.preventDefault()
    const log = URLquery.get('log')

    setLoading(true)

    try {
      await saveManualCommunication('phone', {
        message: whatHappened,
        clientId,
        note,
        log: log === true,
        pointers: {
          callListId: URLquery.get('callListId'),
        },
      })
      await saveNextContactDate(clientId)
      setLoading(false)
      router.goBack()
    } catch (error) {
      setLoading(false)
      setToast({ message: 'Oops something went wrong', color: 'danger' })
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            <h2 className="ion-text-uppercase" style={{ fontSize: '20px' }}>
              Log Call
            </h2>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard color="secondary">
          <IonCardContent>
            <IonList>
              <IonRadioGroup
                required
                onIonChange={(e) => setWhatHappened(e.target.value)}
              >
                <IonListHeader className="ion-no-padding" color="secondary">
                  What Happened?
                </IonListHeader>
                <IonItem className="ion-no-padding" color="secondary">
                  <IonLabel>NO ANSWER</IonLabel>
                  <IonRadio slot="start" value={commonStrings.noAnswer} />
                </IonItem>
                <IonItem className="ion-no-padding" color="secondary">
                  <IonLabel>LEFT VOICEMAIL</IonLabel>
                  <IonRadio slot="start" value={commonStrings.voiceMail} />
                </IonItem>
                <IonItem className="ion-no-padding" color="secondary">
                  <IonLabel>CONNECTED</IonLabel>
                  <IonRadio slot="start" value={commonStrings.connected} />
                </IonItem>
              </IonRadioGroup>
            </IonList>

            <IonItem className="ion-no-padding" color="secondary">
              <IonLabel position="floating">Notes From The Call:</IonLabel>
              <IonTextarea
                autoGrow
                autocapitalize="on"
                rows={5}
                value={note}
                onIonChange={(e) => setNote(e.target.value)}
              />
            </IonItem>

            <IonItem
              className="ion-margin-top ion-no-padding"
              color="secondary"
            >
              <IonLabel>Next Contact:</IonLabel>
              <IonSelect
                id="next-contact-select"
                value={nextContactDate}
                onIonChange={(e) => setNextContactDate(e.target.value)}
                okText={commonStrings.Okay}
                cancelText={commonStrings.Dismiss}
                interfaceOptions={{ cssClass: 'dark-ionic-filter' }}
              >
                {dates.map(({ label, value }) => (
                  <IonSelectOption key={value} value={value}>
                    {label}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
          </IonCardContent>
          <IonGrid style={{ textAlign: 'center' }}>
            <IonRow>
              <IonCol>
                <IonButton onClick={onSubmit} disabled={loading}>
                  {commonStrings.FINISH}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCard>
      </IonContent>
    </IonPage>
  )
}

export default WhatHappened

import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { client } from '../../services/client'

import type { ProfileBase } from '../../types/general'
import type { CloudinaryData, ProductBase } from '../../types/shopwith'
import type { QueryContextFromKeys } from '../../utils/react-query'

type FetchFeedResponse = {
  result: {
    data: Array<FeedItem>
    hasNextPage: boolean
    page: number
    pageSize: number
    totalCount: number
  }
}

export type FeedItem = {
  objectId: string
  user: ProfileBase
  cloudinary: { data?: CloudinaryData } | null
  data: string | null

  look: {
    objectId: string
  } | null
  profileLook?: {
    objectId: string
  }
  post?: {
    objectId: string
  }

  products: Array<ProductBase>
}

type FeedQueryContexts = QueryContextFromKeys<typeof feedQueryKeys>

export const feedQueryKeys = {
  all: [{ entity: 'feed' }] as const,
  lists: () => [{ ...feedQueryKeys.all[0], scope: 'list' }] as const,
  list: () => [{ ...feedQueryKeys.lists()[0] }] as const,
  pendingCount: () =>
    [{ ...feedQueryKeys.lists()[0], scope: 'pending count' }] as const,
}

async function fetchFeed({ pageParam = 0 }: FeedQueryContexts['list']) {
  const pageSize = 10

  const response: FetchFeedResponse = await client
    .post('functions/getFeed', {
      json: {
        page: pageParam,
        pageSize,
      },
    })
    .json()

  return response.result
}
async function fetchPendingPostCount() {
  const response: { result: { count: number } } = await client
    .post('functions/getFeedPending')
    .json()

  return response.result
}

function useFeed() {
  return useInfiniteQuery({
    queryKey: feedQueryKeys.list(),
    queryFn: fetchFeed,
    getNextPageParam: (lastPage) =>
      lastPage.hasNextPage ? lastPage.page + 1 : undefined,
    refetchInterval: 600000, // 10 minutes
  })
}

function usePendingPostCount() {
  return useQuery({
    queryKey: feedQueryKeys.pendingCount(),
    queryFn: fetchPendingPostCount,
  })
}

export { useFeed, usePendingPostCount }

import * as React from 'react'
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { useImmer } from 'use-immer'

import { getFullName } from '../../utils/format-helpers'
import { useAssociates } from '../../hooks/queries/use-associate'
import { Button } from './button'

import type { DisplayConditions } from '../unlayer.schema'

type Props = {
  filters: DisplayConditions
  onSearch: (filters: DisplayConditions) => void
  onCancel: () => void
}

const initialFormState = {
  user: null,
}

function DisplayConditionsModal({ filters, onSearch, onCancel }: Props) {
  const modalRef = React.useRef<HTMLIonModalElement>(null)

  const [formState, updateFormState] = useImmer<DisplayConditions>({
    ...initialFormState,
    ...filters,
  })

  const associatesQuery = useAssociates()
  const flatAssociates = React.useMemo(
    () => associatesQuery.data?.pages.flatMap((d) => d.data) ?? [],
    [associatesQuery.data?.pages]
  )

  return (
    <>
      <Button id="open-modal" variant="secondary">
        Set Preview Condition
      </Button>
      <IonModal ref={modalRef} trigger="open-modal">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                color="secondary"
                onClick={() => modalRef.current?.dismiss()}
              >
                Cancel
              </IonButton>
            </IonButtons>
            <IonTitle>Display Conditions</IonTitle>
            <IonButtons slot="end">
              <IonButton
                color="yellow"
                fill="solid"
                onClick={() => {
                  onSearch(formState)
                  modalRef.current?.dismiss()
                }}
              >
                Save
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonCard color="secondary" className="ion-margin-horizontal">
            <IonCardContent>
              {/* Send From*/}
              <IonItem color="secondary">
                <IonLabel>Send from</IonLabel>
                <IonSelect
                  value={formState.user}
                  onIonChange={(e) =>
                    updateFormState((draft) => {
                      draft.user = e.detail.value
                    })
                  }
                  color="secondary"
                >
                  {flatAssociates.map((item) => (
                    <IonSelectOption key={item.objectId} value={item.objectId}>
                      {getFullName(item)}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
            </IonCardContent>
          </IonCard>
        </IonContent>
      </IonModal>
    </>
  )
}

export { DisplayConditionsModal }

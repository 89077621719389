import { IonContent, IonPage } from '@ionic/react'

import { LoyaltyButton } from '../../loyalty/components/loyalty-button'
import { HeartlandCard } from '../../integrations/heartland/components/ui-extension/heartland-card'
import {
  ButtonWrapper,
  CardWrapper,
  Divider,
  Wrapper,
} from '../../integrations/heartland/components/ui-extension/layout'
import { useHeartlandClient } from '../../integrations/heartland/queries'
import { useShowExtension } from '../../integrations/heartland/use-show-extension'
import { useHeartlandParams } from '../../integrations/heartland/utils'

function HeartlandClientStatusUiExtension() {
  const { groupId, clientId, posUrl, ticketId, querystring } =
    useHeartlandParams()
  const { data: clientInfo } = useHeartlandClient({
    groupId,
    clientId,
    ticketId,
  })

  useShowExtension(posUrl, clientInfo?.show)

  const isOptedIn =
    clientInfo?.optInStatus === 'Opted In' ||
    clientInfo?.optInStatus === 'Opted Out'

  function redirectToSignUp() {
    const url = new URL(
      `https://app.oneshopretail.com/heartland/${groupId}/opt-in${querystring}`
    )

    if (posUrl) {
      window.parent?.postMessage(
        JSON.stringify({
          command: 'redirect',
          params: {
            url,
          },
        }),
        {
          targetOrigin: posUrl,
        }
      )
    }
  }

  return (
    <IonPage>
      <IonContent>
        <Wrapper>
          <CardWrapper>
            <HeartlandCard title="Phone Number">
              {clientInfo
                ? clientInfo?.hasPhoneNumber
                  ? 'Has Phone Number'
                  : 'No Phone Number'
                : '...'}
            </HeartlandCard>
          </CardWrapper>

          <CardWrapper>
            <HeartlandCard title="Opt-In Status">
              {clientInfo ? clientInfo.optInStatus : '...'}
            </HeartlandCard>
          </CardWrapper>

          <ButtonWrapper>
            <LoyaltyButton
              disabled={isOptedIn || !clientInfo}
              onClick={redirectToSignUp}
            >
              Sign Up
            </LoyaltyButton>
          </ButtonWrapper>
        </Wrapper>

        <Divider />
      </IonContent>
    </IonPage>
  )
}

export default HeartlandClientStatusUiExtension

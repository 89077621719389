import { IonSkeletonText } from '@ionic/react'

type Props = {
  label: string
  stat: string
}

function StatCard({ label, stat }: Props) {
  return (
    <div className="ion-text-center">
      <h2>{stat}</h2>
      <p>{label}</p>
    </div>
  )
}

function StatCardSkeleton() {
  return (
    <div className="ion-text-center">
      <h2>
        <IonSkeletonText
          animated
          className="mx-auto my-0 w-2/5 leading-inherit"
        />
      </h2>
      <p>
        <IonSkeletonText animated className="mx-auto -mb-0.5 mt-0.5 w-3/5" />
      </p>
    </div>
  )
}

export { StatCard, StatCardSkeleton }

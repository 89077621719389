import { IonRow } from '@ionic/react'

import { useCreateAyrshareConnection } from '../mutations'
import { ayrsharePlatforms } from '../ayrshare-platforms'
import AyrshareConnectButton from './ayrshare-connect-button'

type Props = {
  id: string
  type: 'account' | 'group' | 'user'
}

function AyrshareUninitConnections({ id, type }: Props) {
  const createAyrshareConnection = useCreateAyrshareConnection()

  function handleAyrshareConnect() {
    createAyrshareConnection.mutate({ id, type })
  }

  return (
    <IonRow className="ion-padding-horizontal items-center justify-between">
      <div className="space-y-2">
        <ul className="flex items-start gap-4">
          {ayrsharePlatforms.map((connection) => {
            const Icon = connection.icon

            return (
              <li key={connection.name} className="opacity-30">
                <Icon />
              </li>
            )
          })}
        </ul>
      </div>
      <div>
        <AyrshareConnectButton
          onClick={handleAyrshareConnect}
          disabled={false}
        />
      </div>
    </IonRow>
  )
}

export default AyrshareUninitConnections

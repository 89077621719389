import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonLoading,
  IonPage,
  IonRow,
} from '@ionic/react'
import { chevronForward } from 'ionicons/icons'
import { ErrorBoundary } from 'react-error-boundary'

import { useGroupsSocialConnections } from '../../../ayrshare/queries'
import AyrshareConnectionItem from '../../../ayrshare/components/ayrshare-connection-item'
import AyrshareUninitConnections from '../../../ayrshare/components/ayrshare-uninit-connections'
import ErrorFallback from '../../../components/error-fallback'
import Header from '../../../components/header'

function GroupConnectionsPage() {
  return (
    <IonPage>
      <IonHeader>
        <Header
          title="Social Setup"
          backRef="/account/connections"
          btn={null}
        />
      </IonHeader>
      <IonContent>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <GroupsConnections />
        </ErrorBoundary>
      </IonContent>
    </IonPage>
  )
}

function GroupsConnections() {
  const groupsSocialConnectionsQuery = useGroupsSocialConnections()

  if (groupsSocialConnectionsQuery.data) {
    const groupsConnections = groupsSocialConnectionsQuery.data

    return (
      <>
        {groupsConnections.map((groupConnection) => (
          <div
            key={groupConnection.objectId}
            className="ion-padding-top ion-padding-horizontal"
          >
            <IonRow className="items-center justify-between">
              <p className="text-xl">{groupConnection.name}</p>
              <IonButton
                fill="clear"
                routerLink={`/account/connections/groups/${groupConnection.objectId}`}
              >
                <IonIcon
                  color="secondary"
                  icon={chevronForward}
                  slot="icon-only"
                />
              </IonButton>
            </IonRow>

            {groupConnection.ayrshares.length > 0 ? (
              <>
                {groupConnection.ayrshares.map((ayrshare) => (
                  <AyrshareConnectionItem
                    key={ayrshare.refId}
                    id={groupConnection.objectId}
                    ayrshare={ayrshare}
                  />
                ))}
              </>
            ) : (
              <AyrshareUninitConnections
                type="group"
                id={groupConnection.objectId}
              />
            )}
          </div>
        ))}
      </>
    )
  }

  return <IonLoading isOpen message="Loading social connections..." />
}

export default GroupConnectionsPage

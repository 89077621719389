import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react'

import { Card, CardContent } from '../../components/ui/card'
import { LoyaltyRedemptionDetail } from '../../loyalty/components/loyalty-redemption-detail'
import { useClientLoyalty } from '../../loyalty/queries'
import { useApplyClientRewards } from '../../marketing/customer-opt-in/mutations'

import type { ApplyLoyaltyReward } from '../../loyalty/loyalty.schema'

function RotateLoyaltyInfoPage() {
  const router = useIonRouter()

  const loyalty = useClientLoyalty()
  const applyRewards = useApplyClientRewards()

  function handleApplyRewards(
    rewards: Array<ApplyLoyaltyReward>,
    options?: { redeemEarly: boolean }
  ) {
    applyRewards.mutate({ rewards, options })
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Redeem Rewards</IonTitle>
          <IonButtons slot="end">
            <IonButton
              color="yellow"
              fill="solid"
              onClick={() =>
                router.push('/rotate/opt-in?loyalty=true', 'none', 'replace')
              }
            >
              Finish
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <>
          {loyalty.data ? (
            <LoyaltyRedemptionDetail
              data={loyalty.data}
              disabled={loyalty.isLoading}
              onApplyRewards={handleApplyRewards}
            />
          ) : loyalty.error ? (
            <div className="flex h-full flex-col justify-center">
              <div className="ion-padding mx-auto max-w-md">
                <Card>
                  <CardContent>
                    <p className="text-lg">
                      <span className="text-ion-color-danger font-semibold">
                        Error:{' '}
                      </span>
                      {loyalty.error instanceof Error
                        ? loyalty.error.message
                        : 'Unknown Error.'}
                    </p>
                  </CardContent>
                </Card>
              </div>
            </div>
          ) : (
            <IonLoading message="Loading Rewards..." isOpen />
          )}
        </>
      </IonContent>
    </IonPage>
  )
}

export { RotateLoyaltyInfoPage }

import { handleResponse } from './responseHandler'

import * as store from '../stores'
import { isPlatform } from '@ionic/react'
import { authService } from './authService'

async function cloudFunction(functionName, body) {
  const options = {
    method: 'POST',
    headers: store.getHeader(),
    body: JSON.stringify(body),
  }

  const response = await window.fetch(
    `${store.getEndpoint()}functions/${functionName}`,
    options
  )
  const result = await handleResponse(response)
  return result.result
}

async function adminUpdateAssociate(associate) {
  const options = {
    method: 'POST',
    headers: store.getHeader(),
    body: JSON.stringify(associate),
  }
  const response = await window.fetch(
    `${store.getEndpoint()}functions/updateAssociate`,
    options
  )
  const result = await handleResponse(response)
  return result
}

async function adminUpdateAssociateAssignClients(associate) {
  return cloudFunction('updateAssociateAssignClients', associate)
}

async function adminUpdateAssociateClearClients(associate) {
  return cloudFunction('updateAssociateClearClients', associate)
}

async function updateTemplate(params) {
  return cloudFunction('updateTemplate', params)
}

async function deleteTemplate(params) {
  return cloudFunction('deleteTemplate', params)
}

async function createClientTag(params) {
  return cloudFunction('createClientTag', params)
}

async function applyDiscount(params) {
  return cloudFunction('applyDiscount', params)
}

async function applyLoyaltyPayment(params) {
  return cloudFunction('applyLoyaltyPayment', params)
}

async function importClients(params) {
  return cloudFunction('importClients', params)
}

export const updateService = {
  updateTemplate,
  deleteTemplate,
  adminUpdateAssociate,
  adminUpdateAssociateAssignClients,
  adminUpdateAssociateClearClients,
  createClientTag,
  applyDiscount,
  applyLoyaltyPayment,
  importClients,
}

import * as React from 'react'
import { useIonActionSheet } from '@ionic/react'
import { radioButtonOn, radioButtonOff } from 'ionicons/icons'
import { isAfter, isBefore, parseISO } from 'date-fns'

import { hasDefinedProp } from '../utils/typescript-helpers'
import type { Client } from './clients/queries'
import type { TaskOverview } from './tasks/queries'
import type { Template } from './templates/queries'

type Sorter<T extends string> = {
  property: T
  isDescending: boolean
}

type ClientKeys = keyof Pick<
  Client,
  | 'firstName'
  | 'lastName'
  | 'lastContact'
  | 'lastSale'
  | 'salesTotal'
  | 'updatedAt'
>

type ClientSortOptions = Array<{ name: string } & Sorter<ClientKeys>>
const clientSortOptions: ClientSortOptions = [
  {
    name: 'Total Sales: Highest to Lowest',
    property: 'salesTotal',
    isDescending: true,
  },
  {
    name: 'Total Sales: Lowest to Highest',
    property: 'salesTotal',
    isDescending: false,
  },
  {
    name: 'First Name: A-Z',
    property: 'firstName',
    isDescending: false,
  },
  {
    name: 'First Name: Z-A',
    property: 'firstName',
    isDescending: true,
  },
  {
    name: 'Last Name: A-Z',
    property: 'lastName',
    isDescending: false,
  },
  {
    name: 'Last Name: Z-A',
    property: 'lastName',
    isDescending: true,
  },
  {
    name: 'Last Shopped: Newest First',
    property: 'lastSale',
    isDescending: true,
  },
  {
    name: 'Last Shopped: Oldest First',
    property: 'lastSale',
    isDescending: false,
  },
  {
    name: 'Last Contacted: Newest First',
    property: 'lastContact',
    isDescending: true,
  },
  {
    name: 'Last Contacted: Oldest First',
    property: 'lastContact',
    isDescending: false,
  },
  {
    name: 'Last Updated: Newest First',
    property: 'updatedAt',
    isDescending: true,
  },
  {
    name: 'Last Updated: Oldest First',
    property: 'updatedAt',
    isDescending: false,
  },
]

type TaskKeys = keyof Pick<
  TaskOverview,
  'firstName' | 'lastName' | 'createdAt' | 'due'
>
type TaskSortOptions = Array<{ name: string } & Sorter<TaskKeys>>
const taskSortOptions: TaskSortOptions = [
  {
    name: 'Due Date: Closest to Furthest',
    property: 'due',
    isDescending: true,
  },
  {
    name: 'Due Date: Furthest to Closest',
    property: 'due',
    isDescending: false,
  },
  {
    name: 'Date Created: Newest First',
    property: 'createdAt',
    isDescending: true,
  },
  {
    name: 'Date Created: Oldest First',
    property: 'createdAt',
    isDescending: false,
  },
  {
    name: 'First Name: A-Z',
    property: 'firstName',
    isDescending: false,
  },
  {
    name: 'First Name: Z-A',
    property: 'firstName',
    isDescending: true,
  },
  {
    name: 'Last Name: A-Z',
    property: 'lastName',
    isDescending: false,
  },
  {
    name: 'Last Name: Z-A',
    property: 'lastName',
    isDescending: true,
  },
]

// TODO: revisit with properly converted typescript hooks
type ShopWithKeys = keyof Pick<
  { createdAt: string; brand: string; title: string; price: string },
  'createdAt' | 'brand' | 'title'
>
type ShopWithSortOptions = Array<{ name: string } & Sorter<ShopWithKeys>>
const shopWithSortOptions: ShopWithSortOptions = [
  {
    name: 'Date Created: Newest First',
    property: 'createdAt',
    isDescending: true,
  },
  {
    name: 'Date Created: Oldest First',
    property: 'createdAt',
    isDescending: false,
  },
  {
    name: 'Title: A-Z',
    property: 'title',
    isDescending: false,
  },
  {
    name: 'Title Name: Z-A',
    property: 'title',
    isDescending: true,
  },
  {
    name: 'Brand: A-Z',
    property: 'brand',
    isDescending: false,
  },
  {
    name: 'Brand: Z-A',
    property: 'brand',
    isDescending: true,
  },
]

type TemplateKeys = keyof Pick<Template, 'name' | 'createdAt'>
type TemplateSortOptions = Array<{ name: string } & Sorter<TemplateKeys>>
const templateSortOptions: TemplateSortOptions = [
  {
    name: 'Date Created: Newest First',
    property: 'createdAt',
    isDescending: true,
  },
  {
    name: 'DateCreated: Oldest First',
    property: 'createdAt',
    isDescending: false,
  },
  {
    name: 'Title: A-Z',
    property: 'name',
    isDescending: false,
  },
  {
    name: 'Title: Z-A',
    property: 'name',
    isDescending: true,
  },
]

function useTaskSorter() {
  const [sorter, setSorter] = React.useState<Sorter<TaskKeys>>({
    property: 'due',
    isDescending: true,
  })
  const [presentSort] = useIonActionSheet()

  const sortCallback = React.useCallback(
    (a, b) => {
      // custom due date sorting
      if (sorter.property === 'due') {
        const sortWeight = sorter.isDescending ? 1 : -1
        if (a.completedAt) return sortWeight
        if (b.completedAt) return -sortWeight

        if (!a.due) return -sortWeight
        if (!b.due) return sortWeight

        if (
          isBefore(parseISO(a.due.iso), new Date()) &&
          isAfter(parseISO(b.due.iso), new Date())
        )
          return sortWeight
        if (
          isBefore(parseISO(b.due.iso), new Date()) &&
          isAfter(parseISO(a.due.iso), new Date())
        )
          return -sortWeight

        return isBefore(parseISO(a.due.iso), parseISO(b.due.iso))
          ? sortWeight
          : -sortWeight
      }

      if (!hasDefinedProp(a, sorter.property)) return 1
      if (!hasDefinedProp(b, sorter.property)) return -1

      if (sorter.property === 'createdAt') {
        return sorter.isDescending
          ? b[sorter.property].iso.localeCompare(a[sorter.property].iso)
          : a[sorter.property].iso.localeCompare(b[sorter.property].iso)
      }

      return sorter.isDescending
        ? b[sorter.property].localeCompare(a[sorter.property])
        : a[sorter.property].localeCompare(b[sorter.property])
    },
    [sorter.isDescending, sorter.property]
  )

  function openSorterActionSheet() {
    presentSort({
      buttons: [
        ...taskSortOptions.map((option) => {
          return {
            text: option.name,
            cssClass: '*:justify-start',
            icon:
              sorter.property === option.property &&
              sorter.isDescending === option.isDescending
                ? radioButtonOn
                : radioButtonOff,
            handler: () =>
              setSorter({
                property: option.property,
                isDescending: option.isDescending,
              }),
          }
        }),
        {
          text: 'Cancel',
          role: 'cancel',
        },
      ],
      header: 'SORT',
      cssClass: 'action-sheet',
    })
  }

  return { openSorterActionSheet, sortCallback }
}

function useClientSorter(
  defaultSorter: Sorter<ClientKeys> = {
    property: 'salesTotal',
    isDescending: true,
  }
) {
  const [sorter, setSorter] = React.useState<Sorter<ClientKeys>>(defaultSorter)
  const [presentSort] = useIonActionSheet()

  const sortCallback = React.useCallback(
    (a, b) => {
      if (sorter.property === 'salesTotal') {
        let aProp = a[sorter.property] ?? 0
        let bProp = b[sorter.property] ?? 0

        return sorter.isDescending
          ? bProp.toString().localeCompare(aProp.toString(), undefined, {
              numeric: true,
            })
          : aProp.toString().localeCompare(bProp.toString(), undefined, {
              numeric: true,
            })
      }

      if (!hasDefinedProp(a, sorter.property)) return 1
      if (!hasDefinedProp(b, sorter.property)) return -1

      if (
        sorter.property === 'lastContact' ||
        sorter.property === 'lastSale' ||
        sorter.property === 'updatedAt'
      ) {
        return sorter.isDescending
          ? b[sorter.property].iso.localeCompare(a[sorter.property].iso)
          : a[sorter.property].iso.localeCompare(b[sorter.property].iso)
      }

      return sorter.isDescending
        ? b[sorter.property].localeCompare(a[sorter.property])
        : a[sorter.property].localeCompare(b[sorter.property])
    },
    [sorter.isDescending, sorter.property]
  )

  function openSorterActionSheet() {
    presentSort({
      buttons: [
        ...clientSortOptions.map((option) => {
          return {
            text: option.name,
            cssClass: '*:justify-start',
            icon:
              sorter.property === option.property &&
              sorter.isDescending === option.isDescending
                ? radioButtonOn
                : radioButtonOff,
            handler: () =>
              setSorter({
                property: option.property,
                isDescending: option.isDescending,
              }),
          }
        }),
        {
          text: 'Cancel',
          role: 'cancel',
        },
      ],
      header: 'SORT',
      cssClass: 'action-sheet',
    })
  }

  return { openSorterActionSheet, sortCallback }
}

function useShopWithSorter() {
  const [sorter, setSorter] = React.useState<Sorter<ShopWithKeys>>({
    property: 'createdAt',
    isDescending: true,
  })
  const [presentSort] = useIonActionSheet()

  const sortCallback = React.useCallback(
    (a, b) => {
      if (!hasDefinedProp(a, sorter.property)) return 1
      if (!hasDefinedProp(b, sorter.property)) return -1

      return sorter.isDescending
        ? b[sorter.property].localeCompare(a[sorter.property])
        : a[sorter.property].localeCompare(b[sorter.property])
    },
    [sorter.isDescending, sorter.property]
  )

  function openSorterActionSheet() {
    presentSort({
      buttons: [
        ...shopWithSortOptions.map((option) => {
          return {
            text: option.name,
            cssClass: '*:justify-start',
            icon:
              sorter.property === option.property &&
              sorter.isDescending === option.isDescending
                ? radioButtonOn
                : radioButtonOff,
            handler: () =>
              setSorter({
                property: option.property,
                isDescending: option.isDescending,
              }),
          }
        }),
        {
          text: 'Cancel',
          role: 'cancel',
        },
      ],
      header: 'SORT',
      cssClass: 'action-sheet',
    })
  }

  return { openSorterActionSheet, sortCallback }
}

function useTemplateSorter() {
  const [sorter, setSorter] = React.useState<Sorter<TemplateKeys>>({
    property: 'createdAt',
    isDescending: true,
  })
  const [presentSort] = useIonActionSheet()

  const sortCallback = React.useCallback(
    (a, b) => {
      if (!hasDefinedProp(a, sorter.property)) return 1
      if (!hasDefinedProp(b, sorter.property)) return -1

      if (sorter.property === 'createdAt') {
        return sorter.isDescending
          ? b[sorter.property].iso.localeCompare(a[sorter.property].iso)
          : a[sorter.property].iso.localeCompare(b[sorter.property].iso)
      }
      return sorter.isDescending
        ? b[sorter.property].localeCompare(a[sorter.property])
        : a[sorter.property].localeCompare(b[sorter.property])
    },
    [sorter.isDescending, sorter.property]
  )

  function openSorterActionSheet() {
    presentSort({
      buttons: [
        ...templateSortOptions.map((option) => {
          return {
            text: option.name,
            cssClass: '*:justify-start',
            icon:
              sorter.property === option.property &&
              sorter.isDescending === option.isDescending
                ? radioButtonOn
                : radioButtonOff,
            handler: () =>
              setSorter({
                property: option.property,
                isDescending: option.isDescending,
              }),
          }
        }),
        {
          text: 'Cancel',
          role: 'cancel',
        },
      ],
      header: 'SORT',
      cssClass: 'action-sheet',
    })
  }

  return { openSorterActionSheet, sortCallback }
}

export { useClientSorter, useTaskSorter, useShopWithSorter, useTemplateSorter }

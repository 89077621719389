import { IonContent, IonPage } from '@ionic/react'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

import { useToast } from '../../contexts/toastContext'
import { OptInContent } from '../../integrations/lightspeed/components/optin/opt-in-content'
import { useCreateLightspeedOptIn } from '../../integrations/lightspeed/mutations'
import { PhoneKeypadOnConfirm } from '../../components/ui/phone-keypad-input'

function LightspeedOptInPage() {
  const optInClient = useCreateLightspeedOptIn()
  const setToast = useToast()

  function handleSignUp({ phoneNumber, country }: PhoneKeypadOnConfirm) {
    const parsedNumber = parsePhoneNumberFromString(phoneNumber, country)

    if (!parsedNumber?.number)
      return setToast({
        message: 'You have entered an invalid phone number.',
        color: 'danger',
      })

    optInClient.mutate({ e164: parsedNumber.number })
  }

  return (
    <IonPage>
      <IonContent className="ion-padding-horizontal">
        <OptInContent handleSignUp={handleSignUp} />
      </IonContent>
    </IonPage>
  )
}

export default LightspeedOptInPage

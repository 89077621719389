import * as React from 'react'
import { RadioGroup } from '@headlessui/react'

import { Chip } from '../ui/buttons/chip'
import ScrollingList from '../ui/scrolling-list'

type Props<T extends { label: string; value: string }> = {
  shortcuts: Readonly<Array<T>>
  currentShortcut: T['value']
  onChange: (shortcut: T['value']) => void
}

function ShopWithShortcuts<T extends { label: string; value: string }>({
  shortcuts,
  currentShortcut,
  onChange,
}: Props<T>) {
  return (
    <>
      <RadioGroup
        as={ScrollingList}
        value={currentShortcut}
        onChange={onChange}
      >
        {shortcuts.map((shortcut) => (
          <React.Fragment key={shortcut.value}>
            <RadioGroup.Option value={shortcut.value}>
              {({ checked }) => {
                return (
                  <Chip fill={checked ? 'solid' : 'outline'}>
                    {shortcut.label}
                  </Chip>
                )
              }}
            </RadioGroup.Option>
          </React.Fragment>
        ))}
      </RadioGroup>
    </>
  )
}

export default ShopWithShortcuts

import {
  IonPage,
  IonContent,
  IonText,
  IonItem,
  IonInput,
  IonLabel,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonRouterLink,
  IonImg,
  IonLoading,
} from '@ionic/react'
import { Controller, useController, useForm } from 'react-hook-form'
import { z } from 'zod'

import logo from '../resources/OneShop_Logo_White.png'
import { useToast } from '../contexts/toastContext'
import { useQueryParams } from '../hooks/use-query-params'
import PasswordInput from '../components/ui/form/password-input'
import { useCreateAccount } from '../account/mutations'
import { useShopifyAddStore } from '../integrations/shopify/mutations'
import { useCreateUser, useLogin } from '../user/mutations'

import type { Control, FieldPath, FieldValues } from 'react-hook-form'

const formSchema = z.object({
  companyName: z.string(),
  shop: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  username: z.string(),
  password: z.string(),
})
const defaultValues: FormState = {
  companyName: '',
  shop: '',
  firstName: '',
  lastName: '',
  username: '',
  password: '',
}

type FormState = z.infer<typeof formSchema>

function CreateAccountPage() {
  const shopParam = useQueryParams().get('shop')

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            <h2>Sign Up</h2>
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonGrid className="h-full">
          <IonRow className="h-full items-center justify-center">
            <IonCol size="12">
              <IonImg src={logo} alt="Oneshop Logo" className="h-16" />

              {shopParam ? (
                <IonText>
                  Create your OneShop account to connect to Shopify
                </IonText>
              ) : null}
              <div className="mx-auto max-w-lg">
                <IonCard color="secondary">
                  <IonCardContent>
                    <CreateAccountForm />
                  </IonCardContent>
                </IonCard>
              </div>

              <div className="ion-text-center">
                Already have an account, please{' '}
                <IonRouterLink
                  style={{ color: 'white', textDecoration: 'underline' }}
                  routerLink={
                    '/login' +
                    (shopParam && shopParam !== 'null'
                      ? `?shop=${shopParam}`
                      : '')
                  }
                  routerDirection="none"
                >
                  login
                </IonRouterLink>
                .
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

function AccountInput<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  label,
  control,
  placeholder,
}: React.ComponentProps<typeof IonInput> & {
  control: Control<TFieldValues>
  label?: string
  name: TFieldName
}) {
  const { field } = useController<TFieldValues>({ name, control })

  return (
    <IonItem color="secondary">
      <IonLabel position="stacked">{label}</IonLabel>
      <IonInput
        type="text"
        onIonChange={field.onChange}
        value={field.value}
        autocapitalize="off"
        required
        placeholder={placeholder}
      />
    </IonItem>
  )
}

function CreateAccountForm() {
  const shopParam = useQueryParams().get('shop')
  const emailParam = useQueryParams().get('email')
  const companyNameParam = useQueryParams().get('companyName')

  const setToast = useToast()

  const createUser = useCreateUser()
  const login = useLogin()
  const createAccount = useCreateAccount()
  const shopifyAddStore = useShopifyAddStore()

  const isLoading =
    createAccount.isLoading ||
    shopifyAddStore.isLoading ||
    login.isLoading ||
    createUser.isLoading

  const { handleSubmit, control } = useForm<FormState>({
    defaultValues: {
      ...defaultValues,
      shop: shopParam ?? '',
      username: emailParam ?? '',
      companyName: companyNameParam ?? '',
    },
  })

  async function handleFormSubmit(formData: FormState) {
    const { username, password, firstName, lastName, companyName } = formData
    if (!(username && password && firstName && lastName && companyName)) return

    // Step 1: Create a user (exit early if fails)
    try {
      await createUser.mutateAsync({
        username,
        password,
        firstName,
        lastName,
        email: username,
      })
    } catch (e) {
      return setToast({
        message: e instanceof Error ? e.message : 'Unknown Error.',
        color: 'danger',
      })
    }

    // Step 2: Login the user using the username/password from the form (exit early if fails)
    try {
      await login.mutateAsync({
        username: formData.username,
        password: formData.password,
      })
    } catch (e) {
      return setToast({
        message: e instanceof Error ? e.message : 'Unknown Error.',
        color: 'danger',
      })
    }

    // Step 3: Create Account using companyName from form (redirect to home page if fails)
    try {
      await createAccount.mutateAsync({
        companyName: formData.companyName,
        settings: {
          marketing: true,
          loyalty: true,
          social: true,
          clienteling: false,
        },
      })
    } catch (e) {
      setToast({
        message: e instanceof Error ? e.message : 'Unknown Error.',
        color: 'danger',
      })
      return window.location.replace('/')
    }

    // Step 4: Add Shopify Store if it's in querystring
    // No matter what redirect to the account page
    if (shopParam) {
      shopifyAddStore.mutate(
        { shop: shopParam },
        {
          onSettled: () => {
            window.location.replace('/account')
          },
        }
      )
      // Step 4: If there isn't a Shopify Store in querystring, just redirect to home page
    } else {
      window.location.replace('/')
    }
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        onKeyUp={(e) => {
          if (e.code === 'Enter') {
            handleSubmit(handleFormSubmit)()
          }
        }}
      >
        {shopParam ? (
          <div className="flex items-center justify-between px-5">
            <p className="font-semibold">Company Website: </p>
            <p>{shopParam}</p>
          </div>
        ) : null}

        {emailParam ? (
          <div className="flex items-center justify-between px-5">
            <p className="font-semibold">Email: </p>
            <p>{emailParam}</p>
          </div>
        ) : null}

        <AccountInput
          control={control}
          name="companyName"
          label="Company Name"
        />

        <AccountInput control={control} name="firstName" label="First Name" />
        <AccountInput control={control} name="lastName" label="Last Name" />
        {!emailParam ? (
          <AccountInput control={control} name="username" label="Email" />
        ) : null}

        <IonItem color="secondary" className="items-center">
          <IonLabel position="stacked">Password</IonLabel>
          <Controller
            control={control}
            name="password"
            render={({ field: { value, onChange } }) => {
              return <PasswordInput onChange={onChange} password={value} />
            }}
          />
        </IonItem>

        <div className="ion-padding-vertical">
          <IonButton type="submit" disabled={isLoading}>
            Create Account
          </IonButton>
        </div>
      </form>
      <IonLoading isOpen={isLoading} message="Creating account..." />
    </>
  )
}

export default CreateAccountPage

export const APPLICATION_ID = '15eaa65a6d14458698fffb72bec53dd1'

export function getEndpoint() {
  return (
    process.env.REACT_APP_SERVER_URL || 'https://prod.oneshopretail.com/parse/'
  )
}

export function getAppId(params) {
  return APPLICATION_ID
}

export function getHeader() {
  const token = window.localStorage.getItem('token')
  const header = {}

  header['X-Parse-Application-Id'] = APPLICATION_ID
  if (token) {
    header['X-Parse-Session-Token'] = `${token}`
  }

  return header
}

import { z } from 'zod'

import {
  isoDateSchema,
  nullableNumber,
  nullableString,
  serverItemSchema,
} from '../utils/schema'
import { groupBaseSchema } from '../schema/account.schema'
import { profileBaseSchema } from '../schema/profile.schema'
import { alterationSteps, taskParseClasses } from './constants'
import { alterationStepSchema } from '../hooks/tasks/mutations'

type AlterationItem = z.infer<typeof alterationItemSchema>
type TaskDetail = z.infer<typeof taskDetailSchema>

const alterationItemSchema = serverItemSchema.extend({
  group: groupBaseSchema.nullable(),
  title: nullableString,
  step: alterationStepSchema,
})

const taskDetailSchema = serverItemSchema.extend({
  className: z.enum(taskParseClasses),
  client: profileBaseSchema,
  completedAt: isoDateSchema.nullable(),
  createdAt: isoDateSchema,
  due: isoDateSchema.nullable(),
  group: groupBaseSchema.nullable(),
  isPublic: z.boolean(),
  items: z.array(alterationItemSchema).nullable(),
  note: nullableString,
  photos: z.array(z.string()).nullable(),
  step: z.enum(alterationSteps).nullable(),
  ticket: nullableString,
  title: nullableString,
  trackingNumber: nullableNumber,
  trigger: z.boolean(),
  user: profileBaseSchema,
  userSale: profileBaseSchema.nullable(),
  users: z.array(profileBaseSchema).nullable(),
  vendor: nullableString,
})

const taskResponseSchema = z.object({ task: taskDetailSchema })

export type { AlterationItem, TaskDetail }
export { taskDetailSchema, taskResponseSchema }

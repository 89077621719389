import * as React from 'react'
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption,
} from '@ionic/react'
import { filterOutline } from 'ionicons/icons'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { zodResolver } from '@hookform/resolvers/zod'
import { addYears, endOfDay, parseISO, startOfDay } from 'date-fns'
import CheckboxTree, { Node, OnCheckNode } from 'react-checkbox-tree'
import { useImmer } from 'use-immer'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'

import { composeSearchFilters } from '../../utils/compose-search-filters'
import { callListFormSchema } from '../../schema/onetime-outreach.schema'
import { useCreateCallList } from '../../hooks/outreach/mutations'
import { useOutreachClientCounts } from '../../hooks/outreach/queries'
import { useGroupsTree } from '../../hooks/queries/use-groups'
import { useShopWithLinks } from '../../hooks/shopwith/use-shopwith-links'
import { useTemplates } from '../../hooks/templates/queries'
import PullClientsModal from '../client/pull-clients-modal'
import { StyledError } from '../ui/form/errors'
import { TextInput } from '../ui/form/input'
import {
  CheckBoxChecked,
  CheckBoxUnchecked,
  CheckBoxHalfChecked,
  ChevronDown,
  ChevronRight,
} from '../ui/icons'
import DatePickerDark from '../ui/ionic/date-picker-dark'

import type { TemplateAttachments } from '../../hooks/templates/queries'
import type { CallListFormState } from '../../schema/onetime-outreach.schema'
import { useBoolean } from '../../hooks/use-boolean'

type TemplateMessage = {
  smsMessage: {
    body: string
  }
  emailMessage: {
    subject: string
    body: string
  }
  shopWithLink?: string
  attachments?: TemplateAttachments
}

const defaultValues: CallListFormState = {
  name: '',
  description: '',
  groupIds: [],
  dateRange: { startDate: null, endDate: null },
  templateId: '',
  filter: null,
  //TODO: TEMPORARY UNTIL ALL TYPES ENABLED
  campaignType: 'associate',
}

function CreateOneTimeOutreachForm() {
  const [expanded, setExpanded] = React.useState<Array<string>>([])
  const [isFilterOpen, { on: openFilter, off: closeFilter }] = useBoolean()

  const [message, updateMessage] = useImmer<TemplateMessage>({
    smsMessage: { body: '' },
    emailMessage: { subject: '', body: '' },
  })

  const templatesQuery = useTemplates()
  const storesQuery = useGroupsTree()

  const createCallList = useCreateCallList()

  const { generateShopWithLinkPreview } = useShopWithLinks()

  const {
    control,
    handleSubmit,
    getValues,
    register,
    setValue,
    formState: { errors },
  } = useForm<CallListFormState>({
    resolver: zodResolver(callListFormSchema),
    defaultValues,
  })
  const watchTemplate = useWatch({ control, name: 'templateId' })
  const watchFilters = useWatch({ control, name: 'filter' })
  const outreachClientCounts = useOutreachClientCounts(watchFilters ?? {})

  function saveOutreach(data: CallListFormState) {
    createCallList.mutate({
      ...data,
      filter: { admin: true, ...composeSearchFilters(data.filter) },
    })
  }

  function handleCheckboxTreeLabelClick(e: OnCheckNode) {
    if (e.children) {
      return
    }

    if (e.checked) {
      setValue(
        'groupIds',
        getValues('groupIds')?.filter((group) => group !== e.value)
      )
    } else {
      setValue('groupIds', [...(getValues('groupIds') ?? []), e.value])
    }
  }

  function handleTemplateSelect(id: string) {
    const selectedTemplate = templatesQuery.data?.find(
      (template) => template.objectId === id
    )

    if (!selectedTemplate) return

    const link = Boolean(selectedTemplate.collections?.length)
      ? generateShopWithLinkPreview(
          'collectionIds',
          selectedTemplate.collections?.map((c) => c.objectId) ?? []
        )
      : Boolean(selectedTemplate.looks?.length)
      ? generateShopWithLinkPreview(
          'lookIds',
          selectedTemplate.looks?.map((l) => l.objectId) ?? []
        )
      : Boolean(selectedTemplate.products?.length)
      ? generateShopWithLinkPreview(
          'productIds',
          selectedTemplate.products?.map((p) => p.objectId) ?? []
        )
      : undefined

    updateMessage((draft) => {
      draft.smsMessage.body = selectedTemplate.smsTemplate?.body ?? ''
      draft.emailMessage.subject = selectedTemplate.emailTemplate?.subject ?? ''
      draft.emailMessage.body = selectedTemplate.emailTemplate?.body ?? ''
      draft.shopWithLink = link
      // TODO: HACK!!! SHOULD EVENTUALLY MOVE TO SAME STRUCTURE AS TEMPLATES WITH ATTACHMENTS ON EACH "TEMPLATE" TYPE
      draft.attachments = selectedTemplate.smsTemplate?.attachments ?? null
    })
  }

  return (
    <form onSubmit={handleSubmit(saveOutreach)}>
      <input type="hidden" {...register('campaignType')} />
      <div className="ion-padding-top flex items-center justify-center gap-x-3">
        <p className="text-lg font-semibold">
          Total Clients:{' '}
          <span className="tabular-nums">
            {outreachClientCounts.data
              ? outreachClientCounts.data.clients.clients
              : '...'}
          </span>
        </p>
        <IonButton
          color="secondary"
          fill="clear"
          size="small"
          onClick={openFilter}
          disabled={createCallList.isLoading}
        >
          Filter
          <IonIcon icon={filterOutline} slot="start" />
        </IonButton>
      </div>
      <IonRow className="ion-justify-content-center ion-padding-top">
        <IonCol size="auto" className="space-x-4">
          <IonButton
            type="submit"
            color="yellow"
            size="small"
            disabled={createCallList.isLoading}
          >
            Save
          </IonButton>
        </IonCol>
      </IonRow>

      <IonGrid>
        <IonRow className="ion-padding-vertical">
          <IonCol size="12" sizeMd="3" sizeLg="2">
            <h2>Basic Info:</h2>
          </IonCol>
          <IonCol size="12" sizeMd="9" sizeLg="10" className="space-y-4">
            <div>
              <TextInput
                disabled={createCallList.isLoading}
                control={control}
                name="name"
                label="Title:"
              />
              <ErrorMessage name="name" errors={errors} as={StyledError} />
            </div>
            <div>
              <TextInput
                disabled={createCallList.isLoading}
                control={control}
                name="description"
                label="Description:"
              />
              <ErrorMessage
                name="description"
                errors={errors}
                as={StyledError}
              />
            </div>

            <div>
              <div className="ion-padding-vertical">
                <div className="flex">
                  <h2>Stores:</h2>
                  {storesQuery.data && (
                    <Controller
                      rules={{
                        validate: () =>
                          getValues('groupIds').length
                            ? true
                            : 'You must select at least one store.',
                      }}
                      name="groupIds"
                      control={control}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <div>
                            <CheckboxTree
                              disabled={createCallList.isLoading}
                              expandOnClick
                              onClick={handleCheckboxTreeLabelClick}
                              checkModel="all"
                              nodes={storesQuery.data as Array<Node>}
                              checked={value}
                              expanded={expanded}
                              showNodeIcon={false}
                              onCheck={onChange}
                              onExpand={(expanded) => setExpanded(expanded)}
                              icons={{
                                check: <CheckBoxChecked />,
                                uncheck: <CheckBoxUnchecked />,
                                halfCheck: <CheckBoxHalfChecked />,
                                expandClose: <ChevronRight />,
                                expandOpen: <ChevronDown />,
                              }}
                            />
                          </div>
                        )
                      }}
                    />
                  )}
                </div>
                <ErrorMessage
                  errors={errors}
                  name="groupIds"
                  render={({ message }) => (
                    <p className="text-ion-color-danger">{message}</p>
                  )}
                />
              </div>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonGrid>
        <IonRow className="ion-padding-vertical">
          <IonCol size="12" sizeMd="3" sizeLg="2">
            <h2>Date Range:</h2>
          </IonCol>
          <IonCol size="12" sizeMd="9" sizeLg="10" className="space-y-4">
            <Controller
              control={control}
              name="dateRange.startDate"
              render={({ field: { value, onChange } }) => (
                <DatePickerDark
                  label="Start Date"
                  disabled={createCallList.isLoading}
                  value={value}
                  min={new Date()}
                  max={addYears(new Date(), 1)}
                  onChange={(e) => {
                    typeof e.detail.value === 'string' && e.detail.value.length
                      ? onChange(
                          startOfDay(parseISO(e.detail.value)).toISOString()
                        )
                      : onChange(null)
                  }}
                  onReset={() => onChange(null)}
                />
              )}
            />
            <Controller
              control={control}
              name="dateRange.endDate"
              render={({ field: { value, onChange } }) => (
                <DatePickerDark
                  label="End Date"
                  disabled={createCallList.isLoading}
                  value={value}
                  min={new Date()}
                  max={addYears(new Date(), 1)}
                  onChange={(e) => {
                    typeof e.detail.value === 'string' && e.detail.value.length
                      ? onChange(
                          endOfDay(parseISO(e.detail.value)).toISOString()
                        )
                      : onChange(null)
                  }}
                  onReset={() => onChange(null)}
                />
              )}
            />
            <p>
              *If you enter start or end dates, it will show up on the home page
              for that amount of time, but these are not required
            </p>
            <ErrorMessage name="dateRange" errors={errors} as={StyledError} />
            <ErrorMessage
              errors={errors}
              name="dateRange.startDate"
              as={StyledError}
            />
            <ErrorMessage
              errors={errors}
              name="dateRange.endDate"
              as={StyledError}
            />
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonGrid>
        <IonRow className="ion-padding-vertical">
          <IonCol size="12" sizeMd="3" sizeLg="2">
            <h2>Messaging:</h2>
          </IonCol>
          <IonCol size="12" sizeMd="9" sizeLg="10" className="space-y-4">
            <div>
              <IonItem
                lines="none"
                style={{
                  '--padding-start': '.5rem',
                  '--background': 'var(--ion-color-step-100)',
                  '--border-radius': '4px',
                }}
              >
                <IonLabel>Select a saved template</IonLabel>
                <Controller
                  name="templateId"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <IonSelect
                      disabled={createCallList.isLoading}
                      interfaceOptions={{ cssClass: 'dark-ionic-filter' }}
                      className="bg-transparent"
                      value={value}
                      onIonChange={(e) => {
                        onChange(e.detail.value)
                        handleTemplateSelect(e.detail.value)
                      }}
                    >
                      {templatesQuery.data?.map((template) => (
                        <IonSelectOption
                          key={template.objectId}
                          value={template.objectId}
                        >
                          {template.name}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  )}
                />
              </IonItem>
              <ErrorMessage
                name="templateId"
                errors={errors}
                as={StyledError}
              />
              {watchTemplate ? (
                <>
                  <h2>Text Message</h2>
                  <p>
                    <strong>Message: </strong>
                    {message.smsMessage.body}
                  </p>
                  <h2>Email</h2>
                  <div className="flex flex-col gap-2">
                    <div>
                      <p>
                        <strong>Subject: </strong>
                        {message.emailMessage.subject}
                      </p>
                    </div>
                    <p>
                      <strong>Message: </strong>
                      {message.emailMessage.body}
                    </p>
                  </div>
                  {message.shopWithLink ? (
                    <p>
                      <strong>ShopWith Link: </strong>
                      {message.shopWithLink}
                    </p>
                  ) : null}
                  {message.attachments ? (
                    <>
                      <p>
                        <strong>Attachment: </strong>
                      </p>
                      {message.attachments?.map((photo) => (
                        <div
                          key={typeof photo === 'string' ? photo : photo.pic}
                          className="flex items-center justify-between"
                        >
                          <IonImg
                            src={typeof photo === 'string' ? photo : photo.pic}
                            style={{ height: '5rem' }}
                          />
                        </div>
                      ))}
                    </>
                  ) : null}
                </>
              ) : null}
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonModal isOpen={isFilterOpen} onDidDismiss={closeFilter}>
        <Controller
          name="filter"
          control={control}
          render={({ field: { value, onChange } }) => (
            <PullClientsModal
              isAdmin
              filters={value}
              onCancel={closeFilter}
              onSearch={(filterData) => {
                onChange(filterData)
                closeFilter()
              }}
            />
          )}
        />
      </IonModal>
    </form>
  )
}

export default CreateOneTimeOutreachForm

import { IonToolbar, IonButtons, IonBackButton, IonTitle } from '@ionic/react'

type Props = {
  backRef?: string
}

function PageHeader({ backRef = '/tabs/home' }: Props) {
  return (
    <IonToolbar>
      <IonButtons slot="start">
        <IonBackButton
          color="secondary"
          defaultHref={backRef}
          text="Exit Without Saving"
        />
      </IonButtons>

      <IonTitle>
        <h2 className="text-xl capitalize">Design HTML Email</h2>
      </IonTitle>
    </IonToolbar>
  )
}

export { PageHeader }

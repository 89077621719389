import type { RewardSet } from '../loyalty.schema'

type Props = {
  bonus: RewardSet
}

function CustomerBonus({ bonus }: Props) {
  const { loyaltyReward } = bonus

  // TODO: revisit if bonus is ever narrowed in schema/type
  if (!loyaltyReward)
    return (
      <div className="flex items-center gap-x-6">
        <p>There is no bonus to display.</p>
      </div>
    )

  return (
    <div className="flex items-center gap-x-6">
      <div className="flex flex-1 flex-col">
        <span className="text-lg font-semibold">{loyaltyReward.text}</span>
      </div>
    </div>
  )
}

export { CustomerBonus }

import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { klona } from 'klona/json'
import { z } from 'zod'

import { makePaginatedServerResponseSchema } from '../../utils/schema'
import { useToast } from '../../contexts/toastContext'
import { client } from '../../services/client'
import { userSchema } from '../../auth/auth.schema'

type FetchAssociatesResponse = {
  result: {
    associates: Array<{ objectId: string; name: string }>
    groups: Array<{ objectId: string; name: string }>
  }
}

const associateQueryKeys = {
  all: [{ entity: 'associates' }] as const,
  lists: () => [{ ...associateQueryKeys.all[0], scope: 'list' }] as const,
  list: () => [{ ...associateQueryKeys.lists()[0] }],
}

const associateQueryKeys_LEGACY = {
  all: [{ entity: 'admin lists' }] as const,
  lists: () =>
    [{ ...associateQueryKeys_LEGACY.all[0], scope: 'list' }] as const,
  list: (type: 'associate' | 'group') => [
    { ...associateQueryKeys_LEGACY.lists()[0] },
  ],
}

async function fetchAssociates() {
  // Workaround: large pagesize so full list returns w/o need to paginate
  const response = await client
    .post('functions/getAccountUsers', { json: { pageSize: 1000 } })
    .json()

  return makePaginatedServerResponseSchema(z.array(userSchema)).parse(response)
    .result
}

async function fetchPageAdminClientsSearch() {
  const response: FetchAssociatesResponse = await client
    .post('functions/page_admin_clients_search')
    .json()

  return response.result
}

function useAssociates() {
  return useInfiniteQuery({
    queryKey: associateQueryKeys.list(),
    queryFn: fetchAssociates,
  })
}

function useAdminAssociates() {
  const setToast = useToast()

  return useQuery({
    queryKey: associateQueryKeys_LEGACY.list('associate'),
    queryFn: fetchPageAdminClientsSearch,
    select: (data) =>
      klona(data.associates).sort((a, b) =>
        a.name?.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: 'base',
        })
      ),
    onError(error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown Error.'

      setToast({
        message: `Something went wrong while loading associates info: ${errorMessage}`,
        color: 'danger',
      })
    },
  })
}

function useAdminGroups() {
  const setToast = useToast()

  return useQuery({
    queryKey: associateQueryKeys_LEGACY.list('group'),
    queryFn: fetchPageAdminClientsSearch,
    select: (data) =>
      klona(data.groups).sort((a, b) =>
        a.name?.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: 'base',
        })
      ),
    onError(error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown Error.'

      setToast({
        message: `Something went wrong while loading associates info: ${errorMessage}`,
        color: 'danger',
      })
    },
  })
}

export { useAdminAssociates, useAdminGroups, useAssociates }

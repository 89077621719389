import { FacebookPagesLogo, InstagramLogo, TiktokLogo } from './social-logos'

import type { ElementType } from 'react'
import type { AyrshareSocials } from './ayrshare.schema'

export const ayrsharePlatforms: Array<{
  name: AyrshareSocials
  icon: ElementType
}> = [
  { icon: InstagramLogo, name: 'instagram' },
  { icon: TiktokLogo, name: 'tiktok' },
  { icon: FacebookPagesLogo, name: 'facebook' },
]

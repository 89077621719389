import { IonButton } from '@ionic/react'

type Props = {
  onClick: () => void
  disabled: boolean
}

function AyrshareConnectButton({ onClick, disabled }: Props) {
  return (
    <IonButton
      fill="outline"
      color="yellow"
      onClick={onClick}
      disabled={disabled}
    >
      Connect
    </IonButton>
  )
}

export default AyrshareConnectButton

import { useQuery } from '@tanstack/react-query'

import { client } from '../../services/client'
import { useToast } from '../../contexts/toastContext'
import type { QueryContextFromKeys } from '../../utils/react-query'

type FetchDescriptionsResponse = {
  result: Array<string>
}

type DescriptionParams = {
  admin?: boolean
  search?: string
}

type DescriptionQueryContexts = QueryContextFromKeys<
  typeof descriptionsQueryKeys
>

const descriptionsQueryKeys = {
  all: [{ entity: 'descriptions' }] as const,
  lists: () => [{ ...descriptionsQueryKeys.all[0], scope: 'list' }] as const,
  list: ({ admin, search }: DescriptionParams) =>
    [{ ...descriptionsQueryKeys.lists()[0], admin, search }] as const,
}

async function fetchDescriptions({
  queryKey: [{ admin, search }],
}: DescriptionQueryContexts['list']) {
  const response: FetchDescriptionsResponse = await client
    .post('functions/descriptions', {
      json: { filter: { admin, search } },
    })
    .json()

  return response.result
}

function useDescriptionsSearch(
  searchTerm: string,
  { admin }: { admin?: boolean }
) {
  const setToast = useToast()

  return useQuery({
    queryKey: descriptionsQueryKeys.list({ admin, search: searchTerm }),
    queryFn: fetchDescriptions,
    refetchOnWindowFocus: false,
    retry: false,
    select: (data) =>
      [...data].sort((a, b) =>
        a.localeCompare(b, undefined, {
          numeric: true,
          ignorePunctuation: true,
        })
      ),
    enabled: Boolean(searchTerm),
    onError(error) {
      if (!error) return
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown Error.'

      setToast({
        message: `Something went wrong while fetching the descriptions: ${errorMessage}`,
        color: 'danger',
      })
    },
  })
}

export { useDescriptionsSearch }

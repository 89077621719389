/**
 * helper for filtering nulls & undefined from array of strings
 *
 * arr.filter(removeNulls)
 */

function removeNulls<Str>(value: Str | null | undefined): value is Str {
  return value != null
}

/**
 * type guard for props that can explicitly be undefined (different from optional)
 * can use this type guard so ts knows that the code that follows has the prop defined
 */
function hasDefinedProp<T extends { [k: string]: unknown }, K extends keyof T>(
  obj: T,
  key: K
): obj is T & Record<K, Exclude<T[K], undefined | null>> {
  return obj[key] !== undefined && obj[key] !== null
}

function typedBoolean<T>(
  value: T
): value is Exclude<T, '' | 0 | false | null | undefined> {
  return Boolean(value)
}

const typedKeys = <T>(a: T) => Object.keys(a) as Array<keyof T>

function isStringArray(v: unknown): v is Array<string> {
  return Array.isArray(v) && v.every((i) => typeof i === 'string')
}

/**
 * type guard and getter for Broadcast URLSearchParam
 *
 */
const listTypes = ['sms', 'email'] as const
type ListType = typeof listTypes[number]

function isListType(listType: string | null): listType is ListType {
  return (
    listType !== null && (listTypes as ReadonlyArray<string>).includes(listType)
  )
}

function getListType(searchParams: URLSearchParams) {
  const listType = searchParams.get('list')

  return isListType(listType) ? listType : null
}

function assertUnreachable(
  _value: never,
  message: string = 'Reached unexpected case in exhaustive switch'
): never {
  throw new Error(message)
}

export {
  assertUnreachable,
  getListType,
  removeNulls,
  hasDefinedProp,
  typedBoolean,
  isStringArray,
  typedKeys,
}

import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'

declare global {
  interface Window {
    analytics: any
  }
}

export const page = () => {
  if (process.env.NODE_ENV !== 'development') {
    window.analytics.page()
  }
}

export const track = (name: string, properties: any) => {
  if (process.env.NODE_ENV !== 'development') {
    window.analytics.track(name, properties)
  }
}

export const setUserProperty = (name: string, value: string) => {
  if (process.env.NODE_ENV !== 'development') {
    FirebaseAnalytics.setUserProperty({ name, value })
  }
}

export const identify = (
  id: string,
  email: string,
  firstName: string,
  lastName: string
) => {
  if (process.env.NODE_ENV !== 'development') {
    window.analytics.identify(id, {
      firstName,
      lastName,
      email,
    })

    FirebaseAnalytics.setUserId({ userId: id })
  }
}

import * as React from 'react'
import {
  IonPage,
  IonContent,
  IonHeader,
  IonLoading,
  useIonViewWillEnter,
  IonItem,
  IonLabel,
  IonButton,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonMenuButton,
  useIonRouter,
  IonBackButton,
  IonRow,
  IonCol,
  IonSelect,
  IonSelectOption,
  IonSpinner,
} from '@ionic/react'
import { useParams } from 'react-router-dom'
import { addYears, endOfDay, parseISO, startOfDay } from 'date-fns'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { ErrorMessage } from '@hookform/error-message'
import { z } from 'zod'

import { getFullName } from '../../utils/format-helpers'
import { typedKeys } from '../../utils/typescript-helpers'
import { useUpdateCallList } from '../../hooks/outreach/mutations'
import { useCallListDetail } from '../../hooks/outreach/queries'
import { useTemplates } from '../../hooks/templates/queries'
import Avatar from '../../components/avatar'
import DatePickerDark from '../../components/ui/ionic/date-picker-dark'
import { StyledError } from '../../components/ui/form/errors'
import { TextInput } from '../../components/ui/form/input'
import TemplateMessageDisplay from '../../components/ui/template-message-display'
import { callListFormSchema } from '../../schema/onetime-outreach.schema'

import type { Template } from '../../hooks/templates/queries'

type TCallListDetail = NonNullable<ReturnType<typeof useCallListDetail>['data']>

type FormState = z.infer<typeof updateSchema> //Pick<CallListFormState, 'dateRange' | 'templateId' | 'name'>

const updateSchema = callListFormSchema.pick({
  dateRange: true,
  templateId: true,
  name: true,
})

const defaultValues: FormState = {
  dateRange: {
    startDate: null,
    endDate: null,
  },
  templateId: null,
  name: '',
}

function CallListDetail() {
  const [selectedTemplate, setSelectedTemplate] =
    React.useState<Template | null>(null)

  const router = useIonRouter()
  const { id } = useParams<{ id: string }>()

  const { data: templates } = useTemplates()
  const {
    data: callList,
    isLoading,
    refetch,
    ...callListQuery
  } = useCallListDetail(id)

  const updateCallList = useUpdateCallList()

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { isDirty, dirtyFields, errors, isSubmitSuccessful },
  } = useForm<FormState>({
    defaultValues,
    values: {
      ...defaultValues,
      name: callList?.callList.name ?? '',
      dateRange: {
        startDate: callList?.callList.startDate?.iso ?? '',
        endDate: callList?.callList.endDate?.iso ?? '',
      },
    },
    resolver: zodResolver(updateSchema),
  })

  const unAssignedClientStats = callList?.userStats.filter((stat) => !stat.user)

  function handleTemplateSelect(id: string) {
    const selectedTemplate = templates?.find(
      (template) => template.objectId === id
    )
    setSelectedTemplate(selectedTemplate ?? null)
  }

  React.useEffect(() => {
    if (isSubmitSuccessful) {
      reset(getValues())
    }
  }, [getValues, isSubmitSuccessful, reset])

  // TODO _ POSSIBLY REPLACE WITH INVALIDATION WHEN MSG SENT AND UPDATED
  useIonViewWillEnter(() => {
    refetch()
  }, [refetch])

  function handleUpdateCallList() {
    const updatedFields = typedKeys(dirtyFields)
      .map((x) => ({
        field: x,
        value: getValues(x),
      }))
      .reduce((acc, curr) => {
        return { ...acc, [curr.field]: curr.value }
      }, {} as FormState)

    updateCallList.mutate({ objectId: id, updates: updatedFields })
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Outreach</IonTitle>
          <IonButtons slot="start">
            {router.canGoBack() ? (
              <IonBackButton color="secondary" />
            ) : (
              <IonMenuButton color="secondary" />
            )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        {callList ? (
          <div className="ion-padding">
            <h1 className="text-center text-2xl">{callList.callList.name}</h1>
            <p className=" text-center text-sm">
              {`(${callList.totalStats.contactedClientsCount} / ${
                callList.totalStats.clientCount
              } Client${
                callList.totalStats.contactedClientsCount === 1 ? '' : 's'
              } contacted)`}
            </p>
            <div className="ion-padding-vertical flex w-full items-center justify-between">
              <div className="flex items-center">
                <Avatar user={callList.callList.user}></Avatar>
                <div className="ion-margin-start">
                  <p className="text-sm">Created By</p>
                  <p className="font-semibold">
                    {callList.callList.user?.firstName}{' '}
                    {callList.callList.user?.lastName}
                  </p>
                </div>
              </div>
            </div>
            {callList.callList.description ? (
              <div className="space-y 2 mt-4">
                <p>{callList.callList.description}</p>
              </div>
            ) : null}

            <p>
              <strong>Store Locations: </strong>
              {callList.callList.groups}
            </p>
            <form onSubmit={handleSubmit(handleUpdateCallList)}>
              <IonRow className="ion-padding-top justify-center">
                <IonCol size="auto">
                  <IonButton
                    disabled={!isDirty}
                    type="submit"
                    color="yellow"
                    size="small"
                    fill={isDirty ? 'solid' : 'outline'}
                  >
                    Update
                  </IonButton>
                </IonCol>
              </IonRow>

              <div className="space-y-8">
                <div>
                  <TextInput
                    control={control}
                    name="name"
                    label="Outreach Name"
                  />
                  <ErrorMessage name="name" errors={errors} as={StyledError} />
                </div>

                <div className="space-y-3">
                  <Controller
                    name="dateRange.startDate"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <DatePickerDark
                        label="Start Date"
                        disabled={updateCallList.isLoading}
                        value={value}
                        min={new Date()}
                        max={addYears(new Date(), 1)}
                        onChange={(e) => {
                          if (typeof e.detail.value !== 'string') return
                          e.detail.value.length
                            ? onChange(
                                startOfDay(
                                  parseISO(e.detail.value)
                                ).toISOString()
                              )
                            : onChange(null)
                        }}
                        onReset={() => onChange(null)}
                      />
                    )}
                  />
                  <Controller
                    name="dateRange.endDate"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <DatePickerDark
                        disabled={updateCallList.isLoading}
                        label="End Date"
                        value={value}
                        min={new Date()}
                        max={addYears(new Date(), 1)}
                        onChange={(e) => {
                          if (typeof e.detail.value !== 'string') return
                          const endDateString = endOfDay(
                            parseISO(e.detail.value)
                          ).toISOString()
                          onChange(endDateString)
                        }}
                        onReset={() => onChange(null)}
                      />
                    )}
                  />
                  <ErrorMessage
                    name="dateRange"
                    errors={errors}
                    as={StyledError}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="dateRange.startDate"
                    as={StyledError}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="dateRange.endDate"
                    as={StyledError}
                  />
                </div>

                <div>
                  {templates ? (
                    <IonItem
                      style={{
                        '--padding-start': '0',
                      }}
                    >
                      <IonLabel>Choose new outreach template</IonLabel>
                      <Controller
                        name="templateId"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <IonSelect
                            disabled={updateCallList.isLoading}
                            interfaceOptions={{
                              cssClass: 'dark-ionic-filter',
                            }}
                            className="bg-transparent"
                            value={value}
                            onIonChange={(e) => {
                              onChange(e.detail.value)
                              handleTemplateSelect(e.detail.value)
                            }}
                          >
                            {templates.map((template) => (
                              <IonSelectOption
                                key={template.objectId}
                                value={template.objectId}
                              >
                                {template.name}
                              </IonSelectOption>
                            ))}
                          </IonSelect>
                        )}
                      />
                    </IonItem>
                  ) : (
                    <IonItem
                      style={{
                        '--padding-start': '0',
                      }}
                    >
                      <IonLabel>Loading templates</IonLabel>
                      <IonSpinner color="primary" />
                    </IonItem>
                  )}
                </div>
              </div>

              <TemplateMessageDisplay
                template={selectedTemplate || callList.callList.template}
              />
            </form>
            {callList.userStats.length ? (
              <div className="ion-padding-vertical mt-8">
                <h2>User Stats</h2>
                <ul className="divide-ion-color-step-300 divide-y">
                  {callList.userStats.map((userStat) => {
                    if (!userStat.user) return null

                    return (
                      <StylistOutreachStats
                        key={userStat.user.objectId}
                        stat={userStat}
                      />
                    )
                  })}

                  {/* UNASSIGNED CLIENTS */}
                  {unAssignedClientStats?.length ? (
                    <StylistOutreachStats stat={unAssignedClientStats[0]} />
                  ) : null}
                </ul>
              </div>
            ) : null}
          </div>
        ) : callListQuery.error ? (
          <div className="ion-padding">
            <p>
              {callListQuery.error instanceof Error
                ? callListQuery.error.message
                : 'An unknown error occurred.'}
            </p>
          </div>
        ) : (
          <IonLoading isOpen message={'Loading outreach...'} />
        )}
      </IonContent>
    </IonPage>
  )
}

function StylistOutreachStats({
  stat,
}: {
  stat: TCallListDetail['userStats'][number]
}) {
  return (
    <li className="flex items-center justify-between py-2">
      <div className="flex items-center">
        <Avatar user={stat.user} />
        <p className="ml-2">
          {stat.user ? getFullName(stat.user) : 'Unassigned Clients'}
        </p>
      </div>

      <span>{`${stat.contactedClientsCount} / ${stat.clientCount}`}</span>
    </li>
  )
}

export default CallListDetail

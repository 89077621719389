import { useMutation, useQueryClient } from '@tanstack/react-query'
import produce from 'immer'
import { equals } from 'remeda'

import { getDefaultPhoneNumber } from '../../utils/client-helpers'
import { client } from '../../services/client'
import { useUser } from '../../contexts/authContext'
import { useToast } from '../../contexts/toastContext'
import { accountQueryKeys } from '../account/queries'
import { clientQueryKeys } from '../clients/queries'
import { useCreateOptIn } from '../opt-in'
import { chatQueryKeys } from './queries'
import { useIsAdminRoute } from '../routing'

import type { PhoneNumber } from '../clients/queries'
import type { ConversationDetail } from './queries'

type SendMessageInput = {
  conversationId: string
  message: {
    data?: string
    attachments?: Array<{ name: string; content: string }> | Array<string>

    productIds?: Array<string>
    lookIds?: Array<string>
    collectionIds?: Array<string>

    smsTemplateId?: string

    callListId?: string
    callListDynamicId?: string
  }
}

async function sendMessage(
  newMessage: SendMessageInput,
  { isAdminRoute }: { isAdminRoute: boolean }
) {
  const { conversationId, message } = newMessage

  return client
    .post('functions/sendSmsMessage', {
      json: { conversationId, message, admin: isAdminRoute },
    })
    .json()
}

function useSendMessage() {
  const queryClient = useQueryClient()
  const isAdminRoute = useIsAdminRoute()

  const user = useUser()
  const setToast = useToast()

  return useMutation(
    (newMessage) => sendMessage(newMessage, { isAdminRoute }),
    {
      onMutate: async (newMessage: SendMessageInput) => {
        const { conversationId, message } = newMessage
        await queryClient.cancelQueries(
          chatQueryKeys.conversationDetail(conversationId)
        )
        const previousMessages = queryClient.getQueryData<ConversationDetail>(
          chatQueryKeys.conversationDetail(conversationId)
        )
        if (previousMessages) {
          queryClient.setQueryData<ConversationDetail>(
            chatQueryKeys.conversationDetail(conversationId),
            (old) => {
              if (old) {
                const tempTimestamp = new Date().toISOString()
                const { objectId, firstName, lastName, photo, displayName } =
                  user
                return produce(
                  old,
                  (draft) =>
                    void draft.data.unshift({
                      objectId: tempTimestamp,
                      date: tempTimestamp,
                      client: null,
                      user: {
                        objectId,
                        firstName,
                        lastName,
                        photo,
                        displayName,
                      },
                      inbound: false,
                      status: 'sent',
                      type: 'sms',
                      data: message.data,
                      attachments: message.attachments?.map((a) => {
                        return typeof a == 'string' ? a : a.content
                      }),
                    })
                )
              }
              return previousMessages
            }
          )
        }
      },
      onSettled: (_data, _err, variables) => {
        queryClient.invalidateQueries(chatQueryKeys.inbox(), {
          refetchType: 'inactive',
        })
        queryClient.invalidateQueries(
          chatQueryKeys.conversationDetail(variables.conversationId)
        )

        // invalidate sms credits
        queryClient.invalidateQueries(accountQueryKeys.credits())

        // invalidate relevant client details for up-to-date msg related data... lastcontact, etc
        const conversationClientIds = queryClient
          .getQueryData<ConversationDetail>(
            chatQueryKeys.conversationDetail(variables.conversationId)
          )
          ?.meta.conversation.clients.map((client) => client.objectId)

        const queryKeys = conversationClientIds?.map((id) =>
          clientQueryKeys.detail(id)
        )

        if (queryKeys) {
          queryClient.invalidateQueries({
            predicate: (q) => queryKeys.some((key) => equals(key, q.queryKey)),
          })
        }
      },
      onError: (error) => {
        const errorMessage =
          error instanceof Error ? error.message : 'Unknown Error.'
        setToast({
          message: `Your message was not sent. ${errorMessage}`,
          color: 'danger',
        })
      },
    }
  )
}

function useSendOptInMessage() {
  const queryClient = useQueryClient()
  const createOptIn = useCreateOptIn()
  const sendMessageMutation = useSendMessage()
  const setToast = useToast()

  return useMutation(
    async ({
      conversationId,
      client,
      optInMessage,
    }: {
      conversationId: string
      client: {
        objectId: string
        phoneNumbers: Array<PhoneNumber>
      }
      optInMessage: string
    }) => {
      const defaultPhoneNumber = getDefaultPhoneNumber(client.phoneNumbers)
      if (!defaultPhoneNumber) return

      const optInId = await createOptIn.mutateAsync({
        client,
        defaultPhoneNumber,
      })

      const messageWithLink = `${optInMessage}\n\nhttps://oneshop.me/opt-in/${optInId}`

      await sendMessageMutation.mutateAsync({
        conversationId,
        message: { data: messageWithLink },
      })
    },
    {
      onSettled: (_data, _err, variables) => {
        queryClient.invalidateQueries(chatQueryKeys.inbox(), {
          refetchType: 'inactive',
        })
        queryClient.invalidateQueries(
          chatQueryKeys.conversationDetail(variables.conversationId)
        )

        // invalidate relevant client details for up-to-date msg related data... lastcontact, etc
        const conversationClientIds = queryClient
          .getQueryData<ConversationDetail>(
            chatQueryKeys.conversationDetail(variables.conversationId)
          )
          ?.meta.conversation.clients.map((client) => client.objectId)

        const queryKeys = conversationClientIds?.map((id) =>
          clientQueryKeys.detail(id)
        )

        if (queryKeys) {
          queryClient.invalidateQueries({
            predicate: (q) => queryKeys?.some((key) => equals(key, q.queryKey)),
          })
        }
      },
      onSuccess: () => {
        setToast({ message: 'Your opt-in message was sent.', color: 'yellow' })
      },
    }
  )
}

export { useSendMessage, useSendOptInMessage }

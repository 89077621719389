import * as React from 'react'
import {
  IonPage,
  IonContent,
  IonRow,
  IonHeader,
  IonCol,
  IonButton,
  IonText,
  IonLoading,
} from '@ionic/react'

import {
  springboard,
  lightspeed,
  intuit,
  mailchimp,
} from '../../config/account.config'
import { useAccount, useAuth, useUser } from '../../contexts/authContext'
import { useToast } from '../../contexts/toastContext'
import IntegrationButton from '../../components/account/integration-button'
import Header from '../../components/header'
import { getFullName } from '../../utils/format-helpers'
import { useForm } from 'react-hook-form'
import { useShopifyAddStore } from '../../integrations/shopify/mutations'
import { useQueryParams } from '../../hooks/use-query-params'

// TODO: REMOVE WHEN AUTHCONTEXT IS IN TYPESCRIPT
type AccountGroup = {
  name: string
  role: 'associate' | 'admin'
  springboardEnabled: boolean | null
  intuitEnabled: boolean | null
  lightspeedEnabled: boolean | null
  mailchimpEnabled?: boolean | null
  groupId: string
}

type FormState = {
  shopify: string
}

export default function AccountPage() {
  const [isShopifyMutating, setIsShopifyMutating] = React.useState(false)

  const { resetAccount } = useAuth()
  const account = useAccount()
  const user = useUser()

  const setToast = useToast()

  const shopifyAddStore = useShopifyAddStore()
  const queryParams = useQueryParams()
  const shop = queryParams.get('shop')

  const groups: Array<AccountGroup> = account.groups

  const { handleSubmit } = useForm<FormState>({
    defaultValues: {
      shopify: shop || '',
    },
  })

  async function handleShopifySubmit(formData: FormState) {
    setIsShopifyMutating(true)
    shopifyAddStore.mutate(
      { shop: formData.shopify },
      {
        onSuccess: async (data) => {
          await resetAccount()
          setToast({
            message:
              'Your account was successfully connected to your Shopify store.',
          })
          setIsShopifyMutating(false)
          if (data?.redirect) {
            window.location.replace(data.redirect)
          }
        },
      }
    )
  }

  return (
    <IonPage>
      <IonHeader>
        <Header showMenu title="Account" btn={null} />
      </IonHeader>

      <IonContent className="ion-padding">
        <IonRow>
          <IonCol size="auto">
            <IonText>
              <p className="font-semibold">{getFullName(user)}</p>
            </IonText>
          </IonCol>
        </IonRow>
        <IonRow className="items-center justify-between">
          <IonCol size="auto">
            <IonText>{user.email ? <p>{user.email}</p> : null}</IonText>
          </IonCol>
        </IonRow>
        {user.twilioProxyAddress ? <p>{user.twilioProxyAddress}</p> : null}
        <p className="font-bold uppercase" color="secondary">
          Roles:
        </p>

        <>
          {groups?.map((groupItem) => {
            const hasIntegrations =
              groupItem.intuitEnabled ||
              groupItem.lightspeedEnabled ||
              groupItem.mailchimpEnabled ||
              groupItem.springboardEnabled

            return (
              <IonRow
                key={`${groupItem.role}_${groupItem.groupId}`}
                className="items-center justify-between"
              >
                <IonCol size="auto">
                  <p>
                    {groupItem.role} - {groupItem.name}
                  </p>
                </IonCol>

                {hasIntegrations ? (
                  <IonCol size="auto">
                    {groupItem.springboardEnabled ? (
                      <IntegrationButton
                        href={springboard.href()}
                        integration={springboard.integration}
                        authState={springboard.state}
                        groupId={groupItem.groupId}
                      >
                        Add Heartland
                      </IntegrationButton>
                    ) : groupItem.intuitEnabled ? (
                      <IntegrationButton
                        href={intuit.href()}
                        integration={intuit.integration}
                        authState={intuit.state}
                        groupId={groupItem.groupId}
                      >
                        AddQuickbooks
                      </IntegrationButton>
                    ) : groupItem.lightspeedEnabled ? (
                      <IntegrationButton
                        href={lightspeed.href()}
                        integration={lightspeed.integration}
                        groupId={groupItem.groupId}
                      >
                        Add Lightspeed
                      </IntegrationButton>
                    ) : groupItem.mailchimpEnabled ? (
                      <IntegrationButton
                        href={mailchimp.href()}
                        integration={mailchimp.integration}
                        groupId={groupItem.groupId}
                      >
                        Add Mailchimp
                      </IntegrationButton>
                    ) : null}
                  </IonCol>
                ) : null}
              </IonRow>
            )
          })}
        </>

        {account?.groups.length &&
          !account.groups.some((g: any) => g.POS === 'shopify') && (
            <>
              {
                shop ? (
                  <>
                    <IonRow className="justify-center">
                      <IonText>
                        You are about to connect {account?.account?.companyName}{' '}
                        to {shop}
                      </IonText>
                    </IonRow>
                    <IonRow className="justify-center">
                      <IonButton
                        size="small"
                        color="yellow"
                        onClick={handleSubmit(handleShopifySubmit)}
                        disabled={shopifyAddStore.isLoading}
                      >
                        Confirm
                      </IonButton>
                    </IonRow>
                  </>
                ) : null

                /*
                  // Note disabled until we get approved for Shopify app store
                  <IonRow className="justify-center">
                  <IonCol size="auto">
                    <TextInput
                      name="shopify"
                      control={control}
                      placeholder={'{shop}.myshopify.com'}
                    />
                  </IonCol>
                  <IonCol size="auto">
                    <IonButton
                      size="small"
                      color="yellow"
                      onClick={handleSubmit(handleShopifySubmit)}
                    >
                      Add Shopify
                    </IonButton>
                  </IonCol>
                </IonRow> */
              }
            </>
          )}
        <IonRow className="ion-margin-top justify-center">
          <IonCol size="auto">
            <p>Version {process.env.REACT_APP_VERSION}</p>
          </IonCol>
        </IonRow>
        <IonRow className="justify-center">
          <IonCol size="auto">
            <p>
              If you wish to delete or disable your account account, please{' '}
              <a
                className="text-ion-color-light underline"
                href="mailto:lonnie@oneshopretail.com"
              >
                email us
              </a>
              .
            </p>
          </IonCol>
        </IonRow>
      </IonContent>
      <IonLoading
        message="Connecting your Shopify account..."
        isOpen={isShopifyMutating}
      />
    </IonPage>
  )
}

import { IonPage, IonContent, IonHeader } from '@ionic/react'

import AdminHeader from '../../components/header/admin-header'
import DashboardSales from '../../components/admin/dashboard-sales'

function DashboardPage() {
  return (
    <IonPage>
      <IonHeader>
        <AdminHeader title="Dashboard" showInbox showMenu />
      </IonHeader>
      <IonContent>
        <DashboardSales />
      </IonContent>
    </IonPage>
  )
}

export default DashboardPage

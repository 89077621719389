import { IonGrid, IonRow, IonCol, IonRouterLink } from '@ionic/react'
import { useRouteMatch } from 'react-router-dom'

import Currency from '../../Currency'
import {
  ShopWithCard,
  ShopWithCardContent,
  ShopWithCardMedia,
  ShopWithCardLineItem,
  ShopWithCardFooter,
} from '../card'
import { LookCover } from './look-cover'

import type { LookDetail as TLookDetail } from '../../../hooks/shopwith/looks/queries'

type Props = {
  lookId?: string
  look: TLookDetail
}
function LookDetail({ look }: Props) {
  const { url } = useRouteMatch()

  const hasProducts = Boolean(look.products.length)

  return (
    <div className="ion-padding-vertical space-y-2">
      <LookCover look={look} />
      {hasProducts ? (
        <IonGrid>
          <IonRow>
            {look.products.map((product) => (
              <IonCol
                key={product.objectId}
                className="flex flex-col"
                sizeXs="6"
                sizeSm="4"
                sizeMd="3"
              >
                <ShopWithCard>
                  <ShopWithCardContent>
                    <ShopWithCardMedia src={product.featuredImage} ratio />
                    <ShopWithCardLineItem bold>
                      {product.brand}
                    </ShopWithCardLineItem>
                    <ShopWithCardLineItem>{product.title}</ShopWithCardLineItem>
                    {product.price ? (
                      <ShopWithCardLineItem bold>
                        <Currency value={product.price} />
                      </ShopWithCardLineItem>
                    ) : null}
                  </ShopWithCardContent>

                  <ShopWithCardFooter>
                    <IonRouterLink
                      className="border-ion-color-dark rounded-md border px-3 py-1"
                      routerLink={`${url}/product/${product.objectId}`}
                    >
                      View Detail
                    </IonRouterLink>
                  </ShopWithCardFooter>
                </ShopWithCard>
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
      ) : null}
      {look.title ? (
        <div className="ion-padding-vertical mx-auto w-11/12 min-[320px]:w-72">
          <div>{look.title}</div>
        </div>
      ) : null}
    </div>
  )
}

export default LookDetail

import { IonButton, IonSpinner } from '@ionic/react'
import { useQuery } from '@tanstack/react-query'

import { client } from '../../services/client'
import { useLoyaltyPrograms } from '../../loyalty/queries'
import { Card, CardContent } from '../../components/ui/card'

function AdminLoyaltyInfo() {
  const loyaltyPrograms = useLoyaltyPrograms()
  const loyaltyStats = useQuery({
    queryKey: [{ entity: 'loyalty', scope: 'stats' }],
    queryFn: async () => {
      const response: {
        result: {
          loyaltyMembersCount: number
          loyaltyMembersWithPointsCount: number
        }
      } = await client.post('functions/loyaltyMemberStats').json()

      return response.result
    },
  })

  return (
    <>
      {loyaltyPrograms.data ? (
        <>
          {!loyaltyPrograms.data?.data.length ? (
            <div className="mx-auto flex flex-col items-center gap-y-6">
              <p className="text-2xl font-normal">
                You haven't created any Loyalty Programs.
              </p>
              <IonButton
                color="yellow"
                routerLink="/admin/loyalty-programs"
                routerDirection="none"
              >
                Create Loyalty Program
              </IonButton>
            </div>
          ) : null}
          <div className="ion-padding-horizontal mx-auto flex max-w-lg gap-x-4">
            <LoyaltyStat
              count={loyaltyStats.data?.loyaltyMembersCount}
              title="Loyalty Members"
            />
            <LoyaltyStat
              count={loyaltyStats.data?.loyaltyMembersWithPointsCount}
              title="Members With Rewards"
            />
          </div>
        </>
      ) : (
        <div className="text-center">
          <IonSpinner name="circles" />
        </div>
      )}
    </>
  )
}

function LoyaltyStat({
  count,
  title,
}: {
  count: number | undefined
  title: string | undefined
}) {
  return (
    <Card>
      <CardContent>
        <div className="text-center">
          <p className="text-xl">{title}</p>
          {count ? <p>{count}</p> : <IonSpinner name="dots" color="primary" />}
        </div>
      </CardContent>
    </Card>
  )
}

export { AdminLoyaltyInfo }

import * as React from 'react'
import { useCloudinaryVideo } from '../../hooks/cloudinary/use-cloudinary-video'

import type { AutoplayMode } from '../../types/cloudinary'

type Props = {
  publicId: string
  autoplayMode?: AutoplayMode
} & React.VideoHTMLAttributes<HTMLVideoElement>

function CldVideoPlayer({
  id,
  publicId,
  autoplayMode = 'never',
  ...props
}: Props) {
  const { videoRef, inViewRef, poster } = useCloudinaryVideo(publicId, {
    autoplayMode,
  })

  return (
    <div ref={inViewRef}>
      <video
        ref={videoRef}
        id={id}
        className="cld-video-player size-full"
        playsInline
        preload="none"
        data-cld-muted
        data-cld-transformation='[{ "transformation": "9x16_video", "format":"auto" }]'
        poster={poster}
        {...props}
      />
    </div>
  )
}

export default CldVideoPlayer

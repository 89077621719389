import { IonRow } from '@ionic/react'

import { cn } from '../../utils/cn'
import { ayrsharePlatforms } from '../ayrshare-platforms'
import AyrshareConnectButton from './ayrshare-connect-button'
import { useAyrshareLogin } from '../mutations'

import type { Ayrshare } from '../ayrshare.schema'

type Props = {
  ayrshare: Ayrshare
  id: string // this is the account, group, or user objectId
}

function AyrshareConnectionItem({ ayrshare, id }: Props) {
  const ayrshareLogin = useAyrshareLogin()

  async function handleAyrshareConnect() {
    ayrshareLogin.mutate({
      ayrshareId: ayrshare.objectId,
      id,
      type: ayrshare.level,
    })
  }

  return (
    <IonRow className="ion-padding-horizontal items-center justify-between">
      <div className="space-y-2">
        <ul className="flex items-start gap-4">
          {ayrsharePlatforms.map((connection) => {
            const Icon = connection.icon
            const disabledSocialAccount =
              !ayrshare.activeSocialAccounts?.includes(connection.name)

            return (
              <li
                key={connection.name}
                className={cn({
                  'opacity-30': disabledSocialAccount,
                })}
              >
                <Icon />
              </li>
            )
          })}
        </ul>
      </div>
      <div>
        <AyrshareConnectButton
          onClick={handleAyrshareConnect}
          disabled={ayrshareLogin.isLoading}
        />
      </div>
    </IonRow>
  )
}

export default AyrshareConnectionItem

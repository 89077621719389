import {
  IonContent,
  IonButton,
  IonPage,
  IonRow,
  IonCard,
  IonCardContent,
  IonHeader,
  IonLoading,
  IonCardHeader,
  IonCardTitle,
} from '@ionic/react'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'

import * as commonStrings from '../../utils/common-strings'
import { useTaskDetail } from '../../hooks/tasks/queries'
import ClientDetailHeader from '../../components/client/ClientDetailHeader'
import AddFab from '../../components/fab/add-fab'
import Header from '../../components/header'
import { TaskDetailForm } from '../../tasks/components/task-detail-form'

import type { TaskClass } from '../../hooks/tasks/queries'

const getTypeString = (taskType: string) => {
  switch (taskType) {
    case 'SpecialOrder':
      return 'SPECIAL ORDER'
    case 'FollowUp':
      return 'TASK'
    default:
      return taskType
  }
}

function TaskDetailPage() {
  const history = useHistory()
  const { url } = useRouteMatch()
  const { objectId, className } = useParams<{
    objectId: string
    className: TaskClass
  }>()

  const taskQuery = useTaskDetail({ objectId, className })

  function onSendComplete() {
    history.push('/NextContact/' + taskQuery.data?.task.client.objectId)
  }

  return (
    <IonPage>
      <AddFab />
      <IonHeader>
        <Header
          title={getTypeString(className)}
          backRef={commonStrings.backTasks}
        />
      </IonHeader>
      <IonContent>
        {taskQuery.data ? (
          <>
            <ClientDetailHeader
              client={taskQuery.data.client}
              mode="dark"
              onCommunicationComplete={onSendComplete}
            />

            <IonCard color="secondary">
              <IonCardHeader>
                <IonCardTitle>{getTypeString(className)}</IonCardTitle>
              </IonCardHeader>

              <IonCardContent>
                <TaskDetailForm task={taskQuery.data.task} />
              </IonCardContent>
            </IonCard>

            {taskQuery.data.client ? (
              <IonRow class="ion-justify-content-center ion-padding-bottom ion-margin-bottom">
                <IonButton
                  color="secondary"
                  routerLink={`${url}/client/${taskQuery.data.client.objectId}`}
                >
                  GO TO PROFILE
                </IonButton>
              </IonRow>
            ) : null}
          </>
        ) : (
          <IonLoading
            isOpen={taskQuery.isLoading}
            message="Loading Task Details..."
          />
        )}
      </IonContent>
    </IonPage>
  )
}

export { TaskDetailPage }

import { forwardRef } from 'react'
import { IonTextarea } from '@ionic/react'

import { cn } from '../../../utils/cn'

import type { ElementRef } from 'react'

type Props = {
  border?: boolean
} & React.ComponentPropsWithRef<typeof IonTextarea>

type Ref = ElementRef<typeof IonTextarea>

// TODO: REMOVE CLASSNAME IN FAVOR OF VARIANTS
const TextArea = forwardRef<Ref, Props>(({ border, ...props }, ref) => (
  <IonTextarea
    ref={ref}
    className={cn({ 'border-ion-color-primary border-b': border })}
    {...props}
  />
))

export { TextArea }

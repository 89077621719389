import { IonButton, IonTitle, IonToolbar } from '@ionic/react'

type Props = { returnUrl: string | null; title: string }

function Header({ returnUrl, title }: Props) {
  return (
    <IonToolbar className="space-x-2">
      <IonButton
        slot="start"
        onClick={() => {
          if (returnUrl) window.location.replace(returnUrl)
        }}
      >
        Back
      </IonButton>
      <IonTitle>{title}</IonTitle>
    </IonToolbar>
  )
}

export { Header }

import * as R from 'remeda'
import { fromZodError } from 'zod-validation-error'

import { makeServerResponseSchema } from '../../utils/schema'
import { client } from '../../services/client'
import { assignUserClientsReturn } from './user-setup.schema'

import type { AddUser, GroupMember, UpdateUserInput } from './user-setup.schema'

async function assignAllClients() {
  return client
    .post('functions/assignUsers', {
      timeout: 90000,
      retry: {
        limit: 0,
      },
    })
    .json()
}

async function autoAssignUserClients({ objectId }: { objectId: string }) {
  const response = await client
    .post('functions/assignUser', {
      json: { objectId },
    })
    .json()
  const parsedResponse = makeServerResponseSchema(
    assignUserClientsReturn
  ).safeParse(response)

  if (!parsedResponse.success)
    throw new Error(fromZodError(parsedResponse.error).message)

  return parsedResponse.data.result
}

async function clearUserClients(id: string) {
  return client
    .post('functions/updateAssociateClearClients', {
      json: { objectId: id },
    })
    .json()
}

async function createGroupMember(
  data: Pick<GroupMember, 'posId' | 'role'> & {
    userId: string
    groupId: string
  }
) {
  return client
    .post('functions/createGroupMember', {
      json: data,
    })
    .json()
}

async function createUser(data: AddUser) {
  return client
    .post('functions/createUser', {
      json: {
        ...R.omitBy(data, (val, _key) => val == null),
        twilioProxyEmail: data.twilioProxyEmail,
      },
    })
    .json()
}

async function deleteGroupMember(objectId: string) {
  return client
    .post('functions/deleteGroupMember', {
      json: { objectId },
    })
    .json()
}

async function sendWelcomeEmail(objectId: string) {
  return client
    .post('functions/sendWelcomeEmail', { json: { objectId } })
    .json()
}

async function updateGroupMember(
  data: Pick<GroupMember, 'objectId' | 'posId' | 'role'>
) {
  return client
    .post('functions/updateGroupMember', {
      json: data,
    })
    .json()
}

async function updateUser(data: UpdateUserInput) {
  return client
    .post('functions/updateUser', {
      json: data,
    })
    .json()
}

export {
  assignAllClients,
  autoAssignUserClients,
  clearUserClients,
  createGroupMember,
  createUser,
  deleteGroupMember,
  sendWelcomeEmail,
  updateGroupMember,
  updateUser,
}

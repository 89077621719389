import {
  IonPage,
  IonContent,
  IonHeader,
  IonCard,
  IonCardContent,
} from '@ionic/react'
import Header from '../components/header'

type Props = {
  title?: string
}
function NotAllowed({ title = 'OneShop' }: Props) {
  return (
    <IonPage>
      <IonContent>
        <IonHeader>
          <Header title={title} showMenu />
        </IonHeader>
        <IonCard color="secondary">
          <IonCardContent>
            You aren't authorized to view this page. Please contact your
            administrator to upgrade your account.
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  )
}

export { NotAllowed }

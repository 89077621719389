import * as React from 'react'
import { useHistory } from 'react-router-dom'
import {
  IonContent,
  IonIcon,
  IonItem,
  IonList,
  IonListHeader,
  IonMenu,
} from '@ionic/react'
import { logOut, logIn, openOutline } from 'ionicons/icons'

import { containsAny } from '../utils/array-helpers'
import { authService } from '../services/authService'
import { AuthContext, useAccount } from '../contexts/authContext'
import { useIsStore } from '../auth/utils'
import { MenuItem } from './components/menu-item'
import { MenuLabel } from './components/menu-label'
import { Qmenu } from './components/q-menu'
import { getUserRoutes } from './utils'

function Menu() {
  const { handleLogOut } = React.useContext(AuthContext)

  const account = useAccount()
  const { settings } = account.account

  const history = useHistory()
  const { isQ } = useIsStore()
  const routes = getUserRoutes(account)

  // hack async account fetch workaround
  if (!Object.keys(account).length) return null

  return (
    <IonMenu type="overlay" contentId="main">
      <IonContent forceOverscroll={false}>
        <IonList lines="none">
          {routes.appPages.map((route) => {
            return (
              <MenuItem key={`${route.title}_${route.path}`} route={route} />
            )
          })}
        </IonList>

        {account.isAdmin && routes.engagePages.length ? (
          <IonList lines="none">
            <IonListHeader>Engage</IonListHeader>
            {routes.engagePages.map((route) => {
              return <MenuItem key={route.title} route={route} />
            })}
          </IonList>
        ) : null}

        {account.isAdmin && routes.socialPages.length ? (
          <IonList lines="none">
            <IonListHeader>Social</IonListHeader>
            {routes.socialPages.map((route) => {
              return <MenuItem key={route.title} route={route} />
            })}
          </IonList>
        ) : null}

        {account.isAdmin && routes.loyaltyPages.length ? (
          <IonList lines="none">
            <IonListHeader>Loyalty</IonListHeader>
            {routes.loyaltyPages.map((route) => {
              return <MenuItem key={route.title} route={route} />
            })}
          </IonList>
        ) : null}

        {account.isAdmin && routes.analyticsPages.length ? (
          <IonList lines="none">
            <IonListHeader>Analytics</IonListHeader>
            {routes.analyticsPages.map((route) => {
              return <MenuItem key={route.title} route={route} />
            })}
          </IonList>
        ) : null}

        {account.isAssociate && routes.associatePages.length ? (
          <IonList lines="none">
            <IonListHeader>Associate</IonListHeader>
            {routes.associatePages.map((route) => {
              return <MenuItem key={route.title} route={route} />
            })}
          </IonList>
        ) : null}

        {isQ() ? <Qmenu /> : null}

        {account.isAdmin &&
        containsAny(Object.keys(settings), ['loyalty', 'marketing']) ? (
          <IonList lines="none">
            <IonListHeader>Check-In</IonListHeader>
            {routes.checkInPages.map((route) => (
              <MenuItem key={route.title} route={route} />
            ))}
          </IonList>
        ) : null}

        <IonList lines="none">
          <IonListHeader>Account</IonListHeader>
          {routes.accountPages.map((route) => {
            return <MenuItem key={route.title} route={route} />
          })}

          <IonItem
            detail={false}
            href="https://oneshopretail.com/training"
            target="_blank"
          >
            <IonIcon slot="start" icon={openOutline} />
            <MenuLabel>Training</MenuLabel>
          </IonItem>

          {authService.getLoggedIn() ? (
            <IonItem
              detail={false}
              button
              onClick={() => handleLogOut(history)}
            >
              <IonIcon slot="start" icon={logOut} />
              <MenuLabel>Logout</MenuLabel>
            </IonItem>
          ) : (
            <IonItem button onClick={() => history.push('/login')}>
              <IonIcon slot="start" icon={logIn} />
              <MenuLabel>Login</MenuLabel>
            </IonItem>
          )}
        </IonList>

        {/* TODO: when we refactor account - encompass this account check in a more scalable way */}
        {/*
        <IonList lines="none">
          {account?.showAssociate ? (
            <>
              {routes.appPages.map((route) => {
                if (route.requiresBlast) {
                  return hasBroadcastSettings('associate') ? (
                    <MenuItem key={route.title} route={route} />
                  ) : null
                }

                return <MenuItem key={route.title} route={route} />
              })}
            </>
          ) : null}
        </IonList>
        <IonList lines="none">
          {account?.isAdmin || account?.roleSocial?.role === 'admin' ? (
            <>
              <IonListHeader>Admin</IonListHeader>
              {routes.adminPages.map((route) => {
                if (route.requiresBlast) {
                  return hasBroadcastSettings('admin') ? (
                    <MenuItem key={route.title} route={route} />
                  ) : null
                }
                if (route.flag === 'sm') {
                  return isOneShop() || isScoutMollysReston() ? (
                    <MenuItem key={route.title} route={route} />
                  ) : null
                }

                return <MenuItem key={route.title} route={route} />
              })}
            </>
          ) : null}
        </IonList>

        <IonList lines="none">
          <IonListHeader>Account</IonListHeader>
          {authService.getLoggedIn() ? (
            <>
              {routes.accountPages.map((route) => (
                <MenuItem key={route.title} route={route} />
              ))}
              {account.isEnterprise ? (
                <>
                  {routes.enterprisePages.map((route) => (
                    <MenuItem key={route.title} route={route} />
                  ))}
                </>
              ) : null}
            </>
          ) : null}

          <IonItem
            detail={false}
            href="https://oneshopretail.com/training"
            target="_blank"
          >
            <IonIcon slot="start" icon={openOutline} />
            <MenuLabel>Training</MenuLabel>
          </IonItem>

          {authService.getLoggedIn() ? (
            <IonItem
              detail={false}
              button
              onClick={() => handleLogOut(history)}
            >
              <IonIcon slot="start" icon={logOut} />
              <MenuLabel>Logout</MenuLabel>
            </IonItem>
          ) : (
            <IonItem button onClick={() => history.push('/login')}>
              <IonIcon slot="start" icon={logIn} />
              <MenuLabel>Login</MenuLabel>
            </IonItem>
          )}
        </IonList> */}
      </IonContent>
    </IonMenu>
  )
}

export { Menu }

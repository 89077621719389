import * as React from 'react'

import useSizes from '../../../hooks/queries/use-sizes'
import SearchableSelect from '../../ui/form/searchable-select'

import type { SelectProps } from './types'

function SizeSelect({
  isAdmin = false,
  selectedItems,
  setSelectedItems,
}: SelectProps) {
  const [isOpen, setIsOpen] = React.useState(false)

  const sizesQuery = useSizes({ admin: isAdmin, enabled: isOpen })

  return (
    <SearchableSelect
      onOpen={() => setIsOpen(true)}
      label="Sizes"
      items={sizesQuery.data ?? []}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
    />
  )
}

export default SizeSelect

import { cva } from 'class-variance-authority'

import { cn } from '../../utils/cn'
import { automationStates } from '../constants'

type Props = { status: typeof automationStates[number] }

const statusVariants = cva('size-3', {
  variants: {
    status: {
      active: 'fill-green-500',
      draft: 'fill-ion-color-yellow',
      stopped: 'fill-ion-color-danger',
    },
  },
})

function CurrentStatus({ status }: Props) {
  return (
    <div className="text-ion-color-primary inline-flex items-center gap-x-1.5 rounded-md bg-white px-2 py-1 text-sm font-medium">
      <svg
        className={cn(statusVariants({ status }))}
        viewBox="0 0 6 6"
        aria-hidden="true"
      >
        <circle cx={3} cy={3} r={3} />
      </svg>
      <span>Status: {status}</span>
    </div>
  )
}

export default CurrentStatus

import { IonPage, IonContent, IonHeader, IonLoading } from '@ionic/react'

import { useFollowUpsList } from '../../hooks/outreach/queries'
import AddFab from '../../components/fab/add-fab'
import Header from '../../components/header'
import { StickyHeader } from '../../components/ui/sticky-header'
import {
  ListPageClientList,
  ListPageDetail,
  ListPageFilters,
  ListPageHeader,
  ListPageSegment,
} from '../../clienteling/list-page-detail'

function FollowUpsListPage() {
  const followUpsListQuery = useFollowUpsList()

  const title = 'Follow Ups'
  const description =
    'Clients with a follow up date for today or prior who have not been reached out to yet.'

  return (
    <IonPage>
      <AddFab />
      <IonHeader>
        <Header backRef="/tabs/clients" title="Outreach" />
      </IonHeader>

      <IonContent>
        {followUpsListQuery.data ? (
          <ListPageDetail clientList={followUpsListQuery.data.clients}>
            <ListPageHeader title={title} description={description} />
            <StickyHeader>
              <ListPageSegment />
              <ListPageFilters />
              <ListPageClientList />
            </StickyHeader>
          </ListPageDetail>
        ) : (
          <IonLoading isOpen message={'Loading the client list...'} />
        )}
      </IonContent>
    </IonPage>
  )
}

export { FollowUpsListPage }

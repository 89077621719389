import { useInfiniteData } from '../../hooks/use-infinite-data'
import { useBroadcasts } from './queries'

function checkForBlockedWords({
  strToCheck,
  blockedList,
}: {
  strToCheck?: string | null
  blockedList: Array<string>
}) {
  if (!strToCheck) return []
  const words = strToCheck.toLowerCase().split(/\W+/)
  const lcBlockedList = blockedList.map((word) => word.toLowerCase())
  const matchedWordsSet = new Set(
    words.filter((word) => lcBlockedList.includes(word))
  )

  return Array.from(matchedWordsSet)
}

function useMyBroadcasts() {
  const { data, ...broadcastsQuery } = useBroadcasts()
  const flatBroadcasts = data?.pages.flatMap((x) => x.data) ?? []

  const [ref, loadMoreBroadcasts] = useInfiniteData(
    broadcastsQuery.fetchNextPage
  )

  return {
    broadcasts: flatBroadcasts,
    ref,
    loadMoreBroadcasts,
    ...broadcastsQuery,
  }
}

export { checkForBlockedWords, useMyBroadcasts }

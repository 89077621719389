import { AdvancedImage, lazyload } from '@cloudinary/react'
import { PlayIcon } from '@heroicons/react/24/solid'

import type { CloudinaryVideo } from '@cloudinary/url-gen'

type Props = {
  cldImage: CloudinaryVideo
}

function CldVideoThumbnail({ cldImage }: Props) {
  return (
    <div className="size-full grid bg-slate-200">
      <div className="col-start-1 col-end-2 row-start-1 row-end-2">
        <AdvancedImage cldImg={cldImage} plugins={[lazyload()]} />
      </div>
      <div className="col-start-1 col-end-2 row-start-1 row-end-2 flex items-center justify-center pr-2 pt-2">
        <PlayIcon className="size-1/3 text-white opacity-50" />
      </div>
    </div>
  )
}

export default CldVideoThumbnail

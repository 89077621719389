import * as React from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { IonContent, IonModal } from '@ionic/react'

import { constants } from '../constants/authConstants'
import { client } from '../services/client'
import { searchService } from '../services/searchService'
import { authActions } from '../actions/authActions'
import { authReducer } from '../reducers/authReducer'
import { authService } from '../services/authService'
import { setUserProperty } from '../analytics'
import Terms from '../components/auth/faherty-terms'
import { useClearActiveStations } from '../rotate/mutations'
import { useToast } from './toastContext'

const AuthContext = React.createContext()

const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  auth: {
    user: {},
  },
  login: {
    loading: false,
    loggedIn: false,
  },
  message: '',
}

const AuthContextProvider = (props) => {
  const [state, dispatch] = React.useReducer(authReducer, initialState)
  const [username, setUsername] = React.useState('')
  const [account, setAccount] = React.useState({})

  const setToast = useToast()
  const queryClient = useQueryClient()

  const clearActiveStations = useClearActiveStations()

  // TODO: group async account retrieval with authentication
  /* NOTE: user is logged in before account data is available
      this causes some errors if accessing account info early
      in say an effect dependency */
  // Initialize User Object if Logged In
  React.useEffect(() => {
    const token = authService.getToken()

    if (token) {
      authService.fetchAuth(authService.getToken(token)).then((result) => {
        dispatch({ type: 'SET_AUTH_USER', user: result })
      })
    }
  }, [])

  React.useEffect(() => {
    searchService.getAccountDetails().then(
      (result) => {
        setAccount(result)

        if (result.groups && result.groups.length) {
          setUserProperty('GroupCompanyName', result.groups[0].companyName)
        }
      },
      (error) => {
        console.error('Get Account Details Error: ', error)
        // log out when appropriate, inform the user
        if (error.status === 400) {
          // reset local storage
          window.localStorage.removeItem('token')
          window.localStorage.removeItem('authUser')
          // reset app state
          dispatch({ type: 'AUTH_LOGOUT' })
          // redirect
          window.history.replaceState(null, null, '/login')
        }
      }
    )
  }, [state.login])

  return (
    <AuthContext.Provider
      value={{
        ...state,
        account,
        resetAccount: async () => {
          const account = await searchService.getAccountDetails()
          queryClient.clear()
          setAccount(account)
        },
        username,
        checkAuthState: React.useCallback(() => {
          dispatch({ type: 'CHECK_AUTH_STATE' })
        }, []),
        acceptOptInClientTerms: async () => {
          try {
            await client
              .post('functions/updateOptInTerms', { json: { accepted: true } })
              .json()

            const updatedAccount = await searchService.getAccountDetails()
            setAccount(updatedAccount)
          } catch (e) {
            const errorMessage =
              e instanceof Error ? e.message : 'Unknown Error.'
            setToast({
              message: `Something went wrong while accepting the terms. ${errorMessage}`,
              color: 'danger',
            })
          }
        },
        handleLogOut: function handleLogOut(history) {
          queryClient.clear()
          // clear all terminals
          clearActiveStations.mutate(undefined, {
            onSettled: () => dispatch(authActions.logout(history)),
          })
        },
        handleSwitchAccount: async (username2) => {
          // logout current
          queryClient.clear()
          authService.logout()
          dispatch({ type: constants.AUTH_LOGOUT })

          // set new username for login screen (where unauth user gets redirected after above)
          setUsername(username2)
        },
      }}
    >
      {props.children}
      {/* Simple Check/Preventative for accounts to require sign-off before accessing app */}
      {/* NOTE: only shows after logging in, not if requriement changes and then they log in */}
      <IonModal
        isOpen={Boolean(
          account.personalDeviceTermsRequired && state.isLoggedIn
        )}
        backdropDismiss={false}
      >
        <IonContent>
          <Terms
            onAccept={() => {
              client
                .post('functions/updatePersonalDeviceTerms', {
                  json: { accepted: true },
                })
                .then(() => {
                  searchService.getAccountDetails().then((result) => {
                    setAccount(result)
                  })
                })
            }}
          />
        </IonContent>
      </IonModal>
    </AuthContext.Provider>
  )
}

function useAuth() {
  const context = React.useContext(AuthContext)

  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthContextProvider`)
  }

  return context
}

function useStylistGroup() {
  const context = React.useContext(AuthContext)

  if (context === undefined) {
    throw new Error(`useAccount must be used within a AuthContextProvider`)
  }

  // TODO: REVISIT when upating auth... poss undefined might be due to async account retrieval.
  return (
    context.account.groups?.find((group) => group.role === 'associate') ??
    context.account.groups?.find((group) => group.role === 'admin')
  )
}

function useAccount() {
  const context = React.useContext(AuthContext)

  if (context === undefined) {
    throw new Error(`useAccount must be used within a AuthContextProvider`)
  }

  return React.useMemo(() => ({ ...context.account }), [context.account])
}

function useUser() {
  const context = React.useContext(AuthContext)

  if (context === undefined) {
    throw new Error(`useUser must be used within a AuthContextProvider`)
  }
  const hasTwilio = Boolean(context.auth.user.twilioProxyAddress)
  const hasSendGrid = Boolean(context.auth.user.twilioProxyEmail)

  return React.useMemo(
    () => ({
      ...context.auth.user,
      hasTwilio,
      hasSendGrid,
      isBlastEnabled: Boolean(
        (context.account.optinRequired && hasTwilio) || hasSendGrid
      ),
    }),
    [context.account.optinRequired, context.auth.user, hasSendGrid, hasTwilio]
  )
}

export {
  AuthContext,
  AuthContextProvider,
  useAuth,
  useAccount,
  useUser,
  useStylistGroup,
}

import * as React from 'react'
import {
  IonButton,
  IonCol,
  IonContent,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  useIonAlert,
  useIonRouter,
} from '@ionic/react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { format } from 'date-fns'
import { useImmer } from 'use-immer'
import { RadioGroup } from '@headlessui/react'
import { InformationCircleIcon } from '@heroicons/react/24/solid'

import { forbiddenWords } from '../../../constants/communication'
import { composeSearchFilters } from '../../../utils/compose-search-filters'
import { useSmsMaxCharacterCount, useTextAreaRef } from '../../../utils/forms'
import { typedKeys } from '../../../utils/typescript-helpers'
import { useAccount, useUser } from '../../../contexts/authContext'
import { useToast } from '../../../contexts/toastContext'
import { useSmsCredits } from '../../../hooks/account/queries'
import { useAssociates } from '../../../hooks/queries/use-associate'
import { useIsAdminRoute } from '../../../hooks/routing'
import { useShopWithLinks } from '../../../hooks/shopwith/use-shopwith-links'
import { useTemplates } from '../../../hooks/templates/queries'
import { useReplacePlaceholders } from '../../../hooks/use-replace-placeholders'
import TakePhoto from '../../../components/communication/TakePhoto'
import SimpleSelectPhoto from '../../../components/communication/SimpleSelectPhoto'
import { Checkbox } from '../../../components/ui/form/checkbox'
import { TextInput } from '../../../components/ui/form/input'
import {
  MergeFieldButton,
  MergeFieldButtons,
} from '../../../components/ui/form/merge-field-buttons'
import { SchedulePicker } from '../../../components/ui/form/schedule-picker'
import { TextArea } from '../../../components/ui/form/textarea'
import {
  useBroadcastHtmlEmail,
  updateBroadcastHtmlEmail,
  resetBroadcastHtmlEmail,
} from '../broadcast-store'
import { useIsStore } from '../../../auth/utils'
import { sendFrom } from '../../../automations/constants'
import { convertServerUnlayerDesign } from '../../../unlayer/utils'
import { useBroadcastClientCounts, useBroadcastDetail } from '../queries'
import {
  useDeleteBroadcast,
  useSendBroadcast,
  useTestBroadcast,
  useUpdateBroadcast,
} from '../mutations'
import { checkForBlockedWords } from '../utils'
import { CreditsUsed } from './credits-used'
import { TestBroadcastButton } from './test-broadcast-button'
import { BroadcastClientFilter } from './broadcast-client-filter'
import { BroadcastLink } from './broadcast-link'
import { BroadcastPhoto } from './broadcast-photo'
import { BroadcastFormSection } from './broadcast-form-section'
import { BroadcastShopWithSelect } from './broadcast-shopwith-select'
import { SubscriberCount } from './subscriber-count'
import { Card, CardContent } from './ui'

import type { SelectChangeEventDetail } from '@ionic/core'
import type { FiltersForm } from '../../../clients/client-filters.schema'
import type { PhotoData } from '../../../hooks/use-photo'
import type {
  CreateBroadcastInputs,
  DU,
  FromUserRule,
} from '../broadcast.schema'
import type { Template } from '../../../hooks/templates/queries'
import type { QueueBroadcastInput } from '../mutations'

interface SelectCustomEvent extends CustomEvent {
  detail: SelectChangeEventDetail<FromUserRule>
  target: HTMLIonSelectElement
}

type ShopWithKeys = 'productIds' | 'lookIds' | 'collectionIds'
type ShopWithIds = { [K in ShopWithKeys]: Array<string> }

type FU = Extract<DU, { fromUserRule: 'fromUser' }>
type FU2 = Extract<DU, { fromUserRule: 'assignedTo' }>
type FormState = Pick<
  CreateBroadcastInputs,
  | 'title'
  | 'messageType'
  | 'smsMessage'
  | 'emailSubject'
  | 'emailMessage'
  | 'date'
  | 'fromUserRule'
  | 'fromUserId'
  | 'fromName'
  | 'fromEmailName'
  | 'fromEmailDomain'
>

type Props = {
  admin?: boolean
  backUrl: string
  broadcast: NonNullable<ReturnType<typeof useBroadcastDetail>['data']>
}

function useInitHtmlEmail(broadcast: Props['broadcast']) {
  React.useEffect(() => {
    updateBroadcastHtmlEmail({
      json: broadcast.unlayerDesign
        ? convertServerUnlayerDesign(broadcast.unlayerDesign)
        : undefined,
      img: broadcast.unlayerImageFull ?? undefined,
      html: (broadcast.unlayerDesign && broadcast.emailMessage) ?? undefined,
    })
  }, [
    broadcast.emailMessage,
    broadcast.unlayerDesign,
    broadcast.unlayerImageFull,
  ])
}

function composeBroadcastFilters({
  admin,
  filters,
}: {
  admin?: boolean
  filters: Parameters<typeof composeSearchFilters>[0]
}) {
  const csf = composeSearchFilters({
    admin,
    subscribersOnly: true,
    ...filters,
  })

  return csf
}

function EditBroadcastForm({ admin, backUrl, broadcast }: Props) {
  const [template, setTemplate] = React.useState<Template>()
  const [includeRecentContacts, setIncludeRecentContacts] = React.useState(
    !broadcast.filters.notContacted
  )
  const [currentEmailType, setCurrentEmailType] = React.useState<
    'html' | 'sms'
  >(
    !broadcast.emailMessage ? 'html' : !broadcast.unlayerDesign ? 'sms' : 'html'
  )

  const [attachments, updateAttachments] = useImmer<{
    smsAttachments: PhotoData | string | null
    emailAttachments: PhotoData | string | null
  }>({
    smsAttachments: broadcast.attachmentsSms
      ? broadcast.attachmentsSms[0]
      : null,
    emailAttachments: broadcast.attachmentsEmail
      ? broadcast.attachmentsEmail[0]
      : null,
  })

  const [filters, updateFilters] = useImmer<FiltersForm>(
    broadcast.filters ?? {}
  )

  const [shopWithIds, updateShopWithIds] = useImmer<{
    sms?: Partial<ShopWithIds>
    email?: Partial<ShopWithIds>
  }>({
    sms: {
      collectionIds: broadcast.collectionsSms?.map((c) => c.objectId),
      lookIds: broadcast.looksSms?.map((l) => l.objectId),
      productIds: broadcast.productsSms?.map((p) => p.objectId),
    },
    email: {
      collectionIds: broadcast.collectionsEmail?.map((c) => c.objectId),
      lookIds: broadcast.looksEmail?.map((l) => l.objectId),
      productIds: broadcast.productsEmail?.map((p) => p.objectId),
    },
  })

  const account = useAccount()
  const user = useUser()
  const { isFaherty } = useIsStore()
  const isAdminRoute = useIsAdminRoute()

  const canSmsMessage = isAdminRoute || user.hasTwilio

  const userHasCustomEmail =
    user.hasSendGrid && account.sendgridDomains.length > 0

  const smsMessageRef = useTextAreaRef()
  const emailMessageRef = useTextAreaRef()

  const setToast = useToast()
  const router = useIonRouter()
  const [presentAlert] = useIonAlert()

  const htmlEmail = useBroadcastHtmlEmail()

  const associatesQuery = useAssociates()
  const flatAssociates =
    associatesQuery.data?.pages
      .flatMap((d) => d.data)
      .filter((associate) =>
        userHasCustomEmail ? true : associate.objectId === user.objectId
      ) ?? []

  const templatesQuery = useTemplates()
  const { generateShopWithLinkPreview } = useShopWithLinks()
  const { getSmsMaxCharacterCount } = useSmsMaxCharacterCount()
  const smsCredits = useSmsCredits(admin ? 'admin' : 'associate')
  const { replacePlaceholders } = useReplacePlaceholders()

  const sendBroadcast = useSendBroadcast()
  const clientCounts = useBroadcastClientCounts(broadcast.objectId)
  const deleteBroadcast = useDeleteBroadcast()
  const updateBroadcast = useUpdateBroadcast()
  const testBroadcast = useTestBroadcast()

  const {
    control,
    formState: { dirtyFields },
    getValues,
    handleSubmit,
    register,
    reset,
    setValue,
    trigger,
  } = useForm<FormState>({
    defaultValues: {
      title: broadcast.title,
      date: broadcast.date?.iso ?? null,
      messageType: broadcast.messageType || 'prioSmsEmail', // OR short circuit cause pre finished API it could be ""
      smsMessage: broadcast.smsMessage ?? '',
      emailSubject: broadcast.emailSubject ?? '',
      emailMessage: broadcast.emailMessage ?? '',
      fromUserRule: broadcast.fromUserRule,
      fromUserId: broadcast.fromUser?.objectId,
      fromName: broadcast.fromName,
      fromEmailName: broadcast.fromEmail?.split('@')[0],
      fromEmailDomain: broadcast.fromEmail?.split('@')[1],
    },
  })

  React.useEffect(() => {
    if (!getValues('fromUserRule'))
      setValue('fromUserRule', broadcast.fromUserRule ?? 'fromUser', {
        shouldDirty: broadcast.fromUserRule ? false : true,
      })

    if (getValues('fromUserRule') === 'fromUser' && !getValues('fromUserId'))
      setValue('fromUserId', broadcast.fromUser?.objectId ?? user.objectId, {
        shouldDirty: broadcast.fromUser?.objectId ? false : true,
      })
  }, [
    broadcast.fromUser?.objectId,
    broadcast.fromUserRule,
    getValues,
    setValue,
    user.objectId,
  ])

  const watchSmsMessage = useWatch({ control, name: 'smsMessage' })
  const watchMessageType = useWatch({
    control,
    name: 'messageType',
  })
  const watchDate = useWatch({ control, name: 'date' })
  const watchFromUserRule = useWatch({ control, name: 'fromUserRule' })

  useInitHtmlEmail(broadcast)

  const isBroadcastDisabled =
    // Should clientcount query hold up filling out form?
    clientCounts.data?.totalCount === 0 ||
    updateBroadcast.isLoading ||
    sendBroadcast.isLoading ||
    sendBroadcast.isSuccess

  const isDisabled = broadcast.status !== 'draft'

  const maxCharacterCount = getSmsMaxCharacterCount(watchSmsMessage)
  const creditsUsed = clientCounts.data?.smsClientCount
    ? attachments.smsAttachments
      ? clientCounts.data.smsClientCount * 3
      : clientCounts.data.smsClientCount
    : 0

  const htmlPreview = htmlEmail?.img

  const requiresApproval = !isAdminRoute && isFaherty()
  const loadingMessage = 'Updating...'

  function composeDraft() {
    const currentState = getValues()

    const updatedFields = typedKeys(dirtyFields)
      .map((x) => ({
        field: x,
        value: getValues(x) || null,
      }))
      .reduce((acc, curr) => {
        return { ...acc, [curr.field]: curr.value }
      }, {} as CreateBroadcastInputs)

    return {
      objectId: broadcast.objectId,
      broadcast: {
        ...updatedFields,
        emailMessage: htmlEmail?.html || getValues('emailMessage'),
        filter: {
          ...composeBroadcastFilters({ admin, filters }),
        },
        // if there is no htmlEmail then explicitly set to null (undefined passes thru as no change)
        unlayerDesign: htmlEmail?.json ?? null,
        smsTemplateId: template?.smsTemplate?.objectId,
        emailTemplateId: template?.emailTemplate?.objectId,
        setAttachmentsSms:
          typeof attachments.smsAttachments === 'string'
            ? [attachments.smsAttachments]
            : attachments.smsAttachments !== null
            ? [
                {
                  content: attachments.smsAttachments.base64String,
                  name: attachments.smsAttachments.name,
                },
              ]
            : attachments.smsAttachments,
        setAttachmentsEmail:
          typeof attachments.emailAttachments === 'string'
            ? [attachments.emailAttachments]
            : attachments.emailAttachments !== null
            ? [
                {
                  content: attachments.emailAttachments.base64String,
                  name: attachments.emailAttachments.name,
                },
              ]
            : attachments.emailAttachments,
        collectionIdsSms: shopWithIds.sms?.collectionIds ?? null,
        lookIdsSms: shopWithIds.sms?.lookIds ?? null,
        productIdsSms: shopWithIds.sms?.productIds ?? null,
        collectionIdsEmail: shopWithIds.email?.collectionIds ?? null,
        lookIdsEmail: shopWithIds.email?.lookIds ?? null,
        productIdsEmail: shopWithIds.email?.productIds ?? null,
        fromEmailName: currentState.fromEmailName,
        fromEmailDomain: currentState.fromEmailDomain,
        requiresApproval,
      },
      isPartial: true,
    }
  }

  function composeShopWithLink(ids?: Partial<ShopWithIds>) {
    return ids?.productIds
      ? generateShopWithLinkPreview('productIds', ids?.productIds)
      : ids?.lookIds
      ? generateShopWithLinkPreview('lookIds', ids?.lookIds)
      : ids?.collectionIds
      ? generateShopWithLinkPreview('collectionIds', ids?.collectionIds)
      : ''
  }

  function handleTemplateSelect(
    event: CustomEvent<SelectChangeEventDetail<string>>
  ) {
    const selectedTemplate = templatesQuery.data?.find(
      (template) => template.objectId === event.detail.value
    )

    if (!selectedTemplate) {
      return
    }

    setTemplate(selectedTemplate)
    setValue(
      'smsMessage',
      replacePlaceholders(selectedTemplate.smsTemplate?.body ?? '')
    )
    setValue(
      'emailSubject',
      replacePlaceholders(selectedTemplate.emailTemplate?.subject ?? '')
    )
    setValue(
      'emailMessage',
      replacePlaceholders(selectedTemplate.emailTemplate?.body ?? '')
    )

    updateShopWithIds({
      sms: {
        productIds: selectedTemplate.products?.map((p) => p.objectId),
        lookIds: selectedTemplate.looks?.map((l) => l.objectId),
        collectionIds: selectedTemplate.collections?.map((c) => c.objectId),
      },
      email: {
        productIds: selectedTemplate.products?.map((p) => p.objectId),
        lookIds: selectedTemplate.looks?.map((l) => l.objectId),
        collectionIds: selectedTemplate.collections?.map((c) => c.objectId),
      },
    })

    // TODO: HACK!!! SHOULD EVENTUALLY MOVE TO SAME STRUCTURE AS TEMPLATES WITH ATTACHMENTS ON EACH "TEMPLATE" TYPE
    updateAttachments({
      smsAttachments: selectedTemplate.smsTemplate?.attachments
        ? selectedTemplate.smsTemplate.attachments[0]
        : null,
      emailAttachments: selectedTemplate.emailTemplate?.attachments
        ? selectedTemplate.emailTemplate.attachments[0]
        : null,
    })
  }

  function handleSmsShopWithSelect(
    type: 'productIds' | 'lookIds' | 'collectionIds',
    value: Array<string>
  ) {
    updateShopWithIds((draft) => {
      draft.sms = { [type]: value }
    })
  }

  function handleEmailShopWithSelect(
    type: 'productIds' | 'lookIds' | 'collectionIds',
    value: Array<string>
  ) {
    updateShopWithIds((draft) => {
      draft.email = { [type]: value }
    })
  }

  function handleShopWithDelete(type: 'sms' | 'email') {
    updateShopWithIds((draft) => {
      draft[type] = {}
    })
  }

  // TODO: MIGRATE TO HOOK FORM RESOLVER VALIDATION
  function validateBroadcast(data: FormState) {
    if (!smsCredits.data) {
      setToast({ message: 'There was an issue loading your credits.' })
      return false
    }
    if (!clientCounts.data) {
      setToast({ message: 'There was an issue retrieving the client counts.' })
      return false
    }

    const { smsClientCount, totalCount } = clientCounts.data

    if (creditsUsed > smsCredits.data?.smsCreditsRemaining) {
      setToast({
        message: `You do not have enough credits to ${
          data.date ? 'schedule' : 'send'
        } this broadcast. Please contact your OneShop representative to purchase more.`,
      })
      return false
    }

    if (totalCount === 0) {
      setToast({ message: 'No clients found' })
      return false
    }

    if (
      (data.messageType === 'sms' || data.messageType === 'prioSmsEmail') &&
      !canSmsMessage &&
      data.smsMessage.length > 0
    ) {
      setToast({
        message:
          'You do not have the correct permissions to send a text message. Please select Email message type.',
      })
      return false
    }

    if (
      (data.messageType === 'sms' || data.messageType === 'prioSmsEmail') &&
      canSmsMessage &&
      data.smsMessage.length === 0
    ) {
      setToast({ message: 'Missing text message' })
      return false
    }

    // TODO: MIGHT NOT NEED TO CHECK BOTH
    const emailMessage = data.emailMessage || htmlEmail?.json
    if (
      (data.messageType === 'email' || data.messageType === 'prioSmsEmail') &&
      (!data.emailSubject || !emailMessage)
    ) {
      setToast({ message: 'Missing email message or subject' })
      return false
    }

    if (smsClientCount > 0 && data.smsMessage.length > maxCharacterCount) {
      setToast({
        message: 'Text message is longer than the max allowed',
      })
      return false
    }

    const blockedWords = checkForBlockedWords({
      strToCheck: broadcast.smsMessage?.toLowerCase(),
      blockedList: forbiddenWords,
    })
    if (blockedWords.length > 0) {
      setToast({
        message: `The following words are restricted: ${blockedWords.join(
          ', '
        )}. Please revise and try again.`,
      })
      return false
    }

    if (
      userHasCustomEmail &&
      data.fromUserId &&
      data.messageType !== 'sms' &&
      (data.fromName == null ||
        data.fromEmailName == null ||
        data.fromEmailDomain == null)
    ) {
      setToast({
        message: 'You must set both a Sender Name and Sender Email Address.',
      })
      return false
    }

    return true
  }

  function handleRecentlyContacted(bool: boolean) {
    const notContacted = bool ? undefined : { day: 1 }

    // checkbox
    setIncludeRecentContacts(bool)
    // update the filters to be current
    updateFilters((draft) => {
      draft.notContacted = notContacted
    })
    // save broadcast so can get updated count
    updateBroadcast.mutate({
      objectId: broadcast.objectId,
      broadcast: {
        filter: {
          ...composeBroadcastFilters({ admin, filters }),
          notContacted,
        },
      },
      isPartial: true,
    })
  }

  function handleDeleteBroadcast() {
    deleteBroadcast.mutate(broadcast.objectId, {
      onSuccess: () =>
        router.canGoBack()
          ? router.goBack()
          : router.push(backUrl, 'back', 'pop'),
    })
  }

  function handleSaveDraft() {
    const currentState = getValues()
    updateBroadcast.mutate(composeDraft(), {
      onSuccess: () => {
        reset(currentState)

        setToast({
          message: 'Your draft was successfully saved.',
          color: 'yellow',
        })
      },
    })
  }

  async function handleSendBroadcast(formData: FormState) {
    const isValidBroadcast = validateBroadcast(formData)
    if (!isValidBroadcast) return

    presentAlert({
      header: 'Alert',
      message: `You are about to
      ${formData.date ? 'schedule' : 'send'} this broadcast to ${
        clientCounts.data?.totalCount
      } customers. ${
        !formData.date ? 'This cannot be undone. Are you sure?' : ''
      }`,
      buttons: [
        'Cancel',
        {
          text: formData.date ? 'Schedule' : 'Send',
          handler: async () => {
            const broadcastData: Omit<
              QueueBroadcastInput,
              'smsClientIds' | 'emailClientIds' | 'type'
            > = {
              title:
                formData.title || `Broadcast ${format(new Date(), 'MM/dd/yy')}`,
              date: formData.date,
              smsMessage: formData.smsMessage ? formData.smsMessage : undefined,
              emailSubject: formData.emailSubject
                ? formData.emailSubject
                : undefined,
              emailMessage: htmlEmail?.html || formData.emailMessage,
              unlayerDesign: htmlEmail?.json,
              smsTemplateId: template?.smsTemplate?.objectId,
              emailTemplateId: template?.emailTemplate?.objectId,
              setAttachmentsSms:
                typeof attachments.smsAttachments === 'string'
                  ? [attachments.smsAttachments]
                  : attachments.smsAttachments !== null
                  ? [
                      {
                        content: attachments.smsAttachments.base64String,
                        name: attachments.smsAttachments.name,
                      },
                    ]
                  : attachments.smsAttachments,
              setAttachmentsEmail:
                typeof attachments.emailAttachments === 'string'
                  ? [attachments.emailAttachments]
                  : attachments.emailAttachments !== null
                  ? [
                      {
                        content: attachments.emailAttachments.base64String,
                        name: attachments.emailAttachments.name,
                      },
                    ]
                  : attachments.emailAttachments,
              collectionIdsSms: shopWithIds.sms?.collectionIds,
              lookIdsSms: shopWithIds.sms?.lookIds,
              productIdsSms: shopWithIds.sms?.productIds,
              collectionIdsEmail: shopWithIds.email?.collectionIds,
              lookIdsEmail: shopWithIds.email?.lookIds,
              productIdsEmail: shopWithIds.email?.productIds,
              filter: {
                ...composeBroadcastFilters({ admin, filters }),
              },
              fromUserRule: formData.fromUserRule ?? 'fromUser',
              fromUserId: formData.fromUserId ?? user.objectId,
              fromName: formData.fromName,
              fromEmailName: formData.fromEmailName,
              fromEmailDomain: formData.fromEmailDomain,
            }

            updateBroadcast.mutate(
              {
                objectId: broadcast.objectId,
                broadcast: broadcastData,
              },
              {
                onSuccess: () => {
                  sendBroadcast.mutate(broadcast.objectId)
                  router.canGoBack()
                    ? router.goBack()
                    : router.push(backUrl, 'back', 'pop')
                },
              }
            )
          },
        },
      ],
    })
  }

  return (
    <>
      <IonContent>
        <div className="ion-padding">
          <div className="ion-padding-top flex items-center justify-center gap-x-3">
            <SubscriberCount />
            {/* <IonRouterLink
              className="text-lg font-semibold"
              routerLink={`${url}/clients`}
              color="secondary"
            >
              Total Subscribers:{' '}
              {smsClientCount +
                emailClientCount}
            </IonRouterLink> */}
            <BroadcastClientFilter
              filters={filters}
              isAdmin={admin}
              onSearch={(filterData) => {
                updateFilters(filterData)
                updateBroadcast.mutate({
                  objectId: broadcast.objectId,
                  broadcast: {
                    filter: composeBroadcastFilters({
                      admin,
                      filters: filterData,
                    }),
                  },
                  isPartial: true,
                })
              }}
            />
          </div>

          <CreditsUsed
            isAdmin={admin}
            creditsUsed={creditsUsed}
            isLoading={clientCounts.isLoading}
          />

          {/* Buttons */}
          <div className="mx-auto max-w-lg space-y-3">
            <IonButton
              color="yellow"
              fill="solid"
              expand="block"
              size="small"
              disabled={isBroadcastDisabled}
              onClick={handleSubmit(handleSendBroadcast)}
            >
              {watchDate ? 'Schedule Broadcast' : 'Send Broadcast'}
            </IonButton>

            <Controller
              name="date"
              control={control}
              render={({ field: { value, onChange } }) => (
                <SchedulePicker
                  label="Schedule Broadcast"
                  value={value}
                  disabled={isDisabled}
                  onChange={onChange}
                  onReset={() => setValue('date', '')}
                />
              )}
            />

            <div className="flex gap-x-3">
              <IonButton
                size="small"
                color="danger"
                fill="outline"
                className="flex-grow"
                disabled={deleteBroadcast.isLoading}
                onClick={handleDeleteBroadcast}
              >
                Delete {broadcast.status === 'draft' ? 'Draft' : 'Broadcast'}
              </IonButton>
              {broadcast.status === 'pending' ? (
                <IonButton
                  size="small"
                  color="yellow"
                  fill="outline"
                  className="flex-grow"
                  onClick={() =>
                    updateBroadcast.mutate({
                      objectId: broadcast.objectId,
                      broadcast: { status: 'draft', requiresApproval },
                      isPartial: true,
                    })
                  }
                >
                  Revert to Draft
                </IonButton>
              ) : broadcast.status === 'draft' ? (
                <>
                  <IonButton
                    size="small"
                    color="yellow"
                    fill="outline"
                    className="flex-grow"
                    onClick={handleSaveDraft}
                  >
                    Save Draft
                  </IonButton>

                  <TestBroadcastButton
                    disabled={
                      updateBroadcast.isLoading || testBroadcast.isLoading
                    }
                    onSend={(data) =>
                      updateBroadcast.mutate(composeDraft(), {
                        onSuccess: () => {
                          testBroadcast.mutate({
                            broadcastId: broadcast.objectId,
                            ...data,
                          })
                        },
                      })
                    }
                  />
                </>
              ) : null}
            </div>
          </div>

          {/* Title */}
          <div className="py-2">
            <TextInput
              disabled={isDisabled}
              control={control}
              name="title"
              label="Title"
            />
          </div>

          {/* Template Select */}
          <div className="py-2">
            <IonItem style={{ '--padding-start': 0 }}>
              <IonLabel>Select a Template</IonLabel>
              <IonSelect
                interfaceOptions={{ cssClass: 'dark-ionic-filter' }}
                onIonChange={handleTemplateSelect}
                okText="OK"
                cancelText="Dismiss"
                disabled={isDisabled}
                className="bg-transparent"
              >
                {templatesQuery.data?.map((template, index) => (
                  <IonSelectOption key={index} value={template.objectId}>
                    {template.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
          </div>

          {/* Recently Contacted */}
          <div className="py-2">
            <Checkbox
              id="recentlyContacted"
              disabled={isDisabled}
              checked={includeRecentContacts}
              label="Include Recently Contacted"
              onChange={handleRecentlyContacted}
            />
          </div>

          {/* Send From */}
          {admin ? (
            <BroadcastFormSection>
              <h3>Send From</h3>
              <IonRow>
                <IonCol size="6">
                  <Controller
                    name="fromUserRule"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <IonSelect
                        disabled={isDisabled}
                        className="bg-ion-color-step-100 rounded"
                        value={value}
                        // w/o this the form value was missing fields when switching this select w/o selecting id
                        // onIonBlur={recomputeFormValue}
                        onIonChange={(e: SelectCustomEvent) => {
                          onChange(e.detail.value)
                          if (e.detail.value !== 'fromUser') {
                            setValue('fromUserId', null, { shouldDirty: true })
                            setValue('fromEmailName', null, {
                              shouldDirty: true,
                            })
                            setValue('fromEmailDomain', null, {
                              shouldDirty: true,
                            })
                            setValue('fromName', null, { shouldDirty: true })
                          } else
                            setValue('fromUserId', user.objectId, {
                              shouldDirty: true,
                            })
                        }}
                      >
                        {sendFrom.map((from) => (
                          <IonSelectOption key={from.value} value={from.value}>
                            {from.label}
                          </IonSelectOption>
                        ))}
                      </IonSelect>
                    )}
                  />
                </IonCol>

                {watchFromUserRule === 'fromUser' ? (
                  <IonCol size="6">
                    <Controller
                      name="fromUserId"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <IonSelect
                          style={{ '--placeholder-color': 'text-white' }}
                          disabled={isDisabled}
                          className="bg-ion-color-step-100 rounded"
                          value={value}
                          onIonChange={(e) => {
                            const associate = flatAssociates.find(
                              (a) => a.objectId === e.detail.value
                            )
                            if (!associate) return
                            setValue('fromName', associate.displayName)
                            setValue(
                              'fromEmailDomain',
                              account.sendgridDomains[0]
                            )
                            setValue(
                              'fromEmailName',
                              associate.twilioProxyEmail?.split('@')[0] ?? null
                            )
                            onChange(e.detail.value)
                          }}
                          placeholder="Select Sylist"
                        >
                          {flatAssociates.map((associate) => (
                            <IonSelectOption
                              key={associate.objectId}
                              value={associate.objectId}
                            >
                              {associate.displayName}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      )}
                    />
                  </IonCol>
                ) : null}
              </IonRow>

              {watchFromUserRule === 'fromUser' &&
              (user.hasSendGrid ||
                getValues([
                  'fromEmailName',
                  'fromEmailDomain',
                  'fromName',
                ]).some((value) => value != null)) ? (
                <div className="space-y-2 py-4">
                  <div className="flex-shrink-0 basis-2/5">
                    <TextInput
                      control={control}
                      name="fromName"
                      label="Sender Name"
                    />
                  </div>
                  {account.sendgridDomains.length > 0 && user.hasSendGrid && (
                    <div className="flex w-full items-end gap-x-2">
                      <div className="flex-1">
                        <TextInput
                          control={control}
                          name="fromEmailName"
                          className="lowercase"
                          label="Sender Email Address"
                        />
                      </div>
                      <span className="text-lg">@</span>
                      <Controller
                        name="fromEmailDomain"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <IonSelect
                            style={{ '--placeholder-color': 'text-white' }}
                            className="bg-ion-color-step-100 rounded"
                            value={value}
                            onIonChange={(e) => onChange(e.detail.value)}
                          >
                            {account.sendgridDomains.map((d: string) => (
                              <IonSelectOption key={d}>{d}</IonSelectOption>
                            ))}
                          </IonSelect>
                        )}
                      />
                    </div>
                  )}
                </div>
              ) : null}
            </BroadcastFormSection>
          ) : (
            <>
              <input type="hidden" {...register('fromUserRule')} />
              <input type="hidden" {...register('fromUserId')} />
              <input type="hidden" {...register('fromName')} />
              <input type="hidden" {...register('fromEmailName')} />
              <input type="hidden" {...register('fromEmailDomain')} />
            </>
          )}

          {/* Message Type */}
          <BroadcastFormSection>
            <div className="flex flex-col gap-y-4 py-2">
              <div className="space-y-1">
                <p className="font-semibold">
                  Send Via{' '}
                  <span className="text-sm font-normal">(*required)</span>
                </p>
                <Controller
                  name="messageType"
                  control={control}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState,
                  }) => (
                    <RadioGroup
                      className="border-ion-color-step-950 flex items-center gap-5"
                      disabled={isDisabled}
                      value={value}
                      onBlur={onBlur}
                      onChange={(e) => {
                        onChange(e)
                        updateBroadcast.mutate({
                          objectId: broadcast.objectId,
                          broadcast: {
                            messageType: e,
                          },
                          isPartial: true,
                        })
                        fieldState.isTouched &&
                          trigger([
                            'smsMessage',
                            'emailSubject',
                            'emailMessage',
                          ])
                      }}
                    >
                      <RadioGroup.Option
                        value="sms"
                        className="flex cursor-pointer items-center rounded-full ui-disabled:cursor-default"
                      >
                        <span className="border-ion-color-secondary ui-checked:bg-ion-color-yellow size-4 rounded-full border" />
                        <span className="flex cursor-pointer items-center px-1.5 leading-3 ui-disabled:cursor-default">
                          Text
                        </span>
                      </RadioGroup.Option>
                      <RadioGroup.Option
                        value="email"
                        className="flex cursor-pointer items-center rounded-full ui-disabled:cursor-default"
                      >
                        <span className="border-ion-color-secondary ui-checked:bg-ion-color-yellow size-4 rounded-full border" />
                        <span className="flex cursor-pointer items-center px-1.5 leading-3 ui-disabled:cursor-default">
                          Email
                        </span>
                      </RadioGroup.Option>
                      <RadioGroup.Option
                        value="prioSmsEmail"
                        className="flex cursor-pointer items-center rounded-full ui-disabled:cursor-default"
                      >
                        <span className="border-ion-color-secondary ui-checked:bg-ion-color-yellow size-4 rounded-full border" />
                        <span className="flex cursor-pointer items-center px-1.5 leading-3 ui-disabled:cursor-default">
                          Text or Email
                        </span>
                      </RadioGroup.Option>
                    </RadioGroup>
                  )}
                />
                {/* <ErrorMessage
                errors={errors}
                name="messageType"
                render={({ message }) => (
                  <p className="text-ion-color-danger">{message}</p>
                )}
              /> */}
              </div>
              <div className="flex items-center gap-2">
                <InformationCircleIcon className="text-ion-color-secondary size-5 flex-shrink-0" />
                <p className="italic leading-none">
                  When "Text or Email" is selected, text message is prioritized.
                </p>
              </div>
            </div>
          </BroadcastFormSection>

          {/* SMS */}
          {watchMessageType === 'sms' || watchMessageType === 'prioSmsEmail' ? (
            <div className="py-2">
              <IonRow className="ion-padding-horizontal ion-justify-content-between ion-align-items-center">
                <IonCol size="auto">
                  <h5>Text Message:</h5>
                </IonCol>
                <IonCol size="auto">
                  <IonButton
                    // routerLink={`${url}/clients?list=sms`}
                    size="small"
                    color="tertiary"
                    fill="clear"
                    className="pointer-events-none"
                  >
                    {clientCounts.data?.smsClientCount} Clients
                  </IonButton>
                </IonCol>
              </IonRow>
              <Card color="secondary">
                <CardContent>
                  {!isDisabled ? (
                    <div className="flex justify-end">
                      <IonButton
                        size="small"
                        fill="outline"
                        onClick={() => setValue('smsMessage', '')}
                      >
                        Clear
                      </IonButton>
                    </div>
                  ) : null}
                  <Controller
                    control={control}
                    name="smsMessage"
                    render={({ field: { value, onChange } }) => (
                      <TextArea
                        ref={smsMessageRef}
                        value={value}
                        onIonChange={onChange}
                        placeholder="Write your text here..."
                        className="min-h-16.5"
                        autoGrow
                        autocapitalize="on"
                        disabled={isDisabled}
                      />
                    )}
                  />
                  <IonRow className="ion-justify-content-between">
                    {!isDisabled ? (
                      <IonCol size="auto" className="space-x-2">
                        <MergeFieldButtons
                          mergeRef={smsMessageRef}
                          onInsert={(value) => setValue('smsMessage', value)}
                        >
                          <MergeFieldButton placeholder="<First Name>">
                            First Name
                          </MergeFieldButton>
                          <MergeFieldButton placeholder="<Last Name>">
                            Last Name
                          </MergeFieldButton>
                          <MergeFieldButton placeholder="<Lifetime Loyalty Points>">
                            Lifetime Loyalty Points
                          </MergeFieldButton>
                          <MergeFieldButton placeholder="<Active Loyalty Points>">
                            Active Loyalty Points
                          </MergeFieldButton>
                          <MergeFieldButton placeholder="<Active Loyalty Visits>">
                            Active Loyalty Visits
                          </MergeFieldButton>
                          <MergeFieldButton placeholder="<Active Loyalty Rewards>">
                            Active Loyalty Rewards
                          </MergeFieldButton>
                        </MergeFieldButtons>
                      </IonCol>
                    ) : null}
                    <IonCol size="auto" className="ion-align-self-end">
                      <p>
                        <IonText
                          color={
                            watchSmsMessage.length > maxCharacterCount
                              ? 'danger'
                              : 'dark'
                          }
                        >
                          {watchSmsMessage.length}
                        </IonText>
                        /{maxCharacterCount}
                      </p>
                    </IonCol>
                  </IonRow>
                  {composeShopWithLink(shopWithIds.sms) ? (
                    <BroadcastLink
                      shopWithLink={composeShopWithLink(shopWithIds.sms)}
                      onDelete={() => handleShopWithDelete('sms')}
                    />
                  ) : null}
                  {attachments.smsAttachments ? (
                    <BroadcastPhoto
                      photo={attachments.smsAttachments}
                      onDelete={() =>
                        updateAttachments((draft) => {
                          draft.smsAttachments = null
                        })
                      }
                    />
                  ) : null}
                </CardContent>
              </Card>

              {!isDisabled ? (
                <div className="flex justify-end gap-x-2 px-4 py-2">
                  <TakePhoto
                    onTakePhoto={(e) => {
                      updateAttachments((draft) => {
                        draft.smsAttachments = e
                      })
                    }}
                  />
                  <SimpleSelectPhoto
                    onSelectPhoto={(e) => {
                      updateAttachments((draft) => {
                        draft.smsAttachments = e
                      })
                    }}
                  />
                  <BroadcastShopWithSelect
                    onConfirm={handleSmsShopWithSelect}
                  />
                </div>
              ) : null}
            </div>
          ) : null}

          {/* Email */}
          {watchMessageType === 'email' ||
          watchMessageType === 'prioSmsEmail' ? (
            <div className="py-2">
              {!isDisabled ? (
                <IonRow className="justify-center">
                  <IonCol size="auto" className="justify-center">
                    {currentEmailType === 'sms' ? (
                      <IonButton
                        size="small"
                        color="yellow"
                        onClick={() => {
                          setCurrentEmailType('html')
                          router.push(
                            `./${
                              broadcast.objectId
                            }/html-email?displayConditions=${
                              getValues().fromUserRule === 'assignedTo'
                                ? 'show'
                                : 'hide'
                            }`
                          )
                        }}
                      >
                        Create HTML Email
                      </IonButton>
                    ) : (
                      <div className="flex gap-x-3">
                        <IonButton
                          size="small"
                          color="yellow"
                          onClick={() => {
                            router.push(
                              `./${
                                broadcast.objectId
                              }/html-email?displayConditions=${
                                getValues().fromUserRule === 'assignedTo'
                                  ? 'show'
                                  : 'hide'
                              }`
                            )
                          }}
                        >
                          Edit HTML Email
                        </IonButton>
                        <IonButton
                          size="small"
                          color="secondary"
                          onClick={() => {
                            setCurrentEmailType('sms')
                            setValue('emailMessage', '')
                            resetBroadcastHtmlEmail()
                          }}
                        >
                          Write Plain Text Email
                        </IonButton>
                      </div>
                    )}
                  </IonCol>
                </IonRow>
              ) : null}

              <IonRow className="ion-padding-horizontal ion-justify-content-between ion-align-items-center">
                <IonCol size="auto">
                  <h5 className="space-x-2">
                    Email:
                    {htmlEmail?.html?.includes('{{#') ? (
                      <span className="font-semibold">
                        This email contains dynamic content.
                      </span>
                    ) : null}
                  </h5>
                </IonCol>
                <IonCol size="auto">
                  <IonButton
                    // routerLink={`${url}/clients?list=email`}
                    size="small"
                    color="tertiary"
                    fill="clear"
                    className="pointer-events-none"
                  >
                    {clientCounts.data?.emailClientCount} Clients
                  </IonButton>
                </IonCol>
              </IonRow>

              <div className="relative">
                <Card color="secondary">
                  <CardContent>
                    <IonItem color="secondary" style={{ '--padding-start': 0 }}>
                      <IonLabel>Subject:</IonLabel>
                      <Controller
                        control={control}
                        name="emailSubject"
                        render={({ field: { value, onChange } }) => (
                          <IonInput
                            value={value}
                            onIonChange={onChange}
                            name="subject"
                            autocapitalize="on"
                            disabled={isDisabled}
                          />
                        )}
                      />
                    </IonItem>
                    <div className="ion-padding-top">
                      {!isDisabled ? (
                        <div className="flex justify-end">
                          <IonButton
                            size="small"
                            fill="outline"
                            onClick={() => {
                              setValue('emailMessage', '')
                              resetBroadcastHtmlEmail()
                              setCurrentEmailType('sms')
                            }}
                          >
                            Clear
                          </IonButton>
                        </div>
                      ) : null}
                      {htmlPreview ? (
                        <div className="flex justify-center">
                          <IonImg alt="Email Preview" src={htmlPreview} />
                        </div>
                      ) : (
                        <>
                          <Controller
                            control={control}
                            name="emailMessage"
                            render={({ field: { value, onChange } }) => (
                              <TextArea
                                ref={emailMessageRef}
                                value={value}
                                onIonChange={onChange}
                                placeholder="Write your email here..."
                                autoGrow
                                autocapitalize="on"
                                disabled={isDisabled}
                              />
                            )}
                          />
                          {!isDisabled ? (
                            <IonRow className="ion-justify-content-between">
                              <IonCol size="auto" className="space-x-2">
                                <MergeFieldButtons
                                  mergeRef={emailMessageRef}
                                  onInsert={(value) =>
                                    setValue('emailMessage', value)
                                  }
                                >
                                  <MergeFieldButton placeholder="<First Name>">
                                    First Name
                                  </MergeFieldButton>
                                  <MergeFieldButton placeholder="<Last Name>">
                                    Last Name
                                  </MergeFieldButton>
                                  <MergeFieldButton placeholder="<Lifetime Loyalty Points>">
                                    Lifetime Loyalty Points
                                  </MergeFieldButton>
                                  <MergeFieldButton placeholder="<Active Loyalty Points>">
                                    Active Loyalty Points
                                  </MergeFieldButton>
                                  <MergeFieldButton placeholder="<Active Loyalty Visits>">
                                    Active Loyalty Visits
                                  </MergeFieldButton>
                                  <MergeFieldButton placeholder="<Active Loyalty Rewards>">
                                    Active Loyalty Rewards
                                  </MergeFieldButton>
                                </MergeFieldButtons>
                              </IonCol>
                            </IonRow>
                          ) : null}
                          {composeShopWithLink(shopWithIds.email) ? (
                            <BroadcastLink
                              shopWithLink={composeShopWithLink(
                                shopWithIds.email
                              )}
                              onDelete={() => handleShopWithDelete('email')}
                            />
                          ) : null}
                          {attachments.emailAttachments ? (
                            <BroadcastPhoto
                              photo={attachments.emailAttachments}
                              onDelete={() =>
                                updateAttachments((draft) => {
                                  draft.emailAttachments = null
                                })
                              }
                            />
                          ) : null}
                        </>
                      )}
                    </div>
                  </CardContent>
                </Card>
                {currentEmailType === 'html' && !broadcast.unlayerDesign ? (
                  <button
                    className="absolute inset-0 flex items-center justify-center bg-black/60"
                    onClick={() =>
                      router.push(
                        `./${broadcast.objectId}/html-email?displayConditions=${
                          getValues().fromUserRule === 'assignedTo'
                            ? 'show'
                            : 'hide'
                        }`
                      )
                    }
                  >
                    <span className="bg-ion-color-yellow rounded-lg p-3 text-lg text-black">
                      Design Email
                    </span>
                  </button>
                ) : null}
              </div>

              {!htmlPreview && !isDisabled ? (
                <div className="flex justify-end gap-x-2 px-4 py-2">
                  <TakePhoto
                    onTakePhoto={(e) =>
                      updateAttachments((draft) => {
                        draft.emailAttachments = e
                      })
                    }
                  />
                  <SimpleSelectPhoto
                    onSelectPhoto={(e) =>
                      updateAttachments((draft) => {
                        draft.emailAttachments = e
                      })
                    }
                  />
                  <BroadcastShopWithSelect
                    onConfirm={handleEmailShopWithSelect}
                  />
                </div>
              ) : null}
            </div>
          ) : null}
        </div>

        <IonLoading
          isOpen={updateBroadcast.isLoading}
          message={loadingMessage}
        />
      </IonContent>
    </>
  )
}

export { EditBroadcastForm }

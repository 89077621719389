import placeholder from '../../../resources/no-image-product.png'
import { cn } from '../../../utils/cn'

import type { Nullish } from '../../../types'

function ShopWithCardMedia({
  ratio,
  shadow,
  src,
}: {
  ratio?: boolean
  shadow?: boolean
  src: Nullish<string>
}) {
  return (
    <div
      style={{
        backgroundImage: `url("${src ?? placeholder}")`,
      }}
      className={cn(
        'bg-cover bg-top bg-no-repeat',
        { 'aspect-[3/4]': ratio },
        {
          'shadow-[0_1px_3px_0_rgba(0,0,0,0.1),0_1px_2px_0_rgba(0,0,0,0.06)]':
            shadow,
        }
      )}
    ></div>
  )
}

export { ShopWithCardMedia }

import * as React from 'react'
import {
  IonButton,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  SelectChangeEventDetail,
} from '@ionic/react'
import { IonSelectCustomEvent } from '@ionic/core'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { zodResolver } from '@hookform/resolvers/zod'
import { fromZodError } from 'zod-validation-error'
import { dequal } from 'dequal/lite'

import { useAccount } from '../../../contexts/authContext'
import { useToast } from '../../../contexts/toastContext'
import { useAdminGroups } from '../../../hooks/queries/use-associate'
import { StyledError } from '../../../components/ui/form/errors'
import { TextInput } from '../../../components/ui/form/input'
import { useCreateUser } from '../mutations'
import { addUserSchema } from '../user-setup.schema'

import type { UserInput } from '../user-setup.schema'

type Props = { onCancel: () => void; onSuccess: () => void }
type Role = 'admin' | 'associate'

const defaultValues: Partial<UserInput> = {
  firstName: '',
  lastName: '',
  email: '',
  displayName: '',
  // optional
  goal: '',
  workDays: '',
  twilioProxyEmail: '',
}

const userRoles: Record<
  string,
  { value: Array<{ role: Role; groupIds: Array<string> }>; label: string }
> = {
  associate: {
    value: [{ role: 'associate', groupIds: [] }],
    label: 'Associate',
  },
  admin: { value: [{ role: 'admin', groupIds: [] }], label: 'Admin' },
  both: {
    value: [
      { role: 'admin', groupIds: [] },
      { role: 'associate', groupIds: [] },
    ],
    label: 'Both',
  },
}

function AddUserForm({ onCancel, onSuccess }: Props) {
  const account = useAccount()
  const isClienteling = account.account.settings.clienteling

  const [selectedRole, setSelectedRole] = React.useState<
    keyof typeof userRoles | null
  >(isClienteling ? null : 'admin')

  const setToast = useToast()

  const stores = useAdminGroups()
  const createUser = useCreateUser()

  const {
    control,
    formState: { errors },
    ...formMethods
  } = useForm<UserInput>({
    defaultValues: {
      ...defaultValues,
      groupMemberRoles: !isClienteling
        ? [
            {
              groupIds: [],
              posId: '',
              role: 'admin',
            },
          ]
        : undefined,
    },

    resolver: zodResolver(addUserSchema),
  })

  const groupMemberRoles = useFieldArray({ control, name: 'groupMemberRoles' })

  function handleSubmit(data: UserInput) {
    const twilioProxyEmail =
      account.sendgridDomain && data.twilioProxyEmail?.length
        ? `${data.twilioProxyEmail}@${account.sendgridDomain}`
        : undefined

    const parsedInput = addUserSchema.safeParse(data)

    if (!parsedInput.success)
      return setToast({
        message: `Something went wrong adding the user: ${
          fromZodError(parsedInput.error).message
        }`,
        color: 'danger',
      })

    createUser.mutate(
      { ...parsedInput.data, twilioProxyEmail },
      { onSuccess: () => onSuccess() }
    )
  }

  return (
    <form onSubmit={formMethods.handleSubmit(handleSubmit)} className="h-full">
      <div className="flex h-full flex-col justify-between">
        <div className="space-y-3">
          <div className="flex gap-x-3">
            <div className="flex-1">
              <TextInput
                color="light"
                control={control}
                name="firstName"
                label="First Name"
              />
              <ErrorMessage as={StyledError} name="firstName" errors={errors} />
            </div>
            <div className="flex-1">
              <TextInput
                control={control}
                name="lastName"
                color="light"
                label="Last Name"
              />
              <ErrorMessage as={StyledError} name="lastName" errors={errors} />
            </div>
          </div>

          <div>
            <TextInput
              control={control}
              name="email"
              label="Email"
              color="light"
            />
            <ErrorMessage as={StyledError} name="email" errors={errors} />
          </div>

          <TextInput
            control={control}
            name="displayName"
            label="Display Name"
            color="light"
          />
          {account.sendgridDomain ? (
            <div className="flex items-end gap-x-3">
              <span className="flex-1">
                <TextInput
                  control={control}
                  name="twilioProxyEmail"
                  label="Sender Email"
                  color="light"
                />
              </span>
              <span>@{account.sendgridDomain}</span>
            </div>
          ) : null}

          <div className="flex gap-x-3">
            <div>
              <TextInput
                type="number"
                control={control}
                name="goal"
                label="Goal"
                color="light"
              />
            </div>
            <div>
              <TextInput
                type="number"
                control={control}
                name="workDays"
                label="Work Days"
                color="light"
              />
            </div>
          </div>

          <IonItem
            color="secondary"
            lines="none"
            style={{ '--padding-start': 0 }}
          >
            <IonLabel className="font-semibold">Role</IonLabel>

            {isClienteling ? (
              <IonSelect
                value={selectedRole}
                onIonChange={(
                  e: IonSelectCustomEvent<SelectChangeEventDetail<Role>>
                ) => {
                  setSelectedRole(e.detail.value)
                  groupMemberRoles.replace(userRoles[e.detail.value].value)
                }}
              >
                {Object.entries(userRoles).map(([k, v]) => (
                  <IonSelectOption key={k} value={k}>
                    {v.label}
                  </IonSelectOption>
                ))}
              </IonSelect>
            ) : null}
          </IonItem>

          {selectedRole ? (
            <div className="space-y-2 rounded border border-slate-500 bg-slate-100 p-4">
              <div className="grid grid-cols-[max-content_1fr_1fr] items-end justify-between gap-x-4 gap-y-3">
                {groupMemberRoles.fields.map((groupMemberRole, index) => (
                  <React.Fragment key={groupMemberRole.id}>
                    <div>{userRoles[groupMemberRole.role].label}</div>
                    <div>
                      <IonItem color="secondary" lines="none">
                        <IonLabel>Store</IonLabel>
                        <Controller
                          shouldUnregister
                          name={`groupMemberRoles.${index}.groupIds`}
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <IonSelect
                              multiple
                              color="secondary"
                              value={value}
                              onIonChange={(e) => {
                                // WORKAROUND: Look into why using onChange directly is setting then unsetting the value
                                if (!e.detail.value) return

                                // WORKAROUND: Ionic v6 multiple infinite rerender bug
                                if (
                                  dequal(
                                    JSON.stringify(e.detail.value),
                                    JSON.stringify(value)
                                  )
                                ) {
                                  return
                                }
                                // WORKAROUND: incorrect formatting multiple select
                                if (!Array.isArray(e.detail.value)) return

                                onChange(e.detail.value)
                              }}
                            >
                              {stores.data?.map((store) => (
                                <IonSelectOption
                                  key={store.objectId}
                                  value={store.objectId}
                                >
                                  {store.name}
                                </IonSelectOption>
                              ))}
                            </IonSelect>
                          )}
                        />
                      </IonItem>
                      <ErrorMessage
                        as={StyledError}
                        name="groupId"
                        errors={errors}
                      />
                    </div>
                    <div>
                      {groupMemberRole.role !== 'admin' ? (
                        <TextInput
                          label="POS ID"
                          color="light"
                          control={control}
                          name={`groupMemberRoles.${index}.posId`}
                        />
                      ) : null}
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
          ) : null}
          <ErrorMessage
            name="groupMemberRoles"
            errors={errors}
            as={StyledError}
          />
        </div>

        <div className="flex gap-x-3 py-3">
          <IonButton fill="outline" onClick={onCancel}>
            Cancel
          </IonButton>
          <IonButton color="yellow" type="submit">
            Add User
          </IonButton>
        </div>
      </div>
    </form>
  )
}

export { AddUserForm }

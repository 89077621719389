import {
  IonFab,
  IonFabButton,
  IonIcon,
  isPlatform,
  useIonActionSheet,
  useIonRouter,
} from '@ionic/react'
import { add } from 'ionicons/icons'
import { FilePicker } from '@capawesome/capacitor-file-picker'

import { updatePost } from '../../social-sharing/posts/post-store'
import { usePhoto } from '../../hooks/use-photo'

import type { PhotoData } from '../../hooks/use-photo'

function CreateAssetFAB() {
  const [present] = useIonActionSheet()

  const { selectPhotos, takePhoto } = usePhoto()

  const router = useIonRouter()

  function handlePhoto(p: PhotoData) {
    updatePost({
      media: {
        type: 'photo',
        coverPhoto: p,
      },
    })
    router.push('/tabs/shopwith/look/create')
  }

  async function pickVideo() {
    try {
      if (isPlatform('hybrid')) {
        const result = await FilePicker.pickVideos({
          limit: 1,
        })

        return result.files[0]
      } else {
        const result = await FilePicker.pickFiles({
          limit: 1,
          types: ['video/*'],
        })

        return result.files[0]
      }
    } catch (e) {
      console.log({ e })
      const errorMessage =
        e instanceof Error ? e.message : `Unknown Error, ${e}`
      throw new Error(
        `There was a problem selecting the video. ${errorMessage}`
      )
    }
  }

  async function handleSelectVideo() {
    // init store that this is a video post
    updatePost({
      media: { type: 'video', video: { mimeType: '', name: '', size: 0 } },
    })

    router.push('/tabs/shopwith/look/create')

    try {
      const file = await pickVideo()
      updatePost({ media: { type: 'video', video: file } })
    } catch (e) {
      router.push('/tabs/shopwith', 'back', 'pop')
    }
  }

  function handleFABClick() {
    present({
      header: 'Create Asset',
      buttons: [
        {
          text: 'Take Photo',
          handler: () => {
            takePhoto((p) => handlePhoto(p))
          },
        },
        {
          text: 'Select Photo',
          handler: () => {
            selectPhotos((p) => handlePhoto(p))
          },
        },
        {
          text: 'Create Collage',
          handler: () => {
            router.push('/tabs/shopwith/look/create-collage')
          },
        },
        {
          text: 'Select Video',
          handler: () => {
            handleSelectVideo()
          },
        },
        { text: 'Cancel', role: 'cancel' },
      ],
      cssClass: 'action-sheet',
    })
  }

  return (
    <IonFab vertical="bottom" horizontal="end" slot="fixed">
      <IonFabButton color="yellow" onClick={() => handleFABClick()}>
        <IonIcon icon={add} />
      </IonFabButton>
    </IonFab>
  )
}

export default CreateAssetFAB

import {
  IonButton,
  IonItem,
  IonLabel,
  IonRadio,
  IonRadioGroup,
} from '@ionic/react'
import { Controller, useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { zodResolver } from '@hookform/resolvers/zod'

import { useAccount } from '../../../contexts/authContext'
import { StyledError } from '../../../components/ui/form/errors'
import { TextInput } from '../../../components/ui/form/input'
import { useUpdateUser } from '../mutations'
import { updateUserSchema } from '../user-setup.schema'

import type { UpdateUserInput, UserSetup } from '../user-setup.schema'

type Props = {
  onCancel: () => void
  onSuccess: () => void
  userData: UserSetup
}

function EditUserForm({ onCancel, onSuccess, userData: { user } }: Props) {
  const account = useAccount()

  const updateUser = useUpdateUser()

  const {
    control,
    formState: { errors },
    ...formMethods
  } = useForm<UpdateUserInput>({
    defaultValues: {
      objectId: user.objectId,
      displayName: user.displayName ?? '',
      firstName: user.firstName ?? '',
      goal: user.goal ?? '',
      lastName: user.lastName ?? '',
      twilioProxyEmail: user.twilioProxyEmail
        ? user.twilioProxyEmail.split('@')[0]
        : '',
      workDays: user.workDays ?? '',
      deactivated: user.deactivated ? 'inactive' : 'active',
    },
    resolver: zodResolver(updateUserSchema),
  })

  function handleSubmit(data: UpdateUserInput) {
    const twilioProxyEmail =
      account.sendgridDomain && data.twilioProxyEmail?.length
        ? `${data.twilioProxyEmail}@${account.sendgridDomain}`
        : undefined

    updateUser.mutate(
      { ...data, twilioProxyEmail },
      { onSuccess: () => onSuccess() }
    )
  }

  return (
    <form onSubmit={formMethods.handleSubmit(handleSubmit)} className="h-full">
      <TextInput control={control} name="objectId" hidden />
      <div className="flex h-full flex-col justify-between">
        <div className="space-y-3">
          <div className="flex gap-x-3">
            <div className="flex-1">
              <TextInput
                color="light"
                control={control}
                name="firstName"
                label="First Name"
              />
              <ErrorMessage as={StyledError} name="firstName" errors={errors} />
            </div>
            <div className="flex-1">
              <TextInput
                control={control}
                name="lastName"
                color="light"
                label="Last Name"
              />
              <ErrorMessage as={StyledError} name="lastName" errors={errors} />
            </div>
          </div>

          <div className="flex gap-x-3">
            <div>
              <TextInput
                type="number"
                control={control}
                name="goal"
                label="Goal"
                color="light"
              />
            </div>
            <div>
              <TextInput
                type="number"
                control={control}
                name="workDays"
                label="Work Days"
                color="light"
              />
            </div>
          </div>
          <TextInput
            control={control}
            name="displayName"
            label="Display Name"
            color="light"
          />
          {account.sendgridDomain ? (
            <div className="flex items-end gap-x-3">
              <span className="flex-1">
                <TextInput
                  control={control}
                  name="twilioProxyEmail"
                  label="Sender Email"
                  color="light"
                />
              </span>
              <span>@{account.sendgridDomain}</span>
            </div>
          ) : null}

          <div className="flex items-center justify-between">
            <h3 className="font-semibold">User Status</h3>
            <Controller
              name="deactivated"
              control={control}
              render={({ field: { value, onChange } }) => (
                <IonRadioGroup
                  color="primary"
                  className="flex"
                  value={value}
                  onIonChange={(e) => onChange(e.detail.value)}
                >
                  <IonItem color="secondary" lines="none">
                    <IonLabel>Active</IonLabel>
                    <IonRadio mode="md" value="active" />
                  </IonItem>
                  <IonItem color="secondary" lines="none">
                    <IonLabel>Inactive</IonLabel>
                    <IonRadio mode="md" value="inactive" />
                  </IonItem>
                </IonRadioGroup>
              )}
            />
          </div>
        </div>

        <div className="flex gap-x-3 py-3">
          <IonButton fill="outline" onClick={onCancel}>
            Cancel
          </IonButton>
          <IonButton color="yellow" type="submit">
            Update User
          </IonButton>
        </div>
      </div>
    </form>
  )
}

export { EditUserForm }

import { IonImg } from '@ionic/react'

import { cn } from '../utils/cn'
import { Client } from '../hooks/clients/queries'
import { cva } from 'class-variance-authority'

import type { VariantProps } from 'class-variance-authority'

type UserBase = Pick<Client, 'firstName' | 'lastName' | 'photo'> | null

type Props = {
  user: UserBase
} & VariantProps<typeof avatarVariants>

const avatarVariants = cva('', {
  variants: {
    size: {
      sm: ['size-10 text-base'],
      lg: ['size-[4.25rem] text-2xl'],
      xl: ['size-32 text-6xl'],
    },
    color: {
      primary: ['border-ion-color-primary, text-ion-color-primary'],
      secondary: ['border-ion-color-secondary, text-ion-color-secondary'],
      yellow: ['border-ion-color-yellow, text-ion-color-yellow'],
    },
  },
  defaultVariants: { size: 'sm', color: 'secondary' },
})

function nameToInitials(user: UserBase) {
  let nameInitials = ''

  if (!user) return nameInitials

  if (user.firstName) {
    nameInitials = nameInitials.concat(
      user.firstName.trim().toUpperCase().charAt(0)
    )
  }

  if (user.lastName) {
    nameInitials = nameInitials.concat(
      user.lastName.trim().toUpperCase().charAt(0)
    )
  }

  return nameInitials
}

function Avatar({ user, color, size }: Props) {
  return (
    <>
      {user?.photo ? (
        <div className={cn(avatarVariants({ color: null, size }))}>
          <IonImg
            src={user.photo}
            alt=""
            className="size-full overflow-hidden rounded-full object-cover"
          />
        </div>
      ) : (
        <div
          className={cn(
            `bg-ion-color-primary inline-flex items-center justify-center rounded-full border`,
            avatarVariants({ color, size })
          )}
        >
          <span className={cn('block', avatarVariants({ color, size: null }))}>
            {nameToInitials(user)}
          </span>
        </div>
      )}
    </>
  )
}

export default Avatar

import {
  IonPage,
  IonContent,
  IonItem,
  IonHeader,
  IonText,
  IonGrid,
  IonButton,
  IonCard,
  IonCardContent,
  IonRadioGroup,
  IonLabel,
  IonRadio,
  IonRow,
  useIonRouter,
} from '@ionic/react'
import { addDays, addYears } from 'date-fns'

import * as commonStrings from '../utils/common-strings'
import useNextContact from '../hooks/use-next-contact'
import DatePicker from '../components/ui/ionic/date-picker'

const NextContact = ({ match }) => {
  const { dates, nextContactDate, setNextContactDate, saveNextContactDate } =
    useNextContact()

  const router = useIonRouter()

  async function setNextContact() {
    await saveNextContactDate(match.params.clientId)

    if (router.canGoBack) {
      router.goBack()
    } else {
      router.push('/tabs/home', 'back', 'pop')
    }
  }

  const RadioItem = ({ date }) => {
    const { label, value } = date

    return (
      <IonItem color="secondary">
        <IonLabel style={{ textAlign: 'center' }}>{label}</IonLabel>
        <IonRadio slot="start" value={value} />
      </IonItem>
    )
  }

  return (
    <IonPage>
      <IonHeader />
      <IonContent>
        <IonRow
          class="ion-justify-content-center"
          style={{ textAlign: 'center' }}
        >
          <IonText>
            <h2>When would you like to reach out again?</h2>
          </IonText>
        </IonRow>
        <IonCard color="secondary">
          <IonCardContent>
            <IonRadioGroup
              value={nextContactDate}
              required
              onIonChange={(e) => setNextContactDate(e.target.value)}
            >
              {dates.map((date, index) => {
                return <RadioItem key={index} date={date} />
              })}
            </IonRadioGroup>

            <DatePicker
              label="Date"
              value={nextContactDate}
              min={addDays(new Date(), 1)}
              max={addYears(new Date(), 1)}
              onChange={(e) => setNextContactDate(e.target.value)}
              onReset={() => setNextContactDate(undefined)}
            />
          </IonCardContent>
          <IonGrid style={{ textAlign: 'center' }}>
            <IonButton onClick={setNextContact}>
              {commonStrings.FINISH}
            </IonButton>
          </IonGrid>
        </IonCard>
      </IonContent>
    </IonPage>
  )
}

export default NextContact

import * as React from 'react'
import {
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonButton,
  IonLoading,
  IonPage,
  useIonAlert,
} from '@ionic/react'
import { ellipsisHorizontal } from 'ionicons/icons'
import { useParams } from 'react-router-dom'
import { Dialog, Transition } from '@headlessui/react'
import { formatDistanceToNow, parseISO } from 'date-fns'
import { ResourceType } from '@capawesome/capacitor-cloudinary'

import { useDeleteProfileLook } from '../../../hooks/profile/profile-looks/mutations'
import { useProfileLook } from '../../../hooks/profile/profile-looks/queries'
import Header from '../../../components/header'
import CollageItem from '../../../components/shopwith/collage-item'
import { AvatarHeader } from '../../../components/ui/avatar-header'
import ExpandableText from '../../../components/ui/expandable-text'
import CldVideoPlayer from '../../../components/cloudinary/cld-video-player'

function ProfileLookPage() {
  const [isOpen, setIsOpen] = React.useState(false)

  const { profileLookId } = useParams<{ profileLookId: string }>()
  const profileLookQuery = useProfileLook(profileLookId)

  const [presentAlert] = useIonAlert()

  const deleteProfileLook = useDeleteProfileLook()

  function handleDeleteProfileLook() {
    presentAlert({
      header: 'Delete Post?',
      message:
        'This will remove the post from your oneshop.me profile. Are you sure you want to delete this post?',
      backdropDismiss: false,
      buttons: [
        'Cancel',
        {
          text: 'Delete',
          role: 'destructive',
          handler: () => {
            deleteProfileLook.mutate({ profileLookId })
          },
        },
      ],
    })
    setIsOpen(false)
  }

  return (
    <IonPage>
      <IonHeader>
        <Header title="Post Detail" btn={null} />
      </IonHeader>
      <IonContent>
        {profileLookQuery.data ? (
          <div className="mx-auto max-w-xl space-y-6 overflow-hidden pb-6 sm:pt-6">
            <article className="sm:rounded-lg sm:rounded-b-none sm:border sm:border-gray-600">
              <AvatarHeader
                user={profileLookQuery.data.user}
                action={
                  <IonButton
                    fill="clear"
                    size="small"
                    color="secondary"
                    onClick={() => setIsOpen(true)}
                  >
                    <IonIcon slot="icon-only" icon={ellipsisHorizontal} />
                  </IonButton>
                }
              />

              {profileLookQuery.data.look.cloudinary?.data?.resource_type ===
              ResourceType.Video ? (
                <div className="aspect-h-16 aspect-w-9">
                  <CldVideoPlayer
                    publicId={
                      profileLookQuery.data.look.cloudinary.data.public_id
                    }
                  />
                </div>
              ) : (
                <div className="aspect-h-4 aspect-w-3">
                  {profileLookQuery.data.look.coverPhoto?.originalSrc ? (
                    <IonImg
                      src={profileLookQuery.data.look.coverPhoto.originalSrc}
                      className="bg-ion-background-color size-full object-cover object-top"
                    />
                  ) : (
                    <CollageItem items={profileLookQuery.data.look.products} />
                  )}
                </div>
              )}
              <div className="p-2">
                {profileLookQuery.data.description ? (
                  <div className="leading-tight">
                    <ExpandableText characterLimit={60}>
                      {profileLookQuery.data.description}
                    </ExpandableText>
                  </div>
                ) : null}
                <div className="text-sm text-gray-400">
                  {`${formatDistanceToNow(
                    parseISO(profileLookQuery.data.createdAt)
                  )} ago`.toUpperCase()}
                </div>
              </div>
            </article>

            <Transition show={isOpen} as={React.Fragment}>
              <Dialog
                onClose={() => setIsOpen(false)}
                className="relative z-50"
              >
                <Transition.Child
                  as={React.Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div
                    className="fixed inset-0 bg-black/30"
                    aria-hidden="true"
                  />
                </Transition.Child>

                <div className="fixed inset-0 flex items-center justify-center p-4">
                  <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-90"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-90"
                  >
                    <Dialog.Panel className="bg-ion-color-step-100 flex w-full max-w-sm transform flex-col divide-y-[1px] divide-white/20 rounded-2xl shadow-xl transition-all">
                      <button
                        className="text-ion-color-danger min-h-[3rem] px-2 py-1 text-center font-semibold"
                        onClick={handleDeleteProfileLook}
                      >
                        Delete
                      </button>
                      <button
                        className="text-ion-color-secondary min-h-[3rem] px-2 py-1 text-center"
                        onClick={() => setIsOpen(false)}
                      >
                        Cancel
                      </button>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition>
          </div>
        ) : (
          <IonLoading isOpen message="Loading Post..." />
        )}
      </IonContent>
    </IonPage>
  )
}

export default ProfileLookPage

import { IonGrid, IonRow, IonCol } from '@ionic/react'

import { useCurrentSequence } from '../../../stores/useSequence'
import { useUser } from '../../../contexts/authContext'
import { useIsStore } from '../../../auth/utils'
import CurrentOutreachSection from './current-outreach-section'
import { SmsSubscribers, EmailSubscribers, MessageGoals } from './info-section'
import QListsSection from './q-lists-section'
import RecentSalesSection from './recent-sales-section'
import CreatePostButton from '../create-post-button'
import DynamicOutreachSection from './dynamic-outreach-section'
import OutreachSection from './outreach-section'

function Lists() {
  const user = useUser()

  const { isQ, isUno } = useIsStore()
  const showInfoGrid = isUno() || user.hasTwilio || user.hasSendGrid

  const currentSequence = useCurrentSequence()

  return (
    <div className="mx-auto max-w-xl overflow-hidden">
      <IonRow className="justify-center">
        <CreatePostButton />
      </IonRow>
      {showInfoGrid ? (
        <IonGrid>
          <IonRow>
            {user.hasTwilio ? (
              <IonCol>
                <SmsSubscribers />
              </IonCol>
            ) : null}
            {user.hasSendGrid ? (
              <IonCol>
                <EmailSubscribers />
              </IonCol>
            ) : null}
            {isUno() ? (
              <IonCol>
                <MessageGoals />
              </IonCol>
            ) : null}
          </IonRow>
        </IonGrid>
      ) : null}

      {Object.values(currentSequence).length > 0 ? (
        <CurrentOutreachSection />
      ) : null}

      <RecentSalesSection />

      <OutreachSection title="One-Time Outreach" filter="onetime" />
      <OutreachSection title="Ongoing Outreach" filter="ongoing" />

      <DynamicOutreachSection />

      {isQ() ? <QListsSection /> : null}
    </div>
  )
}

export default Lists

import * as React from 'react'
import {
  IonButton,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
  IonSelect,
  IonSelectOption,
} from '@ionic/react'
import { ErrorMessage } from '@hookform/error-message'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { typedKeys } from '../../utils/typescript-helpers'
import { dynamicCallListSchema } from '../../schema/dynamic-outreach.schema'
import { useUpdateDynamicCallList } from '../../hooks/outreach/mutations'
import { useTemplates } from '../../hooks/templates/queries'
import { TextInput, NumericInput } from '../ui/form/input'
import { StyledError } from '../ui/form/errors'
import TemplateMessageDisplay from '../ui/template-message-display'

import type { Template } from '../../hooks/templates/queries'
import type { DynamicCallListSchemaState } from '../../schema/dynamic-outreach.schema'
import type { DynamicCallListDetail } from '../../hooks/outreach/queries'

type Props = {
  dynamicCallList: DynamicCallListDetail
}

function UpdateDynamicOutreachForm({ dynamicCallList }: Props) {
  const [selectedTemplate, setSelectedTemplate] =
    React.useState<Template | null>(null)

  const templatesQuery = useTemplates()
  const updateDynamicCallList = useUpdateDynamicCallList()
  const { objectId, template, description, ...dynamicInputs } = dynamicCallList

  const {
    control,
    handleSubmit,
    watch,
    getValues,
    reset,
    formState: { errors, isDirty, dirtyFields, isSubmitSuccessful },
  } = useForm<DynamicCallListSchemaState>({
    defaultValues: {
      ...dynamicInputs,
      description: description ?? '',
      templateId: null,
    },
    resolver: zodResolver(dynamicCallListSchema),
  })

  const watchTrigger = watch('trigger')

  function handleTemplateSelect(id: string) {
    const foundTemplate = templatesQuery.data?.find(
      (template) => template.objectId === id
    )
    setSelectedTemplate(foundTemplate ?? null)
  }

  React.useEffect(() => {
    if (isSubmitSuccessful) {
      reset(getValues())
    }
  }, [getValues, isSubmitSuccessful, reset])

  function updateOutreach() {
    const updatedFields = typedKeys(dirtyFields)
      .map((x) => ({
        field: x,
        value: getValues(x),
      }))
      .reduce((acc, curr) => {
        return { ...acc, [curr.field]: curr.value }
      }, {} as DynamicCallListSchemaState)

    updateDynamicCallList.mutate({ objectId, updates: updatedFields })
  }

  return (
    <form onSubmit={handleSubmit(updateOutreach)}>
      <IonRow className="ion-justify-content-center ion-padding-top">
        <IonCol size="auto">
          <IonButton
            disabled={!isDirty}
            type="submit"
            color="yellow"
            size="small"
            fill={isDirty ? 'solid' : 'outline'}
          >
            Update
          </IonButton>
        </IonCol>
      </IonRow>
      <IonGrid>
        <IonRow className="ion-padding-vertical">
          <IonCol size="12" sizeMd="3" sizeLg="2">
            <h2>Basic Info:</h2>
          </IonCol>
          <IonCol size="12" sizeMd="9" sizeLg="10" className="space-y-4">
            <div>
              <TextInput control={control} name="name" label="Title:" />
              {watchTrigger === 'BIRTHDAY'
                ? 'The current month will be added to the beginning of the title for display.'
                : null}
              <ErrorMessage name="name" errors={errors} as={StyledError} />
            </div>
            <TextInput
              control={control}
              name="description"
              label="Description:"
            />
          </IonCol>
        </IonRow>

        <IonRow className="ion-padding-vertical">
          <IonCol size="12" sizeMd="3" sizeLg="2">
            <h2>Trigger:</h2>
          </IonCol>
          <IonCol size="12" sizeMd="9" sizeLg="10" className="space-y-4">
            <IonItem
              lines="none"
              style={{
                '--padding-start': '.5rem',
                '--background': 'var(--ion-color-step-100)',
                '--border-radius': '4px',
              }}
            >
              <IonLabel>Trigger: {dynamicInputs.trigger}</IonLabel>
            </IonItem>
            {watchTrigger === 'LAST_SALE' ? (
              <div>
                <p>Last sale is between:</p>
                <div className="flex items-center gap-4">
                  <NumericInput control={control} name="filter.end" />
                  and
                  <NumericInput control={control} name="filter.start" />
                  days ago
                </div>
                <ErrorMessage name="filter" errors={errors} as={StyledError} />
                <ErrorMessage
                  name="filter.start"
                  errors={errors}
                  as={StyledError}
                />
                <ErrorMessage
                  name="filter.end"
                  errors={errors}
                  as={StyledError}
                />
              </div>
            ) : watchTrigger === 'BIRTHDAY' ? (
              <div>
                <div className="flex items-end gap-x-4">
                  <NumericInput
                    control={control}
                    name="filter.daysBefore"
                    label=""
                  />{' '}
                  <span>days before birthday month</span>
                </div>
                <ErrorMessage
                  name="filter.daysBefore"
                  errors={errors}
                  as={StyledError}
                />
              </div>
            ) : null}
          </IonCol>
        </IonRow>

        <IonRow className="ion-padding-vertical">
          <IonCol size="12" sizeMd="3" sizeLg="2">
            <h2>Messaging:</h2>
          </IonCol>
          <IonCol size="12" sizeMd="9" sizeLg="10" className="space-y-4">
            <IonItem
              lines="none"
              style={{
                '--padding-start': '.5rem',
                '--background': 'var(--ion-color-step-100)',
                '--border-radius': '4px',
              }}
            >
              <IonLabel>Choose a new outreach template</IonLabel>
              <Controller
                name="templateId"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <IonSelect
                    interfaceOptions={{ cssClass: 'dark-ionic-filter' }}
                    className="bg-transparent"
                    value={value}
                    onIonChange={(e) => {
                      onChange(e.detail.value)
                      handleTemplateSelect(e.detail.value)
                    }}
                  >
                    {templatesQuery.data?.map((template) => (
                      <IonSelectOption
                        key={template.objectId}
                        value={template.objectId}
                      >
                        {template.name}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                )}
              />
            </IonItem>
            <ErrorMessage name="templateId" errors={errors} as={StyledError} />
            <TemplateMessageDisplay template={selectedTemplate || template} />
          </IonCol>
        </IonRow>
      </IonGrid>
    </form>
  )
}

export default UpdateDynamicOutreachForm

import * as React from 'react'
import {
  IonRow,
  IonCol,
  IonButton,
  IonRadioGroup,
  IonItem,
  IonRadio,
  IonLabel,
  IonIcon,
} from '@ionic/react'
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { zodResolver } from '@hookform/resolvers/zod'
import clsx from 'clsx'
import { addCircleOutline, removeCircle } from 'ionicons/icons'

import { StyledError } from '../../components/ui/form/errors'
import { DecimalInput, NumericInput } from '../../components/ui/form/input'
import { loyaltyProgramFormSchema } from '../loyalty.schema'

import type {
  LoyaltyProgramFormState,
  LoyaltyRuleDetail,
} from '../loyalty.schema'

type Props = {
  currentState: LoyaltyRuleDetail['state']
  defaultValues: LoyaltyProgramFormState
  isDisabled: boolean
  onSave: (form: LoyaltyProgramFormState) => void
  onStop?: () => void
  onPublish: (form: LoyaltyProgramFormState) => void
}

const defaultStep: LoyaltyProgramFormState['steps'][number] = {
  settings: {
    percent: 5,
    points: 1000,
  },
}
function LoyaltyProgramsForm(props: Props) {
  const { currentState, defaultValues, isDisabled, onSave, onStop, onPublish } =
    props

  const {
    control,
    getValues,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<LoyaltyProgramFormState>({
    defaultValues,

    resolver: zodResolver(loyaltyProgramFormSchema),
  })

  const stepFields = useFieldArray({ control, name: 'steps' })
  const watchProgramStyle = useWatch({ control, name: 'programStyle' })
  const watchProgramType = useWatch({ control, name: 'type' })

  return (
    <>
      <IonRow className="justify-center">
        {currentState === 'active' ? (
          <IonCol size="auto">
            <IonButton
              color="danger"
              fill="outline"
              size="small"
              onClick={onStop}
            >
              Stop
            </IonButton>
          </IonCol>
        ) : currentState === 'draft' || currentState === 'stopped' ? (
          <>
            <IonCol size="auto">
              <IonButton
                color="yellow"
                fill="outline"
                size="small"
                onClick={() => onSave(getValues())}
              >
                Save Draft
              </IonButton>
            </IonCol>
            <IonCol size="auto">
              <IonButton
                color="yellow"
                size="small"
                onClick={async () => {
                  const valid = await trigger()
                  if (valid) onPublish(getValues())
                }}
              >
                Publish
              </IonButton>
            </IonCol>
          </>
        ) : null}
      </IonRow>

      <div className="space-y-8">
        <div>
          <h2>Loyalty Program Style</h2>
          <Controller
            name="programStyle"
            control={control}
            render={({ field: { value, onChange } }) => (
              <IonRadioGroup
                value={value}
                onIonChange={(e) => {
                  onChange(e)
                  if (e.detail.value === 'points')
                    setValue('settings', { visits: 0 })
                }}
                className="flex"
              >
                <IonItem lines="none">
                  <IonRadio
                    slot="end"
                    value="points"
                    color="yellow"
                    className="peer size-6 rounded-full border aria-checked:opacity-100"
                    disabled={isDisabled}
                  />
                  <IonLabel className={clsx('peer-aria-checked:opacity-100')}>
                    Points Program
                  </IonLabel>
                </IonItem>
                <IonItem lines="none">
                  <IonRadio
                    slot="end"
                    value="box"
                    color="yellow"
                    className="peer size-6 rounded-full border aria-checked:opacity-100"
                    disabled={isDisabled}
                  />
                  <IonLabel className="peer-aria-checked:opacity-100">
                    Box Program
                  </IonLabel>
                </IonItem>
              </IonRadioGroup>
            )}
          />
        </div>

        <div>
          <h2>Loyalty Program Type</h2>
          <Controller
            name="type"
            control={control}
            render={({ field: { value, onChange } }) => (
              <IonRadioGroup
                value={value}
                onIonChange={onChange}
                className="flex"
              >
                <IonItem lines="none">
                  <IonRadio
                    slot="end"
                    value="linear"
                    color="yellow"
                    className="peer size-6 rounded-full border aria-checked:opacity-100"
                    disabled={isDisabled}
                  />
                  <IonLabel className="peer-aria-checked:opacity-100">
                    Linear
                  </IonLabel>
                </IonItem>
                <IonItem lines="none">
                  <IonRadio
                    slot="end"
                    value="tier"
                    color="yellow"
                    className="peer size-6 rounded-full border aria-checked:opacity-100"
                    disabled={isDisabled}
                  />
                  <IonLabel className="peer-aria-checked:opacity-100">
                    Tier
                  </IonLabel>
                </IonItem>
              </IonRadioGroup>
            )}
          />
        </div>

        <div className="space-y-2">
          <h2>Rule</h2>
          <p>$1 = 1 Point</p>
        </div>

        <div className="space-y-4">
          <h2>Rewards</h2>
          {stepFields.fields.map((field, index) => (
            <React.Fragment key={field.id}>
              <div className="flex items-center gap-x-2">
                <p className="min-w-[50px]">
                  {watchProgramType === 'linear' ? 'Every' : 'At'}
                </p>
                {field.objectId ? (
                  <input
                    {...register(`steps.${index}.objectId`)}
                    hidden
                    readOnly
                  />
                ) : null}
                <NumericInput
                  control={control}
                  name={`steps.${index}.settings.points`}
                  min={0}
                  disabled={isDisabled}
                  color="dark"
                />{' '}
                <span>points earns</span>{' '}
                <DecimalInput
                  control={control}
                  name={`steps.${index}.settings.percent`}
                  min={0}
                  disabled={isDisabled}
                />{' '}
                <span>% in rewards</span>
                {index !== 0 && !field.objectId ? (
                  <div className="relative flex items-center">
                    <span className="absolute left-3.5 size-4 rounded-full bg-white" />
                    <IonButton
                      size="small"
                      fill="clear"
                      color="danger"
                      onClick={() => stepFields.remove(index)}
                      className="hover:opacity-100"
                    >
                      <IonIcon slot="icon-only" icon={removeCircle} />
                    </IonButton>
                  </div>
                ) : null}
              </div>
              <ErrorMessage
                errors={errors}
                as={StyledError}
                name={`steps.${index}.settings.points`}
              />
              <ErrorMessage
                errors={errors}
                as={StyledError}
                name={`steps.${index}.settings.percent`}
              />
            </React.Fragment>
          ))}

          <div className="flex items-center gap-x-2">
            <p className="text-xl">Or</p>
            <IonButton
              style={{ '--padding-start': 0, '--padding-end': 0 }}
              fill="clear"
              color="yellow"
              size="large"
              onClick={() => {
                stepFields.append(defaultStep)
              }}
              disabled={isDisabled}
            >
              <IonIcon slot="icon-only" icon={addCircleOutline} />
            </IonButton>
          </div>
        </div>
        {watchProgramStyle === 'box' ? (
          <div className="space-y-4">
            <h2>Redemption Limitations</h2>
            <div className="flex items-center gap-x-2">
              <p>Can be redeemed every</p>{' '}
              <NumericInput
                control={control}
                name="settings.visits"
                min={0}
                disabled={isDisabled}
                color="dark"
              />{' '}
              visits
            </div>
            <ErrorMessage
              name="settings.visits"
              as={StyledError}
              errors={errors}
            />
          </div>
        ) : null}
      </div>
    </>
  )
}

export { LoyaltyProgramsForm }

import { useQuery } from '@tanstack/react-query'

import { strToTitleCase } from '../../utils/format-helpers'
import { client } from '../../services/client'
import { useToast } from '../../contexts/toastContext'

import type { QueryContextFromKeys } from '../../utils/react-query'
import type { FetchSelectFilterResponse, SelectFilterParams } from './types'

type PosClassesQueryContexts = QueryContextFromKeys<typeof posClassesQueryKeys>

const posClassesQueryKeys = {
  all: [{ entity: 'posClasses' }] as const,
  lists: () => [{ ...posClassesQueryKeys.all[0], scope: 'list' }] as const,
  list: ({ admin }: Pick<SelectFilterParams, 'admin'>) => [
    { ...posClassesQueryKeys.lists()[0], admin },
  ],
}

async function fetchPosClasses({
  queryKey: [{ admin }],
}: PosClassesQueryContexts['list']) {
  const response: FetchSelectFilterResponse = await client
    .post('functions/posClasses', {
      json: admin ? { filter: { admin } } : undefined,
    })
    .json()

  return response.result
}

export default function usePosClasses({ admin, enabled }: SelectFilterParams) {
  const setToast = useToast()

  return useQuery({
    queryKey: posClassesQueryKeys.list({ admin }),
    queryFn: fetchPosClasses,
    enabled,
    refetchOnWindowFocus: false,
    retry: false,
    select: (data) => data.map((item) => strToTitleCase(item)),
    onError(error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown Error.'

      setToast({
        message: `Something went wrong while loading categories: ${errorMessage}.`,
        color: 'danger',
      })
    },
  })
}

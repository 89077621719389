import { IonButton } from '@ionic/react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { TrashIcon } from '@heroicons/react/24/outline'
import { z } from 'zod'

import { formDataSchema } from '../actions'
import { useDeleteReceipt, useUpdateReceipt } from '../mutations'
import { LoyaltyInput } from './loyalty-input'

import type { FormData } from '../actions'
import type { RewardSet } from '../loyalty.schema'

type Props = {
  onSubmit: () => void
  receipt: RewardSet['receipts'][number]
}

function EditReceiptBoxForm({ onSubmit, receipt }: Props) {
  const editForm = useForm<Pick<FormData, 'value'>>({
    defaultValues: {
      value: receipt.value ? `${receipt.value}` : '',
    },
    resolver: zodResolver(
      formDataSchema.pick({ value: true }).superRefine((data, ctx) => {
        if (data.value < 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.too_small,
            minimum: 1,
            type: 'number',
            inclusive: false,
            message: 'The points cannot be negative.',
            path: ['value'],
          })
        }
      })
    ),
  })

  const updateReceipt = useUpdateReceipt()
  const deleteReceipt = useDeleteReceipt()

  const isDisabled = updateReceipt.isLoading || deleteReceipt.isLoading

  function submit(data: Pick<FormData, 'value'>) {
    updateReceipt.mutate({ objectId: receipt.objectId, value: data.value })
  }

  return (
    <>
      <form className="flex flex-col gap-y-3 p-5">
        <div className="text-ion-color-secondary flex justify-between">
          <p>Transaction #</p>
          <p>{receipt.posId}</p>
        </div>
        <div className="flex items-center gap-x-3">
          <div className="flex-grow">
            <LoyaltyInput
              type="number"
              control={editForm.control}
              name="value"
              disabled={!receipt.canEdit || isDisabled}
            />
          </div>
          <div className="flex gap-x-1">
            <IonButton
              onClick={() => {
                editForm.handleSubmit(submit)()
                onSubmit()
              }}
              color="yellow"
              size="small"
              disabled={isDisabled}
            >
              Save
            </IonButton>
            <button
              type="button"
              className="text-ion-color-danger py-1 disabled:opacity-50"
              onClick={() => {
                deleteReceipt.mutate({ objectId: receipt.objectId })
                onSubmit()
              }}
              disabled={isDisabled}
            >
              <TrashIcon className="size-5" />
            </button>
          </div>
        </div>
      </form>
    </>
  )
}

export { EditReceiptBoxForm }

import * as React from 'react'
import { IonButton, IonIcon, IonImg, useIonRouter } from '@ionic/react'
import { ResourceType } from '@capawesome/capacitor-cloudinary'
import { ellipsisHorizontal } from 'ionicons/icons'
import { Dialog, Transition } from '@headlessui/react'

import { useToast } from '../../../contexts/toastContext'
import CldVideoPlayer from '../../cloudinary/cld-video-player'
import { AvatarHeader } from '../../ui/avatar-header'

import type { FeedItem as TFeedItem } from '../../../hooks/shopwith/use-feed'

type Props = {
  feedItem: TFeedItem
}

function FeedItem({ feedItem }: Props) {
  const [isOpen, setIsOpen] = React.useState(false)

  const router = useIonRouter()
  const setToast = useToast()

  const postId = feedItem.post?.objectId
  const profileLookId = feedItem.profileLook?.objectId
  const lookId = feedItem.look?.objectId

  const hasProducts = feedItem.products.length

  function composeFeedItemLink() {
    if (postId) return `/tabs/home/post/${postId}`
    if (profileLookId) return `/tabs/home/profileLook/${profileLookId}`
    if (lookId) return `/tabs/home/asset/${lookId}`

    throw new Error(
      'Something went wrong trying to go to the feed item detail.'
    )
  }

  function gotoFeedItem() {
    const link = composeFeedItemLink()

    setIsOpen(false)
    router.push(link)
  }

  function shareFeedItem() {
    setIsOpen(false)

    if (lookId) return router.push(`/tabs/home/asset/${lookId}/share`)

    setToast({ message: 'This Asset can not be shared' })
  }

  return (
    <>
      <article className="group sm:rounded-lg sm:rounded-b-none sm:border sm:border-gray-600">
        <AvatarHeader
          user={feedItem.user}
          action={
            <IonButton
              fill="clear"
              size="small"
              color="secondary"
              onClick={() => setIsOpen(true)}
            >
              <IonIcon slot="icon-only" icon={ellipsisHorizontal} />
            </IonButton>
          }
        />

        <div className="grid">
          {feedItem.cloudinary?.data?.resource_type === ResourceType.Video ? (
            <div className="aspect-h-16 aspect-w-9 relative col-start-1 col-end-2 row-start-1 row-end-2 ">
              <CldVideoPlayer
                id={feedItem.objectId}
                publicId={feedItem.cloudinary.data.public_id}
                autoplayMode="on-scroll"
                playsInline
                loop
                muted
              />
            </div>
          ) : (
            <div className="aspect-h-4 aspect-w-3 relative col-start-1 col-end-2 row-start-1 row-end-2 ">
              <IonImg
                src={feedItem.cloudinary?.data?.secure_url}
                className="bg-ion-background-color size-full object-cover object-top"
              />
            </div>
          )}
          {hasProducts ? (
            <div className="relative col-start-1 col-end-2 row-start-1 row-end-2 flex items-start justify-end pt-2">
              <ShoppableCallout />
            </div>
          ) : null}
        </div>
      </article>
      <Transition show={isOpen} as={React.Fragment}>
        <Dialog onClose={() => setIsOpen(false)} className="relative z-50">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
          </Transition.Child>

          <div className="fixed inset-0 flex items-center justify-center p-4">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-90"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-90"
            >
              <Dialog.Panel className="bg-ion-color-step-100 flex w-full max-w-sm transform flex-col divide-y-[1px] divide-white/20 rounded-2xl shadow-xl transition-all">
                <button
                  className="text-ion-color-secondary min-h-[3rem] px-2 py-1 text-center"
                  onClick={gotoFeedItem}
                >
                  Go To Post
                </button>
                <button
                  className="text-ion-color-secondary min-h-[3rem] px-2 py-1 text-center"
                  onClick={shareFeedItem}
                >
                  Share Post
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

function ShoppableCallout() {
  return (
    <div className="size-10 rounded-bl-md rounded-tl-md bg-black/60">
      <div className="flex size-full items-center justify-center group-hover:animate-shoppable">
        <ShoppingBagIcon />
      </div>
    </div>
  )
}

function ShoppingBagIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      className="size-6"
    >
      <path
        fill="#fff"
        d="M454.65 169.4A31.82 31.82 0 00432 160h-64v-16a112 112 0 00-224 0v16H80a32 32 0 00-32 32v216c0 39 33 72 72 72h272a72.22 72.22 0 0050.48-20.55 69.48 69.48 0 0021.52-50.2V192a31.75 31.75 0 00-9.35-22.6zM176 144a80 80 0 01160 0v16H176zm192 96a112 112 0 01-224 0v-16a16 16 0 0132 0v16a80 80 0 00160 0v-16a16 16 0 0132 0z"
      />
    </svg>
  )
}

export default FeedItem

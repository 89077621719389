import { z } from 'zod'

import { nullableString } from '../../utils/schema'
import { posLoyaltySchema } from '../../loyalty/loyalty.schema'

type LightspeedLoyalty = z.infer<typeof lightspeedLoyaltySchema>
type LightspeedLoyaltyParams = z.infer<typeof lightspeedLoyaltyParamsSchema>

const lightspeedLoyaltyParamsSchema = z.object({
  accountID: nullableString,
  customerID: nullableString,
  saleID: nullableString,
})

const lightspeedSaleLineSchema = z.object({
  saleLineID: z.string(),
})

const lightspeedLoyaltyBaseSchema = posLoyaltySchema.extend({
  lightspeedSale: z.object({
    SaleLines: z
      .object({
        SaleLine: z
          .union([lightspeedSaleLineSchema, lightspeedSaleLineSchema.array()])
          .transform((val) => {
            return Array.isArray(val) ? val : [val]
          })
          .optional(),
      })
      .optional(),
  }),
})

const lightspeedLoyaltySchema = lightspeedLoyaltyBaseSchema.transform(
  (schema) => ({
    ...schema,
    loyaltyType: 'lightspeed' as const,
  })
)

export type { LightspeedLoyalty, LightspeedLoyaltyParams }
export { lightspeedLoyaltySchema }

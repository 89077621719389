import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { formDataSchema } from '../actions'
import { useUpdateAdjustment } from '../mutations'
import { LoyaltyInput } from './loyalty-input'
import { useLoyalty } from './loyalty-redemption-detail'
import { SaveFooter } from './save-footer'

import type { FormData } from '../actions'

type Props = {
  objectId: string
  onCancel: () => void
  onSubmit: () => void
  value: number | null
}

function EditAdjustmentForm({ objectId, onCancel, onSubmit, value }: Props) {
  const { clientId } = useLoyalty()

  const updateAdjustment = useUpdateAdjustment()

  const formMethods = useForm<Pick<FormData, 'value'>>({
    defaultValues: { value: value ?? '' },
    resolver: zodResolver(formDataSchema.pick({ value: true })),
  })

  function submitForm(data: Pick<FormData, 'value'>) {
    updateAdjustment.mutate(
      {
        value: data.value,
        clientId,
        objectId,
      },
      { onSuccess: () => onSubmit() }
    )
  }

  return (
    <>
      <h2 className="text-center">Add or Subtract Points</h2>
      <form className="space-y-3 p-5">
        <LoyaltyInput
          disabled={updateAdjustment.isLoading}
          control={formMethods.control}
          name="value"
          label="Points"
        />
        <p className="text-ion-color-secondary text-center text-sm">
          (use negative number to subtract points)
        </p>
      </form>
      <SaveFooter
        onCancel={onCancel}
        onSuccess={() => {
          formMethods.handleSubmit(submitForm)()
        }}
      />
    </>
  )
}

export { EditAdjustmentForm }

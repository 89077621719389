import { useLocation, useParams } from 'react-router-dom'

function useCustomerOptInClientId() {
  const location = useLocation()
  const params = useParams<{ clientId: string }>()

  const searchParams = new URLSearchParams(location.search)
  const clientIdFromQuery = searchParams.get('clientId')

  if (clientIdFromQuery) {
    return clientIdFromQuery
  } else if (params.clientId) {
    return params.clientId
  }

  return null
}

export { useCustomerOptInClientId }

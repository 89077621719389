const alterationSteps = [
  'Ready to be Altered',
  'Alteration in Progress',
  'Alteration Complete',
  'Ready to be Shipped',
  'Ready to be Picked Up',
  'Picked Up',
  'Shipped',
] as const

const taskParseClasses = [
  'Alteration',
  'Appointment',
  'Consignment',
  'FollowUp',
  'MTM',
  'Shipment',
  'SpecialOrder',
] as const

export { alterationSteps, taskParseClasses }

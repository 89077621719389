import { IonIcon } from '@ionic/react'
import { close } from 'ionicons/icons'

import { cn } from '../../../utils/cn'

type Props = React.PropsWithChildren<{ onClick?: () => void; inner?: boolean }>

const buttonClasses =
  'size-5 bg-ion-color-primary flex cursor-pointer items-center justify-center rounded-full text-base leading-none'

function Button({ children, onClick }: Omit<Props, 'inner'>) {
  return (
    <button className={buttonClasses} onClick={onClick}>
      {children}
    </button>
  )
}

function InnerButton({ children, onClick }: Omit<Props, 'inner'>) {
  return (
    <button
      className={cn(buttonClasses, 'absolute right-[5px] top-[5px]')}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

function CloseButton({ onClick, inner = false }: Props) {
  const ButtonTag = inner ? InnerButton : Button

  return (
    <ButtonTag onClick={onClick}>
      <IonIcon className="size-3" color="secondary" icon={close} />
    </ButtonTag>
  )
}

export default CloseButton

import * as React from 'react'
import { IonButton, IonIcon, IonInput } from '@ionic/react'
import { eyeOffOutline, eyeOutline } from 'ionicons/icons'

type Props = {
  password: string
  required?: boolean
  onChange: () => void
}

function PasswordInput({ password, required = true, onChange }: Props) {
  const [showPassword, setShowPassword] = React.useState(false)

  return (
    <>
      <IonInput
        name="password"
        type={showPassword ? 'text' : 'password'}
        value={password}
        onIonChange={onChange}
        required={required}
        autocapitalize="off"
      />
      <IonButton
        fill="clear"
        color="primary"
        onClick={() => setShowPassword((currentState) => !currentState)}
        slot="end"
      >
        <IonIcon
          slot="icon-only"
          icon={showPassword ? eyeOffOutline : eyeOutline}
        />
      </IonButton>
    </>
  )
}

export default PasswordInput

import { IonCheckbox, useIonAlert } from '@ionic/react'

import { composeDollarAmount } from '../../utils/format-helpers'
import { useAccount } from '../../contexts/authContext'
import { LoyaltyButton } from './loyalty-button'

import type { IonCheckboxCustomEvent } from '@ionic/core'
import type { CheckboxChangeEventDetail } from '@ionic/react'
import type { LoyaltyData, RewardSet } from '../loyalty.schema'

type Props = {
  disabled: boolean
  isDiscountApplied: boolean
  onRedeem: (reward: RewardSet, options?: { redeemEarly: boolean }) => void
  onSelect: (
    event: IonCheckboxCustomEvent<CheckboxChangeEventDetail<RewardSet>>
  ) => void
  reward: RewardSet
  type: LoyaltyData['loyaltyType']
}

function Reward({
  disabled,
  isDiscountApplied, // TO PULL OUT?
  onRedeem,
  onSelect,
  reward,
  type,
}: Props) {
  const account = useAccount()
  const isNonIntegrated = account.account.settings.nonIntegrated

  const [presentAlert] = useIonAlert()

  const { pointsBalance, rewardsAvailable, text, visitsUntilReward } = reward

  const isAvailableEarly = visitsUntilReward > 0

  return (
    <div className="flex items-center gap-x-6">
      <div className="flex-0">
        <IonCheckbox
          disabled={!reward.rewardsAvailable || reward.visitsUntilReward > 0}
          onIonChange={onSelect}
          value={reward}
        />
      </div>
      <div className="flex flex-1 flex-col">
        <span className="text-lg font-semibold">Redeem {text}</span>
        <span className="text-lg">{pointsBalance} Points</span>
      </div>
      <div className="flex-0">
        {isAvailableEarly && type === 'manual' ? (
          <LoyaltyButton
            size={isNonIntegrated ? 'lg' : 'default'}
            disabled={disabled || !rewardsAvailable || isDiscountApplied}
            onClick={() =>
              presentAlert({
                header: 'Warning',
                buttons: [
                  { text: 'Cancel', role: 'cancel' },
                  {
                    text: 'Redeem Early',
                    role: 'confirm',
                    handler: () => {
                      onRedeem(reward, { redeemEarly: true })
                    },
                  },
                ],
                message: `You are about to redeem ${composeDollarAmount(
                  rewardsAvailable
                )} in rewards early. Are you sure you want to do this?`,
              })
            }
          >
            Redeem Early
          </LoyaltyButton>
        ) : (
          <LoyaltyButton
            disabled={disabled || !rewardsAvailable || isDiscountApplied}
            onClick={() => onRedeem(reward)}
          >
            Redeem
          </LoyaltyButton>
        )}
      </div>
    </div>
  )
}

export { Reward }

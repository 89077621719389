import { ShopWithCardMedia } from './card/shopwith-card-media'

type Props = {
  items: Array<{ objectId: string; featuredImage: string | null }>
}

function CollageItem({ items }: Props) {
  return (
    <div className="absolute inset-0 grid h-full grid-cols-2 grid-rows-2 gap-1">
      {items.slice(0, 4).map((item) => (
        <ShopWithCardMedia
          key={item.objectId}
          src={item.featuredImage}
          shadow
        />
      ))}
    </div>
  )
}

export default CollageItem

import { useQuery } from '@tanstack/react-query'

import { formatCurrency } from '../../utils/format-helpers'
import { client } from '../../services/client'
import { StatCard, StatCardSkeleton } from './components/stat-card'

type GroupRoiGoal = { roi: number; goal: number }

async function fetchFahertyRoi() {
  const response: { result: { data: Array<GroupRoiGoal> } } = await client
    .post('functions/getRoiGroupsGoal')
    .json()

  return response.result
}

function FahertyRoi() {
  const fahertyRoi = useQuery({
    queryKey: ['fahertyRoi'],
    queryFn: fetchFahertyRoi,
  })

  return (
    <>
      {fahertyRoi.data ? (
        <>
          {/* TODO: INDEX KEY */}
          {fahertyRoi.data.data.map((d, index) => {
            const roi = formatCurrency(d.roi, { abbreviate: true })
            const goal = formatCurrency(d.goal, { abbreviate: true })
            return (
              <StatCard key={index} label="2024 Goal" stat={`${roi}/${goal}`} />
            )
          })}
        </>
      ) : (
        <StatCardSkeleton />
      )}
    </>
  )
}

export { FahertyRoi }

import type { ReactNode } from 'react'

function ErrorCard({ children }: { children: ReactNode }) {
  return (
    <div className="border-ion-color-danger bg-ion-color-secondary text-ion-color-danger mx-auto w-11/12 rounded-md border p-4">
      {children}
    </div>
  )
}

export default ErrorCard

import { ChevronUpDownIcon } from '@heroicons/react/24/solid'

function SortButton({
  onClick,
}: Pick<React.ComponentPropsWithoutRef<'button'>, 'onClick'>) {
  return (
    <button
      className="inline-flex items-center gap-x-1.5 text-sm"
      onClick={onClick}
    >
      <ChevronUpDownIcon className="size-5" />
      Sort
    </button>
  )
}

export { SortButton }

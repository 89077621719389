import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useIonRouter } from '@ionic/react'

import { client } from '../../services/client'
import { useToast } from '../../contexts/toastContext'
import { createClientOptInResponseSchema } from '../../marketing/customer-opt-in/customer-opt-in.schema'
import { useLightspeedParams } from './utils'
import { lightspeedQueryKeys } from './queries'

import type { E164Number } from 'libphonenumber-js'
import type { ApplyLoyaltyReward } from '../../loyalty/loyalty.schema'
import type { CreateOptInParams } from '../../marketing/customer-opt-in/customer-opt-in.schema'

type ApplyLoyaltyParams = {
  accountID: string
  saleID: string
  customerID: string
  rewards: Array<ApplyLoyaltyReward>
}

type RemoveLoyaltyParams = {
  accountID: string
  saleID: string
}

async function applyLoyaltyRewardLightspeed(params: ApplyLoyaltyParams) {
  const { accountID, saleID, customerID, rewards } = params

  return client.post('functions/applyLoyaltyRewardLightspeed', {
    json: {
      accountID,
      saleID,
      customerID,
      rewards,
    },
  })
}

async function removeLoyaltyRewardLightspeed(params: RemoveLoyaltyParams) {
  const { accountID, saleID } = params

  return client.post('functions/removeLoyaltyRewardLightspeed', {
    json: {
      accountID,
      saleID,
    },
  })
}

async function createLightspeedOptIn(params: {
  e164: E164Number
  accountID: string
  customerID: string
}) {
  const { accountID, e164, customerID } = params

  const response = await client
    .post('functions/addClientSignUpPhoneLightspeed', {
      json: {
        e164,
        accountID,
        customerID,
      },
    })
    .json()

  try {
    createClientOptInResponseSchema.parse(response)
  } catch (e) {
    return Promise.reject(new Error('Something went wrong.'))
  }

  return createClientOptInResponseSchema.parse(response)
}

function useApplyLightspeedRewards() {
  const queryClient = useQueryClient()
  const { accountID, saleID, customerID, returnURL } = useLightspeedParams()

  const setToast = useToast()

  if (!saleID || !customerID || !accountID)
    throw new Error('missing parameters')

  return useMutation(
    (rewards: ApplyLoyaltyParams['rewards']) =>
      applyLoyaltyRewardLightspeed({ accountID, saleID, customerID, rewards }),
    {
      onSettled: () => {
        queryClient.invalidateQueries(
          lightspeedQueryKeys.loyalty({ accountID, saleID, customerID })
        )
      },
      onSuccess: () => {
        if (returnURL) window.location.replace(returnURL)
      },
      onError: (error) => {
        const message =
          error instanceof Error ? error.message : 'Unknown error.'

        setToast({
          message,
          color: 'danger',
        })
      },
    }
  )
}

function useRemoveLightspeedRewards() {
  const queryClient = useQueryClient()
  const { accountID, saleID, customerID } = useLightspeedParams()

  const setToast = useToast()

  if (!saleID || !customerID || !accountID)
    throw new Error('missing parameters')

  return useMutation(
    async () => removeLoyaltyRewardLightspeed({ accountID, saleID }),
    {
      onSettled: () => {
        queryClient.invalidateQueries(
          lightspeedQueryKeys.loyalty({ accountID, saleID, customerID })
        )
      },
      onError: (error) => {
        const message =
          error instanceof Error ? error.message : 'Unknown error.'

        setToast({
          message,
          color: 'danger',
        })
      },
    }
  )
}

function useCreateLightspeedOptIn() {
  const { accountID, saleID, customerID, querystring } = useLightspeedParams()

  const router = useIonRouter()
  const setToast = useToast()

  if (!saleID || !customerID || !accountID)
    throw new Error('missing parameters')

  return useMutation(
    async ({ e164 }: CreateOptInParams) =>
      createLightspeedOptIn({ accountID, customerID, e164 }),
    {
      onSuccess: ({ result }) => {
        const { client } = result
        if (client.firstName || client.lastName) {
          router.push(
            `/lightspeed/opt-in/success${querystring}`,
            'none',
            'replace'
          )
        } else {
          router.push(
            `/lightspeed/opt-in/more-info${querystring}`,
            'none',
            'replace'
          )
        }
      },

      onError: (error) => {
        const message =
          error instanceof Error
            ? error.message.toLowerCase() === 'invalid parameter e164'
              ? 'Please enter a valid phone number.'
              : error.message
            : 'Unknown error.'

        setToast({
          message,
          color: 'danger',
        })
      },
    }
  )
}

export {
  useApplyLightspeedRewards,
  useRemoveLightspeedRewards,
  useCreateLightspeedOptIn,
}

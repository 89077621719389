import { useLocation, useParams } from 'react-router-dom'

import { useQueryParams } from '../../hooks/use-query-params'

function useHeartlandParams() {
  const { search: querystring } = useLocation()
  const params = useParams<{ groupId: string }>()

  const queryParams = useQueryParams()

  const { groupId } = params

  const clientId = queryParams.get('customer_id')
  const posUrl = queryParams.get('pos_url')
  const ticketId = queryParams.get('ticket_id')
  const subdomain = queryParams.get('subdomain')

  return { groupId, clientId, posUrl, subdomain, ticketId, querystring }
}

export { useHeartlandParams }

import * as React from 'react'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonItem,
  IonLabel,
  IonModal,
  IonText,
} from '@ionic/react'
import type { DatetimeChangeEventDetail } from '@ionic/react'
import {
  format,
  formatISO,
  parseISO,
  subYears as subtractYears,
} from 'date-fns'

type Props = {
  label: string
  value?: string | null
  fallback?: string
  min?: Date
  max?: Date
  onChange?: (e: CustomEvent<DatetimeChangeEventDetail>) => void
  onReset?: () => void
  disabled?: boolean
}

function DatePickerDark({
  label,
  value,
  fallback = 'Choose a date...',
  min = subtractYears(new Date(), 10),
  max = new Date(),
  onChange,
  onReset,
  disabled = false,
}: Props) {
  const ionDateTimeRef = React.useRef<HTMLIonDatetimeElement>(null)
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <IonItem
      style={{
        '--padding-start': '.5rem',
        '--background': 'var(--ion-color-step-100)',
        '--border-radius': '4px',
      }}
      lines="none"
      disabled={disabled}
      button={true}
      onClick={() => setIsOpen(true)}
      detail={!Boolean(value)}
    >
      <IonLabel className="font-semibold">{label}</IonLabel>
      <IonText slot="end">
        {value ? format(parseISO(value), 'MMM dd, yyyy') : fallback}
      </IonText>

      <IonModal
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(false)}
        style={{
          '--width': '290px',
          '--height': '382px',
          '--border-radius': '8px',
        }}
      >
        <IonContent>
          <IonDatetime
            className="h-[382px]"
            ref={ionDateTimeRef}
            style={{
              '--background': 'var(--ion-color-primary)',
              '--background-rgb': '0, 0, 0',
            }}
            value={value && formatISO(new Date(value))} // convert to date and reformat as ISO otherwise day after is highlighted (related to Z instead of offset hours??)
            color="yellow"
            presentation="date"
            min={min?.toISOString()}
            max={max?.toISOString()}
            onIonChange={(e) => {
              onChange && onChange(e)
            }}
          >
            <IonButtons slot="buttons">
              <IonButton
                onClick={() => {
                  ionDateTimeRef.current?.cancel(true)
                }}
              >
                Cancel
              </IonButton>
              <div>
                {onReset ? (
                  <IonButton
                    onClick={() => {
                      onReset()

                      ionDateTimeRef.current?.cancel(true)
                    }}
                  >
                    Reset
                  </IonButton>
                ) : null}
                <IonButton
                  color="yellow"
                  onClick={async () => {
                    if (!ionDateTimeRef.current) return

                    await ionDateTimeRef.current.confirm(true)
                  }}
                >
                  Confirm
                </IonButton>
              </div>
            </IonButtons>
          </IonDatetime>
        </IonContent>
      </IonModal>
    </IonItem>
  )
}

export default DatePickerDark

import * as React from 'react'
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonicSlides,
} from '@ionic/react'
import { chevronDown, chevronUp } from 'ionicons/icons'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import 'swiper/swiper.min.css'
import 'swiper/css/pagination'
import '@ionic/react/css/ionic-swiper.css'

import placeholder from '../../../resources/no-image-product.png'
import Currency from '../../../components/Currency'
import { useIsStore } from '../../../auth/utils'

import type { Inventory } from '../../../types/shopwith'
import type { ProductDetail as TProductDetail } from '../../../hooks/shopwith/products/queries'

type NonEmptyArray<T> = [T, ...Array<T>]

type Props = {
  product: NonNullable<TProductDetail>
}

function InventoryCard({ item }: { item: [string, NonEmptyArray<Inventory>] }) {
  const [showLocations, setShowLocations] = React.useState(false)

  const [size, locations] = item

  return (
    <IonCard className="ion-no-margin">
      <IonCardContent>
        <IonItem
          style={{
            '--padding-start': 0,
            '--inner-padding-end': 0,
            '--min-height': 0,
          }}
          onClick={() => setShowLocations((showLocations) => !showLocations)}
          color="secondary"
          lines="none"
        >
          <IonLabel className="ion-no-margin">
            <span className="font-bold capitalize">{size}</span>
          </IonLabel>
          <div slot="end" className="flex items-center">
            <span className="text-ion-color-primary">
              {`${locations.reduce((acc, curr) => acc + curr.qty, 0)} unit${
                locations.reduce((acc, curr) => acc + curr.qty, 0) > 1
                  ? 's'
                  : ''
              } `}
            </span>
            <IonIcon
              className="ion-no-margin ml-1"
              icon={showLocations ? chevronUp : chevronDown}
            />
          </div>
        </IonItem>

        {showLocations ? (
          <div className="mt-2">
            {locations.map((location) => (
              <div
                key={location.group.name}
                className="text-ion-color-primary flex items-center justify-between"
              >
                <span>{location.group.name}</span>
                <span>{location.qty}</span>
              </div>
            ))}
          </div>
        ) : null}
      </IonCardContent>
    </IonCard>
  )
}

function ProductDetail({ product }: Props) {
  const { isTristan } = useIsStore()

  const hasOtherFields = Object.values(product.otherInfo).some((v) => v)

  return (
    <>
      <div className="relative mx-auto w-11/12 xs:w-75">
        {product.images.length ? (
          <Swiper
            style={{
              '--bullet-background-active': 'var(--ion-color-yellow)',
            }}
            key={product.objectId}
            modules={[Pagination, IonicSlides]}
            pagination={true}
            loop={product.images?.length > 1 ? true : false}
            navigation={{
              nextEl: '.item-next',
              prevEl: '.item-prev',
            }}
          >
            {product.images.map((photo) => (
              <SwiperSlide key={photo.objectId}>
                <img src={photo.originalSrc} alt="" className="object-cover" />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <img src={placeholder} alt="Product with no images" />
        )}
      </div>
      <div className="ion-padding-vertical mx-auto w-11/12 xs:w-75">
        <div className="text-2xl font-bold">{product.brand}</div>
        {isTristan() && (
          <div className="text-2xl font-bold">
            Style Code: {product.styleCode ?? 'N/A'}
          </div>
        )}
        <div>{product.title}</div>
        <div className="text-sm">
          <Currency value={product.price} />
        </div>
        {product.description && (
          <IonCard className="mx-0">
            <IonCardContent>
              <div className="text-ion-color-dark text-sm">
                {product.description}
              </div>
            </IonCardContent>
          </IonCard>
        )}
        {hasOtherFields ? (
          <IonCard className="mx-0">
            <IonCardHeader>
              <span className="text-ion-color-primary font-bold">
                Other Info
              </span>
            </IonCardHeader>
            <IonCardContent>
              <div className="text-ion-color-dark text-sm">
                {product.otherInfo.category && (
                  <div>Category: {product.otherInfo.category}</div>
                )}

                {product.otherInfo.gender && (
                  <div>Gender: {product.otherInfo.gender}</div>
                )}

                {product.otherInfo.season && (
                  <div>Season: {product.otherInfo.season}</div>
                )}

                {product.otherInfo.year && (
                  <div>Year: {product.otherInfo.year}</div>
                )}
              </div>
            </IonCardContent>
          </IonCard>
        ) : null}
        {product.inventory ? (
          <section className="ion-padding-bottom">
            <h2 className="text-center text-sm font-bold uppercase">
              Inventory
            </h2>
            <div>
              <span className="font-bold">Total Inventory: </span>
              {product.inventory.total}
            </div>
            <div>
              {Object.entries(product.inventory.items).map(([color, sizes]) => (
                <div key={color}>
                  <p className="mx-0 mb-1 mt-3 p-0 font-bold uppercase">
                    {color}
                  </p>
                  <div className="space-y-2">
                    {Object.entries(sizes).map((item, idx) => (
                      <InventoryCard key={idx} item={item} />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </section>
        ) : null}
      </div>
    </>
  )
}

export default ProductDetail

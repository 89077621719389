import { useQuery, useQueryClient } from '@tanstack/react-query'

import { client } from '../../../services/client'

import type { Look } from '../../../types/shopwith'
import type { QueryContextFromKeys } from '../../../utils/react-query'

type LooksResponse = {
  result: {
    looks: Array<Look>
  }
}

export type LookResponse = {
  result: {
    look: Look
  }
}

export type LookDetail = NonNullable<ReturnType<typeof useLook>['data']>

export type LookQueryContexts = QueryContextFromKeys<typeof lookQueryKeys>

export const lookQueryKeys = {
  all: [{ entity: 'looks' }] as const,
  lists: () => [{ ...lookQueryKeys.all[0], scope: 'list' }] as const,
  list: () => [{ ...lookQueryKeys.lists()[0] }] as const,
  details: () => [{ ...lookQueryKeys.all[0], scope: 'detail' }] as const,
  detail: (id: string) => [{ ...lookQueryKeys.details()[0], id }] as const,
}

async function fetchLooks() {
  const response: LooksResponse = await client.post('functions/looks').json()

  return response.result.looks
}

async function fetchLook({ queryKey: [{ id }] }: LookQueryContexts['detail']) {
  const response: LookResponse = await client
    .post(`functions/look/`, {
      json: {
        objectId: id,
      },
    })
    .json()

  return response.result.look
}

function useLooks({ enabled }: { enabled?: boolean }) {
  return useQuery({
    queryKey: lookQueryKeys.list(),
    queryFn: fetchLooks,
    enabled,
  })
}

function useLook(id: string) {
  const queryClient = useQueryClient()

  return useQuery({
    queryKey: lookQueryKeys.detail(id),
    queryFn: fetchLook,
    // initialize product data from products cache if it exists
    placeholderData: () => {
      const look = queryClient
        .getQueryData<Array<Look>>(lookQueryKeys.list(), { exact: true })
        ?.find((look) => look.objectId === id)

      return look ? look : undefined
    },
  })
}

export { useLooks, useLook }

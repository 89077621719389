import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonCol,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { useForm, Controller, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { ErrorMessage } from '@hookform/error-message'

import { cn } from '../../utils/cn'
import { addClientSchema } from '../../schema/add-client.schema'
import { useUser } from '../../contexts/authContext'
import { useCreateClient } from '../../hooks/clients/mutations'
import { StyledError } from '../ui/form/errors'
import { PhoneNumberInput, TagsInput, TextInput } from '../ui/form/input'

import type { AddClientSchema } from '../../schema/add-client.schema'

type Props = {
  onDismiss: (data?: AddClientSchema | null, role?: string) => void
}

const defaultValues: Omit<AddClientSchema, 'manualOptIn' | 'birthday'> = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  address: {
    street1: '',
    street2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  },
  tagNames: [],
  message: '',
}

function AddClient({ onDismiss }: Props) {
  const user = useUser()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AddClientSchema>({
    defaultValues: {
      ...defaultValues,
      manualOptIn: user.hasTwilio ? true : false,
    },
    resolver: zodResolver(addClientSchema),
  })
  const watchFirstName = useWatch({ control, name: 'firstName' })
  const watchPhoneNumber = useWatch({ control, name: 'phoneNumber' })
  const watchManualOptIn = useWatch({ control, name: 'manualOptIn' })

  const createClient = useCreateClient()

  function handleAddClient(data: AddClientSchema) {
    createClient.mutate(data, {
      onSuccess: () => onDismiss(data, 'submit'),
    })
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton color="medium" onClick={() => onDismiss(null, 'cancel')}>
              Cancel
            </IonButton>
          </IonButtons>
          <IonTitle>Add Client</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <form onSubmit={handleSubmit(handleAddClient)}>
          <IonRow>
            <IonCol size="12" sizeSm="6">
              <TextInput
                control={control}
                name="firstName"
                label="First Name *"
                autocapitalize="on"
              />
              <ErrorMessage name="firstName" as={StyledError} errors={errors} />
            </IonCol>
            <IonCol size="12" sizeSm="6">
              <TextInput
                control={control}
                name="lastName"
                label="Last Name"
                autocapitalize="on"
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <TextInput
                control={control}
                name="email"
                label="Email"
                type="email"
                autocapitalize="off"
              />
              <ErrorMessage name="email" as={StyledError} errors={errors} />
            </IonCol>
            <IonCol size="12" sizeSm="auto">
              <PhoneNumberInput
                control={control}
                name="phoneNumber"
                label="Phone Number"
              />
              <ErrorMessage
                name="phoneNumber"
                as={StyledError}
                errors={errors}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="9">
              <TextInput
                control={control}
                name="address.street1"
                label="Street"
              />
            </IonCol>
            <IonCol size="3">
              <TextInput
                control={control}
                name="address.street2"
                label="Apt No."
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5" sizeSm="6">
              <TextInput control={control} name="address.city" label="City" />
            </IonCol>
            <IonCol size="4" sizeSm="2">
              <TextInput control={control} name="address.state" label="State" />
            </IonCol>
            <IonCol size="3" sizeSm="4">
              <TextInput
                control={control}
                name="address.zip"
                label="Zip Code"
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12" sizeSm="6">
              <TextInput
                control={control}
                name="address.country"
                label="Country"
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <Controller
                name="tagNames"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <TagsInput tags={value} onChange={onChange} />
                )}
              />
            </IonCol>
          </IonRow>

          {user.hasTwilio ? (
            <>
              <p
                className={cn(
                  'ion-padding',
                  watchPhoneNumber
                    ? 'text-ion-color-secondary'
                    : 'text-ion-color-step-300'
                )}
              >
                {watchFirstName ? watchFirstName : 'The customer'} consents to
                receive calls and text messages, including by automatic
                telephone dialing system, this store or its associates to the
                phone number provided for informational and/or marketing
                purposes. Consent to receive marketing calls and texts is not a
                condition for purchase. Msg&Data Rates May Apply.
              </p>
              <IonItem>
                <IonLabel>Accept Opt In Terms</IonLabel>
                <Controller
                  control={control}
                  name="manualOptIn"
                  render={({ field: { value, onChange } }) => (
                    <IonCheckbox
                      disabled={!watchPhoneNumber}
                      checked={!watchPhoneNumber ? false : value}
                      onIonChange={(e) => onChange(e.detail.checked)}
                    />
                  )}
                />
              </IonItem>

              {watchManualOptIn && watchPhoneNumber ? (
                <IonItem lines="none">
                  <IonLabel position="stacked" className="font-semibold">
                    Message:
                  </IonLabel>
                  <Controller
                    name="message"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <IonTextarea
                        value={value}
                        onIonChange={onChange}
                        rows={6}
                        className="rounded"
                        style={{
                          '--background': 'var(--ion-color-step-100)',
                        }}
                      />
                    )}
                  />
                </IonItem>
              ) : null}
            </>
          ) : null}
          <div className="ion-padding-vertical ion-text-center">
            <IonButton
              disabled={createClient.isLoading}
              type="submit"
              color="yellow"
            >
              Add Client
            </IonButton>
          </div>
        </form>
      </IonContent>
      <IonLoading
        isOpen={createClient.isLoading}
        message="Adding new client..."
      />
    </IonPage>
  )
}

export default AddClient

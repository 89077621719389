import {
  IonContent,
  IonHeader,
  IonLabel,
  IonLoading,
  IonNote,
  IonPage,
} from '@ionic/react'
import clsx from 'clsx'
import { format, parseISO } from 'date-fns'

import ErrorCard from '../../components/ui/error-card'
import Header from '../../components/header'
import {
  ListItem,
  ListItemHeading,
  ListItemSubHeading,
} from '../../components/ui/list-item'
import { useLoyaltyPrograms } from '../../loyalty/queries'
import { LoyaltyProgramsForm } from '../../loyalty/components/loyalty-programs-form'

import type {
  LoyaltyProgramFormState,
  LoyaltyRuleBase,
  RuleState,
} from '../../loyalty/loyalty.schema'
import { useCreateLoyaltyProgram } from '../../loyalty/mutations'

const defaultValues: LoyaltyProgramFormState = {
  programStyle: 'points',
  type: 'linear',
  steps: [{ settings: { points: 1000, percent: 5 } }],
  settings: { visits: 0 },
}

function LoyaltyProgramsPage() {
  const loyaltyPrograms = useLoyaltyPrograms()
  const { publishLoyaltyProgram, saveLoyaltyProgramDraft } =
    useCreateLoyaltyProgram()

  const isDisabled = false

  return (
    <IonPage>
      <IonHeader>
        <Header btn={null} title="Loyalty Programs" showMenu />
      </IonHeader>
      <IonContent>
        {loyaltyPrograms.data ? (
          <>
            {loyaltyPrograms.data.data.length === 0 ? (
              <div className="ion-padding">
                <LoyaltyProgramsForm
                  currentState="draft"
                  defaultValues={defaultValues}
                  isDisabled={isDisabled}
                  onPublish={(d) => {
                    publishLoyaltyProgram.mutate(d)
                  }}
                  onSave={(d) => saveLoyaltyProgramDraft.mutate(d)}
                />
              </div>
            ) : (
              <>
                {loyaltyPrograms.data?.data.map((program) => (
                  <LoyaltyItem
                    key={program.objectId}
                    loyaltyProgram={program}
                  />
                ))}
              </>
            )}
          </>
        ) : loyaltyPrograms.error ? (
          <div className="flex h-full flex-col items-center justify-center">
            <ErrorCard>
              {loyaltyPrograms.error instanceof Error
                ? loyaltyPrograms.error.message
                : 'Unknown Error.'}
            </ErrorCard>
          </div>
        ) : (
          <IonLoading isOpen message="Loading loyalty programs..." />
        )}
      </IonContent>
    </IonPage>
  )
}

type Variant = Record<RuleState, string>
const variantClasses: Variant = {
  active: 'text-green-500',
  draft: 'text-slate-300',
  stopped: 'text-red-500',
}

function LoyaltyItem({ loyaltyProgram }: { loyaltyProgram: LoyaltyRuleBase }) {
  return (
    <ListItem
      routerLink={`/admin/loyalty-programs/detail/${loyaltyProgram.objectId}`}
    >
      <IonLabel className="ml-4">
        <ListItemHeading>
          <span className="capitalize">{loyaltyProgram.type} Program</span>
        </ListItemHeading>
        <ListItemSubHeading>
          Created On:{' '}
          {format(parseISO(loyaltyProgram.createdAt.iso), 'MM/dd/yy')}
        </ListItemSubHeading>
      </IonLabel>

      <IonNote color="secondary" className="text-sm" slot="end">
        <div className="flex flex-col gap-2">
          <p>
            Status:{' '}
            <span
              className={clsx(
                'capitalize',
                variantClasses[loyaltyProgram.state]
              )}
            >
              {loyaltyProgram.state === 'active'
                ? 'Published'
                : loyaltyProgram.state ?? 'Unknown'}
            </span>
          </p>
        </div>
      </IonNote>
    </ListItem>
  )
}

export default LoyaltyProgramsPage

import { useInfiniteQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { makePaginatedServerResponseSchema } from '../../utils/schema'
import { client } from '../../services/client'
import { campaignSchema } from './campaigns.schema'
import { useCampaignParams } from './utils'

import type { QueryFunctionContext } from '@tanstack/react-query'
import { fromZodError } from 'zod-validation-error'

type Permission = 'admin' | 'associate'
type Filter = 'onetime' | 'ongoing'

const campaignQueryKeys = {
  all: [{ entity: 'campaigns' }] as const,
  lists: () => [{ ...campaignQueryKeys.all[0], scope: 'list' }] as const,
  list: ({
    category,
    filter,
    permission,
    type,
  }: {
    category: 'all' | 'broadcasts' | 'callLists'
    filter?: Filter
    permission: Permission
    type?: 'admin' | 'associate' | 'personal'
  }) =>
    [
      { ...campaignQueryKeys.lists()[0], category, filter, permission, type },
    ] as const,
  admin: ({
    broadcasts,
    callLists,
  }: {
    broadcasts?: boolean
    callLists?: boolean
  }) =>
    [
      { ...campaignQueryKeys.lists()[0], broadcasts, callLists, admin: true },
    ] as const,
  stylist: () => [] as const,
}

async function fetchCampaigns({
  pageParam = 0,
  queryKey: [{ category, filter, permission, type }],
}: QueryFunctionContext<ReturnType<typeof campaignQueryKeys['list']>>) {
  const response = await client
    .post('functions/getCampaigns', {
      json: {
        admin: permission === 'admin',
        broadcasts: category === 'broadcasts' || category === 'all',
        callLists: category === 'callLists' || category === 'all',
        campaignType: type,
        onGoing: filter === 'ongoing',
        oneTime: filter === 'onetime',
        page: pageParam,
      },
    })
    .json()

  const parsedResponse = makePaginatedServerResponseSchema(
    z.array(campaignSchema)
  ).safeParse(response)

  if (!parsedResponse.success)
    throw new Error(fromZodError(parsedResponse.error).message)

  return parsedResponse.data.result
}

function useCampaigns(filter: Parameters<typeof campaignQueryKeys['list']>[0]) {
  return useInfiniteQuery({
    queryKey: campaignQueryKeys.list(filter),
    queryFn: fetchCampaigns,
    getNextPageParam: (lastPage) =>
      lastPage.hasNextPage ? lastPage.page + 1 : undefined,
  })
}

function useCampaignsByCategory(permission: Permission) {
  const category = useCampaignParams()
  return useCampaigns({ category, permission })
}

function useOutreachByFilter({
  filter,
  permission,
}: {
  filter: Filter
  permission: Permission
}) {
  return useCampaigns({
    category: 'callLists',
    filter,
    permission,
    type: 'associate',
  })
}

export type { Filter }
export { campaignQueryKeys, useCampaignsByCategory, useOutreachByFilter }

import { IonRow, IonCol } from '@ionic/react'
import { ErrorMessage } from '@hookform/error-message'
import { parsePhoneNumber } from 'libphonenumber-js'

import { useZodForm } from '../../utils/use-zod-form'
import { useAccount } from '../../contexts/authContext'
import { StyledError } from '../../components/ui/form/errors'
import { Form } from '../../components/ui/form/form'
import { TextInput, PhoneNumberInput } from '../../components/ui/form/input'
import { StickyHeader } from '../../components/ui/sticky-header'
import { useCheckInMethod } from '../../check-in/check-in-store'
import { checkInSchema } from '../../schema/add-client.schema'

import type { DeepPartial, SubmitHandler } from 'react-hook-form'
import type { CheckInFormInputs } from '../../schema/add-client.schema'

type Props = {
  initialValues?: DeepPartial<CheckInFormInputs>
  disabled?: boolean
  onSubmit: SubmitHandler<CheckInFormInputs>
}

function CustomerCheckInForm({ initialValues, disabled, onSubmit }: Props) {
  const account = useAccount()
  const { fields } = account.account.checkInSettings

  const checkInMethod = useCheckInMethod()

  const form = useZodForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      birthday: { month: null, day: null },
      phoneNumber: '',
      ...initialValues,
    },
    schema: checkInSchema,
  })

  return (
    <Form form={form} onSubmit={(data) => onSubmit(data)} className="w-full">
      <StickyHeader>
        <div className="flex justify-center py-6">
          <button
            disabled={disabled}
            type="submit"
            color="secondary"
            className="bg-ion-color-secondary text-ion-color-primary rounded-full px-20 py-3 text-3xl font-semibold disabled:opacity-60"
          >
            Continue
          </button>
        </div>
        {checkInMethod?.phone ? (
          <p className="text-ion-color-yellow flex justify-center text-2xl">
            {parsePhoneNumber(checkInMethod.phone).formatNational()}
          </p>
        ) : null}
        {checkInMethod?.email ? (
          <p className="text-ion-color-yellow flex justify-center text-2xl">
            {checkInMethod.email}
          </p>
        ) : null}
      </StickyHeader>

      {fields.firstName.enabled || fields.lastName.enabled ? (
        <IonRow className="w-full">
          {fields.firstName.enabled ? (
            <IonCol size="6">
              <TextInput
                required={fields.firstName.required}
                label={`First Name${
                  fields.firstName.required ? ' (required)' : ''
                }`}
                disabled={disabled}
                control={form.control}
                name="firstName"
              />
              <ErrorMessage
                name="firstName"
                as={StyledError}
                errors={form.formState.errors}
              />
            </IonCol>
          ) : null}
          {fields.lastName.enabled ? (
            <IonCol size="6">
              <TextInput
                required={fields.lastName.required}
                label={`Last Name${
                  fields.lastName.required ? ' (required)' : ''
                }`}
                disabled={disabled}
                control={form.control}
                name="lastName"
              />
            </IonCol>
          ) : null}
        </IonRow>
      ) : null}

      {fields.email.enabled || fields.phone.enabled ? (
        <IonRow className="w-full">
          {fields.email.enabled && !checkInMethod?.email ? (
            <IonCol size="7">
              <TextInput
                disabled={disabled}
                control={form.control}
                inputMode="email"
                name="email"
                required={fields.email.required}
                label={`Email${fields.email.required ? ' (required)' : ''}`}
              />
            </IonCol>
          ) : null}
          {fields.phone.enabled && !checkInMethod?.phone ? (
            <IonCol size="5">
              <PhoneNumberInput
                disabled={disabled}
                required={fields.phone.required}
                label={`Phone${fields.phone.required ? ' (required)' : ''}`}
                control={form.control}
                name="phoneNumber"
                placeholder="Phone Number"
                defaultCountry="US"
              />
            </IonCol>
          ) : null}
        </IonRow>
      ) : null}

      {fields.birthdayDay.enabled || fields.birthdayMonth.enabled ? (
        <IonRow>
          <IonCol size="5">
            <div>
              <p className="font-semibold">Birthday</p>
              <div className="flex gap-x-3">
                {fields.birthdayMonth.enabled ? (
                  <TextInput
                    inputMode="numeric"
                    control={form.control}
                    name="birthday.month"
                    required={fields.birthdayMonth.required}
                    label={`Month${
                      fields.birthdayMonth.required ? ' (required)' : ''
                    }`}
                  />
                ) : null}
                {fields.birthdayDay.enabled ? (
                  <TextInput
                    inputMode="numeric"
                    control={form.control}
                    name="birthday.day"
                    required={fields.birthdayDay.required}
                    label={`Day${
                      fields.birthdayDay.required ? ' (required)' : ''
                    }`}
                  />
                ) : null}
              </div>
              <ErrorMessage
                as={StyledError}
                errors={form.formState.errors}
                name="birthday.month"
              />
              <ErrorMessage
                as={StyledError}
                errors={form.formState.errors}
                name="birthday.day"
              />
            </div>
          </IonCol>
        </IonRow>
      ) : null}

      <div className="py-4" />
      <p className="text-xl">
        We don't currently have you as a customer. Please fill out the form to
        join.
      </p>
    </Form>
  )
}

export { CustomerCheckInForm }

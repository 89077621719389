import { IonButton, IonCard, IonCardContent } from '@ionic/react'
import { useHistory } from 'react-router-dom'

import { useQueryParams } from '../../hooks/use-query-params'

function PhoneCard({ onPhoneCancel, phoneNumber, clientId }) {
  const history = useHistory()
  const URLquery = useQueryParams()
  const callListId = URLquery.get('callListId')

  return (
    <IonCard className="followup-card" id="phone" color="secondary">
      <IonCardContent>
        <div className="buttons" style={{ marginTop: '6px' }}>
          <div>
            <IonButton fill="outline" onClick={onPhoneCancel}>
              CANCEL
            </IonButton>
          </div>
          <div>
            <IonButton
              fill="outline"
              onClick={() => {
                history.push(
                  `/WhatHappened/${clientId}?log=true${
                    callListId ? `&callListId=${callListId}` : ''
                  }`
                )
              }}
            >
              LOG
            </IonButton>
            <IonButton
              href={`tel:${phoneNumber}`}
              onClick={() => {
                history.push(
                  `/WhatHappened/${clientId}${
                    callListId ? `?callListId=${callListId}` : ''
                  }`
                )
              }}
            >
              CALL
            </IonButton>
          </div>
        </div>
      </IonCardContent>
    </IonCard>
  )
}

export default PhoneCard

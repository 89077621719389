import { Product } from '../types/shopwith'
import { typedBoolean } from './typescript-helpers'

export type RadioFilters = 'yes' | 'no' | undefined

function getSortedDataList(
  list: Array<Product>,
  key: keyof Pick<
    Product,
    | 'brand'
    | 'title'
    | 'posId'
    | 'category'
    | 'gender'
    | 'year'
    | 'season'
    | 'delivery'
  >
) {
  return [
    ...Array.from(new Set(list.map((item) => item[key]).filter(typedBoolean))),
  ].sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' }))
}

function getRadioBoolean(value: RadioFilters) {
  return value === 'yes' ? true : value === 'no' ? false : undefined
}

function getRadioValue(bool: boolean | undefined) {
  return bool === true ? 'yes' : bool === false ? 'no' : undefined
}

export { getRadioBoolean, getRadioValue, getSortedDataList }

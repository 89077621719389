/* eslint-disable react/jsx-pascal-case */
import * as React from 'react'
import { isPlatform } from '@ionic/react'
import {
  ArrowDownTrayIcon,
  PencilIcon,
  PlusSmallIcon,
  UserMinusIcon,
  UserPlusIcon,
} from '@heroicons/react/24/solid'
import { Button, IconButton, Tooltip } from '@mui/material'
import {
  MaterialReactTable,
  MRT_ToggleGlobalFilterButton,
} from 'material-react-table'
import { download, generateCsv, mkConfig } from 'export-to-csv'

import { useBoolean } from '../../../hooks/use-boolean'
import { AddUserModal } from './add-user-modal'
import { columns } from '../columns'
import { DetailPanel } from './detail-panel'
import { EditUserModal } from './edit-user-modal'
import {
  useAssignAllClients,
  useAutoAssignUserClients,
  useClearUserClients,
} from '../mutations'
import { fetchUsers } from '../queries'

import type {
  MRT_PaginationState,
  MRT_Row,
  MRT_TableState,
} from 'material-react-table'
import type { UserSetup } from '../user-setup.schema'
import type {
  ConfigOptions,
  WithDefaults,
} from 'export-to-csv/output/lib/types'
import { useAccount } from '../../../contexts/authContext'

type CsvHeader = WithDefaults<ConfigOptions>['columnHeaders']
type Props = {
  data: Array<UserSetup>
  disabled: boolean
  rowCount: number
  // state: Pick<MRT_TableState, 'pagination' | 'isLoading' | 'showProgressBars'>
  // onPaginationChange: React.Dispatch<React.SetStateAction<MRT_PaginationState>>
  state: Pick<MRT_TableState, 'isLoading' | 'showProgressBars'>
}

function UserSetupTable(props: Props) {
  const { data, disabled, state, rowCount } = props

  const [userData, setUserData] = React.useState<UserSetup | null>(null)

  const [isAddUserModalOpen, { on: openAddUserModal, off: closeAddUserModal }] =
    useBoolean()
  const [
    isEditUserModalOpen,
    { on: openEditUserModal, off: closeEditUserModal },
  ] = useBoolean()

  const account = useAccount()
  const isClienteling = account.account.settings.clienteling

  const autoAssignClients = useAutoAssignUserClients()
  const assignAllClients = useAssignAllClients()
  const clearUserClients = useClearUserClients()

  function handleAssignAll() {
    assignAllClients.mutate()
  }

  function handleAutoAssignClients(row: MRT_Row<UserSetup>) {
    autoAssignClients.mutate({ objectId: row.original.user.objectId })
  }

  function handleClearClients(id: string) {
    clearUserClients.mutate(id)
  }

  async function handleExportToCSV(headers: CsvHeader) {
    const users = await fetchUsers({
      queryKey: [{ entity: 'user setup', page: 0, pageSize: rowCount }],
      meta: undefined,
    })

    const config = mkConfig({
      columnHeaders: headers,
      filename: 'users',
    })

    const csv = generateCsv(config)(
      users.data.map((user) => ({
        clientCount: user.clientCount,
        ...user.user,
      }))
    )

    download(config)(csv)
  }

  const isMutating =
    autoAssignClients.isLoading ||
    assignAllClients.isLoading ||
    clearUserClients.isLoading

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data}
        initialState={{ showGlobalFilter: true, density: 'comfortable' }}
        state={{ ...state, showAlertBanner: false }}
        positionToolbarAlertBanner="none"
        defaultColumn={{ size: 0 }}
        enableColumnActions={false}
        enableColumnFilters={false}
        enableColumnOrdering
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableHiding={false} // not all tables have
        enableRowActions // not all tables have
        enableSorting={false}
        // manualPagination
        muiTableBodyRowProps={() => ({ hover: false })}
        // onPaginationChange={onPaginationChange}
        displayColumnDefOptions={{
          'mrt-row-expand': { header: 'More Info' },
        }}
        enableExpandAll={false}
        renderDetailPanel={({ row }) => (
          <DetailPanel
            detail={{
              groupMembers: row.original.groupMembers,
              rootInfo: {
                posId: row.original.groupMembers.length
                  ? row.original.groupMembers[0].posId
                  : null,
                userId: row.original.user.objectId,
              },
            }}
          />
        )}
        renderToolbarInternalActions={({ table }) => (
          <>
            <MRT_ToggleGlobalFilterButton table={table} />
            <div className="flex gap-x-2">
              {isClienteling ? (
                <Button
                  disabled={disabled || isMutating}
                  color="primary"
                  variant="contained"
                  onClick={handleAssignAll}
                >
                  Assign All
                </Button>
              ) : null}
              <Button
                disabled={disabled || isMutating}
                color="primary"
                variant="contained"
                onClick={openAddUserModal}
              >
                Add User
              </Button>
            </div>
            {!isPlatform('capacitor') ? (
              <Tooltip title="Export">
                <span className="flex">
                  <button
                    className="text-ion-color-step-400 disabled:text-ion-color-step-700 flex items-center justify-center p-2"
                    onClick={() =>
                      handleExportToCSV(
                        table
                          .getFlatHeaders()

                          //  filter out photo and row action headers
                          .filter((header) => header.id !== 'photo')
                          .filter((header) => header.id !== 'mrt-row-actions')
                          .map((header) => {
                            return {
                              key: header.column.id.substring(
                                header.column.id.indexOf('.') + 1
                              ),
                              displayLabel: (header.column.columnDef.header ??
                                '') as string,
                            }
                          })
                      )
                    }
                  >
                    <ArrowDownTrayIcon className="size-6" />
                  </button>
                </span>
              </Tooltip>
            ) : null}

            {/* <Tooltip title="Add User">
              <span className="flex">
                <button
                  disabled={Boolean(table.getState().editingRow)}
                  className="bg-ion-color-yellow rounded p-1 text-slate-900 disabled:opacity-50"
                  onClick={openAddUserModal}
                >
                  <PlusSmallIcon className=" size-4" />
                </button>
              </span>
            </Tooltip> */}
          </>
        )}
        renderRowActions={({ row, table }) => (
          <>
            <div className="nowrap flex gap-2">
              <Tooltip title="Edit">
                <span className="flex">
                  <IconButton
                    className="text-slate-500"
                    onClick={() => {
                      setUserData(row.original)
                      openEditUserModal()
                    }}
                    disabled={disabled || isMutating}
                  >
                    <PencilIcon className="size-5" />
                  </IconButton>
                </span>
              </Tooltip>

              {isClienteling ? (
                <>
                  <Tooltip title="Auto-Assign Clients">
                    <span className="flex">
                      <IconButton
                        color="primary"
                        onClick={() => handleAutoAssignClients(row)}
                        disabled={
                          disabled ||
                          isMutating ||
                          row.original.user.deactivated
                        }
                      >
                        <UserPlusIcon className="size-6" />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Un-Assign Clients">
                    <span className="flex">
                      <IconButton
                        color="error"
                        onClick={() => {
                          handleClearClients(row.original.user.objectId)
                        }}
                        disabled={disabled || isMutating}
                      >
                        <UserMinusIcon className="size-6" />
                      </IconButton>
                    </span>
                  </Tooltip>
                </>
              ) : null}
            </div>
          </>
        )}
        rowCount={rowCount}
      />

      <AddUserModal onClose={closeAddUserModal} open={isAddUserModalOpen} />

      <EditUserModal
        userData={userData}
        onClose={() => {
          setUserData(null)
          closeEditUserModal()
        }}
        open={isEditUserModalOpen}
      />
    </>
  )
}

export { UserSetupTable }

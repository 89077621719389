import * as React from 'react'
import {
  IonButton,
  IonCol,
  IonContent,
  IonHeader,
  IonImg,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  useIonRouter,
} from '@ionic/react'
import { useParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { ResourceType } from '@capawesome/capacitor-cloudinary'
import clsx from 'clsx'

import placeholder from '../../resources/no-image-product.png'
import { useAccount, useUser } from '../../contexts/authContext'
import { useToast } from '../../contexts/toastContext'
import { useLook } from '../../hooks/shopwith/looks/queries'
import { useDefaultSocialConnections } from '../../ayrshare/queries'
import { SchedulePicker } from '../../components/ui/form/schedule-picker'
import { useShareLook } from '../../social-sharing/posts/mutations'
import { shareAssetInputSchema } from '../../social-sharing/posts/posts.schema'
import SocialChannelSelector from '../../social-sharing/social-channel-selector'
import {
  getAccountChannels,
  getSocialConnections,
  getUserChannels,
} from '../../social-sharing/utils'
import Header from '../../components/header'
import CollageItem from '../../components/shopwith/collage-item'
import { TextArea } from '../../components/ui/form/textarea'

import type { ShareAssetInput } from '../../social-sharing/posts/posts.schema'

const defaultValues: ShareAssetInput = {
  description: '',
  date: '',
  selectedChannels: {},
}

function ShareAssetPage() {
  const { assetId } = useParams<{ assetId: string }>()

  const user = useUser()
  const account = useAccount()

  const router = useIonRouter()
  const setToast = useToast()

  const ayrshareConnectionsQuery = useDefaultSocialConnections()
  const lookQuery = useLook(assetId)
  const shareLook = useShareLook()

  const accountChannels = getAccountChannels(ayrshareConnectionsQuery.data)
  const userChannels = getUserChannels(ayrshareConnectionsQuery.data)

  const postForm = useForm<ShareAssetInput>({
    defaultValues,
    values: {
      ...defaultValues,
      selectedChannels: {
        ...accountChannels,
        ...(Object.keys(userChannels).length
          ? userChannels
          : { uninitUser: ['oneshop'] }),
      },
    },
    resolver: zodResolver(shareAssetInputSchema),
  })

  async function handleShareAsset(data: ShareAssetInput) {
    const { description, date, selectedChannels } = data
    const noSocialChannelsSelected = Object.values(selectedChannels).every(
      (sc) => !sc.length
    )

    if (!lookQuery.data)
      return setToast({
        message: 'Something went wrong loading the asset to share.',
      })

    if (noSocialChannelsSelected)
      return setToast({ message: 'You must share to at least one channel.' })

    if (
      lookQuery.data.cloudinary?.data?.resource_type !== ResourceType.Video &&
      Object.values(selectedChannels).some((platforms) =>
        platforms.includes('tiktok')
      )
    ) {
      return setToast({
        message:
          'You cannot share an image to TikTok. Please deselct that channel.',
      })
    }

    // Notify user immediately that a post is in process
    setToast({
      message:
        'Congrats! Your post has started sharing and should be done shortly!',
      color: 'yellow',
    })

    const socialConnections = getSocialConnections(selectedChannels)

    shareLook.mutate({
      lookId: lookQuery.data.objectId,
      description,
      date,
      socialConnections,
    })

    router.push('/tabs/home', 'back', 'pop')
  }

  return (
    <IonPage>
      <IonHeader>
        <Header
          title="Share Asset"
          btn={
            <IonButton
              color="yellow"
              onClick={postForm.handleSubmit(handleShareAsset)}
              disabled={shareLook.isLoading}
            >
              Share
            </IonButton>
          }
        />
      </IonHeader>
      <IonContent>
        {lookQuery.data && Object.keys(account).length ? (
          <>
            <IonRow className="ion-padding-horizontal">
              <IonCol size="5" sizeLg="3">
                {lookQuery.data.cloudinary?.data?.resource_type ===
                ResourceType.Video ? (
                  <div className="aspect-h-16 aspect-w-9 w-full">
                    <video
                      src={`${lookQuery.data.cloudinary.data.url}#t=0.1`}
                      controls
                      playsInline
                      preload="metadata"
                    />
                  </div>
                ) : (
                  <div className="aspect-h-4 aspect-w-3 w-full">
                    {lookQuery.data.coverPhoto?.originalSrc ? (
                      <IonImg
                        src={lookQuery.data.coverPhoto.originalSrc}
                        className="object-cover object-top"
                      />
                    ) : (
                      <CollageItem items={lookQuery.data.products} />
                    )}
                  </div>
                )}
              </IonCol>
              <IonCol size="7" sizeLg="5" offsetLg="1">
                <IonItem style={{ '--padding-start': 0 }} lines="none">
                  <div className="px-2 pb-2">
                    <IonLabel
                      position="stacked"
                      color={
                        postForm.formState.errors.description
                          ? 'danger'
                          : 'secondary'
                      }
                    >
                      Description (required)
                    </IonLabel>
                    <Controller
                      name="description"
                      control={postForm.control}
                      render={({ field: { value, onChange } }) => (
                        <TextArea
                          style={{
                            '--background': 'var(--ion-color-step-100)',
                          }}
                          className={clsx([
                            'overflow-y-auto',
                            postForm.formState.errors.description &&
                              'ring-ion-color-danger ring-2',
                          ])}
                          rows={4}
                          value={value}
                          onIonChange={onChange}
                          autocapitalize="on"
                        />
                      )}
                    />
                  </div>
                </IonItem>
              </IonCol>
            </IonRow>

            {/* Product List */}
            <IonRow className="ion-padding-horizontal">
              {lookQuery.data.products.map((product) => (
                <IonCol size="2" key={product.objectId}>
                  <div className="aspect-h-10 aspect-w-7 w-full">
                    <IonImg
                      src={product.featuredImage ?? placeholder}
                      className="object-cover object-top"
                    />
                  </div>
                </IonCol>
              ))}
            </IonRow>

            {/* Schedule Post */}
            {!account.roleSocial.requiresApproval ? (
              <div className="ion-padding-horizontal ion-padding-top space-y-4">
                <Controller
                  name="date"
                  control={postForm.control}
                  render={({ field: { value, onChange } }) => (
                    <SchedulePicker
                      label="Schedule Post"
                      value={value}
                      onChange={onChange}
                      onReset={() => postForm.setValue('date', '')}
                    />
                  )}
                />
              </div>
            ) : null}

            {/* <SocialChannels /> */}
            <>
              {ayrshareConnectionsQuery.data ? (
                <div className="ion-padding-horizontal ion-margin-top">
                  {ayrshareConnectionsQuery.data
                    .filter(
                      (ayrshare) =>
                        ayrshare.level === 'account' ||
                        ayrshare.level === 'group'
                    )
                    .map((ayrshareConnection) => (
                      <React.Fragment key={ayrshareConnection.objectId}>
                        <h2>{ayrshareConnection.name}</h2>
                        <div className="flex space-x-4">
                          <SocialChannelSelector
                            ayrshareConnection={ayrshareConnection}
                            control={postForm.control}
                            name={`selectedChannels.${ayrshareConnection.objectId}`}
                          />
                        </div>
                      </React.Fragment>
                    ))}
                  <h2>{user.displayName} - Business</h2>
                  {ayrshareConnectionsQuery.data.filter(
                    (ayrshare) =>
                      ayrshare.type === 'business' && ayrshare.level === 'user'
                  ).length > 0 ? (
                    <>
                      {ayrshareConnectionsQuery.data
                        .filter(
                          (ayrshare) =>
                            ayrshare.type === 'business' &&
                            ayrshare.level === 'user'
                        )
                        .map((ayrshareConnection, index) => (
                          <React.Fragment key={ayrshareConnection.objectId}>
                            <SocialChannelSelector
                              ayrshareConnection={ayrshareConnection}
                              control={postForm.control}
                              name={`selectedChannels.${ayrshareConnection.objectId}`}
                              shareToOneShop={index === 0}
                            />
                          </React.Fragment>
                        ))}
                    </>
                  ) : (
                    <SocialChannelSelector
                      control={postForm.control}
                      name="selectedChannels.uninitUser"
                      shareToOneShop
                    />
                  )}
                </div>
              ) : null}
            </>

            <IonLoading
              isOpen={shareLook.isLoading}
              message="Sharing Asset..."
            />
          </>
        ) : (
          <IonLoading isOpen message="Loading..." />
        )}
      </IonContent>
    </IonPage>
  )
}

export default ShareAssetPage

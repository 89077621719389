import { useMutation } from '@tanstack/react-query'

import { client } from '../../services/client'
import { useToast } from '../../contexts/toastContext'
import { shopifyAddStoreResponseSchema } from './shopify.schema'

import type { ShopifyAddStoreInputs } from './shopify.schema'

async function shopifyAddStore(params: ShopifyAddStoreInputs) {
  const response = await client
    .post('functions/shopifyAddStore', {
      json: { ...params },
    })
    .json()

  return shopifyAddStoreResponseSchema.parse(response).result
}

function useShopifyAddStore() {
  const setToast = useToast()

  return useMutation(shopifyAddStore, {
    onError: (error) => {
      const errorMsg = error instanceof Error ? error.message : 'Unknown error.'
      setToast({
        message: `Something went wrong while creating adding Shopify: ${errorMsg}`,
        color: 'danger',
      })
    },
  })
}

export { useShopifyAddStore }

import * as React from 'react'
import {
  IonRouterOutlet,
  IonContent,
  IonIcon,
  IonItem,
  IonList,
  IonMenu,
  IonMenuToggle,
} from '@ionic/react'
import { useHistory, Route, Redirect } from 'react-router-dom'
import { logOut, desktop, person, home } from 'ionicons/icons'

import { AuthContext } from './contexts/authContext'
import { PrivateRoute } from './auth/private-route'
import { MenuLabel } from './menu/components/menu-label'
import { MenuItem } from './menu/components/menu-item'
import { CheckInHome } from './pages/admin/check-in/paired/check-in-home'
import { LoyaltyDetailPage } from './pages/admin/check-in/paired/loyalty-detail-page'
import { TerminalSetupPage } from './pages/admin/terminal-setup-page'
import { PairedAddCustomerPage } from './pages/rotate/paired-add-customer-page'
import { PairedEmailCheckInPage } from './pages/rotate/paired-email-check-in-page'
import { PairedLoyaltyInfoPage } from './pages/rotate/paired-loyalty-info-page'
import { PairedPhoneCheckInPage } from './pages/rotate/paired-phone-check-in-page'
import { useActiveStations } from './rotate/queries'

function CheckInApp() {
  const { handleLogOut } = React.useContext(AuthContext)

  const history = useHistory()

  const activeStations = useActiveStations()
  const isPos = activeStations.data?.pages
    .flatMap((d) => d.data)
    .some((s) => s.isPos)
  const isClient = activeStations.data?.pages
    .flatMap((d) => d.data)
    .some((s) => s.isClient)

  return (
    <>
      <IonMenu type="overlay" contentId="main" swipeGesture={isClient}>
        <IonContent forceOverscroll={false}>
          <IonList lines="none">
            {isPos ? (
              <MenuItem
                route={{
                  title: 'Check-In Home',
                  path: '/check-in/paired/admin/home',
                  icon: home,
                  appSettings: ['clienteling', 'marketing'],
                  user: 'admin',
                }}
              />
            ) : null}
            {isClient ? (
              <MenuItem
                route={{
                  title: 'Paired Check-In',
                  path: '/check-in/paired/phone-check-in',
                  icon: person,
                  appSettings: ['clienteling', 'marketing'],
                  user: 'admin',
                }}
              />
            ) : null}
            <MenuItem
              route={{
                title: 'Terminal Setup',
                path: '/check-in/paired/admin/terminal-setup',
                icon: desktop,
                user: 'admin',
              }}
            />

            <IonMenuToggle>
              <IonItem
                detail={false}
                button
                onClick={() => handleLogOut(history)}
              >
                <IonIcon slot="start" icon={logOut} />
                <MenuLabel>Logout</MenuLabel>
              </IonItem>
            </IonMenuToggle>
          </IonList>
        </IonContent>
      </IonMenu>

      <IonRouterOutlet id="main">
        <Route exact path="/check-in/paired/admin/home">
          <CheckInHome />
        </Route>

        {/* Paired Check-In */}
        <PrivateRoute
          exact
          path="/check-in/paired/phone-check-in"
          requiredSettings={['clienteling', 'marketing']}
        >
          <PairedPhoneCheckInPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/check-in/paired/email-check-in"
          requiredSettings={['clienteling', 'marketing']}
        >
          <PairedEmailCheckInPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/check-in/paired/add-customer"
          requiredSettings={['clienteling', 'marketing']}
        >
          <PairedAddCustomerPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/check-in/paired/loyalty-info"
          requiredSettings={['clienteling', 'marketing']}
        >
          <PairedLoyaltyInfoPage />
        </PrivateRoute>

        <Route exact path="/check-in/paired/admin/terminal-setup">
          <TerminalSetupPage />
        </Route>

        <Route exact path="/check-in/paired/admin/redeem-loyalty">
          <LoyaltyDetailPage />
        </Route>

        {isPos ? (
          <Route>
            <Redirect to="/check-in/paired/admin/home" />
          </Route>
        ) : (
          <Route>
            <Redirect to="/check-in/paired/phone-check-in" />
          </Route>
        )}
      </IonRouterOutlet>
    </>
  )
}

export { CheckInApp }

import { cva } from 'class-variance-authority'

import { cn } from '../../utils/cn'

import type { VariantProps } from 'class-variance-authority'
import type { ComponentProps } from 'react'

type Props = VariantProps<typeof buttonVariants> &
  Omit<ComponentProps<'button'>, 'className' | 'fill' | 'color'>

const buttonVariants = cva(
  [
    'size-full rounded-lg px-5 py-1 font-semibold leading-none disabled:opacity-30',
  ],
  {
    variants: {
      color: {
        primary: [],
        secondary: [],
        danger: [],
      },
      fill: {
        outline: ['border'],
        solid: [],
      },
      size: { default: ['text-base'], lg: ['text-2xl'] },
    },
    compoundVariants: [
      {
        fill: 'solid',
        color: 'primary',
        className: ['bg-ion-color-yellow text-ion-color-primary'],
      },
      {
        fill: 'solid',
        color: 'secondary',
        className: ['bg-ion-color-tertiary text-ion-color-primary'],
      },
      {
        fill: 'solid',
        color: 'danger',
        className: ['bg-ion-color-danger text-ion-color-secondary'],
      },
      {
        fill: 'outline',
        color: 'primary',
        className: ['text-ion-color-yellow border-ion-color-yellow'],
      },
      {
        fill: 'outline',
        color: 'secondary',
        className: ['text-ion-color-tertiary border-ion-color-tertiary'],
      },
      {
        fill: 'outline',
        color: 'danger',
        className: ['border-ion-color-danger text-ion-color-danger'],
      },
    ],
    defaultVariants: { color: 'primary', fill: 'solid' },
  }
)

function LoyaltyButton({ children, fill, color, size, ...buttonProps }: Props) {
  return (
    <button
      className={cn(buttonVariants({ color, fill, size }))}
      {...buttonProps}
    >
      {children}
    </button>
  )
}

export { LoyaltyButton }

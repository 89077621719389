import { useMutation, useQueryClient } from '@tanstack/react-query'
import * as R from 'remeda'

import { makeServerResponseSchema } from '../utils/schema'
import { client } from '../services/client'
import { useToast } from '../contexts/toastContext'
import {
  createAdjustment,
  createSale,
  deleteAdjustment,
  deleteLoyaltyPoint,
  deleteReceipt,
  updateAdjustment,
  updateLoyaltyPoint,
  updateReceipt,
} from './actions'
import { loyaltyRuleBaseSchema } from './loyalty.schema'
import { loyaltyQueryKeys } from './queries'

import type {
  CreateLoyaltyProgramParams,
  UpdateLoyaltyProgramParams,
} from './loyalty.schema'
import { useLoyalty } from './components/loyalty-redemption-detail'
import { heartlandQueryKeys } from '../integrations/heartland/queries'
import { lightspeedQueryKeys } from '../integrations/lightspeed/queries'

async function createLoyaltyProgram(params: CreateLoyaltyProgramParams) {
  const { steps, ...rule } = params

  try {
    const createdLoyaltyRule = await client
      .post('functions/createLoyaltyRule', {
        json: rule,
      })
      .json()

    const parsedRule = makeServerResponseSchema(loyaltyRuleBaseSchema).parse(
      createdLoyaltyRule
    ).result

    const createdLoyaltySteps = await Promise.allSettled(
      // createSteps
      steps.map((step) =>
        client
          .post('functions/createLoyaltyStep', {
            json: {
              ...step,
              loyaltyRuleId: parsedRule.objectId,
            },
          })
          .json()
      )
    )

    return Promise.resolve()
  } catch (e) {
    if (e instanceof Error) throw new Error(e.message)
    throw new Error('unknown error')
  }
}

async function updateLoyaltyProgram(params: UpdateLoyaltyProgramParams) {
  const { steps = [], ...rule } = params

  const [currentSteps, newSteps] = R.partition(steps, (step) =>
    Boolean(step.objectId)
  )
  try {
    await Promise.allSettled([
      // update Rule
      client.post('functions/updateLoyaltyRule', { json: rule }).json(),
      // update Steps
      ...currentSteps.map((step) =>
        client
          .post('functions/updateLoyaltyStep', {
            json: { loyaltyRuleId: rule.objectId, ...step },
          })
          .json()
      ),
      // createSteps
      ...newSteps.map((step) =>
        client
          .post('functions/createLoyaltyStep', {
            json: { loyaltyRuleId: rule.objectId, ...step },
          })
          .json()
      ),
    ])

    return Promise.resolve()
  } catch (e) {
    if (e instanceof Error) throw new Error(e.message)
    throw new Error('unknown error')
  }
}

function useCreateAdjustment() {
  const { loyaltyType, clientId } = useLoyalty()
  const queryClient = useQueryClient()
  const setToast = useToast()

  return useMutation({
    mutationFn: createAdjustment,
    onError: (error) =>
      setToast({
        message:
          error instanceof Error
            ? error.message
            : 'Something went wrong: Unknown Error.',
        color: 'danger',
      }),
    onSettled: () => {
      loyaltyType === 'heartland'
        ? queryClient.invalidateQueries(heartlandQueryKeys.all)
        : loyaltyType === 'lightspeed'
        ? queryClient.invalidateQueries(lightspeedQueryKeys.all)
        : queryClient.invalidateQueries({
            queryKey: loyaltyQueryKeys.clientLoyalty(clientId),
          })
    },
    onSuccess: () => {
      setToast({
        message: 'The card value has been adjusted.',
        color: 'yellow',
      })
    },
  })
}

function useCreateLoyaltyProgram() {
  const queryClient = useQueryClient()

  return {
    publishLoyaltyProgram: useMutation(
      (params: CreateLoyaltyProgramParams) =>
        createLoyaltyProgram({ ...params, state: 'active' }),
      {
        onSettled() {
          queryClient.invalidateQueries(loyaltyQueryKeys.loyaltyPrograms())
        },
      }
    ),
    saveLoyaltyProgramDraft: useMutation(
      (params: CreateLoyaltyProgramParams) =>
        createLoyaltyProgram({ state: 'draft', ...params }),
      {
        onSettled() {
          queryClient.invalidateQueries(loyaltyQueryKeys.loyaltyPrograms())
        },
        onError: (error, variables, context) => {
          console.error(error)
        },
      }
    ),
  }
}

function useCreateSale() {
  const queryClient = useQueryClient()
  const setToast = useToast()

  return useMutation(createSale, {
    onError: (error) => {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown Error'

      return setToast({
        message: `Something went wrong while adding the sale. ${errorMessage}`,
      })
    },
    onSettled: (_data, _error, variables) =>
      queryClient.invalidateQueries({
        queryKey: loyaltyQueryKeys.clientLoyalty(variables.clientId),
      }),
    onSuccess: () =>
      setToast({
        message: 'The sale was successfully added.',
        color: 'yellow',
      }),
  })
}

function useDeleteLoyaltyPoint() {
  const queryClient = useQueryClient()
  const { loyaltyType, clientId } = useLoyalty()

  const setToast = useToast()

  return useMutation({
    mutationFn: deleteLoyaltyPoint,
    onSettled: () => {
      loyaltyType === 'heartland'
        ? queryClient.invalidateQueries(heartlandQueryKeys.all)
        : loyaltyType === 'lightspeed'
        ? queryClient.invalidateQueries(lightspeedQueryKeys.all)
        : queryClient.invalidateQueries({
            queryKey: loyaltyQueryKeys.clientLoyalty(clientId),
          })
    },
    onSuccess: () =>
      setToast({
        message: 'The loyalty points were deleted.',
        color: 'yellow',
      }),
  })
}

function useDeleteReceipt() {
  const { loyaltyType, clientId } = useLoyalty()

  const queryClient = useQueryClient()
  const setToast = useToast()

  return useMutation({
    mutationFn: deleteReceipt,
    onSettled: () => {
      loyaltyType === 'heartland'
        ? queryClient.invalidateQueries(heartlandQueryKeys.all)
        : loyaltyType === 'lightspeed'
        ? queryClient.invalidateQueries(lightspeedQueryKeys.all)
        : queryClient.invalidateQueries({
            queryKey: loyaltyQueryKeys.clientLoyalty(clientId),
          })
    },
    onSuccess: () =>
      setToast({ message: 'The receipt was deleted.', color: 'yellow' }),
  })
}

function useDeleteAdjustment() {
  const { loyaltyType, clientId } = useLoyalty()

  const queryClient = useQueryClient()
  const setToast = useToast()

  return useMutation({
    mutationFn: deleteAdjustment,
    onError: (error) =>
      setToast({
        message:
          error instanceof Error
            ? error.message
            : 'Something went wrong: Unknown Error.',
        color: 'danger',
      }),
    onSettled: () => {
      loyaltyType === 'heartland'
        ? queryClient.invalidateQueries(heartlandQueryKeys.all)
        : loyaltyType === 'lightspeed'
        ? queryClient.invalidateQueries(lightspeedQueryKeys.all)
        : queryClient.invalidateQueries({
            queryKey: loyaltyQueryKeys.clientLoyalty(clientId),
          })
    },
    onSuccess: () => {
      setToast({
        message: 'The card adjustment was deleted.',
        color: 'yellow',
      })
    },
  })
}

function useUpdateAdjustment() {
  const { loyaltyType, clientId } = useLoyalty()

  const queryClient = useQueryClient()
  const setToast = useToast()

  return useMutation({
    mutationFn: updateAdjustment,
    onError: (error) =>
      setToast({
        message:
          error instanceof Error
            ? error.message
            : 'Something went wrong: Unknown Error.',
        color: 'danger',
      }),
    onSettled: () => {
      loyaltyType === 'heartland'
        ? queryClient.invalidateQueries(heartlandQueryKeys.all)
        : loyaltyType === 'lightspeed'
        ? queryClient.invalidateQueries(lightspeedQueryKeys.all)
        : queryClient.invalidateQueries({
            queryKey: loyaltyQueryKeys.clientLoyalty(clientId),
          })
    },
    onSuccess: () => {
      setToast({
        message: 'The card value has been adjusted.',
        color: 'yellow',
      })
    },
  })
}

function useUpdateLoyaltyPoint() {
  const { loyaltyType, clientId } = useLoyalty()

  const queryClient = useQueryClient()
  const setToast = useToast()

  return useMutation({
    mutationFn: updateLoyaltyPoint,
    onSettled: () => {
      loyaltyType === 'heartland'
        ? queryClient.invalidateQueries(heartlandQueryKeys.all)
        : loyaltyType === 'lightspeed'
        ? queryClient.invalidateQueries(lightspeedQueryKeys.all)
        : queryClient.invalidateQueries({
            queryKey: loyaltyQueryKeys.clientLoyalty(clientId),
          })
    },
    onSuccess: () =>
      setToast({
        message: 'The loyalty points were updated.',
        color: 'yellow',
      }),
  })
}

function useUpdateLoyaltyProgram() {
  const queryClient = useQueryClient()

  return {
    publishLoyaltyProgram: useMutation(
      (params: UpdateLoyaltyProgramParams) =>
        updateLoyaltyProgram({ ...params, state: 'active' }),
      {
        onSettled(data, error, variables, context) {
          queryClient.invalidateQueries(
            loyaltyQueryKeys.loyaltyProgramDetail(variables.objectId)
          )
          queryClient.invalidateQueries(loyaltyQueryKeys.loyaltyPrograms())
        },
      }
    ),
    saveLoyaltyProgramDraft: useMutation(
      (params: UpdateLoyaltyProgramParams) =>
        updateLoyaltyProgram({ state: 'draft', ...params }),
      {
        onSettled(data, error, variables, context) {
          queryClient.invalidateQueries(
            loyaltyQueryKeys.loyaltyProgramDetail(variables.objectId)
          )
          queryClient.invalidateQueries(loyaltyQueryKeys.loyaltyPrograms())
        },
        onError: (error, variables, context) => {
          console.error(error)
        },
      }
    ),
    stopLoyaltyProgram: useMutation(
      (objectId: UpdateLoyaltyProgramParams['objectId']) =>
        updateLoyaltyProgram({ objectId, state: 'stopped' }),
      {
        onSettled(data, error, variables, context) {
          queryClient.invalidateQueries(
            loyaltyQueryKeys.loyaltyProgramDetail(variables)
          )
        },
        onError: (error, variables, context) => {
          console.error(error)
        },
      }
    ),
  }
}

function useUpdateReceipt() {
  const { loyaltyType, clientId } = useLoyalty()

  const queryClient = useQueryClient()
  const setToast = useToast()

  return useMutation({
    mutationFn: updateReceipt,
    onSettled: () => {
      loyaltyType === 'heartland'
        ? queryClient.invalidateQueries(heartlandQueryKeys.all)
        : loyaltyType === 'lightspeed'
        ? queryClient.invalidateQueries(lightspeedQueryKeys.all)
        : queryClient.invalidateQueries({
            queryKey: loyaltyQueryKeys.clientLoyalty(clientId),
          })
    },
    onSuccess: () =>
      setToast({ message: 'The receipt was updated.', color: 'yellow' }),
  })
}

export {
  useCreateAdjustment,
  useCreateLoyaltyProgram,
  useCreateSale,
  useDeleteAdjustment,
  useUpdateAdjustment,
  useDeleteReceipt,
  useUpdateReceipt,
  useDeleteLoyaltyPoint,
  useUpdateLoyaltyPoint,
  useUpdateLoyaltyProgram,
}

import { Capacitor } from '@capacitor/core'
import {
  CapacitorBarcodeScanner,
  CapacitorBarcodeScannerTypeHintALLOption,
} from '@capacitor/barcode-scanner'

const canScanSku = Capacitor.isNativePlatform()

async function getSku() {
  return CapacitorBarcodeScanner.scanBarcode({
    hint: CapacitorBarcodeScannerTypeHintALLOption.ALL,
  })
}

async function scanSku() {
  const result = await getSku()

  if (!result.ScanResult) return // todo: possibly permissions error toast since this indicates an issue
  return result.ScanResult
}

export { canScanSku, scanSku }

function SaveFooter({
  onCancel,
  onSuccess,
}: {
  onCancel: () => void
  onSuccess: () => void
}) {
  return (
    <div className="grid grid-cols-2 divide-x divide-white/20 border-t border-white/20">
      <button
        type="button"
        onClick={onCancel}
        className="text-ion-color-secondary p-1"
      >
        Cancel
      </button>
      <button
        type="button"
        onClick={onSuccess}
        className="text-ion-color-yellow p-1"
      >
        Save
      </button>
    </div>
  )
}

export { SaveFooter }

import { Fragment } from 'react'

import { Card, CardContent } from '../../components/ui/card'
import { LoyaltyDetailHeading } from '../../loyalty/components/loyalty-detail-heading'
import { LoyaltyDetailInfo } from '../../loyalty/components/loyalty-detail-info'
import { SixBoxCard } from '../../loyalty/components/six-box-card'

import type { LoyaltyData } from '../../loyalty/loyalty.schema'
import type { CustomRules } from '../../loyalty/queries'
import { CustomerBonus } from '../../loyalty/components/customer-bonus'

type Props = {
  data: LoyaltyData & CustomRules
}

function CustomerLoyaltyInfo({ data }: Props) {
  return (
    <div className="space-y-8 pb-6">
      <div className="flex flex-col items-center justify-center gap-3">
        <LoyaltyDetailHeading>{data.client.displayName}</LoyaltyDetailHeading>
        <LoyaltyDetailInfo
          lifetimeLoyaltyVisits={data.loyaltyVisitsLifetime}
          pointsBalance={data.pointsBalance}
          visitsUntilReward={data.visitsUntilReward}
        />
      </div>

      {data.bonusRules.length ? (
        <section className="ion-padding-horizontal mx-auto max-w-lg">
          <h2 className="text-xl">Bonuses</h2>
          <div className="space-y-2">
            {data.bonusRules.map((bonusRule) => (
              <Fragment key={bonusRule.loyaltyBonusRule.objectId}>
                {bonusRule.rewards.map((bonus) => (
                  <Card>
                    <CardContent>
                      <CustomerBonus bonus={bonus} />
                    </CardContent>
                  </Card>
                ))}
              </Fragment>
            ))}
          </div>
        </section>
      ) : null}

      <section className="ion-padding-horizontal mx-auto max-w-lg">
        <h2 className="text-xl">Rewards</h2>
        <div className="space-y-2">
          {data.rewardRules.map((rewardRule) => (
            <Fragment key={rewardRule.loyaltyRule.objectId}>
              {rewardRule.rewards.map((reward) => (
                <div key={JSON.stringify(reward)}>
                  <Card>
                    <CardContent>
                      {/* REWARD */}
                      <div className="flex items-center">
                        <div className="flex flex-1 flex-col items-center">
                          <span className="text-lg font-semibold">
                            Redeem {reward.text}
                          </span>
                          <span className="text-lg">
                            {reward.pointsBalance} Points
                          </span>
                        </div>
                      </div>
                      {/* SIXBOX */}
                      {rewardRule.loyaltyRule.settings.visits > 0 ? (
                        <SixBoxCard
                          reward={reward}
                          totalBoxes={rewardRule.loyaltyRule.settings.visits}
                        />
                      ) : null}
                    </CardContent>
                  </Card>
                </div>
              ))}
            </Fragment>
          ))}
        </div>
      </section>
    </div>
  )
}

export { CustomerLoyaltyInfo }

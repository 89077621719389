import { useGoals } from '../../../hooks/outreach/queries'
import {
  useSmsSubscriberCount,
  useEmailSubscriberCount,
} from '../../../hooks/clients/queries'
import { StatCard, StatCardSkeleton } from '../components/stat-card'

function MessageGoals() {
  const goalsQuery = useGoals()

  return (
    <>
      {goalsQuery.data ? (
        <StatCard
          label="Goals"
          stat={`${goalsQuery.data.thisMonthMessageCount}/${goalsQuery.data.goal}`}
        />
      ) : (
        <StatCardSkeleton />
      )}
    </>
  )
}

function SmsSubscribers() {
  const textSubscriberCountQuery = useSmsSubscriberCount()

  return (
    <>
      {textSubscriberCountQuery.data ? (
        <StatCard
          label="Text Subscribers"
          stat={textSubscriberCountQuery.data}
        />
      ) : (
        <StatCardSkeleton />
      )}
    </>
  )
}
function EmailSubscribers() {
  const emailSubscriberCountQuery = useEmailSubscriberCount()

  return (
    <>
      {emailSubscriberCountQuery.data ? (
        <StatCard
          label="Email Subscribers"
          stat={emailSubscriberCountQuery.data}
        />
      ) : (
        <StatCardSkeleton />
      )}
    </>
  )
}
export { MessageGoals, SmsSubscribers, EmailSubscribers }

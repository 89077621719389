import {
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLoading,
} from '@ionic/react'
import { useInfiniteData } from '../../../hooks/use-infinite-data'
import { usePostsForApproval } from '../../../social-sharing/posts/queries'
import { ApprovalsListWrapper } from './approvals-list-wrapper'
import { PostApprovalItem } from './post-approval-item'

function PostApprovalList() {
  const postsQuery = usePostsForApproval()

  const [ionInfiniteScrollRef, loadMorePosts] = useInfiniteData(
    postsQuery.fetchNextPage
  )

  const flatPosts = postsQuery.data?.pages.flatMap((page) => page.data) ?? []

  if (postsQuery.data)
    return (
      <>
        <ApprovalsListWrapper>
          {flatPosts.length ? (
            <>
              {flatPosts.map((post) => (
                <PostApprovalItem key={post.objectId} post={post} />
              ))}
            </>
          ) : (
            <div className="ion-padding-horizontal">
              <p>There are no posts that need your approval.</p>
            </div>
          )}
        </ApprovalsListWrapper>

        <IonInfiniteScroll
          ref={ionInfiniteScrollRef}
          onIonInfinite={loadMorePosts}
          threshold="100px"
          disabled={!postsQuery.hasNextPage}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="loading more posts..."
          />
        </IonInfiniteScroll>
      </>
    )

  return <IonLoading isOpen message="Loading posts..." />
}

export { PostApprovalList }

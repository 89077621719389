import { z } from 'zod'

const createUserInputsSchema = z.object({
  username: z.string(),
  password: z.string(),
  email: z.string().email(),
  firstName: z.string(),
  lastName: z.string(),
})

const createUserResponseSchema = z.object({
  sessionToken: z.string(),
  objectId: z.string(),
  createdAt: z.string(),
  updatedAt: z.string().optional(),
  profileId: z.string().optional(),

  firstName: z.string().optional(),
  lastName: z.string().optional(),
  email: z.string().optional(),
  username: z.string().optional(),
})

const loginInputsSchema = z.object({
  username: z.string(),
  password: z.string(),
})

const loginResponseSchema = z.object({
  objectId: z.string(),
  createdAt: z.string(),
  sessionToken: z.string(),
  profileId: z.string().optional(),
})

const resetPasswordInputsSchema = z.object({
  email: z.string().email(),
})
const resetPasswordResponseSchema = z.object({})

const verificationEmailRequestSchema = z.object({
  email: z.string().email(),
})
const verificationEmailResponseSchema = z.object({})

export type CreateUserInputs = z.infer<typeof createUserInputsSchema>
export type LoginInputs = z.infer<typeof loginInputsSchema>
export type ResetPasswordInputs = z.infer<typeof resetPasswordInputsSchema>
export type VerificationEmailRequest = z.infer<
  typeof verificationEmailRequestSchema
>

export {
  createUserInputsSchema,
  createUserResponseSchema,
  loginInputsSchema,
  loginResponseSchema,
  resetPasswordInputsSchema,
  resetPasswordResponseSchema,
  verificationEmailRequestSchema,
  verificationEmailResponseSchema,
}

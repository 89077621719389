import * as React from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { endOfDay, parseISO, startOfDay } from 'date-fns'

import { client } from '../services/client'
import type { QueryContextFromKeys } from '../utils/react-query'

type FetchStatsResponse = {
  result: {
    count: number
    data: Array<Stat>
    page: number
    pageSize: number
  }
}

type StatsParams = {
  startDate: string
  endDate: string
  search: string
}

type Stat = {
  objectId: string
  emailCount: number
  firstName: string | null
  gmid: string
  lastName: string | null
  letterCount: number
  oneshopSalesTotal: number
  outreach1: number
  outreach7: number
  outreach30: number
  outreachCount: number
  phoneCount: number
  photo: string | null
  smsCount: number
  storeNames: string
}

type StatsFilters = {
  start: string
  end: string
  search: string
  pageSize: number
  page: number
}

type StatsQueryContexts = QueryContextFromKeys<typeof statsQueryKeys>

const statsQueryKeys = {
  all: [{ entity: 'stats' }] as const,
  stats: (filters: StatsFilters) =>
    [{ ...statsQueryKeys.all[0], ...filters }] as const,
}

async function fetchStats({
  queryKey: [{ start, end, search, page, pageSize }],
}: StatsQueryContexts['stats']) {
  const response: FetchStatsResponse = await client
    .post('functions/searchAssociateStats', {
      json: {
        startDate: start,
        endDate: end,
        search,
        page,
        pageSize,
      },
      timeout: false,
    })
    .json()

  return response.result
}

function useStats({ startDate, endDate, search }: StatsParams) {
  const [{ pageIndex, pageSize }, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10,
  })

  const queryClient = useQueryClient()

  const start = startOfDay(parseISO(startDate)).toISOString()
  const end = endOfDay(parseISO(endDate)).toISOString()

  const statsQuery = useQuery({
    queryKey: statsQueryKeys.stats({
      start,
      end,
      search,
      pageSize,
      page: pageIndex,
    }),
    queryFn: fetchStats,
    keepPreviousData: true,
  })

  const pagination = React.useMemo(
    () => ({ pageIndex, pageSize }),
    [pageIndex, pageSize]
  )

  // prefetch next page if it exists only for pagesize 5, 10, 20
  if (pageSize < 50) {
    const hasMore = statsQuery.data?.count
      ? Boolean((pageIndex + 1) * pageSize < statsQuery.data.count)
      : false

    if (hasMore) {
      queryClient.prefetchQuery(
        statsQueryKeys.stats({
          start,
          end,
          search,
          pageSize,
          page: pageIndex + 1,
        }),
        fetchStats
      )
    }
  }

  // return necessary pieces to consuming Stats page
  return {
    data: statsQuery.data?.data ?? [],
    isPreviousData: statsQuery.isPreviousData,
    isLoading: statsQuery.isLoading,
    totalCount: statsQuery.data?.count ?? 0,
    pagination,
    setPagination,
  }
}

export type { Stat }
export { useStats }

import * as React from 'react'
import { useIonModal } from '@ionic/react'
import Mustache from 'mustache'
import EmailEditor from 'react-email-editor'
import { useImmer } from 'use-immer'
import * as R from 'remeda'
import { useRefEffect } from 'react-use-ref-effect'

import { getFullName } from '../../utils/format-helpers'
import { useAccount, useUser } from '../../contexts/authContext'
import { useAssociates } from '../../hooks/queries/use-associate'
import { defaultTheme } from '../custom/unlayer-theme-defaults'
import { replaceThemeValues } from '../utils'
import { Button } from './button'
import { DisplayConditionsModal } from './display-conditions-modal'
import { SelectConditionModal } from './select-conditions-modal'
import { SelectTemplate } from './select-template'

import type { OverlayEventDetail } from '@ionic/core/components'
import type { Editor, EditorRef } from 'react-email-editor'
import type {
  DisplayConditions,
  UnlayerJSONTemplate,
  UnlayerTemplate,
} from '../unlayer.schema'
import { client } from '../../services/client'
import { useToast } from '../../contexts/toastContext'

type UnlayerDisplayCondition = Parameters<
  Editor['setDisplayConditions']
>[0][number]

type Props = {
  displayConditions?: 'show' | 'hide'
  unlayerEmail?: UnlayerJSONTemplate
  onConfirm?: ({ json, html }: UnlayerTemplate) => void
}

function UnlayerEditor({
  displayConditions = 'hide',
  unlayerEmail,
  onConfirm,
}: Props) {
  const isEmailLoadedRef = React.useRef(false)

  const account = useAccount()
  const user = useUser()

  const setToast = useToast()

  const associatesQuery = useAssociates()
  const flatAssociates = React.useMemo(
    () => associatesQuery.data?.pages.flatMap((d) => d.data) ?? [],
    [associatesQuery.data?.pages]
  )

  const [previewCondition, setPreviewCondition] = useImmer<DisplayConditions>({
    user: user.objectId,
  })

  const stylistConditions = flatAssociates.flatMap((a) => [
    {
      type: 'Sent From Stylist',
      label: getFullName(a),
      description: `Send on behalf of ${getFullName(a)}`,
      before: `{{#user_eq_${a.objectId}}}`,
      after: `{{/user_eq_${a.objectId}}}`,
    },
  ])
  const [present, dismiss] = useIonModal(SelectConditionModal, {
    onDismiss: (data: string, role: string) => dismiss(data, role),
    stylistConditions,
  })

  const hasDynamicPrivileges =
    account.account.settings.clienteling && displayConditions === 'show'

  const selectedUser = flatAssociates.find(
    (a) => a.objectId === previewCondition.user
  )
  const emailEditorRef = useRefEffect<EditorRef>(
    (unlayerEditor) => {
      unlayerEditor.editor?.registerCallback(
        'previewHtml',
        async (
          params: { html: string },
          done: ({ html }: { html: string }) => void
        ) => {
          if (previewCondition.user == null || previewCondition.user === '') {
            return
          }

          const data = R.mapToObj(flatAssociates, (a) => [
            `user_eq_${a.objectId}`,
            previewCondition.user === a.objectId,
          ])

          const result = Mustache.render(params.html, data)

          done({
            html: result,
          })
        }
      )

      unlayerEditor.editor?.registerCallback(
        'displayCondition',
        (
          data: UnlayerDisplayCondition,
          done: (data: UnlayerDisplayCondition) => void
        ) => {
          present({
            onWillPresent: (ev) => {},
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
              if (ev.detail.role === 'confirm') done(ev.detail.data)
            },
          })
        }
      )

      return () => {
        unlayerEditor.editor?.unregisterCallback('previewHtml')
        unlayerEditor.editor?.unregisterCallback('displayCondition')
      }
    },
    [flatAssociates, previewCondition.user] // Dependency array of original useEffect
  )

  function handleFinishEmail() {
    emailEditorRef.current?.editor?.exportHtml(
      (data) => {
        const { design, html } = data
        onConfirm && onConfirm({ json: design, html })
      },
      { minify: true, cleanup: true }
    )
  }

  async function handleSelectTemplate(id: number) {
    try {
      const response: { result: { design: any } } = await client
        .post('functions/unlayerTemplate', { json: { id } })
        .json()

      const template = replaceThemeValues(response.result.design, {
        defaultTheme,
        customTheme: account.account.styleSettings,
      })

      emailEditorRef.current?.editor?.loadDesign(template)
    } catch (error) {
      setToast('There was a problem loading the template.')
    }
  }

  function onReady(unlayer: Editor) {
    if (hasDynamicPrivileges) unlayer.setDisplayConditions(stylistConditions)

    unlayer.setMergeTags({
      firstName: {
        name: 'First Name',
        value: '{{first_name}}',
      },
      lastName: {
        name: 'Last Name',
        value: '{{last_name}}',
      },
      lifetimeLoyaltyPoints: {
        name: 'Lifetime Loyalty Points',
        value: '{{lifetime_loyalty_points}}',
      },
      activeLoyaltyPoints: {
        name: 'Active Loyalty Points',
        value: '{{active_loyalty_points}}',
      },
      activeLoyaltyVisits: {
        name: 'Active Loyalty Visits',
        value: '{{active_loyalty_visits}}',
      },
      activeLoyaltyRewards: {
        name: 'Active Loyalty Rewards',
        value: '{{active_loyalty_rewards}}',
      },
    })

    unlayer.setAppearance({
      features: { preview: true },
      theme: 'dark',
      panels: {
        tools: {
          collapsible: true,
          dock: 'right',
          tabs: {
            body: {
              visible: true,
            },
          },
        },
      },
    })

    if (!unlayerEmail) {
      // TODO: workaround to set bg of email
      unlayer?.setBodyValues({
        backgroundColor: '#fff',
      })
    }

    if (unlayerEmail && !isEmailLoadedRef.current) {
      unlayer?.loadDesign(unlayerEmail)
    }
  }

  if (associatesQuery.data)
    return (
      <>
        <div className="relative flex h-full flex-col">
          <div className="flex max-h-10 flex-1 items-center justify-between gap-x-2.5 bg-[#293039]">
            <div className="flex h-full items-center gap-x-2">
              <SelectTemplate onClick={handleSelectTemplate} />
              <Button
                variant="secondary"
                onClick={() => emailEditorRef.current?.editor?.showPreview({})}
              >
                Show Preview
              </Button>
              {hasDynamicPrivileges ? (
                <>
                  <DisplayConditionsModal
                    filters={previewCondition}
                    onCancel={() => {}}
                    onSearch={(e) => setPreviewCondition(e)}
                  />
                  <p className="text-xl">
                    {selectedUser ? getFullName(selectedUser) : null}
                  </p>
                </>
              ) : null}
            </div>

            <div className="h-full space-x-2.5">
              <Button onClick={handleFinishEmail}>Save</Button>
            </div>
          </div>

          <React.StrictMode>
            <EmailEditor
              options={{
                user: {
                  id: account.account.objectId,
                },
                projectId: 97081,
                features: {
                  smartMergeTags: true,
                  // preview: false
                },
              }}
              ref={emailEditorRef}
              onReady={onReady}
            />
          </React.StrictMode>
        </div>
      </>
    )

  return <p>Loading</p>
}

export type { UnlayerDisplayCondition }
export default UnlayerEditor

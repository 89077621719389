import * as React from 'react'
import { IonRow } from '@ionic/react'
import { format, parseISO } from 'date-fns'

import { cn } from '../../utils/cn'
import { useAccount } from '../../contexts/authContext'
import { Card, CardContent } from '../../components/ui/card'
import { useHasRole } from '../../auth/utils'
import { ClientTags } from '../../components/client/client-tags'
import { AdjustCard } from './adjust-card'
import { Bonus } from './bonus'
import { DeleteAdjustment } from './delete-adjustment'
import { EditAdjustment } from './edit-adjustment'
import { LogPoints } from './log-points'
import { LoyaltyButton } from './loyalty-button'
import { LoyaltyDetailHeading } from './loyalty-detail-heading'
import { LoyaltyDetailInfo } from './loyalty-detail-info'
import { NonIntegratedSaleForm } from './non-integrated-sale-form'
import { Reward } from './reward'
import { SixBoxCard } from './six-box-card'

import type { IonCheckboxCustomEvent } from '@ionic/core'
import type { CheckboxChangeEventDetail } from '@ionic/react'
import type {
  ApplyLoyaltyReward,
  LoyaltyData,
  RewardSet,
} from '../loyalty.schema'
import type { CustomRules } from '../queries'

type Props = {
  data: LoyaltyData & CustomRules
  disabled: boolean
  onApplyRewards: (
    rewards: Array<ApplyLoyaltyReward>,
    options?: { redeemEarly: boolean }
  ) => void
  onRemoveRewards?: () => void
}
type LoyaltyContext = {
  clientId: string
  loyaltyRuleId?: string | null
  loyaltyType: LoyaltyData['loyaltyType']
  rewardRules: CustomRules['rewardRules']
}

const loyaltyContext = React.createContext<LoyaltyContext | null>(null)

function useLoyalty() {
  const context = React.useContext(loyaltyContext)
  if (!context) {
    throw new Error(
      'useLoyalty has to be used within <loyaltyContext.Provider>'
    )
  }

  return context
}

function LoyaltyRedemptionDetail({
  data,
  disabled,
  onApplyRewards,
  onRemoveRewards,
}: Props) {
  const [selectedRewards, setSelectedRewards] = React.useState<
    Array<RewardSet>
  >([])

  const account = useAccount()
  const isAdmin = useHasRole('admin')

  const isNonIntegrated = account.account.settings.nonIntegrated
  const isDiscountApplied =
    data.loyaltyType !== 'manual' ? data.rewardApplied : false

  const contextValue = {
    clientId: data.client.objectId,
    loyaltyType: data.loyaltyType,
    rewardRules: data.rewardRules,
  }

  function redeemReward(
    reward: ApplyLoyaltyReward,
    options?: { redeemEarly: boolean }
  ) {
    onApplyRewards([reward], options)
    setSelectedRewards([])
  }

  function redeemSelectedRewards() {
    onApplyRewards(selectedRewards)
    setSelectedRewards([])
  }

  function redeemAllRewards() {
    const filteredRewards = data.loyaltyRules.map((lr) =>
      lr.rewards.filter((r) => r.rewardsAvailable)
    )

    onApplyRewards(filteredRewards.flatMap((reward) => reward))
    setSelectedRewards([])
  }

  function handleSelectReward(
    event: IonCheckboxCustomEvent<CheckboxChangeEventDetail<RewardSet>>
  ) {
    const { value, checked } = event.detail

    if (checked) {
      // Add checked item into checkList
      setSelectedRewards((prev) => [...prev, value])
    } else {
      // Remove unchecked item from checkList
      const filteredList = selectedRewards.filter((item) => item !== value)
      setSelectedRewards(filteredList)
    }
  }

  return (
    <div
      className={cn('flex w-full flex-col gap-x-5 sm:flex-row', {
        'justify-center': !isNonIntegrated,
        'px-4': isNonIntegrated,
      })}
    >
      {isNonIntegrated ? (
        <div className="pt-5 [flex-basis:50%]">
          <h2>Enter Sale</h2>

          <NonIntegratedSaleForm />
        </div>
      ) : null}

      <div className={cn('space-y-8', { 'flex-grow': isNonIntegrated })}>
        <div className="flex flex-col items-center justify-center gap-3">
          <LoyaltyDetailHeading>{data.client.displayName}</LoyaltyDetailHeading>
          <ClientTags clientId={data.client.objectId} mode="dark" />
          <LoyaltyDetailInfo
            lifetimeLoyaltyVisits={data.loyaltyVisitsLifetime}
            pointsBalance={data.pointsBalance}
            visitsUntilReward={data.visitsUntilReward}
          />

          {/* LOG POINTS */}
          {account.isAdmin ? (
            <div>
              <loyaltyContext.Provider value={contextValue}>
                <LogPoints />
              </loyaltyContext.Provider>
            </div>
          ) : null}
        </div>
        <div className="flex items-center justify-center gap-6">
          {onRemoveRewards ? (
            <div className="flex-0">
              <LoyaltyButton
                size="lg"
                disabled={disabled || !isDiscountApplied}
                onClick={onRemoveRewards}
                fill="solid"
                color="secondary"
              >
                Remove Rewards
              </LoyaltyButton>
            </div>
          ) : null}
          <div className="flex-0">
            <LoyaltyButton
              size="lg"
              disabled={
                disabled ||
                !Boolean(selectedRewards.length) ||
                isDiscountApplied
              }
              onClick={redeemSelectedRewards}
            >
              Redeem Selected
            </LoyaltyButton>
          </div>
          <div className="flex-0">
            <LoyaltyButton
              size="lg"
              disabled={disabled || !data.rewardsAvailable || isDiscountApplied}
              onClick={redeemAllRewards}
            >
              Redeem All
            </LoyaltyButton>
          </div>
        </div>

        <section className="ion-padding-horizontal mx-auto w-full max-w-lg space-y-2">
          {data.bonusRules.length ? (
            <>
              <h2 className="text-xl">Bonuses</h2>
              <div className="space-y-2">
                {data.bonusRules.map((bonusRule) => (
                  <React.Fragment key={bonusRule.loyaltyBonusRule.objectId}>
                    {bonusRule.rewards.map((bonus) => (
                      <Card>
                        <CardContent>
                          <Bonus
                            bonus={bonus}
                            disabled={disabled}
                            isDiscountApplied={isDiscountApplied}
                            onRedeem={redeemReward}
                            onSelect={handleSelectReward}
                          />
                        </CardContent>
                      </Card>
                    ))}
                  </React.Fragment>
                ))}
              </div>
            </>
          ) : null}
          <h2 className="text-xl">Rewards</h2>
          <div className="space-y-2">
            {data.rewardRules.map((rewardRule) => (
              <React.Fragment key={rewardRule.loyaltyRule.objectId}>
                {rewardRule.rewards.map((reward, index) => (
                  <div key={index}>
                    <Card>
                      <CardContent>
                        <Reward
                          disabled={disabled}
                          isDiscountApplied={isDiscountApplied}
                          onRedeem={redeemReward}
                          onSelect={handleSelectReward}
                          reward={reward}
                          type={data.loyaltyType}
                        />
                        {rewardRule.loyaltyRule.settings.visits > 0 ? (
                          <div className="space-y-2">
                            <loyaltyContext.Provider value={contextValue}>
                              <SixBoxCard
                                isAdmin={isAdmin}
                                reward={reward}
                                totalBoxes={
                                  rewardRule.loyaltyRule.settings.visits
                                }
                              />
                            </loyaltyContext.Provider>
                            {/* ADJUSTMENTS */}
                            {account.isAdmin && index === 0 ? (
                              <IonRow className="justify-end">
                                <loyaltyContext.Provider
                                  value={{
                                    ...contextValue,
                                    loyaltyRuleId: reward.loyaltyRuleId,
                                  }}
                                >
                                  <AdjustCard />
                                </loyaltyContext.Provider>
                              </IonRow>
                            ) : null}
                            {reward.loyaltyAdjustments.length ? (
                              <>
                                <h2 className="text-ion-color-primary text-xl font-semibold">
                                  Adjustments
                                </h2>
                                <div className="space-y-5">
                                  {reward.loyaltyAdjustments.map(
                                    (adjustment) => (
                                      <IonRow key={adjustment.objectId}>
                                        <div className="flex w-full flex-col gap-y-1">
                                          {/* INFO */}
                                          <div>
                                            <span className="text-xl">
                                              {adjustment.value} pts.
                                            </span>
                                            {adjustment.date ? (
                                              <span>
                                                {' '}
                                                on{' '}
                                                {format(
                                                  parseISO(adjustment.date),
                                                  'MM/dd/yy'
                                                )}
                                              </span>
                                            ) : null}{' '}
                                            by{' '}
                                            <span>
                                              {adjustment.user.displayName}
                                            </span>
                                          </div>
                                          {/* EDIT ADJUSTMENT BUTTONS */}
                                          {account.isAdmin ? (
                                            <div className="flex gap-x-3">
                                              {adjustment.objectId ? (
                                                <loyaltyContext.Provider
                                                  value={contextValue}
                                                >
                                                  <EditAdjustment
                                                    objectId={
                                                      adjustment.objectId
                                                    }
                                                    value={adjustment.value}
                                                  />
                                                  <DeleteAdjustment
                                                    clientId={
                                                      data.client.objectId
                                                    }
                                                    objectId={
                                                      adjustment.objectId
                                                    }
                                                  />
                                                </loyaltyContext.Provider>
                                              ) : null}
                                            </div>
                                          ) : null}
                                        </div>
                                      </IonRow>
                                    )
                                  )}
                                </div>
                              </>
                            ) : null}
                          </div>
                        ) : null}
                      </CardContent>
                    </Card>
                  </div>
                ))}
              </React.Fragment>
            ))}
          </div>
        </section>
      </div>
    </div>
  )
}

export { LoyaltyRedemptionDetail, useLoyalty }

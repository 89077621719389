import { useEffect, useState } from 'react'
import { useIonRouter } from '@ionic/react'
import * as R from 'remeda'
import { useTimeoutWhen } from 'rooks'

import { usePushToken } from '../utils/use-push-token'
import { useToast } from '../contexts/toastContext'
import { useCheckInMethod, useCheckInValues } from '../check-in/check-in-store'
import { useClientLoyalty } from '../loyalty/queries'
import { useCustomerOptInClientId } from '../marketing/customer-opt-in/utils'
import { useCheckIn, useUpdateClientStation } from './mutations'

function hasAllValues<T extends Record<string, any>>(
  obj: T,
  keys: Array<keyof T>
) {
  const requiredKeys = keys

  for (const key of requiredKeys) {
    const value = obj[key]

    if (R.isEmpty(value) || R.isNullish(value)) {
      return false
    }
  }

  return true
}

function useCheckInRedirect() {
  const [timeout, setTimeout] = useState(false)
  const [clientStation, setClientStation] = useState<string | null>(null)

  const { data: loyalty } = useClientLoyalty()

  // useParams returns null due to redirect - https://github.com/ionic-team/ionic-framework/issues/28984
  const clientId = useCustomerOptInClientId()
  const router = useIonRouter()
  const setToast = useToast()

  const { data: deviceToken } = usePushToken()

  const checkInMethod = useCheckInMethod()
  const { mutate: checkIn, abortCheckIn } = useCheckIn(clientId)
  const { mutate: updateClientStation } = useUpdateClientStation()

  useTimeoutWhen(
    () => {
      if (!clientStation)
        return setToast({
          message: 'This station is not properly set up.',
          color: 'danger',
        })

      updateClientStation(
        { objectId: clientStation, status: 'completed' },
        {
          onSuccess: () => {
            router.push('/check-in/paired/phone-check-in', 'none', 'replace')
          },
        }
      )
    },
    5000,
    timeout
  )

  useEffect(() => {
    // do not run if the token has not finished fetching
    // do not run if loyalty page hasn't finished fetching
    // TODO: possibly refactor this check
    if (!deviceToken || !loyalty) return

    async function init() {
      checkIn(checkInMethod, {
        onSuccess: (data) => {
          setClientStation(data.stationClient.objectId)
          setTimeout(true)
        },
      })
    }

    init()
  }, [
    checkIn,
    checkInMethod?.email,
    checkInMethod?.phone,
    deviceToken,
    loyalty,
  ])

  return {
    clientStation,
    resetCheckIn: () => {
      abortCheckIn()

      router.push('/check-in/paired/phone-check-in', 'none', 'replace')
    },
  }
}

export { hasAllValues, useCheckInRedirect }

import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonLoading,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
} from '@ionic/react'
import { chevronForward } from 'ionicons/icons'
import { ErrorBoundary } from 'react-error-boundary'

import { useIonSegment } from '../../utils/ionic'
import { useAccount } from '../../contexts/authContext'
import {
  useAccountSocialConnections,
  useUserSocialConnections,
} from '../../ayrshare/queries'
import AyrshareConnectionItem from '../../ayrshare/components/ayrshare-connection-item'
import AyrshareUninitConnections from '../../ayrshare/components/ayrshare-uninit-connections'
import ErrorFallback from '../../components/error-fallback'
import Header from '../../components/header'

const segments = ['internal', 'mine'] as const

function ConnectionsPage() {
  const [currentSegment, handleSegmentChange] = useIonSegment(
    segments,
    'internal'
  )

  const account = useAccount()

  // HACK: IN CASE OF EMPTY ACCOUNT OBJECT (ACCOUNT FETCHED AFTER LOGIN)
  if (!Object.keys(account).length) return null

  return (
    <IonPage>
      <IonHeader>
        <Header title="Social Setup" btn={null} showMenu />
      </IonHeader>
      <IonContent>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <>
            {account.roleSocial?.role === 'admin' ? (
              <>
                <IonSegment
                  onIonChange={handleSegmentChange}
                  value={currentSegment}
                >
                  {segments.map((segment) => (
                    <IonSegmentButton
                      key={segment}
                      className="capitalize"
                      value={segment}
                    >
                      {segment}
                    </IonSegmentButton>
                  ))}
                </IonSegment>

                {currentSegment === 'internal' ? <AccountConnections /> : null}
                {currentSegment === 'mine' ? <MyConnections /> : null}
              </>
            ) : (
              <MyConnections />
            )}
          </>
        </ErrorBoundary>
      </IonContent>
    </IonPage>
  )
}

function AccountConnections() {
  const accountSocialConnectionsQuery = useAccountSocialConnections()

  if (accountSocialConnectionsQuery.data)
    return (
      <div className="ion-padding-top ion-padding-horizontal">
        {/* Account Info */}
        <IonRow className="items-center justify-between">
          <p className="text-xl">
            {accountSocialConnectionsQuery.data.companyName}
          </p>
          <IonButton
            size="small"
            fill="clear"
            routerLink="/account/connections/groups"
          >
            <IonIcon color="secondary" icon={chevronForward} slot="icon-only" />
          </IonButton>
        </IonRow>

        {accountSocialConnectionsQuery.data.ayrshares.length ? (
          <>
            {accountSocialConnectionsQuery.data.ayrshares.map((ayrshare) => (
              <AyrshareConnectionItem
                key={ayrshare.refId}
                id={accountSocialConnectionsQuery.data.objectId}
                ayrshare={ayrshare}
              />
            ))}
          </>
        ) : (
          <AyrshareUninitConnections
            type="account"
            id={accountSocialConnectionsQuery.data.objectId}
          />
        )}
      </div>
    )

  return <IonLoading isOpen message="Loading Account Social Connections..." />
}

function MyConnections() {
  const userSocialConnectionsQuery = useUserSocialConnections()

  const businessConnections =
    userSocialConnectionsQuery.data?.ayrshares.filter(
      (ayrshare) => ayrshare.type === 'business'
    ) ?? []

  return (
    <>
      {userSocialConnectionsQuery.data ? (
        <div className="ion-padding-top ion-padding-horizontal space-y-3">
          <p className="text-xl">
            {userSocialConnectionsQuery.data.displayName}
          </p>
          <div className="space-y-1">
            <h2>Business Channels</h2>
            {businessConnections.length > 0 ? (
              <>
                {businessConnections.map((ayrshare) => (
                  <AyrshareConnectionItem
                    key={ayrshare.refId}
                    id={userSocialConnectionsQuery.data.objectId}
                    ayrshare={ayrshare}
                  />
                ))}
              </>
            ) : (
              <AyrshareUninitConnections
                type="user"
                id={userSocialConnectionsQuery.data.objectId}
              />
            )}
          </div>
        </div>
      ) : (
        <IonLoading isOpen message="Loading Your Social Connections..." />
      )}
    </>
  )
}

export default ConnectionsPage

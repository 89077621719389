import * as React from 'react'

import { formatName } from '../utils/format-helpers'
import { useStylistGroup, useUser } from '../contexts/authContext'

export function useReplacePlaceholders() {
  const user = useUser()
  const group = useStylistGroup()

  const replacePlaceholders = React.useCallback(
    (message: string) => {
      message = message
        .replaceAll('<Stylist>', formatName(user.firstName))
        .replaceAll('<StylistId>', user.objectId)

      if (group) {
        message = message
          .replaceAll('<Company>', group.companyName ?? '')
          .replaceAll('<Store>', group.name ?? '')
      }

      return message
    },
    [group, user.firstName, user.objectId]
  )

  return { replacePlaceholders }
}

import { Card, CardContent, CardHeader, CardTitle } from '../../ui/card'

import type { CallList } from '../../../clienteling/outreach/outreach.schema'

function OutreachCard({ outreach }: { outreach: CallList }) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>{outreach.name}</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex h-full flex-1 flex-col">
          <p className="mt-auto pt-2 text-center text-sm leading-none">
            {outreach.stats.clientCount} Client
            {outreach.stats.clientCount === 1 ? '' : 's'}
          </p>
        </div>
      </CardContent>
    </Card>
  )
}

export { OutreachCard }

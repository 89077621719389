import {
  IonItem,
  IonLabel,
  IonCheckbox,
  IonInput,
  IonSelect,
  IonSelectOption,
  SelectChangeEventDetail,
  IonTextarea,
  IonButton,
  IonLoading,
} from '@ionic/react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { ErrorMessage } from '@hookform/error-message'
import { subYears, addYears } from 'date-fns'
import { dequal } from 'dequal/lite'

import { getFullName } from '../../utils/format-helpers'
import { useAccount, useUser } from '../../contexts/authContext'
import { useAssociates } from '../../hooks/queries/use-associate'
import { useFlatAccountGroups } from '../../hooks/queries/use-groups'
import {
  useCreateTask,
  alterationInputSchema,
} from '../../hooks/tasks/mutations'
import AddPhotos from '../../components/AddPhotos'
import SearchBar from '../../components/SearchBar'
import { StyledError } from '../../components/ui/form/errors'
import DatePicker from '../../components/ui/ionic/date-picker'
import { alterationSteps } from '../constants'
import { AlterationItemInputs } from './alteration-item-input'

import type { CreateAlterationInput } from '../../hooks/tasks/mutations'
import type { Client } from '../../hooks/clients/queries'
import { useHeartlandParams } from '../../integrations/heartland/utils'

type Props = {
  client: Pick<Client, 'objectId' | 'firstName' | 'lastName'> | null
  onSuccess?: () => void
  salespersonId?: string
}
const today = new Date()

function CreateAlterationForm({ client, onSuccess, salespersonId }: Props) {
  const { ticketId } = useHeartlandParams()

  const user = useUser()
  const account = useAccount()

  const associates = useAssociates()
  const groups = useFlatAccountGroups()

  const { mutate, isLoading } = useCreateTask({ onSuccess })

  const flatAssociates = associates.data?.pages
    .flatMap((d) => d.data)
    .filter((u) => !u.deactivated)
  const currentUserGroup = account.groups[0].groupId

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<CreateAlterationInput>({
    defaultValues: {
      ticket: ticketId ?? '',
      clientId: client?.objectId ?? '',
      note: '',
      photos: [],
      due: { iso: '' },
      step: 'Ready to be Altered',
      groupId: currentUserGroup,
      userSaleId: salespersonId ?? user.objectId,
      usersIds: [],
      isPublic: true,
      className: 'Alteration',
    },
    resolver: zodResolver(alterationInputSchema),
  })
  const alterationItems = useFieldArray({ control, name: 'items' })

  function createAlteration(data: CreateAlterationInput) {
    mutate(data)
  }

  return (
    <>
      <form onSubmit={handleSubmit(createAlteration)}>
        <Controller
          name="clientId"
          control={control}
          render={({ field: { value, onChange } }) => (
            <SearchBar
              clientCallback={() => {}}
              clientIdCallback={onChange}
              clearOnSelect={false}
              initialText={
                client ? `${client.firstName} ${client.lastName}` : null
              }
            />
          )}
        />
        <div className="px-6">
          <ErrorMessage name="clientId" as={StyledError} errors={errors} />
        </div>

        <IonItem color="secondary">
          <IonLabel>Make Private</IonLabel>
          <Controller
            name="isPublic"
            control={control}
            render={({ field: { value, onChange } }) => (
              <IonCheckbox
                value={!value}
                onIonChange={(e) => {
                  console.log(e.detail)
                  onChange(!e.detail.checked)
                }}
              />
            )}
          />
        </IonItem>

        <IonItem color="secondary">
          <IonLabel>Ticket #</IonLabel>
          <Controller
            name="ticket"
            control={control}
            render={({ field: { value, onChange } }) => (
              <IonInput
                value={value}
                onIonChange={(e) => onChange(e.detail.value)}
              />
            )}
          />
        </IonItem>

        <IonItem color="secondary">
          <IonLabel>Sales Person</IonLabel>
          <Controller
            name="userSaleId"
            control={control}
            render={({ field: { value, onChange } }) => (
              <IonSelect
                color="secondary"
                value={value}
                onIonChange={(e) => onChange(e.detail.value)}
              >
                {flatAssociates?.map((associate) => (
                  <IonSelectOption
                    key={associate.objectId}
                    value={associate.objectId}
                  >
                    {getFullName(associate)}
                  </IonSelectOption>
                ))}
              </IonSelect>
            )}
          />
        </IonItem>

        <IonItem color="secondary">
          <IonLabel>Team Members</IonLabel>
          <Controller
            name="usersIds"
            control={control}
            render={({ field: { value, onChange } }) => (
              <IonSelect
                key={value.toString()}
                color="secondary"
                multiple
                value={value}
                onIonChange={(
                  e: CustomEvent<SelectChangeEventDetail<Array<string>>>
                ) => {
                  // EARLY RETURN IF VALUE WASN'T CHANGED
                  if (
                    dequal(
                      JSON.stringify(e.detail.value),
                      JSON.stringify(value)
                    )
                  ) {
                    return
                  }

                  onChange(e.detail.value)
                }}
              >
                {flatAssociates?.map((associate) => (
                  <IonSelectOption
                    key={associate.objectId}
                    value={associate.objectId}
                  >
                    {getFullName(associate)}
                  </IonSelectOption>
                ))}
              </IonSelect>
            )}
          />
        </IonItem>

        <Controller
          control={control}
          name="due.iso"
          render={({ field: { value, onChange } }) => (
            <DatePicker
              label="Estimated Due Date"
              fallback="No Due Date set."
              value={value}
              min={subYears(today, 1)}
              max={addYears(today, 1)}
              onChange={(e) => {
                onChange(e.detail.value)
              }}
            />
          )}
        />

        <IonItem color="secondary">
          <IonLabel>Current Location</IonLabel>
          <Controller
            name="groupId"
            control={control}
            render={({ field: { value, onChange } }) => (
              <IonSelect
                color="secondary"
                value={value}
                onIonChange={(e) => {
                  // HACK some reason revalidation causes reset to empty string
                  if (!e.detail.value) return
                  onChange(e.detail.value)
                }}
              >
                {groups.data?.map((group) => (
                  <IonSelectOption key={group.objectId} value={group.objectId}>
                    {group.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            )}
          />
        </IonItem>

        <IonItem color="secondary">
          <IonLabel>Current Step</IonLabel>
          <Controller
            name="step"
            control={control}
            render={({ field: { value, onChange } }) => (
              <IonSelect
                color="secondary"
                value={value}
                onIonChange={(e) => onChange(e.detail.value)}
              >
                {alterationSteps.map((step) => (
                  <IonSelectOption key={step} value={step}>
                    {step}
                  </IonSelectOption>
                ))}
              </IonSelect>
            )}
          />
        </IonItem>

        <IonItem color="secondary">
          <IonLabel position="floating">Note</IonLabel>
          <Controller
            control={control}
            name="note"
            render={({ field: { value, onChange } }) => (
              <IonTextarea
                autoGrow
                autocapitalize="on"
                rows={5}
                value={value}
                onIonChange={(e) => {
                  onChange(e.detail.value)
                }}
              />
            )}
          />
        </IonItem>
        <Controller
          name="photos"
          control={control}
          render={({ field: { value, onChange } }) => (
            <AddPhotos photos={value} onPhotosUpdate={onChange} showPhotos />
          )}
        />

        <div className="space-y-5">
          {alterationItems.fields.map((field, index) => (
            <AlterationItemInputs
              key={field.id}
              control={control}
              index={index}
              groups={groups}
            />
          ))}
        </div>

        <div className="flex justify-center gap-x-3 py-4">
          <IonButton
            onClick={() =>
              alterationItems.append({
                groupId: currentUserGroup,
                step: 'Ready to be Altered',
                title: '',
              })
            }
          >
            Add Item
          </IonButton>
          <IonButton type="submit" color="yellow">
            Save
          </IonButton>
        </div>
      </form>
      <IonLoading isOpen={isLoading} message="Creating Alteration..." />
    </>
  )
}

export { CreateAlterationForm }

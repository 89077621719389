import { IonPage, IonContent, IonHeader, IonLoading } from '@ionic/react'

import { useMyProfile } from '../../hooks/account/queries'
import Header from '../../components/header'
import Profile from '../../components/account/profile'
import ProfileContent from '../../components/account/profile-content'

export default function ProfilePage() {
  const profileQuery = useMyProfile()

  return (
    <IonPage>
      <IonHeader>
        <Header showMenu title="Profile" btn={null} />
      </IonHeader>
      {profileQuery.data ? (
        <IonContent>
          <div className="ion-padding mx-auto max-w-4xl">
            <Profile profile={profileQuery.data} />
            <ProfileContent profileId={profileQuery.data.profileId} />
          </div>
        </IonContent>
      ) : (
        <IonLoading
          isOpen={profileQuery.isLoading}
          message="Loading your profile..."
        />
      )}
    </IonPage>
  )
}

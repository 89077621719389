import { IonIcon } from '@ionic/react'
import { camera } from 'ionicons/icons'

import { cn } from '../../utils/cn'
import { usePhoto } from '../../hooks/use-photo'

import type { PhotoData } from '../../hooks/use-photo'

type Props = {
  disabled?: boolean
  onTakePhoto: (data: PhotoData) => void
  mode?: 'standalone' | 'contained'
}

function TakePhoto({ disabled, onTakePhoto, mode = 'contained' }: Props) {
  const { takePhoto } = usePhoto()

  return (
    <button
      type="button"
      className="bg-ion-color-secondary size-8 ml-1 flex items-center justify-center rounded-3xl disabled:opacity-50"
      onClick={() => takePhoto(onTakePhoto)}
      disabled={disabled}
    >
      <IonIcon
        className={cn(mode === 'standalone' ? 'size-full' : 'size-6')}
        color="primary"
        icon={camera}
      />
    </button>
  )
}

export default TakePhoto

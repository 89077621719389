import {
  IonButton,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  useIonToast,
} from '@ionic/react'
import { useParams } from 'react-router-dom'
import { addCircleOutline, close } from 'ionicons/icons'
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form'

import { replacePlaceholders } from '../../../utils/format-helpers'
import {
  useAccount,
  useStylistGroup,
  useUser,
} from '../../../contexts/authContext'
import { useAssociates } from '../../../hooks/queries/use-associate'
import AdminHeader from '../../../components/header/admin-header'
import { TextInput } from '../../../components/ui/form/input'
import { createAutomationSchema } from '../../../automations/automations-form.schema'
import { TriggerStep } from '../../../automations/components/trigger-step'
import { ConditionsModal } from '../../../automations/components/conditions-modal'
import { sendFrom, triggers } from '../../../automations/constants'
import { useCreateAutomation } from '../../../automations/mutations'
import {
  automationTemplates,
  defaultStep,
} from '../../../automations/templates'
import { useAutomationFormRefs } from '../../../automations/utils'

import type { SelectChangeEventDetail } from '@ionic/react'
import type {
  CreateAutomationInput,
  FromUserRule,
} from '../../../automations/automations-form.schema'

interface SelectCustomEvent extends CustomEvent {
  detail: SelectChangeEventDetail<FromUserRule>
  target: HTMLIonSelectElement
}

function isKeyOfTemplate(s: string): s is keyof typeof automationTemplates {
  return s in automationTemplates
}

function CreateAutomationPage() {
  const { templateId } = useParams<{ templateId: string }>()

  const stylistGroup = useStylistGroup()
  const account = useAccount()
  const user = useUser()
  const userHasCustomEmail =
    user.hasSendGrid && account.sendgridDomains.length > 0

  const template = isKeyOfTemplate(templateId)
    ? automationTemplates[templateId]
    : null

  const associatesQuery = useAssociates()
  const flatAssociates =
    associatesQuery.data?.pages
      .flatMap((d) => d.data)
      .filter((associate) =>
        userHasCustomEmail ? true : associate.objectId === user.objectId
      ) ?? []

  const [presentToast, dismissToast] = useIonToast()
  function setToast(message: string) {
    presentToast({
      message,
      color: 'danger',
      duration: 8000,
      position: 'middle',
      buttons: [{ icon: close, role: 'cancel' }],
    })
  }

  const { control, ...formMethods } = useForm<CreateAutomationInput>({
    defaultValues: {
      ...template,
      steps: template?.steps.map((step) => ({
        ...step,
        smsMessage: step.smsMessage
          ? replacePlaceholders(step.smsMessage, {
              '<Company>': stylistGroup.companyName,
            })
          : null,
        emailSubject: step.emailSubject
          ? replacePlaceholders(step.emailSubject, {
              '<Company>': stylistGroup.companyName,
            })
          : null,
        emailMessage: step.emailMessage
          ? replacePlaceholders(step.emailMessage, {
              '<Company>': stylistGroup.companyName,
            })
          : null,
      })),
    },
  })
  const watchFromUserRule = useWatch({ control, name: 'fromUserRule' })
  const stepFields = useFieldArray({ control, name: 'steps' })

  const createAutomation = useCreateAutomation()

  const refs = useAutomationFormRefs()

  async function validate(values: Partial<CreateAutomationInput>) {
    await dismissToast()

    const result = createAutomationSchema.safeParse(values)

    if (!result.success) {
      return setToast(result.error.errors.map((e) => e.message).join('<br/>'))
    }

    // quick check b4 saving draft since backend is more strict about fromUser field existing
    if (result.data.fromUserRule === 'fromUser' && !result.data.fromUserId)
      return setToast('Please select a stylist to send the messages from.')

    return result
  }

  async function handleSubmit(values: CreateAutomationInput) {
    const validatedValues = await validate(values)
    if (!validatedValues) return

    createAutomation.mutate({ ...values, state: 'active' })
  }

  async function saveAsDraft() {
    const formValues = formMethods.getValues()

    const validatedValues = await validate(formValues)
    if (!validatedValues) return

    createAutomation.mutate({ ...validatedValues.data, state: 'draft' })
  }

  return (
    <IonPage>
      <IonHeader>
        <AdminHeader backRef="/admin/automations" title="Create Automation" />
      </IonHeader>
      <IonContent className="ion-padding">
        {template ? (
          <FormProvider control={control} {...formMethods}>
            <form className="space-y-5">
              {/* Save Options */}
              <div className="flex justify-center gap-4">
                <IonButton
                  type="button"
                  color="yellow"
                  fill="outline"
                  size="small"
                  disabled={createAutomation.isLoading}
                  onClick={saveAsDraft}
                >
                  Save as Draft
                </IonButton>
                <IonButton
                  type="button"
                  color="yellow"
                  size="small"
                  disabled={createAutomation.isLoading}
                  onClick={() => handleSubmit(formMethods.getValues())}
                >
                  Publish
                </IonButton>
              </div>
              {/* General Info */}
              <fieldset className="space-y-3">
                <TextInput
                  name="title"
                  control={control}
                  label="Title"
                  disabled={createAutomation.isLoading}
                />

                <TextInput
                  name="description"
                  control={control}
                  label="Description"
                  disabled={createAutomation.isLoading}
                />

                {/* Send From */}
                <div className="border-ion-color-secondary mt-3 rounded-lg border px-3 py-2">
                  <h3>Send From</h3>
                  <IonRow>
                    <IonCol size="6">
                      <Controller
                        name="fromUserRule"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <IonSelect
                            disabled={createAutomation.isLoading}
                            className="bg-ion-color-step-100 rounded"
                            value={value}
                            // w/o this the form value was missing fields when switching this select w/o selecting id
                            // onIonBlur={recomputeFormValue}
                            onIonChange={(e: SelectCustomEvent) => {
                              onChange(e.detail.value)
                              if (e.detail.value !== 'fromUser') {
                                formMethods.setValue('fromUserId', null, {
                                  shouldDirty: true,
                                })
                                formMethods.setValue('fromEmailName', null, {
                                  shouldDirty: true,
                                })
                                formMethods.setValue('fromEmailDomain', null, {
                                  shouldDirty: true,
                                })
                                formMethods.setValue('fromName', null, {
                                  shouldDirty: true,
                                })
                              } else {
                                formMethods.setValue(
                                  'fromUserId',
                                  user.objectId,
                                  {
                                    shouldDirty: true,
                                  }
                                )
                                const associate = flatAssociates.find(
                                  (a) => a.objectId === user.objectId
                                )
                                if (!associate) return
                                formMethods.setValue(
                                  'fromName',
                                  associate.displayName
                                )
                                // HACK: Maybe this check should live elsewhere.
                                formMethods.setValue(
                                  'fromEmailDomain',
                                  account.sendgridDomains[0] ?? null
                                )
                                formMethods.setValue(
                                  'fromEmailName',
                                  associate.twilioProxyEmail?.split('@')[0] ??
                                    null
                                )
                              }
                            }}
                          >
                            {sendFrom.map((from) => (
                              <IonSelectOption
                                key={from.value}
                                value={from.value}
                              >
                                {from.label}
                              </IonSelectOption>
                            ))}
                          </IonSelect>
                        )}
                      />
                    </IonCol>

                    {watchFromUserRule === 'fromUser' ? (
                      <IonCol size="6">
                        <Controller
                          name="fromUserId"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <IonSelect
                              style={{ '--placeholder-color': 'text-white' }}
                              disabled={createAutomation.isLoading}
                              className="bg-ion-color-step-100 rounded"
                              value={value}
                              onIonChange={(e) => {
                                const associate = flatAssociates.find(
                                  (a) => a.objectId === e.detail.value
                                )
                                if (!associate) return
                                formMethods.setValue(
                                  'fromName',
                                  associate.displayName
                                )
                                formMethods.setValue(
                                  'fromEmailDomain',
                                  account.sendgridDomains[0] ?? null
                                )
                                formMethods.setValue(
                                  'fromEmailName',
                                  associate.twilioProxyEmail?.split('@')[0] ??
                                    null
                                )
                                onChange(e.detail.value)
                              }}
                              placeholder="Select Sylist"
                            >
                              {flatAssociates.map((associate) => (
                                <IonSelectOption
                                  key={associate.objectId}
                                  value={associate.objectId}
                                >
                                  {associate.displayName}
                                </IonSelectOption>
                              ))}
                            </IonSelect>
                          )}
                        />
                      </IonCol>
                    ) : null}
                  </IonRow>

                  {watchFromUserRule === 'fromUser' &&
                  (user.hasSendGrid ||
                    formMethods
                      .getValues([
                        'fromEmailName',
                        'fromEmailDomain',
                        'fromName',
                      ])
                      .some((value) => value != null)) ? (
                    <div className="space-y-2 py-4">
                      <div className="flex-shrink-0 basis-2/5">
                        <TextInput
                          control={control}
                          name="fromName"
                          label="Sender Name"
                        />
                      </div>
                      {account.sendgridDomains.length > 0 && user.hasSendGrid && (
                        <div className="flex w-full items-end gap-x-2">
                          <div className="flex-1">
                            <TextInput
                              control={control}
                              name="fromEmailName"
                              className="lowercase"
                              label="Sender Email Address"
                            />
                          </div>
                          <span className="text-lg">@</span>
                          <Controller
                            name="fromEmailDomain"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <IonSelect
                                style={{ '--placeholder-color': 'text-white' }}
                                className="bg-ion-color-step-100 rounded"
                                value={value}
                                onIonChange={(e) => onChange(e.detail.value)}
                              >
                                {account.sendgridDomains.map((d: string) => (
                                  <IonSelectOption key={d}>{d}</IonSelectOption>
                                ))}
                              </IonSelect>
                            )}
                          />
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>
              </fieldset>
              <div>
                <h2 className="text-center text-3xl font-normal">Flow</h2>
                <fieldset className="space-y-5">
                  {/* Trigger */}
                  <div className="space-y-2">
                    <h3 className="text-ion-color-yellow text-xl font-semibold">
                      Trigger
                    </h3>
                    <Controller
                      name="type"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <IonSelect
                          disabled
                          style={{ '--padding-start': '.75rem' }}
                          value={value}
                          onIonChange={(e) => onChange(e.detail.value)}
                          className="trigger-select border-ion-color-yellow w-full rounded border bg-transparent"
                        >
                          {triggers.map((item) => (
                            <IonSelectOption
                              key={item.value}
                              value={item.value}
                            >
                              {item.label}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      )}
                    />
                  </div>
                  {/* Conditions */}
                  <Controller
                    name="conditions"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <ConditionsModal conditions={value} onSearch={onChange} />
                    )}
                  />
                </fieldset>
              </div>
              <fieldset>
                {stepFields.fields.map((field, index) => (
                  <div key={field.id} className="relative">
                    {/* Flow Divider */}
                    <div className="bg-ion-color-yellow mx-auto my-6 h-12 w-0.5" />

                    <div
                      ref={refs.messageItemRef}
                      id={`message-card-${index + 1}`}
                      className="bg-ion-color-step-150 space-y-3 rounded px-4 py-6"
                    >
                      <h2 className="text-ion-color-yellow text-center text-lg font-semibold">
                        Message {index + 1}
                      </h2>
                      <TriggerStep
                        index={index}
                        disabled={createAutomation.isLoading}
                      />
                    </div>
                  </div>
                ))}
                {/* Add Steps */}
                <IonRow className="justify-center">
                  <IonCol size="auto">
                    <IonButton
                      fill="clear"
                      color="yellow"
                      size="large"
                      onClick={() => {
                        stepFields.append({
                          ...defaultStep,
                          position: formMethods.getValues('steps').length,
                        })
                        setTimeout(() =>
                          refs.messageItemRef.current?.scrollIntoView({
                            behavior: 'smooth',
                          })
                        )
                      }}
                    >
                      <IonIcon slot="icon-only" icon={addCircleOutline} />
                    </IonButton>
                  </IonCol>
                </IonRow>
              </fieldset>
              {/*  */}
            </form>
          </FormProvider>
        ) : (
          <p>There is no template with that ID.</p>
        )}
      </IonContent>
    </IonPage>
  )
}

export default CreateAutomationPage

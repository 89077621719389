import React from 'react'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { ellipsisHorizontal } from 'ionicons/icons'
import { Redirect } from 'react-router-dom'

import {
  useCurrentSequence,
  useCurrentSequenceClient,
  useSequenceBackRef,
} from '../../stores/useSequence'
import { usePrefetchClient } from '../../hooks/clients/queries'
import Header from '../../components/header'
import CreateSequenceTemplate from '../../components/sequence/CreateSequenceTemplate'
import SendSequence from '../../components/sequence/SendSequence'
import ClientInfoDisplay from '../../components/header/ClientInfoDisplay'
import { getFullName } from '../../utils/format-helpers'

function Sequence() {
  const [showContactDetails, setShowContactDetails] = React.useState(false)

  const currentSequence = useCurrentSequence()
  const sequenceBackRef = useSequenceBackRef()
  const currentSequenceClient = useCurrentSequenceClient()

  const { status } = currentSequence
  const fullName = currentSequenceClient
    ? getFullName(currentSequenceClient)
    : 'No Name'

  usePrefetchClient(currentSequenceClient?.objectId)

  if (!Object.keys(currentSequence).length) {
    return <Redirect to={`${sequenceBackRef}`} />
  }

  return (
    <IonPage>
      <IonHeader>
        {status === 'isUninitialized' ? (
          <IonToolbar>
            <IonTitle>Sequences</IonTitle>
          </IonToolbar>
        ) : status === 'isInitialized' ? (
          <Header
            backRef={currentSequence.backRef}
            showMenu={false}
            title={fullName}
            btn={
              <IonButton onClick={() => setShowContactDetails(true)}>
                <IonIcon
                  slot="icon-only"
                  color="secondary"
                  icon={ellipsisHorizontal}
                />
              </IonButton>
            }
          />
        ) : null}
      </IonHeader>
      <IonContent>
        {status === 'isUninitialized' ? (
          <CreateSequenceTemplate />
        ) : status === 'isInitialized' ? (
          <SendSequence />
        ) : null}
      </IonContent>
      <IonModal
        isOpen={showContactDetails}
        onDidDismiss={() => setShowContactDetails(false)}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons>
              <IonButton
                onClick={() => setShowContactDetails(false)}
                color="secondary"
                slot="end"
              >
                CLOSE
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <ClientInfoDisplay clientId={currentSequenceClient?.objectId} />
        </IonContent>
      </IonModal>
    </IonPage>
  )
}

export default Sequence

import { isPlatform } from '@ionic/react'

import { authService } from '../services/authService'
import { client } from '../services/client'

async function updateInstallation(deviceToken: string) {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const localeIdentifier = navigator.language

  try {
    const response = await client
      .post('installations', {
        json: {
          deviceType: isPlatform('ios')
            ? 'ios'
            : isPlatform('android')
            ? 'android'
            : isPlatform('desktop')
            ? 'web'
            : 'unknown',
          deviceToken,
          appVersion: process.env.REACT_APP_VERSION,
          user: authService.getUserId()
            ? {
                __type: 'Pointer',
                className: '_User',
                objectId: authService.getUserId(),
              }
            : undefined,
          localeIdentifier,
          timeZone,
        },
      })
      .json()

    return response
  } catch (error) {
    console.log('update installation error', error)
  }
}

export { updateInstallation }

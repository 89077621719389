import { IonRow, IonCol, IonButton, IonImg, IonSpinner } from '@ionic/react'
import { format, parseISO } from 'date-fns'

import {
  composeDollarAmount,
  composeNumber,
  roundTo,
} from '../../../utils/format-helpers'
import { useIsAdminRoute } from '../../../hooks/routing'
import { useBroadcastDetail, useBroadcastStats } from '../queries'
import { Card, CardContent } from './ui'

type Props = {
  broadcast: NonNullable<ReturnType<typeof useBroadcastDetail>['data']>
}

function BroadcastDetail({ broadcast }: Props) {
  const isAdmin = useIsAdminRoute()
  const { openRate, roi } = useBroadcastStats()

  const clientsLink = isAdmin
    ? `/tabs/admin/broadcasts/detail/${broadcast.objectId}/clients`
    : `/broadcasts/detail/${broadcast.objectId}/clients`

  return (
    <div className="ion-padding-bottom">
      <IonRow className="ion-padding-horizontal justify-between">
        {broadcast.date ? (
          <IonCol size="auto">
            Sent on {format(parseISO(broadcast.date.iso), 'MMM dd, yyyy')}
          </IonCol>
        ) : null}
        <IonCol size="auto">
          Created by {broadcast.user?.displayName} on{' '}
          {format(parseISO(broadcast.createdAt.iso), 'MMM dd, yyyy')}
        </IonCol>
      </IonRow>

      <div className="ion-padding-horizontal divide-y divide-slate-500">
        <IonRow className="ion-padding-bottom justify-center">
          <IonCol size="auto" className="space-y-3 p-0">
            <p className="text-ion-color-yellow text-center font-semibold uppercase">
              Total Results
            </p>
            <div className="text-ion-color-yellow flex items-center justify-center gap-x-10">
              <div className="flex flex-col items-center">
                <span className="text-3xl font-semibold leading-none">
                  {composeNumber(broadcast.emailCount + broadcast.smsCount)}
                </span>
                <span>Sent</span>
              </div>
              <div className="flex flex-col items-center">
                <span className="text-3xl font-semibold leading-none">
                  {roi.data ? (
                    composeDollarAmount(roi.data.total)
                  ) : roi.isError ? (
                    <span className="text-ion-color-danger text-lg">
                      Results Failed to Load
                    </span>
                  ) : (
                    <div className="text-center">
                      <IonSpinner name="dots" duration={1000} />
                    </div>
                  )}
                </span>
                <span>Sales in 14 days</span>
              </div>
            </div>
          </IonCol>
        </IonRow>

        {broadcast.smsCount > 0 ? (
          <div className="ion-padding-vertical">
            <h5 className="text-center font-semibold">Text Message</h5>

            <div className="ion-padding flex justify-end">
              <IonButton
                routerLink={`${clientsLink}?list=sms`}
                size="small"
                color="tertiary"
              >
                {broadcast.smsCount} Clients
              </IonButton>
            </div>

            {broadcast.smsMessage ? (
              <Card color="secondary">
                <CardContent>
                  <div className="whitespace-pre-line">
                    {broadcast.smsMessage}
                  </div>
                </CardContent>
              </Card>
            ) : null}

            {Boolean(broadcast.attachmentsSms) ? (
              <IonRow className="ion-padding-horizontal">
                {broadcast.attachmentsSms?.map((attachment) => (
                  <IonCol key={attachment} size="3">
                    <IonImg src={attachment} />
                  </IonCol>
                ))}
              </IonRow>
            ) : null}
          </div>
        ) : null}

        {broadcast.emailCount > 0 ? (
          <div className="ion-padding-vertical">
            <div className="space-y-3">
              <h5 className="text-center font-semibold">Email</h5>
              <div className="flex items-center justify-center gap-x-10">
                <div className="flex flex-col items-center">
                  <span className="text-3xl font-semibold leading-none">
                    {openRate.data ? (
                      composeNumber(openRate.data.open)
                    ) : openRate.isError ? (
                      <span className="text-ion-color-danger text-lg">
                        Results Failed to Load
                      </span>
                    ) : (
                      <div className="text-center">
                        <IonSpinner name="dots" duration={1000} />
                      </div>
                    )}
                  </span>
                  <span>Opened</span>
                </div>
                <div className="flex flex-col items-center">
                  <span className="text-3xl font-semibold leading-none">
                    {openRate.data ? (
                      `${roundTo(
                        (openRate.data.open / broadcast.emailCount) * 100,
                        1
                      )}%`
                    ) : openRate.isError ? (
                      <span className="text-ion-color-danger text-lg">
                        Results Failed to Load
                      </span>
                    ) : (
                      <div className="text-center">
                        <IonSpinner name="dots" duration={1000} />
                      </div>
                    )}
                  </span>
                  <span>Open Rate</span>
                </div>
              </div>
            </div>

            <div className="ion-padding flex justify-end">
              <IonButton
                routerLink={`${clientsLink}?list=email`}
                size="small"
                color="tertiary"
              >
                {broadcast.emailCount} Clients
              </IonButton>
            </div>

            {broadcast.unlayerImageFull ? (
              <Card color="secondary">
                <CardContent>
                  <p className="text-lg">
                    <span className="font-semibold">Subject:</span>{' '}
                    {broadcast.emailSubject}
                  </p>
                  <div className="flex justify-center">
                    <IonImg src={broadcast.unlayerImageFull} />
                  </div>
                </CardContent>
              </Card>
            ) : broadcast.emailMessage ? (
              <Card color="secondary">
                <CardContent>
                  <p className="text-lg">
                    <span className="font-semibold">Subject:</span>{' '}
                    {broadcast.emailSubject}
                  </p>
                  <div className="ion-padding-top whitespace-pre-line">
                    {broadcast.emailMessage}
                  </div>
                </CardContent>
              </Card>
            ) : null}
            {Boolean(broadcast.attachmentsEmail) ? (
              <IonRow className="ion-padding-horizontal">
                {broadcast.attachmentsEmail?.map((attachment) => (
                  <IonCol key={attachment} size="3">
                    <IonImg src={attachment} />
                  </IonCol>
                ))}
              </IonRow>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default BroadcastDetail

import { authService } from '../services/authService'
import { searchService } from '../services/searchService'

type CommunicationType = 'sms' | 'email' | 'phone'

interface Pointers {
  callListId?: string
  callListDynamicId?: string
  productIds?: Array<string>
  lookIds?: Array<string>
  collectionIds?: Array<string>
  emailTemplateId?: string
  smsTemplateId?: string
}

interface SendProps {
  message?: string
  clientId?: string
  log?: boolean
  note?: string
  pointers?: Pointers
  subject?: string
}
async function saveManualCommunication(
  type: CommunicationType,
  { clientId, subject, message, note, log, pointers }: SendProps
) {
  const userId = authService.getUserId()
  let body: any

  body = {
    user: {
      __type: 'Pointer',
      className: '_User',
      objectId: userId,
    },
    client: {
      __type: 'Pointer',
      className: 'Client',
      objectId: clientId,
    },
    manual: true,
    inbound: false,
  }

  if (pointers) {
    const {
      smsTemplateId,
      emailTemplateId,
      callListId,
      callListDynamicId,
      collectionIds,
      lookIds,
      productIds,
    } = pointers

    if (smsTemplateId) {
      body.smsTemplate = {
        __type: 'Pointer',
        className: 'SmsTemplate',
        objectId: smsTemplateId,
      }
    }
    if (emailTemplateId) {
      body.emailTemplate = {
        __type: 'Pointer',
        className: 'EmailTemplate',
        objectId: emailTemplateId,
      }
    }
    if (callListId) {
      body.callList = {
        __type: 'Pointer',
        className: 'CallList',
        objectId: callListId,
      }
    }
    if (callListDynamicId) {
      body.callListDynamic = {
        __type: 'Pointer',
        className: 'CallListDynamic',
        objectId: callListDynamicId,
      }
    }
    if (collectionIds) {
      const collections: Array<{
        __type: 'Pointer'
        className: 'Collection'
        objectId: string
      }> = []

      collectionIds.forEach((collectionId) => {
        collections.push({
          __type: 'Pointer',
          className: 'Collection',
          objectId: collectionId,
        })
      })
      body.collections = collections
    }
    if (lookIds) {
      const looks: Array<{
        __type: 'Pointer'
        className: 'Look'
        objectId: string
      }> = []
      lookIds.forEach((lookId) => {
        looks.push({
          __type: 'Pointer',
          className: 'Look',
          objectId: lookId,
        })
      })
      body.looks = looks
    }
    if (productIds) {
      const products: Array<{
        __type: 'Pointer'
        className: 'Product'
        objectId: string
      }> = []
      productIds.forEach((productId) => {
        products.push({
          __type: 'Pointer',
          className: 'Product',
          objectId: productId,
        })
      })
      body.products = products
    }
  }

  if (log) body.log = log
  if (message) body.data = message
  if (subject) body.subject = subject
  if (type) body.type = type
  if (note) body.note = note

  await searchService.createObject('Message', body)
}

export default saveManualCommunication

import { useParams } from 'react-router-dom'
import { IonContent, IonHeader, IonLoading, IonPage } from '@ionic/react'

import { useLook } from '../../hooks/shopwith/looks/queries'
import Header from '../../components/header'
import LookDetail from '../../components/shopwith/looks/look-detail'

function Look() {
  const { lookId } = useParams<{ lookId: string }>()

  const lookQuery = useLook(lookId)

  return (
    <IonPage>
      <IonHeader>
        <Header backRef="/tabs/shopwith" title="Asset" />
      </IonHeader>

      <IonContent>
        {lookQuery.data ? (
          <LookDetail look={lookQuery.data} />
        ) : (
          <IonLoading isOpen message="Loading Asset..." />
        )}
      </IonContent>
    </IonPage>
  )
}

export default Look

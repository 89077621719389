import { authService } from './authService'

import * as store from '../stores'

async function cloudFunction(functionName, body) {
  const options = {
    method: 'POST',
    headers: store.getHeader(),
    body: JSON.stringify(body),
  }

  const response = await window.fetch(
    `${store.getEndpoint()}functions/${functionName}`,
    options
  )
  const result = await handleResponse(response)
  return result.result
}

// internal
async function oathAuthorize(params) {
  return await cloudFunction('authorize', params)
}

async function sendLetter(body) {
  const options = {
    method: 'POST',
    headers: store.getHeader(),
    body: JSON.stringify(body),
  }

  const response = await window.fetch(
    `${store.getEndpoint()}functions/sendLetter`,
    options
  )
  const result = await handleResponse(response)
  return result.result
}

async function getLetterCredits() {
  const options = {
    method: 'POST',
    headers: store.getHeader(),
  }

  const response = await window.fetch(
    `${store.getEndpoint()}functions/letterCredits`,
    options
  )
  const result = await handleResponse(response)
  return result.result
}

async function getVendors() {
  const options = {
    method: 'POST',
    headers: store.getHeader(),
  }

  const response = await window.fetch(
    `${store.getEndpoint()}functions/vendors`,
    options
  )
  const result = await handleResponse(response)
  return result.result
}

async function getClient(params) {
  return await cloudFunction('client', params)
}

async function searchClients(params) {
  return cloudFunction('searchClients', params)
}

async function getAssociates() {
  const options = {
    method: 'POST',
    headers: store.getHeader(),
  }

  const response = await window.fetch(
    `${store.getEndpoint()}functions/page_associate`,
    options
  )
  const result = await handleResponse(response)
  return result.result
}

async function getUserStats(params) {
  return cloudFunction('getUserStats', params)
}

async function getUserOutReach(params) {
  return cloudFunction('getUserOutReach', params)
}

async function getUserTopClients(params) {
  return cloudFunction('getUserTopClients', params)
}

async function getRoiByType(params) {
  return cloudFunction('getRoiByType', params)
}

async function getRoiByTemplate(params) {
  return cloudFunction('getRoiByTemplate', params)
}

async function createObject(className, body) {
  const updateBody = {
    ...body,
  }

  const options = {
    method: 'POST',
    headers: store.getHeader(),
    body: JSON.stringify(updateBody),
  }

  const response = await window.fetch(
    `${store.getEndpoint()}classes/${className}`,
    options
  )
  const result = await handleResponse(response)
  return result
}

async function createClient(body) {
  const options = {
    method: 'POST',
    headers: store.getHeader(),
    body: JSON.stringify(body),
  }

  const response = await window.fetch(
    `${store.getEndpoint()}functions/createClient`,
    options
  )
  const result = await handleResponse(response)
  return result
}

async function updateObject(className, objectId, body) {
  const updateBody = {
    ...body,
    _method: 'PUT',
  }

  const options = {
    method: 'POST',
    headers: store.getHeader(),
    body: JSON.stringify(updateBody),
  }

  const response = await window.fetch(
    `${store.getEndpoint()}classes/${className}/${objectId}`,
    options
  )
  const result = await handleResponse(response)
  return result.results
}

async function getAccountDetails() {
  if (!authService.getToken()) return {}
  const options = {
    method: 'POST',
    headers: store.getHeader(),
  }
  const response = await window.fetch(
    `${store.getEndpoint()}functions/account`,
    options
  )
  const result = await handleResponse(response)

  if (result.result.groups) {
    const isAdmin =
      result.result.groups.filter((group) => group.role === 'admin').length > 0
    const isAssociate =
      result.result.groups.filter((group) => group.role === 'associate')
        .length > 0
    const isFemaleBrand =
      result.result.groups.filter((group) => group.femaleBrand === true)
        .length > 0

    result.result.isAdmin = isAdmin
    result.result.isAssociate = isAssociate
    result.result.showAssociate = isAssociate || (!isAssociate && !isAdmin)
    result.result.isFemaleBrand = isFemaleBrand
  }

  return result.result
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      const error =
        (data && data.message) ||
        (data && data.error) ||
        response.statusText ||
        'error'
      console.log(error)
      console.log(data)

      const reason = {
        error,
        status: response.status,
      }
      return Promise.reject(reason)
    }

    return data
  })
}

export const searchService = {
  createObject,
  createClient,
  getAccountDetails,
  updateObject,
  getAssociates,
  getClient,
  searchClients,
  getVendors,
  getLetterCredits,
  sendLetter,
  getUserStats,
  getUserOutReach,
  getUserTopClients,
  getRoiByType,
  getRoiByTemplate,
  oathAuthorize,
}

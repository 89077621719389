import { create } from 'zustand'

import type { PickedFile } from '@capawesome/capacitor-file-picker'
import type { PhotoData } from '../../hooks/use-photo'

type PostBase = {
  description: string
  products: Array<{
    objectId: string
    photo: string | null
    name: string | null
    brand: string | null
  }>
}

type PostMedia =
  | { type: 'collage' }
  | {
      type: 'photo'
      coverPhoto: PhotoData
    }
  | { type: 'video'; video: PickedFile }

type PostStore = PostBase & { media?: PostMedia }

const defaultState = () =>
  ({
    description: '',
    products: [],
  } as PostStore)

const usePostStore = create<PostStore>()(() => ({
  ...defaultState(),
}))

function updatePost(input: Partial<PostStore>) {
  usePostStore.setState((state) => ({
    ...state,
    ...input,
  }))
}

function resetPost() {
  // overwrite store to reset to original to remove optional items not set in defaults
  usePostStore.setState(defaultState(), true)
}

function usePostContent() {
  const description = usePostStore((state) => state.description)
  const products = usePostStore((state) => state.products)
  const postMedia = usePostStore((state) => state.media)

  return { description, products, postMedia }
}

export type { PostMedia }
export { usePostContent, resetPost, updatePost }

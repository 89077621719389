import { useQuery } from '@tanstack/react-query'

import { client } from '../../services/client'
import { useToast } from '../../contexts/toastContext'

import type { QueryContextFromKeys } from '../../utils/react-query'
import type { FetchSelectFilterResponse, SelectFilterParams } from './types'

type MaterialsQueryContexts = QueryContextFromKeys<typeof materialsQueryKeys>

const materialsQueryKeys = {
  all: [{ entity: 'materials' }] as const,
  lists: () => [{ ...materialsQueryKeys.all[0], scope: 'list' }] as const,
  list: ({ admin }: Pick<SelectFilterParams, 'admin'>) =>
    [{ ...materialsQueryKeys.lists()[0], admin }] as const,
}

async function fetchMaterials({
  queryKey: [{ admin }],
}: MaterialsQueryContexts['list']) {
  const response: FetchSelectFilterResponse = await client
    .post('functions/materials', {
      json: admin ? { filter: { admin } } : undefined,
    })
    .json()

  return response.result
}

function selectMaterials(data: Awaited<ReturnType<typeof fetchMaterials>>) {
  return [...Array.from(new Set(data.map((item) => item.toUpperCase())))].sort(
    (a, b) => {
      // Custom filter for UNO to bring gold and siver their one use case to the top of the list
      if (a === 'MTL') return -1
      if (a === 'ORO') return -1
      return a.localeCompare(b, undefined, {
        numeric: true,
        ignorePunctuation: true,
      })
    }
  )
}

function useMaterials({ admin, enabled }: SelectFilterParams) {
  const setToast = useToast()

  return useQuery({
    queryKey: materialsQueryKeys.list({ admin }),
    queryFn: fetchMaterials,
    enabled,
    refetchOnWindowFocus: false,
    retry: false,
    select: selectMaterials,
    onError: (error) => {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown Error.'

      setToast({
        message: `Something went wrong while loading materials: ${errorMessage}`,
        color: 'danger',
      })
    },
  })
}

export { useMaterials }

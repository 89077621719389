import * as React from 'react'
import { Listbox } from '@headlessui/react'
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLoading,
  IonRow,
  IonSearchbar,
  IonToolbar,
} from '@ionic/react'
import { Controller, useForm } from 'react-hook-form'

import { usePostProducts } from '../../hooks/shopwith/products/queries'
import { useInfiniteData } from '../../hooks/use-infinite-data'
import ProductItem from './product-item'

import type { Product } from '../../types/shopwith'

type Props = {
  products: Array<Product>
  onCancel: () => void
  onClose: (stuff: Array<Product>) => void
}

function SelectProductsModal({ products, onCancel, onClose }: Props) {
  const [searchTerm, setSearchTerm] = React.useState('')
  const [selectedProducts, setSelectedProducts] =
    React.useState<Array<Product>>(products)

  const searchForm = useForm<{ search: string }>({
    defaultValues: { search: '' },
  })

  const productsQuery = usePostProducts(searchTerm) //<< // TODO: USE LOOK QUERY?
  const [ionInfiniteScrollRef, loadMoreProducts] = useInfiniteData(
    productsQuery.fetchNextPage
  )
  const flatProducts = productsQuery.data?.pages.flatMap((x) => x.data)

  function handleSearch(data: { search: string }) {
    setSearchTerm(data.search)
  }

  function handleSearchClear() {
    setSearchTerm('')
  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton color="secondary" fill="outline" onClick={onCancel}>
              Cancel
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton
              color="yellow"
              fill="solid"
              onClick={() => onClose(selectedProducts)}
            >
              Done
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRow className="ion-align-items-center ion-justify-content-center">
          <form
            className="flex-1"
            onSubmit={searchForm.handleSubmit(handleSearch)}
          >
            <Controller
              name="search"
              control={searchForm.control}
              render={({ field: { value, onChange } }) => (
                <IonSearchbar
                  value={value}
                  onIonChange={onChange}
                  onIonClear={handleSearchClear}
                  debounce={0}
                />
              )}
            />
          </form>
        </IonRow>

        <Listbox
          value={selectedProducts}
          by="objectId"
          onChange={setSelectedProducts}
          multiple
        >
          <Listbox.Options as={IonRow} static>
            {flatProducts?.length ? (
              flatProducts.map((product) => (
                <IonCol
                  key={product.objectId}
                  className="flex flex-col"
                  sizeXs="6"
                  sizeSm="4"
                  sizeMd="3"
                >
                  <Listbox.Option value={product} as={React.Fragment}>
                    {({ selected }) => {
                      return (
                        <ProductItem
                          product={product}
                          shouldHideDetail
                          isSelected={selected}
                        />
                      )
                    }}
                  </Listbox.Option>
                </IonCol>
              ))
            ) : flatProducts?.length === 0 ? (
              <p>Sorry. There were no products matching that search.</p>
            ) : productsQuery.isLoading ? (
              <IonLoading isOpen />
            ) : null}
          </Listbox.Options>
        </Listbox>
        <IonInfiniteScroll
          ref={ionInfiniteScrollRef}
          onIonInfinite={loadMoreProducts}
          threshold="100px"
          disabled={!productsQuery.hasNextPage}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="loading more products..."
          />
        </IonInfiniteScroll>
      </IonContent>
    </>
  )
}

export default SelectProductsModal

import * as React from 'react'
import { useParams } from 'react-router-dom'
import {
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import { client } from '../../services/client'
import { useToast } from '../../contexts/toastContext'
import { useInfiniteData } from '../use-infinite-data'

import type { IsoDate, ProfileBase } from '../../types/general'
import type { Client } from '../clients/queries'
import type { QueryContextFromKeys } from '../../utils/react-query'

type FetchInboxResponse = {
  result: {
    hasNextPage: boolean
    meta: { unreadCount: number }
    page: number
    pageSize: number
    data: Array<InboxConversation>
  }
}

type FetchInboxUnreadCountResponse = {
  result: {
    unreadConversationCount: number
  }
}

type FetchMessagesResponse = {
  result: ConversationDetail
}

type InboxState = 'all' | 'unread'
type MailboxType = 'shared' | 'personal'

export type InboxConversation = {
  objectId: string
  users: Array<ProfileBase>
  clients: Array<Client>
  lastMessage: {
    data?: string
    attachments?: Array<{ photoUrl: string }>
  } | null
  date: IsoDate | null
  unread: boolean
}

type MessageDeliveryStatus =
  | 'pending' // internal need to send
  | 'accepted' // sent to twilio
  | 'queued' // queued in twilio
  | 'sending'
  | 'sent' // sent in twilio and in email
  | 'delivered'
  | 'delivery_unknown'
  | 'undelivered'
  | 'failed'

export type Message = {
  objectId: string
  attachments?: Array<string>
  client: ProfileBase | null
  data?: string
  date: string
  inbound: boolean
  status: MessageDeliveryStatus
  subject?: string
  type: 'sms' | 'email'
  user: ProfileBase | null
}

export type ConversationDetail = {
  data: Array<Message>
  meta: { conversation: InboxConversation }
  page: number
  pageSize: number
}

type ChatQueryContexts = QueryContextFromKeys<typeof chatQueryKeys>

export const chatQueryKeys = {
  all: [{ entity: 'messages' }] as const, // entire cache
  inbox: () => [{ ...chatQueryKeys.all[0], scope: 'inbox' }] as const, // (Conversation)
  inboxFiltered: ({
    mailbox,
    filter,
    userRole,
  }: {
    filter: InboxState
    mailbox: MailboxType
    userRole: 'admin' | 'associate'
  }) => [{ ...chatQueryKeys.inbox()[0], filter, mailbox, userRole }],
  inboxDetail: (conversationId: string) =>
    [{ ...chatQueryKeys.inbox()[0], conversationId }] as const,
  inboxDetailFind: ({ clientId }: { clientId: string }) =>
    [{ ...chatQueryKeys.inbox()[0], clientId }] as const,
  inboxUnreadCount: ({ userRole }: { userRole: 'admin' | 'associate' }) => [
    { ...chatQueryKeys.inbox()[0], meta: 'unreadCount', userRole },
  ],
  conversations: () =>
    [{ ...chatQueryKeys.all[0], scope: 'conversation' }] as const, // (Message)
  conversationDetail: (conversationId: string) =>
    [{ ...chatQueryKeys.conversations()[0], conversationId }] as const,
}

async function fetchPrimaryClientConversation({
  queryKey: [{ clientId }],
}: ChatQueryContexts['inboxDetailFind']) {
  const response: { result: InboxConversation } = await client
    .post('functions/getPrimaryConversation', { json: { clientId } })
    .json()

  return response.result
}

async function fetchInboxUnreadCount({
  queryKey: [{ userRole }],
}: ChatQueryContexts['inboxUnreadCount']) {
  const response: FetchInboxUnreadCountResponse = await client
    .post('functions/getUnreadConversationCount', {
      json: { admin: userRole === 'admin' },
    })
    .json()

  return response.result
}

async function fetchInbox({
  pageParam = 0,
  queryKey,
}: ChatQueryContexts['inboxFiltered']) {
  const pageSize = 20
  if (queryKey[0].mailbox === 'shared') {
    const response: FetchInboxResponse = await client
      .post('functions/getSharedConversations', {
        json: {
          page: pageParam,
          pageSize,
          unreadOnly: queryKey[0].filter === 'unread',
          admin: queryKey[0].userRole === 'admin',
        },
      })
      .json()

    return response.result
  }

  const response: FetchInboxResponse = await client
    .post('functions/getConversations', {
      json: {
        page: pageParam,
        pageSize,
        unreadOnly: queryKey[0].filter === 'unread',
        admin: queryKey[0].userRole === 'admin',
      },
    })
    .json()

  return response.result
}

/* ---------- CONVERSATION/THREAD DETAIL */
async function fetchMessages({
  queryKey: [{ conversationId }],
}: ChatQueryContexts['conversationDetail']) {
  const response: FetchMessagesResponse = await client
    .post('functions/getConversationMessages', {
      json: { conversationId },
    })
    .json()

  return response.result
}

function usePrimaryClientConversation() {
  const queryClient = useQueryClient()
  const setToast = useToast()

  async function getConversationId(clientId: string) {
    try {
      const c = await queryClient.fetchQuery(
        chatQueryKeys.inboxDetailFind({ clientId }),
        fetchPrimaryClientConversation
      )

      return c.objectId
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Unknown error'

      setToast({
        message: `Something went wrong setting up the conversation for this client. ${errorMessage}`,
        color: 'danger',
      })
    }
  }

  return { getConversationId }
}

function useInfiniteInbox({
  mailbox,
  filter,
  userRole,
}: {
  mailbox: MailboxType
  filter: InboxState
  userRole: 'admin' | 'associate'
}) {
  return useInfiniteQuery({
    queryKey: chatQueryKeys.inboxFiltered({ filter, mailbox, userRole }),
    queryFn: fetchInbox,
    getNextPageParam: (lastPage, pages) =>
      lastPage.hasNextPage ? lastPage.page + 1 : undefined,
    refetchInterval: 600000, // 10 minutes
  })
}

function useInbox({
  mailbox,
  filter,
  userRole,
}: {
  mailbox: MailboxType
  filter: InboxState
  userRole: 'admin' | 'associate'
}) {
  const query = useInfiniteInbox({ mailbox, filter, userRole })
  const [ionInfiniteScrollRef, loadMore] = useInfiniteData(query.fetchNextPage)

  return {
    ionInfiniteScrollRef,
    data: query.data,
    hasNextPage: query.hasNextPage,
    loadMore,
  }
}

function usePersonalInbox() {
  return useInbox({ mailbox: 'personal', filter: 'all', userRole: 'associate' })
}

function useUnreadPersonalInbox() {
  return useInbox({
    mailbox: 'personal',
    filter: 'unread',
    userRole: 'associate',
  })
}

function useSharedInbox() {
  return useInbox({ mailbox: 'shared', filter: 'all', userRole: 'associate' })
}

function useUnreadSharedInbox() {
  return useInbox({
    mailbox: 'shared',
    filter: 'unread',
    userRole: 'associate',
  })
}

function useAdminAssignedInbox() {
  return useInbox({ mailbox: 'personal', filter: 'all', userRole: 'admin' })
}
function useAdminSharedInbox() {
  return useInbox({ mailbox: 'shared', filter: 'all', userRole: 'admin' })
}
function useAdminUnreadAssignedInbox() {
  return useInbox({ mailbox: 'personal', filter: 'unread', userRole: 'admin' })
}
function useAdminUnreadSharedInbox() {
  return useInbox({ mailbox: 'shared', filter: 'unread', userRole: 'admin' })
}

function useInboxUnreadCount() {
  return useQuery({
    queryKey: chatQueryKeys.inboxUnreadCount({ userRole: 'associate' }),
    queryFn: fetchInboxUnreadCount,
  })
}

function useAdminInboxUnreadCount() {
  return useQuery({
    queryKey: chatQueryKeys.inboxUnreadCount({ userRole: 'admin' }),
    queryFn: fetchInboxUnreadCount,
  })
}

function useConversationDetail() {
  const queryClient = useQueryClient()
  const cid = useParams<{ cid: string }>().cid

  React.useEffect(() => {
    queryClient.invalidateQueries(chatQueryKeys.inbox())
  }, [queryClient])

  // make sure to invalidate on unmount so next mount is fresh data from server
  React.useEffect(() => {
    return () => {
      queryClient.invalidateQueries(chatQueryKeys.conversationDetail(cid), {
        refetchType: 'none',
      })
    }
  }, [cid, queryClient])

  return useQuery({
    queryKey: chatQueryKeys.conversationDetail(cid),
    queryFn: fetchMessages,
    refetchInterval: 30000, // 30 seconds - should only be refetching when conversation is open
  })
}

export {
  useInfiniteInbox,
  usePersonalInbox,
  useUnreadPersonalInbox,
  useSharedInbox,
  useUnreadSharedInbox,
  useInboxUnreadCount,
  useConversationDetail,
  usePrimaryClientConversation,
  useAdminInboxUnreadCount,
  useAdminSharedInbox,
  useAdminAssignedInbox,
  useAdminUnreadSharedInbox,
  useAdminUnreadAssignedInbox,
}

import { useQuery } from '@tanstack/react-query'
import { klona } from 'klona/json'

import { strToTitleCase } from '../../utils/format-helpers'
import { client } from '../../services/client'
import { useToast } from '../../contexts/toastContext'

import type { QueryContextFromKeys } from '../../utils/react-query'
import type { FetchSelectFilterResponse, SelectFilterParams } from './types'

type PosSellerNamesQueryContexts = QueryContextFromKeys<
  typeof posSellerNamesQueryKeys
>

const posSellerNamesQueryKeys = {
  all: [{ entity: 'posSellerNames' }] as const,
  lists: () => [{ ...posSellerNamesQueryKeys.all[0], scope: 'list' }] as const,
  list: ({ admin }: Pick<SelectFilterParams, 'admin'>) =>
    [{ ...posSellerNamesQueryKeys.lists()[0], admin }] as const,
}

async function getPosSellerNames({
  queryKey: [{ admin }],
}: PosSellerNamesQueryContexts['list']) {
  const response: FetchSelectFilterResponse = await client
    .post('functions/posSellerNames', {
      json: admin ? { filter: { admin } } : undefined,
    })
    .json()

  return response.result
}

export default function usePosSellerNames({
  admin,
  enabled,
}: SelectFilterParams) {
  const setToast = useToast()

  return useQuery({
    queryKey: posSellerNamesQueryKeys.list({ admin }),
    queryFn: getPosSellerNames,
    select: (data) =>
      klona(data.map((item) => strToTitleCase(item))).sort((a, b) =>
        a.toLowerCase() < b.toLowerCase() ? -1 : 1
      ),
    enabled,
    refetchOnWindowFocus: false,
    retry: false,
    onError(error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown Error.'

      setToast({
        message: `Something went wrong while loading POS Seller Names: ${errorMessage}`,
        color: 'danger',
      })
    },
  })
}

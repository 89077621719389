import PhoneInput from 'react-phone-number-input'

import { isRowValid } from './validation'

import type { MRT_ColumnDef } from 'material-react-table'

type ClientImport = {
  'First Name': string
  'Last Name': string
  Email: string
  Phone: string
  Tag: string
  Note: string
  'Loyalty Points': string
  'Loyalty Visits': string
  'Loyalty Points Lifetime': string
  'Loyalty Visits Lifetime': string
}

const columns: Array<MRT_ColumnDef<ClientImport>> = [
  { accessorKey: 'First Name', header: 'First Name', enableEditing: true },
  { accessorKey: 'Last Name', header: 'Last Name', enableEditing: true },
  {
    accessorKey: 'Email',
    header: 'Email',
    enableEditing: true,
  },
  {
    accessorKey: 'Phone',
    header: 'Phone',
    Edit: ({ cell, row }) => (
      <PhoneInput
        value={`${cell.getValue() || ''}`}
        onChange={(value) => (row._valuesCache['Phone'] = value)}
        placeholder="Phone Number"
        defaultCountry="US"
      />
    ),
    enableEditing: true,
  },
  { accessorKey: 'Tag', header: 'Tag', enableEditing: true },
  { accessorKey: 'Note', header: 'Note', enableEditing: true },
  {
    accessorKey: 'Loyalty Points',
    header: 'Loyalty Points',
    enableEditing: true,
  },
  {
    accessorKey: 'Loyalty Visits',
    header: 'Loyalty Visits',
    enableEditing: true,
  },
  {
    accessorKey: 'Loyalty Points Lifetime',
    header: 'Loyalty Points Lifetime',
    enableEditing: true,
  },
  {
    accessorKey: 'Loyalty Visits Lifetime',
    header: 'Loyalty Visits Lifetime',
    enableEditing: true,
  },
  {
    id: 'error',
    header: 'Error',
    columnDefType: 'display',
    enableEditing: false,
    Cell: ({ row }) => {
      return <p>{isRowValid(row.original).helperText}</p>
    },
  },
]

export type { ClientImport }
export { columns }

import { useMemo, useState } from 'react'

function useBoolean(initialValue: boolean = false) {
  const [on, setOn] = useState(initialValue)
  const handlers = useMemo(
    () => ({
      on() {
        setOn(true)
      },
      off() {
        setOn(false)
      },
    }),
    []
  )

  return [on, handlers] as const
}

export { useBoolean }

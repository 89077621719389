import * as React from 'react'
import { IonLabel, IonList, IonNote, IonItem, IonIcon } from '@ionic/react'
import { chevronDown, chevronUp } from 'ionicons/icons'

import { DataSet } from './RoiChart'
import Currency from '../Currency'
import { useFormatNumber } from '../../hooks/use-format-number'

interface RoiListProps {
  header?: string
  label?: string
  datasets: Array<DataSet> | undefined
  onToggleViewAll: (toggle: boolean) => void
  toggle: boolean
  addTotalRow?: boolean
}

const RoiList: React.FC<RoiListProps> = ({
  label,
  datasets,
  onToggleViewAll,
  toggle,
  addTotalRow,
}) => {
  const [showAll, setShowAll] = React.useState(false)
  const formatNumber = useFormatNumber()

  React.useEffect(() => {
    setShowAll(toggle)
  }, [toggle])

  const reducer = (accumulator: number, currentValue: number) =>
    accumulator + currentValue

  const allTotal =
    datasets?.map((dataset) => dataset.total).reduce(reducer, 0) ?? 0
  const allMessageCount =
    datasets?.map((dataset) => dataset.messageCount).reduce(reducer, 0) ?? 0

  return (
    <IonList color="secondary" lines="none">
      <IonItem color="secondary" onClick={() => onToggleViewAll(!showAll)}>
        <IonLabel>{label}</IonLabel>
        <IonNote
          style={{
            whiteSpace: 'pre',
            paddingRight: '0px',
          }}
        >
          <table>
            <tbody>
              <tr style={{ textAlign: 'right', whiteSpace: 'pre' }}>
                <td>TOTAL</td>
                <td style={{ minWidth: '72px' }}>SENT</td>
                <td style={{ minWidth: '84px' }}>ROI MSGs</td>
                <td style={{ minWidth: '80px' }}>$/MSG</td>
              </tr>
            </tbody>
          </table>
        </IonNote>
        <IonIcon slot="start" icon={showAll ? chevronDown : chevronUp} />
      </IonItem>

      {addTotalRow && (
        <IonItem color="secondary">
          <IonLabel>All</IonLabel>
          <IonNote
            slot="end"
            style={{
              whiteSpace: 'pre',
              paddingRight: '0px',
            }}
          >
            <table>
              <tbody>
                <tr style={{ textAlign: 'right', whiteSpace: 'pre' }}>
                  <td>
                    <b>
                      <Currency value={allTotal} />
                    </b>
                  </td>
                  <td style={{ minWidth: '72px' }}>{allMessageCount}</td>
                  <td style={{ minWidth: '72px' }}>
                    {formatNumber(allTotal / (allMessageCount || 1))}
                  </td>
                </tr>
              </tbody>
            </table>
          </IonNote>
        </IonItem>
      )}

      {datasets
        ?.slice(0, showAll ? undefined : addTotalRow ? 0 : 1)
        .map((data: any, index: number) => (
          <IonItem key={index + data.label} color="secondary">
            <IonLabel>{data.label}</IonLabel>
            <IonNote
              slot="end"
              style={{
                whiteSpace: 'pre',
                paddingRight: '0px',
              }}
            >
              <table>
                <tbody>
                  <tr style={{ textAlign: 'right', whiteSpace: 'pre' }}>
                    <td>
                      <b>
                        <Currency value={data.total} />
                      </b>
                    </td>
                    <td style={{ minWidth: '72px' }}>{data.messageCount}</td>
                    <td style={{ minWidth: '84px' }}>{data.messageCountRoi}</td>
                    <td style={{ minWidth: '80px' }}>
                      {formatNumber(data.total / data.messageCountRoi)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </IonNote>
          </IonItem>
        ))}
    </IonList>
  )
}

export default RoiList

import * as React from 'react'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonList,
  IonModal,
  IonNote,
  IonText,
  IonToolbar,
} from '@ionic/react'
import { notifications } from 'ionicons/icons'
import { formatDistance, parseISO } from 'date-fns'

import { useNotifications } from '../../hooks/alerts/queries'
import { useUpdateNotificationStatus } from '../../hooks/alerts/mutations'
import Badge from '../Badge'
import { ListItem, ListItemSubHeading } from '../ui/list-item'
import type { ParseNotification } from '../../hooks/alerts/queries'

function getNotificationPath(notification: ParseNotification) {
  const likesClientId = notification.likes?.[0]?.client?.objectId

  if (likesClientId) {
    return `/tabs/clients/client/${likesClientId}?currentview=wishlist`
  } else if (notification?.conversationSid) {
    return `/chat/c/${notification?.conversationSid}`
  } else if (notification?.clientId) {
    return `/tabs/clients/client/${notification?.clientId}`
  } else if (notification.callList) {
    return `/tabs/clients/outreach/${notification.callList.objectId}`
  } else if (notification.alteration) {
    return `/tasks/Alteration/${notification.alteration.objectId}`
  } else if (notification.followUp) {
    return `/tasks/FollowUp/${notification.followUp.objectId}`
  } else if (notification.specialOrder) {
    return `/tasks/SpecialOrder/${notification.specialOrder.objectId}`
  } else if (notification.event === 'broadcastRequiresApproval') {
    return '/admin/campaign-approvals'
  } else if (notification.event === 'broadcastApproved') {
    return `/broadcasts/detail/${notification.broadcast.objectId}`
  }
}

function AlertsButton() {
  const [showNotifications, setShowNotifications] = React.useState(false)

  const notificationsQuery = useNotifications()
  const updateReadStatus = useUpdateNotificationStatus()

  const totalUnreadNotifications =
    notificationsQuery.data?.filter((notification) => !notification.readAt)
      .length ?? 0

  function handleNotificationClick(notificationId: string, isRead: boolean) {
    setShowNotifications(false)
    !isRead && updateReadStatus.mutate(notificationId)
  }

  return (
    <>
      <IonButton
        style={{
          '--padding-start': 0,
          '--padding-end': 0,
          '--overflow': 'initial',
        }}
        className="relative"
        color="secondary"
        onClick={() => setShowNotifications(true)}
      >
        <IonIcon slot="icon-only" icon={notifications} />
        {totalUnreadNotifications > 0 ? (
          <Badge>{totalUnreadNotifications}</Badge>
        ) : null}
      </IonButton>

      <IonModal
        isOpen={showNotifications}
        onDidDismiss={() => setShowNotifications(false)}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                onClick={() => {
                  setShowNotifications(false)
                }}
                color="secondary"
              >
                CANCEL
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
            {notificationsQuery.data?.map((notification) => (
              <ListItem
                key={notification.objectId}
                detail={false}
                routerLink={getNotificationPath(notification)}
                onClick={() =>
                  handleNotificationClick(
                    notification.objectId,
                    Boolean(notification.readAt)
                  )
                }
                style={{
                  '--detail-icon-opacity': notification.readAt ? 0.4 : 1,
                }}
              >
                <IonLabel className="ml-4">
                  <IonText color="secondary">
                    <ListItemSubHeading
                      className={
                        notification.readAt ? 'font-normal' : 'font-semibold'
                      }
                    >
                      {notification.title}
                    </ListItemSubHeading>
                    <ListItemSubHeading
                      className={
                        notification.readAt ? 'font-normal' : 'font-semibold'
                      }
                    >
                      {notification.body}
                    </ListItemSubHeading>
                  </IonText>
                </IonLabel>
                <IonNote color={notification.readAt ? undefined : 'secondary'}>
                  {formatDistance(
                    parseISO(notification.createdAt),
                    new Date(),
                    { addSuffix: true }
                  )}
                </IonNote>
              </ListItem>
            ))}
          </IonList>
        </IonContent>
      </IonModal>
    </>
  )
}

export { AlertsButton }

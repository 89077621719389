import * as React from 'react'
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonImg,
  IonLabel,
  IonRow,
  isPlatform,
  useIonLoading,
} from '@ionic/react'
import { useForm, Controller } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'

import { getDefaultPhoneNumber } from '../../utils/client-helpers'
import saveManualCommunication from '../../utils/save-communication'
import { formatName } from '../../utils/format-helpers'
import { useUser } from '../../contexts/authContext'
import { useToast } from '../../contexts/toastContext'
import { useSendMessage } from '../../hooks/chat/mutations'
import { usePrimaryClientConversation } from '../../hooks/chat/queries'
import { outreachQueryKeys } from '../../hooks/outreach/queries'
import useNextContact from '../../hooks/use-next-contact'
import useSendCommunication from '../../hooks/use-send-communication'
import { useCurrentSequence } from '../../stores/useSequence'
import { TextArea } from '../ui/form/textarea'

const isBrowser = !isPlatform('capacitor')

function replacePlaceholders(str, client) {
  if (str === null || str === undefined) {
    return ''
  }
  var mapObj = {
    '<First Name>': formatName(client.firstName),
    '<Last Name>': formatName(client.lastName),
  }

  return str.replace(/<First Name>|<Last Name>/gi, function (matched) {
    return mapObj[matched]
  })
}

function SequenceSmsForm({ currentClient, template, onMessageSend }) {
  const queryClient = useQueryClient()
  const [presentLoading, dismissLoading] = useIonLoading()
  const { hasTwilio, smsManual } = useUser()
  const { getConversationId } = usePrimaryClientConversation()
  const sendMessageMutation = useSendMessage()

  const currentSequence = useCurrentSequence()

  const { control, reset, handleSubmit, setValue } = useForm({
    defaultValues: {
      message: replacePlaceholders(template.message, currentClient),
      photos: template.photos,
    },
  })

  const { saveNextContactDate } = useNextContact(currentSequence.followUpDate)
  const { sendSMS } = useSendCommunication()

  const setToast = useToast()
  const defaultNumber = getDefaultPhoneNumber(currentClient.phoneNumbers)

  // reset the form when current client changes.
  React.useEffect(() => {
    reset({
      message: replacePlaceholders(template.message, currentClient),
    })
  }, [currentClient, reset, template.message, template.signUpMessage])

  function invalidateCallLists() {
    if (currentSequence.callListId) {
      queryClient.invalidateQueries(
        outreachQueryKeys.detail({ id: currentSequence.callListId })
      )
      queryClient.invalidateQueries(
        outreachQueryKeys.list({ type: 'One Time' })
      )
    }

    if (currentSequence.callListDynamicId) {
      queryClient.invalidateQueries(
        outreachQueryKeys.detail({ id: currentSequence.callListDynamicId })
      )
      queryClient.invalidateQueries(outreachQueryKeys.list({ type: 'Dynamic' }))
    }
  }

  async function sendSequenceSMS(formState) {
    // TODO: possibly revisit checkin both photo types in this if we ever revisit sequences
    const attachments = []
    if (!isBrowser) {
      formState.photos?.forEach((photo) => {
        if (photo.fileUri) {
          attachments.push(photo.fileUri)
        } else if (photo) attachments.push(photo)
      })
    }

    try {
      await sendSMS(defaultNumber.phoneNumber, {
        message: formState.message,
        attachments,
      })
      invalidateCallLists()
      onMessageSend()
      await saveManualCommunication('sms', {
        message: formState.message,
        clientId: currentClient.objectId,
        log: false,
        pointers: {
          smsTemplateId: template.smsTemplateId,
          callListId: currentSequence.callListId,
          callListDynamicId: currentSequence.callListDynamicId,
        },
      })

      await saveNextContactDate(currentClient.objectId)
    } catch (error) {
      setToast({ message: 'Message canceled' })
    }
  }

  async function sendSequenceOneShopMessage(formState) {
    // Just in case - tho technically button should be disabled
    if (!defaultNumber || !defaultNumber.e164) {
      return setToast({
        message:
          'This client does not have a default phone number that can be messaged.',
      })
    }
    presentLoading({
      message: 'Sending message...',
    })

    try {
      const conversationId = await getConversationId(currentClient.objectId)

      if (!conversationId) {
        throw new Error(
          'Something went wrong setting up the conversation for this client.'
        )
      }
      const pointers = {
        smsTemplateId: template.smsTemplateId,
        callListId: currentSequence.callListId,
        callListDynamicId: currentSequence.callListDynamicId,
      }

      const message = {
        ...(formState.message && { data: formState.message }),
        ...(formState.photos?.length && {
          attachments: formState.photos.map((photo) => {
            return typeof photo === 'string'
              ? photo
              : {
                  name: photo.name,
                  content: photo.base64String,
                }
          }),
        }),
        ...pointers,
      }

      await sendMessageMutation.mutateAsync({
        conversationId,
        message,
      })
      invalidateCallLists()
    } catch (error) {
      const errorMsg = error instanceof Error ? error.message : 'Unknown Error.'
      setToast({
        message: `Something went wrong while sending the message. ${errorMsg}`,
        color: 'danger',
      })
    } finally {
      dismissLoading()
    }
  }

  async function handleSendMessage(data) {
    const formState = { ...data, photos: template.photos }

    if (hasTwilio && !smsManual) {
      await sendSequenceOneShopMessage(formState)
      onMessageSend()
    } else {
      sendSequenceSMS(formState)
    }
  }

  return (
    <IonCard key={currentClient} color="secondary">
      <IonCardContent>
        <div className="ion-margin-top flex items-center justify-between">
          <IonLabel className="ion-align-self-auto" position="stacked">
            Message:
          </IonLabel>
          <IonButton
            size="small"
            fill="outline"
            onClick={() => setValue('message', '')}
          >
            Clear
          </IonButton>
        </div>
        <Controller
          control={control}
          name="message"
          render={({ field: { value, onChange } }) => (
            <TextArea
              value={value}
              onIonChange={onChange}
              autoGrow
              autocapitalize="on"
            />
          )}
        />

        {template.photos?.length ? (
          <IonGrid>
            <IonRow>
              {hasTwilio || (!hasTwilio && !isBrowser ) ? (
                <>
                  {template.photos.map((photo) => (
                    <IonCol
                      sizeSm="4"
                      size="6"
                      id={photo.name ?? photo}
                      key={photo.name ?? photo}
                    >
                      <div className="ion-margin-vertical relative">
                        {/* photo quick fix/workaround for incoming template for twilio */}
                        <IonImg src={photo.pic ?? photo} />
                      </div>
                    </IonCol>
                  ))}
                </>
              ) : null}
            </IonRow>
          </IonGrid>
        ) : null}

        <div className="ion-margin-top ion-text-center">
          <IonButton
            color="yellow"
            onClick={handleSubmit(handleSendMessage)}
            disabled={sendMessageMutation.isLoading}
          >
            Send Message
          </IonButton>
        </div>
      </IonCardContent>
    </IonCard>
  )
}

export default SequenceSmsForm

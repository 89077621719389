import { Capacitor } from '@capacitor/core'
import { Cloudinary } from '@cloudinary/url-gen'

import type { PickedFile } from '@capawesome/capacitor-file-picker'

const oneshopCld = new Cloudinary({
  cloud: {
    cloudName: 'oneshop',
  },
})

function getVideoPath(video: PickedFile) {
  if (video.blob) return URL.createObjectURL(video.blob)
  if (video.path) return Capacitor.convertFileSrc(video.path)
  return ''

  // throw new Error('No video path - Something went wrong selecting the video.')
}

export { oneshopCld, getVideoPath }

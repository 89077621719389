/* eslint-disable react/jsx-pascal-case */
import { isPlatform } from '@ionic/react'
import {
  MaterialReactTable,
  MRT_ShowHideColumnsButton,
  MRT_ToggleGlobalFilterButton,
} from 'material-react-table'
import { Tooltip } from '@mui/material'
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid'
import { CSVLink } from 'react-csv'

import { columns } from './columns'

import type { Stat } from '../../hooks/use-stats'
import type { MRT_PaginationState } from 'material-react-table'

type Props = {
  data: Array<Stat>
  rowCount: number
  state: {
    pagination: { pageIndex: number; pageSize: number }
    isLoading: boolean
    showProgressBars: boolean
  }
  onSearchChange: (value: string) => void
  onPaginationChange: React.Dispatch<React.SetStateAction<MRT_PaginationState>>
}

function StylistStatsTable({
  data,
  rowCount,
  state,
  onSearchChange,
  onPaginationChange,
}: Props) {
  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      initialState={{ density: 'comfortable' }}
      state={state}
      defaultColumn={{ size: 0 }}
      enableColumnActions={false}
      enableColumnFilters={false}
      enableColumnOrdering
      enableDensityToggle={false}
      enableFullScreenToggle={false}
      enableSorting={false}
      manualFiltering
      manualPagination
      onGlobalFilterChange={onSearchChange}
      onPaginationChange={onPaginationChange}
      renderToolbarInternalActions={({ table }) => (
        <>
          <MRT_ToggleGlobalFilterButton table={table} />
          <MRT_ShowHideColumnsButton table={table} />
          {!isPlatform('capacitor') ? (
            <Tooltip title="Export">
              <span className="flex">
                <CSVLink
                  filename="stylist-stats.csv"
                  className="text-ion-color-step-400 disabled:text-ion-color-step-700 flex items-center justify-center p-2"
                  data={table
                    .getRowModel()
                    .rows.map(
                      ({ original: { photo, gmid, objectId, ...rest } }) => ({
                        ...rest,
                      })
                    )}
                  headers={table
                    .getFlatHeaders()
                    .filter((header) => header.id !== 'photo')
                    .map((header) => ({
                      key: header.column.id,
                      label: (header.column.columnDef.header ?? '') as string,
                    }))}
                >
                  <ArrowDownTrayIcon className=" size-6" />
                </CSVLink>
              </span>
            </Tooltip>
          ) : null}
        </>
      )}
      rowCount={rowCount}
    />
  )
}

export { StylistStatsTable }

import { klona } from 'klona/json'
import * as R from 'remeda'

import { dateCompare } from './date-helpers'
import { Sale } from '../types/client'

type OrganizedPurchase = {
  sales: Array<Omit<Sale, 'date' | 'posId1'>>
  sellerName: string | null
  sellerPhoto: string | null
  store: string | null
  totalSaleAmount: number
}
type SalesByPOS = {
  allSales: Array<Omit<Sale, 'date' | 'posId1'>>
  className: 'Sale'
  date: { iso: string }
  objectId: string
  organizedSales: {
    topSeller: OrganizedPurchase
    otherSellers: Array<OrganizedPurchase>
  }
  otherSellerNames: Array<string | null> | undefined
  sellers: {
    [key: string]: OrganizedPurchase
  }
  totalSaleAmount: number
}

function groupByPOS(sales: Array<Sale>) {
  // 1. create object with POSid keys each containing data for that POSid sale
  const groupedSales = sales.reduce<{
    [key: string]: SalesByPOS
  }>((acc, curr) => {
    const {
      posId1,
      posSellerName,
      date: { iso },
      ...rest
    } = curr

    acc[posId1] = acc[posId1] || {
      date: { iso },
      allSales: [],
      className: 'Sale',
      totalSaleAmount: 0,
      objectId: '',
      sellers: {},
    }

    acc[posId1]['allSales'].push({ posSellerName, ...rest })

    return acc
  }, {})

  // 3. add total sale amounts and a unique id to each POS entry
  Object.entries(groupedSales).forEach(([key, values]) => {
    const totalSaleAmount = values.allSales.reduce(
      (acc, curr) => acc + curr.value,
      0
    )
    const objectIds = values.allSales.reduce<Array<string>>(
      (acc, curr) => acc.concat(curr.objectId),
      []
    )
    const objectId = objectIds.join('-')
    groupedSales[key].totalSaleAmount = totalSaleAmount
    groupedSales[key].objectId = objectId
  })

  // 3. return array of values from newly formed object
  return Object.values(groupedSales)
}

function organizeSale(sale: SalesByPOS) {
  const groupedItemsByAssociate = R.groupBy(
    sale.allSales,
    (x) => x.posSellerName ?? 'null'
  )

  Object.keys(groupedItemsByAssociate).forEach((key) => {
    sale.sellers[key] = {
      sales: groupedItemsByAssociate[key],
      sellerName: key === 'null' ? null : key,
      sellerPhoto: groupedItemsByAssociate[key][0].userPhoto,
      store: groupedItemsByAssociate[key][0].group?.name ?? null,
      totalSaleAmount: 0,
    }
  })

  // get totals for each seller's sales
  const purchaseSellers = Object.values(sale.sellers)
  purchaseSellers.forEach((seller) => {
    seller.totalSaleAmount = seller.sales.reduce(
      (acc, curr) => acc + curr.value,
      0
    )
  })

  // find the seller object with the most amount sold
  // they will be the main associate for the display card
  const topSeller = purchaseSellers.reduce((max, seller) =>
    max && max.totalSaleAmount > seller.totalSaleAmount ? max : seller
  )

  // array of other sellers and array of seller names
  const otherSellers = purchaseSellers.filter(
    (seller) => seller.sellerName !== topSeller.sellerName
  )

  const otherSellerNames =
    otherSellers.length > 0
      ? otherSellers.map((seller) => seller.sellerName)
      : undefined

  sale.otherSellerNames = otherSellerNames
  sale.organizedSales = {
    topSeller,
    otherSellers,
  }
}

function groupPurchases(purchaseList: Array<Sale> = []) {
  // let sales = klona(purchaseList) // clone og list

  let sales = groupByPOS(klona(purchaseList)) // new array of sales by POSid
  sales.sort(dateCompare) // sort groupedByPOS sales in place

  sales.forEach((sale) => {
    organizeSale(sale)
  })

  return sales
}

export default groupPurchases

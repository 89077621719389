import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
  IonSpinner,
  IonText,
  useIonRouter,
} from '@ionic/react'
import { reloadCircleOutline } from 'ionicons/icons'
import { format, parseISO } from 'date-fns'

import { getDefaultPhoneNumber } from '../../utils/client-helpers'
import * as commonStrings from '../../utils/common-strings'
import { getFullName } from '../../utils/format-helpers'
import { useAccount, useUser } from '../../contexts/authContext'
import { useClientDetail, usePrefetchClient } from '../../hooks/clients/queries'
import { useIsStore } from '../../auth/utils'
import {
  useCurrentSequence,
  useCurrentSequenceClient,
} from '../../stores/useSequence'
import ClientDetailFeed from '../feed/ClientDetailFeed'
import SequenceSmsWithOptinForm from './sequence-sms-with-optin-form'
import SequenceSmsForm from './sequence-sms-form'

function SendSequence() {
  const account = useAccount()
  const { hasTwilio, smsManual } = useUser()

  const router = useIonRouter()
  const { isTristan } = useIsStore()

  const currentClient = useCurrentSequenceClient()
  const currentSequence = useCurrentSequence()

  const {
    template,
    list: sequenceList,
    currentIndex,
    resetSequence,
    incrementCurrentIndex,
  } = currentSequence
  usePrefetchClient(sequenceList[currentIndex + 1]?.objectId)

  const clientInfo = useClientDetail(currentClient.objectId)

  const defaultPhone = getDefaultPhoneNumber(currentClient.phoneNumbers)

  const isUnsubscribed =
    Boolean(currentClient.unsubscribedAt) ||
    defaultPhone?.optInState === 'OPTED_OUT' ||
    currentClient.posActive === false

  const hasPhone = defaultPhone && !defaultPhone.isLandLine
  const isFirstMessage = !Boolean(currentClient.lastContact)

  const fullName = currentClient ? getFullName(currentClient) : 'No Name'

  const nextContactDate = currentClient.nextContact
    ? format(parseISO(currentClient.nextContact.iso), 'MM/dd/yyyy')
    : commonStrings.notAvailable

  const lastContactDate = currentClient.lastContact
    ? format(parseISO(currentClient.lastContact.iso), 'MM/dd/yyyy')
    : commonStrings.notAvailable

  function getSmsSequenceStatus() {
    if (defaultPhone?.optInState === 'NOT_INITIALIZED') {
      return account.optinRequired && hasTwilio && !smsManual
        ? 'sms form with opt in'
        : 'sms form alone'
    }

    if (defaultPhone?.optInState === 'PENDING') {
      return account.optinRequired && hasTwilio && !smsManual
        ? 'pending opt in'
        : 'sms form alone'
    }

    if (defaultPhone?.optInState === 'OPTED_IN') {
      return 'sms form alone'
    }

    return 'error if we got here'
  }

  function exitSequence() {
    router.goBack()
  }

  // all the "page" and sequence logic for sending or skipping
  async function nextSequence() {
    // if this is the last client, skipping or sending should reset and exit
    if (currentIndex === sequenceList.length - 1) {
      resetSequence()
      return router.goBack()
    }

    // "load" next client
    incrementCurrentIndex()
  }

  return (
    <>
      <IonGrid>
        <IonRow class="justify-center">
          <IonCol>
            <h2 className="mt-0 text-center">{fullName}</h2>
            <h4 className="m-0 text-center">Last Contact: {lastContactDate}</h4>
            <h4 className="m-2 text-center">Next Contact: {nextContactDate}</h4>
            {isTristan() ? (
              <h4 className="mt-2 text-center">
                {`Phone: ${defaultPhone?.phoneNumber || 'N/A'}`}
              </h4>
            ) : null}
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol className="text-center">
            <div className="space-x-2">
              <IonButton
                fill="outline"
                color="secondary"
                onClick={exitSequence}
              >
                EXIT
              </IonButton>
              <IonButton color="secondary" onClick={nextSequence}>
                SKIP
              </IonButton>
            </div>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
            {isUnsubscribed ? (
              <IonCard>
                <IonCardContent>
                  <IonText color="dark">
                    <p>{fullName} has unsubscribed from communications.</p>
                  </IonText>
                </IonCardContent>
              </IonCard>
            ) : !hasPhone ? (
              <IonCard>
                <IonCardContent>
                  <IonText color="dark">
                    <p>{fullName} does not have a phone number.</p>
                  </IonText>
                </IonCardContent>
              </IonCard>
            ) : (
              <>
                {isFirstMessage && (
                  <p className="ion-padding-horizontal">
                    <IonText color="yellow">
                      This is your first message with {fullName}.
                    </IonText>
                  </p>
                )}
                {getSmsSequenceStatus() === 'sms form alone' ? (
                  <>
                    <SequenceSmsForm
                      template={template}
                      currentClient={currentClient}
                      onMessageSend={nextSequence}
                    />
                  </>
                ) : getSmsSequenceStatus() === 'sms form with opt in' ? (
                  <>
                    <p className="ion-padding-horizontal">
                      <IonText color="yellow">
                        {fullName} has not yet opted in to communications. Feel
                        free to edit the outgoing opt-in message.
                      </IonText>
                    </p>
                    <SequenceSmsWithOptinForm
                      template={template}
                      currentClient={currentClient}
                      onMessageSend={nextSequence}
                    />
                  </>
                ) : getSmsSequenceStatus() === 'pending opt in' ? (
                  <IonCard>
                    <IonCardContent>
                      <IonText color="dark">
                        <p>{fullName} has a pending opt-in request.</p>
                      </IonText>
                    </IonCardContent>
                  </IonCard>
                ) : null}
              </>
            )}
          </IonCol>
        </IonRow>
      </IonGrid>

      {clientInfo.data ? (
        <ClientDetailFeed
          purchases={clientInfo.data.purchases}
          messages={clientInfo.data.messages}
          notes={clientInfo.data.notes}
          tasks={clientInfo.data.tasks}
        />
      ) : clientInfo.error && !clientInfo.isLoading ? (
        <div className="ion-padding-horizontal ion-padding-bottom flex flex-col items-center justify-center">
          <p>
            There was a problem loading the purchase history for this client.
          </p>
          <IonButton
            color="secondary"
            fill="outline"
            onClick={() => clientInfo.refetch()}
          >
            Try Again
            <IonIcon slot="end" icon={reloadCircleOutline} />
          </IonButton>
        </div>
      ) : (
        <div className="flex justify-center">
          <IonSpinner />
        </div>
      )}
    </>
  )
}

export default SendSequence

import {
  IonButton,
  isPlatform,
  useIonActionSheet,
  useIonRouter,
} from '@ionic/react'
import { FilePicker } from '@capawesome/capacitor-file-picker'

import { updatePost } from '../../social-sharing/posts/post-store'
import { usePhoto } from '../../hooks/use-photo'

import type { PhotoData } from '../../hooks/use-photo'

async function pickVideo() {
  try {
    if (isPlatform('hybrid')) {
      const result = await FilePicker.pickVideos({
        limit: 1,
      })

      return result.files[0]
    } else {
      const result = await FilePicker.pickFiles({
        limit: 1,
        types: ['video/*'],
      })

      return result.files[0]
    }
  } catch (e) {
    console.log({ e })
    const errorMessage = e instanceof Error ? e.message : `Unknown Error, ${e}`
    throw new Error(`There was a problem selecting the video. ${errorMessage}`)
  }
}

function CreatePostButton() {
  const [present] = useIonActionSheet()

  const { selectPhotos, takePhoto } = usePhoto()

  const router = useIonRouter()

  function handlePhoto(p: PhotoData) {
    updatePost({
      media: { type: 'photo', coverPhoto: p },
    })
    router.push('/post/create')
  }

  async function handleSelectVideo() {
    // init store that this is a video post
    updatePost({
      media: { type: 'video', video: { mimeType: '', name: '', size: 0 } },
    })

    router.push('/post/create')

    try {
      const file = await pickVideo()
      updatePost({ media: { type: 'video', video: file } })
    } catch (e) {
      router.canGoBack()
        ? router.goBack()
        : router.push('/tabs/home', 'back', 'pop')
    }
  }

  function openActionSheet() {
    present({
      header: 'Create Post',
      buttons: [
        {
          text: 'Take Photo',
          handler: () => {
            takePhoto((p) => handlePhoto(p))
          },
        },
        {
          text: 'Select Photo',
          handler: () => {
            selectPhotos((p) => handlePhoto(p))
          },
        },
        {
          text: 'Create Collage',
          handler: () => {
            updatePost({ media: { type: 'collage' } })
            router.push('/post/create')
          },
        },
        {
          text: 'Select Video',
          handler: () => {
            handleSelectVideo()
          },
        },
        { text: 'Cancel', role: 'cancel' },
      ],
      cssClass: 'action-sheet',
    })
  }

  return (
    <IonButton size="small" onClick={openActionSheet} color="yellow">
      Create Post
    </IonButton>
  )
}

export default CreatePostButton

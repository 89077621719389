import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { mapValues } from 'remeda'

import type { Nullable } from '../types'

type CheckInMethod =
  | { email: string; phone?: never }
  | { email?: never; phone: string }

type State = {
  checkInMethod: CheckInMethod | null
  clientInfo: Partial<{
    firstName: Nullable<string>
    lastName: Nullable<string>
    email: Nullable<string>
    phoneNumber: Nullable<string>
    dobObject: {
      day: Nullable<number>
      month: Nullable<number>
    }
  }>
}

const defaultClientInfo: State['clientInfo'] = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  dobObject: { day: null, month: null },
}

const defaultState: State = {
  checkInMethod: null,
  clientInfo: defaultClientInfo,
}

type Actions = {
  setCheckInMethod: (method: CheckInMethod | null) => void
  setClientInfo: (client: State['clientInfo']) => void
  resetClientInfo: () => void
}

const devtoolsInProd = process.env.NODE_ENV === 'production'

const useCheckInStore = create(
  devtools(
    immer<State & { actions: Actions }>((set) => ({
      ...defaultState,
      actions: {
        setCheckInMethod: (method) =>
          set((state) => {
            state.checkInMethod = method
          }),
        setClientInfo: (client) => {
          return set((state) => {
            state.clientInfo = client
          })
        },
        resetClientInfo: () => defaultState,
      },
    })),
    { enabled: !devtoolsInProd }
  )
)

const useCheckInMethod = () => useCheckInStore((s) => s.checkInMethod)
const useClientInfo = () => useCheckInStore((s) => s.clientInfo)

const useStringFields = () =>
  useCheckInStore((s) => ({
    firstName: s.clientInfo.firstName,
    lastName: s.clientInfo.lastName,
    email: s.clientInfo.email,
    phoneNumber: s.clientInfo.phoneNumber,
  }))
const useCheckInValues = () => ({
  ...mapValues(useStringFields(), (v) => v ?? ''),
  birthday: {
    day: useClientInfo().dobObject?.day ?? null,
    month: useClientInfo().dobObject?.month ?? null,
  },
})

const useCheckInActions = () => useCheckInStore((s) => s.actions)

export type { CheckInMethod }
export { useCheckInMethod, useCheckInValues, useCheckInActions }

import * as React from 'react'
import {
  IonContent,
  IonHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonText,
} from '@ionic/react'
import { Tab } from '@headlessui/react'

import { formatDistanceFromNow } from '../../../utils/date-helpers'
import { getFullName } from '../../../utils/format-helpers'
import {
  useAdminSharedInbox,
  useAdminAssignedInbox,
  useAdminUnreadSharedInbox,
  useAdminUnreadAssignedInbox,
} from '../../../hooks/chat/queries'
import Avatar from '../../../components/avatar'
import AdminHeader from '../../../components/header/admin-header'
import { Chip } from '../../../components/ui/buttons/chip'
import ScrollingList from '../../../components/ui/scrolling-list'
import type { InboxConversation } from '../../../hooks/chat/queries'
import { cn } from '../../../utils/cn'

const inboxShortcuts = ['All', 'Unread'] as const
const mailboxes = ['Shared Clients', 'Assigned Clients'] as const

function InboxItem({ conversation }: { conversation: InboxConversation }) {
  const client = {
    photo: conversation.clients[0].photo,
    firstName: conversation.clients[0].firstName,
    lastName: conversation.clients[0].lastName,
  }

  return (
    <IonItem
      lines="full"
      key={conversation.objectId}
      routerLink={`/admin/chat/c/${conversation.objectId}`}
    >
      <div slot="start">
        <Avatar user={client} />
      </div>
      <IonLabel
        className={conversation.unread ? 'font-semibold' : 'font-normal'}
      >
        <div>
          {conversation.clients.map((client) => getFullName(client)).join(', ')}
        </div>
        <div
          className={cn(
            'flex',
            conversation.unread ? 'text-white' : 'text-ion-color-tertiary'
          )}
        >
          <span className="overflow-hidden text-ellipsis">
            {conversation.lastMessage?.data
              ? conversation.lastMessage?.data
              : conversation.lastMessage?.attachments
              ? 'Sent a photo'
              : 'Unread Message'}
          </span>
          {conversation.date ? (
            <>
              <span>•</span>
              <span>{formatDistanceFromNow(conversation.date.iso)}</span>
            </>
          ) : null}
        </div>
        {conversation.users ? (
          <div>
            <IonText color="tertiary">
              {conversation.users.map((user) => getFullName(user)).join(', ')}
            </IonText>
          </div>
        ) : null}
      </IonLabel>
    </IonItem>
  )
}

function AdminInbox() {
  const [currentMailbox, setCurrentMailbox] =
    React.useState<typeof mailboxes[number]>('Shared Clients')

  const sharedInboxQuery = useAdminSharedInbox()
  const stylistInboxQuery = useAdminAssignedInbox()
  const unreadSharedInboxQuery = useAdminUnreadSharedInbox()
  const unreadStylistInboxQuery = useAdminUnreadAssignedInbox()

  const hasAssignedInbox = Boolean(stylistInboxQuery.data?.pages[0].data.length)
  const hasSharedInbox = Boolean(sharedInboxQuery.data?.pages[0].data.length)

  const isLoaded = Boolean(
    sharedInboxQuery.data &&
      stylistInboxQuery.data &&
      unreadSharedInboxQuery.data &&
      unreadStylistInboxQuery.data
  )

  React.useEffect(() => {
    // return early if not fully loaded
    // this prevents segment from getting set with one mailbox
    // before both available to determine initial selected segment
    if (!isLoaded) return
    let updatedMailbox: typeof mailboxes[number] = 'Shared Clients'

    if (!hasSharedInbox && hasAssignedInbox) updatedMailbox = 'Assigned Clients'

    setCurrentMailbox(updatedMailbox)
  }, [hasAssignedInbox, hasSharedInbox, isLoaded])

  return (
    <IonPage>
      <IonHeader>
        <AdminHeader title="Admin Inbox" showMenu />
      </IonHeader>
      <IonContent>
        {sharedInboxQuery.data &&
        stylistInboxQuery.data &&
        unreadSharedInboxQuery.data &&
        unreadStylistInboxQuery.data ? (
          <>
            {hasAssignedInbox && hasSharedInbox ? (
              <div className="ion-padding-horizontal ion-padding-top ion-text-uppercase">
                <IonSegment
                  value={currentMailbox}
                  onIonChange={(e) =>
                    setCurrentMailbox(
                      e.detail.value as typeof mailboxes[number]
                    )
                  }
                >
                  {mailboxes.map((mailbox) => (
                    <IonSegmentButton value={mailbox} key={mailbox}>
                      <IonLabel>{mailbox}</IonLabel>
                    </IonSegmentButton>
                  ))}
                </IonSegment>
              </div>
            ) : null}

            <div className="mt-3">
              {currentMailbox === 'Shared Clients' ? (
                <Tab.Group defaultIndex={0}>
                  <Tab.List as={ScrollingList}>
                    {inboxShortcuts.map((shortcut) => (
                      <Tab as={React.Fragment} key={shortcut}>
                        {({ selected }) => (
                          <Chip fill={selected ? 'solid' : 'outline'}>
                            {shortcut}
                          </Chip>
                        )}
                      </Tab>
                    ))}
                  </Tab.List>
                  <Tab.Panels>
                    <Tab.Panel>
                      <IonList>
                        {sharedInboxQuery.data.pages.map((inboxPage, i) => (
                          <React.Fragment key={i}>
                            {inboxPage.data.map((conversation) => (
                              <InboxItem
                                key={conversation.objectId}
                                conversation={conversation}
                              />
                            ))}
                          </React.Fragment>
                        ))}
                      </IonList>
                      <IonInfiniteScroll
                        ref={sharedInboxQuery.ionInfiniteScrollRef}
                        onIonInfinite={sharedInboxQuery.loadMore}
                        threshold="100px"
                        disabled={!sharedInboxQuery.hasNextPage}
                      >
                        <IonInfiniteScrollContent
                          loadingSpinner="bubbles"
                          loadingText="loading more conversations..."
                        />
                      </IonInfiniteScroll>
                    </Tab.Panel>
                    <Tab.Panel>
                      <IonList>
                        {unreadSharedInboxQuery.data.pages.map(
                          (unreadInboxPage, i) => (
                            <React.Fragment key={i}>
                              {unreadInboxPage.data.map((conversation) => (
                                <InboxItem
                                  key={conversation.objectId}
                                  conversation={conversation}
                                />
                              ))}
                            </React.Fragment>
                          )
                        )}
                      </IonList>
                      <IonInfiniteScroll
                        ref={unreadSharedInboxQuery.ionInfiniteScrollRef}
                        onIonInfinite={unreadSharedInboxQuery.loadMore}
                        threshold="100px"
                        disabled={!unreadSharedInboxQuery.hasNextPage}
                      >
                        <IonInfiniteScrollContent
                          loadingSpinner="bubbles"
                          loadingText="loading more conversations..."
                        />
                      </IonInfiniteScroll>
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              ) : null}

              {currentMailbox === 'Assigned Clients' ? (
                <Tab.Group defaultIndex={0}>
                  <Tab.List as={ScrollingList}>
                    {inboxShortcuts.map((shortcut) => (
                      <Tab as={React.Fragment} key={shortcut}>
                        {({ selected }) => (
                          <Chip fill={selected ? 'solid' : 'outline'}>
                            {shortcut}
                          </Chip>
                        )}
                      </Tab>
                    ))}
                  </Tab.List>
                  <Tab.Panels>
                    <Tab.Panel>
                      <IonList>
                        {stylistInboxQuery.data.pages.map((inboxPage, i) => (
                          <React.Fragment key={i}>
                            {inboxPage.data.map((conversation) => (
                              <InboxItem
                                key={conversation.objectId}
                                conversation={conversation}
                              />
                            ))}
                          </React.Fragment>
                        ))}
                      </IonList>
                      <IonInfiniteScroll
                        ref={stylistInboxQuery.ionInfiniteScrollRef}
                        onIonInfinite={stylistInboxQuery.loadMore}
                        threshold="100px"
                        disabled={!stylistInboxQuery.hasNextPage}
                      >
                        <IonInfiniteScrollContent
                          loadingSpinner="bubbles"
                          loadingText="loading more conversations..."
                        />
                      </IonInfiniteScroll>
                    </Tab.Panel>
                    <Tab.Panel>
                      <IonList>
                        {unreadStylistInboxQuery.data.pages.map(
                          (unreadInboxPage, i) => (
                            <React.Fragment key={i}>
                              {unreadInboxPage.data.map((conversation) => (
                                <InboxItem
                                  key={conversation.objectId}
                                  conversation={conversation}
                                />
                              ))}
                            </React.Fragment>
                          )
                        )}
                      </IonList>
                      <IonInfiniteScroll
                        ref={unreadStylistInboxQuery.ionInfiniteScrollRef}
                        onIonInfinite={unreadStylistInboxQuery.loadMore}
                        threshold="100px"
                        disabled={!unreadStylistInboxQuery.hasNextPage}
                      >
                        <IonInfiniteScrollContent
                          loadingSpinner="bubbles"
                          loadingText="loading more conversations..."
                        />
                      </IonInfiniteScroll>
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              ) : null}
            </div>
          </>
        ) : (
          <IonLoading isOpen message="Loading your inbox..." />
        )}
      </IonContent>
    </IonPage>
  )
}

export default AdminInbox

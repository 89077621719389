import { useState, useEffect } from 'react'
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonHeader,
} from '@ionic/react'
import { subMonths as subtractMonths } from 'date-fns'

import { authService } from '../services/authService'
import { searchService } from '../services/searchService'
import Header from '../components/header'
import DashboardCard from '../components/card/DashboardCard'
import TopClientCard from '../components/card/TopClientCard'
import RoiCard from '../components/roi/RoiCard'
import Currency from '../components/Currency'
import { DateSelect } from '../stats/components/date-select'
import { RecapCard } from '../stats/components/recap-card'

function StatsPage() {
  const today = new Date()

  const [userStatData, setUserStatData] = useState({})
  const [userOutReachData, setUserOutReachData] = useState({})
  const [userTopClientsData, setuserTopClientsData] = useState([])
  const [roiByTypeData, setRoiByTypeData] = useState({})
  const [roiByTemplateData, setRoiByTemplateData] = useState({})

  const [startDate, setStartDate] = useState(
    subtractMonths(today, 1).toISOString()
  )
  const [endDate, setEndDate] = useState(today.toISOString())

  useEffect(() => {
    searchService
      .getUserStats({ userId: authService.getUserId(), startDate, endDate })
      .then(
        (result) => {
          setUserStatData(result)
        },
        (error) => {
          console.log('getUserStats error: ', error)
        }
      )

    searchService
      .getUserOutReach({ userId: authService.getUserId(), startDate, endDate })
      .then(
        (result) => {
          setUserOutReachData(result)
        },
        (error) => {
          console.log('getUserOutReach error: ', error)
        }
      )

    searchService
      .getUserTopClients({
        userId: authService.getUserId(),
        startDate,
        endDate,
      })
      .then(
        (result) => {
          setuserTopClientsData(result)
        },
        (error) => {
          console.log('getUserTopClients error: ', error)
        }
      )

    searchService
      .getRoiByType({ userId: authService.getUserId(), startDate, endDate })
      .then(
        (result) => {
          setRoiByTypeData(result)
        },
        (error) => {
          console.log('getRoiByType error: ', error)
        }
      )

    searchService
      .getRoiByTemplate({ userId: authService.getUserId(), startDate, endDate })
      .then(
        (result) => {
          setRoiByTemplateData(result)
        },
        (error) => {
          console.log('getRoiByTemplate error: ', error)
        }
      )
  }, [startDate, endDate])

  return (
    <IonPage>
      <IonHeader>
        <Header showMenu title="Stats" />
      </IonHeader>
      <IonContent>
        <IonGrid className="ion-no-padding ion-no-margin">
          <IonRow className="ion-no-padding ion-no-margin">
            <IonCol
              sizeXs="12"
              sizeLg="12"
              sizeXl="6"
              className="ion-no-padding ion-no-margin"
            >
              <IonRow>
                <IonCol>
                  <RecapCard
                    outreach1={userOutReachData.outreach1}
                    outreach7={userOutReachData.outreach7}
                    outreach30={userOutReachData.outreach30}
                  />
                </IonCol>
              </IonRow>
              <DateSelect
                showSm
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
              <IonRow>
                <IonCol>
                  <DashboardCard
                    title="SALES"
                    value={<Currency value={userStatData.salesAll} />}
                    percentChange={userStatData.salesAllChange}
                    isRed={userStatData.salesAllChange < 0}
                  />
                </IonCol>
                <IonCol>
                  <DashboardCard
                    title="CLIENTS"
                    value={userStatData.clientsAll || 0}
                    percentChange={userStatData.clientsAllChange}
                    isRed={userStatData.clientsAllChange < 0}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <DashboardCard
                    title="OUTREACH"
                    value={userOutReachData.outreachAll || 0}
                    percentChange={userOutReachData.outreachAllChange}
                    isRed={userOutReachData.outreachAllChange < 0}
                    hideChange={true}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <RoiCard
                    data={roiByTypeData}
                    title="OneShop Sales"
                    subtitle="OneShop Sales After Outreach"
                    showAll={true}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <RoiCard
                    data={roiByTemplateData}
                    title="Template Sales"
                    subtitle="OneShop Sales After Outreach Using Templates"
                  />
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol
              sizeXs="12"
              sizeLg="12"
              sizeXl="6"
              className="ion-no-padding ion-no-margin"
            >
              <DateSelect
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
              <TopClientCard list={userTopClientsData || []} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export { StatsPage }

import * as React from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { getDefaultPhoneNumber } from '../../utils/client-helpers'
import { composeSearchFilters } from '../../utils/compose-search-filters'
import groupPurchases from '../../utils/group-purchases'
import { getLastPurchase } from '../../utils/sales'
import { client } from '../../services/client'
import { useUser } from '../../contexts/authContext'
import { useToast } from '../../contexts/toastContext'
import { useClientFiltersActions } from '../../clients/client-filters-store'

import type { E164Number } from 'libphonenumber-js'
import type {
  DateObject,
  IsoDate,
  Note,
  ProfileBase,
} from '../../types/general'
import type { FiltersForm } from '../../clients/client-filters.schema'
import type { Tag } from '../tags/queries'
import type { Product } from '../../types/shopwith'
import type { QueryContextFromKeys } from '../../utils/react-query'
import type { Sale } from '../../types/client'
import type { Message } from '../chat/queries'
import type { Template } from '../templates/queries'
import type { TaskOverview } from '../tasks/queries'

type FetchClientsResponse = { result: Array<Client> }
type FetchMyClientsResponse = { result: Array<Omit<Client, 'users'>> }
type FetchAdminClientsResponse = { result: Array<AdminClient> }
type FetchClientDetailResponse = {
  result: ClientDetail
}
type FetchSubscriberCountsResult = {
  result: { smsSubscribers: number; emailSubscribers: number }
}

type FetchClientSearchResponse = {
  result: {
    clients: Array<SearchClient>
    filter: SearchFilter
  }
}

type ClientBase = {
  objectId: string
  email?: string | null
  firstName?: string | null
  lastContact?: IsoDate | null
  lastName?: string | null
  nextContact: IsoDate | null // not on explore list?
  tags: Array<Tag>
}

type Client = ClientBase & {
  allowTwilioSms: boolean
  firstSale?: IsoDate
  lastSale?: IsoDate
  phoneNumbers: Array<PhoneNumber>
  photo: string | null
  posActive?: boolean
  salesTotal?: number
  unsubscribedAt?: IsoDate
  updatedAt: IsoDate
  users?: Array<{ objectId: string }>
}

type AdminClient = Client & {
  isSelected?: boolean
  lastSaleAmt: number
  assignedAt: IsoDate
  assignedBy: Omit<ProfileBase, 'objectId'>
  posSellerNames: Array<string>
  filterSalesTotal: number
}

type ClientDetail = ClientBase & {
  allowSms: boolean
  birthday: number | null
  dobObject: DateObject | null
  emailTemplates: Array<NonNullable<Template['emailTemplate']>>
  firstSaleDate: IsoDate | null
  followUps: Array<TaskOverview>
  hasAddress: boolean | null
  lastSaleDate: IsoDate | null
  letterTemplates: Array<NonNullable<Template['letterTemplate']>>
  letters: Array<Letter>
  likes: Array<Like>
  messages: Array<Message>
  note: string | null
  notes: Array<Note>
  phoneNumbers: Array<PhoneNumber>
  photo: string | null
  posActive?: boolean
  posLanguage: string
  posNote1: string | null
  posNote2: string | null
  sales: Array<Sale>
  sales12Months: number
  sales24Months: number
  sales30Days: number
  smsTemplates: Array<NonNullable<Template['smsTemplate']>>
  unsubscribedAt: IsoDate | null
  users: Array<ProfileBase> | null
}

type SelectClientDetail = ReturnType<typeof useClientDetail>['data']

type OptInState = 'NOT_INITIALIZED' | 'PENDING' | 'OPTED_IN' | 'OPTED_OUT'

type PhoneNumber = {
  objectId: string
  default: boolean
  e164: E164Number | null
  optInState: OptInState
  phoneNumber: string
  posName: typeof posNameOrder[number] | null
  type: string | null
  verified: boolean
}

type Like = {
  objectId: string
  like: boolean
  product: Product
}

type Letter = {
  objectId: string
  body: string
  firstName: string | null
  lastName: string | null
  type: 'letter'
}

type SearchClient = Pick<
  ClientBase,
  'objectId' | 'firstName' | 'lastName' | 'email'
> & {
  displayName: string
  birthday: string | null
  dobObject: DateObject
  isMine?: boolean
  phoneNumbers: Array<PhoneNumber>
  posActive: boolean
  posLanguage: 'en' | 'fr' | null
  unsubscribedAt?: IsoDate
}

type SearchFilter = {
  list: 'mine'
  name: string
}

type ClientQueryContext = QueryContextFromKeys<typeof clientQueryKeys>

const clientQueryKeys = {
  all: [{ entity: 'clients' }] as const,
  lists: () => [{ ...clientQueryKeys.all[0], scope: 'list' }] as const,
  list: ({
    filters,
    type,
  }: {
    filters?: FiltersForm
    type: 'admin' | 'mine' | 'subscribers'
  }) => [{ ...clientQueryKeys.lists()[0], filters, type }] as const,
  search: (name: string, list: 'all' | 'mine' = 'mine') =>
    [{ ...clientQueryKeys.lists()[0], type: 'search', name, list }] as const,
  details: () => [{ ...clientQueryKeys.all[0], scope: 'detail' }] as const,
  basic: (id: string | null) =>
    [{ ...clientQueryKeys.details()[0], detailType: 'basic', id }] as const,
  detail: (id: string) => [{ ...clientQueryKeys.details()[0], id }] as const,
  subscribers: () =>
    [{ ...clientQueryKeys.lists()[0], type: 'subscribers' }] as const,
}

const posNameOrder = [
  'Sign Up',
  'Cell',
  'Mobile',
  'Primary Phone',
  'Mobile 1',
  'Mobile 2',
  'Phone',
  'Phone 1',
  'phone1',
  'Phone 2',
  'Phone 3',
  'Phone 4',
  'Address Phone',
  'Work Phone',
  'Home Phone',
  'Unknown',
  undefined,
] as const

async function fetchAdminClients({
  queryKey: [{ filters }],
}: ClientQueryContext['list']) {
  const filter = composeSearchFilters(filters)

  const params = {
    filter: { admin: true, ...filter },
  }

  const response: FetchAdminClientsResponse = await client
    .post('functions/clients', {
      json: params ? params : undefined,
    })
    .json()

  return response.result
}

async function fetchAllSubscribedClients({
  queryKey: [{ filters }],
}: ClientQueryContext['list']) {
  const filter = composeSearchFilters(filters)

  const params = {
    filter: { admin: true, subscribersOnly: true, ...filter },
  }

  const response: FetchClientsResponse = await client
    .post('functions/clients', {
      json: params ? params : undefined,
      timeout: 180000,
    })
    .json()

  return response.result
}

async function fetchBasicDetail(id: string | null) {
  if (!id)
    throw new Error('You must provide a client id to retrieve client detail.')

  const response: { result: { client: ClientBase } } = await client
    .post('functions/getClient', {
      json: {
        objectId: id,
      },
    })
    .json()

  return response.result
}
async function fetchClients({
  queryKey: [{ filters }],
}: ClientQueryContext['list']) {
  const filter = composeSearchFilters(filters)

  const response: FetchClientsResponse = await client
    .post('functions/clients', {
      json: { filter },
    })
    .json()

  return response.result
}

async function fetchClientDetail(id: string) {
  const response: FetchClientDetailResponse = await client
    .post('functions/page_client_id', {
      json: { objectId: id },
    })
    .json()

  return response.result
}

async function fetchMyClients() {
  const response: FetchMyClientsResponse = await client
    .post('functions/getMyClients')
    .json()

  return response.result
}

async function fetchSubscriberCounts() {
  const response: FetchSubscriberCountsResult = await client
    .post('functions/getSubscriberCounts')
    .json()

  return response.result
}

async function searchByName(name: string, admin: boolean) {
  const response: any = await client
    .post('functions/getClientsSearch', {
      json: {
        filter: { name, admin },
      },
    })
    .json()

  return response.result.clients
}

async function searchClientList({
  queryKey: [{ name, list }],
}: ClientQueryContext['search']) {
  const response: FetchClientSearchResponse = await client
    .post('functions/searchClients', {
      json: {
        filter: { name, list: list === 'mine' ? list : undefined },
      },
    })
    .json()

  return response.result.clients
}

function selectClientDetail(data: ClientDetail) {
  const purchases = groupPurchases(data.sales)
  const lastPurchase = getLastPurchase(purchases[0])
  const closet = data.sales.filter(
    (purchase) =>
      purchase.posPhotoUrls.length || purchase.photos.length || purchase.photo
  )
  const posNotes: Array<string> = []
  data.note && posNotes.push(data.note)
  data.posNote1 && posNotes.push(data.posNote1)
  data.posNote2 && posNotes.push(data.posNote2)

  return {
    ClientDetailsData: {
      lastPurchase,
      ...data,
    },
    purchases,
    notes: data.notes,
    messages: [...data.messages, ...data.letters],
    tasks: data.followUps,
    closet,
    posNotes,
    likes: data.likes,
  }
}

function useAllClients(filters: FiltersForm) {
  const setToast = useToast()

  return useQuery({
    queryKey: clientQueryKeys.list({ filters, type: 'admin' }),
    queryFn: fetchAdminClients,
    enabled: !!Object.keys(filters).length,
    retry: false,
    onError(error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown Error.'

      setToast({
        message: `Something went wrong while loading your client list: ${errorMessage}.`,
        color: 'danger',
      })
    },
  })
}

/* DEPRECATED
function useAllOutreachClients(
  filters: FiltersForm,
  {
    enabled,
    onSuccess,
  }: { enabled?: boolean; onSuccess?: (data: Array<Client>) => void } = {
    enabled: false,
  }
) {
  const setToast = useToast()

  return useQuery({
    queryKey: clientQueryKeys.list({ filters, type: 'admin' }),
    queryFn: fetchAdminClients,
    enabled,
    retry: false,
    onSuccess,
    onError(error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown Error.'

      setToast({
        message: `Something went wrong while fetching your client list: ${errorMessage}.`,
        color: 'danger',
      })
    },
  })
}*/

function useAllSubscribedClients(filters: FiltersForm) {
  const setToast = useToast()

  return useQuery({
    queryKey: clientQueryKeys.list({ filters, type: 'admin' }),
    queryFn: fetchAllSubscribedClients,
    retry: false,
    onError(error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown Error.'

      setToast({
        message: `Something went wrong while fetching your client list: ${errorMessage}.`,
        color: 'danger',
      })
    },
  })
}

function useBasicClient<T = { client: ClientBase }>(
  id: string | null,
  { select }: { select?: (data: { client: ClientBase }) => T } = {}
) {
  return useQuery({
    queryKey: clientQueryKeys.basic(id),
    queryFn: () => fetchBasicDetail(id),
    select,
  })
}

const useClientContactInfo = (id: string) =>
  useClientDetailBase(id, {
    select: (data) => ({
      objectId: data.objectId,
      phoneNumbers: data.phoneNumbers,
      defaultPhoneNumber: getDefaultPhoneNumber(data.phoneNumbers),
      unsubscribedAt: data.unsubscribedAt,
      email: data.email,
      posActive: data.posActive,
    }),
  })

const useClientDetail = (id: string) =>
  useClientDetailBase(id, { select: selectClientDetail })

function useClientDetailBase<T extends any = ClientDetail>(
  id: string,
  { select }: { select?: (data: ClientDetail) => T } = {}
) {
  const setToast = useToast()

  return useQuery({
    queryKey: clientQueryKeys.detail(id),
    queryFn: () => fetchClientDetail(id),
    select,
    onError(error) {
      if (!error) return

      setToast({
        message: `Something went wrong while fetching the client detail ${
          error instanceof Error && `: ${error.message}`
        }.`,
        color: 'danger',
      })
    },
  })
}

function useClientSearch(
  searchString: string,
  { enabled, canSearchAll }: { enabled: boolean; canSearchAll?: boolean } = {
    enabled: true,
    canSearchAll: false,
  }
) {
  const setToast = useToast()

  return useQuery({
    queryKey: clientQueryKeys.search(
      searchString,
      canSearchAll ? 'all' : 'mine'
    ),
    queryFn: searchClientList,
    staleTime: Infinity,
    onError(error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown Error.'

      setToast({
        message: `Something went wrong while fetching the client: ${errorMessage}.`,
        color: 'danger',
      })
    },
    enabled,
  })
}

function useEmailSubscriberCount() {
  return useSubscribersCount((data) => data.emailSubscribers.toString())
}

function useMyClients<T = FetchMyClientsResponse['result']>(
  select?: (data: FetchMyClientsResponse['result']) => T
) {
  return useQuery({
    queryKey: clientQueryKeys.list({ type: 'mine' }),
    queryFn: fetchMyClients,
    select,
  })
}

function usePrefetchClient(id: string) {
  const queryClient = useQueryClient()

  React.useEffect(() => {
    if (!id) return

    queryClient.prefetchQuery(clientQueryKeys.detail(id), () =>
      fetchClientDetail(id)
    )
  }, [id, queryClient])
}

function usePullClientList(
  filters: FiltersForm,
  { enabled = false }: { enabled?: boolean }
) {
  const setToast = useToast()

  const { setClientFilters } = useClientFiltersActions()

  return useQuery({
    queryKey: clientQueryKeys.list({ filters, type: 'mine' }),
    queryFn: fetchClients,
    enabled,
    onSuccess: () => {
      setClientFilters(filters)
    },
    onError: (error) => {
      if (error instanceof Error) {
        return setToast({
          message: `Something went wrong while fetching the client list: ${error.message}.`,
          color: 'danger',
        })
      }
      setToast({
        message: `Something went wrong while fetching the client list.`,
        color: 'danger',
      })
    },
  })
}

function useSearchByName({
  name,
  isAdmin,
}: {
  name: string
  isAdmin: boolean
}) {
  return useQuery({
    queryKey: ['s', { name }],
    queryFn: () => searchByName(name, isAdmin),
    enabled: Boolean(name),
  })
}

function useSmsSubscriberCount() {
  return useSubscribersCount((data) => data.smsSubscribers.toString())
}

function useSubscribersCount(
  select: (data: FetchSubscriberCountsResult['result']) => string
) {
  const user = useUser()

  return useQuery({
    queryKey: clientQueryKeys.subscribers(),
    queryFn: fetchSubscriberCounts,
    refetchInterval: 3600000, // 1 hour
    enabled: user.hasTwilio || user.hasSendGrid,
    select,
  })
}

export type {
  AdminClient,
  Client,
  ClientDetail,
  Like,
  OptInState,
  PhoneNumber,
  SearchClient,
  SelectClientDetail,
}
export {
  clientQueryKeys,
  useAllClients,
  useAllSubscribedClients,
  useBasicClient,
  useClientContactInfo,
  useClientDetail,
  useClientSearch,
  useEmailSubscriberCount,
  useMyClients,
  usePrefetchClient,
  usePullClientList,
  useSearchByName,
  useSmsSubscriberCount,
}

import * as React from 'react'
import {
  IonContent,
  IonButton,
  IonRow,
  IonPage,
  IonIcon,
  IonGrid,
  IonCol,
  IonHeader,
  IonModal,
  useIonRouter,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from '@ionic/react'
import { filter } from 'ionicons/icons'

import { useFormatNumber } from '../../hooks/use-format-number'
import { usePullClientList } from '../../hooks/clients/queries'
import { useInfiniteList } from '../../hooks/use-infinite-list'
import Header from '../../components/header'
import AddFab from '../../components/fab/add-fab'
import ClientItem, {
  ClientItemSkeleton,
} from '../../components/client/client-item'
import BulkMessageButton from '../../components/ui/buttons/bulk-message-button'
import PullClientsModal from '../../components/client/pull-clients-modal'

import type { FiltersForm } from '../../clients/client-filters.schema'
import { composeSearchFilters } from '../../utils/compose-search-filters'

function CreateClientList() {
  const hasPulledListRef = React.useRef(false)

  const [searchIsOpen, setSearchIsOpen] = React.useState(true)
  const [filters, setFilters] = React.useState<FiltersForm>({})

  const router = useIonRouter()

  const pullClientListQuery = usePullClientList(filters, {
    enabled: Boolean(Object.keys(filters).length),
  })

  const { infiniteListCount, doInfiniteStuff } = useInfiniteList()
  const formatNumber = useFormatNumber()

  const visibleClients = pullClientListQuery.data ?? []

  function handleSearch(searchFilters: FiltersForm) {
    if (!hasPulledListRef.current) {
      hasPulledListRef.current = true
    }

    setFilters(searchFilters)

    setSearchIsOpen(false)
  }

  return (
    <IonPage>
      <AddFab />
      <IonHeader>
        <Header backRef="/tabs/clients" title="Filter Client List" />
      </IonHeader>
      <IonContent>
        <div className="ion-margin-top">
          <IonGrid
            style={{ '--ion-grid-padding': 0 }}
            className="ion-padding-horizontal"
          >
            <IonRow className="ion-nowrap ion-align-items-center">
              <IonCol className="flex-full">
                <div className="flex items-center gap-x-2">
                  <BulkMessageButton
                    clientList={visibleClients}
                    filter={composeSearchFilters(filters)}
                  />
                  <h2 className="text-ion-color-yellow ml-2">
                    {formatNumber(visibleClients.length)} Client
                    {visibleClients.length === 1 ? '' : 's'}
                  </h2>
                </div>
              </IonCol>
              <IonCol>
                <IonButton
                  disabled={pullClientListQuery.isInitialLoading}
                  onClick={() => setSearchIsOpen(true)}
                  size="small"
                  color="secondary"
                >
                  Filter
                  <IonIcon slot="end" icon={filter} />
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>

        {pullClientListQuery.isInitialLoading ? (
          Array(10)
            .fill(null)
            .map((_item, idx) => <ClientItemSkeleton key={idx} />)
        ) : (
          <>
            {visibleClients.slice(0, infiniteListCount).map((client) => (
              <ClientItem key={client.objectId} client={client} />
            ))}
            <IonInfiniteScroll
              onIonInfinite={doInfiniteStuff}
              disabled={infiniteListCount >= visibleClients.length}
            >
              <IonInfiniteScrollContent loadingSpinner="bubbles" />
            </IonInfiniteScroll>
          </>
        )}
      </IonContent>

      <IonModal
        isOpen={searchIsOpen}
        onDidDismiss={() => setSearchIsOpen(false)}
      >
        <PullClientsModal
          filters={filters}
          onSearch={handleSearch}
          onCancel={() => {
            setSearchIsOpen(false)
            if (!Boolean(hasPulledListRef.current)) {
              router.goBack()
            }
          }}
        />
      </IonModal>
    </IonPage>
  )
}

export default CreateClientList

import * as React from 'react'
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
  IonDatetime,
} from '@ionic/react'
import { formatISO } from 'date-fns'

function ScheduleModal({
  onDismiss,
}: {
  onDismiss: (data?: string | null, role?: string) => void
}) {
  const [scheduleDate, setScheduleDate] = React.useState('')

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              color="secondary"
              onClick={() => onDismiss(null, 'cancel')}
            >
              Cancel
            </IonButton>
          </IonButtons>

          <IonButtons slot="end">
            <IonButton
              color="yellow"
              disabled={!scheduleDate}
              onClick={() => {
                onDismiss(scheduleDate, 'confirm')
              }}
            >
              Confirm
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding space-y-3">
        <h1 className="text-center text-xl">Schedule and Approve</h1>
        <p>
          This will be approved and scheduled when you confirm. If this not what
          you wish to do, click Cancel.
        </p>
        <IonDatetime
          style={{
            '--background': 'var(--ion-color-primary)',
            '--background-rgb': '0, 0, 0',
          }}
          value={scheduleDate && formatISO(new Date(scheduleDate))} // convert to date and reformat as ISO otherwise day after is highlighted (related to Z instead of offset hours??)
          color="yellow"
          presentation="date-time"
          min={formatISO(Date.now())}
          onIonChange={(e) => {
            typeof e.detail.value === 'string' &&
              setScheduleDate(e.detail.value)
          }}
        ></IonDatetime>
      </IonContent>
    </IonPage>
  )
}

export { ScheduleModal }

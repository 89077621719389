import { useQuery, useQueryClient } from '@tanstack/react-query'

import { client } from '../../services/client'
import { useUser } from '../../contexts/authContext'
import type { QueryContextFromKeys } from '../../utils/react-query'
import type { Group, IsoDate, ProfileBase } from '../../types/general'
import type { PhotoData } from '../use-photo'

export type TemplateType = 'general'

type FetchTemplatesResponse = {
  result: {
    templates: Array<Template>
  }
}

type FetchTemplateResponse = {
  result: {
    template: Template
  }
}
export type Template = UserTemplate | StoreTemplate

type UserTemplate = TemplateBase & {
  user: ProfileBase
  groups: null
}

type StoreTemplate = TemplateBase & {
  user: ProfileBase | null
  groups: Array<Group> | null
}

export type SMSTemplate = {
  objectId: string
  name: string
  body: string | null
  attachments: Array<string> | null
} & Partial<ShopWithPointers>

export type EmailTemplate = {
  objectId: string
  name: string
  subject: string | null
  body: string | null
  attachments: Array<string> | null
} & Partial<ShopWithPointers>

export type LetterTemplate = {
  objectId: string
  name: string
  body: string | null
} & Partial<ShopWithPointers>

type TemplateBase = {
  objectId: string
  name: string | null
  description: string | null
  type: TemplateType | null
  createdAt: IsoDate
  smsTemplate?: SMSTemplate
  emailTemplate?: EmailTemplate
  letterTemplate?: LetterTemplate
} & Partial<ShopWithPointers>

export type TemplateAttachments = Array<PhotoData> | Array<string> | null

export type ShopWithPointers = {
  products?: Array<{ objectId: string }>
  looks?: Array<{ objectId: string }>
  collections?: Array<{ objectId: string }>
}

type TemplateQueryContexts = QueryContextFromKeys<typeof templateQueryKeys>

export const templateQueryKeys = {
  all: [{ entity: 'templates' }] as const,
  lists: () => [{ ...templateQueryKeys.all[0], scope: 'list' }] as const,
  list: (type: 'all') => [{ ...templateQueryKeys.lists()[0], type }] as const,
  details: () => [{ ...templateQueryKeys.all[0], scope: 'detail' }] as const,
  detail: (id: string) => [{ ...templateQueryKeys.details()[0], id }] as const,
}

async function fetchTemplates() {
  const respone: FetchTemplatesResponse = await client
    .post('functions/v2_getTemplates')
    .json()

  return respone.result.templates
}

async function fetchTemplate({
  queryKey: [{ id }],
}: TemplateQueryContexts['detail']) {
  const respone: FetchTemplateResponse = await client
    .post('functions/v2_getTemplate', { json: { objectId: id } })
    .json()

  return respone.result.template
}

function selectMine(data: Array<Template>, id: string) {
  return data.filter(
    (template) => template.user?.objectId === id || Boolean(template.groups)
  )
}

function selectStoreTemplates(data: Array<Template>) {
  return data.filter((template) => template.groups)
}

function useTemplates<T = Array<Template>>(
  select?: (data: Array<Template>) => T
) {
  return useQuery({
    queryKey: templateQueryKeys.list('all'),
    queryFn: fetchTemplates,
    select,
  })
}

function useMyTemplates() {
  const user = useUser()
  const { objectId } = user

  return useTemplates((data) => selectMine(data, objectId))
}

function useStoreTemplates() {
  return useTemplates(selectStoreTemplates)
}

function useTemplateDetail(id: string) {
  const queryClient = useQueryClient()

  return useQuery({
    queryKey: templateQueryKeys.detail(id),
    queryFn: fetchTemplate,
    initialData: () =>
      queryClient
        .getQueryData<Array<Template>>(templateQueryKeys.list('all'))
        ?.find((template) => template.objectId === id),
    initialDataUpdatedAt: () =>
      queryClient.getQueryState<Array<Template>>(templateQueryKeys.list('all'))
        ?.dataUpdatedAt,
  })
}

export { useTemplates, useTemplateDetail, useMyTemplates, useStoreTemplates }

import { IonButton, IonCheckbox, IonItem, IonLabel } from '@ionic/react'
import { useForm, Controller } from 'react-hook-form'

import { IsoDate } from '../../types/general'
import { useUpdateClientSubscriptionStatus } from '../../hooks/clients/mutations'

type Props = {
  clientId: string
  unsubscribedAt: IsoDate | null
  onCancel: () => void
  onSave: () => void
}

type FormState = {
  unsubscribe: boolean
}

function SubscriptionEditForm({
  clientId,
  unsubscribedAt,
  onCancel,
  onSave,
}: Props) {
  const defaultValues = {
    unsubscribe: Boolean(unsubscribedAt),
  }
  const { control, handleSubmit } = useForm<FormState>({ defaultValues })
  const updateClientSubscriptionStatus = useUpdateClientSubscriptionStatus()

  function handleSave(data: FormState) {
    updateClientSubscriptionStatus.mutate({
      clientId,
      unsubscribe: data.unsubscribe,
    })
    onSave()
  }

  return (
    <section>
      <IonItem lines="none" color="secondary">
        <IonLabel color="primary">Do Not Contact: </IonLabel>
        <Controller
          name="unsubscribe"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <IonCheckbox
                color="primary"
                onIonChange={(e) => onChange(e.detail.checked)}
                checked={value}
              />
            )
          }}
        />
      </IonItem>
      <div className="flex justify-between">
        <IonButton
          disabled={updateClientSubscriptionStatus.isLoading}
          color="primary"
          fill="outline"
          size="small"
          onClick={onCancel}
        >
          Cancel
        </IonButton>
        <IonButton
          disabled={updateClientSubscriptionStatus.isLoading}
          color="yellow"
          size="small"
          onClick={handleSubmit(handleSave)}
        >
          Save
        </IonButton>
      </div>
    </section>
  )
}

export default SubscriptionEditForm

import { z } from 'zod'

import {
  posLoyaltySchema,
  optInStatusSchema,
} from '../../loyalty/loyalty.schema'
import { profileBaseSchema } from '../../schema/profile.schema'
import { nullableString } from '../../utils/schema'

type HeartlandLoyaltyParams = z.infer<typeof heartlandLoyaltyParamsSchema>
type HeartlandLoyalty = z.infer<typeof heartlandLoyaltySchema>

const heartlandLoyaltyParamsSchema = z.object({
  clientId: nullableString,
  groupId: z.string(),
  subdomain: nullableString,
  ticketId: nullableString,
})

const heartlandClientSchema = z.object({
  show: z.boolean(),
  client: profileBaseSchema,
  optInStatus: optInStatusSchema,
  hasPhoneNumber: z.boolean(),
})

const heartlandLoyaltyBaseSchema = posLoyaltySchema.extend({
  ticket: z.object({
    id: z.number(),
    lines: z.array(z.object({ id: z.number() })),
  }),
})

const heartlandLoyaltySchema = heartlandLoyaltyBaseSchema.transform(
  (schema) => ({
    ...schema,
    loyaltyType: 'heartland' as const,
  })
)

export type { HeartlandLoyalty, HeartlandLoyaltyParams }
export { heartlandClientSchema, heartlandLoyaltySchema }

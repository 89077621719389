import React, { useEffect, useState } from 'react'
import {
  IonPage,
  IonContent,
  IonLoading,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonHeader,
} from '@ionic/react'

import { authService } from '../services/authService'
import { searchService } from '../services/searchService'
import Header from '../components/header'

const AuthCallback = ({ history }) => {
  const [showLoading, setShowLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    const params = new URLSearchParams(history.location.search)
    const paramState = params.get('state')
    const paramCode = params.get('code')
    const { authState, groupId, integration } = authService.getOAuthParams()

    if (
      integration &&
      paramCode &&
      groupId &&
      (integration === 'lightspeed' ||
        integration === 'mailchimp' ||
        (paramState && authState && paramState === authState))
    ) {
      searchService
        .oathAuthorize({
          groupId,
          integration,
          url: history.location.pathname + history.location.search,
          code: paramCode,
        })
        .then(
          () => {
            setErrorMessage('')
            setShowLoading(false)
          },
          (error) => {
            setShowLoading(false)
            if (error.error) {
              setErrorMessage(error.error)
            } else {
              setErrorMessage('something went wrong')
            }
          }
        )
    } else {
      setShowLoading(false)
      setErrorMessage('something went wrong')
    }
  }, [history, history.location.search])

  return (
    <IonPage>
      <IonHeader>
        <Header title="Account" showMenu />
      </IonHeader>
      <IonContent>
        <IonGrid style={{ height: '100%' }}>
          {!showLoading && (
            <IonRow
              class="ion-align-items-center"
              style={{ height: '100%', marginTop: 0 }}
            >
              <IonCol style={{ textAlign: 'center' }}>
                {errorMessage && (
                  <IonRow className="ion-justify-content-center">
                    <h2>
                      We ran into an issue {errorMessage} please contact support{' '}
                      <a
                        style={{ color: 'white', textDecoration: 'underline' }}
                        href="mailto:support@oneshopretail.com"
                      >
                        {' '}
                        support@oneshopretail.com
                      </a>
                    </h2>
                  </IonRow>
                )}
                {!errorMessage && (
                  <IonRow className="ion-justify-content-center">
                    <h2>
                      Your integration has begun! The OneShop team will contact
                      you when the process has finished, this can take several
                      hours to complete
                    </h2>
                    <h4>
                      If you have any questions please contact support{' '}
                      <a
                        style={{ color: 'white', textDecoration: 'underline' }}
                        href="mailto:support@oneshopretail.com"
                      >
                        {' '}
                        support@oneshopretail.com
                      </a>
                    </h4>
                  </IonRow>
                )}
                <IonRow className="ion-justify-content-center ion-padding-top">
                  <IonButton color="secondary" href="/account">
                    OK
                  </IonButton>
                </IonRow>
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
      </IonContent>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={'Please wait...'}
      />
    </IonPage>
  )
}

export default AuthCallback

import { PartialDeep } from 'type-fest'

type DefaultTheme = typeof defaultTheme
type CustomTheme = PartialDeep<typeof defaultTheme> | null

const defaultTheme = {
  color: {
    primary: '#758FBF',
    secondary: '#EF9A9A',
    tertiary: '#0A0336',
    background: '#F7F6F9',
  },
  font: { color: { primary: '#171717', secondary: '#EAE9F0' } },
  logo: {
    dark: 'OneShop_Default_Logo_Dark.png',
    light: 'ONeShop_Default_Logo_White.png',
  },
}

export type { DefaultTheme, CustomTheme }
export { defaultTheme }

import * as React from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'
import { IonSplitPane, IonRouterOutlet, IonLoading } from '@ionic/react'
import { differenceInDays, parseISO } from 'date-fns'

import { useAccount } from './contexts/authContext'
import AuthCallback from './pages/auth-callback'
import Sequence from './pages/sequence/Sequence'
import AddClass from './pages/actions/AddClass'
import NextContact from './pages/next-contact'
import Inbox from './pages/chat/Inbox'
import ConversationDetail from './pages/chat/conversation-detail'
import Search from './pages/search'
import StatsStylist from './pages/admin/stylist-stats'
import SwitchAccount from './pages/account/switch-account'
import AdminTemplates from './pages/admin/templates/templates'
import AdminTemplateDetail from './pages/admin/templates/admin-template-detail'
import CreateStoreTemplate from './pages/admin/templates/create-store-template'
import Templates from './pages/templates/templates'
import TemplateDetail from './pages/templates/template-detail'
import CreateTemplate from './pages/templates/create-template'
import CallListDetail from './pages/admin/call-list-detail'
import AdminInbox from './pages/admin/chat/inbox'
import { MyBroadcastsPage } from './pages/broadcasts/my-broadcasts-page'
import ClientImport from './pages/client-import'
import { Menu } from './menu/menu'
import AccountPage from './pages/account/account-page'
import { StatsPage } from './pages/stats-page'
import WhatHappened from './pages/what-happened'
import Outreach from './pages/admin/outreach'
import ClientDetail from './pages/client/ClientDetail'
import CreateOutreach from './pages/admin/outreach/create-outreach'
import AdminDynamicOutreachDetail from './pages/admin/outreach/admin-dynamic-outreach-detail'
import CreatePostPage from './pages/post/create-post-page'
import { BroadcastDetailPage } from './pages/broadcasts/broadcast-detail-page'
import BroadcastDetailClientsPage from './pages/broadcasts/broadcast-detail-clients'
import Tasks from './pages/tasks/tasks'
import { TaskDetailPage } from './pages/tasks/task-detail-page'
import TempAppTabs from './components/temp-app-tabs'
import ConnectionsPage from './pages/account/connections-page'
import GroupConnectionsPage from './pages/account/connections/groups-connections-page'
import UsersConnectionsPage from './pages/account/connections/users-connections-page'
import PostListPage from './pages/posts/post-list-page'
import PostDetailPage from './pages/posts/post-detail-page'
import { ApprovalsPage } from './pages/admin/approvals-page'
import RoleSocialsPage from './pages/admin/role-socials-page'
import { SocialApp } from './social-app'

import NoAccess from './pages/no-access'
import AutomationsListPage from './pages/admin/automations/automations-list-page'
import AutomationDetailPage from './pages/admin/automations/automation-detail-page'

import HeartlandRedemptionPage from './pages/heartland/heartland-redemption-page'
import HeartlandOptInPage from './pages/heartland/heartland-opt-in-page'
import HeartlandOptInSuccessPage from './pages/heartland/heartland-opt-in-success-page'
import HeartlandOptInMoreInfoPage from './pages/heartland/heartland-opt-in-more-info-page'
import Home from './pages/home'
import { PrivateRoute } from './auth/private-route'
import { NotAllowed } from './auth/not-allowed'
import { UserSetupPage } from './pages/admin/user-setup-page'
import DashboardPage from './pages/admin/dashboard-page'
import { isSocialOnly, useIsStore } from './auth/utils'
import CreateAutomationPage from './pages/admin/automations/create-automation-page'
import CreateAutomationHtmlEmailPage from './pages/admin/automations/create-automation-html-email-page'
import LightspeedRedemptionPage from './pages/lightspeed/lightspeed-redemption-page'
import LightspeedOptInPage from './pages/lightspeed/lightspeed-opt-in-page'
import LightspeedOptInSuccessPage from './pages/lightspeed/lightspeed-opt-in-success-page'
import LightspeedOptInMoreInfoPage from './pages/lightspeed/lightspeed-opt-in-more-info-page'
import LoyaltyProgramsPage from './pages/loyalty/loyalty-programs-page'
import LoyaltyProgramsDetailPage from './pages/loyalty/loyalty-programs-detail-page'
import { AddAccount } from './add-account'
import { CreateAlterationPage } from './pages/tasks/create-alteration-page'
import { DynamicListsPage } from './pages/admin/dynamic-lists-page'
import { CreateDynamicListPage } from './pages/admin/outreach/create-dynamic-list-page'
import { AdminCampaignsPage } from './pages/engage/campaigns/admin-campaigns-page'
import { CampaignBroadcastPage } from './pages/engage/campaigns/campaign-broadcast-page'
import { UpgradePlanPage } from './pages/upgrade-plan-page'
import { HeartlandCreateAlterationPage } from './pages/heartland/heartland-create-alteration-page'
import { TerminalSetupPage } from './pages/admin/terminal-setup-page'
import { useInitNotifications } from './notification/use-init-notifications'
import { useNotificationListeners } from './notification/use-notification-listeners'
import { CampaignApprovalsPage } from './pages/engage/campaigns/campaign-approvals-page'
import CreateHtmlEmailPage from './pages/admin/broadcasts/create-html-email-page'
import { ClientLoyaltyDetailPage } from './pages/client/client-loyalty-detail-page'
import { CashierAddCustomerPage } from './pages/rotate/cashier-add-customer-page'
import { CashierCheckInPage } from './pages/rotate/cashier-check-in-page'
import { CashierLoyaltyInfoPage } from './pages/rotate/cashier-loyalty-info-page'
import { CustomerAddCustomerPage } from './pages/rotate/customer-add-customer-page'
import { CustomerCheckInPage } from './pages/rotate/customer-check-in-page'
import { CustomerLoyaltyInfoPage } from './pages/rotate/customer-loyalty-info-page'
import { RotateAddCustomerPage } from './pages/rotate/rotate-add-customer-page'
import { RotateEmailCheckInPage } from './pages/rotate/rotate-email-check-in-page'
import { RotateLoyaltyInfoPage } from './pages/rotate/rotate-loyalty-info-page'
import { RotateOptInPage } from './pages/rotate/rotate-opt-in-page'
import { RotateSuccessPage } from './pages/rotate/rotate-success-page'
import { useActiveStations } from './rotate/queries'
import { CheckInApp } from './check-in-app'

const disabledPaths = [
  'heartland',
  'check-in',
  'rotate',
  'cashier',
  'customer',
  'lightspeed',
]

function useRouteChange() {
  const location = useLocation()
  const [currentPath, setCurrentPath] = React.useState(location.pathname)

  React.useEffect(() => {
    const pathname = location.pathname

    setCurrentPath(pathname)
  }, [location.pathname])

  return currentPath
}

function AuthenticatedApp() {
  const account = useAccount()

  const { isUno } = useIsStore()

  const path = useRouteChange()

  const isSplitMenuDisabled = disabledPaths.some((p) => path.includes(p))

  useInitNotifications()
  useNotificationListeners()
  const activeStations = useActiveStations()
  const isCheckInOnly = Boolean(
    activeStations.data?.pages.flatMap((d) => d.data).length
  )

  // HACK: ACCOUNT EMPTY OBJECT WORKAROUND
  if (!Object.keys(account).length) return null
  if (!account.account?.objectId) return <AddAccount />

  if (
    differenceInDays(Date.now(), parseISO(account.account?.createdAt.iso)) >
      14 &&
    !account.subscriptionStatus
  )
    return <UpgradePlanPage />

  if (isSocialOnly(account)) {
    return <SocialApp />
  }

  if (isCheckInOnly) return <CheckInApp />

  if (activeStations.isInitialLoading)
    return <IonLoading message="Initializing App..." isOpen />

  return (
    <IonSplitPane contentId="main" disabled={isSplitMenuDisabled}>
      <Menu />
      <IonRouterOutlet id="main">
        <Route path="/tabs" render={() => <TempAppTabs />} />

        {/* Admin Home */}
        <Route exact path="/admin/home">
          <Home />
        </Route>

        {/* ****** */}
        {/* ENGAGE */}
        {/* ****** */}
        {/* Admin Campaigns */}
        <PrivateRoute
          exact
          path="/admin/campaigns"
          requiredSettings={['clienteling', 'marketing']}
        >
          <AdminCampaignsPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/admin/campaign-approvals"
          requiredSettings={['clienteling', 'marketing']}
        >
          <CampaignApprovalsPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/admin/campaigns/broadcast/:broadcastId"
          requiredSettings={['marketing']}
        >
          <CampaignBroadcastPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/admin/campaigns/broadcast/:broadcastId/html-email"
          requiredSettings={['marketing']}
        >
          <CreateHtmlEmailPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/admin/outreach/detail/:id"
          requiredSettings={['clienteling']}
        >
          <CallListDetail />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/admin/outreach/create"
          requiredSettings={['clienteling']}
        >
          <CreateOutreach />
        </PrivateRoute>

        {/* Templates */}
        <PrivateRoute
          exact
          path="/admin/templates"
          requiredSettings={['clienteling', 'marketing']}
        >
          <AdminTemplates />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/admin/templates/create"
          requiredSettings={['clienteling', 'marketing']}
        >
          <CreateStoreTemplate />
        </PrivateRoute>
        <PrivateRoute
          exact
          requiredSettings={['clienteling', 'marketing']}
          path="/admin/templates/detail/:tid"
        >
          <AdminTemplateDetail />
        </PrivateRoute>

        {/* ADMIN AUTOMATIONS */}
        <PrivateRoute
          exact
          path="/admin/automations/"
          requiredSettings={['marketing']}
        >
          <AutomationsListPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/admin/automations/create/:templateId/"
          requiredSettings={['marketing']}
        >
          <CreateAutomationPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/admin/automations/detail/:automationId"
          requiredSettings={['marketing']}
        >
          <AutomationDetailPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/admin/automations/detail/:automationId/step/:stepId/html-email"
          requiredSettings={['marketing']}
        >
          <CreateAutomationHtmlEmailPage />
        </PrivateRoute>

        {/* Client Import */}
        <PrivateRoute
          exact
          path="/admin/import"
          requiredSettings={['clienteling', 'marketing']}
        >
          <ClientImport />
        </PrivateRoute>

        {/* ********* */}
        {/* SOCIAL  */}
        {/* ********* */}
        {/* Approvals */}
        <PrivateRoute exact path="/admin/posts" requiredSettings={['social']}>
          <PostListPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/admin/approvals"
          requiredSettings={['social']}
        >
          <ApprovalsPage />
        </PrivateRoute>
        {/* HOMEPAGE POST FLOW */}
        <PrivateRoute exact path="/post/create/" requiredSettings={['social']}>
          <CreatePostPage />
        </PrivateRoute>

        {/* Outreach */}
        <PrivateRoute
          exact
          path="/admin/outreach"
          requiredSettings={['clienteling']}
        >
          <Outreach />
        </PrivateRoute>

        <PrivateRoute
          exact
          path="/admin/dynamic-lists"
          requiredSettings={['clienteling']}
        >
          <DynamicListsPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/admin/dynamic-lists/detail/:outreachId"
          requiredSettings={['clienteling']}
        >
          <AdminDynamicOutreachDetail backRef="/admin/dynamic-lists" />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/admin/dynamic-lists/create"
          requiredSettings={['clienteling']}
        >
          <CreateDynamicListPage />
        </PrivateRoute>
        <Route exact path="/admin/outreach/*/client/:id">
          <ClientDetail />
        </Route>

        {/* ******* */}
        {/* LOYALTY */}
        {/* ******* */}
        <Route exact path="/admin/loyalty-programs">
          <LoyaltyProgramsPage />
        </Route>
        <Route exact path="/admin/loyalty-programs/detail/:loyaltyId">
          <LoyaltyProgramsDetailPage />
        </Route>

        {/* ********* */}
        {/* ANALYTICS */}
        {/* ********* */}
        <PrivateRoute
          exact
          path="/admin/dashboard"
          requiredSettings={['clienteling', 'marketing']}
        >
          <DashboardPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/admin/stats/stylist"
          requiredSettings={['clienteling']}
        >
          <StatsStylist />
        </PrivateRoute>

        {/* ********* */}
        {/* ASSOCIATE */}
        {/* ********* */}
        {/* My Clients */}
        <Route exact path="/*/client/:id">
          <ClientDetail />
        </Route>
        {/* My Templates */}
        <PrivateRoute
          exact
          path="/templates"
          requiredSettings={['clienteling']}
        >
          <Templates />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/templates/create"
          requiredSettings={['clienteling']}
        >
          <CreateTemplate />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/templates/detail/:tid"
          requiredSettings={['clienteling']}
        >
          <TemplateDetail />
        </PrivateRoute>
        {/* Tasks */}
        <PrivateRoute exact path="/tasks" requiredSettings={['clienteling']}>
          <Tasks />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/tasks/:className/:objectId"
          requiredSettings={['clienteling']}
        >
          <TaskDetailPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/alterations/create"
          requiredSettings={['clienteling']}
        >
          <CreateAlterationPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/add/:className"
          requiredSettings={['clienteling']}
        >
          <AddClass />
        </PrivateRoute>
        {/*  My Broadcasts */}
        <PrivateRoute
          exact
          path="/broadcasts"
          requiredSettings={['clienteling']}
        >
          {isUno() ? <NoAccess title="Broadcasts" /> : <MyBroadcastsPage />}
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/broadcasts/detail/:broadcastId"
          requiredSettings={['clienteling']}
        >
          <BroadcastDetailPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/broadcasts/detail/:broadcastId/html-email"
          requiredSettings={['clienteling']}
        >
          <CreateHtmlEmailPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/broadcasts/detail/:broadcastId/clients"
          requiredSettings={['clienteling']}
        >
          <BroadcastDetailClientsPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/broadcasts/detail/:broadcastId/clients/client/:id"
          requiredSettings={['clienteling']}
        >
          <ClientDetail />
        </PrivateRoute>
        {/* My Posts */}
        <PrivateRoute exact requiredSettings={['social']} path="/posts">
          <PostListPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/posts/detail/:postId"
          requiredSettings={['social']}
        >
          <PostDetailPage />
        </PrivateRoute>
        {/* My Stats */}
        <PrivateRoute exact path="/stats" requiredSettings={['clienteling']}>
          <StatsPage />
        </PrivateRoute>

        {/* CHECK-IN ROUTES */}
        {/* Rotate */}
        <PrivateRoute
          exact
          path="/rotate/opt-in"
          requiredSettings={['clienteling', 'marketing']}
        >
          <RotateOptInPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/rotate/email-check-in"
          requiredSettings={['clienteling', 'marketing']}
        >
          <RotateEmailCheckInPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/rotate/add-customer"
          requiredSettings={['clienteling', 'marketing']}
        >
          <RotateAddCustomerPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/rotate/opt-in/success"
          requiredSettings={['clienteling', 'marketing']}
        >
          <RotateSuccessPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/rotate/opt-in/loyalty-info"
          requiredSettings={['clienteling', 'marketing']}
        >
          <RotateLoyaltyInfoPage />
        </PrivateRoute>

        {/* Cashier */}
        <PrivateRoute
          exact
          path="/rotate/cashier/check-in"
          requiredSettings={['clienteling', 'marketing']}
        >
          <CashierCheckInPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/rotate/cashier/add-customer"
          requiredSettings={['clienteling', 'marketing']}
        >
          <CashierAddCustomerPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/rotate/cashier/loyalty-info/:clientId"
          requiredSettings={['clienteling', 'marketing']}
        >
          <CashierLoyaltyInfoPage />
        </PrivateRoute>

        {/* Customer */}
        <PrivateRoute
          exact
          path="/rotate/customer/check-in"
          requiredSettings={['clienteling', 'marketing']}
        >
          <CustomerCheckInPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/rotate/customer/add-customer"
          requiredSettings={['clienteling', 'marketing']}
        >
          <CustomerAddCustomerPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/rotate/customer/loyalty-info/:clientId"
          requiredSettings={['clienteling', 'marketing']}
        >
          <CustomerLoyaltyInfoPage />
        </PrivateRoute>

        {/* ******* */}
        {/* ACCOUNT */}
        {/* ******* */}
        <Route path="/account" component={AccountPage} exact />
        {/* Switch Account */}
        <Route exact path="/account/switch-accounts">
          <SwitchAccount />
        </Route>
        {/* Social Roles Setup */}
        <PrivateRoute exact path="/admin/roles" requiredSettings={['social']}>
          <RoleSocialsPage />
        </PrivateRoute>
        {/* Social Setup */}
        <PrivateRoute
          exact
          path="/account/connections"
          requiredSettings={['social']}
        >
          <ConnectionsPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/account/connections/groups"
          requiredSettings={['social']}
        >
          <GroupConnectionsPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/account/connections/groups/:groupId"
          requiredSettings={['social']}
        >
          <UsersConnectionsPage />
        </PrivateRoute>
        <Route exact path="/admin/user-setup">
          <UserSetupPage />
        </Route>
        <Route exact path="/admin/terminal-setup">
          <TerminalSetupPage />
        </Route>

        {/* ************ */}
        {/* OTHER ROUTES */}
        {/* ************ */}
        <Route exact path="/authorization/callback" component={AuthCallback} />

        <Route exact path="/search">
          <Search />
        </Route>

        {/* ASSOCIATE INBOX */}
        <Route exact path="/chat/inbox">
          <Inbox />
        </Route>
        <Route exact path="/chat/c/:cid">
          <ConversationDetail />
        </Route>

        <Route exact path="/sequence">
          <Sequence />
        </Route>

        {/* Heartland Routes */}
        <Route exact path="/heartland/:groupId/redeem-loyalty">
          <HeartlandRedemptionPage />
        </Route>
        <Route exact path="/heartland/:groupId/opt-in">
          <HeartlandOptInPage />
        </Route>
        <Route exact path="/heartland/:groupId/opt-in/success">
          <HeartlandOptInSuccessPage />
        </Route>
        <Route exact path="/heartland/:groupId/opt-in/more-info">
          <HeartlandOptInMoreInfoPage />
        </Route>
        <Route exact path="/heartland/alteration/create/:clientId">
          <HeartlandCreateAlterationPage />
        </Route>

        {/* Lightspeed Routs */}
        <Route exact path="/lightspeed/redeem-loyalty">
          <LightspeedRedemptionPage />
        </Route>
        <Route exact path="/lightspeed/opt-in">
          <LightspeedOptInPage />
        </Route>
        <Route exact path="/lightspeed/opt-in/success">
          <LightspeedOptInSuccessPage />
        </Route>
        <Route exact path="/lightspeed/opt-in/more-info">
          <LightspeedOptInMoreInfoPage />
        </Route>

        <Route path="/WhatHappened/:clientId">
          <WhatHappened />
        </Route>
        <Route exact path="/NextContact/:clientId" component={NextContact} />

        {/* Admin Inbox */}
        <Route exact path="/admin/chat/inbox">
          <AdminInbox />
        </Route>
        <Route exact path="/admin/chat/c/:cid">
          <ConversationDetail />
        </Route>

        <Route exact path="*/client/:clientId/redeem-loyalty">
          <ClientLoyaltyDetailPage />
        </Route>

        <Route exact path="/access-denied">
          <NotAllowed />
        </Route>

        <Redirect exact from="/" to="/tabs" />
      </IonRouterOutlet>
    </IonSplitPane>
  )
}

export default AuthenticatedApp

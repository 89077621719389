import { useMutation, useQueryClient } from '@tanstack/react-query'

import { client as apiClient } from '../services/client'
import { useAccount } from '../contexts/authContext'
import { useToast } from '../contexts/toastContext'
import { useIsStore } from '../auth/utils'
import { clientQueryKeys } from './clients/queries'
import { outreachQueryKeys } from './outreach/queries'
import { taskQueryKeys } from './tasks/queries'

import type { PhoneNumber } from './clients/queries'

type CreateOptInResponse = { result: { optIn: { objectId: string } } }

type CreateOptInInput = {
  defaultPhoneNumber: PhoneNumber
  client: { objectId: string }
  manualAccept?: boolean
}

async function createOptIn(
  clientPhoneId: string,
  config: { manualAccept?: boolean } = { manualAccept: false }
) {
  const response: CreateOptInResponse = await apiClient
    .post('functions/createOptIn', {
      json: {
        clientPhoneId,
        ...config,
      },
    })
    .json()

  return response.result.optIn.objectId
}

function useCreateOptIn() {
  const queryClient = useQueryClient()
  const setToast = useToast()

  return useMutation(
    async (variables: CreateOptInInput) => {
      const { defaultPhoneNumber, manualAccept } = variables
      return createOptIn(defaultPhoneNumber.objectId, { manualAccept })
    },
    {
      onError: (error) => {
        const errorMessage =
          error instanceof Error ? error.message : 'Unknown error.'

        setToast({
          message: `Something went wrong while sending the opt-in message. ${errorMessage}`,
          color: 'danger',
        })
      },
      onSettled: (_data, _error, variables) => {
        queryClient.invalidateQueries(
          clientQueryKeys.detail(variables.client.objectId)
        )
        queryClient.invalidateQueries(clientQueryKeys.lists())
        queryClient.invalidateQueries(outreachQueryKeys.details())
        queryClient.invalidateQueries(taskQueryKeys.all)
      },
    }
  )
}

function useOptInMessage() {
  const { hasAssignAll } = useAccount()

  const { isUno, isUnoSpanish, isUnoFrench } = useIsStore()

  if (isUnoSpanish())
    return 'Hola <First Name>. Soy <Stylist> de UNOde50 <Store>. Le apreciamos mucho como cliente y esperamos que disfrute de sus piezas de UNOde50. Por favor, asegúrese de hacer clic en el siguiente enlace si desea continuar con nuestra comunicación, comunicarse en caso de que tenga alguna pregunta, o si desea que le envíe nuestras futuras promociones y eventos. ¡Que tenga un lindo día!'

  if (isUnoFrench())
    return "Bonjour, <First Name>. C'est <Stylist>, du magasin UNOde50 <Store>. Nous vous apprécions en tant que client et espérons que vous appréciez vos bijoux UNOde50. Assurez-vous de cliquer sur le lien suivant si vous souhaitez continuer notre communication, nous contacter si vous avez des questions, ou si vous souhaitez recevoir nos futures promotions et événements. Passez une bonne journée!"

  if (isUno())
    return 'Hello <First Name>. This is <Stylist> from UNOde50 <Store>. We really appreciate you as a client and really hope you enjoy your UNOde50 pieces. Please make sure to click on the following link if you want to continue our communication, reach out in case you have any questions, or if you want me to send you our future promotions and events. Have a nice day!'

  if (hasAssignAll)
    return 'Hi <First Name> - This is <Company>. If there is ever anything you need from us, you can reach me at this number, and if you want to get occasional product or store updates from our location, simply opt in at the link below.'

  return 'Hi <First Name> - This is <Stylist> from <Company> on my personal work number. If there is ever anything you need from us, you can reach me at this number, and if you want to get occasional product or store updates from our location, simply opt in at the link below.'
  // TODO: include company and store, but not if they match
  // : 'Hi <First Name> - This is <Stylist> from <Company> <Store> on my personal work number. If there is ever anything you need from us, you can reach me at this number, and if you want to get occasional product or store updates from our location, simply opt in at the link below.'
}

export { useCreateOptIn, useOptInMessage }

import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { useHistory } from 'react-router-dom'

import { LoyaltyRedemptionDetail } from '../../../../loyalty/components/loyalty-redemption-detail'
import { useClientLoyalty } from '../../../../loyalty/queries'
import { useApplyClientRewards } from '../../../../marketing/customer-opt-in/mutations'

import type { ApplyLoyaltyReward } from '../../../../loyalty/loyalty.schema'

function LoyaltyDetailPage() {
  const history = useHistory()

  const loyalty = useClientLoyalty()
  const applyRewards = useApplyClientRewards()

  function handleApplyRewards(
    rewards: Array<ApplyLoyaltyReward>,
    options?: { redeemEarly: boolean }
  ) {
    applyRewards.mutate({ rewards, options })
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Redeem Rewards</IonTitle>
          <IonButtons slot="end">
            <IonButton
              color="yellow"
              fill="solid"
              onClick={() => history.replace('/check-in/paired/admin/home')}
            >
              Finish
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <>
          {loyalty.data ? (
            <LoyaltyRedemptionDetail
              data={loyalty.data}
              disabled={loyalty.isLoading}
              onApplyRewards={handleApplyRewards}
            />
          ) : loyalty.error ? (
            <p>
              Error:{' '}
              {loyalty.error instanceof Error
                ? loyalty.error.message
                : 'Unknown Error.'}
            </p>
          ) : (
            <IonLoading message="Loading Rewards..." isOpen />
          )}
        </>
      </IonContent>
    </IonPage>
  )
}

export { LoyaltyDetailPage }

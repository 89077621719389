import { useIonRouter } from '@ionic/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { client } from '../../services/client'
import { useToast } from '../../contexts/toastContext'
import { campaignQueryKeys } from '../../engage/campaigns/queries'
import { outreachQueryKeys } from './queries'

import type { DynamicCallList } from './queries'
import type { DynamicCallListSchemaState } from '../../schema/dynamic-outreach.schema'
import type { CallListFormState } from '../../schema/onetime-outreach.schema'
import type { CallList } from '../../clienteling/outreach/outreach.schema'

type CreateDynamicCallListResponse = { result: DynamicCallList }
type CreateCallListResponse = { result: CallList }
type UpdateCallListResponse = { result: CallList }

type CreateDynamicCallListInput = DynamicCallListSchemaState
type UpdateDynamicCallListInput = {
  objectId: string
  updates: Omit<DynamicCallListSchemaState, 'trigger'>
}
type CreateCallListInput = CallListFormState
type UpdateCallListInput = {
  objectId: string
  updates: { removeClientIds?: Array<string> } & Partial<
    Pick<CallListFormState, 'dateRange' | 'templateId' | 'name'>
  >
}

async function createCallList(callListInput: CreateCallListInput) {
  const { dateRange, ...inputProps } = callListInput

  const response: CreateCallListResponse = await client
    .post('functions/updateCallList', {
      json: {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        isDynamic: true, // all call lists dynamic now
        ...inputProps,
      },
    })
    .json()

  return response.result
}

async function updateCallList(callListInput: UpdateCallListInput) {
  const { dateRange, ...inputProps } = callListInput.updates

  const response: UpdateCallListResponse = await client
    .post('functions/updateCallList', {
      json: {
        objectId: callListInput.objectId,
        startDate: dateRange?.startDate,
        endDate: dateRange?.endDate,
        isDynamic: true, // all call lists dynamic now
        ...inputProps,
      },
    })
    .json()

  return response.result
}

async function createDynamicCallList(
  dynamicCallListInput: CreateDynamicCallListInput
) {
  const response: CreateDynamicCallListResponse = await client
    .post('functions/createCallListDynamic', {
      json: dynamicCallListInput,
    })
    .json()

  return response.result
}

async function updateDynamicCallList(
  dynamicCallListInput: UpdateDynamicCallListInput
) {
  const { ...inputProps } = dynamicCallListInput.updates

  const response: CreateDynamicCallListResponse = await client
    .post('functions/updateCallListDynamic', {
      json: {
        objectId: dynamicCallListInput.objectId,
        ...inputProps,
      },
    })
    .json()

  return response.result
}

function useCreateCallList() {
  const setToast = useToast()
  const queryClient = useQueryClient()
  const router = useIonRouter()

  return useMutation(createCallList, {
    onSuccess: (_data, variables) => {
      const callListName = variables.name

      setToast({
        message: `The outreach ${callListName} was successfully created.`,
        color: 'yellow',
      })

      router.canGoBack()
        ? router.goBack()
        : router.push('/admin/campaigns', 'back', 'pop')
    },

    onSettled: () => {
      queryClient.invalidateQueries(campaignQueryKeys.lists())
      queryClient.invalidateQueries(
        outreachQueryKeys.list({ type: 'One Time' })
      )
    },
    onError: () => {
      setToast({
        message: 'Oops, something went wrong trying to create the outreach.',
        color: 'danger',
      })
    },
  })
}

function useUpdateCallList() {
  const setToast = useToast()
  const queryClient = useQueryClient()

  return useMutation(updateCallList, {
    onSuccess: () => {
      setToast({
        message: `The outreach was successfully updated.`,
        color: 'yellow',
      })
    },

    onSettled: (_data, _err, variables) => {
      queryClient.invalidateQueries(campaignQueryKeys.lists())

      queryClient.invalidateQueries(
        outreachQueryKeys.list({ type: 'One Time' })
      )
      queryClient.invalidateQueries(
        outreachQueryKeys.detail({ id: variables.objectId })
      )
    },

    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err) => {
      const errorMessage = err instanceof Error ? err.message : 'Unknown error'

      setToast({
        message: `Oops, something went wrong while updating the outreach: ${errorMessage}`,
        color: 'danger',
      })
    },
  })
}

function useCreateDynamicCallList() {
  const queryClient = useQueryClient()
  const setToast = useToast()
  const router = useIonRouter()

  return useMutation(createDynamicCallList, {
    onSettled: () => {
      queryClient.invalidateQueries(outreachQueryKeys.list({ type: 'Dynamic' }))
    },
    onSuccess: (_data, variables) => {
      const callListName = variables.name

      setToast({
        message: `The outreach ${callListName} was successfully created.`,
        color: 'yellow',
      })

      router.canGoBack()
        ? router.goBack()
        : router.push('/admin/outreach', 'back', 'pop')
    },
    onError: (err, variables) => {
      const errorMessage = err instanceof Error ? err.message : 'Unknown Error.'
      if (err instanceof Error) {
        console.log(err.message)
      }

      setToast({
        message: `Something went wrong while creating ${variables?.name}. Please try again later.\n\n${errorMessage}`,
        color: 'danger',
      })
    },
  })
}

function useUpdateDynamicCallList() {
  const queryClient = useQueryClient()
  const setToast = useToast()

  return useMutation(updateDynamicCallList, {
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(outreachQueryKeys.list({ type: 'Dynamic' }))
      queryClient.invalidateQueries(
        outreachQueryKeys.detail({ id: variables.objectId })
      )
    },
    onSuccess: () => {
      setToast({
        message: `The outreach was successfully updated.`,
        color: 'yellow',
      })
    },
    onError: (err, variables) => {
      const errorMessage = err instanceof Error ? err.message : 'Unknown Error.'
      if (err instanceof Error) {
        console.log(err.message)
      }

      setToast({
        message: `Something went wrong while creating ${variables?.updates.name}. Please try again later.\n\n${errorMessage}`,
        color: 'danger',
      })
    },
  })
}

export {
  useCreateCallList,
  useUpdateCallList,
  useCreateDynamicCallList,
  useUpdateDynamicCallList,
}

import { useQuery } from '@tanstack/react-query'
import { ZodError } from 'zod'
import { fromZodError } from 'zod-validation-error'

import { makeServerResponseSchema } from '../../utils/schema'
import { client } from '../../services/client'
import { useToast } from '../../contexts/toastContext'
import { loyaltyQuerySelector } from '../../loyalty/utils'
import {
  heartlandClientSchema,
  heartlandLoyaltySchema,
} from './heartland.schema'

import type { QueryContextFromKeys } from '../../utils/react-query'
import type { HeartlandLoyaltyParams } from './heartland.schema'
import type { ProfileBase } from '../../schema/profile.schema'

type HeartlandQueryContexts = QueryContextFromKeys<typeof heartlandQueryKeys>

type SalespersonParams = Pick<HeartlandLoyaltyParams, 'subdomain' | 'ticketId'>
type LoyaltyParams = Omit<HeartlandLoyaltyParams, 'subdomain'>

const heartlandQueryKeys = {
  all: [{ entity: 'heartland' }] as const,
  loyalty: ({ clientId, groupId, ticketId }: LoyaltyParams) =>
    [
      {
        ...heartlandQueryKeys.all[0],
        scope: 'loyalty',
        groupId,
        clientId,
        ticketId,
      },
    ] as const,
  client: ({ clientId, groupId, ticketId }: LoyaltyParams) => [
    {
      ...heartlandQueryKeys.all[0],
      scope: 'client',
      groupId,
      clientId,
      ticketId,
    },
  ],
  salesperson: ({ subdomain, ticketId }: SalespersonParams) => [
    {
      ...heartlandQueryKeys.all[0],
      scope: 'salesperson',
      subdomain,
      ticketId,
    },
  ],
}

async function fetchHeartlandClientLoyalty({
  queryKey: [{ clientId, groupId, ticketId }],
}: HeartlandQueryContexts['loyalty']) {
  const response = await client
    .post('functions/getClientLoyaltyHeartland', {
      json: { clientId, groupId, ticketId },
    })
    .json()

  const parsedResponse = makeServerResponseSchema(
    heartlandLoyaltySchema
  ).safeParse(response)

  if (!parsedResponse.success)
    throw new Error(fromZodError(parsedResponse.error).message)

  return parsedResponse.data.result
}

async function fetchHeartlandClient({
  queryKey: [{ clientId, groupId, ticketId }],
}: HeartlandQueryContexts['client']) {
  const response = await client
    .post('functions/getHeartlandClient', {
      json: { clientId, groupId, ticketId },
    })
    .json()
  try {
    return makeServerResponseSchema(heartlandClientSchema).parse(response)
      .result
  } catch (e) {
    if (e instanceof ZodError) throw new Error(fromZodError(e).message)
  }
}

function useHeartlandClientLoyalty(params: LoyaltyParams) {
  const { clientId, groupId, ticketId } = params

  const setToast = useToast()

  return useQuery({
    queryKey: heartlandQueryKeys.loyalty(params),
    queryFn: fetchHeartlandClientLoyalty,
    enabled: Boolean(clientId && groupId && ticketId),
    select: loyaltyQuerySelector,
    onError(error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown Error.'

      setToast({
        message: `Something went wrong while loading the loyalty rewards info: ${errorMessage}`,
        color: 'danger',
      })
    },
  })
}

function useHeartlandClient(params: LoyaltyParams) {
  const setToast = useToast()

  return useQuery({
    queryKey: heartlandQueryKeys.client(params),
    queryFn: fetchHeartlandClient,
    enabled: Boolean(params.clientId && params.groupId && params.ticketId),
    onError(error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown Error.'

      setToast({
        message: `Something went wrong while loading the client info: ${errorMessage}`,
        color: 'danger',
      })
    },
  })
}

function useHeartlandSalesperson({
  subdomain,
  ticketId,
}: {
  subdomain: string | null
  ticketId: string | null
}) {
  return useQuery({
    queryKey: heartlandQueryKeys.salesperson({ subdomain, ticketId }),
    queryFn: async () => {
      const response: { result: { users: Array<ProfileBase> } } = await client
        .post('functions/getTicketUsersHeartland', {
          json: {
            subdomain,
            ticketId,
          },
        })
        .json()
      return response.result
    },
  })
}

export {
  heartlandQueryKeys,
  useHeartlandClientLoyalty,
  useHeartlandClient,
  useHeartlandSalesperson,
}

import {
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonPage,
} from '@ionic/react'

import Header from '../components/header'

function NoAccess({ title }: { title: string }) {
  return (
    <IonPage>
      <IonHeader>
        <Header showMenu btn={null} title={title} />
      </IonHeader>
      <IonContent className="ion-padding">
        <IonCard color="secondary" className="mx-auto max-w-sm">
          <IonCardContent>
            You do not have access to this page. If you feel this is an error,
            please contact support@oneshopretail.com
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  )
}

export default NoAccess

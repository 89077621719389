import { Chip } from '../ui/buttons/chip'

function SeeMore({
  onClick,
}: {
  onClick: React.MouseEventHandler<HTMLButtonElement>
}) {
  return (
    <Chip size="sm" color="light" onClick={onClick}>
      <span className="uppercase">See More</span>
    </Chip>
  )
}

export { SeeMore }

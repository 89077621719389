import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useIonRouter } from '@ionic/react'
import { useParams } from 'react-router'
import produce from 'immer'

import { composeFromEmail } from '../utils/misc'
import { makeServerResponseSchema } from '../utils/schema'
import { client } from '../services/client'
import { useToast } from '../contexts/toastContext'
import { automationSchema } from './automations-base.schema'
import { automationStates } from './constants'
import { automationsQueryKeys } from './queries'
import { composeConditions } from './utils'

import type { Automation } from './automations-base.schema'
import {
  type CreateAutomationInput,
  type UpdateAutomationInput,
} from './automations-form.schema'

async function createAutomation(
  automation: CreateAutomationInput & {
    state: typeof automationStates[number]
  }
) {
  const {
    steps: triggerSteps,
    fromUserRule,
    fromUserId,
    conditions,
    fromEmailDomain,
    fromEmailName,
    ...triggerRule
  } = automation
  const fromUser = {
    ...(fromUserRule && { fromUserRule }),
    ...(fromUserId ? { fromUserId } : { fromUserId: null }),
  }
  const fromEmail = composeFromEmail({
    domain: fromEmailDomain,
    name: fromEmailName,
  })
  const { type, ...triggerRest } = triggerRule

  const availableConditions = composeConditions(conditions)

  const triggerRuleResponse = await client
    .post('functions/createTriggerRule', {
      json: {
        ...fromUser,
        fromEmail,
        conditions: availableConditions,
        ...triggerRest,
        type,
      },
    })
    .json()

  const triggerRuleResult =
    makeServerResponseSchema(automationSchema).parse(triggerRuleResponse).result

  await Promise.all(
    triggerSteps.map(({ attachmentsEmail, attachmentsSms, ...step }) =>
      client
        .post('functions/createTriggerStep', {
          json: {
            triggerRuleId: triggerRuleResult.objectId,
            setAttachmentsSms: attachmentsSms?.length ? attachmentsSms : null,
            setAttachmentsEmail: attachmentsEmail?.length
              ? attachmentsEmail
              : null,
            ...step,
          },
        })
        .json()
    )
  )

  return { objectId: triggerRuleResult.objectId }
}

async function updateAutomation({
  objectId,
  automation,
}: {
  objectId: string
  automation: Partial<UpdateAutomationInput> & {
    state: typeof automationStates[number]
  }
}) {
  const {
    steps: updatedTriggerSteps,
    fromUserRule,
    fromUserId,
    fromEmailDomain,
    fromEmailName,
    conditions,
    ...updatedTriggerRule
  } = automation

  const fromUser = {
    ...(fromUserRule && { fromUserRule }),
    ...(fromUserRule === 'fromUser' ? { fromUserId } : { fromUserId: null }),
  }
  const fromEmail = composeFromEmail({
    domain: fromEmailDomain,
    name: fromEmailName,
  })

  const availableConditions = conditions
    ? composeConditions(conditions)
    : undefined

  // TODO: move stopped/restart into custom hook?
  const updatedRule =
    automation.state === 'stopped'
      ? { objectId, ...automation }
      : {
          objectId,
          ...fromUser,
          fromEmail,
          conditions: availableConditions,
          ...updatedTriggerRule,
        }

  const mappedPromises = updatedTriggerSteps?.map(
    async ({ attachmentsEmail, attachmentsSms, ...step }) =>
      client
        .post('functions/updateTriggerStep', {
          json: {
            setAttachmentsSms: attachmentsSms?.length ? attachmentsSms : null,
            setAttachmentsEmail: attachmentsEmail?.length
              ? attachmentsEmail
              : null,
            ...step,
          },
        })
        .json()
  )
  return Promise.all([
    client.post('functions/updateTriggerRule', {
      json: updatedRule,
    }),
    ...(mappedPromises ?? []),
  ])
}

async function updateTriggerStep({
  objectId,
  triggerStep,
}: {
  objectId: string
  triggerStep: Omit<Partial<UpdateAutomationInput['steps'][number]>, 'objectId'>
}) {
  const { attachmentsEmail, attachmentsSms, ...step } = triggerStep

  return client
    .post('functions/updateTriggerStep', {
      json: {
        objectId,
        // deal with attachments only if they are passed in
        ...(attachmentsSms && {
          setAttachmentsSms: attachmentsSms?.length ? attachmentsSms : null,
        }),
        ...(attachmentsEmail && {
          setAttachmentsEmail: attachmentsEmail?.length
            ? attachmentsEmail
            : null,
        }),
        ...step,
      },
    })
    .json()
}

function useCreateAutomation() {
  const queryClient = useQueryClient()
  const setToast = useToast()

  const router = useIonRouter()

  return useMutation(createAutomation, {
    onError: (error) => {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown error.'
      setToast({
        message: `Something went wrong when updating your automation. ${errorMessage}`,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries(automationsQueryKeys.list())
    },
    onSuccess: (data, variables) => {
      const msg = variables.state === 'draft' ? 'saved' : 'created'

      setToast({
        message: `Your automation has been ${msg}!`,
        color: 'yellow',
      })

      variables.state === 'draft'
        ? router.push(
            `/admin/automations/detail/${data.objectId}`,
            'none',
            'replace'
          )
        : router.canGoBack()
        ? router.goBack()
        : router.push('/admin/automations', 'back', 'pop')
    },
  })
}

function useUpdateAutomation() {
  const queryClient = useQueryClient()
  const router = useIonRouter()
  const setToast = useToast()

  return useMutation(updateAutomation, {
    onError: (error) => {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown error.'
      setToast({
        message: `Something went wrong when updating your automation. ${errorMessage}`,
      })
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(
        automationsQueryKeys.detail(variables.objectId)
      )
      queryClient.invalidateQueries(automationsQueryKeys.list())
    },
    onSuccess: (_data, variables) => {
      const { state } = variables.automation
      const msg =
        state === 'active'
          ? 'published'
          : state === 'draft'
          ? 'saved'
          : 'paused'

      setToast({
        message: `Your automation has been ${msg}!`,
        color: 'yellow',
      })
      if (state === 'active') router.push('/admin/automations', 'back', 'pop')
    },
  })
}

function useUpdateTriggerStep() {
  const { automationId } = useParams<{ automationId: string }>()

  const queryClient = useQueryClient()

  return useMutation(updateTriggerStep, {
    onSettled: (d, e, v) => {
      queryClient.invalidateQueries(automationsQueryKeys.detail(automationId))
    },
  })
}

function useDeleteTriggerStepHtmlEmail() {
  const { automationId } = useParams<{ automationId: string }>()

  const queryClient = useQueryClient()

  return useMutation(
    (v) =>
      updateTriggerStep({
        objectId: v.objectId,
        triggerStep: {
          emailMessage: null,
          unlayerDesign: null,
          unlayerTemplateId: null,
        },
      }),
    {
      onMutate: async ({ objectId }: { objectId: string }) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(automationsQueryKeys.all)

        const previousAutomation = queryClient.getQueryData<Automation>(
          automationsQueryKeys.detail(automationId)
        )

        if (previousAutomation) {
          queryClient.setQueryData<Automation>(
            automationsQueryKeys.detail(automationId),
            (old) => {
              if (old) {
                const triggerStepIndex = old.steps.findIndex(
                  (triggerStep) => triggerStep.objectId === objectId
                )

                if (triggerStepIndex === -1) return previousAutomation

                return produce(old, (draft) => {
                  draft.steps[triggerStepIndex].emailMessage = ''
                  draft.steps[triggerStepIndex].unlayerDesign = null
                  draft.steps[triggerStepIndex].unlayerTemplateId = null
                  draft.steps[triggerStepIndex].unlayerImage = null
                  draft.steps[triggerStepIndex].unlayerImageFull = null
                })
              }

              return previousAutomation
            }
          )
        }
      },
      onSettled: (d, e, v) => {
        queryClient.invalidateQueries(automationsQueryKeys.detail(automationId))
      },
    }
  )
}

export {
  useCreateAutomation,
  useDeleteTriggerStepHtmlEmail,
  useUpdateAutomation,
  useUpdateTriggerStep,
}

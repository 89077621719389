import * as React from 'react'
import {
  IonCol,
  IonImg,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLoading,
  IonRouterLink,
  IonRow,
  useIonRouter,
} from '@ionic/react'
import { name } from '@cloudinary/url-gen/actions/namedTransformation'
import { z } from 'zod'
import { ErrorBoundary } from 'react-error-boundary'

import { oneshopCld } from '../../../utils/cloudinary'
import { formatDistanceFromNow } from '../../../utils/date-helpers'
import { useInfiniteData } from '../../../hooks/use-infinite-data'
import { ayrsharePlatforms } from '../../../ayrshare/ayrshare-platforms'
import { OneshopLogo } from '../../../ayrshare/social-logos'
import { PostOverview } from '../posts.schema'
import { usePosts } from '../queries'
import CldVideoThumbnail from '../../../components/cloudinary/cld-video-thumbnail'
import ErrorFallback from '../../../components/error-fallback'
import { AvatarHeader } from '../../../components/ui/avatar-header'
import { Chip } from '../../../components/ui/buttons/chip'
import ScrollingList from '../../../components/ui/scrolling-list'
import { oneshopStatuses } from '../../../constants/misc'

const shortcuts = [
  'all',
  'posted',
  'pending_approval',
  'scheduled',
  'failed',
] as const

export const filterMap: {
  [key in typeof shortcuts[number]]: {
    filter?: { status?: typeof oneshopStatuses[number]; scheduled?: boolean }
    sort?: { date: 1 | -1 }
  }
} = {
  all: {},
  posted: { filter: { status: 'success' } },
  pending_approval: { filter: { status: 'pending' } },
  scheduled: { filter: { scheduled: true }, sort: { date: 1 } },
  failed: { filter: { status: 'failed' } },
}

export function usePostListFilters<T extends string>(
  shortcuts: readonly [T, ...Array<T>]
) {
  // register route changes
  // w/o calling the hook changes in the route do not re-render component
  useIonRouter()

  const shortcutsMap = shortcuts.map((shortcut) => ({
    label: shortcut.replaceAll('_', ' '),
    value: shortcut,
  }))
  const shortcutsEnumSchema = z.enum<T, readonly [T, ...Array<T>]>(shortcuts)

  const currentList = window.location.search

  const queryParams = new URLSearchParams(currentList)
  const list = queryParams.get('list')
  return {
    currentList,
    parsedList: shortcutsEnumSchema.parse(list ?? 'all'),
    shortcutsMap,
  }
}

function PostItem({ post }: { post: PostOverview }) {
  const cldImage = oneshopCld
    .video(post.look.cloudinary.data.public_id)
    .namedTransformation(name('9x16_video_poster'))
    .format('auto:image')

  return (
    <IonRouterLink
      style={{ '--color': 'var(--ion-color-secondary' }}
      routerLink={`/posts/detail/${post.objectId}`}
      routerDirection="forward"
      className="block space-y-4 pb-2 pt-6"
    >
      <IonRow className="ion-padding-horizontal">
        <IonCol size="2" sizeLg="3">
          {post.look.cloudinary?.data.resource_type === 'video' ? (
            <div className="aspect-h-16 aspect-w-9 w-full">
              <CldVideoThumbnail cldImage={cldImage} />
            </div>
          ) : post.look.cloudinary?.data.resource_type === 'image' ? (
            <div className="aspect-h-4 aspect-w-3 w-full">
              <IonImg src={post.look.cloudinary.data.secure_url} />
            </div>
          ) : null}
        </IonCol>
        <IonCol size="9" sizeLg="5" offsetLg="1">
          <AvatarHeader user={post.user} />
          {post.post ? (
            <div className="px-3 pb-2">
              <p>{post.post}</p>
            </div>
          ) : null}
          {/* post info */}
          <div className="flex items-center justify-between px-3">
            <span>
              {formatDistanceFromNow(
                post.date ? post.date.iso : post.createdAt.iso
              )}
            </span>
            <span>Status: {post.status}</span>
          </div>
        </IonCol>
      </IonRow>

      {/* Social Channels */}
      <IonRow className="ion-padding-horizontal">
        <div className="flex space-x-4">
          <ul className="flex items-start gap-4">
            {ayrsharePlatforms.map((connection) => {
              const Icon = connection.icon

              return (
                <li
                  key={connection.name}
                  className={
                    post.platforms.includes(connection.name)
                      ? 'opacity-100'
                      : 'opacity-30'
                  }
                >
                  <Icon />
                </li>
              )
            })}
            <li
              className={
                post.platforms.includes('oneshop')
                  ? 'opacity-100'
                  : 'opacity-30'
              }
            >
              <OneshopLogo />
            </li>
          </ul>
        </div>
      </IonRow>
    </IonRouterLink>
  )
}

function PostList() {
  const { currentList, parsedList, shortcutsMap } =
    usePostListFilters(shortcuts)

  return (
    <div className="ion-padding-horizontal ion-padding-vertical">
      <ScrollingList>
        {shortcutsMap.map((shortcut) => (
          <IonRouterLink
            key={shortcut.value}
            routerLink={`/posts?list=${shortcut.value}`}
            routerDirection="none"
          >
            <Chip fill={shortcut.value !== parsedList ? 'outline' : 'solid'}>
              <span className="capitalize">{shortcut.label}</span>
            </Chip>
          </IonRouterLink>
        ))}
      </ScrollingList>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        resetKeys={[currentList]}
      >
        <FilteredPosts />
      </ErrorBoundary>
    </div>
  )
}

function FilteredPosts() {
  const postsQuery = usePosts()

  const [ionInfiniteScrollRef, loadMorePosts] = useInfiniteData(
    postsQuery.fetchNextPage
  )

  if (postsQuery.data) {
    return (
      <>
        {postsQuery.data.pages[0].data.length > 0 ? (
          <>
            <div className="ion-padding-horizontal divide-y-2 divide-gray-700">
              {postsQuery.data.pages.map((page, i) => (
                <React.Fragment key={i}>
                  {page.data.map((post) => (
                    <PostItem post={post} key={post.objectId} />
                  ))}
                </React.Fragment>
              ))}
            </div>
            <IonInfiniteScroll
              ref={ionInfiniteScrollRef}
              onIonInfinite={loadMorePosts}
              threshold="100px"
              disabled={!postsQuery.hasNextPage}
            >
              <IonInfiniteScrollContent
                loadingSpinner="bubbles"
                loadingText="loading more posts..."
              />
            </IonInfiniteScroll>
          </>
        ) : (
          <div className="ion-padding-horizontal flex h-full items-center justify-center">
            <p>You have no posts that match this filter.</p>
          </div>
        )}
      </>
    )
  }
  return <IonLoading isOpen message="Loading posts..." />
}
export default PostList

import { cn } from '../../utils/cn'

function SectionWrapper({ children }: { children: React.ReactNode }) {
  return <section className="space-y-2 pb-8 pt-2">{children}</section>
}

function SectionContent({ children }: { children: React.ReactNode }) {
  return <div>{children}</div>
}

function SectionHeader({
  title,
  action,
}: {
  title: React.ReactText
  action?: React.ReactNode
}) {
  return (
    <div
      className={cn('ion-padding-horizontal', {
        'flex items-center justify-between': action,
      })}
    >
      <h2 className="text-2xl">{title}</h2>
      {action ? <div className="ml-2">{action}</div> : null}
    </div>
  )
}

export { SectionWrapper, SectionHeader, SectionContent }

import * as React from 'react'
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { chatboxOutline, mailOutline } from 'ionicons/icons'
import { useForm, Controller } from 'react-hook-form'

import { getDefaultPhoneNumber } from '../../utils/client-helpers'
import { cn } from '../../utils/cn'
import saveManualCommunication from '../../utils/save-communication'
import { useUser } from '../../contexts/authContext'
import { useToast } from '../../contexts/toastContext'
import { useSendMessage } from '../../hooks/chat/mutations'
import { usePrimaryClientConversation } from '../../hooks/chat/queries'
import { useClientSearch } from '../../hooks/clients/queries'
import useSendCommunication from '../../hooks/use-send-communication'
import { MessageCard } from '../../shopwith/components/message-card'

import type { SearchClient } from '../../hooks/clients/queries'

type Props = {
  title: string
  items: Array<string>
  pointers:
    | { productIds: Array<string> }
    | { lookIds: Array<string> }
    | { collectionIds: Array<string> }
  link: string
  onClose: () => void
  onSend: () => void
}

const defaultValues = {
  subject: '',
  message: '',
}

const translations = {
  subject: { en: 'Check this out!', fr: 'Nouveau Asset Tristan!' },
  intro: {
    en: 'Hi',
    fr: 'Bonjour',
  },
  message: {
    en: {
      single: 'Here is something I think you might like.',
      multi: 'Here are a few things I think you might like.',
    },
    fr: {
      single:
        'Voici un article de la nouvelle collection qui nous a fait penser à vous. Nous croyons bien que ça pourrait vous intéresser!\n\nAu plaisir!',
      multi:
        'Voici un Asset de la nouvelle collection que nous avons créé pour vous. Nous croyons bien que ça pourrait vous intéresser.\n\nAu plaisir!',
    },
  },
}

type FormState = typeof defaultValues

type Segment = 'sms' | 'email'

function ShareShopWithModal({
  title,
  items,
  pointers,
  link,
  onClose,
  onSend,
}: Props) {
  const [searchString, setSearchString] = React.useState('')
  const [selectedClient, setSelectedClient] =
    React.useState<SearchClient | null>(null)
  const [segment, setSegment] = React.useState<Segment | undefined>()

  const user = useUser()
  const { hasTwilio, smsManual } = user

  const defaultPhone = selectedClient
    ? getDefaultPhoneNumber(selectedClient.phoneNumbers)
    : null

  const { getConversationId } = usePrimaryClientConversation()
  const sendMessageMutation = useSendMessage()

  const { handleSubmit, control, getValues, reset } = useForm<FormState>({
    defaultValues,
  })
  const setToast = useToast()
  const { sendSMS, sendMessage } = useSendCommunication()

  const linkWithClientId = selectedClient
    ? `${link}&client=${selectedClient.objectId}`
    : link
  const foundClientName =
    selectedClient && `${selectedClient.firstName} ${selectedClient.lastName}`

  const clientSearchQuery = useClientSearch(searchString, {
    enabled:
      !!searchString && (!foundClientName || foundClientName !== searchString),
  })

  const clientLanguage = selectedClient?.posLanguage ?? 'en'
  const email = selectedClient ? selectedClient.email : undefined
  const initialSegment =
    defaultPhone && !defaultPhone.isLandLine
      ? 'sms'
      : email
      ? 'email'
      : undefined

  const isUnsubscribed =
    Boolean(selectedClient?.unsubscribedAt) ||
    selectedClient?.posActive === false

  // initial selected segment
  React.useEffect(() => {
    setSegment(initialSegment)
  }, [initialSegment])

  // update default message
  React.useEffect(() => {
    const message =
      items.length > 1
        ? `${translations.message[clientLanguage].multi}`
        : `${translations.message[clientLanguage].single}`

    reset({
      subject: translations.subject[clientLanguage],
      message: `${translations.intro[clientLanguage]} ${selectedClient?.firstName},\n${message}`,
    })
  }, [clientLanguage, items.length, reset, selectedClient?.firstName])

  async function sendShopWithSMS() {
    if (!selectedClient || !defaultPhone) return

    try {
      const message = `${getValues('message')}\n\n${linkWithClientId}`

      await sendSMS(defaultPhone.phoneNumber, { message })
      await saveManualCommunication('sms', {
        message,
        clientId: selectedClient.objectId,
        log: false,
        pointers,
      })
    } catch (e) {
      setToast({
        message: 'Message canceled',
      })
    }
  }

  async function sendShopWithEmail() {
    if (!selectedClient) return
    if (!selectedClient.email)
      return setToast({
        message: 'This client does not have an email address',
      })

    const sendMessageProps = {
      user,
      clientId: selectedClient.objectId,
      to: selectedClient.email,
      subject: getValues('subject'),
      body: getValues('message'),
      log: false,
      pointers,
    }

    await sendMessage(sendMessageProps)
  }

  async function sendShopWithOneShopMessage() {
    // Just in case - tho technically button should be disabled
    if (!defaultPhone || !defaultPhone.e164) {
      return setToast({
        message:
          'This client does not have a default phone number that can be messaged.',
      })
    }

    if (!selectedClient) {
      return setToast({
        message: 'You must select a client in order to share a ShopWith link.',
      })
    }

    const msg = getValues('message')

    const conversationId = await getConversationId(selectedClient.objectId)

    if (!conversationId) {
      return setToast({
        message:
          'Something went wrong setting up the conversation for this client.',
        color: 'danger',
      })
    }

    // SEND MESSAGE - TEXT ONLY
    sendMessageMutation.mutate({
      conversationId,
      message: { data: msg, ...pointers },
    })
  }

  function handleSelectSearchResult(id: string) {
    // nab that client
    let client: SearchClient | undefined

    if (clientSearchQuery.data) {
      client = clientSearchQuery.data.find((client) => client.objectId === id)
      if (!client) {
        return setToast({
          message: 'Something went wrong loading the selected client.',
          color: 'danger',
        })
      }
      setSearchString(`${client?.firstName} ${client?.lastName}`)
      setSelectedClient(client)
    }
  }

  function handleFormSubmit() {
    if (segment === 'email') {
      sendShopWithEmail()
    } else if (segment === 'sms' && (!hasTwilio || (hasTwilio && smsManual))) {
      sendShopWithSMS()
    } else if (segment === 'sms' && hasTwilio) {
      sendShopWithOneShopMessage()
    }

    onSend()
  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{title}</IonTitle>
          <IonButtons slot="start">
            <IonButton color="secondary" onClick={onClose}>
              CANCEL
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonSearchbar
          debounce={500}
          style={{
            '--border-radius': '9999px',
          }}
          color="dark"
          type="search"
          placeholder="Find A Client"
          showCancelButton="never"
          value={searchString}
          onIonChange={(e) => setSearchString(e.detail.value!)}
          onIonClear={() => {
            setSearchString('')
            setSelectedClient(null)
          }}
        />

        {clientSearchQuery.isInitialLoading ? (
          <div className="ion-text-center ion-padding">
            <IonSpinner />
          </div>
        ) : clientSearchQuery.data && clientSearchQuery.data.length > 0 ? (
          <IonList inset>
            {clientSearchQuery.data.map((selectedClient) => (
              <IonItem
                button
                color="secondary"
                key={selectedClient.objectId}
                onClick={() =>
                  handleSelectSearchResult(selectedClient.objectId)
                }
              >
                <div tabIndex={0} />
                <IonLabel>
                  {selectedClient.firstName} {selectedClient.lastName}
                </IonLabel>
              </IonItem>
            ))}
          </IonList>
        ) : null}

        <div className="ion-padding">
          {!selectedClient ? (
            <MessageCard>
              Please search for a client to send this to.
            </MessageCard>
          ) : null}

          {selectedClient && isUnsubscribed ? (
            <MessageCard>
              This client has unsubscribed from communications.
            </MessageCard>
          ) : selectedClient && !email && !defaultPhone ? (
            <MessageCard>
              This client does not have a phone number or email address.
            </MessageCard>
          ) : selectedClient && !isUnsubscribed ? (
            <>
              <IonSegment
                value={segment}
                onIonChange={(e) => setSegment(e.detail.value as Segment)}
              >
                <IonSegmentButton
                  value="sms"
                  disabled={defaultPhone?.isLandLine || !defaultPhone}
                >
                  <IonIcon icon={chatboxOutline}></IonIcon>
                </IonSegmentButton>
                <IonSegmentButton value="email" disabled={!email}>
                  <IonIcon icon={mailOutline}></IonIcon>
                </IonSegmentButton>
              </IonSegment>

              {segment === 'sms' && defaultPhone?.optInState === 'OPTED_OUT' ? (
                <MessageCard>
                  This client has opted out of text communications.
                </MessageCard>
              ) : segment === 'sms' && !defaultPhone ? (
                <MessageCard>
                  This client does not have a valid phone number that can be
                  messaged.
                </MessageCard>
              ) : segment === 'email' && !email ? (
                <MessageCard>
                  This client does not have an email address.
                </MessageCard>
              ) : (
                <>
                  {hasTwilio ? (
                    <p className="text-ion-color-yellow">
                      Note: The ShopWith link is sent separately and may arrive
                      before or after your message.
                    </p>
                  ) : null}
                  <IonCard>
                    <IonCardContent>
                      <form onSubmit={handleSubmit(handleFormSubmit)}>
                        <IonItem
                          className={cn(
                            'ion-no-padding',
                            segment === 'email' ? 'block' : 'none'
                          )}
                          color="secondary"
                        >
                          <IonLabel position="stacked">Subject</IonLabel>
                          <Controller
                            render={({
                              field: { value, onBlur, onChange },
                            }) => (
                              <IonInput
                                value={value}
                                onIonChange={onChange}
                                onIonBlur={onBlur}
                              />
                            )}
                            control={control}
                            name="subject"
                          />
                        </IonItem>

                        <IonItem className="ion-no-padding" color="secondary">
                          <IonLabel position="stacked">Message</IonLabel>
                          <Controller
                            render={({ field: { value, onChange } }) => {
                              return (
                                <IonTextarea
                                  autocapitalize="on"
                                  rows={6}
                                  onIonChange={onChange}
                                  value={value}
                                />
                              )
                            }}
                            control={control}
                            name="message"
                          />
                        </IonItem>
                        <p className="ion-padding-top text-ion-color-primary">
                          <span className="font-semibold">ShopWith Link:</span>{' '}
                          {linkWithClientId}
                        </p>
                        <div className="ion-margin-top ion-text-center">
                          <IonButton
                            type="submit"
                            expand="block"
                            color="yellow"
                          >
                            SEND
                          </IonButton>
                        </div>
                      </form>
                    </IonCardContent>
                  </IonCard>
                </>
              )}
            </>
          ) : null}
        </div>
      </IonContent>
    </>
  )
}

export default ShareShopWithModal

import * as React from 'react'
import { useParams } from 'react-router-dom'
import {
  IonContent,
  IonLabel,
  IonFab,
  IonFabButton,
  IonIcon,
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonList,
  IonItem,
  IonListHeader,
} from '@ionic/react'
import { add } from 'ionicons/icons'

import { useSetCurrentClient } from '../../contexts/clientContext'
import { useShowBroadcast } from '../../hooks/broadcast/use-show-broadcast'
import { useIsStore } from '../../auth/utils'
import { useCreateMyBroadcast } from '../../marketing/broadcasts/mutations'
import AddClient from '../client/add-client'

type Props = {
  size?: 'default'
}

const taskMenuItems = [
  { label: 'Add Task', path: '/add/FollowUp' },
  { label: 'Add Appointment', path: '/add/Appointment' },
  { label: 'Add Alteration', path: '/alterations/create' },
  { label: 'Add Special Order', path: '/add/SpecialOrder' },
  { label: 'Add MTM', path: '/add/MTM' },
]

function AddFab({ size }: Props) {
  const [modalVisible, setModalVisible] = React.useState(false)
  const [showAddClient, setShowAddClient] = React.useState(false)

  const setCurrentClient = useSetCurrentClient()
  const { isTristan } = useIsStore()

  const { hasBroadcastSettings } = useShowBroadcast()

  const createBroadcast = useCreateMyBroadcast()

  const params = useParams<{
    className?: string
    id?: string
    objectId?: string
  }>()

  function openClientModal() {
    setShowAddClient(true)
  }

  function closeClientModal() {
    setModalVisible(false)
    setShowAddClient(false)
  }

  function handleFABClick(showModal: boolean) {
    // reset client context if id not present in URL
    if (!params.className && !params.id) {
      setCurrentClient(null)
    }

    // Follow Up Detail has different params
    if (params.className && !params.objectId) {
      setCurrentClient(null)
    }

    // set modal visibility
    setModalVisible(showModal)
  }

  return (
    <>
      <IonFab vertical="bottom" horizontal="end">
        <IonFabButton
          size={size === 'default' ? undefined : 'small'}
          color="yellow"
          onClick={() => handleFABClick(!modalVisible)}
        >
          <IonIcon icon={add} />
        </IonFabButton>
      </IonFab>

      <IonModal
        isOpen={modalVisible}
        onDidDismiss={() => setModalVisible(false)}
      >
        <IonContent>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton
                  onClick={() => {
                    setModalVisible(false)
                  }}
                  color="secondary"
                >
                  Cancel
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonList lines="none">
            <IonListHeader>
              <IonLabel>Communication</IonLabel>
            </IonListHeader>
            {hasBroadcastSettings() ? (
              <IonItem
                color="secondary"
                className="with-space"
                button
                onClick={() => {
                  createBroadcast.mutate({})
                  setModalVisible(false)
                }}
              >
                <IonLabel style={{ textAlign: 'center' }}>
                  Create Broadcast
                </IonLabel>
              </IonItem>
            ) : null}
            <IonItem
              color="secondary"
              className="with-space"
              routerLink="/tabs/clients/list/create"
              button
              onClick={() => setModalVisible(false)}
            >
              <IonLabel style={{ textAlign: 'center' }}>
                Pull Client List
              </IonLabel>
            </IonItem>

            <IonListHeader>
              <IonLabel>Tasks</IonLabel>
            </IonListHeader>

            {taskMenuItems.map((task) => (
              <IonItem
                key={task.path}
                color="secondary"
                className="with-space"
                routerLink={task.path}
                button
                onClick={() => setModalVisible(false)}
              >
                <IonLabel style={{ textAlign: 'center' }}>
                  {task.label}
                </IonLabel>
              </IonItem>
            ))}

            <IonListHeader>
              <IonLabel>Other</IonLabel>
            </IonListHeader>

            {!isTristan() ? (
              <IonItem
                color="secondary"
                className="with-space"
                button
                onClick={() => {
                  openClientModal()
                }}
              >
                <IonLabel style={{ textAlign: 'center' }}>Add Client</IonLabel>
              </IonItem>
            ) : null}

            <IonItem
              color="secondary"
              className="with-space"
              routerLink="/add/Note"
              button
              onClick={() => setModalVisible(false)}
            >
              <IonLabel style={{ textAlign: 'center' }}>Add Note</IonLabel>
            </IonItem>
          </IonList>
        </IonContent>
      </IonModal>

      <IonModal isOpen={showAddClient} onWillDismiss={closeClientModal}>
        <AddClient onDismiss={closeClientModal} />
      </IonModal>
    </>
  )
}

export default AddFab

import {
  IonButton,
  IonSelect,
  IonSelectOption,
  IonRow,
  IonCol,
  useIonToast,
  IonLoading,
} from '@ionic/react'
import { format, parseISO } from 'date-fns'
import { close } from 'ionicons/icons'
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form'

import { replacePlaceholders } from '../../utils/format-helpers'
import {
  useAccount,
  useStylistGroup,
  useUser,
} from '../../contexts/authContext'
import { useAssociates } from '../../hooks/queries/use-associate'
import { TextInput } from '../../components/ui/form/input'
import { updateAutomationSchema } from '../automations-form.schema'
import { sendFrom, triggers } from '../constants'
import { useAutomation } from '../queries'
import { useUpdateAutomation } from '../mutations'
import { useAutomationFormRefs } from '../utils'
import { ConditionsModal } from './conditions-modal'
import CurrentStatus from './current-status'
import { TriggerStep } from './trigger-step'

import type { SelectChangeEventDetail } from '@ionic/react'
import type {
  FromUserRule,
  UpdateAutomationInput,
} from '../automations-form.schema'

interface SelectCustomEvent extends CustomEvent {
  detail: SelectChangeEventDetail<FromUserRule>
  target: HTMLIonSelectElement
}

function composeDefaultValues(
  automation: NonNullable<ReturnType<typeof useAutomation>['data']>,
  stylistGroup: { companyName: string }
): UpdateAutomationInput {
  return {
    objectId: automation.objectId, // TODO - WASN'T THERE IN V1
    type: automation.type, // TODO - WASN'T THERE IN V1
    conditions: automation.conditions,
    description: automation.description ?? '',
    fromUserId: automation.fromUser?.objectId,
    fromName: automation.fromName,
    fromEmailName: automation.fromEmail?.split('@')[0] ?? null,
    fromEmailDomain: automation.fromEmail?.split('@')[1] ?? null,
    fromUserRule: automation.fromUserRule,
    steps: automation.steps.map((step) => ({
      ...step,
      smsMessage: step.smsMessage
        ? replacePlaceholders(step.smsMessage, {
            '<Company>': stylistGroup.companyName,
          })
        : null,
      emailSubject: step.emailSubject
        ? replacePlaceholders(step.emailSubject, {
            '<Company>': stylistGroup.companyName,
          })
        : null,
      emailMessage: step.emailMessage
        ? replacePlaceholders(step.emailMessage, {
            '<Company>': stylistGroup.companyName,
          })
        : null,
    })),

    title: automation.title,
    state: automation.state,
  }
}

function AutomationDetail({
  automation,
}: {
  automation: NonNullable<ReturnType<typeof useAutomation>['data']>
}) {
  const stylistGroup = useStylistGroup()
  const account = useAccount()
  const user = useUser()
  const userHasCustomEmail =
    user.hasSendGrid && account.sendgridDomains.length > 0

  const associatesQuery = useAssociates()
  const flatAssociates =
    associatesQuery.data?.pages
      .flatMap((d) => d.data)
      .filter((associate) =>
        userHasCustomEmail ? true : associate.objectId === user.objectId
      ) ?? []

  const [presentToast, dismissToast] = useIonToast()
  function setToast(message: string) {
    presentToast({
      message,
      color: 'danger',
      duration: 8000,
      position: 'middle',
      buttons: [{ icon: close, role: 'cancel' }],
    })
  }

  const { control, ...formMethods } = useForm<UpdateAutomationInput>({
    defaultValues: composeDefaultValues(automation, stylistGroup),
    values: composeDefaultValues(automation, stylistGroup),
  })

  const stepFields = useFieldArray({ control, name: 'steps' })
  const watchFromUserRule = useWatch({ control, name: 'fromUserRule' })

  const updateAutomation = useUpdateAutomation()

  const refs = useAutomationFormRefs()

  async function validate(values: Partial<UpdateAutomationInput>) {
    await dismissToast()

    const result = updateAutomationSchema.safeParse(values)

    if (!result.success) {
      return setToast(result.error.errors.map((e) => e.message).join('<br/>'))
    }

    // quick check b4 saving draft since backend is more strict about fromUser field existing
    if (values.fromUserRule === 'fromUser' && !values.fromUserId)
      return setToast('Please select a stylist to send the messages from.')

    return result.data
  }

  async function startAutomation(values: UpdateAutomationInput) {
    const validatedValues = await validate(values)
    if (!validatedValues) return

    updateAutomation.mutate({
      objectId: automation.objectId,
      automation: {
        ...validatedValues,
        state: 'active',
      },
    })
  }

  async function saveAsDraft() {
    const formValues = formMethods.getValues()

    const validatedValues = await validate(formValues)
    if (!validatedValues) return

    updateAutomation.mutate({
      objectId: automation.objectId,
      automation: {
        ...validatedValues,
        state: 'draft',
      },
    })
  }

  async function restartAutomation() {
    const formValues = formMethods.getValues()

    const validatedValues = await validate(formValues)
    if (!validatedValues) return

    updateAutomation.mutate({
      objectId: automation.objectId,
      automation: {
        ...validatedValues,
        state: 'active',
      },
    })
  }

  async function stopAutomation() {
    updateAutomation.mutate({
      objectId: automation.objectId,
      automation: {
        state: 'stopped',
      },
    })
  }

  return (
    <div className="space-y-5">
      <IonRow className="justify-between">
        <IonCol>
          <div>
            <p className="leading-tight">
              Created By: {automation.user.displayName}
            </p>
            <p className="leading-tight">
              {automation.state === 'draft' ? 'Created On: ' : 'Started: '}
              {format(parseISO(automation.createdAt), 'M/d/yyyy')}
            </p>
          </div>
        </IonCol>
        <IonCol size="auto">
          <CurrentStatus status={automation.state} />
        </IonCol>
      </IonRow>

      <FormProvider control={control} {...formMethods}>
        <form className="space-y-5">
          {/* Save Options */}
          <div className="flex justify-center gap-4">
            {automation.state === 'draft' ? (
              <>
                <IonButton
                  type="button"
                  color="yellow"
                  fill="outline"
                  size="small"
                  disabled={updateAutomation.isLoading}
                  onClick={saveAsDraft}
                >
                  Save as Draft
                </IonButton>
                <IonButton
                  type="button"
                  color="yellow"
                  size="small"
                  disabled={updateAutomation.isLoading}
                  onClick={() => startAutomation(formMethods.getValues())}
                >
                  Publish
                </IonButton>
              </>
            ) : automation.state === 'active' ? (
              <>
                <IonButton
                  type="button"
                  color="danger"
                  fill="outline"
                  size="small"
                  disabled={updateAutomation.isLoading}
                  onClick={stopAutomation}
                >
                  Stop Automation
                </IonButton>
                <IonButton
                  type="button"
                  color="yellow"
                  size="small"
                  disabled={updateAutomation.isLoading}
                  onClick={() => startAutomation(formMethods.getValues())}
                >
                  Save
                </IonButton>
              </>
            ) : automation.state === 'stopped' ? (
              <>
                <IonButton
                  type="button"
                  color="yellow"
                  fill="outline"
                  size="small"
                  disabled={updateAutomation.isLoading}
                  onClick={restartAutomation}
                >
                  Restart Automation
                </IonButton>
                <IonButton
                  type="button"
                  color="yellow"
                  size="small"
                  disabled={updateAutomation.isLoading}
                  onClick={saveAsDraft}
                >
                  Save Draft
                </IonButton>
              </>
            ) : null}
          </div>

          {/* General Info */}
          <fieldset className="space-y-3">
            <TextInput
              name="title"
              control={control}
              label="Title"
              disabled={updateAutomation.isLoading}
            />

            <TextInput
              name="description"
              control={control}
              label="Description"
              disabled={updateAutomation.isLoading}
            />

            {/* Send From */}
            <div className="border-ion-color-secondary mt-3 rounded-lg border px-3 py-2">
              <h3>Send From</h3>
              <IonRow>
                <IonCol size="6">
                  <Controller
                    name="fromUserRule"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <IonSelect
                        disabled={updateAutomation.isLoading}
                        className="bg-ion-color-step-100 rounded"
                        value={value}
                        // w/o this the form value was missing fields when switching this select w/o selecting id
                        // onIonBlur={recomputeFormValue}
                        onIonChange={(e: SelectCustomEvent) => {
                          onChange(e.detail.value)
                          if (e.detail.value !== 'fromUser') {
                            formMethods.setValue('fromUserId', null, {
                              shouldDirty: true,
                            })
                            formMethods.setValue('fromEmailName', null, {
                              shouldDirty: true,
                            })
                            formMethods.setValue('fromEmailDomain', null, {
                              shouldDirty: true,
                            })
                            formMethods.setValue('fromName', null, {
                              shouldDirty: true,
                            })
                          } else {
                            formMethods.setValue('fromUserId', user.objectId, {
                              shouldDirty: true,
                            })
                            const associate = flatAssociates.find(
                              (a) => a.objectId === user.objectId
                            )
                            if (!associate) return
                            formMethods.setValue(
                              'fromName',
                              associate.displayName
                            )
                            formMethods.setValue(
                              'fromEmailDomain',
                              account.sendgridDomains[0] ?? null
                            )
                            formMethods.setValue(
                              'fromEmailName',
                              associate.twilioProxyEmail?.split('@')[0] ?? null
                            )
                          }
                        }}
                      >
                        {sendFrom.map((from) => (
                          <IonSelectOption key={from.value} value={from.value}>
                            {from.label}
                          </IonSelectOption>
                        ))}
                      </IonSelect>
                    )}
                  />
                </IonCol>

                {watchFromUserRule === 'fromUser' ? (
                  <IonCol size="6">
                    <Controller
                      name="fromUserId"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <IonSelect
                          style={{ '--placeholder-color': 'text-white' }}
                          disabled={updateAutomation.isLoading}
                          className="bg-ion-color-step-100 rounded"
                          value={value}
                          onIonChange={(e) => {
                            const associate = flatAssociates.find(
                              (a) => a.objectId === e.detail.value
                            )
                            if (!associate) return
                            formMethods.setValue(
                              'fromName',
                              associate.displayName
                            )
                            formMethods.setValue(
                              'fromEmailDomain',
                              account.sendgridDomains[0] ?? null
                            )
                            formMethods.setValue(
                              'fromEmailName',
                              associate.twilioProxyEmail?.split('@')[0] ?? null
                            )
                            onChange(e.detail.value)
                          }}
                          placeholder="Select Sylist"
                        >
                          {flatAssociates.map((associate) => (
                            <IonSelectOption
                              key={associate.objectId}
                              value={associate.objectId}
                            >
                              {associate.displayName}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      )}
                    />
                  </IonCol>
                ) : null}
              </IonRow>

              {watchFromUserRule === 'fromUser' &&
              (user.hasSendGrid ||
                formMethods
                  .getValues(['fromEmailName', 'fromEmailDomain', 'fromName'])
                  .some((value) => value != null)) ? (
                <div className="space-y-2 py-4">
                  <div className="flex-shrink-0 basis-2/5">
                    <TextInput
                      control={control}
                      name="fromName"
                      label="Sender Name"
                    />
                  </div>
                  {account.sendgridDomains.length > 0 && user.hasSendGrid && (
                    <div className="flex w-full items-end gap-x-2">
                      <div className="flex-1">
                        <TextInput
                          control={control}
                          name="fromEmailName"
                          className="lowercase"
                          label="Sender Email Address"
                        />
                      </div>
                      <span className="text-lg">@</span>
                      <Controller
                        name="fromEmailDomain"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <IonSelect
                            style={{ '--placeholder-color': 'text-white' }}
                            className="bg-ion-color-step-100 rounded"
                            value={value}
                            onIonChange={(e) => onChange(e.detail.value)}
                          >
                            {account.sendgridDomains.map((d: string) => (
                              <IonSelectOption key={d}>{d}</IonSelectOption>
                            ))}
                          </IonSelect>
                        )}
                      />
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          </fieldset>

          <div>
            <h2 className="text-center text-3xl font-normal">Flow</h2>
            <fieldset className="space-y-5">
              {/* Trigger */}
              <div className="space-y-2">
                <h3 className="text-ion-color-yellow text-xl font-semibold">
                  Trigger
                </h3>

                <IonSelect
                  disabled
                  style={{ '--padding-start': '.75rem' }}
                  value={automation.type}
                  className="trigger-select border-ion-color-yellow w-full rounded border bg-transparent"
                >
                  {triggers.map((item) => (
                    <IonSelectOption key={item.value} value={item.value}>
                      {item.label}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </div>

              {/* Conditions */}
              <Controller
                name="conditions"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <ConditionsModal conditions={value} onSearch={onChange} />
                )}
              />
            </fieldset>
          </div>

          <fieldset>
            {stepFields.fields.map((field, index) => (
              <div key={field.id} className="relative">
                {/* Flow Divider */}
                <div className="bg-ion-color-yellow mx-auto my-6 h-12 w-0.5" />

                <div
                  ref={refs.messageItemRef}
                  id={`message-card-${index + 1}`}
                  className="bg-ion-color-step-150 space-y-3 rounded px-4 py-6"
                >
                  <h2 className="text-ion-color-yellow text-center text-lg font-semibold">
                    Message {index + 1}
                  </h2>
                  <TriggerStep
                    step={automation.steps[index]}
                    index={index}
                    disabled={updateAutomation.isLoading}
                  />
                </div>
              </div>
            ))}
          </fieldset>
        </form>
      </FormProvider>

      <IonLoading
        isOpen={updateAutomation.isLoading}
        message="Updating Automation..."
      />
    </div>
  )
}

export default AutomationDetail

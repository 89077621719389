import * as React from 'react'

import { sleep } from '../utils/sleep'

const DEFAULT_COUNT = 25
const DEFAULT_STEP = 10

type InfiniteProps = {
  count?: number
  step?: number
}

function useInfiniteList({
  count = DEFAULT_COUNT,
  step = DEFAULT_STEP,
}: InfiniteProps = {}) {
  const [infiniteListCount, setInfiniteListCount] = React.useState(count)

  async function doInfiniteStuff(event: any) {
    setInfiniteListCount((count) => count + step)

    // TODO: this is a hack, seems to help from being too jumpy when the list gets long,
    // increaseing threshold doesnt seem to help
    await sleep(200)
    event && event.target && event.target.complete && event.target.complete()
  }

  return { infiniteListCount, doInfiniteStuff }
}

export { useInfiniteList }

import * as React from 'react'

export type ProductShortcut = typeof productShortcuts[number]['value']

const productShortcuts = [
  { label: 'All Products', value: 'all' },
  { label: 'Shoppable', value: 'shoppableOnly' },
  { label: 'Created By Me', value: 'createdByMe' },
] as const
const lookShortcuts = [{ label: 'All Assets', value: 'all' }] as const
const collectionShortcuts = [
  { label: 'All Collections', value: 'all' },
] as const

function useProductShortcuts() {
  const [productShortcut, setProductShortcut] =
    React.useState<typeof productShortcuts[number]['value']>('all')

  return [productShortcut, setProductShortcut] as const
}

function useLookShortcuts() {
  const [lookShortcut, setLookShortcut] =
    React.useState<typeof lookShortcuts[number]['value']>('all')

  return [lookShortcut, setLookShortcut] as const
}

function useCollectionShortcuts() {
  const [collectionShortcut, setCollectionShortcut] =
    React.useState<typeof collectionShortcuts[number]['value']>('all')

  return [collectionShortcut, setCollectionShortcut] as const
}

export {
  productShortcuts,
  lookShortcuts,
  collectionShortcuts,
  useProductShortcuts,
  useLookShortcuts,
  useCollectionShortcuts,
}

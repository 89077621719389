import * as React from 'react'
import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react'

import { DropDownInput } from './dropdown-input'

import type { DayMonthYear } from '../../../clients/client-filters.schema'

type Amount = Partial<DayMonthYear>

// Type guards to narrow down the type
const isDaysSale = (sale: Amount): sale is { days: number } => {
  if (!sale) return false
  return 'days' in sale && typeof sale.days !== 'undefined'
}
const isMonthsSale = (sale: Amount): sale is { months: number } => {
  if (!sale) return false
  return 'months' in sale && typeof sale.months !== 'undefined'
}
const isYearsSale = (sale: Amount): sale is { years: number } => {
  if (!sale) return false
  return 'years' in sale && typeof sale.years !== 'undefined'
}

function getInitialValues(sale?: Amount) {
  const validSale = sale ?? {}
  let unit: 'days' | 'months' | 'years' = 'days'
  let num = ''

  if (isDaysSale(validSale)) {
    unit = 'days'
    num = validSale.days ? `${validSale.days}` : ''
  } else if (isMonthsSale(validSale)) {
    unit = 'months'
    num = validSale.months ? `${validSale.months}` : ''
  } else if (isYearsSale(validSale)) {
    unit = 'years'
    num = validSale.years ? `${validSale.years}` : ''
  }

  return { unit, num }
}

function AmountOfTimeInput({
  initialValue,
  onChange,
}: {
  initialValue: Amount
  onChange: (val: Amount) => void
}) {
  const v = React.useMemo(() => getInitialValues(initialValue), [initialValue])
  const [{ amount, unit }, setValue] = React.useState<{
    amount: string
    unit: 'days' | 'months' | 'years'
  }>(() => ({ amount: `${v.num}`, unit: v.unit }))

  return (
    <div className="flex w-full items-center justify-between">
      <div className="flex items-center">
        <div className="">
          <DropDownInput
            label="Number"
            step="1"
            type="number"
            value={amount}
            onChange={(e) => {
              const { value } = e.currentTarget
              setValue((prev) => ({ ...prev, amount: value }))
              onChange({ [unit]: value ? parseInt(value) : undefined })
            }}
          />
        </div>
        <div className="flex-1">
          <IonItem color="secondary">
            <IonLabel position="stacked">Days/Months/Years</IonLabel>
            <IonSelect
              value={unit}
              onIonChange={(e) => {
                const unit = e.detail.value
                setValue((prev) => ({ ...prev, unit }))
                onChange({ [unit]: amount ? parseInt(amount) : undefined })
              }}
            >
              <IonSelectOption value="days">Days</IonSelectOption>
              <IonSelectOption value="months">Months</IonSelectOption>
              <IonSelectOption value="years">Years</IonSelectOption>
            </IonSelect>
          </IonItem>
        </div>
      </div>
    </div>
  )
}

export { AmountOfTimeInput }

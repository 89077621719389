import {
  IonContent,
  IonHeader,
  IonLoading,
  IonPage,
  useIonRouter,
} from '@ionic/react'
import { useParams } from 'react-router-dom'

import { PageHeader } from '../../../unlayer/components/page-header'
import UnlayerEditor from '../../../unlayer/components/unlayer-editor'
import { useTriggerStep } from '../../../automations/queries'
import { useUpdateTriggerStep } from '../../../automations/mutations'

import type { UnlayerTemplate } from '../../../unlayer/unlayer.schema'

// TODO: ABSTRACTIONS WILL BE NEEDED WHEN INCORPORATING INTO TEMPLATES
function CreateAutomationHtmlEmailPage() {
  const { automationId, stepId } = useParams<{
    automationId: string
    stepId: string
  }>()

  const router = useIonRouter()

  const { data: triggerStep } = useTriggerStep(stepId)
  const updateTriggerStep = useUpdateTriggerStep()

  const queryParams = new URLSearchParams(window.location.search)
  const showDisplayConditions =
    queryParams.get('displayConditions') === 'show' ? 'show' : 'hide'

  function handleConfirm({ html, json }: UnlayerTemplate) {
    updateTriggerStep.mutate(
      {
        objectId: stepId,
        triggerStep: { unlayerDesign: json, emailMessage: html },
      },
      {
        onSuccess: () =>
          router.canGoBack()
            ? router.goBack()
            : router.push(
                `/admin/automations/detail/${automationId}`,
                'back',
                'pop'
              ),
      }
    )
  }

  return (
    <IonPage>
      <IonHeader>
        <PageHeader backRef={`/admin/automations/detail/${automationId}`} />
      </IonHeader>
      <IonContent>
        <UnlayerEditor
          displayConditions={showDisplayConditions}
          unlayerEmail={triggerStep?.unlayerDesign ?? undefined}
          onConfirm={handleConfirm}
        />
      </IonContent>

      <IonLoading
        isOpen={updateTriggerStep.isLoading}
        message="Saving Email Design..."
      />
    </IonPage>
  )
}

export default CreateAutomationHtmlEmailPage

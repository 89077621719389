import type { Children } from '../../../types'

function ApprovalItemWrapper({ children }: Children) {
  return (
    <article className="pb-6 sm:rounded-lg sm:rounded-b-none sm:border sm:border-gray-600">
      {children}
    </article>
  )
}

export { ApprovalItemWrapper }

import { useRef } from 'react'

function useInfiniteData<T>(loadMoreItems: () => Promise<T>) {
  const ionInfiniteScrollRef = useRef<HTMLIonInfiniteScrollElement>(null)

  async function loadMore() {
    await loadMoreItems()
    ionInfiniteScrollRef.current?.complete()
  }

  return [ionInfiniteScrollRef, loadMore] as const
}

export { useInfiniteData }

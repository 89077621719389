import * as React from 'react'
import { IonButton, IonText, useIonRouter } from '@ionic/react'
import { format, parseISO } from 'date-fns'
import clsx from 'clsx'

import avatar from '../../resources/avatar-placeholder.png'
import { formatDateObject } from '../../utils/date-helpers'
import { getFullName } from '../../utils/format-helpers'
import { useAccount } from '../../contexts/authContext'
import { SelectClientDetail } from '../../hooks/clients/queries'
import { useIsStore } from '../../auth/utils'
import OneShopMessaging from '../communication/one-shop-messaging'
import Currency from '../Currency'
import Toggle from '../ui/buttons/toggle'

type InfoProps = {
  client: NonNullable<SelectClientDetail>['ClientDetailsData']
}

function getClientPhoto(client: InfoProps['client']) {
  if (!client.photo) return avatar

  return client.photo
}

function ClientInfo({ client }: InfoProps) {
  const [isOtherInfoOpen, setIsOtherInfoOpen] = React.useState(false)

  const account = useAccount()
  const router = useIonRouter()

  const { isTristan } = useIsStore()

  const fullName = getFullName(client)

  const isUnsubscribed = client.unsubscribedAt || client.posActive === false

  const salesTwoYearsAgo = client.sales24Months - client.sales12Months
  const totalSpent = client.sales.reduce(
    (acc: number, currSale: { value: number }) => acc + currSale.value,
    0
  )
  const loyaltyLink = `${
    router.routeInfo.pathname.includes('tabs') ? '/tabs' : ''
  }/clients/client/${client.objectId}/redeem-loyalty`

  return (
    <section className="ion-padding">
      <div className="flex">
        <div className="flex-1">
          <div className="size-20 overflow-hidden rounded-full">
            <img src={getClientPhoto(client)} alt="" className="w-full" />
          </div>
        </div>
        <div className="ml-2 flex justify-end space-x-3">
          <div className="ion-text-center my-0">
            <span className="block font-semibold">
              {client.lastSaleDate?.iso
                ? format(parseISO(client.lastSaleDate.iso), 'MM/dd/yy')
                : 'N/A'}
            </span>
            <span className="block text-sm">Last Shopped</span>
          </div>
          <div className="ion-text-center my-0">
            <span className="block font-semibold">
              <Currency value={client.sales12Months} abbreviate />
            </span>
            <span className="block text-sm">Last 12 Months</span>
          </div>
          <div className="ion-text-center my-0">
            <IonText color="yellow">
              <span className="block font-semibold">
                <Currency value={totalSpent} abbreviate />
              </span>
              <span className="block text-sm">Total Spent</span>
            </IonText>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div className="flex-1">
          <span className="fontsem block text-xl">{fullName}</span>

          <div className="space-y-2">
            <div>
              {isUnsubscribed ? (
                <IonText color="yellow">Do Not Contact</IonText>
              ) : (
                <OneShopMessaging client={client} />
              )}
            </div>
            {account.account.settings.loyalty ? (
              <IonButton
                routerLink={loyaltyLink}
                size="small"
                color="secondary"
                fill="outline"
              >
                View Loyalty
              </IonButton>
            ) : null}
          </div>
        </div>
        <div className="flex items-center justify-center">
          <div className="ion-text-right">
            <div className="text-sm">
              Last Contacted:{' '}
              {client.lastContact?.iso
                ? format(parseISO(client.lastContact.iso), 'MM/dd/yy')
                : 'Never'}
            </div>
            <div className="text-sm">
              First Sale:{' '}
              {client.firstSaleDate
                ? format(parseISO(client.firstSaleDate.iso), 'MM/dd/yy')
                : 'N/A'}
            </div>
            {!account?.hasAssignAll ? (
              <div className="text-ion-color-yellow text-sm">
                Assigned To:{' '}
                {client.users?.length
                  ? client.users?.map((u) => u.displayName)
                  : 'Not Assigned'}
              </div>
            ) : null}

            {isTristan() && (
              <div className="text-sm">
                Language: {client.posLanguage?.toUpperCase() ?? 'N/A'}
              </div>
            )}
          </div>

          <div className="ml-3">
            <Toggle
              isOpen={isOtherInfoOpen}
              onClick={() => setIsOtherInfoOpen((prev) => !prev)}
            />
          </div>
        </div>
      </div>

      <div
        className={clsx('ion-margin-top', isOtherInfoOpen ? 'block' : 'hidden')}
      >
        {client.nextContact && (
          <div className="text-sm">
            Next Contact: {format(parseISO(client.nextContact.iso), 'MM/dd/yy')}
          </div>
        )}

        <div className="text-sm">
          Two Years Ago: <Currency value={salesTwoYearsAgo} abbreviate />
        </div>

        <div className="text-sm">
          Last Sale: <Currency value={client.lastPurchase.amount} abbreviate />
        </div>
        <div className="text-sm">
          Sold By:{' '}
          {client.lastPurchase.stylist.length > 0
            ? client.lastPurchase.stylist.join(', ')
            : 'N/A'}
        </div>
        <div className="text-sm">
          Sold At:{' '}
          {client.lastPurchase.store.length > 0
            ? client.lastPurchase.store.join(', ')
            : 'N/A'}
        </div>
        {client.dobObject ? (
          <div className="text-sm">
            Birthday: {formatDateObject(client.dobObject)}
          </div>
        ) : null}
      </div>
    </section>
  )
}

export default ClientInfo

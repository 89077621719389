import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { defineCustomElements } from '@ionic/pwa-elements/loader'

import { AuthContextProvider } from './contexts/authContext'
import { ToastProvider } from './contexts/toastContext'
import App from './App'
import * as serviceWorker from './serviceWorker'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      staleTime: 5 * 60 * 60 * 1000,
      retry: 1,
    },
  },
})

const container = document.getElementById('root')
const root = createRoot(container!)

function initSegment() {
  var analytics = (window.analytics = window.analytics || [])
  if (!analytics.initialize)
    if (analytics.invoked)
      window.console &&
        console.error &&
        console.error('Segment snippet included twice.')
    else {
      analytics.invoked = !0
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
        'addSourceMiddleware',
        'addIntegrationMiddleware',
        'setAnonymousId',
        'addDestinationMiddleware',
      ]
      analytics.factory = function (e: any) {
        return function () {
          var t = Array.prototype.slice.call(arguments)
          t.unshift(e)
          analytics.push(t)
          return analytics
        }
      }
      for (var e = 0; e < analytics.methods.length; e++) {
        var t = analytics.methods[e]
        analytics[t] = analytics.factory(t)
      }
      analytics.load = function (e: any, t: any) {
        var n = document.createElement('script')
        n.type = 'text/javascript'
        n.async = !0
        n.src =
          'https://cdn.segment.com/analytics.js/v1/' + e + '/analytics.min.js'
        var a = document.getElementsByTagName('script')[0]
        a.parentNode!.insertBefore(n, a)
        analytics._loadOptions = t
      }
      analytics.SNIPPET_VERSION = '4.1.0'
      analytics.load('PD8etfAHYVEbgfo5LQsXRHDgD6ffN5Ei')
    }
}

if (process.env.NODE_ENV !== 'development') {
  initSegment()
}

root.render(
  <QueryClientProvider client={queryClient}>
    <ToastProvider>
      <AuthContextProvider>
        <App />

        {/* Workaround for https://github.com/tailwindlabs/headlessui/discussions/666 */}
        <div id="headlessui-portal-root">
          {/* It needs at least one child, so that HeadlessUI doesn't remove this portal root workaround
        ( https://github.com/tailwindlabs/headlessui/blob/main/packages/@headlessui-react/src/components/portal/portal.tsx#L84 ) */}
          <div />
        </div>

        <ReactQueryDevtools />
      </AuthContextProvider>
    </ToastProvider>
  </QueryClientProvider>
)

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

import * as React from 'react'
import {
  IonIcon,
  IonImg,
  IonButton,
  useIonAlert,
  IonLoading,
} from '@ionic/react'
import { ellipsisHorizontal } from 'ionicons/icons'
import { Dialog, Transition } from '@headlessui/react'
import { formatDistanceToNow, isAfter, parseISO } from 'date-fns'
import { ResourceType } from '@capawesome/capacitor-cloudinary'

import { useDeletePost } from '../mutations'
import { usePostDetail } from '../queries'
import { AvatarHeader } from '../../../components/ui/avatar-header'
import ExpandableText from '../../../components/ui/expandable-text'
import CldVideoPlayer from '../../../components/cloudinary/cld-video-player'

import type { PostOverview } from '../posts.schema'
import ErrorCard from '../../../components/ui/error-card'

type Props = {
  postId: string
}

function PostDetailUI({ post }: { post: PostOverview }) {
  const [isOpen, setIsOpen] = React.useState(false)

  const [presentAlert] = useIonAlert()

  const deletePost = useDeletePost()

  const isPostScheduled = post.date?.iso
    ? post.status === 'success' && isAfter(parseISO(post.date.iso), Date.now())
    : false
  const canDeletePost = isPostScheduled || post.status === 'pending'

  function handleDeleteProfileLook() {
    presentAlert({
      header: 'Delete Post?',
      message: 'Are you sure you want to delete this post?',
      backdropDismiss: false,
      buttons: [
        'Cancel',
        {
          text: 'Delete',
          role: 'destructive',
          handler: () => {
            deletePost.mutate(post.objectId)
          },
        },
      ],
    })
    setIsOpen(false)
  }

  return (
    <div className="mx-auto max-w-xl space-y-6 overflow-hidden pb-6 pt-3 sm:pt-6">
      <article className="sm:rounded-lg sm:rounded-b-none sm:border sm:border-gray-600">
        <AvatarHeader
          user={post.user}
          action={
            canDeletePost ? (
              <IonButton
                fill="clear"
                size="small"
                color="secondary"
                onClick={() => setIsOpen(true)}
              >
                <IonIcon slot="icon-only" icon={ellipsisHorizontal} />
              </IonButton>
            ) : null
          }
        />

        {post.look.cloudinary?.data?.resource_type === ResourceType.Video ? (
          <div className="aspect-h-16 aspect-w-9">
            <CldVideoPlayer publicId={post.look.cloudinary.data.public_id} />
          </div>
        ) : post.look.cloudinary?.data?.resource_type === ResourceType.Image ? (
          <div className="aspect-h-4 aspect-w-3">
            <IonImg
              src={post.look.cloudinary.data.secure_url}
              className="bg-ion-background-color size-full object-cover object-top"
            />
          </div>
        ) : null}
        <div className="p-2">
          {post.post ? (
            <div className="leading-tight">
              <ExpandableText characterLimit={60}>{post.post}</ExpandableText>
            </div>
          ) : null}
          <div className="text-sm text-gray-400">
            {`${formatDistanceToNow(
              parseISO(post.createdAt.iso)
            )} ago`.toUpperCase()}
          </div>
        </div>
      </article>

      {post.ayrshareErrors?.length ? (
        <ErrorCard>
          {post.ayrshareErrors?.map((error) => (
            <p>
              <strong className="capitalize">{error.platform}: </strong>
              {error.message}
            </p>
          ))}
        </ErrorCard>
      ) : null}

      <Transition show={isOpen} as={React.Fragment}>
        <Dialog onClose={() => setIsOpen(false)} className="relative z-50">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
          </Transition.Child>

          <div className="fixed inset-0 flex items-center justify-center p-4">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-90"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-90"
            >
              <Dialog.Panel className="bg-ion-color-step-100 flex w-full max-w-sm transform flex-col divide-y-[1px] divide-white/20 rounded-2xl shadow-xl transition-all">
                <button
                  className="text-ion-color-danger min-h-[3rem] px-2 py-1 text-center font-semibold"
                  onClick={handleDeleteProfileLook}
                >
                  Delete
                </button>
                <button
                  className="text-ion-color-secondary min-h-[3rem] px-2 py-1 text-center"
                  onClick={() => setIsOpen(false)}
                >
                  Cancel
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}

function PostDetail({ postId }: Props) {
  const postQuery = usePostDetail(postId)

  if (postQuery.data) {
    return <PostDetailUI post={postQuery.data.post} />
  }

  return <IonLoading isOpen message="Loading post..." />
}

export default PostDetail

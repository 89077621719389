import { IonCheckbox } from '@ionic/react'

import { LoyaltyButton } from './loyalty-button'

import type { IonCheckboxCustomEvent } from '@ionic/core'
import type { CheckboxChangeEventDetail } from '@ionic/react'
import type { RewardSet } from '../loyalty.schema'

type Props = {
  bonus: RewardSet
  disabled: boolean
  isDiscountApplied: boolean
  onRedeem: (reward: RewardSet, options?: { redeemEarly: boolean }) => void
  onSelect: (
    event: IonCheckboxCustomEvent<CheckboxChangeEventDetail<RewardSet>>
  ) => void
}

function Bonus({
  bonus,
  disabled,
  isDiscountApplied, // TO PULL OUT?
  onSelect,
  onRedeem,
}: Props) {
  const { loyaltyReward } = bonus

  // TODO: revisit if bonus is ever narrowed in schema/type
  if (!loyaltyReward)
    return (
      <div className="flex items-center gap-x-6">
        <p>There is no bonus to display.</p>
      </div>
    )

  return (
    <div className="flex items-center gap-x-6">
      <div className="flex-0">
        <IonCheckbox onIonChange={onSelect} value={bonus} />
      </div>
      <div className="flex flex-1 flex-col">
        <span className="text-lg font-semibold">
          Redeem {loyaltyReward.text}
        </span>
      </div>
      <div className="flex-0">
        <LoyaltyButton
          disabled={disabled || isDiscountApplied}
          onClick={() => onRedeem(bonus)}
        >
          Redeem
        </LoyaltyButton>
      </div>
    </div>
  )
}

export { Bonus }

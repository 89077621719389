import * as React from 'react'

import { AdjustCardForm } from './adjust-card-form'
import { LoyaltyButton } from './loyalty-button'
import { LoyaltyModal } from './loyalty-modal'

function AdjustCard() {
  const modalRef = React.useRef<HTMLIonModalElement>(null)

  return (
    <>
      <LoyaltyButton id="adjust-modal" fill="solid" color="primary">
        Adjust Total Points
      </LoyaltyButton>

      <LoyaltyModal ref={modalRef} trigger="adjust-modal">
        <div className="bg-ion-color-step-100 w-80 pt-4">
          <AdjustCardForm
            onCancel={async () => await modalRef.current?.dismiss()}
            onSubmit={async () => await modalRef.current?.dismiss()}
          />
        </div>
      </LoyaltyModal>
    </>
  )
}

export { AdjustCard }

import * as React from 'react'
import {
  IonActionSheet,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonItem,
  IonLabel,
  isPlatform,
} from '@ionic/react'
import { usePhoto } from '../hooks/use-photo'
import { close, trash } from 'ionicons/icons'
import type { PhotoData } from '../hooks/use-photo'

interface AddPhotosProps {
  photos: Array<PhotoData>
  onPhotosUpdate: (photos: Array<PhotoData>) => void
  limit?: number
  takePhotoLabel?: string
  selectPhotoLabel?: string
  showPhotos: boolean
}

function AddPhotos({
  photos,
  onPhotosUpdate,
  limit = 15,
  takePhotoLabel = 'TAKE PHOTO',
  selectPhotoLabel = 'SELECT PHOTO',
  showPhotos = false,
}: AddPhotosProps) {
  const [photoToDelete, setPhotoToDelete] = React.useState<PhotoData | null>(
    null
  )
  const { takePhoto, selectPhotos } = usePhoto()

  const isBrowser = !isPlatform('capacitor')

  async function handleTakePhoto() {
    takePhoto((photo) => onPhotosUpdate([...photos, photo]))
  }

  async function handleSelectPhotos() {
    selectPhotos((photo) => onPhotosUpdate([...photos, photo]))
  }

  return (
    <>
      <IonItem
        button
        disabled={photos.length === limit}
        color="secondary"
        onClick={handleTakePhoto}
      >
        <div tabIndex={0} />
        <IonLabel className="ion-text-center">{takePhotoLabel}</IonLabel>
      </IonItem>

      {!isBrowser && (
        <IonItem
          button
          disabled={photos.length === limit}
          color="secondary"
          onClick={handleSelectPhotos}
        >
          <div tabIndex={0} />
          <IonLabel className="ion-text-center">{selectPhotoLabel}</IonLabel>
        </IonItem>
      )}

      {showPhotos ? (
        <IonGrid>
          <IonRow>
            {photos.map((photo, index) => (
              <IonCol size="6" key={index}>
                <IonImg
                  onClick={() => setPhotoToDelete(photo)}
                  src={photo.pic}
                />
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
      ) : null}

      <IonActionSheet
        isOpen={!!photoToDelete}
        buttons={[
          {
            text: 'Delete',
            role: 'destructive',
            icon: trash,
            handler: () => {
              if (photoToDelete) {
                const p = photos.filter(
                  (photo) => photo.name !== photoToDelete.name
                )
                onPhotosUpdate(p)
                setPhotoToDelete(null)
              }
            },
          },
          {
            text: 'Cancel',
            icon: close,
            role: 'cancel',
          },
        ]}
        onDidDismiss={() => setPhotoToDelete(null)}
      />
    </>
  )
}

export default AddPhotos

import React, { createContext } from 'react'
import { IonToast } from '@ionic/react'
import { useImmer } from 'use-immer'
import { close } from 'ionicons/icons'

const ToastContext = createContext()

const ToastProvider = (props) => {
  const [toast, updateToast] = useImmer({
    show: false,
    message: '',
    color: 'tertiary',
    duration: '10000',
    position: 'middle',
  })

  function handleDismiss() {
    updateToast((draft) => {
      draft.show = false
    })
  }

  const setToast = ({
    message,
    color = 'tertiary',
    duration = '10000',
    position = 'middle',
  }) => {
    updateToast((draft) => {
      draft.color = color
      draft.message = message
      draft.duration = duration
      draft.show = true
      draft.position = position
    })
  }

  return (
    <ToastContext.Provider value={setToast}>
      {props.children}

      <IonToast
        onDidDismiss={handleDismiss}
        isOpen={toast.show}
        message={toast.message}
        position={toast.position}
        duration={toast.duration}
        color={toast.color}
        buttons={[{ icon: close, handler: () => handleDismiss() }]}
      />
    </ToastContext.Provider>
  )
}

function useToast() {
  const context = React.useContext(ToastContext)
  if (context === undefined) {
    throw new Error('useToast must be used within a ToastProvider')
  }

  return context
}

export { useToast, ToastProvider }

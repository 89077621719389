import { IonCard, IonCardContent, IonCol, IonGrid, IonRow } from '@ionic/react'
import { format, isBefore, parseISO } from 'date-fns'

import { getDateString } from '../../utils/date-helpers'
import { getClassDisplayString } from '../../constants/classConstants'
import CardItemHeader from './CardItemHeader'
import CardItemPhotos from './CardItemPhotos'

function getColorString(date) {
  if (date.completedAt) {
    return 'success'
  }
  return date.due && isBefore(parseISO(date.due.iso), new Date())
    ? 'danger'
    : 'primary'
}

function TaskContent({ meta, photos }) {
  return (
    <IonCardContent>
      <IonGrid style={{ marginTop: 0 }}>
        <IonRow>
          <IonCol size={12}>
            {meta.map((item, index) => {
              if (!item.label || !item.value) return null

              return (
                <div key={index}>
                  <span style={{ fontWeight: 'bold' }}>{item.label}</span>
                  {item.value}
                </div>
              )
            })}
          </IonCol>
          <CardItemPhotos photosArray={photos} />
        </IonRow>
      </IonGrid>
    </IonCardContent>
  )
}

function CardItemFollowUp({ task }) {
  const dateString = getDateString(task)

  const meta = [
    { label: 'Title: ', value: task.title },
    { label: 'Note: ', value: task.note },
    {
      label: 'Due: ',
      value: task.due ? format(parseISO(task.due.iso), 'MMM d, yyyy') : null,
    },
    {
      label: 'Completed: ',
      value: task.completedAt
        ? format(parseISO(task.completedAt.iso), 'MMM d, yyyy')
        : null,
    },
    { label: 'Vendor: ', value: task.vendor },
    { label: 'Tracking: ', value: task.trackingNumber },
  ]

  return (
    <IonCard color="secondary">
      <CardItemHeader
        user={task}
        date={dateString}
        dateColor={getColorString(task)}
        subtitle={getClassDisplayString(task.className)}
      />
      <TaskContent photos={task.photos} meta={meta} />
    </IonCard>
  )
}

export default CardItemFollowUp

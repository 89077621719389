import { create } from 'zustand'

import type { UnlayerTemplate } from '../../unlayer/unlayer.schema'

type BroadcastState = {
  htmlEmail: UnlayerTemplate | null
}

type BroadcastStore = BroadcastState

const defaultState = () => ({ htmlEmail: null } as BroadcastState)

// look into factoring out user to clean up the logic
const useBroadcastBase = create<BroadcastStore>()((set, get) => ({
  ...defaultState(),
}))

const resetBroadcastHtmlEmail = () =>
  useBroadcastBase.setState({ htmlEmail: null })
const updateBroadcastHtmlEmail = (htmlEmail: UnlayerTemplate) =>
  useBroadcastBase.setState({ htmlEmail })
const useBroadcastHtmlEmail = () => useBroadcastBase((state) => state.htmlEmail)

export {
  resetBroadcastHtmlEmail,
  updateBroadcastHtmlEmail,
  useBroadcastHtmlEmail,
}

import * as React from 'react'
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonImg,
  IonItem,
  IonLabel,
  IonRow,
  IonSelect,
  IonSelectOption,
  isPlatform,
  useIonRouter,
} from '@ionic/react'
import { useImmerReducer } from 'use-immer'

import * as commonStrings from '../../utils/common-strings'
import { formatName, replacePlaceholders } from '../../utils/format-helpers'
import {
  useAccount,
  useStylistGroup,
  useUser,
} from '../../contexts/authContext'
import { useToast } from '../../contexts/toastContext'
import { useOptInMessage } from '../../hooks/opt-in'
import { usePhoto } from '../../hooks/use-photo'
import useNextContact from '../../hooks/use-next-contact'
import { useCurrentSequence } from '../../stores/useSequence'
import AddPhotos from '../AddPhotos'
import TakePhoto from '../communication/TakePhoto'
import SimpleSelectPhoto from '../communication/SimpleSelectPhoto'
import CloseButton from '../ui/buttons/close'
import { TextArea } from '../ui/form/textarea'

function formReducer(draft, action) {
  const { type } = action

  switch (type) {
    case 'UPDATE_FIELD':
      const { name, value } = action
      draft[name] = value
      break
    case 'SELECT_TEMPLATE':
      const { id, template } = action

      if (template) {
        draft.template = id
        draft.message = template.body
      }
      break
    default:
      break
  }
}

const isBrowser = !isPlatform('capacitor')

function SequenceTemplateForm({ templates, type }) {
  const messageRef = React.useRef()
  const optInMessageRef = React.useRef()

  const router = useIonRouter()
  const user = useUser()
  const account = useAccount()
  const stylistGroup = useStylistGroup()

  const optInMessage = useOptInMessage()
  const setToast = useToast()
  const { getPhotoFromUrl } = usePhoto()

  const { dates, nextContactDate, setNextContactDate } = useNextContact()

  const currentSequence = useCurrentSequence()

  const {
    template: { message, ...template },
  } = currentSequence

  const [formState, formDispatch] = useImmerReducer(formReducer, {
    // some templates come with placeholders already
    message: replacePlaceholders(message, {
      '<Stylist>': formatName(user.firstName),
      '<Company>': stylistGroup.companyName,
      '<Store>': stylistGroup.name,
    }),
    ...template,
    optInMessage: replacePlaceholders(optInMessage, {
      '<Stylist>': formatName(user.firstName),
      '<Company>': stylistGroup.companyName,
      '<Store>': stylistGroup.name,
    }),
  })

  const canAddManualPhotos = !user.hasTwilio && !isBrowser
  const canAddApiPhotos = user.hasTwilio

  async function insertStringToBody(subString) {
    const textArea = await messageRef.current.getInputElement()

    const cursorLocation = textArea.selectionStart || 0
    const newMsg =
      formState.message.slice(0, cursorLocation) +
      subString +
      formState.message.slice(cursorLocation)

    formDispatch({ type: 'UPDATE_FIELD', name: 'message', value: newMsg })

    await messageRef.current.setFocus()

    textArea.selectionStart = cursorLocation + subString.length
    textArea.selectionEnd = cursorLocation + subString.length
  }
  async function insertStringToOptInMessage(subString) {
    const textArea = await optInMessageRef.current.getInputElement()

    const cursorLocation = textArea.selectionStart || 0
    const newMsg =
      formState.optInMessage.slice(0, cursorLocation) +
      subString +
      formState.optInMessage.slice(cursorLocation)

    formDispatch({ type: 'UPDATE_FIELD', name: 'optInMessage', value: newMsg })

    await optInMessageRef.current.setFocus()

    textArea.selectionStart = cursorLocation + subString.length
    textArea.selectionEnd = cursorLocation + subString.length
  }

  function updateSequenceTemplate(id) {
    currentSequence.setTemplate({
      message: formState.message,
      nextContactDate,
      photos: formState.photos,
      optInMessage: formState.optInMessage,
      smsTemplateId: id,
    })
  }

  async function handleTemplateSelect(e) {
    const id = e.target.value
    const template = templates.find((template) => template.objectId === id)

    if (!template)
      return setToast({
        message: 'There was a problem loading that template.',
        color: 'danger',
      })

    const formattedTemplate = {
      ...template,
      body: template.body
        .replaceAll('<Stylist>', formatName(user.firstName))
        .replaceAll('<Store>', stylistGroup.name)
        .replaceAll('<StylistId>', user.objectId),
    }
    formDispatch({ type: 'SELECT_TEMPLATE', id, template: formattedTemplate })

    if (!template.attachments) {
      formDispatch({ type: 'UPDATE_FIELD', name: 'photos', value: [] })
      return
    }

    if (canAddManualPhotos) {
      const convertedAttachments = await Promise.all(
        template.attachments.map(async (p) => await getPhotoFromUrl(p))
      )
      formDispatch({
        type: 'UPDATE_FIELD',
        name: 'photos',
        value: convertedAttachments,
      })
    } else if (canAddApiPhotos) {
      formDispatch({
        type: 'UPDATE_FIELD',
        name: 'photos',
        value: template.attachments,
      })
    }
  }

  function handleFormUpdate(e) {
    const { name, value } = e.target

    formDispatch({
      type: 'UPDATE_FIELD',
      name,
      value,
    })
  }

  function handleBeginSequence() {
    // update the sequence store with current chosen template id
    updateSequenceTemplate(formState.template)
  }

  return (
    <>
      <IonCard className="followup-card" color="secondary">
        <IonCardContent>
          <IonItem color="secondary" className="ion-margin-bottom">
            <IonLabel>Select a Saved Template</IonLabel>
            <IonSelect
              id={type}
              interfaceOptions={{ cssClass: 'dark-ionic-filter' }}
              value={formState.template}
              onIonChange={handleTemplateSelect}
              okText={commonStrings.Okay}
              cancelText={commonStrings.Dismiss}
            >
              {templates.map((template, index) => (
                <IonSelectOption key={index} value={template.objectId}>
                  {template.name}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>

          <div>
            <IonLabel position="stacked">Message:</IonLabel>
            <TextArea
              ref={messageRef}
              id="message"
              name="message"
              value={formState.message}
              onIonChange={handleFormUpdate}
              autoGrow
              autocapitalize="on"
            />
          </div>

          <div className="flex items-center justify-between">
            <div className="mt-1 space-x-2">
              <IonButton
                size="small"
                fill="outline"
                onClick={() => insertStringToBody('<First Name>')}
              >
                {commonStrings.FIRSTNAME}
              </IonButton>
              <IonButton
                size="small"
                fill="outline"
                onClick={() => insertStringToBody('<Last Name>')}
              >
                {commonStrings.LASTNAME}
              </IonButton>
            </div>

            {/* Non-Manual/API-driven Attachment Section */}
            {canAddApiPhotos ? (
              <>
                <div className="flex">
                  <TakePhoto
                    onTakePhoto={({
                      name,
                      pic,
                      ios,
                      contentType,
                      fileUri,
                      base64String,
                    }) =>
                      formDispatch({
                        type: 'UPDATE_FIELD',
                        name: 'photos',
                        value: [
                          ...formState.photos,
                          {
                            name,
                            pic,
                            ios,
                            contentType,
                            fileUri,
                            base64String,
                          },
                        ],
                      })
                    }
                    mode="standalone"
                  />
                  {/* For browser this button behaves the same as above, hide it */}
                  {!isBrowser ? (
                    <SimpleSelectPhoto
                      onSelectPhoto={({
                        name,
                        pic,
                        ios,
                        contentType,
                        fileUri,
                        base64String,
                      }) =>
                        formDispatch({
                          type: 'UPDATE_FIELD',
                          name: 'photos',
                          value: [
                            ...formState.photos,
                            {
                              name,
                              pic,
                              ios,
                              contentType,
                              fileUri,
                              base64String,
                            },
                          ],
                        })
                      }
                      dark
                    />
                  ) : null}
                </div>
              </>
            ) : null}
          </div>
          {/* Non-Manual/API-driven Photo Grid */}
          {formState.photos?.length ? (
            <IonGrid>
              <IonRow>
                {formState.photos.map((photo) => (
                  <IonCol size="4" sizeSm="3" key={photo.name ?? photo}>
                    <div className="ion-margin-vertical relative">
                      <IonImg src={photo.pic ?? photo} />
                      <CloseButton
                        inner
                        onClick={() => {
                          formDispatch({
                            type: 'UPDATE_FIELD',
                            name: 'photos',
                            value: formState.photos.filter((p) => {
                              return typeof p === 'string'
                                ? p !== photo
                                : p.name !== photo.name
                            }),
                          })
                        }}
                      />
                    </div>
                  </IonCol>
                ))}
              </IonRow>
            </IonGrid>
          ) : null}

          <IonItem className="ion-margin-top" color="secondary">
            <IonLabel>Follow Up Date:</IonLabel>
            <IonSelect
              id="next-contact-select"
              interfaceOptions={{ cssClass: 'dark-ionic-filter' }}
              value={nextContactDate}
              onIonChange={(e) => setNextContactDate(e.detail.value)}
              okText={'Okay'}
              cancelText={'Dismiss'}
            >
              {dates.map(({ label, value }) => (
                <IonSelectOption key={value} value={value}>
                  {label}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>

          {/* Manual Attachment Section */}
          {canAddManualPhotos && formState.photos ? (
            <AddPhotos
              photos={formState.photos}
              onPhotosUpdate={(photos) =>
                formDispatch({
                  type: 'UPDATE_FIELD',
                  name: 'photos',
                  value: photos,
                })
              }
            />
          ) : null}

          <div className="space-x-2 text-center">
            <IonButton fill="outline" onClick={() => router.goBack()}>
              Cancel
            </IonButton>
            <IonButton color="yellow" onClick={handleBeginSequence}>
              Begin Sequence
            </IonButton>
          </div>
        </IonCardContent>
      </IonCard>

      {account.optinRequired && user.hasTwilio && !user.smsManual ? (
        <IonCard className="followup-card" color="secondary">
          <IonCardContent>
            <div>
              <IonLabel position="stacked">Opt-In Message:</IonLabel>
              <TextArea
                className="min-h-16.5"
                ref={optInMessageRef}
                id="optInMessage"
                name="optInMessage"
                value={formState.optInMessage}
                onIonChange={handleFormUpdate}
                autoGrow
                autocapitalize="on"
              />
            </div>
            <div className="mt-1 space-x-2">
              <IonButton
                size="small"
                fill="outline"
                onClick={() => insertStringToOptInMessage('<First Name>')}
              >
                {commonStrings.FIRSTNAME}
              </IonButton>
              <IonButton
                size="small"
                fill="outline"
                onClick={() => insertStringToOptInMessage('<Last Name>')}
              >
                {commonStrings.LASTNAME}
              </IonButton>
            </div>
          </IonCardContent>
        </IonCard>
      ) : null}
    </>
  )
}

export default SequenceTemplateForm

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { parseDobObject } from '../../utils/client-helpers'
import { client } from '../../services/client'
import { useToast } from '../../contexts/toastContext'
import { loyaltyQueryKeys } from '../../loyalty/queries'
import { createClientOptInResponseSchema } from './customer-opt-in.schema'
import { useCustomerOptInClientId } from './utils'

import type { E164Number } from 'libphonenumber-js'
import type { ApplyLoyaltyReward } from '../../loyalty/loyalty.schema'
import { ZodError } from 'zod'

async function applyRewards({
  clientId,
  rewards,
  options,
}: {
  clientId: string | null
  rewards: Array<ApplyLoyaltyReward>
  options?: { redeemEarly: boolean }
}) {
  if (!clientId) throw new Error('There is no client to display rewards for.')

  return client
    .post('functions/applyLoyaltyRewardManual', {
      json: { clientId, rewards, ...options },
    })
    .json()
}

async function createClientOptIn(e164: E164Number) {
  const response = await client
    .post('functions/optInClientInPerson', {
      json: {
        e164,
      },
    })
    .json()

  try {
    createClientOptInResponseSchema.parse(response)
  } catch (e) {
    const errorMessage = e instanceof Error ? e.message : 'Unknown Error'
    return Promise.reject(new Error(`Something went wrong. ${errorMessage}`))
  }
  const result = createClientOptInResponseSchema.parse(response).result
  return {
    client: {
      ...result.client,
      dobObject: parseDobObject(result.client.dobObject),
    },
  }
}

function useApplyClientRewards() {
  const clientId = useCustomerOptInClientId()
  const queryClient = useQueryClient()
  const setToast = useToast()

  return useMutation(
    (variables: {
      rewards: Array<ApplyLoyaltyReward>
      options?: { redeemEarly: boolean }
    }) => applyRewards({ clientId, ...variables }),
    {
      onError: (error) => {
        const message =
          error instanceof Error ? error.message : 'Unknown error.'

        setToast({
          message,
          color: 'danger',
        })
      },
      onSettled: () => {
        queryClient.invalidateQueries(loyaltyQueryKeys.clientLoyalty(clientId))
      },
      onSuccess: () =>
        setToast({
          message: 'The loyalty reward was successfully applied.',
          color: 'yellow',
        }),
    }
  )
}

function useOptInClient() {
  const setToast = useToast()

  return useMutation(createClientOptIn, {
    onError: (error) => {
      console.log('createClientOptInError')
      const message = error instanceof Error ? error.message : 'Unknown error.'

      setToast({
        message,
        color: 'danger',
      })
    },
  })
}

export { useApplyClientRewards, useOptInClient }

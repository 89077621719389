import * as React from 'react'
import { useInView } from 'react-intersection-observer'
import { name } from '@cloudinary/url-gen/actions/namedTransformation'

import { oneshopCld } from '../../utils/cloudinary'

import type { AutoplayMode } from '../../types/cloudinary'

function useCloudinaryVideo(
  publicId: string,
  config: { autoplayMode?: AutoplayMode } = {}
) {
  const videoRef = React.useRef<HTMLVideoElement | null>(null)
  const cloudinaryRef = React.useRef<any>()
  const playerRef = React.useRef<any>()
  const sourceRef = React.useRef<string>()

  // lazyload and autoplay workaround - cloudinary player not autoplaying as expected so handling manually
  const [inViewRef] = useInView({
    root: null,
    threshold: 0.2,
    // triggerOnce: config.autoplayMode === 'on-scroll' ? false : true,
    onChange: handleVidInView,
  })

  // Store the Cloudinary window instance to a ref when the page renders
  React.useEffect(() => {
    if (cloudinaryRef.current) return

    cloudinaryRef.current = window.cloudinary as any

    playerRef.current = cloudinaryRef.current?.videoPlayer(videoRef.current, {
      cloud_name: 'oneshop',
      secure: true,
      // Player Visuals
      showLogo: false,
      controls: true,
      posterOptions: {
        transformation: { transformation: '9x16_video_poster' },
      },
      // Player Behavior
      playsinline: true,
    })
  }, [])

  function handleVidInView(inView: boolean) {
    if (!playerRef.current) return

    if (inView) {
      if (!sourceRef.current) {
        sourceRef.current = publicId
        playerRef.current.source(publicId, config)
      }

      config.autoplayMode === 'on-scroll' && playerRef.current.play()
      return
    }

    config.autoplayMode === 'on-scroll' && playerRef.current.pause()
  }

  return {
    videoRef,
    inViewRef,
    poster: oneshopCld
      .image(publicId)
      .namedTransformation(name('9x16_video_poster'))
      .setAssetType('video')
      .format('auto:image')
      .toURL(),
  }
}

export { useCloudinaryVideo }

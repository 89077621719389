import { useMutation, useQueryClient } from '@tanstack/react-query'

import { client } from '../../services/client'
import { notificationQueryKeys } from './queries'

async function updateNotificationStatus(objectId: string) {
  const readAt = new Date().toISOString()

  return client.post('functions/updateNotification', {
    json: {
      objectId,
      readAt,
    },
  })
}

function useUpdateNotificationStatus() {
  const queryClient = useQueryClient()
  return useMutation((objectId: string) => updateNotificationStatus(objectId), {
    onSettled: () =>
      queryClient.invalidateQueries(notificationQueryKeys.list()),
  })
}

export { useUpdateNotificationStatus }

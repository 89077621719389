import * as React from 'react'

import { EditAdjustmentForm } from './edit-adjustment-form'
import { LoyaltyButton } from './loyalty-button'
import { LoyaltyModal } from './loyalty-modal'

type Props = {
  objectId: string
  value: number | null
}

function EditAdjustment({ objectId, value }: Props) {
  const modalRef = React.useRef<HTMLIonModalElement>(null)

  return (
    <>
      <LoyaltyButton
        id={`edit-adjustment-modal-${objectId}`}
        fill="outline"
        color="secondary"
      >
        Edit
      </LoyaltyButton>

      <LoyaltyModal
        ref={modalRef}
        trigger={`edit-adjustment-modal-${objectId}`}
      >
        <div className="bg-ion-color-step-100 w-80 pt-4">
          <EditAdjustmentForm
            objectId={objectId}
            onCancel={async () => await modalRef.current?.dismiss()}
            onSubmit={async () => await modalRef.current?.dismiss()}
            value={value}
          />
        </div>
      </LoyaltyModal>
    </>
  )
}

export { EditAdjustment }

import {
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonLoading,
  IonPage,
} from '@ionic/react'
import { useParams } from 'react-router-dom'

import { useBasicClient } from '../../hooks/clients/queries'
import { useHeartlandSalesperson } from '../../integrations/heartland/queries'
import { useHeartlandParams } from '../../integrations/heartland/utils'
import { Header } from '../../loyalty/components/header'
import { CreateAlterationForm } from '../../tasks/components/create-alteration-form'

// http://localhost:3000/heartland/LIAPZUXNbo/redeem-loyalty?account_uuid=599d089f-0e25-43e0-8e31-94f24e20047b&customer_id=100002&pos_url=https%3A%2F%2Fclientelier.retail.heartland.us%2F%23pos%2F160347&subdomain=clientelier&ticket_id=160347&transaction_status=incomplete
function HeartlandCreateAlterationPage() {
  const { posUrl, subdomain, ticketId } = useHeartlandParams()
  const params = useParams<{ clientId: string }>()

  const client = useBasicClient(params.clientId)
  const salesperson = useHeartlandSalesperson({ subdomain, ticketId })

  const isLoaded = client.data && salesperson.data

  return (
    <IonPage>
      <IonHeader>
        <Header returnUrl={posUrl} title="Create Alteration" />
      </IonHeader>
      <IonContent>
        {isLoaded ? (
          <IonCard>
            <IonCardContent>
              <CreateAlterationForm
                client={client.data.client}
                onSuccess={() => {
                  if (posUrl) window.location.replace(posUrl)
                }}
                salespersonId={
                  salesperson.data.users.length
                    ? salesperson.data.users[0].objectId
                    : undefined
                }
              />
            </IonCardContent>
          </IonCard>
        ) : (
          <IonLoading isOpen message="Loading..." />
        )}
      </IonContent>
    </IonPage>
  )
}

export { HeartlandCreateAlterationPage }

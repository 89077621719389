import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react'
import { Redirect } from 'react-router-dom'

import { useCreateClient } from '../../hooks/clients/mutations'
import {
  useCheckInValues,
  useCheckInActions,
} from '../../check-in/check-in-store'
import { CustomerCheckInForm } from '../../rotate/components/customer-check-in-form'

import type { CheckInFormInputs } from '../../schema/add-client.schema'

function CashierAddCustomerPage() {
  const initialValues = useCheckInValues()
  const { resetClientInfo } = useCheckInActions()

  const router = useIonRouter()

  const createClient = useCreateClient()

  function handleSubmit(formData: CheckInFormInputs) {
    createClient.mutate(formData, {
      onSuccess: (data) => {
        resetClientInfo()
        router.push(
          `/rotate/cashier/loyalty-info/${data.result.client.objectId}`
        )
      },
    })
  }

  if (!initialValues.phoneNumber)
    return <Redirect exact to="/rotate/cashier/check-in" />

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Add Customer</IonTitle>
          <IonButtons slot="start">
            <IonButton
              color="secondary"
              fill="outline"
              onClick={() => {
                resetClientInfo()
                if (router.canGoBack()) router.goBack()
                else router.push('/rotate/cashier/check-in', 'back')
              }}
            >
              Cancel
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding-horizontal">
        <div className="h-full">
          <div className="mx-auto flex size-full max-w-xl flex-col items-center justify-center">
            <h1 className="text-5xl">Add Customer</h1>
            <div className="py-3" />
            <p className="text-3xl">
              That customer is not in our records. Please add them here.
            </p>
            <div className="py-6" />
            <CustomerCheckInForm
              disabled={createClient.isLoading}
              onSubmit={handleSubmit}
              initialValues={initialValues}
            />
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}

export { CashierAddCustomerPage }

import * as React from 'react'
import {
  IonPage,
  IonContent,
  IonHeader,
  IonLoading,
  useIonViewWillEnter,
  IonCol,
  IonGrid,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRow,
  IonSegment,
  IonSegmentButton,
} from '@ionic/react'
import { useParams } from 'react-router-dom'
import { format, isAfter, parseISO, subDays } from 'date-fns'

import { getOptInStatus, hasVerifiedCell } from '../../utils/client-helpers'
import { isSameOrBefore } from '../../utils/date-helpers'
import { useAccount } from '../../contexts/authContext'
import { useMyCallListDetail } from '../../hooks/outreach/queries'
import { useInfiniteList } from '../../hooks/use-infinite-list'
import { useClientSorter } from '../../hooks/use-sorter-action-sheet'
import Header from '../../components/header'
import Avatar from '../../components/avatar'
import ClientItem from '../../components/client/client-item'
import AddFab from '../../components/fab/add-fab'
import BulkMessageButton from '../../components/ui/buttons/bulk-message-button'
import { Chip } from '../../components/ui/buttons/chip'
import { SortButton } from '../../components/ui/buttons/sort-button'
import Toggle from '../../components/ui/buttons/toggle'
import ScrollingList from '../../components/ui/scrolling-list'
import { StickyHeader } from '../../components/ui/sticky-header'

type Segment = 'contacted' | 'not-contacted'

const clientShortcuts = [
  'All Clients',
  'Opted-In',
  'Verified Cell',
  'Has Phone',
  'Email Only',
  'Never Contacted',
] as const
type ClientShortcut = typeof clientShortcuts[number]

function getVisibleClients(
  callList: ReturnType<typeof useMyCallListDetail>['data'],
  segment: Segment
) {
  if (!callList) return []

  if (segment === 'not-contacted') {
    if (callList.callListType === 'ongoing')
      return callList.clients.filter(
        (client) =>
          !client.lastContact ||
          isSameOrBefore(
            parseISO(client.lastContact.iso),
            subDays(new Date(), 14)
          )
      )
    else if (callList.callListType === 'onetime')
      return callList.clients.filter(
        (client) =>
          !client.lastContact ||
          isSameOrBefore(
            parseISO(client.lastContact.iso),
            parseISO(callList.startDate.iso)
          )
      )
  }

  if (segment === 'contacted') {
    if (callList.callListType === 'ongoing')
      return callList.clients.filter(
        (client) =>
          client.lastContact &&
          isAfter(parseISO(client.lastContact.iso), subDays(new Date(), 14))
      )
    else if (callList.callListType === 'onetime')
      return callList.clients.filter(
        (client) =>
          client.lastContact &&
          isAfter(
            parseISO(client.lastContact.iso),
            parseISO(callList.startDate.iso)
          )
      )
  }

  // fallback
  return callList.clients
}

function CallListDetail() {
  const [isOtherInfoOpen, setIsOtherInfoOpen] = React.useState(false)
  const [segment, setSegment] = React.useState<Segment>('not-contacted')
  const [clientShortcut, setClientShortcut] =
    React.useState<ClientShortcut>('All Clients')

  const account = useAccount()

  const { sortCallback, openSorterActionSheet } = useClientSorter()
  const { callListId } = useParams<{ callListId: string }>()

  const callListDetailQuery = useMyCallListDetail(callListId)

  const { infiniteListCount, doInfiniteStuff } = useInfiniteList()

  const visibleClients = React.useMemo(
    () =>
      [...getVisibleClients(callListDetailQuery.data, segment)]
        .sort(sortCallback)
        .filter((client) => {
          if (clientShortcut === 'Opted-In') {
            return getOptInStatus(client.phoneNumbers) === 'OPTED_IN'
          }
          if (clientShortcut === 'Verified Cell') {
            return hasVerifiedCell(client.phoneNumbers)
          }
          if (clientShortcut === 'Has Phone') {
            return client.phoneNumbers.length > 0
          }
          if (clientShortcut === 'Email Only') {
            return client.email && client.phoneNumbers.length === 0
          }

          if (clientShortcut === 'Never Contacted') {
            return !client.lastContact
          }
          return client
        }),

    [callListDetailQuery.data, clientShortcut, segment, sortCallback]
  )

  // TODO _ POSSIBLY REPLACE WITH INVALIDATION WHEN MSG SENT AND UPDATED
  useIonViewWillEnter(() => {
    callListDetailQuery.refetch()
  }, [callListDetailQuery.refetch])

  return (
    <IonPage>
      <AddFab />
      <IonHeader>
        <Header backRef="/tabs/clients" title="Outreach" />
      </IonHeader>

      <IonContent>
        {callListDetailQuery.data ? (
          <>
            <div className="ion-padding">
              <h1 className="text-center text-2xl">
                {callListDetailQuery.data.name}
              </h1>
              {callListDetailQuery.data.callListType === 'onetime' ? (
                <div className="flex w-full items-center justify-center text-sm">
                  <p className="flex-1 text-center">
                    <span className="font-semibold">Start Date: </span>
                    {format(
                      parseISO(callListDetailQuery.data.startDate.iso),
                      'MMM dd, yyyy'
                    )}
                  </p>

                  <p className="flex-1 text-center">
                    <span className="font-semibold">End Date: </span>
                    {format(
                      parseISO(callListDetailQuery.data.endDate.iso),
                      'MMM dd, yyyy'
                    )}
                  </p>
                </div>
              ) : null}

              <div className="flex w-full items-center justify-between">
                {callListDetailQuery.data.user ? (
                  <div className="flex items-center">
                    <Avatar user={callListDetailQuery.data.user}></Avatar>
                    <div className="ion-margin-start">
                      <p className="text-sm">Created By</p>
                      <p className="font-semibold">
                        {callListDetailQuery.data.user.firstName}{' '}
                        {callListDetailQuery.data.user.lastName}
                      </p>
                    </div>
                  </div>
                ) : null}

                <div className="ml-2">
                  <Toggle
                    isOpen={isOtherInfoOpen}
                    onClick={() => setIsOtherInfoOpen((prev) => !prev)}
                  />
                </div>
              </div>

              {isOtherInfoOpen ? (
                <div className="mt-4 space-y-2">
                  <p>{callListDetailQuery.data.description}</p>
                </div>
              ) : null}
            </div>

            <StickyHeader>
              <div className="ion-padding-top ion-padding-horizontal">
                {callListDetailQuery.data.callListType === 'onetime' ? (
                  <IonSegment
                    value={segment}
                    onIonChange={(e) => setSegment(e.detail.value as any)}
                  >
                    <IonSegmentButton value="not-contacted">
                      Not Contacted
                    </IonSegmentButton>
                    <IonSegmentButton value="contacted">
                      Contacted
                    </IonSegmentButton>
                  </IonSegment>
                ) : callListDetailQuery.data.callListType === 'ongoing' ? (
                  <IonSegment
                    value={segment}
                    onIonChange={(e) => setSegment(e.detail.value as any)}
                  >
                    <IonSegmentButton value="all">All</IonSegmentButton>
                    <IonSegmentButton value="not-contacted">
                      <div className="flex flex-col">
                        <span>Not Contacted</span>
                        <span>in 14 Days</span>
                      </div>
                    </IonSegmentButton>
                    <IonSegmentButton value="contacted">
                      <div className="flex flex-col">
                        <span>Contacted</span>
                        <span>in 14 days</span>
                      </div>
                    </IonSegmentButton>
                  </IonSegment>
                ) : null}
              </div>

              <div className="mt-3">
                <ScrollingList>
                  {clientShortcuts.map((filter) => {
                    if (
                      !account.hasPhoneVerificationCredits &&
                      filter === 'Verified Cell'
                    )
                      return null
                    if (
                      account.hasPhoneVerificationCredits &&
                      filter === 'Has Phone'
                    )
                      return null
                    if (!account.optinRequired && filter === 'Opted-In')
                      return null

                    return (
                      <Chip
                        key={filter}
                        fill={clientShortcut !== filter ? 'outline' : 'solid'}
                        onClick={() => setClientShortcut(filter)}
                      >
                        {filter}
                      </Chip>
                    )
                  })}
                </ScrollingList>
              </div>

              <div className="ion-margin-top">
                <IonGrid
                  style={{ '--ion-grid-padding': 0 }}
                  className="ion-padding-horizontal"
                >
                  <IonRow className="ion-nowrap ion-align-items-center">
                    <IonCol className="flex-full">
                      <div className="flex items-center gap-x-2">
                        {visibleClients.length > 0 && (
                          <BulkMessageButton
                            clientList={visibleClients}
                            filter={callListDetailQuery.data.filter}
                            template={callListDetailQuery.data.template}
                            pointers={{
                              callListId: callListDetailQuery.data.objectId,
                            }}
                          />
                        )}
                        <h3 className="text-ion-color-yellow">
                          {visibleClients.length} Client
                          {visibleClients.length === 1 ? '' : 's'}
                        </h3>
                      </div>
                    </IonCol>

                    <IonCol>
                      <SortButton onClick={openSorterActionSheet} />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </StickyHeader>

            <div className="ion-padding-bottom">
              {visibleClients.length > 0 ? (
                <>
                  {visibleClients.slice(0, infiniteListCount).map((client) => (
                    <ClientItem key={client.objectId} client={client} />
                  ))}
                  <IonInfiniteScroll
                    onIonInfinite={doInfiniteStuff}
                    disabled={infiniteListCount >= visibleClients.length}
                  >
                    <IonInfiniteScrollContent loadingSpinner="bubbles" />
                  </IonInfiniteScroll>
                </>
              ) : (
                <div className="ion-padding-horizontal">
                  <p>You have no clients on this list.</p>
                </div>
              )}
            </div>
          </>
        ) : callListDetailQuery.error ? (
          <div className="ion-padding">
            <p>
              {callListDetailQuery.error instanceof Error
                ? callListDetailQuery.error.message
                : 'An unknown error occurred.'}
            </p>
          </div>
        ) : (
          <IonLoading isOpen message={'Loading the client list...'} />
        )}
      </IonContent>
    </IonPage>
  )
}

export default CallListDetail

import {
  IonCardHeader,
  IonCardTitle,
  IonText,
  IonRow,
  IonCard,
  IonCardContent,
  IonSkeletonText,
} from '@ionic/react'

const DashboardCard = ({
  title,
  value,
  value2,
  percentChange,
  isRed,
  hideChange = false,
  isLoading,
}) => {
  return (
    <IonCard color="secondary" className="m-4 flex flex-1 flex-col">
      <IonCardHeader className="text-center">
        <IonCardTitle>{title}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent className="mt-auto">
        <IonRow className="justify-evenly">
          {isLoading ? (
            <h1 className="w-2/5">
              <IonSkeletonText
                animated
                style={{
                  '--background': 'rgba(0,0,0,.65)',
                  '--background-rgb': '0,0,0',
                }}
                className="size-full m-0 leading-normal"
              />
            </h1>
          ) : (
            <h1>{value}</h1>
          )}

          {value2 ? <h1>{isLoading ? '-' : value2}</h1> : null}
        </IonRow>
        <IonRow className="ion-justify-content-center text-center">
          {isLoading ? (
            <p className="w-3/5">
              <IonSkeletonText
                animated
                style={{
                  '--background': 'rgba(0,0,0,.65)',
                  '--background-rgb': '0,0,0',
                }}
                className="size-full m-0 leading-normal"
              />
            </p>
          ) : (
            <p>
              <IonText color={isRed ? 'danger' : 'primary'} hidden={hideChange}>
                {percentChange ? (percentChange > 0 ? '+' : '') : ''}
                {percentChange}% vs previous year
              </IonText>
            </p>
          )}
        </IonRow>
      </IonCardContent>
    </IonCard>
  )
}

export default DashboardCard

import { IonHeader, IonModal, IonTitle, IonToolbar } from '@ionic/react'

import { AddGroupMemberForm } from './add-group-member-form'

import type { AddGroupMember } from '../user-setup.schema'

function AddGroupMemberModal({
  data,
  onClose,
  open,
}: {
  data: Pick<AddGroupMember, 'posId' | 'userId'>
  onClose: () => void
  open: boolean
}) {
  return (
    <IonModal
      isOpen={open}
      onDidDismiss={onClose}
      style={{ '--height': 'fit-content' }}
    >
      <IonHeader>
        <IonToolbar color="secondary">
          <IonTitle>Add User Role</IonTitle>
        </IonToolbar>
      </IonHeader>
      <div className="ion-padding bg-ion-color-secondary">
        <AddGroupMemberForm
          data={data}
          onSuccess={onClose}
          onCancel={onClose}
        />
      </div>
    </IonModal>
  )
}

export { AddGroupMemberModal }

import { useQuery } from '@tanstack/react-query'
import { fromZodError } from 'zod-validation-error'
import { z } from 'zod'

import {
  makePaginatedServerResponseSchema,
  makeServerResponseSchema,
} from '../utils/schema'
import { client } from '../services/client'
import { useToast } from '../contexts/toastContext'
import { useCustomerOptInClientId } from '../marketing/customer-opt-in/utils'
import {
  loyaltyRuleBaseSchema,
  loyaltyRuleDetailSchema,
  manualLoyaltySchema,
} from './loyalty.schema'
import { loyaltyQuerySelector } from './utils'

type CustomRules = Pick<
  NonNullable<ReturnType<typeof useClientLoyalty>['data']>,
  'bonusRules' | 'rewardRules'
>

const loyaltyQueryKeys = {
  all: [{ entity: 'loyalty' }] as const,
  clientLoyalty: (clientId: string | null) =>
    [
      {
        ...loyaltyQueryKeys.all[0],
        scope: 'client',
        clientId,
      },
    ] as const,
  loyaltyPrograms: () =>
    [{ ...loyaltyQueryKeys.all[0], scope: 'programs' }] as const,
  loyaltyProgramDetail: (loyaltyId: string) => [
    { ...loyaltyQueryKeys.loyaltyPrograms()[0], loyaltyId },
  ],
}

async function fetchClientLoyalty(clientId: string | null) {
  if (!clientId) throw new Error('There is no client to display rewards for.')

  const response = await client
    .post('functions/getClientLoyaltyManual', {
      json: { objectId: clientId, redeemEarly: true },
    })
    .json()

  const parsedResponse =
    makeServerResponseSchema(manualLoyaltySchema).safeParse(response)

  if (!parsedResponse.success)
    throw new Error(fromZodError(parsedResponse.error).message)

  return parsedResponse.data.result
}

function useClientLoyalty() {
  const clientId = useCustomerOptInClientId()
  const setToast = useToast()

  return useQuery({
    queryKey: loyaltyQueryKeys.clientLoyalty(clientId),
    queryFn: () => fetchClientLoyalty(clientId),
    enabled: Boolean(clientId),
    select: loyaltyQuerySelector,
    onError(error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown Error.'

      setToast({
        message: `Something went wrong while loading the loyalty rewards info: ${errorMessage}`,
        color: 'danger',
      })
    },
  })
}

function useLoyaltyPrograms() {
  return useQuery({
    queryKey: loyaltyQueryKeys.loyaltyPrograms(),
    queryFn: async () => {
      const response = await client.post('functions/getLoyaltyRules').json()

      const parsedResponse = makePaginatedServerResponseSchema(
        z.array(loyaltyRuleBaseSchema)
      ).safeParse(response)

      if (!parsedResponse.success)
        throw new Error(fromZodError(parsedResponse.error).message)

      return parsedResponse.data.result
    },
  })
}

function useLoyaltyProgram(loyaltyId: string) {
  return useQuery({
    queryKey: loyaltyQueryKeys.loyaltyProgramDetail(loyaltyId),
    queryFn: async () => {
      const response = await client
        .post('functions/getLoyaltyRule', { json: { objectId: loyaltyId } })
        .json()

      const parsedResponse = makeServerResponseSchema(
        loyaltyRuleDetailSchema
      ).safeParse(response)

      if (!parsedResponse.success)
        throw new Error(fromZodError(parsedResponse.error).message)

      return parsedResponse.data.result
    },
  })
}

export type { CustomRules }
export {
  loyaltyQueryKeys,
  useClientLoyalty,
  useLoyaltyProgram,
  useLoyaltyPrograms,
}

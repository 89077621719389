import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export interface DataSet {
  label: 'All' | 'Email' | 'Text' | 'Phone Call'
  data: Array<number>
  total: number
  messageCount: number
}

interface RoiChartProps {
  labels?: Array<string>
  datasets?: Array<DataSet>
}

const colors = {
  All: {
    border: 'rgb(179, 152, 0)',
    background: 'rgba(179, 152, 0, 0.5)',
    // border: 'rgb(255, 99, 132)',
    // background: 'rgba(255, 99, 132, 0.5)',
  },
  Email: {
    // border: 'rgb(53, 162, 235)',
    // background: 'rgba(53, 162, 235, 0.5)',
    border: 'rgb(11, 113, 137)',
    background: 'rgba(11, 113, 137, 0.5)',
  },
  Text: {
    border: 'rgb(34, 140, 219)',
    background: 'rgba(34, 140, 219, 0.5)',
  },
  'Phone Call': {
    border: 'rgb(156, 149, 220)',
    background: 'rgba(156, 149, 220, 0.5)',
  },
}

function RoiChart({ datasets = [], labels = [] }: RoiChartProps) {
  const data = {
    datasets: datasets.map((dataset) => ({
      ...dataset,
      borderColor: colors[dataset.label]?.border ?? 'rgb(156, 149, 220)',
      backgroundColor:
        colors[dataset.label]?.background ?? 'rgba(156, 149, 220, 0.5)',
    })),
    labels,
  }
  return (
    <div style={{ height: '30vh', minHeight: '200px' }}>
      <Line
        data={data}
        options={{
          plugins: {
            legend: {
              position: 'bottom',
            },
          },
          maintainAspectRatio: false,

          scales: {
            x: {
              title: { display: true, text: 'Week Starting' },
            },
            y: {
              ticks: { maxTicksLimit: 4, minRotation: 90 },
              title: { display: true, text: '$' },
            },
          },
        }}
      />
    </div>
  )
}

export default RoiChart

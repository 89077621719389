import {
  IonMenuButton,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton,
  IonButton,
  IonIcon,
} from '@ionic/react'
import { searchOutline } from 'ionicons/icons'

import headerIcon from '../resources/OneShop_Logo_White.png'
import { authService } from '../services/authService'
import { useAccount, useUser } from '../contexts/authContext'
import { isSocialOnly } from '../auth/utils'
import { AlertsButton } from './header/alerts-button'
import ChatBubble from './header/ChatBubble'

type HeaderProps = {
  title?: string
  backRef?: string
  showMenu?: boolean
  btn?: React.ReactNode
}

function SearchButton() {
  return (
    <IonButton
      style={{
        '--padding-start': 0,
        '--padding-end': 0,
        '--overflow': 'initial',
      }}
      routerLink="/search"
      routerDirection="none"
      color="secondary"
    >
      <IonIcon slot="icon-only" icon={searchOutline} />
    </IonButton>
  )
}

function DefaultBtns() {
  const account = useAccount()
  const { hasTwilio } = useUser()

  if (isSocialOnly(account)) return <AlertsButton />

  return (
    <>
      <SearchButton />
      <AlertsButton />
      {hasTwilio && <ChatBubble />}
    </>
  )
}

function Header({
  title = '',
  backRef = '/tabs/home',
  showMenu = false,
  btn = <DefaultBtns />,
}: HeaderProps) {
  return (
    <IonToolbar>
      <IonButtons slot="start">
        {showMenu ? (
          <IonMenuButton color="secondary" />
        ) : (
          <IonBackButton color="secondary" defaultHref={backRef} />
        )}
      </IonButtons>

      {title ? (
        <IonTitle>
          <h2 className="text-xl capitalize">{title}</h2>
        </IonTitle>
      ) : (
        <IonTitle>
          <img
            src={headerIcon}
            alt=""
            style={{ height: '36px', marginTop: '4px' }}
          />
        </IonTitle>
      )}

      {authService.getLoggedIn() && (
        <IonButtons className="space-x-2" slot="end">
          {btn}
        </IonButtons>
      )}
    </IonToolbar>
  )
}

export default Header

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
} from '@ionic/react'

import { useCheckIn, useResetActiveClientStations } from '../mutations'
import { Card, CardContent } from '../../components/ui/card'
import { useCheckInValues } from '../../check-in/check-in-store'

type Props = {
  onDismiss: () => void
  clientId: string
}

function BusyStationModal({ onDismiss, clientId }: Props) {
  const { phoneNumber: e164, email } = useCheckInValues()

  const { mutate: checkIn } = useCheckIn(clientId)
  const { mutate: resetClientStations } = useResetActiveClientStations()

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Oops!</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <Card>
          <CardContent>
            <p>There seems to be someone already checked in on this station.</p>
            <IonButton
              onClick={() => {
                resetClientStations(undefined, {
                  onSuccess: () => {
                    checkIn({ e164, email })
                    onDismiss()
                  },
                })
              }}
            >
              Retry
            </IonButton>
          </CardContent>
        </Card>
      </IonContent>
    </IonPage>
  )
}

export { BusyStationModal }

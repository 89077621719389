import type { TriggerStep } from './automations-base.schema'
import type { CreateAutomationInput } from './automations-form.schema'

type Templates = {
  firstSale: CreateAutomationInput
  recovery: CreateAutomationInput
  loyaltyAlmostThere: CreateAutomationInput
  loyaltyEarned: CreateAutomationInput
  newSubscriber: CreateAutomationInput
}

const defaultStep: Omit<TriggerStep, 'objectId' | 'position'> = {
  delayAmount: 7,
  delayUnit: 'days',
  smsMessage: '',
  emailSubject: '',
  emailMessage: '',
  messageType: 'prioSmsEmail',
  unlayerDesign: null,
  unlayerImage: null,
  unlayerImageFull: null,
  unlayerTemplateId: null,
}

const automationTemplates: Templates = {
  firstSale: {
    title: 'New Customers Welcome Flow',
    state: 'draft',
    description:
      'This automation is to welcome your new customers to your store by your staff',
    fromUserRule: 'assignedTo',
    fromEmailDomain: null,
    fromEmailName: null,
    fromName: null,
    type: 'firstSale',
    conditions: {},
    steps: [
      {
        ...defaultStep,
        position: 0,
        delayAmount: 3,
        delayUnit: 'hours',
        messageType: 'prioSmsEmail',
        smsMessage:
          "Hi <First Name>, thanks for your recent purchase at <Company> <Store>! As a thank you, enjoy 10% off your next buy, valid for 60 days. Remember, we're here if you need any assistance. See you soon! - <Stylist>",
        emailSubject: 'Welcome to <Company>, <First Name>!',
        emailMessage:
          "Hi <First Name>\n\nI just want to reach out and say thank you for your recent visit and purchase at <Company> <Store>. We're delighted to have you as part of our community, and we hope you enjoyed your shopping experience with us.\n\nYour support allows us to continue providing high-quality products and exceptional customer service that we take great pride in.\n\nRemember, if you have any questions or need assistance with your purchase, don't hesitate to get in touch. We're here to help!\n\nAs a token of our gratitude, here's 10% off on your next purchase valid for the next 60 days (just show this email at the register). We hope to see you again soon!\n\nBest regards,\n<Stylist>\n<Company>",
      },
      {
        ...defaultStep,
        position: 1,
        delayAmount: 7,
        delayUnit: 'days',
        messageType: 'prioSmsEmail',
        smsMessage:
          "Hi <First Name>, how's is your new merchandise treating you? If you have any questions or there is anything else we can do, please let me know!",
        emailSubject: "How's Your purchase?",
        emailMessage:
          "Dear <First Name>,\n\nWe hope this email finds you well. It's been a few days since your visit to <Company> <Store Name>, and I wanted to check in and see how you're enjoying your purchase?\n\nYour satisfaction is our priority so if you have any questions or need any assistance, please feel free to reach out to us. Whether you need help with usage instructions, maintenance tips, or anything else, our team and I are more than happy to assist.\n\nAlso, we'd love to hear your thoughts on your overall experience. Your feedback helps us improve our products and services, and ensures you and all our valued customers get the best possible shopping experience.\n\nDon't forget, you still have a 10% discount on your next purchase. We can't wait to welcome you back!\n\nWishing you all the best,\n<Stylist>\n<Company>",
      },
      {
        ...defaultStep,
        position: 2,
        delayAmount: 8,
        delayUnit: 'days',
        messageType: 'prioSmsEmail',
        smsMessage:
          "I just saw that you still have 10% off your next purchase, but it is expiring in a few weeks so didn't want you to forget. Discover amazing items tailored to your tastes. Reach out if you need any help. See you soon! - <Stylist>",
        emailSubject: 'Your 10% Discount is Waiting, <First Name>!',
        emailMessage:
          "Hi <First Name>,\n\nWe haven't seen you in a while, and I just wanted to remind you that your exclusive 10% off coupon is still waiting for you! There's no better time to come back to <Company> and discover more amazing items tailored to your tastes.\n\nIt is only valid for 2 more weeks so I didn't want you to miss out on it. Just show this email during your next visit, and you will be good to go.\n\nIf you have any questions or need assistance, please feel free to reach out. We're always here to help!\n\nSee you soon,\n<Stylist>\n<Company>",
      },
    ],
  },
  recovery: {
    title: 'Win Back Flow',
    state: 'draft',
    description:
      'This automation is to get customers that have not shopped in a while to come shop again',
    fromUserRule: 'assignedTo',
    fromEmailDomain: null,
    fromEmailName: null,
    fromName: null,
    type: 'sale',
    conditions: {
      noSales: { gt: 120, delayUnit: 'days' },
    },
    steps: [
      {
        ...defaultStep,
        position: 0,
        delayAmount: 120,
        delayUnit: 'days',
        messageType: 'prioSmsEmail',
        smsMessage:
          "Hey <First Name>, we've missed you at <Company>! New collections & offers await you. Hope to see you soon, and please reach out if we can help in any way.",
        emailSubject: "We've Missed You at <Company>!",
        emailMessage:
          "Hello <First Name>,\n\nIt's been a while since we last saw you at <Company>! We hope all is well. We've added some new collections and offers that we think you'll love.\n\nRemember, shopping local helps support your community, and we're always here to provide you with quality products and a personal touch.\n\nWarmly,\nThe <Company> Team",
      },
      {
        ...defaultStep,
        position: 1,
        delayAmount: 14,
        delayUnit: 'days',
        messageType: 'prioSmsEmail',
        smsMessage:
          'Here is a gift for you, <First Name>: Get 20% off your next buy at <Company>! Just show this text at the register. Valid for 30 days.',
        emailSubject: 'A Special Gift Just for You 🎁',
        emailMessage:
          "Hi <First Name>,\n\nAs a token of our appreciation for being a valued customer, we'd like to offer you an exclusive 20% off your next purchase. Simply show this email at the checkout to use your discount on next favorite purchase.\n\nOffer valid for the next 30 days so don't wait. \n\nLooking forward to seeing you soon!\n\nBest Wishes,\nThe <Company> Team",
      },
      {
        ...defaultStep,
        position: 2,
        delayAmount: 21,
        delayUnit: 'days',
        messageType: 'prioSmsEmail',
        smsMessage:
          'We have new arrivals at <Company>! Use your 20% off & discover something special. Visit us soon!',
        emailSubject: 'Fresh Arrivals Await You at <Company>!',
        emailMessage:
          "Hello <First Name>,\n\nWe thought you might like to know about our latest arrivals. There's something for everyone so you're sure to find your next gotta-have item. And don't forget, you have an exclusive 20% discount waiting for you!\n\nAs always, let us know if there is anything we can do to help.\n\nCheers,\nThe <Company> Team",
      },
      {
        ...defaultStep,
        position: 3,
        delayAmount: 4,
        delayUnit: 'days',
        messageType: 'prioSmsEmail',
        smsMessage:
          "Last chance, <First Name>! Your 20% off at <Company> expires soon. Don't miss out!",
        emailSubject:
          '<First Name> - Last Chance to Use Your Exclusive Discount!',
        emailMessage:
          "Dear <First Name>,\n\nYour exclusive 20% discount is expiring soon!\n\nDon't miss out on this special opportunity to find something wonderful at <Company>. We have a variety of items that are sure to catch your eye.\n\nWe hope to see you before your offer ends! Just show this email at the register to claim your discount.\n\nWarm Regards,\nThe <Company> Team",
      },
    ],
  },
  newSubscriber: {
    title: 'New Subscriber',
    state: 'draft',
    description:
      'This automation is to communicate with shoppers who sign up to your list through a form. This is not tied to a sale and may or may not mean they have shopped yet',
    fromUserRule: 'assignedTo',
    type: 'clientCreated',
    conditions: {},
    steps: [
      {
        ...defaultStep,
        position: 0,
        delayAmount: 2,
        delayUnit: 'minutes',
        messageType: 'prioSmsEmail',
        smsMessage:
          'Hi <First Name> - Thank you so much for signing up for texts from <Company>! Reply back STOP if you no longer want to hear from us',
        emailSubject: 'Thank you for Subscribing! 🌟',
        emailMessage:
          "Hi <First Name> -\n\nThank you so much for subscribing to emails from <Company>!\n\nWe're thrilled to have you join our community and can't wait to keep you in the loop with all the exciting updates and special offers.\n\nVisit us in-store or shop online!\n\nWarmly,\n\nThe <Company> Team",
      },
    ],
  },
  loyaltyEarned: {
    title: 'Loyalty Earned',
    state: 'draft',
    description:
      'This automation is to let customers know they just earned a loyalty reward in your loyalty program',
    fromUserRule: 'assignedTo',
    fromEmailDomain: null,
    fromEmailName: null,
    fromName: null,
    type: 'sale',
    conditions: {},
    steps: [
      {
        ...defaultStep,
        position: 0,
        delayAmount: 3,
        delayUnit: 'hours',
        messageType: 'prioSmsEmail',
        smsMessage:
          'Hi <First Name> - Congrats! You have hit <Active Loyalty Points> points and earned <Active Loyalty Rewards> in rewards at <Company>!',
        emailSubject:
          "🎉 Congratulations, <First Name>! You've Earned a Reward with <Company>! 🎉",
        emailMessage:
          "Dear <First Name>,\n\nWe're thrilled to share some exciting news with you! As a valued member of our loyalty program, your recent shopping has paid off. You've reached an impressive milestone of <Active Loyalty Points> loyalty points, and as a result, you've earned <Active Loyalty Rewards> in rewards!\n\nHere at <Company>, we're all about celebrating your milestones and making your shopping experience even more rewarding. Your new reward is our way of saying a big thank you for your continued support and loyalty.\n\n**Here’s how you can enjoy your rewards:**\n- Visit us at any <Company> store and present your loyalty number or this email at checkout.\n\n**Don’t stop now!** Keep shopping to earn more points and unlock even more fantastic rewards. Remember, every purchase brings you closer to the next exciting milestone.\n\nGot questions or need assistance? Our customer service team is here to help. \n\nThank you for being an integral part of the <Company> family. We look forward to celebrating many more milestones with you.\n\nWarmest regards,\n\nThe <Company> Team",
      },
    ],
  },
  loyaltyAlmostThere: {
    title: 'Loyalty - Almost There',
    state: 'draft',
    description:
      'This automation is to let customers know they are close to earning a loyalty reward in your loyalty program',
    fromUserRule: 'assignedTo',
    fromEmailDomain: null,
    fromEmailName: null,
    fromName: null,
    type: 'sale',
    conditions: {},
    steps: [
      {
        ...defaultStep,
        position: 0,
        delayAmount: 7,
        delayUnit: 'days',
        messageType: 'prioSmsEmail',
        smsMessage:
          'Hi <First Name> - You currently have <Active Loyalty Points> points and are so close to earning rewards at <Company>!',
        emailSubject:
          'You’re on the Verge of Unlocking a Reward at <Company>! 🌟',
        emailMessage:
          "Hi <First Name> - You currently have <Active Loyalty Points> points and are so close to earning rewards at <Company>!\n\nYour dedication and loyalty to <Company> are truly appreciated, and we’re excited to see you reach this fantastic milestone. Every purchase you make brings you one step closer to unlocking your next reward.\n\nDon’t miss out on this opportunity to maximize your rewards with us. Whether it's a treat for yourself or a gift for someone special, your next purchase could be the key to unlocking <Active Loyalty Rewards> in rewards.\n\nVisit us in-store or shop online at CompanyWebsite.com to add to your points and claim your rewards. Your next reward is just around the corner, and we can’t wait to celebrate this achievement with you.\n\nThank you for being a loyal <Company> shopper. Here’s to many more rewarding experiences ahead!\n\nWarmly,\nThe <Company> Team",
      },
    ],
  },
}

export { automationTemplates, defaultStep }

import { createContext, useContext, useMemo } from 'react'
import { IonButton } from '@ionic/react'

import { insertPlaceholder } from '../../../utils/forms'

type ButtonProps = Pick<
  React.ComponentProps<typeof IonButton>,
  'children' | 'disabled' | 'onClick'
> & {
  placeholder: string
}

type Props = {
  children: React.ReactNode
  disabled?: boolean
  mergeRef: React.RefObject<HTMLIonTextareaElement>
  onInsert: (str: string) => void
}

const MergeFieldContext = createContext<Omit<Props, 'children'> | null>(null)

function useMergeFieldContext() {
  const context = useContext(MergeFieldContext)
  if (!context)
    throw new Error(
      'Merge Buttons can only be used as a child of MergeFieldButtons.'
    )

  return context
}

function MergeFieldButtons({ children, disabled, mergeRef, onInsert }: Props) {
  const value = useMemo(
    () => ({ disabled, mergeRef, onInsert }),
    [disabled, mergeRef, onInsert]
  )
  return (
    <MergeFieldContext.Provider value={value}>
      <div className="space-x-2">{children}</div>
    </MergeFieldContext.Provider>
  )
}

function MergeFieldButton({ children, disabled, placeholder }: ButtonProps) {
  const { mergeRef, onInsert } = useMergeFieldContext()

  return (
    <IonButton
      size="small"
      className="text-xs"
      fill="outline"
      disabled={disabled}
      onClick={() =>
        insertPlaceholder(
          {
            placeholder,
            textAreaRef: mergeRef,
          },
          onInsert
        )
      }
    >
      {children}
    </IonButton>
  )
}

export { MergeFieldButton, MergeFieldButtons }

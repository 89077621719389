import * as React from 'react'
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonImg,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonInput,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { useCreateAccount } from './account/mutations'

import logo from './resources/OneShop_Logo_White.png'

function AddAccount() {
  const createAccount = useCreateAccount()
  const [companyName, setCompanyName] = React.useState('')

  async function handleCreateAccount(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    createAccount.mutate(
      {
        companyName,
      },
      {
        onSuccess: () => {
          window.location.reload()
        },
      }
    )
  }

  return (
    <IonPage id="setup">
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            <h2>Set Up</h2>
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonGrid className="h-full">
          <IonRow className="h-full items-center justify-center">
            <IonCol size="12" className="text-center">
              <IonImg src={logo} alt="Oneshop Logo" className="h-16" />
              <div className="mx-auto max-w-lg">
                <IonCard color={'secondary'}>
                  <IonCardContent>
                    <form onSubmit={handleCreateAccount}>
                      <IonItem color={'secondary'}>
                        <IonLabel position="stacked">Company Name</IonLabel>
                        <IonInput
                          name="companyName"
                          type="text"
                          value={companyName}
                          onIonChange={(e) => setCompanyName(e.detail.value!)}
                          autocapitalize="off"
                          required
                        />
                      </IonItem>
                      <IonGrid className="text-center">
                        <IonButton
                          type="submit"
                          disabled={createAccount.isLoading}
                        >
                          Set Up
                        </IonButton>
                      </IonGrid>
                    </form>
                  </IonCardContent>
                </IonCard>
              </div>

              <div className="ion-text-center">
                Already have a company set up? Please contact support at
                support@oneshopretail.com to get added.
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export { AddAccount }

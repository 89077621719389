import * as React from 'react'
import {
  IonButton,
  IonContent,
  IonLabel,
  IonPage,
  IonHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLoading,
  IonList,
  IonNote,
} from '@ionic/react'
import { useRouteMatch } from 'react-router-dom'
import { format, parseISO } from 'date-fns'

import { getFullName } from '../../utils/format-helpers'
import { useCallLists } from '../../hooks/outreach/queries'
import { useInfiniteData } from '../../hooks/use-infinite-data'
import Avatar from '../../components/avatar'
import AdminHeader from '../../components/header/admin-header'
import {
  ListItem,
  ListItemHeading,
  ListItemSubHeading,
} from '../../components/ui/list-item'
import { StickyHeader } from '../../components/ui/sticky-header'

function Outreach() {
  const { url } = useRouteMatch()

  const callListsQuery = useCallLists()
  const [ionInfiniteScrollRef, loadMore] = useInfiniteData(
    callListsQuery.fetchNextPage
  )

  return (
    <IonPage>
      <IonHeader>
        <AdminHeader title="Outreach" showMenu />
      </IonHeader>
      <IonContent>
        <StickyHeader>
          <div className="ion-padding">
            <IonButton
              color="yellow"
              fill="solid"
              size="small"
              routerLink="/admin/outreach/create"
            >
              Create
            </IonButton>
          </div>
        </StickyHeader>

        {callListsQuery.data ? (
          <>
            <IonList>
              {callListsQuery.data.pages.map((callListPage, i) => (
                <React.Fragment key={i}>
                  {callListPage.data.map((callList) => (
                    <ListItem
                      key={callList.objectId}
                      routerLink={`${url}/detail/${callList.objectId}`}
                    >
                      {callList.user ? <Avatar user={callList.user} /> : null}

                      <IonLabel className="ml-4">
                        <ListItemHeading>
                          <span>{callList.name}</span>
                        </ListItemHeading>
                        {callList.startDate && callList.endDate ? (
                          <ListItemSubHeading>
                            {`${format(
                              parseISO(callList.startDate.iso),
                              'MMM d, yyyy'
                            )} - ${format(
                              parseISO(callList.endDate.iso),
                              'MMM d, yyyy'
                            )}`}
                          </ListItemSubHeading>
                        ) : null}
                        <ListItemSubHeading>
                          Created by {getFullName(callList.user)}
                        </ListItemSubHeading>
                      </IonLabel>
                      <IonNote>{callList.stats.clientCount} clients</IonNote>
                    </ListItem>
                  ))}
                </React.Fragment>
              ))}
            </IonList>
            <IonInfiniteScroll
              ref={ionInfiniteScrollRef}
              onIonInfinite={loadMore}
              threshold="100px"
              disabled={!callListsQuery.hasNextPage}
            >
              <IonInfiniteScrollContent
                loadingSpinner="bubbles"
                loadingText="loading more conversations..."
              />
            </IonInfiniteScroll>
          </>
        ) : (
          <IonLoading message="Loading Outreach" isOpen />
        )}
      </IonContent>
    </IonPage>
  )
}

export default Outreach

import { useState } from 'react'

import type {
  IonSegmentCustomEvent,
  SegmentChangeEventDetail,
} from '@ionic/core'

function assertIsSegment<
  T extends readonly [string, ...Array<string>],
  U extends T[number]
>(str: unknown, segs: T): str is U {
  return typeof str === 'string' && segs.includes(str)
}

function useIonSegment<
  T extends readonly [string, ...Array<string>],
  U extends T[number]
>(segments: T, startingSegment: U) {
  const [currentSegment, setCurrentSegment] =
    useState<typeof segments[number]>(startingSegment)

  function handleSegmentChange(
    event: IonSegmentCustomEvent<SegmentChangeEventDetail>
  ) {
    const {
      detail: { value },
    } = event
    if (!value) throw new Error('This Segment has no value.')
    if (!assertIsSegment(value, segments))
      throw new Error('This is not an allowed Segment value.')

    setCurrentSegment(value)
  }

  return [currentSegment, handleSegmentChange] as const
}

export { useIonSegment }

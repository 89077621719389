import { z } from 'zod'

type ServerStatus = z.infer<typeof serverStatusSchema>
const serverStatusSchema = z.enum([
  'canceled',
  'draft',
  'failed',
  'pending',
  'queued',
  'success',
])

export type { ServerStatus }
export { serverStatusSchema }

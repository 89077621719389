import {
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonImg,
  IonRow,
} from '@ionic/react'
import DOMPurify from 'dompurify'

import * as commonStrings from '../../utils/common-strings'
import { getDateString } from '../../utils/date-helpers'
import CardItemHeader from './CardItemHeader'

function MessageContent({ meta, attachments }) {
  return (
    <IonCardContent>
      <IonGrid style={{ marginTop: 0 }}>
        <IonRow>
          <IonCol size={12}>
            {meta.map((item, index) => {
              if (!item.label || !item.value) return null

              return (
                <div key={index}>
                  <span style={{ fontWeight: 'bold' }}>{item.label}</span>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(item.value),
                    }}
                  ></div>
                </div>
              )
            })}
          </IonCol>
        </IonRow>
        {attachments?.length ? (
          <IonRow>
            <IonCol size={12}>
              {attachments.map((url, index) => {
                return (
                  <div key={index}>
                    <IonImg
                      style={{
                        maxWidth: '100px',
                        maxHeight: '100px',
                      }}
                      src={url}
                    />
                  </div>
                )
              })}
            </IonCol>
          </IonRow>
        ) : null}
      </IonGrid>
    </IonCardContent>
  )
}

const CardItemMessage = ({ message }) => {
  const dateString = getDateString(message)

  const user = {
    firstName: message.firstName,
    lastName: message.lastName,
    photo: message.userPhoto,
  }

  let meta = [
    { label: 'Log: ', value: message.log ? 'manual' : '' },
    { label: 'Subject: ', value: message.subject },
    { label: 'Body: ', value: message.body },
    {
      label: message.type === 'phone' ? 'Result: ' : 'Body: ',
      value: message.data,
    },
    { label: 'Note: ', value: message.note },
  ]

  let type = commonStrings.Message

  switch (message.type) {
    case 'sms':
      type = 'text'
      break
    case 'phone':
      type = 'phone'
      break
    case 'email':
      type = 'email'
      break
    case 'letter':
      type = 'letter'
      break
    default:
      break
  }

  return (
    <IonCard color="secondary">
      <CardItemHeader user={user} date={dateString} subtitle={type} />
      <MessageContent meta={meta} attachments={message.attachments} />
    </IonCard>
  )
}

export default CardItemMessage

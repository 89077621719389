import * as React from 'react'
import {
  IonContent,
  IonIcon,
  IonItem,
  IonInput,
  IonLabel,
  IonPage,
  IonButton,
  IonTextarea,
  IonCard,
  IonGrid,
  IonCardContent,
  IonLoading,
  IonHeader,
  IonCheckbox,
  useIonRouter,
} from '@ionic/react'
import { chevronUp, chevronDown } from 'ionicons/icons'
import { useRouteMatch } from 'react-router-dom'
import {
  addHours,
  addYears,
  parseISO,
  startOfDay,
  subYears as subtractYears,
} from 'date-fns'
import { useQueryClient } from '@tanstack/react-query'

import * as commonStrings from '../../utils/common-strings'
import {
  classConstants,
  getClassDisplayString,
  hasTitleArray,
  hasVendorArray,
  hasTrackingNumberArray,
  hasDueDateArray,
  hasNoteArray,
  hasClientArray,
  hasPhotos,
} from '../../constants/classConstants'
import { authService } from '../../services/authService'
import { fileService } from '../../services/fileService'
import { searchService } from '../../services/searchService'
import { useCurrentClient } from '../../contexts/clientContext'
import { useToast } from '../../contexts/toastContext'
import { taskQueryKeys } from '../../hooks/tasks/queries'
import Header from '../../components/header'
import SearchBar from '../../components/SearchBar'
import VendorList from '../../components/VendorList'
import NewEmailComponent from '../../components/communication/NewEmailComponent'
import DatePicker from '../../components/ui/ionic/date-picker'
import AddPhotos from '../../components/AddPhotos'
import '../../assets/css/AddClass.css'
import { clientQueryKeys } from '../../hooks/clients/queries'

const AddClass = () => {
  const match = useRouteMatch()
  const currentClient = useCurrentClient()
  const router = useIonRouter()

  const [client, setClient] = React.useState()
  const [clientId, setClientId] = React.useState()
  const [title, setTitle] = React.useState()
  const [note, setNote] = React.useState()
  const [trackingNumber, setTrackingNumber] = React.useState()
  const [panelOpen, setPanelOpen] = React.useState(false)
  const [selectedDate, setSelectedDate] = React.useState()
  const [vendor, setVendor] = React.useState()

  const [loading, setLoading] = React.useState(false)

  const [photos, setPhotos] = React.useState([])
  const [isPublic, setIsPublic] = React.useState(true)

  const setToast = useToast()

  const queryClient = useQueryClient()

  const canMakePrivate = [classConstants.CLASSNAME_NOTE].every(
    (type) => type !== match.params.className
  )

  React.useEffect(() => {
    if (currentClient) {
      setClient(currentClient)
      setClientId(currentClient.objectId)
    }

    // reset current client when leaving the page
    return () => {
      setClient(null)
      setClientId(null)
    }
  }, [currentClient])

  async function saveClass() {
    try {
      const body = {
        user: {
          __type: 'Pointer',
          className: '_User',
          objectId: authService.getUserId(),
        },
      }
      const files = []
      setLoading(true)

      await Promise.all(
        photos.map(async (photo, i) => {
          files.push(
            await fileService.saveBase64Image(photo.name, photo.base64String)
          )
        })
      )

      if (match.params.className !== classConstants.CLASSNAME_CLIENT) {
        body.client = {
          __type: 'Pointer',
          className: 'Client',
          objectId: clientId,
        }
      }

      if (title) body.title = title
      if (note) body.note = note
      if (vendor) body.vendor = vendor
      if (trackingNumber) body.trackingNumber = trackingNumber
      if (files.length > 0) body.photos = files

      if (selectedDate) {
        body.due = {
          __type: 'Date',
          iso: addHours(startOfDay(parseISO(selectedDate)), 6).toISOString(),
        }
      }

      if (canMakePrivate) {
        body.isPublic = isPublic
      }

      await searchService.createObject(`${match.params.className}`, body)

      await queryClient.invalidateQueries(taskQueryKeys.list())
      await queryClient.invalidateQueries(clientQueryKeys.details())

      setLoading(false)
      router.canGoBack ? router.goBack() : router.push('/tasks', 'back', 'pop')
    } catch (error) {
      console.log('add class error: ', error)
      setLoading(false)
      setToast({
        message: `Oops something went wrong: ${error.message}`,
        color: 'danger',
      })
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <Header
          title={`Add ${getClassDisplayString(match.params.className)}`}
          backRef="/tabs/clients"
        />
      </IonHeader>
      <IonContent>
        {hasClientArray.includes(match.params.className) ? (
          <SearchBar
            initialText={
              client ? `${client.firstName} ${client.lastName}` : null
            }
            clientIdCallback={(clientId) => {
              setClientId(clientId)
            }}
            clientCallback={(client) => {
              setClient(client)
            }}
          />
        ) : null}

        <IonCard color={commonStrings.secondary}>
          <IonCardContent>
            {hasVendorArray.includes(match.params.className) ? (
              <VendorList
                handleVendorUpdate={(newVendor) => setVendor(newVendor)}
              />
            ) : null}

            {hasTrackingNumberArray.includes(match.params.className) ? (
              <IonItem color="secondary">
                <IonLabel position="floating">Tracking Number</IonLabel>
                <IonInput
                  onIonChange={(e) => setTrackingNumber(e.detail.value)}
                />
              </IonItem>
            ) : null}

            {hasTitleArray.includes(match.params.className) ? (
              <IonItem color="secondary">
                <IonLabel position="floating">Title</IonLabel>
                <IonInput
                  value={title}
                  onIonChange={(e) => setTitle(e.detail.value)}
                />
              </IonItem>
            ) : null}

            {hasNoteArray.includes(match.params.className) ? (
              <IonItem color="secondary">
                <IonLabel position="floating">{commonStrings.Note}</IonLabel>
                <IonTextarea
                  autocapitalize="on"
                  autoGrow
                  rows={Math.max((note || '').split(/\r\n|\r|\n/).length, 5)}
                  value={note}
                  onIonChange={(e) => setNote(e.detail.value)}
                />
              </IonItem>
            ) : null}

            {hasDueDateArray.includes(match.params.className) ? (
              <DatePicker
                label="Due Date"
                onChange={(e) => setSelectedDate(e.detail.value)}
                value={selectedDate}
                min={subtractYears(new Date(), 1)}
                max={addYears(new Date(), 1)}
              />
            ) : null}

            {hasTrackingNumberArray.includes(match.params.className) ? (
              <IonItem
                color="secondary"
                onClick={() => setPanelOpen(!panelOpen)}
              >
                <IonLabel style={{ textAlign: 'center' }}>
                  SHIPMENT ALERT EMAIL
                </IonLabel>
                <IonIcon icon={panelOpen ? chevronUp : chevronDown} />
              </IonItem>
            ) : null}
            {panelOpen ? (
              <NewEmailComponent
                to={client ? client.email : null}
                clientId={client ? client.objectId : null}
                onEmailSend={() => setPanelOpen(false)}
              />
            ) : (
              ''
            )}

            {hasPhotos.includes(match.params.className) ? (
              <AddPhotos
                photos={photos}
                onPhotosUpdate={setPhotos}
                showPhotos
              />
            ) : null}

            {canMakePrivate ? (
              <IonItem color="secondary">
                <IonLabel>Make Private</IonLabel>
                <IonCheckbox
                  name="isPublic"
                  value={!isPublic}
                  onIonChange={(e) => setIsPublic(!e.detail.value)}
                />
              </IonItem>
            ) : null}
          </IonCardContent>

          <IonGrid style={{ textAlign: 'center' }}>
            <IonButton
              onClick={() => saveClass()}
              disabled={loading || !clientId}
            >
              {commonStrings.FINISH}
            </IonButton>
          </IonGrid>
        </IonCard>
      </IonContent>
      <IonLoading
        isOpen={loading}
        onDidDismiss={() => setLoading(false)}
        message={'Please wait...'}
      />
    </IonPage>
  )
}

export default AddClass

import * as React from 'react'
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonModal,
  IonNote,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { useImmerReducer } from 'use-immer'
import {
  CheckboxChangeEventDetail,
  SearchbarChangeEventDetail,
} from '@ionic/core'
import { isString } from 'remeda'
import { useVirtualizer } from '@tanstack/react-virtual'
import { matchSorter } from 'match-sorter'

import { isStringArray } from '../../../utils/typescript-helpers'

import type { ListObject } from '../../../types/general'

type Items = Array<string> | Array<ListObject>

type Props = {
  items: Items
  label: string
  onOpen?: () => void
  selectedItems: Array<string>
  setSelectedItems: (items: Array<string>) => void
}
type State = {
  checkedItems: Array<string>
  searchText: string
  searchResults: Items
}
type Action =
  | {
      type: 'loadItems'
      payload: {
        items: Items
      }
    }
  | {
      type: 'updateSearch'
      payload: {
        text: string
        items: Items
      }
    }
  | { type: 'updateCheckedItems'; payload: { checked: boolean; value: string } }
  | {
      type: 'cancel'
      payload: Array<string>
    }
  | { type: 'reset' }

function reducer(draft: State, action: Action) {
  switch (action.type) {
    case 'loadItems':
      draft.searchResults = action.payload.items
      break

    case 'updateSearch':
      draft.searchText = action.payload.text

      draft.searchResults = isStringArray(action.payload.items)
        ? matchSorter(action.payload.items, draft.searchText)
        : matchSorter(action.payload.items, draft.searchText, {
            keys: ['name'],
          })
      break
    case 'updateCheckedItems':
      const { checked, value } = action.payload

      if (checked) {
        draft.checkedItems.push(value)
      } else {
        const index = draft.checkedItems.findIndex((item) => item === value)
        if (index !== -1) draft.checkedItems.splice(index, 1)
      }
      break
    case 'cancel':
      draft.checkedItems = action.payload
      draft.searchText = ''
      break
    case 'reset':
      draft.checkedItems = []
      draft.searchText = ''
      break
  }
}

function SearchableSelect({
  items,
  label,
  onOpen,
  selectedItems,
  setSelectedItems,
}: Props) {
  const [showModal, setShowModal] = React.useState(false)

  const [{ checkedItems, searchText, searchResults }, dispatch] =
    useImmerReducer<State, Action>(reducer, {
      checkedItems: selectedItems,
      searchText: '',
      searchResults: items,
    })

  // reinitialize "searchResults" so the virtual list updates with loaded items
  React.useEffect(() => {
    dispatch({ type: 'loadItems', payload: { items } })
  }, [dispatch, items])

  function getCheckedItemNames() {
    return isStringArray(items)
      ? selectedItems.join(', ')
      : selectedItems
          .map((sel) => items.find((item) => item.objectId === sel)?.name)
          .join(', ')
  }

  function handleItemSelect(e: CustomEvent<CheckboxChangeEventDetail<string>>) {
    const { checked, value } = e.detail
    dispatch({ type: 'updateCheckedItems', payload: { checked, value } })
  }

  function handleModalCancel() {
    dispatch({ type: 'cancel', payload: selectedItems })
    setShowModal(false)
  }

  function handleModalConfirm() {
    dispatch({ type: 'updateSearch', payload: { text: '', items } })
    setSelectedItems(checkedItems)
    setShowModal(false)
  }

  function openModal() {
    setShowModal(true)
    onOpen && onOpen()
  }

  function handleSearch(e: CustomEvent<SearchbarChangeEventDetail>) {
    dispatch({
      type: 'updateSearch',
      payload: { text: e.detail.value ?? '', items },
    })
  }

  return (
    <>
      <IonModal isOpen={showModal} onDidDismiss={handleModalCancel}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Select {label}</IonTitle>
          </IonToolbar>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                fill="outline"
                color="secondary"
                onClick={handleModalCancel}
              >
                Cancel
              </IonButton>
            </IonButtons>
            <IonSearchbar
              debounce={0}
              value={searchText}
              onIonChange={handleSearch}
            />
            <IonButtons slot="end">
              <IonButton
                fill="outline"
                color="secondary"
                onClick={() => dispatch({ type: 'reset' })}
              >
                Reset
              </IonButton>

              <IonButton
                fill="solid"
                color="yellow"
                onClick={handleModalConfirm}
              >
                OK
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {searchResults.length > 0 ? (
            <VirtualList
              rows={searchResults}
              checkedItems={checkedItems}
              handleItemSelect={handleItemSelect}
            />
          ) : null}
        </IonContent>
      </IonModal>

      <IonItem button color="secondary" onClick={openModal}>
        <IonLabel>{label}</IonLabel>
        {selectedItems.length ? (
          <IonNote>{getCheckedItemNames()}</IonNote>
        ) : null}
      </IonItem>
    </>
  )
}

function VirtualList({
  rows,
  checkedItems,
  handleItemSelect,
}: {
  rows: Items
  checkedItems: Array<string>
  handleItemSelect: (e: CustomEvent<CheckboxChangeEventDetail<string>>) => void
}) {
  const parentRef = React.useRef<HTMLDivElement>(null)
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 35,
    overscan: 5,
  })

  return (
    <div ref={parentRef} className="h-full overflow-auto">
      <div
        className="relative w-full"
        style={{ height: `${rowVirtualizer.getTotalSize()}px` }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualItem) => {
          const item = rows[virtualItem.index]

          return (
            <div
              key={virtualItem.key}
              data-index={virtualItem.index}
              ref={rowVirtualizer.measureElement}
              className="ion-padding-horizontal border-ion-color-tertiary absolute left-0 top-0 flex w-full items-center justify-between border-b py-1"
              style={{
                transform: `translateY(${virtualItem.start}px)`,
              }}
            >
              <p>{isString(item) ? item : item.name}</p>
              <IonCheckbox
                value={isString(item) ? item : item.objectId}
                checked={checkedItems.includes(
                  isString(item) ? item : item.objectId
                )}
                onIonChange={handleItemSelect}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SearchableSelect

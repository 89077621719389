import { IonMenuToggle, IonItem, IonIcon } from '@ionic/react'
import { useLocation } from 'react-router-dom'

import { useAccount } from '../../contexts/authContext'
import { isStore } from '../../auth/utils'
import { isAllowed, hasSetting } from '../utils'
import { MenuLabel } from './menu-label'

import type { Route } from '../menu.config'

function MenuItem({ route }: { route: Route }) {
  const location = useLocation()
  const account = useAccount()

  const hasInitialPermissions =
    isAllowed(account, route.user) && hasSetting(account, route.appSettings)

  const canShowMenuItem = hasInitialPermissions
    ? route.flag?.exclude
      ? !isStore(account, route.flag.exclude).length
      : hasInitialPermissions
    : route.flag?.include
    ? isStore(account, route.flag.include).length
    : hasInitialPermissions

  return canShowMenuItem ? (
    <IonMenuToggle auto-hide="false">
      <IonItem
        detail={false}
        routerLink={route.path}
        routerDirection="none"
        className={
          `${location.pathname}${location.search}` === route.path
            ? 'selected'
            : undefined
        }
      >
        <IonIcon slot="start" icon={route.icon} />
        <MenuLabel>{route.title}</MenuLabel>
      </IonItem>
    </IonMenuToggle>
  ) : null
}

export { MenuItem }

import * as React from 'react'
import {
  createAnimation,
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonLoading,
  IonModal,
  IonPage,
  IonRouterLink,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
} from '@ionic/react'
import { closeCircle, warningOutline } from 'ionicons/icons'
import { useForm, Controller } from 'react-hook-form'
import TextareaAutosize from 'react-textarea-autosize'
import { format, isSameDay, subDays as subtractDays } from 'date-fns'
import { ArrowDownIcon } from '@heroicons/react/24/outline'

import { getFullName } from '../../utils/format-helpers'
import { useAccount, useUser } from '../../contexts/authContext'
import { useToast } from '../../contexts/toastContext'
import { useKeyboard } from '../../hooks/capacitor/use-keyboard'
import { useConversationDetail } from '../../hooks/chat/queries'
import { useSendMessage } from '../../hooks/chat/mutations'
import { useIsAdminRoute } from '../../hooks/routing'
import SimpleSelectPhoto from '../../components/communication/SimpleSelectPhoto'
import TakePhoto from '../../components/communication/TakePhoto'
import CloseButton from '../../components/ui/buttons/close'

import type { PhotoData } from '../../hooks/use-photo'

type ScrollContainerProps = { children: React.ReactNode; onScroll: () => void }
type MessageRowProps = {
  children: React.ReactNode
  isAuthor: boolean
  isInbound: boolean
}

type FormInputs = {
  message: string
  msgPhoto: PhotoData | null
}

function formatMessageDate(date: string) {
  const dateToFormat = new Date(date)
  const today = new Date()

  return isSameDay(dateToFormat, today)
    ? format(dateToFormat, 'h:mm a')
    : isSameDay(dateToFormat, subtractDays(today, 1))
    ? 'yesterday'
    : format(dateToFormat, 'EEE MMM do')
}

function MediaMessage({ attachment }: { attachment: string }) {
  const [showFullImage, setShowFullImage] = React.useState(false)

  function enterAnimation(baseEl: any) {
    const root = baseEl.shadowRoot

    const backdropAnimation = createAnimation()
      .addElement(root.querySelector('ion-backdrop')!)
      .fromTo('opacity', '0.01', 'var(--backdrop-opacity)')

    const wrapperAnimation = createAnimation()
      .addElement(root.querySelector('.modal-wrapper')!)
      .keyframes([
        { offset: 0, opacity: '0', transform: 'scale(1)' },
        { offset: 1, opacity: '1', transform: 'scale(1)' },
      ])

    return createAnimation()
      .addElement(baseEl)
      .easing('ease-out')
      .duration(200)
      .addAnimation([backdropAnimation, wrapperAnimation])
  }

  function leaveAnimation(baseEl: any) {
    return enterAnimation(baseEl).direction('reverse')
  }

  return (
    <>
      <button className="outline-0" onClick={() => setShowFullImage(true)}>
        <div className="size-64 min-w-56 overflow-hidden rounded-lg">
          <IonImg
            src={attachment}
            className="z-[122] min-h-full min-h-full object-cover"
            alt=""
          />
        </div>
      </button>
      <IonModal
        isOpen={showFullImage}
        onDidDismiss={() => setShowFullImage(false)}
        enterAnimation={enterAnimation}
        leaveAnimation={leaveAnimation}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons>
              <IonButton
                color="secondary"
                onClick={() => setShowFullImage(false)}
              >
                <IonIcon slot="start" icon={closeCircle} />
                Close
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div
          // className="size-full"
          >
            <img
              // className="block min-h-full min-w-full object-contain"
              src={attachment}
              alt=""
            />
          </div>
        </IonContent>
      </IonModal>
    </>
  )
}

function ConversationDetail() {
  const [showScrollButton, setShowScrollButton] = React.useState(false)

  const contentRef = React.useRef<HTMLDivElement>(null)
  const inputRef = React.useRef<HTMLTextAreaElement>(null)

  const autoScroll = React.useRef(true)

  const isAdminRoute = useIsAdminRoute()

  const user = useUser()
  const account = useAccount()

  const conversationQuery = useConversationDetail()
  const sendMessageMutation = useSendMessage()

  const setToast = useToast()

  const { isKeyboardOpen } = useKeyboard()

  const { control, getValues, handleSubmit, resetField, reset, watch } =
    useForm<FormInputs>({
      defaultValues: { message: '', msgPhoto: null },
    })
  const [watchMessage, watchPhoto] = watch(['message', 'msgPhoto'])

  const conversationClients = conversationQuery.data?.meta.conversation.clients
  const conversationStylists = conversationQuery.data?.meta.conversation.users

  const participants = [
    ...(conversationClients ?? []),
    ...(conversationStylists ?? []).filter((u) => u.objectId !== user.objectId),
  ]
    .map((u) => u.firstName)
    .join(', ')

  const isChatDisabled = !conversationClients?.every(
    (client) => client.allowTwilioSms
  )

  const isFirstMessage = conversationQuery.data?.data.length === 0

  React.useEffect(() => {
    if (isKeyboardOpen) {
      setTimeout(scrollToBottom, 10)
    }
  }, [isKeyboardOpen])

  React.useEffect(scrollToBottom)

  function scrollToBottom() {
    if (contentRef.current && autoScroll.current) {
      contentRef.current.scrollTop = contentRef.current.scrollHeight
    }
  }

  function handleScrollButtonClick() {
    if (contentRef.current) {
      contentRef.current.scrollTo({
        top: contentRef.current.scrollHeight,
        behavior: 'smooth',
      })
    }
  }

  function handleScroll() {
    if (!contentRef.current) return

    const { scrollTop, scrollHeight, clientHeight } = contentRef.current
    const distanceToBottom = scrollHeight - (scrollTop + clientHeight)
    const isAtBottom = distanceToBottom < 10

    if (isAtBottom) {
      setShowScrollButton(false)
    } else {
      setShowScrollButton(true)
    }
    autoScroll.current = isAtBottom
  }

  React.useEffect(() => {
    if (!user.hasTwilio) {
      setToast({
        message: 'You do not have OneShop Messaging services for this account.',
      })
      return
    }
  }, [setToast, user.hasTwilio])

  // NOTE: a little hacky - but seems to be recommended approach
  useIonViewDidEnter(() => {
    setTimeout(() => {
      inputRef.current?.focus()
    }, 100)
  })

  async function sendMessage(data: FormInputs) {
    // shouldn't happen. just in case fallback and so rest of function knows data exists for TS
    if (!conversationQuery.data) {
      return setToast({
        message: 'Something went wrong loading the conversation.',
        color: 'danger',
        duration: 3000,
      })
    }

    reset()
    inputRef.current?.focus()

    const message = {
      ...(data.message && { data: data.message }),
      ...(data.msgPhoto && {
        attachments: [
          {
            name: data.msgPhoto.name,
            content: data.msgPhoto.base64String,
          },
        ],
      }),
    }

    sendMessageMutation.mutate({
      conversationId: conversationQuery.data.meta.conversation.objectId,
      message,
    })
  }

  if (conversationQuery.data) {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton
                color="secondary"
                defaultHref={isAdminRoute ? '/admin/chat/inbox' : '/chat/inbox'}
              />
            </IonButtons>
            <IonTitle>
              <h2 className="overflow-hidden text-ellipsis text-xl">
                {conversationClients?.map((client, index) => (
                  <React.Fragment key={client.objectId}>
                    <IonRouterLink
                      routerLink={`/tabs/clients/client/${client.objectId}`}
                      className="text-ion-color-secondary"
                    >
                      {getFullName(client)}
                    </IonRouterLink>
                    {index !== conversationClients.length - 1 ? ', ' : ''}
                  </React.Fragment>
                ))}
              </h2>
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent className="ion-padding-bottom" scrollY={false}>
          <section className="flex h-full flex-col">
            {isFirstMessage ? (
              <div className="ion-padding-top ion-padding-horizontal flex-1">
                <p>This is your first message with {participants}.</p>
                {!account.optinRequired ? (
                  <p>
                    Instructions for opting out will be sent along with this
                    first message.
                  </p>
                ) : null}
              </div>
            ) : (
              <ScrollContainer ref={contentRef} onScroll={handleScroll}>
                {[...conversationQuery.data.data]
                  .reverse()
                  .map((message, idx) => {
                    // TODO: REVISIT when mutually exclusive sender instead of inbound
                    const isInbound = Boolean(message.client && message.inbound)
                    const isAuthor = message.user?.objectId === user.objectId
                    return (
                      <MessageRow
                        key={message.objectId}
                        isAuthor={isAuthor}
                        isInbound={isInbound}
                      >
                        <MessageWrapper>
                          <MessageHeader>
                            {message.inbound
                              ? getFullName(message.client)
                              : getFullName(message.user)}
                          </MessageHeader>
                          <MessageGroup>
                            <>
                              {/* MESSAGE BUBBLE */}
                              {message.data ? (
                                <MessageBubble>
                                  {/* TEXT_MESSAGE */}
                                  <MessageText>
                                    {message.data
                                      .split('\n')
                                      .map((line, idx) => (
                                        <React.Fragment key={idx}>
                                          {line}
                                          <br />
                                        </React.Fragment>
                                      ))}
                                  </MessageText>
                                  {message.status === 'undelivered' ||
                                  message.status === 'failed' ? (
                                    <MessageStatus>
                                      <IonIcon
                                        icon={warningOutline}
                                        color="danger"
                                      />
                                    </MessageStatus>
                                  ) : null}
                                </MessageBubble>
                              ) : null}
                              {message.attachments
                                ? message.attachments.map((attachment) => (
                                    <MessageBubble key={attachment}>
                                      {/* MEDIA */}
                                      <MediaMessage attachment={attachment} />
                                      {message.status === 'undelivered' ? (
                                        <MessageStatus>
                                          <IonIcon
                                            icon={warningOutline}
                                            color="danger"
                                          />
                                        </MessageStatus>
                                      ) : null}
                                    </MessageBubble>
                                  ))
                                : null}
                            </>
                          </MessageGroup>
                          <MessageFooter>
                            {formatMessageDate(message.date)}
                          </MessageFooter>
                        </MessageWrapper>
                      </MessageRow>
                    )
                  })}
              </ScrollContainer>
            )}

            {showScrollButton ? (
              <div className="absolute bottom-20 right-1/2 translate-x-1/2">
                <button
                  onClick={() => handleScrollButtonClick()}
                  style={{
                    '--border-radius': '9999px',
                    '--padding-top': '10px',
                    '--padding-bottom': '10px',
                    '--padding-start': '10px',
                    '--padding-end': '10px',
                  }}
                  className="text-ion-color-primary bg-ion-color-secondary rounded-full p-2.5"
                >
                  <ArrowDownIcon strokeWidth={2.5} className="size-6" />
                </button>
              </div>
            ) : null}

            {/* message form */}
            <div className="flex-none bg-neutral-900">
              <div className="ion-padding">
                <div className="border-ion-color-secondary min-h-11 flex flex-shrink-0 items-center gap-2 rounded-3xl border">
                  {watchPhoto ? (
                    <div className="flex w-full flex-auto items-center justify-between p-2">
                      <div className="relative">
                        <IonImg
                          className="min-h-28 w-28 overflow-hidden rounded-xl"
                          src={getValues('msgPhoto')?.pic}
                        />

                        <CloseButton
                          inner
                          onClick={() => resetField('msgPhoto')}
                        />
                      </div>
                      <IonButton
                        onClick={handleSubmit(sendMessage)}
                        color="yellow"
                        fill="clear"
                        size="small"
                        strong
                        className="flex-[0_0_auto]"
                        disabled={isChatDisabled}
                      >
                        Send
                      </IonButton>
                    </div>
                  ) : (
                    <>
                      <Controller
                        name="msgPhoto"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <TakePhoto
                            disabled={Boolean(watchMessage)}
                            onTakePhoto={onChange}
                          />
                        )}
                      />

                      <div className="flex flex-1 items-center">
                        <Controller
                          control={control}
                          name="message"
                          render={({ field: { value, onChange } }) => (
                            <TextareaAutosize
                              maxRows={5}
                              className="w-full resize-none overflow-auto bg-transparent py-2 pl-2 focus:outline-0"
                              ref={inputRef}
                              placeholder="Type your message here..."
                              name="message"
                              value={value}
                              onChange={onChange}
                              autoCapitalize="on"
                              autoCorrect="on"
                              disabled={isChatDisabled}
                            />
                          )}
                        />
                      </div>

                      {watchMessage ? (
                        <IonButton
                          onClick={handleSubmit(sendMessage)}
                          color="yellow"
                          fill="clear"
                          size="small"
                          strong
                          disabled={isChatDisabled}
                        >
                          Send
                        </IonButton>
                      ) : (
                        <Controller
                          name="msgPhoto"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <SimpleSelectPhoto onSelectPhoto={onChange} />
                          )}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </section>
          <IonLoading
            isOpen={conversationQuery.isLoading}
            message="Loading messages..."
          />
        </IonContent>
      </IonPage>
    )
  }

  return null
}

const ScrollContainer = React.forwardRef<
  React.ElementRef<'div'>,
  ScrollContainerProps
>(({ children, onScroll }, ref) => (
  <div
    ref={ref}
    className="ion-padding-top ion-padding-horizontal flex-1 select-auto space-y-3 overflow-y-scroll"
    onScroll={onScroll}
  >
    {children}
  </div>
))

function MessageRow({ children, isAuthor, isInbound }: MessageRowProps) {
  return (
    <div
      style={{
        '--chat-position': isInbound ? 'flex-start' : 'flex-end',
        '--label-color': isInbound
          ? 'var(--ion-color-secondary)'
          : isAuthor
          ? 'var(--ion-color-yellow)'
          : 'var(--ion-color-secondary)',
        '--chat-color': isInbound
          ? 'var(--ion-color-primary)'
          : isAuthor
          ? 'var(--ion-color-primary)'
          : 'var(--ion-color-secondary)',
        '--bg-color': isInbound
          ? 'var(--ion-color-secondary)'
          : isAuthor
          ? 'var(--ion-color-yellow)'
          : 'transparent',
        '--border': isAuthor
          ? 'transparent'
          : '1px solid var(--ion-color-secondary)',
      }}
      className="relative flex flex-[0_0_auto] flex-row items-stretch [justify-content:var(--chat-position)]"
    >
      {children}
    </div>
  )
}

function MessageWrapper({ children }: { children: React.ReactNode }) {
  return (
    <div className="relative flex flex-[0_1_auto] flex-col [align-items:var(--chat-position)]">
      {children}
    </div>
  )
}

function MessageHeader({ children }: { children: React.ReactNode }) {
  return <div className="text-sm [color:var(--label-color)]">{children}</div>
}

function MessageGroup({ children }: { children: React.ReactNode }) {
  return (
    <div className="max-w-80 flex flex-col gap-y-2 [align-items:var(--chat-position)]">
      {children}
    </div>
  )
}

function MessageBubble({ children }: { children: React.ReactNode }) {
  return <div className="flex gap-x-1">{children}</div>
}

function MessageText({ children }: { children: React.ReactNode }) {
  return (
    <div className="max-w-80 break-words rounded-lg border border-[var(--border)] bg-[var(--bg-color)] px-3 py-2 text-[var(--chat-color)]">
      {children}
    </div>
  )
}

function MessageStatus({ children }: { children: React.ReactNode }) {
  return <div className="flex items-center">{children}</div>
}

function MessageFooter({ children }: { children: React.ReactNode }) {
  return <div className="text-sm text-[var(--label-color)]">{children}</div>
}

export default ConversationDetail

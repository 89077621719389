import * as React from 'react'
import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
} from '@ionic/react'
import clsx from 'clsx'

import Header from '../../components/header'
import { useAccount, useAuth, useUser } from '../../contexts/authContext'
import Avatar from '../../components/avatar'
import { getFullName } from '../../utils/format-helpers'

type User = {
  firstName: string | null
  lastName: string | null
  objectId: string
  photo: string | null
  username: string
}

function SwitchAccount() {
  const { handleSwitchAccount } = useAuth()
  const user = useUser()
  const account: { users: Array<User> } = useAccount()

  const isCurrentUser = (u: User) => user.objectId === u.objectId

  function handleLoginSubmit(username: string) {
    handleSwitchAccount(username)
  }

  return (
    <IonPage>
      <IonHeader>
        <Header title="Switch Account" showMenu btn={null} />
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow className="h-full">
            {account.users?.map((user) => (
              <IonCol
                key={user.objectId}
                className="flex justify-center px-0 py-8"
                size="6"
                sizeSm="4"
                sizeXl="3"
              >
                <button
                  className={clsx([
                    'flex cursor-pointer flex-col items-center bg-transparent',
                    isCurrentUser(user)
                      ? 'text-ion-color-yellow pointer-events-none'
                      : 'pointer-events-[initial] text-ion-color-secondary',
                  ])}
                  onClick={() => handleLoginSubmit(user.username)}
                >
                  <Avatar
                    size="lg"
                    user={user}
                    color={isCurrentUser(user) ? 'yellow' : 'secondary'}
                  />
                  <p className="m-0 mt-2 text-xl">{getFullName(user)}</p>
                </button>
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export default SwitchAccount

import type { LoyaltyData } from './loyalty.schema'

function composePosId(id: string | null) {
  if (!id) return ''
  if (id.startsWith('MANUAL')) return 'Manual Sale'
  return `Trans. # ${id}`
}

const loyaltyQuerySelector = <T extends LoyaltyData>(data: T) => ({
  ...data,
  bonusRules: data.loyaltyRules.filter((rule) => rule.ruleType === 'bonus'),
  rewardRules: data.loyaltyRules.filter((rule) => rule.ruleType === 'reward'),
})

export { composePosId, loyaltyQuerySelector }

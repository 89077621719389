import { IonSplitPane, IonRouterOutlet } from '@ionic/react'
import { useHistory, Route, Redirect } from 'react-router'

import { Menu } from './menu/menu'
import SocialTabs from './components/social-tabs'
import AccountPage from './pages/account/account-page'
import ConnectionsPage from './pages/account/connections-page'
import GroupConnectionsPage from './pages/account/connections/groups-connections-page'
import UsersConnectionsPage from './pages/account/connections/users-connections-page'
import SwitchAccount from './pages/account/switch-account'
import { ApprovalsPage } from './pages/admin/approvals-page'
import RoleSocialsPage from './pages/admin/role-socials-page'
import AuthCallback from './pages/auth-callback'
import ClientImport from './pages/client-import'
import ClientDetail from './pages/client/ClientDetail'
import CreatePostPage from './pages/post/create-post-page'
import PostDetailPage from './pages/posts/post-detail-page'
import PostListPage from './pages/posts/post-list-page'

function SocialApp() {
  const history = useHistory()
  const isSplitDisabled = history.location.pathname.includes('discount')

  return (
    <IonSplitPane contentId="main" disabled={isSplitDisabled}>
      <Menu />
      <IonRouterOutlet id="main">
        <Route path="/tabs" render={() => <SocialTabs />} />

        {/* HOMEPAGE POST FLOW */}
        <Route path="/post/create/" exact>
          <CreatePostPage />
        </Route>

        <Route path="/account" component={AccountPage} exact />
        <Route
          path="/account/switch-accounts"
          component={SwitchAccount}
          exact
        />

        <Route path="/account/connections" exact>
          <ConnectionsPage />
        </Route>
        <Route path="/account/connections/groups" exact>
          <GroupConnectionsPage />
        </Route>
        <Route path="/account/connections/groups/:groupId" exact>
          <UsersConnectionsPage />
        </Route>

        {/* Post Pages */}
        <Route path="/posts" exact>
          <PostListPage />
        </Route>
        <Route path="/posts/detail/:postId" exact>
          <PostDetailPage />
        </Route>

        <Route path="/*/client/:id" exact>
          <ClientDetail />
        </Route>

        {/* ADMIN ROUTES */}
        {/* POST APPROVALS */}
        <Route path="/admin/approvals" exact>
          <ApprovalsPage />
        </Route>
        <Route path="/admin/roles" exact>
          <RoleSocialsPage />
        </Route>

        <Route path="/admin/import" component={ClientImport} exact />

        <Route path="/authorization/callback" component={AuthCallback} />

        <Redirect from="/" to="/tabs" exact />
      </IonRouterOutlet>
    </IonSplitPane>
  )
}

export { SocialApp }

import { useQuery } from '@tanstack/react-query'
import { FirebaseError } from 'firebase/app'
import { FirebaseMessaging } from '@capacitor-firebase/messaging'

async function fetchToken() {
  const token = await FirebaseMessaging.getToken({
    vapidKey:
      'BOzF2ZeIZyt_fNUtzZvOQmAwMXEuLUTIxAY68FqBFM1VUu_rfv72RLgnuH2GDcP6NCcZg5JAf3aQKuTdaiu5Pdc',
  })
  return token.token
}

function usePushToken({ enabled = true }: { enabled?: boolean } = {}) {
  return useQuery({
    queryKey: ['fb'],
    queryFn: fetchToken,
    enabled,
    onError: (err) => {
      err instanceof FirebaseError
        ? err.code === 'app/no-app'
          ? console.error('There was an error initializing the notifications.')
          : console.error(err.message)
        : console.error('unknown error')
    },
  })
}

export { usePushToken }

import { useParams } from 'react-router-dom'

import { useUser } from '../../contexts/authContext'
import { useIsStore } from '../../auth/utils'

/* unused but holding on to it for future needs
function useIsUnoAssociate() {
  const account = useAccount()
  const { isUno } = useIsStore()

  return Boolean(isUno() && account.isAssociate && !account.isAdmin)
}
*/

function useShowBroadcast() {
  const params = useParams<{ callListId?: string }>()

  const user = useUser()

  const { isUno } = useIsStore()

  function hasBroadcastSettings(role: 'admin' | 'associate' = 'associate') {
    if (!user.isBlastEnabled) return false

    if (user.smsManual && role === 'associate') return false

    return isUno() ? (params.callListId ? true : false) : true
  }

  return { hasBroadcastSettings }
}

export { useShowBroadcast }

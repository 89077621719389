import * as React from 'react'
import {
  IonButton,
  IonItem,
  IonLabel,
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonContent,
  IonTitle,
  IonNote,
  IonIcon,
} from '@ionic/react'
import { Combobox } from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import { useDebounce } from 'use-debounce'
import { checkmarkCircle, ellipse } from 'ionicons/icons'

import { useSearchByName } from '../../../hooks/clients/queries'

type Props = {
  label: string
  selectedItems: Array<{ objectId: string; displayName: string }>
  onConfirm: (items: Array<{ objectId: string; displayName: string }>) => void
  isAdmin?: boolean
  onCancel?: () => void
  onReset?: () => void
}

function NameSearchSelect({
  label,
  onCancel,
  onConfirm,
  onReset,
  isAdmin = false,
  selectedItems,
}: Props) {
  const modalRef = React.useRef<HTMLIonModalElement>(null)
  const inputRef = React.useRef<HTMLInputElement>(null)

  const [selectedComboItems, setSelectedComboItems] = React.useState<
    Array<{ objectId: string; displayName: string }> //| Array<undefined>
  >(selectedItems)

  const [searchTerm, setSearchTerm] = React.useState('')

  // debounce the search string value for useQuery calls
  const [debouncedSearchString] = useDebounce(searchTerm, 300)

  const searchQuery = useSearchByName({
    name: debouncedSearchString,
    isAdmin,
  })

  function handleCancel() {
    setSelectedComboItems(selectedItems)
    onCancel && onCancel()
    modalRef.current?.dismiss()
  }

  function handleConfirm() {
    onConfirm(
      selectedComboItems.map(({ objectId, displayName }) => ({
        objectId,
        displayName,
      }))
    )
    modalRef.current?.dismiss()
  }
  function handleReset() {
    setSearchTerm('')
    setSelectedComboItems([])
    onReset && onReset()
  }

  return (
    <>
      <IonItem id="open-modal" button color="secondary">
        <IonLabel>{label}</IonLabel>
        <IonNote>
          {selectedComboItems.length
            ? selectedComboItems.map((c) => c?.displayName).join(', ')
            : ''}
        </IonNote>
      </IonItem>

      <IonModal
        ref={modalRef}
        trigger="open-modal"
        onDidPresent={() => {
          if (!inputRef.current) return

          inputRef.current.focus()
        }}
        onWillDismiss={(e) => {
          if (e.detail.role === 'backdrop') setSelectedComboItems(selectedItems)
        }}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>Search {label}</IonTitle>

            <IonButtons slot="start">
              <IonButton
                fill="outline"
                color="secondary"
                onClick={handleCancel}
              >
                Cancel
              </IonButton>
            </IonButtons>

            <IonButtons slot="end">
              <IonButton fill="outline" color="secondary" onClick={handleReset}>
                Reset
              </IonButton>

              <IonButton fill="solid" color="yellow" onClick={handleConfirm}>
                OK
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          {/* <div ref={parentRef} className="h-full overflow-auto">
            <div
              className="relative w-full"
              style={{ height: `${rowVirtualizer.getTotalSize()}px` }}
            >
              {searchQuery.isLoading ? (
                <div className="mt-5 flex items-center justify-center">
                  <IonSpinner />
                </div>
              ) : (
                <>
                  {rowVirtualizer.getVirtualItems().map((virtualRow) => (
                    <div
                      key={virtualRow.key}
                      data-index={virtualRow.index}
                      className="ion-padding-horizontal border-ion-color-tertiary absolute left-0 top-0 flex w-full items-center justify-between border-b"
                      style={{
                        transform: `translateY(${virtualRow.start}px)`,
                      }}
                    >
                      <p>{searchResults[virtualRow.index].displayName}</p>
                      <IonCheckbox
                        name="checkbox"
                        value={searchResults[virtualRow.index].objectId}
                        checked={checkedItems.includes(
                          searchResults[virtualRow.index].objectId
                        )}
                        onIonChange={handleItemSelect}
                      />
                    </div>
                  ))}
                </>
              )}
            </div>
          </div> */}
          <div className="ion-padding-horizontal ion-padding-top">
            <Combobox
              multiple
              value={selectedComboItems}
              by="objectId"
              onChange={(v) => setSelectedComboItems(v)}
            >
              <div className="flex items-center rounded-[10px] bg-white bg-opacity-[.07] px-3">
                <MagnifyingGlassIcon className="text-ion-color-step-600 size-5 inline-block" />
                <Combobox.Input
                  ref={inputRef}
                  onChange={(e) => setSearchTerm(e.currentTarget.value)}
                  className={
                    'text-ion-color-secondary w-full bg-transparent px-3 py-2 outline-none'
                  }
                  autoComplete="off"
                />
                {searchQuery.isInitialLoading && <LoadingSpinner />}
              </div>
              <Combobox.Options className="divide-ion-color-tertiary divide-y">
                {searchQuery.data?.map((person: any) => (
                  <Combobox.Option
                    key={person.objectId}
                    value={person}
                    className="relative flex items-center justify-between px-3 py-2" // ui-not-active:bg-white ui-not-active:text-gray-800"
                  >
                    <span>{person.displayName}</span>
                    <span className="text-ion-color-secondary flex hidden items-center pl-3 leading-[0] ui-selected:block">
                      <IonIcon
                        size="large"
                        icon={checkmarkCircle}
                        aria-hidden="true"
                      />
                    </span>
                    <span className="text-ion-color-secondary block flex items-center pl-3 leading-[0] ui-selected:hidden">
                      <IonIcon size="large" icon={ellipse} aria-hidden="true" />
                    </span>
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            </Combobox>
          </div>
        </IonContent>
      </IonModal>
    </>
  )
}

function LoadingSpinner() {
  return (
    <svg
      className="size-5 animate-spin text-gray-500"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  )
}

export { NameSearchSelect }

import { klona } from 'klona/json'

type SortDirection = 'asc' | 'desc'

// todo once account is properly typed refactor this as a settings checking function
export function containsAny(
  baseArray: Array<string>,
  searchArray: Array<string>
) {
  return baseArray.some((str) => searchArray.includes(str))
}

function sortFunction<T extends { [key: string]: any }, K extends keyof T>(
  a: T,
  b: T,
  fieldName: K,
  direction: SortDirection,
  includeNull: boolean,
  secondarySort?: K
): number {
  if (includeNull && !secondarySort) {
    // sort nulls after everything else
    // putting this first prevents any errors for nested fields on undefined
    if (a[fieldName] === undefined) return 1
    if (b[fieldName] === undefined) return -1
  }

  const fieldA = a[fieldName]?.iso ? a[fieldName].iso : a[fieldName]
  const fieldB = b[fieldName]?.iso ? b[fieldName].iso : b[fieldName]

  // sort equal items
  if (fieldA === fieldB) {
    if (secondarySort) return sortFunction(a, b, secondarySort, direction, true)
    return 0
  }

  if (direction === 'desc') {
    return fieldA > fieldB ? -1 : 1
  } else {
    return fieldA < fieldB ? -1 : 1
  }
}

/**
 * Helper function to sort an array of objects by a specific field.
 * Does not mutate the original array.
 *
 */
export function sortFields<T, K extends keyof T>(
  list: Array<T>,
  fieldName: K,
  {
    direction = 'desc',
    includeNull = false,
    secondarySort,
  }: {
    direction?: SortDirection
    includeNull?: boolean
    secondarySort?: K
  } = {}
) {
  // don't mutate original list
  let temp = klona(list)

  if (!includeNull) {
    // null items are not included in sort
    // remove entries that don't contain fieldName
    temp = temp.filter((entry) => entry[fieldName] != null)
  }

  return temp.sort((a, b) => {
    return sortFunction(a, b, fieldName, direction, includeNull, secondarySort)
  })
}

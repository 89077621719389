import { useQuery } from '@tanstack/react-query'
import { klona } from 'klona/json'

import { strToTitleCase } from '../../utils/format-helpers'
import { client } from '../../services/client'
import { useToast } from '../../contexts/toastContext'
import type { QueryContextFromKeys } from '../../utils/react-query'

type FetchPosStylesResponse = {
  result: Array<string>
}

type PosStyleParams = {
  admin?: boolean
  search?: string
}

type PosStylesQueryContexts = QueryContextFromKeys<typeof posStylesQueryKeys>

const posStylesQueryKeys = {
  all: [{ entity: 'posStyles' }] as const,
  lists: () => [{ ...posStylesQueryKeys.all[0], scope: 'list' }] as const,
  list: ({ admin, search }: PosStyleParams) =>
    [{ ...posStylesQueryKeys.lists()[0], admin, search }] as const,
}

async function fetchPosStyles({
  queryKey: [{ admin, search }],
}: PosStylesQueryContexts['list']) {
  const response: FetchPosStylesResponse = await client
    .post('functions/posStyles', {
      body: JSON.stringify({ filter: { admin, search } }),
    })
    .json()

  return response.result
}

function usePosStylesSearch(
  searchTerm: string,
  { admin }: { admin?: boolean }
) {
  const setToast = useToast()

  return useQuery({
    queryKey: posStylesQueryKeys.list({ admin, search: searchTerm }),
    queryFn: fetchPosStyles,
    refetchOnWindowFocus: false,
    retry: false,
    select: (data) =>
      klona(data.map((item) => strToTitleCase(item))).sort((a, b) =>
        a.localeCompare(b, undefined, {
          numeric: true,
          ignorePunctuation: true,
        })
      ),
    enabled: Boolean(searchTerm),
    onError(error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown Error.'

      setToast({
        message: `Something went wrong while loading posStyles: ${errorMessage}`,
        color: 'danger',
      })
    },
  })
}

export { usePosStylesSearch }

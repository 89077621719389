import { useIonRouter, useIonViewWillEnter } from '@ionic/react'
import { klona } from 'klona/json'

import {
  qListSortGenerator,
  useAggregatedQFollowUps,
} from '../../../hooks/outreach/queries'
import {
  ScrollingClientList,
  ScrollingClientListSkeleton,
} from '../scrolling-client-list'
import { SectionWrapper, SectionHeader, SectionContent } from '../section'
import { SeeMore } from '../see-more'

function QListsSection() {
  const qLists = useAggregatedQFollowUps()
  const router = useIonRouter()

  // TODO: Better optimize this with other lists possibly in communication sending logic
  useIonViewWillEnter(() => qLists.refetch())

  return (
    <>
      <SectionWrapper>
        <SectionHeader
          title="New Delivery"
          action={
            Boolean(qLists.data?.clientsWithoutAppt.length) ? (
              <SeeMore
                onClick={() => {
                  router.push('/tabs/clients/list/q-without-appointment')
                }}
              />
            ) : null
          }
        />
        <SectionContent>
          {qLists.data ? (
            <>
              {qLists.data.clientsWithoutAppt.length > 0 ? (
                <ScrollingClientList
                  list={klona(qLists.data.clientsWithoutAppt).sort(
                    qListSortGenerator('updatedAt', 'desc')
                  )}
                />
              ) : (
                <div className="ion-padding-horizontal">
                  <p>You don't have any clients with new deliveries.</p>
                </div>
              )}
            </>
          ) : (
            <ScrollingClientListSkeleton />
          )}
        </SectionContent>
      </SectionWrapper>

      <SectionWrapper>
        <SectionHeader
          title="Customer Service Check In"
          action={
            Boolean(qLists.data?.clientsWithAppt.length) ? (
              <SeeMore
                onClick={() => {
                  router.push('/tabs/clients/list/q-with-appointment')
                }}
              />
            ) : null
          }
        />
        <SectionContent>
          {qLists.data ? (
            <>
              {qLists.data.clientsWithAppt.length > 0 ? (
                <ScrollingClientList
                  list={klona(qLists.data.clientsWithAppt).sort(
                    qListSortGenerator('updatedAt', 'desc')
                  )}
                />
              ) : (
                <div className="ion-padding-horizontal">
                  <p>You don't have any clients to re-engage with.</p>
                </div>
              )}
            </>
          ) : (
            <ScrollingClientListSkeleton />
          )}
        </SectionContent>
      </SectionWrapper>

      <SectionWrapper>
        <SectionHeader
          title="Re-Engage: 120 Days"
          action={
            Boolean(qLists.data?.clientsWithNoMessage.length) ? (
              <SeeMore
                onClick={() => {
                  router.push('/tabs/clients/list/q-re-engage')
                }}
              />
            ) : null
          }
        />
        <SectionContent>
          {qLists.data ? (
            <>
              {qLists.data.clientsWithNoMessage.length > 0 ? (
                <ScrollingClientList
                  list={klona(qLists.data.clientsWithNoMessage).sort(
                    qListSortGenerator('lastContact', 'asc')
                  )}
                />
              ) : (
                <div className="ion-padding-horizontal">
                  <p>You don't have any clients to re-engage with.</p>
                </div>
              )}
            </>
          ) : (
            <ScrollingClientListSkeleton />
          )}
        </SectionContent>
      </SectionWrapper>

      <SectionWrapper>
        <SectionHeader
          title="2nd Reach Out"
          action={
            Boolean(qLists.data?.clientsWithOneMessage.length) ? (
              <SeeMore
                onClick={() => {
                  router.push('/tabs/clients/list/q-2day-follow-up')
                }}
              />
            ) : null
          }
        />
        <SectionContent>
          {qLists.data ? (
            <>
              {qLists.data.clientsWithOneMessage.length > 0 ? (
                <ScrollingClientList
                  list={klona(qLists.data.clientsWithOneMessage).sort(
                    qListSortGenerator('lastContact', 'asc')
                  )}
                />
              ) : (
                <div className="ion-padding-horizontal">
                  <p>
                    You don't have any clients that require a 2nd reach out.
                  </p>
                </div>
              )}
            </>
          ) : (
            <ScrollingClientListSkeleton />
          )}
        </SectionContent>
      </SectionWrapper>

      <SectionWrapper>
        <SectionHeader
          title="3rd Reach Out"
          action={
            Boolean(qLists.data?.clientsWithTwoMessages.length) ? (
              <SeeMore
                onClick={() => {
                  router.push('/tabs/clients/list/q-4day-follow-up')
                }}
              />
            ) : null
          }
        />
        <SectionContent>
          {qLists.data ? (
            <>
              {qLists.data.clientsWithTwoMessages.length > 0 ? (
                <ScrollingClientList
                  list={klona(qLists.data.clientsWithTwoMessages).sort(
                    qListSortGenerator('lastContact', 'asc')
                  )}
                />
              ) : (
                <div className="ion-padding-horizontal">
                  <p>
                    You don't have any clients that require a 3rd reach out.
                  </p>
                </div>
              )}
            </>
          ) : (
            <ScrollingClientListSkeleton />
          )}
        </SectionContent>
      </SectionWrapper>

      <SectionWrapper>
        <SectionHeader
          title="4th Reach Out (Before Recycled Back to Re-Engage)"
          action={
            Boolean(qLists.data?.clientsWithThreeMessages.length) ? (
              <SeeMore
                onClick={() => {
                  router.push('/tabs/clients/list/q-7day-follow-up')
                }}
              />
            ) : null
          }
        />
        <SectionContent>
          {qLists.data ? (
            <>
              {qLists.data.clientsWithThreeMessages.length > 0 ? (
                <ScrollingClientList
                  list={klona(qLists.data.clientsWithThreeMessages).sort(
                    qListSortGenerator('lastContact', 'asc')
                  )}
                />
              ) : (
                <div className="ion-padding-horizontal">
                  <p>
                    You don't have any clients that require a 4th reach out.
                  </p>
                </div>
              )}
            </>
          ) : (
            <ScrollingClientListSkeleton />
          )}
        </SectionContent>
      </SectionWrapper>
    </>
  )
}

export default QListsSection

import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva } from 'class-variance-authority'

import { cn } from '../../utils/cn'

import type { VariantProps } from 'class-variance-authority'

type Ref = HTMLDivElement

type Props = {
  children: React.ReactNode
  asChild?: boolean
} & VariantProps<typeof gapVariants>

const gapVariants = cva(
  [
    'inline-flex flex-nowrap',
    '[&>*:first-of-type]:!ml-3 [&>*:last-child]:!mr-3 [&>*]:flex-[0_0_auto]',
  ],
  {
    variants: {
      gap: {
        normal: 'space-x-2',
        lg: 'space-x-4',
      },
    },
    defaultVariants: { gap: 'normal' },
  }
)

const ScrollingList = React.forwardRef<Ref, Props>(
  ({ children, asChild, gap, ...props }, ref) => {
    const Component = asChild ? Slot : 'div'

    return (
      <div
        ref={ref}
        className="overflow-x-auto overflow-y-hidden scrollbar-hidden"
      >
        <Component className={cn(gapVariants({ gap }))} {...props}>
          {children}
        </Component>
      </div>
    )
  }
)

export default ScrollingList

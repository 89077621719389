import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react'

import { useClientLoyalty } from '../../loyalty/queries'
import { CustomerLoyaltyInfo } from '../../rotate/components/customer-loyalty-info'
import { useCheckInRedirect } from '../../rotate/utils'
import { useUpdateClientStation } from '../../rotate/mutations'
import { closeCircle } from 'ionicons/icons'

function PairedLoyaltyInfoPage() {
  const router = useIonRouter()
  const loyalty = useClientLoyalty()

  const { mutate: updateStation } = useUpdateClientStation()

  const { clientStation, resetCheckIn } = useCheckInRedirect()

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              className="opacity-10"
              onClick={() => {
                if (!clientStation) return resetCheckIn()
                updateStation(
                  { objectId: clientStation, status: 'canceled' },
                  {
                    onSuccess: () =>
                      router.push(
                        '/check-in/paired/phone-check-in',
                        'none',
                        'replace'
                      ),
                  }
                )
              }}
            >
              <IonIcon color="secondary" slot="icon-only" icon={closeCircle} />
            </IonButton>
          </IonButtons>
          <IonTitle>Loyalty Rewards</IonTitle>
          <IonButtons slot="end">
            <IonButton
              disabled={!clientStation}
              color="yellow"
              fill="solid"
              onClick={() => {
                if (!clientStation) return

                updateStation(
                  { objectId: clientStation, status: 'completed' },
                  {
                    onSuccess: () =>
                      router.push(
                        '/check-in/paired/phone-check-in',
                        'none',
                        'replace'
                      ),
                  }
                )
              }}
            >
              Finish
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <>
          {loyalty.data ? (
            <CustomerLoyaltyInfo data={loyalty.data} />
          ) : loyalty.error ? (
            <p>
              Error:{' '}
              {loyalty.error instanceof Error
                ? loyalty.error.message
                : 'Unknown Error.'}
            </p>
          ) : (
            <IonLoading message="Loading Rewards..." isOpen />
          )}
        </>
      </IonContent>
    </IonPage>
  )
}

export { PairedLoyaltyInfoPage }

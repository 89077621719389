import { z } from 'zod'
import {
  isoDateSchema,
  nullableString,
  serverItemSchema,
} from '../../utils/schema'
import { profileBaseSchema } from '../../schema/profile.schema'

type CallList = z.infer<typeof callListSchema>
type CallListBase = z.infer<typeof callListBaseSchema>

const callListBaseSchema = serverItemSchema.extend({
  className: z.literal('CallList'),
  createdAt: isoDateSchema,
  description: nullableString,
  name: z.string(),
  user: profileBaseSchema,
})

const callListSchema = callListBaseSchema.merge(
  z.object({
    startDate: isoDateSchema.nullable(),
    endDate: isoDateSchema.nullable(),
    stats: z.object({ clientCount: z.number() }),
  })
)

const subscriberCounts = z.object({
  clientsSms: z.number(),
  clientsEmail: z.number(),
})

const outreachClientCountsSchema = z.object({
  clients: z.object({
    clients: z.number(),
    email: subscriberCounts,
    prioSmsEmail: subscriberCounts,
    sms: subscriberCounts,
  }),
})

export type { CallList, CallListBase }
export { callListBaseSchema, callListSchema, outreachClientCountsSchema }

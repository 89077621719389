import {
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonRadio,
  IonRadioGroup,
  IonText,
} from '@ionic/react'
import { checkmark, close } from 'ionicons/icons'
import { useForm, Controller } from 'react-hook-form'
import PhoneInput, {
  isValidPhoneNumber,
  formatPhoneNumber,
} from 'react-phone-number-input'

import { getDefaultPhoneNumber } from '../../utils/client-helpers'
import { useToast } from '../../contexts/toastContext'
import {
  useAddPhoneNumber,
  useUpdateDefaultPhone,
} from '../../hooks/clients/mutations'
import type { PhoneNumber } from '../../hooks/clients/queries'

type Props = {
  clientId: string
  phoneNumbers: Array<PhoneNumber>
  onCancel: () => void
  onSave: () => void
}

type FormState = {
  defaultNumber: string
  addPhone: string
}

function PhoneEditForm({ clientId, phoneNumbers, onCancel, onSave }: Props) {
  const defaultPhone = getDefaultPhoneNumber(phoneNumbers)
  const setToast = useToast()

  const defaultValues = {
    defaultNumber: defaultPhone?.objectId ?? '',
    addPhone: '',
  }

  const { control, handleSubmit, watch } = useForm<FormState>({ defaultValues })
  const watchAllFields = watch()

  // PhoneInput component force defaults to undefined if empty
  const newPhoneNumber = watchAllFields.addPhone ?? ''

  const isDefaultNumberDisabled = Boolean(newPhoneNumber)
  const isAddPhoneDisabled =
    watchAllFields.defaultNumber !== defaultValues.defaultNumber

  const isSaveDisabled =
    (defaultValues.addPhone === newPhoneNumber &&
      defaultValues.defaultNumber === watchAllFields.defaultNumber) ||
    (newPhoneNumber !== '' && !isValidPhoneNumber(newPhoneNumber))

  const updateDefaultPhone = useUpdateDefaultPhone()
  const addPhoneNumber = useAddPhoneNumber()

  function handleSave(data: FormState) {
    if (data.defaultNumber !== defaultValues.defaultNumber) {
      updateDefaultPhone.mutate({
        clientId,
        clientPhoneId: data.defaultNumber,
      })
      onSave()
    }

    if (data.addPhone && isValidPhoneNumber(data.addPhone)) {
      // exit early if adding a phone number that already exists
      if (
        phoneNumbers
          .map((phoneNumber) => phoneNumber.e164)
          .some((phone) => phone === data.addPhone)
      ) {
        return setToast({
          message: 'That phone number already exists.',
        })
      }

      addPhoneNumber.mutate(
        { clientId, e164: data.addPhone },
        {
          onError: () => {
            setToast({
              message: 'Something went wrong while adding a new phone number.',
              color: 'danger',
            })
          },
        }
      )
      onSave()
    }
  }

  return (
    <section>
      <Controller
        name="defaultNumber"
        control={control}
        render={({ field: { value, onChange } }) => (
          <IonRadioGroup value={value} onIonChange={onChange}>
            {phoneNumbers.map((phoneNumber, index) => (
              <IonItem
                color="secondary"
                key={phoneNumber.objectId}
                lines={index === phoneNumbers.length - 1 ? 'none' : 'inset'}
              >
                <IonLabel>
                  <div>
                    <IonText color="primary">
                      <div className="font-semibold">
                        {phoneNumber.posName ?? 'No Label'}
                      </div>
                    </IonText>
                    <span>
                      {phoneNumber.e164
                        ? formatPhoneNumber(phoneNumber.e164.toString())
                        : phoneNumber.phoneNumber}
                    </span>
                    <span>
                      {phoneNumber.verified
                        ? ` (Verified${
                            phoneNumber.type ? `: ${phoneNumber.type}` : ''
                          })`
                        : null}
                    </span>
                  </div>
                </IonLabel>
                <IonRadio
                  mode="md"
                  slot="end"
                  value={phoneNumber.objectId}
                  disabled={isDefaultNumberDisabled}
                />
              </IonItem>
            ))}
          </IonRadioGroup>
        )}
      />

      <IonItem color="secondary" disabled={isAddPhoneDisabled}>
        <Controller
          control={control}
          name="addPhone"
          render={({ field: { value, onChange, onBlur } }) => (
            <PhoneInput
              className="h-[70px]"
              placeholder="Add Phone Number"
              defaultCountry="US"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
        {newPhoneNumber && (
          <IonIcon
            slot="end"
            color={
              isValidPhoneNumber(newPhoneNumber ?? '') ? 'success' : 'danger'
            }
            icon={isValidPhoneNumber(newPhoneNumber ?? '') ? checkmark : close}
          />
        )}
      </IonItem>
      <div className="flex justify-between">
        <IonButton
          color="primary"
          fill="outline"
          size="small"
          onClick={onCancel}
        >
          Cancel
        </IonButton>
        <IonButton
          color="yellow"
          size="small"
          onClick={handleSubmit(handleSave)}
          disabled={isSaveDisabled}
        >
          Save
        </IonButton>
      </div>
    </section>
  )
}

export default PhoneEditForm

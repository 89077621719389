import { useQuery } from '@tanstack/react-query'
import { fromZodError } from 'zod-validation-error'

import groupPurchases from '../../utils/group-purchases'
import { getLastPurchase } from '../../utils/sales'
import { makeServerResponseSchema } from '../../utils/schema'
import { client } from '../../services/client'
import { taskParseClasses } from '../../tasks/constants'
import { taskResponseSchema } from '../../tasks/tasks.schema'

import type { IsoDate } from '../../types/general'
import type { QueryContextFromKeys } from '../../utils/react-query'
import type { ClientDetail } from '../clients/queries'

type FetchTasksResponse = {
  result: {
    tasks: Array<TaskOverview>
  }
}

export type TaskClass = typeof taskParseClasses[number]

type TaskProps = {
  objectId: string
  className: TaskClass
}

export type TaskOverview = {
  objectId: string
  className: TaskClass
  completedAt: IsoDate | null
  createdAt: IsoDate
  due: IsoDate | null
  firstName: string
  lastName: string
  isPublic: boolean
  note: string
  photo: string | null
  photos: Array<{ url: string }> | null
  ticket: string | null
  trackingNumber: string | null
  vendor: string
  title?: string
  user: { objectId: string }
}

type TaskQueryContexts = QueryContextFromKeys<typeof taskQueryKeys>

export const taskQueryKeys = {
  all: [{ entity: 'tasks' }] as const,
  lists: () => [{ ...taskQueryKeys.all[0], scope: 'list' }] as const,
  list: () => [{ ...taskQueryKeys.lists()[0] }] as const,
  details: () => [{ ...taskQueryKeys.all[0], scope: 'detail' }] as const,
  detail: ({ id, type }: { id: string; type: TaskClass }) =>
    [{ ...taskQueryKeys.details()[0], id, type }] as const,
}

async function fetchTasks() {
  const response: FetchTasksResponse = await client
    .post('functions/tasks')
    .json()

  return response.result.tasks
}

async function fetchTaskDetail({
  queryKey: [{ id, type }],
}: TaskQueryContexts['detail']) {
  const taskResponse = await client
    .post('functions/getTask', {
      json: { objectId: id, className: type },
    })
    .json()

  const parsedResponse =
    makeServerResponseSchema(taskResponseSchema).safeParse(taskResponse)

  if (!parsedResponse.success)
    throw new Error(fromZodError(parsedResponse.error).message)

  const clientResponse: { result: ClientDetail } = await client
    .post('functions/page_client_id', {
      json: { objectId: parsedResponse.data.result.task.client.objectId },
    })
    .json()

  const purchases = groupPurchases(clientResponse.result.sales)

  return {
    client: {
      ...clientResponse.result,
      lastPurchase: getLastPurchase(purchases[0]),
    },
    task: parsedResponse.data.result.task,
  }
}

function useTasks() {
  return useQuery({ queryKey: taskQueryKeys.list(), queryFn: fetchTasks })
}

function useTaskDetail({ objectId, className }: TaskProps) {
  return useQuery({
    queryKey: taskQueryKeys.detail({ id: objectId, type: className }),
    queryFn: fetchTaskDetail,
  })
}

export { useTasks, useTaskDetail }

import * as React from 'react'
import { Link } from 'react-router-dom'
import {
  IonCard,
  IonCardContent,
  IonItem,
  IonLabel,
  IonText,
  IonSelect,
  IonSelectOption,
  IonCheckbox,
} from '@ionic/react'
import { dequal } from 'dequal/lite'
import { format, formatDistance, isBefore, parseISO } from 'date-fns'

import { useMetaState } from '../../contexts/cardMetaContext'
import { useUpdateAdminClient } from '../../hooks/clients/mutations'
import { useAdmin } from '../../pages/admin/clients'
import Avatar from '../avatar'
import Currency from '../Currency'
import { ClientTags } from '../client/client-tags'
import type { AdminClient } from '../../hooks/clients/queries'

const AssociateSelect = ({ client }: { client: AdminClient }) => {
  const [selectedAssociates, setSelectedAssociates] = React.useState<
    Array<string>
  >(() => (client.users ? client.users.map((client) => client.objectId) : []))

  const { associates } = useAdmin()
  const updateClient = useUpdateAdminClient()

  React.useEffect(() => {
    setSelectedAssociates(
      client.users ? client.users.map((client) => client.objectId) : []
    )
  }, [client.users])

  function handleSelect(selectedUsers: Array<string>) {
    // BUG: incorrectly formatted values - https://github.com/ionic-team/ionic-framework/issues/24584
    if (!Array.isArray(selectedUsers)) return

    const currentUsers = client.users
      ? client.users.map((client) => client.objectId)
      : []

    // do nothing if value (selected users) is same as client.users (current state)
    if (dequal(selectedUsers, currentUsers)) {
      return false
    }

    updateClient.mutate({
      objectId: client.objectId,
      users: selectedUsers,
    })
  }

  return (
    <IonItem color="secondary" lines="none">
      <IonLabel>Assigned to:</IonLabel>
      <IonSelect
        multiple
        value={selectedAssociates}
        onIonChange={(e) => {
          setSelectedAssociates(e.detail.value)
          handleSelect(e.detail.value)
        }}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
        }}
      >
        {associates?.map((associate) => (
          <IonSelectOption key={associate.objectId} value={associate.objectId}>
            {associate.name || '-'}
          </IonSelectOption>
        ))}
      </IonSelect>
    </IonItem>
  )
}

function AdminClientCard({
  client,
  path,
}: {
  client: AdminClient
  path: string
}) {
  const {
    showCheckbox,
    associates,
    handleClientSelect: handleClientSelected,
  } = useAdmin()

  const { adminClientsMeta: meta } = useMetaState()
  const {
    objectId,
    firstName,
    lastName,
    lastContact,
    nextContact,
    lastSale,
    lastSaleAmt,
    assignedBy,
    assignedAt,
  } = client

  const lastPurchaseDate =
    meta.includes('lastSale') && lastSale !== undefined
      ? format(parseISO(lastSale.iso), 'MM/dd/yy')
      : null
  const nextContactDate =
    meta.includes('nextContact') && nextContact != null
      ? formatDistance(parseISO(nextContact.iso), new Date(), {
          addSuffix: true,
        })
      : null
  const lastContactDate =
    meta.includes('lastContact') && lastContact != null
      ? format(parseISO(lastContact.iso), 'MM/dd/yy')
      : null
  const assignedAtDate =
    meta.includes('associates') && assignedAt !== undefined
      ? format(parseISO(assignedAt.iso), 'MM/dd/yy')
      : null
  const assignedByName =
    meta.includes('associates') &&
    assignedBy !== undefined &&
    assignedBy.firstName
      ? `${assignedBy.firstName} ${assignedBy.lastName}`
      : null

  return (
    <IonCard color="secondary">
      <Link to={`/${path}/${objectId}`}>
        <IonItem color="secondary" lines="none" className="pt-2">
          <div tabIndex={0} />
          <div slot="start">
            <Avatar user={client} />
          </div>
          <IonLabel>
            <h2 className="text-ion-color-primary text-xl font-bold">
              {firstName} {lastName}
            </h2>
          </IonLabel>

          {showCheckbox && (
            <IonCheckbox
              checked={client.isSelected}
              color="secondary"
              // TODO: onIonChange runs even if called remotely, POSSIBLE FIX in IONIC 7 TO USE onIonChange
              onClick={(e) => {
                handleClientSelected(objectId, (e.target as any).checked)
              }}
            />
          )}
        </IonItem>
        <IonCardContent className="py-2">
          {meta.includes('posSellerNames') &&
          client.posSellerNames &&
          client.posSellerNames.length > 0 ? (
            <IonText>
              <p>
                <span className="font-bold">SELLERS: </span>
                <span>{client.posSellerNames.join(', ')}</span>
              </p>
            </IonText>
          ) : null}

          {meta.includes('filterSalesTotal') && client.filterSalesTotal ? (
            <IonText>
              <p>
                <span className="font-bold">FILTERED SALES: </span>
                <Currency value={client.filterSalesTotal} />
              </p>
            </IonText>
          ) : null}

          {meta.includes('salesTotal') ? (
            <IonText>
              <p>
                <span className="font-bold">TOTAL PURCHASED: </span>
                <Currency value={client.salesTotal} />
              </p>
            </IonText>
          ) : null}

          {lastPurchaseDate && (
            <IonText color="primary">
              <p>
                <span className="font-bold">LAST PURCHASE: </span>
                <span>{lastPurchaseDate}</span>
              </p>
            </IonText>
          )}
          {meta.includes('lastSaleAmt') ? (
            <IonText color="primary">
              <p>
                <span className="font-bold">AMOUNT SPENT: </span>
                <Currency value={lastSaleAmt} />
              </p>
            </IonText>
          ) : null}
          {nextContactDate && (
            <IonText
              color={
                nextContact && isBefore(parseISO(nextContact.iso), new Date())
                  ? 'danger'
                  : 'primary'
              }
            >
              <p>
                <span className="font-bold">NEXT CONTACT: </span>
                <span>{nextContactDate}</span>
              </p>
            </IonText>
          )}
          {lastContactDate && (
            <IonText>
              <p>
                <span className="font-bold">LAST CONTACTED: </span>
                <span>{lastContactDate}</span>
              </p>
            </IonText>
          )}

          {assignedAtDate && (
            <IonText>
              <p>
                <span className="font-bold">ASSIGNED AT: </span>
                <span>{assignedAtDate}</span>
              </p>
            </IonText>
          )}
          {assignedByName && (
            <IonText>
              <p>
                <span className="font-bold">ASSIGNED BY: </span>
                <span>{assignedByName}</span>
              </p>
            </IonText>
          )}

          {meta.includes('associates') && associates ? (
            <AssociateSelect client={client} />
          ) : null}
        </IonCardContent>
      </Link>
      <ClientTags clientId={client.objectId} />
    </IonCard>
  )
}

export default AdminClientCard

import { IonPage, IonContent, IonHeader } from '@ionic/react'
import { ErrorBoundary } from 'react-error-boundary'

import AdminHeader from '../../components/header/admin-header'
import ErrorFallback from '../../components/error-fallback'
import { PostApprovalList } from '../../admin/approvals/components/approvals'

function ApprovalsPage() {
  return (
    <IonPage>
      <IonHeader>
        <AdminHeader title="Approvals" showMenu />
      </IonHeader>
      <IonContent>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <PostApprovalList />
        </ErrorBoundary>
      </IonContent>
    </IonPage>
  )
}

export { ApprovalsPage }

import * as React from 'react'
import {
  IonContent,
  IonButton,
  IonPage,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonCol,
  IonText,
  IonCard,
  IonCardContent,
  IonGrid,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonImg,
  IonRouterLink,
} from '@ionic/react'

import logo from '../resources/OneShop_Logo_White.png'
import { useResetPassword } from '../user/mutations'

function Recovery() {
  const [email, setEmail] = React.useState('')
  const [sent, setSent] = React.useState(false)
  const resetPassword = useResetPassword()

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    resetPassword.mutate(
      { email },
      {
        onSuccess: () => {
          setSent(true)
        },
      }
    )
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            <h2>Password Reset</h2>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid className="h-full">
          <IonRow className="h-full items-center justify-center">
            <IonCol size="12" className="text-center">
              <IonImg src={logo} alt="" className="h-16" />

              <div className="mx-auto max-w-lg">
                <IonCard color="secondary">
                  <IonCardContent>
                    <form
                      onSubmit={handleSubmit}
                      onKeyUp={(e) => {
                        if (e.code === 'Enter') handleSubmit(e)
                      }}
                    >
                      {sent && (
                        <IonText color="success">
                          <p className="ion-padding-start">
                            An email was sent to your inbox, check your inbox
                            and click the link to reset your password
                          </p>
                          <p className="ion-padding-start">
                            <a
                              style={{
                                color: 'black',
                                textDecoration: 'underline',
                              }}
                              href="/login"
                            >
                              Go Back
                            </a>{' '}
                            to login
                          </p>
                        </IonText>
                      )}

                      <IonItem color="secondary">
                        <IonLabel position="stacked">Username/Email </IonLabel>
                        <IonInput
                          onIonChange={(e) =>
                            setEmail(e.detail.value!.toLowerCase())
                          }
                          name="email"
                          type="email"
                          autocapitalize="off"
                          value={email}
                          required
                        />
                      </IonItem>
                      <IonGrid style={{ textAlign: 'center' }}>
                        <IonButton
                          type="submit"
                          disabled={resetPassword.isLoading || sent}
                        >
                          Reset Password
                        </IonButton>
                      </IonGrid>
                    </form>
                  </IonCardContent>
                </IonCard>
              </div>

              <div className="text-center">
                Don't have an account?{' '}
                <IonRouterLink
                  className="text-ion-color-secondary underline"
                  routerLink="/create-account"
                  routerDirection="none"
                >
                  Sign up
                </IonRouterLink>
              </div>
              <div className="text-center">
                Have an account?{' '}
                <IonRouterLink
                  className="text-ion-color-secondary underline"
                  routerLink="/login"
                  routerDirection="none"
                >
                  Log in
                </IonRouterLink>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export default Recovery

import {
  IonButton,
  IonContent,
  IonHeader,
  IonLoading,
  IonPage,
} from '@ionic/react'
import { useParams } from 'react-router-dom'

import { composeSearchFilters } from '../../utils/compose-search-filters'
import { canEdit } from '../../utils/forms'
import ErrorFallback from '../../components/error-fallback'
import Header from '../../components/header'
import BroadcastDetail from '../../marketing/broadcasts/components/broadcast-detail'
import { EditBroadcastForm } from '../../marketing/broadcasts/components/edit-broadcast-form'
import { useCreateMyBroadcast } from '../../marketing/broadcasts/mutations'
import { useBroadcastDetail } from '../../marketing/broadcasts/queries'
import { convertServerUnlayerDesign } from '../../unlayer/utils'

import type { ClonedBroadcastInput } from '../../marketing/broadcasts/broadcast.schema'

function BroadcastDetailPage() {
  const { broadcastId } = useParams<{ broadcastId: string }>()

  const broadcastQuery = useBroadcastDetail(broadcastId)
  const createBroadcast = useCreateMyBroadcast()

  const showForm = canEdit(broadcastQuery.data?.status)

  function cloneBroadcast() {
    const broadcast = broadcastQuery.data

    if (!broadcast) throw new Error('There is no broadcast loaded.')

    const clonedBroadcast: ClonedBroadcastInput = {
      setAttachmentsSms: broadcast.attachmentsSms,
      setAttachmentsEmail: broadcast.attachmentsEmail,
      emailMessage: broadcast.emailMessage ?? '',
      emailSubject: broadcast.emailSubject ?? '',
      filter: composeSearchFilters(broadcast.filters),
      messageType: broadcast.messageType || 'prioSmsEmail',
      smsMessage: broadcast.smsMessage ?? '',
      title: `${broadcast.title} copy`,
      date: null,
      collectionIdsSms:
        broadcast.collectionsSms?.map((c) => c.objectId) || undefined,
      lookIdsSms: broadcast.looksSms?.map((l) => l.objectId) || undefined,
      productIdsSms: broadcast.productsSms?.map((p) => p.objectId) || undefined,
      collectionIdsEmail:
        broadcast.collectionsEmail?.map((c) => c.objectId) || undefined,
      lookIdsEmail: broadcast.looksEmail?.map((l) => l.objectId) || undefined,
      productIdsEmail:
        broadcast.productsEmail?.map((p) => p.objectId) || undefined,
      unlayerDesign: broadcast.unlayerDesign
        ? convertServerUnlayerDesign(broadcast.unlayerDesign)
        : undefined,
      requiresApproval: broadcast.requiresApproval,
    }

    createBroadcast.mutate(clonedBroadcast)
  }

  return (
    <IonPage>
      <IonHeader>
        <Header
          title={broadcastQuery.data?.title ?? ' '}
          backRef="/broadcasts"
          btn={
            <IonButton color="yellow" fill="solid" onClick={cloneBroadcast}>
              Clone
            </IonButton>
          }
        />
      </IonHeader>

      <IonContent>
        {broadcastQuery.data ? (
          <>
            {showForm ? (
              <EditBroadcastForm
                backUrl="/broadcasts"
                broadcast={broadcastQuery.data}
              />
            ) : (
              <BroadcastDetail broadcast={broadcastQuery.data} />
            )}
          </>
        ) : broadcastQuery.isError ? (
          <ErrorFallback error={broadcastQuery.error}>
            There was a problem loading the broadcast.
          </ErrorFallback>
        ) : (
          <IonLoading isOpen message="Loading Broadcast..." />
        )}
      </IonContent>
    </IonPage>
  )
}

export { BroadcastDetailPage }

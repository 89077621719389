import * as React from 'react'
import {
  IonGrid,
  IonCol,
  IonRow,
  IonCard,
  IonCardContent,
  IonItem,
  IonLabel,
  IonIcon,
  IonListHeader,
  IonList,
  IonItemGroup,
} from '@ionic/react'
import { chevronDown, chevronUp } from 'ionicons/icons'

import * as commonStrings from '../../utils/common-strings'
import { getDateString } from '../../utils/date-helpers'
import CardItemHeader from './CardItemHeader'
import CardItemPhotos from './CardItemPhotos'
import { getStores } from '../../utils/sales'
import Currency from '../Currency'

function getPhotoSrc(object) {
  return object.photo !== null &&
    object.photo !== '' &&
    object.photo !== undefined
    ? object.photo
    : null
}

function LineItem({ sale, photoSrc }) {
  const hasUserPhotos = sale.photos.length > 0
  const hasSystemPhotos = sale.posPhotoUrls.length > 0

  const meta = [
    { label: 'Brand: ', value: sale.brand },
    { label: 'Price: ', value: <Currency value={sale.value} /> },
    { label: 'Size: ', value: sale.size },
    { label: 'Color: ', value: sale.color },
    { label: 'Attribute: ', value: sale.posAttribute1 },
    { label: 'Description: ', value: sale.description },
    { label: 'Quantity: ', value: sale.qty },
    { label: 'SKU: ', value: sale.sku },
    { label: 'Note: ', value: sale.note },
  ]

  return (
    <IonItem
      style={{
        '--inner-padding-top': '16px',
        '--inner-padding-bottom': '16px',
      }}
      color="secondary"
    >
      <div>
        {meta.map((meta) => {
          if (!meta.label || !meta.value) return null

          return (
            <p key={meta.label}>
              <span style={{ fontWeight: 'bold' }}>
                {meta.label.toUpperCase()}
              </span>
              {meta.value}
            </p>
          )
        })}
        {hasUserPhotos || hasSystemPhotos ? (
          <IonGrid>
            {hasUserPhotos && (
              <>
                <IonRow>
                  <IonCol size="12" class="ion-justify-content-center">
                    <p>User Photos</p>
                  </IonCol>
                </IonRow>
                <IonRow>
                  {sale.photos.length > 0 && (
                    <CardItemPhotos
                      photosArray={sale.photos}
                      photoSrc={photoSrc}
                    />
                  )}
                </IonRow>
              </>
            )}
            {hasSystemPhotos && (
              <>
                <IonRow>
                  <IonCol size="12" class="ion-justify-content-center">
                    <p>System Photos</p>
                  </IonCol>
                </IonRow>
                <IonRow>
                  {sale.posPhotoUrls.length > 0 && (
                    <CardItemPhotos
                      photosArray={sale.posPhotoUrls}
                      photoSrc={photoSrc}
                    />
                  )}
                </IonRow>
              </>
            )}
          </IonGrid>
        ) : null}
      </div>
    </IonItem>
  )
}

function LineItemsList({ sales, photoSrc, topSeller }) {
  return (
    <IonList>
      <IonListHeader className="ion-no-padding" color="secondary">
        Items:
      </IonListHeader>

      {/* TOP SELLER */}
      <IonItemGroup>
        <IonLabel style={{ marginTop: '16px', fontSize: '1.25rem' }}>
          {sales.topSeller.sellerName}
        </IonLabel>
        {sales.topSeller.sales.map((sale, idx) => (
          <LineItem key={idx} sale={sale} photoSrc={photoSrc} />
        ))}
      </IonItemGroup>

      {/* Other Sellers */}
      {sales.otherSellers.map((otherSeller, idx) => (
        <IonItemGroup key={idx}>
          <IonLabel style={{ marginTop: '16px', fontSize: '1.25rem' }}>
            {otherSeller.sellerName}
          </IonLabel>
          {otherSeller.sales.map((sale, i) => (
            <LineItem key={i} sale={sale} photoSrc={photoSrc} />
          ))}
        </IonItemGroup>
      ))}
    </IonList>
  )
}

function CardPurchase({ purchase }) {
  const [isPanelOpen, setIsPanelOpen] = React.useState(false)

  const dateString = getDateString(purchase)
  const photoSrc = getPhotoSrc(purchase)
  const stores = getStores(purchase.allSales)

  const user = {
    firstName: purchase.organizedSales.topSeller.sellerName,
    photo: purchase.organizedSales.topSeller.sellerPhoto,
  }

  return (
    <IonCard color={commonStrings.secondary}>
      <CardItemHeader
        user={user}
        date={dateString}
        subtitle="Sale"
        otherSellers={purchase.otherSellerNames}
      />
      <IonCardContent>
        <IonItem
          className="--padding-start"
          onClick={() => setIsPanelOpen(!isPanelOpen)}
          color="secondary"
          lines="none"
        >
          <div tabIndex={0} />
          <IonLabel>
            <span className="ion-text-uppercase" style={{ fontWeight: 'bold' }}>
              Total Sale
            </span>{' '}
            <Currency value={purchase.totalSaleAmount} /> at {stores.join(', ')}
          </IonLabel>
          <IonIcon slot="end" icon={isPanelOpen ? chevronUp : chevronDown} />
        </IonItem>

        {isPanelOpen && (
          <LineItemsList
            topSeller={purchase.organizedSales.topSeller.sellerName}
            photoSrc={photoSrc}
            sales={purchase.organizedSales}
          />
        )}
      </IonCardContent>
    </IonCard>
  )
}

export default CardPurchase

import * as React from 'react'
import {
  IonContent,
  IonButton,
  IonPage,
  IonInput,
  IonItem,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonImg,
  IonRouterLink,
  IonLoading,
} from '@ionic/react'
import { useHistory } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'

import logo from '../resources/OneShop_Logo_White.png'
import { AuthContext } from '../contexts/authContext'
import { useQueryParams } from '../hooks/use-query-params'
import PasswordInput from '../components/ui/form/password-input'
import { useLogin } from '../user/mutations'

const defaultValues = {
  username: '',
  password: '',
}

type FormState = typeof defaultValues

function Login() {
  const history = useHistory()
  const shopParam = useQueryParams().get('shop')
  const emailParam = useQueryParams().get('email')
  const companyNameParam = useQueryParams().get('companyName')

  React.useEffect(() => {
    if (shopParam && emailParam && companyNameParam) {
      history.replace({
        ...window.location,
        pathname: '/create-account',
      })
    }
  }, [shopParam, emailParam, companyNameParam, history])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            <h2>Login</h2>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid className="h-full">
          <IonRow className="h-full items-center justify-center">
            <IonCol size="12" className="text-center">
              <IonImg src={logo} alt="Oneshop Logo" className="h-16" />

              <div className="mx-auto max-w-lg">
                <IonCard color="secondary">
                  <IonCardContent>
                    <LoginForm />
                  </IonCardContent>
                </IonCard>
              </div>

              <div className="text-center">
                Don't have an account?{' '}
                <IonRouterLink
                  className="text-ion-color-secondary underline"
                  routerLink="/create-account"
                  routerDirection="none"
                >
                  Sign up!
                </IonRouterLink>{' '}
              </div>
              <div className="text-center">
                <IonRouterLink
                  className="text-ion-color-secondary underline"
                  routerLink="/recovery"
                  routerDirection="none"
                >
                  Forgot password?
                </IonRouterLink>
              </div>
              <div className="text-center">
                version {process.env.REACT_APP_VERSION}
              </div>
            </IonCol>
            <IonCol className="text-center">
              <a
                href="https://oneshopretail.com/privacy-policy/"
                target="_blank"
                rel="noreferrer"
                className="text-ion-color-secondary underline"
              >
                Privacy Policy
              </a>{' '}
              |{' '}
              <a
                href="https://oneshopretail.com/terms-of-service/"
                target="_blank"
                rel="noreferrer"
                className="text-ion-color-secondary underline"
              >
                Terms and Conditions
              </a>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

function LoginForm() {
  const shopParam = useQueryParams().get('shop')

  const { username } = React.useContext(AuthContext)

  const login = useLogin()

  const { handleSubmit, control } = useForm<FormState>({
    defaultValues: {
      ...defaultValues,
      username: username ?? '',
    },
  })

  function handleLoginSubmit(formData: FormState) {
    login.mutate(formData, {
      onSuccess: () => {
        if (shopParam) {
          window.location.replace(`/account${window.location.search}`)
        } else {
          window.location.replace('/')
        }
      },
    })
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(handleLoginSubmit)}
        onKeyUp={(e) => {
          if (e.code === 'Enter') {
            handleSubmit(handleLoginSubmit)()
          }
        }}
      >
        <IonItem color="secondary">
          <IonLabel position="stacked">Username</IonLabel>
          <Controller
            name="username"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <IonInput
                  type="email"
                  value={value}
                  onIonChange={onChange}
                  autocapitalize="off"
                  required
                />
              )
            }}
          />
        </IonItem>

        <IonItem color="secondary" className="items-center">
          <IonLabel position="stacked">Password</IonLabel>
          <Controller
            name="password"
            control={control}
            render={({ field: { value, onChange } }) => {
              return <PasswordInput password={value} onChange={onChange} />
            }}
          />
        </IonItem>

        <div className="ion-padding-vertical">
          <IonButton type="submit" disabled={login.isLoading}>
            Login
          </IonButton>
        </div>
      </form>
      <IonLoading isOpen={login.isLoading} message="Logging in..." />
    </>
  )
}

export default Login

import { CheckIcon, MinusIcon } from '@heroicons/react/24/solid'

import type { MRT_ColumnDef } from 'material-react-table'
import type { UserRoleSocial } from '../../../social-sharing/roles/userRoleSocial.schema'

const columns: Array<MRT_ColumnDef<UserRoleSocial>> = [
  {
    accessorKey: 'firstName',
    header: 'First Name',
    enableEditing: false,
  },
  {
    accessorKey: 'lastName',
    header: 'Last Name',
    enableEditing: false,
  },
  {
    accessorKey: 'roleSocial.role',
    header: 'Role',
    editVariant: 'select',
    editSelectOptions: ['creator', 'admin'],
  },
  {
    accessorKey: 'roleSocial.requiresApproval',
    header: 'Approval Required',
    Cell: ({ cell }) =>
      cell.getValue() === true ? (
        <span>
          <CheckIcon className="size-6" />
        </span>
      ) : (
        <span>
          <MinusIcon className="size-6" />
        </span>
      ),
    editVariant: 'select',
    editSelectOptions: [
      { text: 'Yes', value: true },
      { text: 'No', value: false },
    ],
  },
]

export { columns }

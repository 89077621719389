import * as React from 'react'
import {
  IonContent,
  IonButton,
  IonCol,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  useIonAlert,
  SelectChangeEventDetail,
  IonModal,
} from '@ionic/react'
import { filter } from 'ionicons/icons'
import { klona } from 'klona'

import { createContext } from '../../utils/create-context'
import { getFullName } from '../../utils/format-helpers'
import { typedBoolean } from '../../utils/typescript-helpers'
import { useAllClients } from '../../hooks/clients/queries'
import { useAdminAssociates } from '../../hooks/queries/use-associate'
import {
  useMergeClients,
  useUpdateAdminClients,
} from '../../hooks/clients/mutations'
import { useFormatNumber } from '../../hooks/use-format-number'
import AdminClientList from '../../components/admin/admin-client-list'
import PullClientsModal from '../../components/client/pull-clients-modal'
import AdminHeader from '../../components/header/admin-header'

import type { AdminClient } from '../../hooks/clients/queries'
import type { FiltersForm } from '../../clients/client-filters.schema'

type AdminContext = {
  associates: Array<{ objectId: string; name: string }>
  showCheckbox: boolean
  handleAssociateSelect: () => void
  handleClientSelect: (id: string, isChecked: boolean) => void
}

// todo return this as part of the query so we can change it dynamically?
const MAX_CLIENTS = 5000

const [useAdmin, ContextProvider] = createContext<AdminContext>('Admin Context')

function getVisibleClients(
  clients: Array<AdminClient>,
  selectedClients: Array<string>,
  searchString: string
) {
  let newList = clients.map((client) => ({
    ...klona(client),
    isSelected: selectedClients.includes(client.objectId),
  }))

  // SEARCH
  if (searchString) {
    newList = newList.filter((client) => {
      const searchStringLowerCase = searchString
        ? searchString.toLowerCase().replace(' ', '').trim()
        : null

      const firstName = client.firstName || ''
      const lastName = client.lastName || ''
      const fullName = (firstName + lastName).toLowerCase()
      if (!searchStringLowerCase) return false
      if (fullName.includes(searchStringLowerCase)) return true
      return false
    })
  }

  return newList
}

function Clients() {
  const [searchString] = React.useState('')
  const [filterPanelOpen, setFilterPanelOpen] = React.useState(true)
  const [filters, setFilters] = React.useState<FiltersForm>({})
  const [selectedClients, setSelectedClients] = React.useState<Array<string>>(
    []
  )

  const [presentMergeWarning] = useIonAlert()

  const associatesQuery = useAdminAssociates()
  const clientsQuery = useAllClients(filters)
  const updateClients = useUpdateAdminClients()
  const mergeClients = useMergeClients()

  const formatNumber = useFormatNumber()

  const visibleClients = React.useMemo(
    () =>
      getVisibleClients(clientsQuery.data ?? [], selectedClients, searchString),
    [clientsQuery.data, searchString, selectedClients]
  )

  function getSelectedVisibleClients({
    preserveOrder,
  }: { preserveOrder?: boolean } = {}) {
    return preserveOrder
      ? selectedClients
          .map((id) => visibleClients.find((client) => id === client.objectId))
          .filter(typedBoolean)
      : visibleClients
          .filter((visibleClient) =>
            selectedClients.find(
              (selectedClient) => selectedClient === visibleClient.objectId
            )
          )
          .filter(typedBoolean)
  }

  function selectAll() {
    const selected = visibleClients.map((client) => client.objectId)
    setSelectedClients(selected)
  }

  function selectNone() {
    setSelectedClients([])
  }

  function assignAssociatesToSelected(
    e: CustomEvent<SelectChangeEventDetail<Array<string>>>
  ) {
    const userIds = e.detail.value

    const selectedClients = getSelectedVisibleClients() // selected Clients in visible list
    const clientIds = selectedClients.map((client) => client?.objectId)

    updateClients.mutate({
      clientIds,
      users: userIds,
    })
  }

  function handleSearch(newFilters: any) {
    selectNone()
    setFilters(newFilters)
    setFilterPanelOpen(false)
  }

  function handleClientSelect(id: string, isChecked: boolean) {
    if (isChecked) {
      setSelectedClients([...selectedClients, id])
    } else {
      setSelectedClients([
        ...selectedClients.filter((clientId) => clientId !== id),
      ])
    }
  }

  function handleMergeClick() {
    const clients = getSelectedVisibleClients({ preserveOrder: true })
    if (!clients[0] || !clients[1]) return

    presentMergeWarning({
      header: 'Alert: Clients Will Be Merged',
      message: `You are about to merge ${getFullName(
        clients[1]
      )} into ${getFullName(
        clients[0]
      )}. This cannot be reversed. Are you sure?`,
      buttons: [
        'Cancel',
        {
          text: 'Ok',
          handler: () => {
            if (!clients[0] || !clients[1]) return

            selectNone()
            mergeClients.mutate({
              clientId: clients[0].objectId,
              fromClientId: clients[1].objectId,
            })
          },
        },
      ],
    })
  }

  return (
    <IonPage>
      <IonHeader>
        <AdminHeader title="Clients" showMenu showInbox />
      </IonHeader>
      <IonContent className="ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonButton
                color="yellow"
                onClick={selectAll}
                expand="block"
                disabled={
                  getSelectedVisibleClients().length === visibleClients.length
                }
              >
                SELECT ALL
              </IonButton>
            </IonCol>
            <IonCol>
              <IonButton
                color="secondary"
                onClick={selectNone}
                expand="block"
                disabled={getSelectedVisibleClients().length === 0}
              >
                DESELECT ALL
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>

        {visibleClients.length ? (
          <IonGrid>
            <IonRow>
              <IonCol size="auto">
                <IonButton
                  color="yellow"
                  expand="block"
                  disabled={selectedClients.length !== 2}
                  onClick={handleMergeClick}
                >
                  Merge Clients
                </IonButton>
              </IonCol>{' '}
              <IonCol>
                <IonSelect
                  disabled={getSelectedVisibleClients().length === 0}
                  multiple
                  placeholder="Assign Selected to"
                  onIonChange={assignAssociatesToSelected}
                >
                  {associatesQuery.data?.map((item) => (
                    <IonSelectOption key={item.objectId} value={item.objectId}>
                      {item.name || '-'}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonCol>
            </IonRow>
          </IonGrid>
        ) : null}

        {visibleClients.length >= MAX_CLIENTS && (
          <IonText color="danger">
            <h2
              style={{
                fontSize: '15px',
                margin: '16px 0px 0px 16px',
              }}
            >
              WARNING: increase the number of filters to see all results
            </h2>
          </IonText>
        )}

        <IonItem button onClick={() => setFilterPanelOpen(true)} lines="none">
          <div tabIndex={0} />
          <IonLabel color="secondary">
            <p
              style={{
                fontSize: '15px',
                fontWeight: '600',
                whiteSpace: 'normal',
              }}
            >
              <span>
                {`Results: ${visibleClients.length >= MAX_CLIENTS ? '>' : ''}`}{' '}
              </span>
              <span>{`${formatNumber(visibleClients.length)}`}</span>
              <IonText
                color="yellow"
                style={{
                  padding: '24px',
                }}
              >
                <br></br>
                <span>{`Selected: ${formatNumber(
                  getSelectedVisibleClients().length
                )}`}</span>
              </IonText>
            </p>
          </IonLabel>
          <IonButton slot="end" color="secondary">
            {'Search Clients'}
            <IonIcon icon={filter} />
          </IonButton>
        </IonItem>

        {/* Trying this since before (without the check) the ClientList Cards were re-rendering
        and firing the Associate Select onChange whenever the Search Modal was used */}
        {!filterPanelOpen ? (
          <ContextProvider
            value={{
              associates: associatesQuery.data ?? [],
              showCheckbox: true,
              handleAssociateSelect: () => {},
              handleClientSelect,
            }}
          >
            <AdminClientList clientList={visibleClients} />
          </ContextProvider>
        ) : null}
      </IonContent>

      <IonModal
        isOpen={filterPanelOpen}
        onDidDismiss={() => setFilterPanelOpen(false)}
      >
        <PullClientsModal
          isAdmin
          filters={filters}
          onSearch={handleSearch}
          onCancel={() => setFilterPanelOpen(false)}
        />
      </IonModal>

      <IonLoading
        isOpen={clientsQuery.isInitialLoading || mergeClients.isLoading}
        message={'Please wait...'}
      />
    </IonPage>
  )
}

export default React.memo(Clients)
export { useAdmin }

import * as R from 'remeda'

import { typedKeys } from '../utils/typescript-helpers'
import { ayrsharePlatforms } from '../ayrshare/ayrshare-platforms'

import type { PostPlatforms } from './posts/posts.schema'
import type { Ayrshare } from '../ayrshare/ayrshare.schema'

type GetSocialConnectionsProps = Record<string, Array<PostPlatforms>>

function getAccountChannels(channels: Array<Ayrshare> | undefined) {
  return channels?.length
    ? R.mapToObj.indexed(
        channels.filter((a) => a.type === 'business' && a.level !== 'user'),
        (x) => {
          const intersection = R.intersection(
            ayrsharePlatforms.map((platform) => platform.name),
            x.activeSocialAccounts ?? []
          )
          return [x.objectId, intersection]
        }
      )
    : {}
}

function getUserChannels(channels: Array<Ayrshare> | undefined) {
  return channels?.length
    ? R.mapToObj.indexed(
        channels.filter((a) => a.type === 'business' && a.level === 'user'),
        (x, index) => {
          const intersection = R.intersection(
            ayrsharePlatforms.map((platform) => platform.name),
            x.activeSocialAccounts ?? []
          )
          return [
            x.objectId,
            [
              ...intersection,
              ...(index === 0 ? ['oneshop' as PostPlatforms] : []),
            ],
          ]
        }
      )
    : {}
}

function getSocialConnections(selectedChannels: GetSocialConnectionsProps) {
  return typedKeys(selectedChannels)
    .map((c) => ({
      ayrshareId: c,
      platforms: [...selectedChannels[c]],
    }))
    .filter((socialConnection) => socialConnection.platforms.length)
}

export { getAccountChannels, getSocialConnections, getUserChannels }

import { useQueryClient, useMutation } from '@tanstack/react-query'

import { useToast } from '../../contexts/toastContext'
import {
  assignAllClients,
  autoAssignUserClients,
  clearUserClients,
  createGroupMember,
  createUser,
  deleteGroupMember,
  sendWelcomeEmail,
  updateGroupMember,
  updateUser,
} from './actions'
import { userSetupQueryKeys } from './queries'

function useAssignAllClients() {
  const queryClient = useQueryClient()
  const setToast = useToast()

  return useMutation(assignAllClients, {
    onError: (error) => {
      const errorMsg = error instanceof Error ? error.message : 'Unknown error.'

      setToast({
        message: `Something went wrong while updating: ${errorMsg}`,
        color: 'danger',
      })
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: userSetupQueryKeys.all }),
  })
}

function useAutoAssignUserClients() {
  const queryClient = useQueryClient()
  const setToast = useToast()

  return useMutation(autoAssignUserClients, {
    onError: (error) => {
      const errorMsg = error instanceof Error ? error.message : 'Unknown error.'

      setToast({
        message: `Something went wrong while updating: ${errorMsg}`,
        color: 'danger',
      })
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: userSetupQueryKeys.all }),
  })
}

function useClearUserClients() {
  const queryClient = useQueryClient()
  const setToast = useToast()

  return useMutation(clearUserClients, {
    onError: (error) => {
      const errorMsg = error instanceof Error ? error.message : 'Unknown error.'

      setToast({
        message: `Something went wrong while updating: ${errorMsg}`,
        color: 'danger',
      })
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: userSetupQueryKeys.all }),
  })
}

function useCreateGroupMember() {
  const queryClient = useQueryClient()
  const setToast = useToast()

  return useMutation(createGroupMember, {
    onError: (error) => {
      const errorMsg = error instanceof Error ? error.message : 'Unknown error.'

      setToast({
        message: `Something went wrong while adding a new role: ${errorMsg}`,
        color: 'danger',
      })
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: userSetupQueryKeys.all }),
  })
}

function useCreateUser() {
  const queryClient = useQueryClient()
  const setToast = useToast()

  return useMutation(createUser, {
    onError: (error) => {
      const errorMsg = error instanceof Error ? error.message : 'Unknown error.'

      setToast({
        message: `Something went wrong while creating the user: ${errorMsg}`,
        color: 'danger',
      })
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: userSetupQueryKeys.all }),
  })
}

function useDeleteGroupMember() {
  const queryClient = useQueryClient()
  const setToast = useToast()

  return useMutation(deleteGroupMember, {
    onError: (error) => {
      const errorMsg = error instanceof Error ? error.message : 'Unknown error.'

      setToast({
        message: `Something went wrong while creating the user: ${errorMsg}`,
        color: 'danger',
      })
    },
    onSettled: () => queryClient.invalidateQueries(userSetupQueryKeys.all),
  })
}

function useSendWelcomeEmail() {
  const queryClient = useQueryClient()
  const setToast = useToast()

  return useMutation(sendWelcomeEmail, {
    onError: (error) => {
      const errorMsg = error instanceof Error ? error.message : 'Unknown error.'

      setToast({
        message: `Something went wrong while creating the user: ${errorMsg}`,
        color: 'danger',
      })
    },
    onSettled: () => queryClient.invalidateQueries(userSetupQueryKeys.all),
    onSuccess: () =>
      setToast({
        message: 'The welcome email has been sent.',
        color: 'yellow',
      }),
  })
}

function useUpdateGroupMember() {
  const queryClient = useQueryClient()
  const setToast = useToast()

  return useMutation(updateGroupMember, {
    onError: (error) => {
      const errorMsg = error instanceof Error ? error.message : 'Unknown error.'

      setToast({
        message: `Something went wrong while creating the user: ${errorMsg}`,
        color: 'danger',
      })
    },
    onSettled: () => queryClient.invalidateQueries(userSetupQueryKeys.all),
  })
}

function useUpdateUser() {
  const queryClient = useQueryClient()
  const setToast = useToast()

  return useMutation(updateUser, {
    onError: (error) => {
      const errorMsg = error instanceof Error ? error.message : 'Unknown error.'

      setToast({
        message: `Something went wrong while creating the user: ${errorMsg}`,
        color: 'danger',
      })
    },
    onSettled: () => queryClient.invalidateQueries(userSetupQueryKeys.all),
  })
}

export {
  useAssignAllClients,
  useAutoAssignUserClients,
  useClearUserClients,
  useCreateGroupMember,
  useCreateUser,
  useDeleteGroupMember,
  useSendWelcomeEmail,
  useUpdateGroupMember,
  useUpdateUser,
}

import { IonIcon } from '@ionic/react'
import { filterOutline } from 'ionicons/icons'

function FilterButton({
  onClick,
  disabled,
  id,
}: Pick<
  React.ComponentPropsWithoutRef<'button'>,
  'onClick' | 'disabled' | 'id'
>) {
  return (
    <button
      id={id}
      className="inline-flex items-center gap-x-1.5 text-sm"
      onClick={onClick}
      disabled={disabled}
    >
      <IonIcon className="size-[18px]" icon={filterOutline} />
      Filter
    </button>
  )
}

export { FilterButton }

import { IonHeader, IonModal, IonTitle, IonToolbar } from '@ionic/react'

import { AddUserForm } from './add-user-form'

function AddUserModal({
  onClose,
  open,
}: {
  onClose: () => void
  open: boolean
}) {
  return (
    <IonModal
      isOpen={open}
      onDidDismiss={onClose}
      style={{ '--height': 'fit-content' }}
    >
      <IonHeader>
        <IonToolbar color="secondary">
          <IonTitle>Add User</IonTitle>
        </IonToolbar>
      </IonHeader>
      <div className="ion-padding bg-ion-color-secondary">
        <AddUserForm onSuccess={onClose} onCancel={onClose} />
      </div>
    </IonModal>
  )
}

export { AddUserModal }

import { useMutation, useQueryClient } from '@tanstack/react-query'
import produce from 'immer'
import { useParams } from 'react-router-dom'

import { useToast } from '../../contexts/toastContext'
import {
  useAdminClientFilters,
  useClientFilters,
} from '../../clients/client-filters-store'
import { clientQueryKeys } from '../clients/queries'
import { taskQueryKeys } from '../tasks/queries'
import { tagQueryKeys } from './queries'
import { client } from '../../services/client'

import type { ClientDetail } from '../clients/queries'
import type { TaskClass } from '../tasks/queries'

type ClientTags = Pick<ClientDetail, 'objectId' | 'tags'>

type UpdateTagInput = {
  clientId: string
  tag: {
    objectId: string
    name: string
  }
}

function useCreateClientTag() {
  const queryClient = useQueryClient()
  const setToast = useToast()

  return useMutation(
    async (newTag: string) => {
      const response = await client
        .post('functions/createClientTag', {
          json: { name: newTag },
        })
        .json<{ result: UpdateTagInput['tag'] }>()

      return response.result
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(tagQueryKeys.all)
      },
      onError: (error, variables) => {
        const errorMessage =
          error instanceof Error ? error.message : 'Unknown Error.'

        setToast({
          message: `Oops, something went wrong while creating the tag ${variables}: ${errorMessage}`,
          color: 'danger',
        })
      },
    }
  )
}

function useUpdateClientTags() {
  const queryClient = useQueryClient()

  const setToast = useToast()

  const currentClientFilters = useClientFilters()
  const currentAdminFilters = useAdminClientFilters()

  const params = useParams<{ objectId: string; className: TaskClass }>()
  const taskDetailKey = taskQueryKeys.detail({
    id: params.objectId,
    type: params.className,
  })

  return useMutation(
    ({ clientId, tag }) =>
      client.post('functions/updateClient', {
        json: {
          objectId: clientId,
          addTagIds: [tag.objectId],
        },
      }),
    {
      onMutate: async (newTag: UpdateTagInput) => {
        // cancel any ongoing client-related queries
        await queryClient.cancelQueries(tagQueryKeys.all)
        await queryClient.cancelQueries(clientQueryKeys.lists())
        await queryClient.cancelQueries(clientQueryKeys.detail(newTag.clientId))
        await queryClient.cancelQueries(taskDetailKey)

        // Tags on Client Detail
        const previousClientDetail = queryClient.getQueryData<ClientTags>(
          clientQueryKeys.detail(newTag.clientId)
        )

        if (previousClientDetail) {
          queryClient.setQueryData<ClientTags>(
            clientQueryKeys.detail(newTag.clientId),
            (oldDetail) => {
              if (oldDetail) {
                return produce(oldDetail, (draft) => {
                  draft?.tags.push({
                    name: newTag.tag.name,
                    objectId: `${newTag.tag.objectId}adding`, // "adding" is a flag to prevent deleting while updating in consuming ClientTags component
                  })
                })
              }

              return previousClientDetail
            }
          )
        }

        // Tags on Clients listing page
        const previousClients = queryClient.getQueryData<Array<ClientTags>>(
          clientQueryKeys.list({ filters: currentClientFilters, type: 'mine' })
        )

        if (previousClients) {
          queryClient.setQueryData<Array<ClientTags>>(
            clientQueryKeys.list({
              filters: currentClientFilters,
              type: 'mine',
            }),
            (oldClients) => {
              if (oldClients) {
                const clientIndex = oldClients.findIndex(
                  (client) => client.objectId === newTag.clientId
                )

                if (clientIndex === -1) return previousClients

                return produce(oldClients, (draft) => {
                  draft?.[clientIndex].tags.push({
                    name: newTag.tag.name,
                    objectId: `${newTag.tag.objectId}adding`,
                  })
                })
              }

              return previousClients
            }
          )
        }

        // tags on Admin Clients listing page
        const previousAdminClients = queryClient.getQueryData<
          Array<ClientTags>
        >(clientQueryKeys.list({ filters: currentAdminFilters, type: 'admin' }))

        if (previousAdminClients) {
          queryClient.setQueryData<Array<ClientTags>>(
            clientQueryKeys.list({
              filters: currentAdminFilters,
              type: 'admin',
            }),
            (oldAdminClients) => {
              if (oldAdminClients) {
                const clientIndex = oldAdminClients.findIndex(
                  (client) => client.objectId === newTag.clientId
                )

                if (clientIndex < 0) return previousAdminClients
                return produce(oldAdminClients, (draft) => {
                  draft[clientIndex].tags.push({
                    name: newTag.tag.name,
                    objectId: `${newTag.tag.objectId}adding`,
                  })
                })
              }

              return previousAdminClients
            }
          )
        }

        // Tags on Tasks Detail
        const previousTaskDetail = queryClient.getQueryData<{
          client: ClientTags
        }>(taskDetailKey)

        if (previousTaskDetail && params.objectId) {
          queryClient.setQueryData<{ client: ClientTags }>(
            taskDetailKey,
            (oldTaskDetail) => {
              if (oldTaskDetail) {
                return produce(oldTaskDetail, (draft) => {
                  draft?.client.tags.push({
                    name: newTag.tag.name,
                    objectId: `${newTag.tag.objectId}adding`, // "adding" is a flag to prevent deleting while updating in consuming ClientTags component
                  })
                })
              }

              return previousTaskDetail
            }
          )
        }

        return {
          previousClientDetail,
          previousClients,
          previousAdminClients,
          previousTaskDetail,
        }
      },
      onSettled: (_data, _error, newTag) => {
        queryClient.invalidateQueries(tagQueryKeys.all)
        queryClient.invalidateQueries(
          clientQueryKeys.list({ filters: currentClientFilters, type: 'mine' })
        )
        queryClient.invalidateQueries(clientQueryKeys.detail(newTag.clientId))
        queryClient.invalidateQueries(
          clientQueryKeys.list({ filters: currentAdminFilters, type: 'admin' })
        )
        queryClient.invalidateQueries(taskDetailKey)
      },
      onError: (error, newTag, context) => {
        const errorMessage =
          error instanceof Error ? error.message : 'Unknown Error.'

        if (context?.previousClientDetail) {
          queryClient.setQueryData(
            clientQueryKeys.detail(newTag.clientId),
            context.previousClientDetail
          )
        }
        if (context?.previousClients) {
          queryClient.setQueryData(
            clientQueryKeys.list({
              filters: currentClientFilters,
              type: 'mine',
            }),
            context.previousClients
          )
        }
        if (context?.previousAdminClients) {
          queryClient.setQueryData(
            clientQueryKeys.list({
              filters: currentAdminFilters,
              type: 'admin',
            }),
            context.previousAdminClients
          )
        }
        if (context?.previousTaskDetail) {
          queryClient.setQueryData(taskDetailKey, context.previousTaskDetail)
        }

        setToast({
          message: `Oops, something went wrong while updating the tags. ${errorMessage}`,
          color: 'danger',
        })
      },
    }
  )
}

function useDeleteClientTag() {
  const queryClient = useQueryClient()

  const setToast = useToast()

  const currentClientFilters = useClientFilters()
  const currentAdminFilters = useAdminClientFilters()

  const params = useParams<{ objectId: string; className: TaskClass }>()
  const taskDetailKey = taskQueryKeys.detail({
    id: params.objectId,
    type: params.className,
  })

  return useMutation(
    ({ clientId, tagId }) =>
      client.post('functions/updateClient', {
        json: { objectId: clientId, removeTagIds: [tagId] },
      }),
    {
      onMutate: async (variables: { clientId: string; tagId: string }) => {
        await queryClient.cancelQueries(tagQueryKeys.all)
        await queryClient.cancelQueries(clientQueryKeys.lists())
        await queryClient.cancelQueries(
          clientQueryKeys.detail(variables.clientId)
        )
        await queryClient.cancelQueries(taskDetailKey)

        // Tags on Client Detail
        const previousClientDetail = queryClient.getQueryData<ClientTags>(
          clientQueryKeys.detail(variables.clientId)
        )

        if (previousClientDetail) {
          queryClient.setQueryData<ClientTags>(
            clientQueryKeys.detail(variables.clientId),
            (oldDetail) => {
              if (oldDetail) {
                return produce(oldDetail, (draft) => {
                  draft.tags = draft.tags.filter(
                    (tag) => tag.objectId !== variables.tagId
                  )
                })
              }

              return previousClientDetail
            }
          )
        }

        // Tags on Clients listing page
        const previousClients = queryClient.getQueryData<Array<ClientTags>>(
          clientQueryKeys.list({ filters: currentClientFilters, type: 'mine' })
        )

        if (previousClients) {
          queryClient.setQueryData<Array<ClientTags>>(
            clientQueryKeys.list({
              filters: currentClientFilters,
              type: 'mine',
            }),
            (oldClients) => {
              if (oldClients) {
                const clientIndex = oldClients.findIndex(
                  (client) => client.objectId === variables.clientId
                )

                if (clientIndex !== -1) {
                  return produce(oldClients, (draft) => {
                    draft[clientIndex].tags = draft[clientIndex].tags.filter(
                      (tag) => tag.objectId !== variables.tagId
                    )
                  })
                }
              }
              return previousClients
            }
          )
        }

        // tags on Admin Clients listing page
        const previousAdminClients = queryClient.getQueryData<
          Array<ClientTags>
        >(clientQueryKeys.list({ filters: currentAdminFilters, type: 'admin' }))

        if (previousAdminClients) {
          queryClient.setQueryData<Array<ClientTags>>(
            clientQueryKeys.list({
              filters: currentAdminFilters,
              type: 'admin',
            }),
            (oldAdminClients) => {
              if (oldAdminClients) {
                const clientIndex = oldAdminClients.findIndex(
                  (client) => client.objectId === variables.clientId
                )

                if (clientIndex >= 0) {
                  return produce(oldAdminClients, (draft) => {
                    draft[clientIndex].tags = draft[clientIndex].tags.filter(
                      (tag) => tag.objectId !== variables.tagId
                    )
                  })
                }
              }

              return previousAdminClients
            }
          )
        }

        // Tags on Tasks Detail
        const previousTaskDetail = queryClient.getQueryData<{
          client: ClientTags
        }>(taskDetailKey)

        if (previousTaskDetail && params.objectId) {
          queryClient.setQueryData<{ client: ClientTags }>(
            taskDetailKey,
            (oldDetail) => {
              if (oldDetail) {
                return produce(oldDetail, (draft) => {
                  draft.client.tags = draft.client.tags.filter(
                    (tag) => tag.objectId !== variables.tagId
                  )
                })
              }

              return previousTaskDetail
            }
          )
        }

        return {
          previousClientDetail,
          previousClients,
          previousAdminClients,
          previousTaskDetail,
        }
      },
      onSettled: (_data, _error, variables) => {
        queryClient.invalidateQueries(tagQueryKeys.all)
        queryClient.invalidateQueries(
          clientQueryKeys.list({ filters: currentClientFilters, type: 'mine' })
        )
        queryClient.invalidateQueries(
          clientQueryKeys.detail(variables.clientId)
        )
        queryClient.invalidateQueries(
          clientQueryKeys.list({ filters: currentAdminFilters, type: 'admin' })
        )
        queryClient.invalidateQueries(taskDetailKey)
      },
      onError: (error, variables, context) => {
        const errorMessage =
          error instanceof Error ? error.message : 'Unknown error.'

        if (context?.previousClientDetail) {
          queryClient.setQueryData(
            clientQueryKeys.detail(variables.clientId),
            context.previousClientDetail
          )
        }
        if (context?.previousClients) {
          queryClient.setQueryData(
            clientQueryKeys.list({
              filters: currentClientFilters,
              type: 'mine',
            }),
            context.previousClients
          )
        }
        if (context?.previousAdminClients) {
          queryClient.setQueryData(
            clientQueryKeys.list({
              filters: currentAdminFilters,
              type: 'admin',
            }),
            context.previousAdminClients
          )
        }
        if (context?.previousTaskDetail) {
          queryClient.setQueryData(taskDetailKey, context.previousTaskDetail)
        }

        setToast({
          message: `Oops, something went wrong while deleting the tags: ${errorMessage}`,
          color: 'danger',
        })
      },
    }
  )
}

export { useCreateClientTag, useUpdateClientTags, useDeleteClientTag }

import * as React from 'react'
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  IonLoading,
  IonPage,
  IonRow,
  useIonRouter,
} from '@ionic/react'
import { format } from 'date-fns'

import { useInfiniteData } from '../../../hooks/use-infinite-data'
import ErrorFallback from '../../../components/error-fallback'
import AdminHeader from '../../../components/header/admin-header'
import { BroadcastItem } from '../../../marketing/broadcasts/components/broadcast-item'
import { useCreateAdminBroadcast } from '../../../marketing/broadcasts/mutations'
import { useBroadcasts } from '../../../marketing/broadcasts/queries'

function AdminBroadcastList() {
  const router = useIonRouter()

  const broadcastsQuery = useBroadcasts()
  const createAdminBroadcast = useCreateAdminBroadcast()

  const [ionInfiniteScrollRef, loadMoreBroadcasts] = useInfiniteData(
    broadcastsQuery.fetchNextPage
  )

  function handleBroadcastCreate() {
    createAdminBroadcast.mutate(
      {
        title: `Broadcast ${format(new Date(), 'MM/dd/yy')}`,
      },
      {
        onSuccess: (data) => {
          router.push(
            `/tabs/admin/broadcasts/detail/${data.objectId}`,
            'forward',
            'push'
          )
        },
      }
    )
  }

  if (broadcastsQuery.data) {
    return (
      <IonPage>
        <IonHeader>
          <AdminHeader title="Store Broadcasts" showMenu />
        </IonHeader>
        <IonContent>
          <div className="ion-margin-top">
            <IonGrid
              style={{ '--ion-grid-padding': 0 }}
              className="ion-padding-horizontal"
            >
              <IonRow className="items-center whitespace-nowrap">
                <IonCol className="flex-full">
                  <div className="flex items-center gap-x-2">
                    <IonButton
                      size="small"
                      color="yellow"
                      fill="solid"
                      onClick={handleBroadcastCreate}
                    >
                      Create Store Broadcast
                    </IonButton>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
          {broadcastsQuery.data.pages[0].data.length > 0 ? (
            <>
              <IonList>
                {broadcastsQuery.data.pages.map((page, i) => (
                  <React.Fragment key={i}>
                    {page.data.map((broadcast) => (
                      <BroadcastItem
                        key={broadcast.objectId}
                        broadcast={broadcast}
                        routerLink={`/tabs/admin/broadcasts/detail/${broadcast.objectId}`}
                      />
                    ))}
                  </React.Fragment>
                ))}
              </IonList>
              <IonInfiniteScroll
                ref={ionInfiniteScrollRef}
                onIonInfinite={loadMoreBroadcasts}
                threshold="100px"
                disabled={!broadcastsQuery.hasNextPage}
              >
                <IonInfiniteScrollContent
                  loadingSpinner="bubbles"
                  loadingText="loading more broadcasts..."
                />
              </IonInfiniteScroll>
            </>
          ) : (
            <div className="ion-padding-horizontal flex justify-center">
              <p>
                You have not sent any broadcasts. You can send a store-wide
                broadcast to all clients or a filtered broadcast to a select
                group of clients.
              </p>
            </div>
          )}
        </IonContent>
      </IonPage>
    )
  }

  if (broadcastsQuery.isError) {
    return (
      <IonPage>
        <IonContent>
          <IonHeader>
            <AdminHeader title="Store Broadcasts" showMenu />
          </IonHeader>
          <ErrorFallback error={broadcastsQuery.error}>
            There was a problem loading the list of broadcasts.
          </ErrorFallback>
        </IonContent>
      </IonPage>
    )
  }

  return <IonLoading isOpen message="Loading broadcasts..." />
}

export default AdminBroadcastList

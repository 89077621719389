import { z } from 'zod'
import { fromZodError } from 'zod-validation-error'

import { client } from '../../services/client'
import { makePaginatedServerResponseCountSchema } from '../../utils/schema'
import { userSetupSchema } from './user-setup.schema'

import type { QueryContextFromKeys } from '../../utils/react-query'

const userSetupQueryKeys = {
  all: [{ entity: 'user setup' }] as const,
  setup: (filters: { page: number; pageSize: number }) =>
    [{ ...userSetupQueryKeys.all[0], ...filters }] as const,
}

async function fetchUsers({
  queryKey: [{ page, pageSize }],
}: QueryContextFromKeys<typeof userSetupQueryKeys>['setup']) {
  const response = await client
    .post('functions/getAccountUserGroupMembers', {
      json: {
        page,
        pageSize,
      },
    })
    .json()

  const parsedResponse = makePaginatedServerResponseCountSchema(
    z.array(userSetupSchema)
  ).safeParse(response)

  if (!parsedResponse.success)
    throw new Error(fromZodError(parsedResponse.error).message)

  return parsedResponse.data.result
}

export { fetchUsers, userSetupQueryKeys }

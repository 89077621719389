exports.handleResponse = (response) => {
  return response.text().then((text) => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      const error = (data && data.message) || response.statusText || 'error'
      console.log('error response: ', error)
      console.log(data)
      return Promise.reject(error)
    }

    return data
  })
}

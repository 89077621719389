const messageTypes = ['email', 'sms', 'prioSmsEmail'] as const

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
] as const

const oneshopStatuses = [
  'success',
  'pending',
  'failed',
  'canceled',
  'deleted',
] as const

const units = ['minutes', 'hours', 'days'] as const

export { messageTypes, MONTHS, oneshopStatuses, units }

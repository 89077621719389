import { z } from 'zod'

import {
  isoDateSchema,
  nullableArray,
  nullableBoolean,
  nullableNumber,
  nullableString,
  serverItemSchema,
} from '../utils/schema'
import { profileBaseSchema } from '../schema/profile.schema'
import { ayrshareOverviewSchema } from '../ayrshare/ayrshare.schema'
import { settings } from './constants'

type Account = z.infer<typeof accountSchema>

const accountSchema = z.object({
  account: z.object({
    settings: z.record(z.enum(settings), z.boolean()),
  }),
  createdAt: isoDateSchema,
  groups: z
    .array(
      z
        .object({
          companyName: z.string(),
          name: z.string(),
        })
        .partial()
    )
    .optional(),
  isAdmin: z.boolean(),
  isAssociate: z.boolean(),
  roleSocial: z.object({
    role: z.enum(['admin', 'creator']),
  }),
  subscriptionStatus: z.literal('paid').nullable(),
})

const userSchema = profileBaseSchema.merge(
  z.object({
    account: serverItemSchema,
    bio: nullableString,
    deactivated: nullableBoolean,
    email: nullableString,
    goal: nullableNumber,
    smsManual: z.boolean(),
    workDays: nullableNumber,

    optInTermsAcceptedAt: isoDateSchema.nullable(),
    personalDeviceTermsAcceptedAt: isoDateSchema.nullable(),
    version: nullableString,

    profileId: nullableString,
    username: nullableString,
    emailVerified: nullableBoolean,

    twilioProxyAddress: nullableString,
    twilioProxyEmail: nullableString,
  })
)

const socialUserSchema = userSchema.merge(
  z.object({
    ayrshares: nullableArray(ayrshareOverviewSchema),
    roleSocial: z.nullable(
      z.object({
        objectId: nullableString,
        role: nullableString,
        requiresApproval: nullableBoolean,
      })
    ),
  })
)

export type { Account }
export { userSchema }

import { useInfiniteQuery, useQuery } from '@tanstack/react-query'

import { client } from '../../../services/client'

import type { QueryContextFromKeys } from '../../../utils/react-query'
import type { ProductBase } from '../../../types/shopwith'
import type { PaginatedResponse, ResponseData } from '../../../types/queries'
import type { ProfileBase } from '../../../types/general'

type FetchProfileProductsResponse = PaginatedResponse<ProfileProduct>
type FetchProfileProductResponse = ResponseData<ProfileProduct>

export type ProfileProduct = {
  objectId: string
  createdAt: string
  product: ProductBase
  user: ProfileBase
}

export type ProfileProductQueryContexts = QueryContextFromKeys<
  typeof profileProductQueryKeys
>

export const profileProductQueryKeys = {
  all: [{ entity: 'profileProducts' }] as const,
  lists: () => [{ ...profileProductQueryKeys.all[0], scope: 'list' }] as const,
  list: (profileId: string) =>
    [{ ...profileProductQueryKeys.lists()[0], profileId }] as const,
  details: () =>
    [{ ...profileProductQueryKeys.all[0], scope: 'detail' }] as const,
  detail: (id: string) =>
    [{ ...profileProductQueryKeys.details()[0], id }] as const,
}

async function fetchProfileProducts({
  pageParam = 0,
  queryKey: [{ profileId }],
}: ProfileProductQueryContexts['list']) {
  const response: FetchProfileProductsResponse = await client
    .post('functions/getProfileProducts', {
      json: {
        profileId,
        page: pageParam,
        pageSize: 20,
      },
    })
    .json()

  return response.result
}

async function fetchProfileProduct({
  queryKey: [{ id }],
}: ProfileProductQueryContexts['detail']) {
  const response: FetchProfileProductResponse = await client
    .post(`functions/getProfileProduct/`, {
      json: {
        objectId: id,
      },
    })
    .json()

  return response.result
}

function useProfileProducts(profileId: string) {
  return useInfiniteQuery({
    queryKey: profileProductQueryKeys.list(profileId),
    queryFn: fetchProfileProducts,
  })
}

function useProfileProduct(id: string) {
  return useQuery({
    queryKey: profileProductQueryKeys.detail(id),
    queryFn: fetchProfileProduct,
  })
}

export { useProfileProducts, useProfileProduct }

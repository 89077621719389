import { IonButton } from '@ionic/react'

import { useClipboard } from '../hooks/use-clipboard'
import ErrorCard from './ui/error-card'

import type { ReactNode } from 'react'

function ErrorFallback({
  children,
  error,
}: {
  children?: ReactNode
  error: unknown
}) {
  const { copyToClipboard } = useClipboard()

  const errorMessage = error instanceof Error ? error.message : 'Unknown Error.'

  function handleCopyError() {
    copyToClipboard(
      `${window.location.pathname} : ${errorMessage}`,
      'Error copied'
    )
  }

  return (
    <div role="alert">
      <div className="flex items-center justify-between gap-2 p-4">
        <p>Something went wrong:</p>
        <IonButton
          color="secondary"
          fill="outline"
          size="small"
          onClick={handleCopyError}
        >
          Copy Error Message
        </IonButton>
      </div>
      <ErrorCard>
        <pre className="whitespace-pre-wrap">
          {children ??
            `There was a problem loading the posts that need to be approved.`}{' '}
          Please use the button above to copy the associated error to send to
          support.
        </pre>
      </ErrorCard>
    </div>
  )
}

export default ErrorFallback

import { useMutation, useQueryClient } from '@tanstack/react-query'
import produce from 'immer'
import { useParams } from 'react-router-dom'

import { composeDobObject } from '../../utils/client-helpers'
import { client } from '../../services/client'
import { clientQueryKeys } from '../clients/queries'
import { outreachQueryKeys } from '../outreach/queries'
import { taskQueryKeys } from '../tasks/queries'
import { useSequence } from '../../stores/useSequence'
import { useToast } from '../../contexts/toastContext'
import { useAdminClientFilters } from '../../clients/client-filters-store'
import { useSendMessage } from '../chat/mutations'
import { usePrimaryClientConversation } from '../chat/queries'

import type { AdminClient } from '../clients/queries'
import type { E164Number } from 'libphonenumber-js'
import type { ClientDetail, PhoneNumber } from '../clients/queries'
import type { IsoDate } from '../../types/general'
import type { TaskClass } from '../tasks/queries'
import type { AddClientSchema } from '../../schema/add-client.schema'
import type { UpdateClientInput } from '../../marketing/customer-opt-in/customer-opt-in.schema'

type AddPhoneNumberResponse = {
  result: {
    phoneNumbers: Array<PhoneNumber>
  }
}

type UpdateDefaultPhoneNumberResponse = {
  result: {
    phoneNumbers: Array<PhoneNumber>
  }
}

type AddPhoneNumberInput = {
  clientId: string
  e164: E164Number
}

type UpdateDefaultPhoneNumberInput = {
  clientId: string
  clientPhoneId: string
}

type UpdateClientSubscriptionInput = {
  clientId: string
  unsubscribe: boolean
}

type UpdateAdminClientsInput = {
  clientIds: Array<string>
  users: Array<string>
}
type UpdateAdminClientInput = {
  objectId: string
  users: Array<string>
}

type UpdateProductProps = {
  productIds: Array<string>
  clientId: string
}

type MergeClientsInput = { clientId: string; fromClientId: string }

async function createClient({ birthday, ...params }: Partial<AddClientSchema>) {
  return client
    .post('functions/createClient', {
      json: {
        ...params,
        ...(birthday && { dobObject: composeDobObject(birthday) }),
      },
    })
    .json<{ result: { client: { objectId: string } } }>()
}

async function updateClient({
  birthday,
  ...params
}: UpdateClientInput & { objectId: string }) {
  return client
    .post('functions/updateClient', {
      json: {
        ...params,
        ...(birthday && { dobObject: composeDobObject(birthday) }),
      },
    })
    .json()
}

async function updateClientWishlist({
  clientId,
  productIds,
}: UpdateProductProps) {
  return client
    .post('functions/likeProducts', {
      json: {
        clientId,
        productIds,
        like: true,
      },
    })
    .json()
}

function useUpdateClient() {
  const setToast = useToast()

  return useMutation(updateClient, {
    onError: (error) => {
      const message = error instanceof Error ? error.message : 'Unknown error.'
      setToast({ message, color: 'danger', duration: 0 })
    },
  })
}

function useUpdateClientWishlist() {
  const queryClient = useQueryClient()
  const setToast = useToast()

  return useMutation(updateClientWishlist, {
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(clientQueryKeys.detail(variables.clientId))
    },
    onError: (error) => {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown error.'

      setToast({
        message: `There was an error updating the wishlist. ${errorMessage}`,
        color: 'danger',
      })
    },
    onSuccess() {
      setToast({
        message: 'The wishlist for that client was updated.',
        color: 'yellow',
      })
    },
  })
}

function useCreateClient() {
  const queryClient = useQueryClient()

  const setToast = useToast()

  const { getConversationId } = usePrimaryClientConversation()
  const sendMessage = useSendMessage()

  return useMutation(createClient, {
    onSuccess: async (data, variables) => {
      if (variables.manualOptIn && variables.message) {
        const conversationId = await getConversationId(
          data.result.client.objectId
        )
        if (!conversationId) {
          return setToast({
            message:
              'Something went wrong setting up the conversation for this client.',
            color: 'danger',
          })
        }
        sendMessage.mutate({
          conversationId,
          message: { data: variables.message },
        })
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(clientQueryKeys.lists())
    },
    onError: (error) =>
      setToast({
        message: `Something went wrong: ${
          error instanceof Error ? error.message : 'Unknown Error.'
        }`,
      }),
  })
}

function useAddPhoneNumber() {
  const queryClient = useQueryClient()
  const setToast = useToast()
  const updateSequenceClient: (
    id: string,
    prop: string,
    phoneNumbers: Array<PhoneNumber>
  ) => void = useSequence((s) => s.updateSequenceClient)

  return useMutation(
    ({ clientId, e164 }) =>
      client
        .post('functions/updateClient', {
          json: { objectId: clientId, addE164: e164 },
        })
        .json<AddPhoneNumberResponse>(),
    {
      onMutate: async ({ clientId, e164 }: AddPhoneNumberInput) => {
        await queryClient.cancelQueries(clientQueryKeys.detail(clientId))

        // Snapshot the previous value
        const previousClientDetail = queryClient.getQueryData<ClientDetail>(
          clientQueryKeys.detail(clientId)
        )

        // Optimistically update to the new value
        if (previousClientDetail) {
          queryClient.setQueryData<ClientDetail>(
            clientQueryKeys.detail(clientId),
            (old) => {
              if (old) {
                return produce(old, (draft) => {
                  const currentDefault = old.phoneNumbers.findIndex(
                    (phoneNumber) => phoneNumber.default
                  )

                  if (currentDefault !== -1) {
                    draft.phoneNumbers[currentDefault].default = false
                  }
                  draft.phoneNumbers.push({
                    objectId: Date.now().toString(),
                    default: true,
                    e164,
                    optInState: 'NOT_INITIALIZED',
                    phoneNumber: e164.toString(),
                    posName: null,
                    type: null,
                    verified: false,
                  })
                })
              }
              return previousClientDetail
            }
          )
        }

        // Return a context object with the snapshotted value
        return { previousClientDetail }
      },
      onSuccess: (data, variables) => {
        updateSequenceClient(
          variables.clientId,
          'phoneNumbers',
          data.result.phoneNumbers
        )
      },
      onSettled: (_data, _error, variables) => {
        queryClient.invalidateQueries(clientQueryKeys.lists())
        queryClient.invalidateQueries(
          clientQueryKeys.detail(variables.clientId)
        )
        queryClient.invalidateQueries(outreachQueryKeys.details())
      },

      onError: (_error, variables, context) => {
        if (context?.previousClientDetail) {
          queryClient.setQueryData(
            clientQueryKeys.detail(variables.clientId),
            context.previousClientDetail
          )
        }

        setToast({
          message:
            'Oops, something went wrong while adding the new phone number.',
          color: 'danger',
        })
      },
    }
  )
}

function useUpdateDefaultPhone() {
  const queryClient = useQueryClient()
  const setToast = useToast()
  const updateSequenceClient: (
    id: string,
    prop: string,
    phoneNumbers: Array<PhoneNumber>
  ) => void = useSequence((s) => s.updateSequenceClient)

  return useMutation(
    ({ clientId, clientPhoneId }) =>
      client
        .post('functions/updateClientPhoneDefault', {
          json: { clientId, clientPhoneId },
        })
        .json<UpdateDefaultPhoneNumberResponse>(),

    {
      onMutate: async ({
        clientId,
        clientPhoneId,
      }: UpdateDefaultPhoneNumberInput) => {
        await queryClient.cancelQueries(clientQueryKeys.detail(clientId))

        // Snapshot the previous value
        const previousClientDetail = queryClient.getQueryData<ClientDetail>(
          clientQueryKeys.detail(clientId)
        )

        // Optimistically update to the new value
        if (previousClientDetail) {
          queryClient.setQueryData<ClientDetail>(
            clientQueryKeys.detail(clientId),
            (old) => {
              if (old) {
                return produce(old, (draft) => {
                  const currentDefault = old.phoneNumbers.findIndex(
                    (phoneNumber) => phoneNumber.default
                  )
                  const newDefault = old.phoneNumbers.findIndex(
                    (phoneNumber) => phoneNumber.objectId === clientPhoneId
                  )

                  if (currentDefault !== -1) {
                    draft.phoneNumbers[currentDefault].default = false
                  }
                  if (newDefault !== -1)
                    draft.phoneNumbers[newDefault].default = true
                })
              }
              return previousClientDetail
            }
          )
        }

        // Return a context object with the snapshotted value
        return { previousClientDetail }
      },
      onSuccess: (data, variables) => {
        updateSequenceClient(
          variables.clientId,
          'phoneNumbers',
          data.result.phoneNumbers
        )
      },
      onSettled: (_data, _error, variables) => {
        queryClient.invalidateQueries(clientQueryKeys.lists())
        queryClient.invalidateQueries(clientQueryKeys.subscribers())
        queryClient.invalidateQueries(
          clientQueryKeys.detail(variables.clientId)
        )
        queryClient.invalidateQueries(outreachQueryKeys.all)
      },
      onError: (_error, variables, context) => {
        if (context?.previousClientDetail) {
          queryClient.setQueryData(
            clientQueryKeys.detail(variables.clientId),
            context.previousClientDetail
          )
        }

        setToast({
          message:
            'Oops, something went wrong while changing the default phone number.',
          color: 'danger',
        })
      },
    }
  )
}

function useUpdateClientSubscriptionStatus() {
  const queryClient = useQueryClient()
  const updateSequenceClientSubscription: (
    id: string,
    unsub: IsoDate | undefined
  ) => void = useSequence((s) => s.updateSequenceClientSubscription)
  const setToast = useToast()

  const params = useParams<{ objectId: string; className: TaskClass }>()
  const taskDetailKey = taskQueryKeys.detail({
    id: params.objectId,
    type: params.className,
  })

  return useMutation(
    ({ clientId, unsubscribe }) =>
      client
        .post('functions/updateClient', {
          json: { objectId: clientId, unsubscribe },
        })
        .json(),
    {
      onMutate: async ({
        clientId,
        unsubscribe,
      }: UpdateClientSubscriptionInput) => {
        await queryClient.cancelQueries(clientQueryKeys.detail(clientId))
        await queryClient.cancelQueries(taskDetailKey)

        // Snapshot the previous value
        const previousClientDetail = queryClient.getQueryData<ClientDetail>(
          clientQueryKeys.detail(clientId)
        )
        const previousTaskDetail = queryClient.getQueryData<{
          client: ClientDetail
        }>(taskDetailKey)

        // Optimistically update to the new value
        if (previousClientDetail) {
          queryClient.setQueryData<ClientDetail>(
            clientQueryKeys.detail(clientId),
            (old) => {
              if (old) {
                return produce(old, (draft) => {
                  draft.unsubscribedAt = unsubscribe
                    ? { iso: new Date().toISOString() }
                    : null
                })
              }
              return previousClientDetail
            }
          )
        }

        if (previousTaskDetail && params.objectId) {
          queryClient.setQueryData<{ client: ClientDetail }>(
            taskDetailKey,
            (oldTaskDetail) => {
              if (oldTaskDetail) {
                return produce(oldTaskDetail, (draft) => {
                  draft.client.unsubscribedAt = unsubscribe
                    ? { iso: new Date().toISOString() }
                    : null
                })
              }

              return previousTaskDetail
            }
          )
        }

        // Return a context object with the snapshotted value
        return { previousClientDetail, previousTaskDetail }
      },
      onSettled: (_data, _error, variables) => {
        queryClient.invalidateQueries(
          clientQueryKeys.detail(variables.clientId)
        )
        queryClient.invalidateQueries(clientQueryKeys.subscribers())
        queryClient.invalidateQueries(taskDetailKey)
        const unsub = variables.unsubscribe
          ? { iso: new Date().toISOString() }
          : undefined
        updateSequenceClientSubscription(variables.clientId, unsub)
      },
      onError: (_err, variables, context) => {
        if (context?.previousClientDetail) {
          queryClient.setQueryData(
            clientQueryKeys.detail(variables.clientId),
            context.previousClientDetail
          )
        }
        if (context?.previousTaskDetail) {
          queryClient.setQueryData(taskDetailKey, context.previousTaskDetail)
        }

        setToast({
          message: 'Oops, something went wrong while updating the client',
          color: 'danger',
        })
      },
    }
  )
}

function useUpdateAdminClients(options = {}) {
  const queryClient = useQueryClient()

  const setToast = useToast()

  const currentFilters = useAdminClientFilters()

  /*
manualAssigned: true

    */
  return useMutation(
    async ({ clientIds, users }) => {
      const userPointers = users.map((user) => ({
        __type: 'Pointer',
        className: '_User',
        objectId: user,
      }))
      client.post('functions/updateClients', {
        json: {
          clientIds,
          users: userPointers,
          manualAssigned: true,
        },
      })
    },
    {
      //manualAssigned: true,
      onMutate: async (updateClientsData: UpdateAdminClientsInput) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(
          clientQueryKeys.list({ filters: currentFilters, type: 'admin' })
        )

        // Snapshot the previous value
        const previousClientList = queryClient.getQueryData<Array<AdminClient>>(
          clientQueryKeys.list({ filters: currentFilters, type: 'admin' })
        )

        // Optimistically update to the new value
        if (previousClientList) {
          queryClient.setQueryData<Array<AdminClient>>(
            clientQueryKeys.list({ filters: currentFilters, type: 'admin' }),
            (oldData) => {
              if (oldData) {
                // find the clients to update
                const clientsToUpdate = oldData.filter((client) =>
                  updateClientsData.clientIds.includes(client.objectId)
                )

                const userPointers = updateClientsData.users.map((user) => ({
                  __type: 'Pointer',
                  className: '_User',
                  objectId: user,
                }))

                clientsToUpdate.forEach(
                  (client) => (client.users = userPointers)
                )

                return oldData.map((client) => {
                  if (updateClientsData.clientIds.includes(client.objectId)) {
                    return {
                      ...client,
                      users: userPointers,
                    }
                  }
                  return client
                })
              }
              return previousClientList
            }
          )
        }
        // Return a context object with the snapshotted value
        return { previousClientList }
      },

      // If the mutation fails, use the value returned from onMutate to roll back
      onError: (error, _newValue, context) => {
        if (context?.previousClientList) {
          queryClient.setQueryData(
            clientQueryKeys.list({ filters: currentFilters, type: 'admin' }),
            context.previousClientList
          )
        }

        const errorMessage =
          error instanceof Error ? error.message : 'Unknown Error.'

        setToast({
          message: `Oops, something went wrong while assigning associates to the selected clients: ${errorMessage}`,
          color: 'danger',
        })
      },

      onSuccess: () => {
        setToast({
          message: `The associates for the selected clients have been updated.`,
          color: 'yellow',
        })
      },

      onSettled: () => {
        queryClient.invalidateQueries(
          clientQueryKeys.list({ filters: currentFilters, type: 'admin' })
        )
      },
      ...options,
    }
  )
}

function useUpdateAdminClient(options = {}) {
  const queryClient = useQueryClient()

  const setToast = useToast()

  const currentFilters = useAdminClientFilters()

  return useMutation(
    async ({ objectId, users }) => {
      const userPointers = users.map((user) => ({
        __type: 'Pointer',
        className: '_User',
        objectId: user,
      }))

      client.post('functions/updateClient', {
        json: {
          objectId,
          users: userPointers,
          manualAssigned: true,
        },
      })
    },
    {
      onMutate: async (updateClientData: UpdateAdminClientInput) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(
          clientQueryKeys.list({ filters: currentFilters, type: 'admin' })
        )

        // Snapshot the previous value
        const previousClientList = queryClient.getQueryData<Array<AdminClient>>(
          clientQueryKeys.list({ filters: currentFilters, type: 'admin' })
        )

        // Optimistically update to the new value
        if (previousClientList) {
          queryClient.setQueryData<Array<AdminClient>>(
            clientQueryKeys.list({ filters: currentFilters, type: 'admin' }),
            (oldData) => {
              if (oldData) {
                return produce(oldData, (draft) => {
                  // find the client to update
                  const clientToUpdate = oldData.findIndex(
                    (client) => client.objectId === updateClientData.objectId
                  )

                  if (clientToUpdate < 0) return

                  const userPointers = updateClientData.users.map((user) => ({
                    __type: 'Pointer',
                    className: '_User',
                    objectId: user,
                  }))

                  draft[clientToUpdate].users = userPointers
                })
              }
              return previousClientList
            }
          )
        }
        // Return a context object with the snapshotted value
        return { previousClientList }
      },

      // If the mutation fails, use the value returned from onMutate to roll back
      onError: (_err, _newValue, context) => {
        setToast({
          message: 'Oops, something went wrong while updating the Client.',
          color: 'danger',
        })
        if (context?.previousClientList) {
          queryClient.setQueryData(
            clientQueryKeys.list({ filters: currentFilters, type: 'admin' }),
            context.previousClientList
          )
        }
      },

      onSuccess: (data) => {
        setToast({
          message: `The associates for this client have been updated.`,
          color: 'yellow',
        })
      },

      onSettled: () => {
        queryClient.invalidateQueries(clientQueryKeys.lists())
      },
      ...options,
    }
  )
}

function useMergeClients() {
  const queryClient = useQueryClient()

  return useMutation(
    (variables: MergeClientsInput) =>
      client.post('functions/mergeClients', { json: variables }),
    {
      onSettled: () => {
        queryClient.invalidateQueries(clientQueryKeys.list({ type: 'admin' }))
      },
    }
  )
}

export {
  useCreateClient,
  useAddPhoneNumber,
  useUpdateClient,
  useUpdateDefaultPhone,
  useUpdateAdminClient,
  useUpdateAdminClients,
  useUpdateClientSubscriptionStatus,
  useUpdateClientWishlist,
  useMergeClients,
}

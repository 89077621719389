import { fromZodError } from 'zod-validation-error'

import { parseDobObject } from '../utils/client-helpers'
import {
  makePaginatedServerResponseSchema,
  makeServerResponseSchema,
} from '../utils/schema'
import { client } from '../services/client'
import {
  activeStationsResponseSchema,
  checkedInClientSchema,
  stationResponseSchema,
  stationsResponseSchema,
} from './rotate.schema'

import type { E164Number } from 'libphonenumber-js'
import type { BaseClientDetail } from '../clients/clients.schema'
import type { TerminalType } from './rotate.schema'

function composeCheckedInClient(client: BaseClientDetail | null) {
  return {
    client: client
      ? {
          ...client,
          dobObject: parseDobObject(client ? client.dobObject : null),
        }
      : null,
  }
}

async function emailCheckIn(email: string) {
  const response = await client
    .post('functions/getClientEmail', { json: { email } })
    .json()

  const parsedResponse = makeServerResponseSchema(
    checkedInClientSchema
  ).safeParse(response)

  if (!parsedResponse.success) {
    console.log('emailcheckin error')
    throw new Error(fromZodError(parsedResponse.error).message)
  }

  const result = parsedResponse.data.result
  return composeCheckedInClient(result.client)
}

async function fetchActiveStation({
  deviceToken,
  type,
}: {
  deviceToken?: string
  type: TerminalType
}) {
  const response = await client
    .post('functions/getStationActive', {
      json: { deviceToken, type },
    })
    .json()

  const parsedResponse = makeServerResponseSchema(
    stationResponseSchema
  ).safeParse(response)

  if (!parsedResponse.success)
    throw new Error('Server data', parsedResponse.error)

  return parsedResponse.data.result.station
}

async function fetchActiveStations({ deviceToken }: { deviceToken?: string }) {
  const response = await client
    .post('functions/getStationsActive', {
      json: { deviceToken },
    })
    .json()

  const parsedResponse = makePaginatedServerResponseSchema(
    activeStationsResponseSchema
  ).safeParse(response)

  if (!parsedResponse.success)
    throw new Error('Server data', parsedResponse.error)

  return parsedResponse.data.result
}

async function fetchStations() {
  const response = await client.post('functions/getStations').json()

  const parsedResponse = makePaginatedServerResponseSchema(
    stationsResponseSchema
  ).safeParse(response)
  if (!parsedResponse.success) throw new Error('Server data')

  return parsedResponse.data.result
}

async function getClientFromE164(e164: E164Number) {
  const { result }: { result: { client: BaseClientDetail | null } } =
    await client
      .post('functions/getClientE164', {
        json: {
          e164,
        },
      })
      .json()

  return composeCheckedInClient(result.client)
}

export {
  emailCheckIn,
  fetchActiveStation,
  fetchActiveStations,
  fetchStations,
  getClientFromE164,
}

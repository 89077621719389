import {
  IonButton,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  SelectChangeEventDetail,
} from '@ionic/react'
import { IonSelectCustomEvent } from '@ionic/core'
import { useForm, Controller, useWatch } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { zodResolver } from '@hookform/resolvers/zod'

import { useAdminGroups } from '../../../hooks/queries/use-associate'
import { StyledError } from '../../../components/ui/form/errors'
import { TextInput } from '../../../components/ui/form/input'
import { useCreateGroupMember } from '../mutations'
import { addGroupMemberSchema } from '../user-setup.schema'

import type { AddGroupMember } from '../user-setup.schema'

type Props = {
  data: Pick<AddGroupMember, 'posId' | 'userId'>
  onCancel: () => void
  onSuccess: () => void
}
type Role = 'admin' | 'associate'

const userRoles: Record<string, { value: Role; label: string }> = {
  associate: {
    value: 'associate',
    label: 'Associate',
  },
  admin: { value: 'admin', label: 'Admin' },
}

function AddGroupMemberForm({ data, onCancel, onSuccess }: Props) {
  const stores = useAdminGroups()
  const createGroupMember = useCreateGroupMember()

  const {
    control,
    formState: { errors },
    ...formMethods
  } = useForm<AddGroupMember>({
    defaultValues: {
      posId: data.posId,
      role: 'associate',
      userId: data.userId,
    },
    resolver: zodResolver(addGroupMemberSchema),
  })

  const watchRole = useWatch({ control, name: 'role' })

  function handleSubmit(data: AddGroupMember) {
    createGroupMember.mutate(data, { onSuccess: () => onSuccess() })
  }

  return (
    <form onSubmit={formMethods.handleSubmit(handleSubmit)} className="h-full">
      <div className="flex h-full flex-col justify-between">
        <IonItem
          color="secondary"
          lines="none"
          style={{ '--padding-start': 0 }}
        >
          <IonLabel className="font-semibold">Role</IonLabel>

          <Controller
            name="role"
            control={control}
            render={({ field: { value, onChange } }) => (
              <IonSelect
                value={value}
                onIonChange={(
                  e: IonSelectCustomEvent<SelectChangeEventDetail<Role>>
                ) => {
                  onChange(e.detail.value)
                }}
              >
                {Object.entries(userRoles).map(([k, v]) => (
                  <IonSelectOption key={k} value={k}>
                    {v.label}
                  </IonSelectOption>
                ))}
              </IonSelect>
            )}
          />
        </IonItem>

        <div className="flex items-end gap-x-3">
          <div className="flex-grow">
            <IonItem color="secondary" style={{ '--padding-start': '0px' }}>
              <IonLabel>Store</IonLabel>
              <Controller
                shouldUnregister
                name="groupId"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <IonSelect
                    color="secondary"
                    value={value}
                    onIonChange={(e) => {
                      // WORKAROUND: Look into why using onChange directly is setting then unsetting the value
                      if (!e.detail.value) return
                      onChange(e.detail.value)
                    }}
                  >
                    {stores.data?.map((store) => (
                      <IonSelectOption
                        key={store.objectId}
                        value={store.objectId}
                      >
                        {store.name}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                )}
              />
            </IonItem>
            <ErrorMessage as={StyledError} name="groupId" errors={errors} />
          </div>
          {watchRole !== 'admin' ? (
            <div>
              <TextInput
                control={control}
                name="posId"
                color="light"
                label="POS ID"
              />
            </div>
          ) : null}
        </div>
      </div>

      <div className="flex gap-x-3 py-3">
        <IonButton
          fill="outline"
          onClick={onCancel}
          disabled={createGroupMember.isLoading}
        >
          Cancel
        </IonButton>
        <IonButton
          color="yellow"
          type="submit"
          disabled={createGroupMember.isLoading}
        >
          Add User Role
        </IonButton>
      </div>
    </form>
  )
}

export { AddGroupMemberForm }

import { IonButton, IonModal } from '@ionic/react'

type Props = { isOpen: boolean; returnUrl: string | null }

function MissingItemsModal({ isOpen, returnUrl }: Props) {
  return (
    <IonModal
      style={{
        '--width': '350px',
        '--height': 'fit-content',
        '--border-radius': '6px',
      }}
      animated={false}
      backdropDismiss={false}
      isOpen={isOpen}
    >
      <div className="ion-padding bg-ion-color-secondary">
        <p className="text-xl">
          There is no customer and/or no items on the ticket. Please add both
          before redeeming points.
        </p>
      </div>
      <IonButton
        color="yellow"
        expand="full"
        onClick={() => {
          if (returnUrl) window.location.replace(returnUrl)
        }}
      >
        {'<< Return to POS'}
      </IonButton>
    </IonModal>
  )
}

export { MissingItemsModal }

import { useState, useEffect } from 'react'
import { FirebaseMessaging } from '@capacitor-firebase/messaging'

import { usePushToken } from '../utils/use-push-token'
import { updateInstallation } from './api'

function useInitNotifications() {
  const [enabled, setEnabled] = useState(false)

  const token = usePushToken({ enabled })

  useEffect(() => {
    FirebaseMessaging.requestPermissions().then((status) => {
      console.log('requested permission: ', status.receive)
      if (status.receive === 'granted') {
        // getToken
        setEnabled(true)
      }
      if (status.receive === 'denied') console.log('Permissions Denied')
    })
  }, [])

  useEffect(() => {
    if (!token.data) return

    updateInstallation(token.data)
  }, [token.data])
}

export { useInitNotifications }

import * as React from 'react'
import {
  IonContent,
  IonItem,
  IonLabel,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
} from '@ionic/react'
import { Controller, useForm } from 'react-hook-form'

import { usePushToken } from '../../utils/use-push-token'
import Header from '../../components/header'
import { useMyTerminal, useTerminals } from '../../rotate/queries'
import { useUpdateTerminal } from '../../rotate/mutations'

function TerminalSetupPage() {
  const [segment, setSegment] = React.useState<'cashier' | 'customer'>(
    'cashier'
  )

  return (
    <IonPage>
      <Header title="Terminal Setup" showMenu btn={null} />
      <IonContent className="ion-padding">
        <IonSegment
          onIonChange={(e) =>
            setSegment(e.detail.value as 'cashier' | 'customer')
          }
          value={segment}
        >
          <IonSegmentButton value="cashier">Cashier Terminal</IonSegmentButton>
          <IonSegmentButton value="customer">
            Customer Terminal
          </IonSegmentButton>
        </IonSegment>

        {segment === 'cashier' ? (
          <CashierTerminalSetup />
        ) : segment === 'customer' ? (
          <CustomerTerminalSetup />
        ) : null}
      </IonContent>
    </IonPage>
  )
}

function CashierTerminalSetup() {
  const cashierTerminal = useMyTerminal('pos')

  const { control, getValues, handleSubmit } = useForm<{
    terminal: string | null
  }>({
    defaultValues: {
      terminal: null,
    },
    values: {
      terminal: cashierTerminal.data?.objectId ?? null,
    },
  })
  const deviceToken = usePushToken()

  const terminals = useTerminals()
  const updateTerminal = useUpdateTerminal()

  function handleSelectTerminal(data: { terminal: string | null }) {
    if (!data.terminal || !deviceToken.data) return

    updateTerminal.mutate({
      deviceToken: deviceToken.data,
      stationId: data.terminal,
      type: 'pos',
    })
  }

  if (!terminals.data) return null

  return (
    <div className="ion-padding">
      <p>
        {cashierTerminal.data
          ? `You are currently assigned to: ${cashierTerminal.data.name}`
          : 'You are not currently assigned to a terminal'}
      </p>
      <IonItem color="primary">
        <IonLabel>Select Your Terminal</IonLabel>
        <Controller
          name="terminal"
          control={control}
          render={({ field: { value, onChange } }) => (
            <IonSelect
              color="primary"
              value={value}
              onIonChange={(e) => {
                if (e.detail.value === getValues().terminal) return

                onChange(e.detail.value)
                handleSubmit(handleSelectTerminal)()
              }}
              className="bg-ion-color-primary"
            >
              {terminals.data.data.map((t) => (
                <IonSelectOption
                  color="primary"
                  key={t.objectId}
                  value={t.objectId}
                >
                  {t.name}
                </IonSelectOption>
              ))}
            </IonSelect>
          )}
        />
      </IonItem>
    </div>
  )
}

function CustomerTerminalSetup() {
  const customer = useMyTerminal('client')

  const { control, getValues, handleSubmit } = useForm<{
    terminal: string | null
  }>({
    defaultValues: {
      terminal: null,
    },
    values: {
      terminal: customer.data?.objectId ?? null,
    },
  })
  const deviceToken = usePushToken()

  const terminals = useTerminals()
  const updateTerminal = useUpdateTerminal()

  if (!terminals.data) return null
  function handleSelectTerminal(data: { terminal: string | null }) {
    //
    if (!data.terminal || !deviceToken.data) return

    updateTerminal.mutate({
      deviceToken: deviceToken.data,
      stationId: data.terminal,
      type: 'client',
    })
  }

  return (
    <div className="ion-padding">
      <p>
        {customer.data
          ? `You are currently assigned to: ${customer.data.name}`
          : 'You are not currently assigned to a terminal'}
      </p>
      <IonItem color="primary">
        <IonLabel>Select Your Terminal</IonLabel>
        <Controller
          name="terminal"
          control={control}
          render={({ field: { value, onChange } }) => (
            <IonSelect
              color="primary"
              value={value}
              onIonChange={(e) => {
                if (e.detail.value === getValues().terminal) return

                onChange(e.detail.value)
                handleSubmit(handleSelectTerminal)()
              }}
              className="bg-ion-color-primary"
            >
              {terminals.data.data.map((t) => (
                <IonSelectOption
                  color="primary"
                  key={t.objectId}
                  value={t.objectId}
                >
                  {t.name}
                </IonSelectOption>
              ))}
            </IonSelect>
          )}
        />
      </IonItem>
    </div>
  )
}

export { TerminalSetupPage }

import { IonContent, IonPage, useIonRouter } from '@ionic/react'
import { useLocation } from 'react-router-dom'

import { useCustomerOptInClientId } from '../../marketing/customer-opt-in/utils'

function RotateSuccessPage() {
  return (
    <IonPage>
      <IonContent>
        <Success />
      </IonContent>
    </IonPage>
  )
}

function Success() {
  const router = useIonRouter()
  const clientId = useCustomerOptInClientId()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  return (
    <div className="mx-auto flex size-full max-w-xl flex-col items-center justify-center">
      <div className="flex flex-col gap-y-20">
        <p className="text-5xl">You're Good To Go!</p>
        <p className="text-5xl">Please hand back to cashier.</p>

        <button
          onClick={() =>
            clientId && searchParams.get('loyalty') === 'true'
              ? router.push(
                  `/rotate/opt-in/loyalty-info?loyalty=true&clientId=${clientId}`,
                  'none',
                  'replace'
                )
              : router.push('/rotate/opt-in', 'none', 'replace')
          }
          color="secondary"
          className="bg-ion-color-secondary text-ion-color-primary rounded-full px-20 py-3 text-3xl font-semibold"
        >
          {`>>>`}
        </button>
      </div>
    </div>
  )
}
export { RotateSuccessPage }

import type {
  FiltersForm,
  ServerFilters,
} from '../clients/client-filters.schema'
import type { Nullish } from '../types'

const getArrayFilterValues = (items?: Array<string>) =>
  items && items.length > 0 ? items : undefined

const getBooleanFilterValue = (item?: boolean) => item || undefined

// TODO: do these two need to be different?
const getObjectFilterValue = (obj?: Record<string, unknown>) =>
  obj &&
  Object.values(obj).length &&
  Object.values(obj).every((val) => val != null)
    ? obj
    : undefined

const getGtLtValue = (obj: Record<string, unknown> = {}) =>
  Object.values(obj).every((val) => val == null) ? undefined : obj

function composeSearchFilters(filters: Nullish<FiltersForm>) {
  if (!filters) return {}

  const users = filters.selectedAssociates?.filter(
    (associate) => associate !== 'unassigned'
  )
  const unassignedClients = filters.selectedAssociates?.includes('unassigned')

  const composedFilters: ServerFilters = {
    admin: getBooleanFilterValue(filters.admin),
    subscribersOnly: getBooleanFilterValue(filters.subscribersOnly),

    verifiedMobile: getBooleanFilterValue(filters.hasVerifiedCell),
    phoneNumberExists: getBooleanFilterValue(filters.hasPhoneNumber),
    emailExists: getBooleanFilterValue(filters.hasEmail),
    addressExists: getBooleanFilterValue(filters.hasAddress),

    firstName: filters.firstName || undefined,
    lastName: filters.lastName || undefined,
    phoneNumber: filters.phoneNumber || undefined,
    objectIds: filters.selectedClients?.map((c) => c.objectId) || undefined,

    saleBefore: filters.saleBefore || undefined,
    saleAfter: filters.saleAfter || undefined,
    firstSaleAfter: filters.firstSaleStart || undefined,
    firstSaleBefore: filters.firstSaleEnd || undefined,
    lastContactAfter: filters.lastContactAfter || undefined,
    lastContactBefore: filters.lastContactBefore || undefined,
    lastContactNever: getBooleanFilterValue(filters.lastContactNever),
    birthday:
      typeof filters.birthdayMonth === 'number'
        ? { month: filters.birthdayMonth }
        : undefined,
    anniversary:
      typeof filters.anniversaryMonth === 'number'
        ? { month: filters.anniversaryMonth }
        : undefined,

    brand: getArrayFilterValues(filters.selectedBrands),
    colors: getArrayFilterValues(filters.selectedColors),
    materials: getArrayFilterValues(filters.selectedMaterials),
    posClasses: getArrayFilterValues(filters.selectedPosClasses),
    size: getArrayFilterValues(filters.selectedSizes),
    posStyles: getArrayFilterValues(filters.selectedPosStyles),
    descriptions: getArrayFilterValues(filters.selectedDescriptions),
    posGenders: getArrayFilterValues(filters.selectedPosGenders),
    posSellerName: getArrayFilterValues(filters.selectedPosSellerNames),
    saleGroups:
      filters.selectedSaleGroups && filters.selectedSaleGroups.length > 0
        ? filters.selectedSaleGroups.map((group) => ({
            __type: 'Pointer',
            className: 'Group',
            objectId: group,
          }))
        : undefined,
    clientTagIds: getArrayFilterValues(filters.selectedClientTags),
    users:
      users && users.length > 0
        ? users.map((user) => ({
            __type: 'Pointer',
            className: '_User',
            objectId: user,
          }))
        : undefined,
    unassigned: getBooleanFilterValue(unassignedClients),

    groups:
      filters.selectedGroups && filters.selectedGroups.length > 0
        ? filters.selectedGroups.map((group) => ({
            __type: 'Pointer',
            className: 'Group',
            objectId: group,
          }))
        : undefined,

    loyaltyPointsActive: getGtLtValue(filters.loyaltyPointsActive),
    loyaltyPointsLifetime: getGtLtValue(filters.loyaltyPointsLifetime),
    loyaltyVisitsActive: getGtLtValue(filters.loyaltyVisitsActive),
    loyaltyRewardsPosActive: getGtLtValue(filters.loyaltyRewardsPosActive),
    loyaltyVisitsLifetime: getGtLtValue(filters.loyaltyVisitsLifetime),

    salesTotalMin: filters.totalSalesMin || undefined,
    salesTotalMax: filters.totalSalesMax || undefined,

    haveNotShopped:
      filters.notShoppedIn && Object.keys(filters.notShoppedIn).length
        ? filters.notShoppedIn
        : undefined,

    notContacted: filters.notContacted,

    saleCount: getGtLtValue(filters.saleCount),
    saleIn: getObjectFilterValue(filters.saleIn),
    saleFirstIn: getObjectFilterValue(filters.saleFirstIn),
  }

  return Object.fromEntries(
    Object.entries(composedFilters).filter(([_, v]) => v != null)
  ) as ServerFilters // HACK?/WORKAROUND to narrow type for Typescript in bulk message button implementations
}

function parseServerFilters(filters: ServerFilters | null): FiltersForm {
  if (!filters) return {}

  const parsedFilters: FiltersForm = {
    hasVerifiedCell: filters.verifiedMobile,
    hasPhoneNumber: filters.phoneNumberExists,
    hasEmail: filters.emailExists,
    hasAddress: filters.addressExists,

    firstName: filters.firstName,
    lastName: filters.lastName,
    phoneNumber: filters.phoneNumber,

    saleBefore: filters.saleBefore,
    saleAfter: filters.saleAfter,
    firstSaleStart: filters.firstSaleAfter,
    firstSaleEnd: filters.firstSaleBefore,
    lastContactNever: filters.lastContactNever,
    lastContactBefore: filters.lastContactBefore,
    lastContactAfter: filters.lastContactAfter,
    birthdayMonth: filters.birthday?.month,
    anniversaryMonth: filters.anniversary?.month,

    selectedBrands: filters.brand,
    selectedColors: filters.colors,
    selectedMaterials: filters.materials,
    selectedPosClasses: filters.posClasses,
    selectedSizes: filters.size,
    selectedPosStyles: filters.posStyles,
    selectedDescriptions: filters.descriptions,
    selectedPosGenders: filters.posGenders,
    selectedPosSellerNames: filters.posSellerName,
    selectedSaleGroups: filters.saleGroups?.map((group) => group.objectId),
    selectedClientTags: filters.clientTagIds,
    selectedAssociates: filters.unassigned
      ? filters.users?.map((user) => user.objectId).concat('unassigned')
      : filters.users?.map((user) => user.objectId),
    selectedGroups: filters.groups?.map((group) => group.objectId),

    totalSalesMin: filters.salesTotalMin,
    totalSalesMax: filters.salesTotalMax,

    notShoppedIn: filters.haveNotShopped,
    notContacted: filters.notContacted,

    admin: filters.admin,
    subscribersOnly: filters.subscribersOnly,

    loyaltyPointsActive: filters.loyaltyPointsActive,
    loyaltyPointsLifetime: filters.loyaltyPointsLifetime,
    loyaltyRewardsPosActive: filters.loyaltyRewardsPosActive,
    loyaltyVisitsActive: filters.loyaltyVisitsActive,
    loyaltyVisitsLifetime: filters.loyaltyVisitsActive,

    saleCount: filters.saleCount,
    saleIn: filters.saleIn,
    saleFirstIn: filters.saleFirstIn,
  }

  return Object.fromEntries(
    Object.entries(parsedFilters).filter(([_, v]) => v != null)
  )
}

export { composeSearchFilters, parseServerFilters }

import { useQuery } from '@tanstack/react-query'
import { ZodError } from 'zod'
import { fromZodError } from 'zod-validation-error'

import { makeServerResponseSchema } from '../../utils/schema'
import { client } from '../../services/client'
import { useToast } from '../../contexts/toastContext'
import { loyaltyQuerySelector } from '../../loyalty/utils'
import { lightspeedLoyaltySchema } from './lightspeed.schema'

import type { QueryContextFromKeys } from '../../utils/react-query'
import type { LightspeedLoyaltyParams } from './lightspeed.schema'

type LightspeedQueryContexts = QueryContextFromKeys<typeof lightspeedQueryKeys>

const lightspeedQueryKeys = {
  all: [{ entity: 'lightspeed' }] as const,
  loyalty: ({ accountID, saleID, customerID }: LightspeedLoyaltyParams) =>
    [
      {
        ...lightspeedQueryKeys.all[0],
        scope: 'loyalty',
        accountID,
        saleID,
        customerID,
      },
    ] as const,
  client: ({ accountID, saleID, customerID }: LightspeedLoyaltyParams) => [
    {
      ...lightspeedQueryKeys.all[0],
      scope: 'client',
      accountID,
      saleID,
      customerID,
    },
  ],
}

async function fetchLightspeedClientLoyalty({
  queryKey: [{ accountID, saleID, customerID }],
}: LightspeedQueryContexts['loyalty']) {
  const response = await client
    .post('functions/getClientLoyaltyLightspeed', {
      json: { accountID, saleID, customerID },
    })
    .json()

  const parsedResponse = makeServerResponseSchema(
    lightspeedLoyaltySchema
  ).safeParse(response)
  if (!parsedResponse.success)
    throw new Error(fromZodError(parsedResponse.error).message)

  return parsedResponse.data.result
}

async function fetchLightspeedClient({
  queryKey: [{ accountID, saleID, customerID }],
}: LightspeedQueryContexts['client']) {
  const response = await client
    .post('functions/getLightspeedClient', {
      json: { accountID, saleID, customerID },
    })
    .json()
  try {
    return makeServerResponseSchema(lightspeedLoyaltySchema).parse(response)
      .result
  } catch (e) {
    if (e instanceof ZodError) throw new Error(fromZodError(e).message)
  }
}

function useLightspeedClientLoyalty(params: LightspeedLoyaltyParams) {
  const { accountID, saleID, customerID } = params
  const setToast = useToast()

  return useQuery({
    queryKey: lightspeedQueryKeys.loyalty(params),
    queryFn: fetchLightspeedClientLoyalty,
    enabled: Boolean(accountID && saleID && customerID),
    select: loyaltyQuerySelector,
    onError(error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown Error.'

      setToast({
        message: `Something went wrong while loading the loyalty rewards info: ${errorMessage}`,
        color: 'danger',
      })
    },
  })
}

function useLightspeedClient(params: LightspeedLoyaltyParams) {
  const { accountID, saleID, customerID } = params
  const setToast = useToast()

  return useQuery({
    queryKey: lightspeedQueryKeys.client(params),
    queryFn: fetchLightspeedClient,
    enabled: Boolean(accountID && saleID && customerID),
    onError(error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown Error.'

      setToast({
        message: `Something went wrong while loading the loyalty rewards info: ${errorMessage}`,
        color: 'danger',
      })
    },
  })
}

export { lightspeedQueryKeys, useLightspeedClientLoyalty, useLightspeedClient }

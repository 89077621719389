import { z } from 'zod'
import { isValidPhoneNumber } from 'libphonenumber-js'

import { messageTypes } from '../../constants/misc'
import {
  isoDateSchema,
  makePaginatedServerResponseSchema,
  makeServerResponseSchema,
  nullableArray,
  nullableNumber,
  nullableString,
  serverItemSchema,
} from '../../utils/schema'
import { profileBaseSchema } from '../../schema/profile.schema'
import { serverStatusSchema } from '../../schema/server-status.schema'
import { photoDataSchema } from '../../hooks/use-photo'
import { serverFiltersSchema } from '../../clients/client-filters.schema'
import { loyaltyBonusRuleSchema } from '../../loyalty/loyalty.schema'
import { noteDetailSchema } from '../../schema/general.schema'
import {
  serverUnlayerDesignSchema,
  unlayerDesignSchema,
} from '../../unlayer/unlayer.schema'

type BroadcastBase = z.infer<typeof broadcastBaseSchema>
type BroadcastDetail = z.infer<typeof broadcastDetailSchema>
type ClonedBroadcastInput = Omit<
  CreateBroadcastInputs,
  | 'type'
  | 'photo'
  | 'fromUserRule'
  | 'fromUserId'
  | 'fromName'
  | 'fromEmailName'
  | 'fromEmailDomain'
>
type CreateBroadcastInputs = z.infer<typeof createBroadcastInputsSchema>
type TestBroadcastInputs = z.infer<typeof testBroadcastSchema>
type UpdateBroadcastInputs = z.infer<typeof updateBroadcastInputsSchema>

const fromUserRules = ['assignedTo', 'fromUser'] as const
type FromUserRule = typeof fromUserRules[number]
const fromUserSchema = fromUserRules.map((id) => {
  switch (id) {
    case 'fromUser':
      return z.object({
        fromUserRule: z.literal(id),
        fromUser: serverItemSchema,
      })
    default:
      return z.object({
        fromUserRule: z.literal(id),
        fromUser: z.null(),
      })
  }
})
type SchemaTuple = [typeof fromUserSchema[0], ...typeof fromUserSchema]

const fromUserDU = z.discriminatedUnion(
  'fromUserRule',
  fromUserSchema as SchemaTuple
)

export type DU = z.infer<typeof fromUserDU>
const broadcastErrorSchema = serverItemSchema.extend({
  error: z.string(),
})
const broadcastRoiSchema = z.object({
  total: z.number(),
  messageCount: z.number(),
})

const broadcastEmailOpenRateSchema = z.object({
  open: z.number(),
})

const broadcastTypes = [
  'associate',
  'adminSharedOnly',
  'adminAssignedOnly',
  'adminAll',
  'admin',
] as const

const broadcastTypeSchema = z.enum(broadcastTypes)

const subjAndMsgSchema = z.object({
  emailSubject: z.string().min(1),
  emailMessage: z.string().min(1),
})
const noSubjAndMsg = z.object({
  emailSubject: z.literal(''),
  emailMessage: z.literal(''),
})
const emailSchema = subjAndMsgSchema.or(noSubjAndMsg)

const messageTypeSmsSchema = z
  .object({
    messageType: z.literal('sms'),
    smsMessage: z
      .string()
      .min(1, { message: 'You must add a text message for the Broadcast.' }),
  })
  .merge(noSubjAndMsg)
const messageTypeEmailSchema = z
  .object({
    messageType: z.literal('email'),
    smsMessage: z.literal(''),
  })
  .merge(subjAndMsgSchema)
const messageTypePrioSmsEmailSchema = z
  .object({
    messageType: z.literal('prioSmsEmail'),
    smsMessage: z
      .string()
      .min(1, { message: 'You must add a text message for the Broadcast.' }),
  })
  .merge(subjAndMsgSchema)
const messageTypeSchema = z.discriminatedUnion('messageType', [
  messageTypeSmsSchema,
  messageTypeEmailSchema,
  messageTypePrioSmsEmailSchema,
])
type Union = z.infer<typeof messageTypeSchema>

const [first, ...rest] = Array.from(messageTypeSchema.options.values()).map(
  (option) => option.shape.messageType
) as [z.ZodLiteral<Union['messageType']>, z.ZodLiteral<Union['messageType']>]

const messageTypeLiterals = z.union([first, ...rest])

const attachmentsSchema = z
  .array(z.string())
  .or(z.array(z.object({ content: z.string(), name: z.string() })))
const photoSchema = z.string().or(photoDataSchema)

const broadcastBaseSchema = serverItemSchema.extend({
  attachments: nullableArray(z.string()),
  attachmentsSms: nullableArray(z.string()),
  attachmentsEmail: nullableArray(z.string()),
  className: z.literal('Broadcast'),
  collections: nullableArray(serverItemSchema),
  collectionsSms: nullableArray(serverItemSchema),
  collectionsEmail: nullableArray(serverItemSchema),
  createdAt: isoDateSchema,
  date: z.nullable(isoDateSchema),
  emailCount: z.number(),
  emailMessage: nullableString,
  emailSubject: nullableString,
  errors: nullableArray(z.any()), // TODO Did this change from errorSchema to {objectId:string}
  filter: serverFiltersSchema.nullable(),
  fromEmail: nullableString,
  fromName: nullableString,
  fromUserRule: z.enum(fromUserRules).nullable(),
  fromUser: serverItemSchema.nullable(),
  looks: nullableArray(serverItemSchema),
  looksSms: nullableArray(serverItemSchema),
  looksEmail: nullableArray(serverItemSchema),
  loyaltyBonusRule: loyaltyBonusRuleSchema.nullable(),
  messageType: messageTypeLiterals.nullable().or(z.literal('')), //TODO:??
  notes: nullableArray(noteDetailSchema),
  products: nullableArray(serverItemSchema),
  productsSms: nullableArray(serverItemSchema),
  productsEmail: nullableArray(serverItemSchema),
  requiresApproval: z.boolean(),
  smsCount: z.number(),
  smsMessage: nullableString,
  status: serverStatusSchema.nullable(),
  title: z.string(),
  unlayerDesign: serverUnlayerDesignSchema.nullable(),
  unlayerImage: nullableString,
  unlayerImageFull: nullableString,
  unlayerTemplateId: nullableString,
  user: profileBaseSchema.nullable(),
})
// .and(emailSchema)

const broadcastDetailBaseSchema = z.object({
  broadcast: broadcastBaseSchema,
})

const broadcastDetailSchema = broadcastBaseSchema.and(
  z.object({
    emailClients: z.array(profileBaseSchema),
    smsClients: z.array(profileBaseSchema),
  })
)

const broadcastClientCountsSchema = z.object({
  smsClientCount: nullableNumber,
  emailClientCount: nullableNumber,
})

const createBroadcastInputsSchema = z.object({
  title: z.string(),
  smsTemplateId: z.string().optional(),
  emailTemplateId: z.string().optional(),
  productIdsSms: nullableArray(z.string()).optional(),
  lookIdsSms: nullableArray(z.string()).optional(),
  collectionIdsSms: nullableArray(z.string()).optional(),
  productIdsEmail: nullableArray(z.string()).optional(),
  lookIdsEmail: nullableArray(z.string()).optional(),
  collectionIdsEmail: nullableArray(z.string()).optional(),
  setAttachmentsSms: attachmentsSchema.nullable(),
  setAttachmentsEmail: attachmentsSchema.nullable(),
  photo: photoSchema.nullable(),
  type: broadcastTypeSchema,
  filter: serverFiltersSchema,
  status: serverStatusSchema.optional(),
  // POSSIBLE UPCOMING FEATURE unlayerTemplateId: z.string().optional(),
  unlayerDesign: unlayerDesignSchema.nullable().optional(),
  messageType: z.enum(messageTypes),
  smsMessage: z.string(),
  emailSubject: z.string(),
  emailMessage: z.string(),
  date: nullableString,
  fromUserRule: z.enum(fromUserRules).nullable().optional(), // TODO: req'd for sent
  fromUserId: nullableString.optional(), // TODO: req'd for sent
  fromName: nullableString,
  fromEmailName: z
    .string()
    .transform((value) => (value ? value.toLowerCase() : null)),
  fromEmailDomain: nullableString,
  requiresApproval: z.boolean(),
})
// .and(messageTypeSchema) // TODO: Might need to revisit when enforcing stricter validations

const updateBroadcastInputsSchema = createBroadcastInputsSchema
  .merge(z.object({ note: z.string() }))
  .partial()

const createBroadcastResponseSchema = makeServerResponseSchema(
  z.object({ broadcast: broadcastBaseSchema })
)

const testBroadcastSchema = z.object({
  emailList: z.array(
    z.object({
      email: z
        .literal('')
        .or(z.string().email({ message: 'Please enter a valid email.' })),
    })
  ),
  smsList: z.array(
    z.object({
      e164: z.literal('').or(
        z
          .string()
          .refine(
            (phone) => (phone?.length ? isValidPhoneNumber(phone) : false),
            {
              message: 'Please enter a valid phone number.',
            }
          )
      ),
    })
  ),
})

// For Reference
const queueBroadcastResponseSchema = makeServerResponseSchema(
  z.object({
    broadcast: serverItemSchema.extend({
      title: z.string(),
      emailCount: z.number(),
      smsCount: z.number(),
    }),
  })
)

const fetchBroadcastsResponseSchema = makePaginatedServerResponseSchema(
  z.array(broadcastBaseSchema)
)
const fetchBroadcastResponseSchema = makeServerResponseSchema(
  broadcastDetailSchema
)

const fetchBroadcastClientCountResponseSchema = makeServerResponseSchema(
  broadcastClientCountsSchema
)

export type {
  BroadcastBase,
  BroadcastDetail,
  ClonedBroadcastInput,
  CreateBroadcastInputs,
  FromUserRule,
  TestBroadcastInputs,
  UpdateBroadcastInputs,
}
export {
  broadcastBaseSchema,
  broadcastDetailBaseSchema,
  broadcastRoiSchema,
  broadcastEmailOpenRateSchema,
  createBroadcastInputsSchema,
  createBroadcastResponseSchema,
  fetchBroadcastsResponseSchema,
  fetchBroadcastClientCountResponseSchema,
  testBroadcastSchema,
}

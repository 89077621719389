import { IonImg } from '@ionic/react'

import type { Product } from '../../../types/shopwith'

type Props = {
  delivery: Product
}

function DeliveryItem({ delivery }: Props) {
  if (!delivery.featuredImage) return null

  return (
    <div className="flex w-40 flex-col">
      <div className="aspect-w-7 aspect-h-10 w-full">
        <IonImg
          src={delivery.featuredImage}
          className="object-cover object-top"
        />
      </div>
      <p className="text-left font-bold">{delivery.brand}</p>
      <p className="text-left leading-none">{delivery.title}</p>
    </div>
  )
}

export default DeliveryItem

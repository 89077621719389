import { useQuery } from '@tanstack/react-query'

import { client } from '../../../services/client'
import type { Collection } from '../../../types/shopwith'

type FetchCollectionsResponse = {
  result: {
    collections: Array<Collection>
  }
}

export const collectionQueryKeys = {
  all: [{ entity: 'collections' }] as const,
  lists: () => [{ ...collectionQueryKeys.all[0], scope: 'list' }] as const,
  list: () => [{ ...collectionQueryKeys.lists()[0] }] as const,
}

async function fetchCollections() {
  const response: FetchCollectionsResponse = await client
    .post('functions/collections')
    .json()

  return response.result.collections
}

function useCollections({ enabled }: { enabled: boolean }) {
  return useQuery({
    queryKey: collectionQueryKeys.list(),
    queryFn: fetchCollections,
    enabled,
  })
}

export { useCollections }

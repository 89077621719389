import * as React from 'react'

import usePosSellerNames from '../../../hooks/queries/use-pos-seller-names'
import SearchableSelect from '../../ui/form/searchable-select'

import type { SelectProps } from './types'

function PosSellerNameSelect({
  isAdmin,
  selectedItems,
  setSelectedItems,
}: SelectProps) {
  const [isOpen, setIsOpen] = React.useState(false)

  const posSellerNamesQuery = usePosSellerNames({
    admin: isAdmin,
    enabled: isOpen,
  })

  return (
    <SearchableSelect
      onOpen={() => setIsOpen(true)}
      label="Sold By"
      items={posSellerNamesQuery.data ?? []}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
    />
  )
}

export default PosSellerNameSelect

import { useLocation } from 'react-router-dom'
import { z } from 'zod'

const shortcuts = ['all', 'broadcasts', 'callLists'] as const

function useCampaignParams() {
  const location = useLocation()
  const { search } = location
  const qp = new URLSearchParams(search)
  const type = qp.get('type') as 'all' | 'broadcasts' | 'callLists'
  return type ?? 'all'
}
function useCampaignShortcuts() {
  const currentShortcut = window.location.search
  const shortcutsEnumSchema = z.enum(shortcuts)
  const queryParams = new URLSearchParams(currentShortcut)
  const list = queryParams.get('type')
  return {
    shortcuts,
    parsedShortcut: shortcutsEnumSchema.parse(list ?? 'all'),
  }
}

export { useCampaignParams, useCampaignShortcuts }

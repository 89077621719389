import { useCallback, useEffect } from 'react'
import { isPlatform, useIonRouter } from '@ionic/react'
import { FirebaseMessaging } from '@capacitor-firebase/messaging'
import { LocalNotifications } from '@capacitor/local-notifications'
import { useHistory } from 'react-router-dom'
import { Capacitor } from '@capacitor/core'

import {
  composeCheckInNotificationPath,
  isCheckInNotification,
  useComposeNotificationPath,
} from './utils'

function useNotificationListeners() {
  const history = useHistory()
  const router = useIonRouter()

  const { composeNotificationPath } = useComposeNotificationPath()

  const swMessageHandler = useCallback(
    (event: MessageEvent<any>) => {
      console.log(`serviceWorker message`, { event })
      const data = event.data.data

      if (isCheckInNotification(data)) {
        console.log('foreground sw check-in received')
        const { clientId } = data
        const pathname = router.routeInfo.pathname

        const url = composeCheckInNotificationPath({ clientId, pathname })

        if (router.routeInfo.pathname.includes('redeem-loyalty')) {
          history.replace(url)
        } else {
          history.push(url)
        }
      }
    },
    [history, router.routeInfo.pathname]
  )

  useEffect(() => {
    FirebaseMessaging.addListener('tokenReceived', (event) => {
      console.log('tokenReceived', event)
      // update installation??
    })

    FirebaseMessaging.addListener('notificationReceived', (event) => {
      console.log('notificationReceived', event)
      if (isCheckInNotification(event.notification.data)) {
        console.log('foreground check-in received')
        const { clientId } = event.notification.data
        const pathname = router.routeInfo.pathname

        const url = composeCheckInNotificationPath({ clientId, pathname })

        if (router.routeInfo.pathname.includes('redeem-loyalty')) {
          history.replace(url)
        } else {
          history.push(url)
        }
      }

      if (isPlatform('android')) {
        console.log('android scheduling LocalNotification')
        LocalNotifications.schedule({
          notifications: [
            {
              title: event.notification.title ?? '',
              body: event.notification.body ?? '',
              id: 1,
              extra: event.notification.data,
            },
          ],
        })
      }
    })

    LocalNotifications.addListener(
      'localNotificationActionPerformed',
      (event) => {
        console.log(
          'localNotificationActionPerformed',
          event.notification.extra
        )
        const path = composeNotificationPath(event.notification.extra)
        if (!path) return
        if (history.length === 1) {
          history.replace(path)
        } else {
          history.push(path)
        }
      }
    )

    FirebaseMessaging.addListener('notificationActionPerformed', (event) => {
      console.log('notificationActionPerformed', event)
      const path = composeNotificationPath(event.notification.data)
      if (!path) return
      if (history.length === 1) {
        history.replace(path)
      } else {
        history.push(path)
      }
    })

    // this will still run on native devices when app is in browser (caused problems with heartland UI extension)
    if (!Capacitor.isNativePlatform()) {
      navigator?.serviceWorker?.addEventListener('message', swMessageHandler)
    }

    return () => {
      FirebaseMessaging.removeAllListeners()
      if (!Capacitor.isNativePlatform())
        navigator?.serviceWorker?.removeEventListener(
          'message',
          swMessageHandler
        )
    }
  }, [
    composeNotificationPath,
    history,
    router.routeInfo.pathname,
    swMessageHandler,
  ])
}

export { useNotificationListeners }

import { IonCard, IonCardContent } from '@ionic/react'

import type { ReactNode } from 'react'

function Card({
  children,
  ...cardProps
}: { children: React.ReactNode } & Omit<
  React.ComponentProps<typeof IonCard>,
  'className'
>) {
  return (
    <IonCard {...cardProps} className="my-0">
      {children}
    </IonCard>
  )
}

function CardContent({ children }: { children: ReactNode }) {
  return <IonCardContent className="p-3">{children}</IonCardContent>
}

export { Card, CardContent }

import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import * as R from 'remeda'

import { client } from '../../../services/client'

import type {
  Product,
  ProductDetail as ProductDetailResult,
} from '../../../types/shopwith'
import type { QueryContextFromKeys } from '../../../utils/react-query'

type FetchProductsResponse = {
  result: {
    data: Array<Product>
    hasNextPage: boolean
    page: number
    pageSize: number
    totalCount: number
  }
}

type FetchProductResponse = {
  result: ProductDetailResult
}

export type ProductListFilters = {
  search?: string
  filters?: {
    brands?: Array<string>
    categories?: Array<string>
    shoppableOnly?: boolean
    createdByMe?: boolean
    hasImage?: boolean
    hasInventory?: boolean
    isDiscounted?: boolean
  }
}

export type ProductDetail = ReturnType<typeof useProduct>['data']

type ProductQueryContexts = QueryContextFromKeys<typeof productQueryKeys>

export const productQueryKeys = {
  all: [{ entity: 'products' }] as const,
  lists: () => [{ ...productQueryKeys.all[0], scope: 'list' }] as const,
  list: ({ search = '', filters = {} }: ProductListFilters = {}) =>
    [{ ...productQueryKeys.lists()[0], search, filters }] as const,
  deliveries: () =>
    [{ ...productQueryKeys.lists()[0], type: 'deliveries' }] as const,
  details: () => [{ ...productQueryKeys.all[0], scope: 'detail' }] as const,
  detail: (id: string) => [{ ...productQueryKeys.details()[0], id }] as const,
  filters: () => [{ ...productQueryKeys.all[0], scope: 'filters' }] as const,
}

function composeInventory(productData: ProductDetailResult) {
  return productData.inventory.length > 0
    ? {
        total: productData.product.totalInventory,
        items: organizeInventory(productData.inventory),
      }
    : null
}

function organizeInventory(arr: ProductDetailResult['inventory']) {
  return R.pipe(
    arr,
    // color
    R.groupBy((i) => i.color ?? 'No Color'),
    R.mapValues((v) =>
      R.pipe(
        v,
        R.groupBy((i) => i.size ?? 'No Size')
      )
    )
  )
}

async function fetchProducts({
  queryKey: [{ search, filters }],
  pageParam = 0,
}: ProductQueryContexts['list']) {
  const pageSize = 20

  const { brands, categories, ...otherFilters } = filters ?? {}
  const filter: ProductListFilters['filters'] = {
    brands: brands?.length ? brands : undefined,
    categories: categories?.length ? categories : undefined,
    ...otherFilters,
  }

  const response: FetchProductsResponse = await client
    .post('functions/searchProducts', {
      json: { page: pageParam, pageSize, search, filter },
    })
    .json()

  return response.result
}

async function fetchDeliveries({
  pageParam = 0,
}: ProductQueryContexts['deliveries']) {
  const response: FetchProductsResponse = await client
    .post('functions/products', {
      json: { page: pageParam },
    })
    .json()

  return response.result
}

async function fetchProduct({
  queryKey: [{ id }],
}: ProductQueryContexts['detail']) {
  const response: FetchProductResponse = await client
    .post(`functions/product`, {
      json: {
        objectId: id,
      },
    })
    .json()

  return response.result
}

async function fetchProductFilters() {
  const [brands, categories] = await Promise.all([
    client.post('functions/getProductBrands').json<{ result: Array<string> }>(),
    client
      .post('functions/getProductCategories')
      .json<{ result: Array<string> }>(),
  ])

  return {
    brands: brands.result,
    categories: categories.result,
  }
}

function useProducts({ filters = {}, search }: ProductListFilters) {
  return useProductsBase({ filters, search }, { keepPreviousData: true })
}

function usePostProducts(search: string) {
  return useProductsBase({ filters: {}, search })
}

function useNewDeliveries() {
  return useQuery({
    queryKey: productQueryKeys.deliveries(),
    queryFn: fetchDeliveries,
    select: (data) => data.data,
  })
}

function useProductsBase(
  { filters = {}, search }: ProductListFilters,
  {
    enabled,
    keepPreviousData,
  }: {
    enabled?: boolean
    keepPreviousData?: boolean
  } = {}
) {
  return useInfiniteQuery({
    queryKey: productQueryKeys.list({ filters, search }),
    queryFn: fetchProducts,
    getNextPageParam: (lastPage) =>
      lastPage.hasNextPage ? lastPage.page + 1 : undefined,
    enabled,
    keepPreviousData,
  })
}

function useProduct(id: string) {
  return useQuery({
    queryKey: productQueryKeys.detail(id),
    queryFn: fetchProduct,
    select: (data) => {
      return {
        objectId: data.product.objectId,
        brand: data.product.brand,
        description: data.product.description,
        featuredImage: data.product.featuredImage,
        price: data.product.price,
        title: data.product.title,
        images: data.images,
        otherInfo: {
          category: data.product.category,
          gender: data.product.gender,
          season: data.product.season,
          year: data.product.year,
        },
        inventory: composeInventory(data),
        styleCode: data.product.styleCode,
      }
    },
  })
}

function useProductFilters() {
  return useQuery({
    queryKey: productQueryKeys.filters(),
    queryFn: fetchProductFilters,
  })
}

export {
  useNewDeliveries,
  useProducts,
  usePostProducts,
  useProduct,
  useProductFilters,
}

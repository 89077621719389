import * as React from 'react'
import { Capacitor } from '@capacitor/core'
import { Keyboard } from '@capacitor/keyboard'

function useKeyboard() {
  const isKeyboardAvailable = Capacitor.isPluginAvailable('Keyboard')
  const [isKeyboardOpen, setIsKeyboardOpen] = React.useState(false)

  React.useEffect(() => {
    const showCallback = () => {
      setIsKeyboardOpen(true)
    }
    const hideCallback = () => {
      setIsKeyboardOpen(false)
    }
    if (isKeyboardAvailable) {
      Keyboard.addListener('keyboardDidShow', showCallback)
      Keyboard.addListener('keyboardDidHide', hideCallback)
    }
    return () => {
      if (isKeyboardAvailable) {
        Keyboard.removeAllListeners()
      }
    }
  }, [isKeyboardAvailable])

  return { isKeyboardOpen }
}

export { useKeyboard }

import * as React from 'react'
import { IonModal } from '@ionic/react'

import PullClientsModal from '../../../components/client/pull-clients-modal'
import { FilterButton } from '../../../components/ui/buttons/filter-button'

import type { FiltersForm } from '../../../clients/client-filters.schema'

type Props = {
  disabled?: boolean
  filters: FiltersForm
  isAdmin?: boolean
  onSearch: (filters: FiltersForm) => void
}
function BroadcastClientFilter({
  disabled,
  filters,
  isAdmin = false,
  onSearch,
}: Props) {
  const modalRef = React.useRef<HTMLIonModalElement>(null)

  function dismissModal() {
    modalRef.current?.dismiss()
  }

  return (
    <>
      <FilterButton id="filter-modal" disabled={disabled} />
      <IonModal ref={modalRef} trigger="filter-modal">
        <PullClientsModal
          isAdmin={isAdmin}
          filters={filters}
          onCancel={dismissModal}
          onSearch={(filters) => {
            onSearch(filters)
            dismissModal()
          }}
        />
      </IonModal>
    </>
  )
}

export { BroadcastClientFilter }

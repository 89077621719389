import { parsePhoneNumberFromString as parsePhoneNumber } from 'libphonenumber-js'
import { validate } from 'email-validator'

import type { ClientImport } from './columns'

type ValidState = {
  isValid: boolean
  helperText: string
}

const VALID_STATE: ValidState = {
  isValid: true,
  helperText: '',
}

function isPhoneValid(rowData: ClientImport) {
  const { Phone } = rowData

  if (Phone && !parsePhoneNumber(`${Phone}`, 'US')?.isValid()) {
    return {
      isValid: false,
      helperText: 'Invalid Phone',
    }
  }
  return VALID_STATE
}

function isEmailValid(rowData: ClientImport) {
  const { Email } = rowData

  if (Email && !validate(Email)) {
    return {
      isValid: false,
      helperText: 'Invalid Email',
    }
  }
  return VALID_STATE
}

function isLoyaltyPointsValid(rowData: ClientImport) {
  const { 'Loyalty Points': loyaltyPoints } = rowData

  if (loyaltyPoints && !Number(loyaltyPoints)) {
    return {
      isValid: false,
      helperText: 'Invalid Loyalty Points',
    }
  }
  return VALID_STATE
}

function isLoyaltyVisitsValid(rowData: ClientImport) {
  const { 'Loyalty Visits': loyaltyVisits } = rowData

  if (loyaltyVisits && (!Number(loyaltyVisits) || Number(loyaltyVisits) < 0)) {
    return {
      isValid: false,
      helperText: 'Invalid Loyalty Visits',
    }
  }
  return VALID_STATE
}

function isLoyaltyPointsLifetimeValid(rowData: ClientImport) {
  const { 'Loyalty Points Lifetime': loyaltyPointsLifetime } = rowData

  if (loyaltyPointsLifetime && !Number(loyaltyPointsLifetime)) {
    return {
      isValid: false,
      helperText: 'Invalid Loyalty Points Lifetime',
    }
  }
  return VALID_STATE
}

function isLoyaltyVisitsLifetimeValid(rowData: ClientImport) {
  const { 'Loyalty Visits Lifetime': loyaltyVisitsLifetime } = rowData

  if (
    loyaltyVisitsLifetime &&
    (!Number(loyaltyVisitsLifetime) || Number(loyaltyVisitsLifetime) < 0)
  ) {
    return {
      isValid: false,
      helperText: 'Invalid Loyalty Visits Lifetime',
    }
  }
  return VALID_STATE
}

function isRowValid(rowData: ClientImport) {
  if (!isPhoneValid(rowData).isValid) return isPhoneValid(rowData)
  if (!isEmailValid(rowData).isValid) return isEmailValid(rowData)
  if (!rowData.Phone && !rowData.Email) {
    return {
      isValid: false,
      helperText: 'Email or Phone number is required',
    }
  }
  if (!isLoyaltyPointsValid(rowData).isValid)
    return isLoyaltyPointsValid(rowData)
  if (!isLoyaltyVisitsValid(rowData).isValid)
    return isLoyaltyVisitsValid(rowData)
  if (!isLoyaltyPointsLifetimeValid(rowData).isValid)
    return isLoyaltyPointsLifetimeValid(rowData)
  if (!isLoyaltyVisitsLifetimeValid(rowData).isValid)
    return isLoyaltyVisitsLifetimeValid(rowData)
  return VALID_STATE
}

function isDataValid(data: Array<ClientImport>) {
  return data.length && data.every((row) => isRowValid(row).isValid)
}

export { isDataValid, isEmailValid, isPhoneValid, isRowValid }

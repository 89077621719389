import { useMutation, useQueryClient } from '@tanstack/react-query'
import produce from 'immer'

import { typedKeys } from '../../utils/typescript-helpers'
import { client } from '../../services/client'
import { useToast } from '../../contexts/toastContext'
import { useUser } from '../../contexts/authContext'
import { profileLookQueryKeys } from '../../hooks/profile/profile-looks/queries'
import { postQueryKeys } from './queries'
import { lookQueryKeys } from '../../hooks/shopwith/looks/queries'
import { feedQueryKeys } from '../../hooks/shopwith/use-feed'
import { updateVideoLook } from '../../hooks/shopwith/looks/mutations'

import type { InfiniteData } from '@tanstack/react-query'
import type { PickedFile } from '@capawesome/capacitor-file-picker'
import type { PostOverview, PostPlatforms } from './posts.schema'
import type { PostFilters } from './queries'
import type { PaginatedResponse } from '../../types/queries'

type AccountShare = {
  lookId: string
  post: string
  accountAyrshares: Record<string, Array<PostPlatforms>>
}

type ApprovePostInput = {
  postId: string
  accountShare: AccountShare
  date?: string
}

type SchedulePostInput = {
  postId: string
  date: string
  accountShare: AccountShare
}

type CreatePostsInput = {
  posts: Array<{
    lookId: string
    post: string
    ayrshareId?: string
    platforms: Array<PostPlatforms>
    date?: string
  }>
}

type UpdatePostInput = {
  postId: string
  date?: string
  note?: string
  status?: 'canceled' | 'pending' | 'failed' | 'success' | 'deleted'
}

type ShareLookInput = {
  lookId: string
  description: string
  date: string
  socialConnections: Array<{
    ayrshareId: string
    platforms: Array<PostPlatforms>
  }>
}

type PostsForApprovalCache = InfiniteData<
  PaginatedResponse<PostOverview>['result']
>

async function approvePost(variables: ApprovePostInput) {
  const { postId, accountShare, date } = variables

  const shareToAccount =
    Object.values(accountShare.accountAyrshares).some((v) => v.length) === true

  let createdAccountPostIds: Array<string> = []

  if (shareToAccount) {
    const { accountAyrshares, lookId, post } = accountShare

    const createdPosts = await createPosts({
      posts: typedKeys(accountAyrshares).map((ayrshareId) => ({
        lookId,
        post,
        ayrshareId,
        platforms: accountAyrshares[ayrshareId],
        ...(date && { date }),
      })),
    })

    createdAccountPostIds = createdPosts.posts.map((post) => post.objectId)
  }

  return sendPosts({ postIds: [postId, ...createdAccountPostIds] })
}

async function schedulePost(variables: SchedulePostInput) {
  const { postId, accountShare, date } = variables

  await updatePost({ postId, date })

  return approvePost({ postId, accountShare, date })
}

async function createPosts({ posts }: CreatePostsInput) {
  const response: {
    result: {
      posts: Array<Pick<PostOverview, 'objectId' | 'requiresApproval'>>
    }
  } = await client.post('functions/createPosts', { json: { posts } }).json()

  return response.result
}

async function updatePost(variables: UpdatePostInput) {
  const { postId: objectId, ...updateInputs } = variables

  const response: { result: { post: { objectId: string } } } = await client
    .post('functions/updatePost', { json: { objectId, ...updateInputs } })
    .json()

  return response.result
}

async function deletePost(postId: string) {
  return client.post('functions/deletePost', {
    json: {
      objectId: postId,
    },
  })
}

async function shareLook({
  lookId,
  description,
  date,
  socialConnections,
}: ShareLookInput) {
  // 1. createPosts
  const response = await createPosts({
    posts: socialConnections.map((connection) => ({
      lookId,
      post: description,
      platforms: connection.platforms,
      ayrshareId: connection.ayrshareId,
      ...(date && { date }),
    })),
  })

  // 2. sendPosts
  return sendPosts({
    postIds: response.posts
      .filter((p) => !p.requiresApproval) // filter out posts that require approval
      .map((post) => post.objectId),
  })
}

async function sendPosts({ postIds }: { postIds: Array<string> }) {
  // exit early if no postsIds
  if (!postIds.length) return

  const response: { result: { posts: Array<{ objectId: string }> } } =
    await client
      .post('functions/sendPosts', {
        json: { objectIds: postIds },
        timeout: 300000,
      })
      .json()

  return response.result
}

function useCreateVideoPost() {
  const queryClient = useQueryClient()
  const user = useUser()
  const setToast = useToast()

  return useMutation(
    async ({
      lookId,
      date,
      description,
      socialConnections,
      video,
    }: ShareLookInput & { video: PickedFile }) => {
      await updateVideoLook({
        objectId: lookId,
        video,
        userId: user.objectId,
        accountId: user.account.objectId,
      })

      return shareLook({
        lookId,
        description,
        date,
        socialConnections,
      })
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(lookQueryKeys.lists())
        queryClient.invalidateQueries(profileLookQueryKeys.lists())
        queryClient.invalidateQueries(feedQueryKeys.lists())
        queryClient.invalidateQueries(feedQueryKeys.pendingCount())
      },
      onError: (error) => {
        const errorMessage =
          error instanceof Error ? error.message : 'Unknown error.'

        setToast({
          message: `Something went wrong while creating the post. ${errorMessage}`,
          color: 'danger',
        })
      },
    }
  )
}

function useDeletePost() {
  const queryClient = useQueryClient()
  const setToast = useToast()

  return useMutation(deletePost, {
    onMutate: () => {
      // TODO: cancel queries, feed? posts? profileLooks?
    },
    onSuccess: () => {
      setToast({
        message: 'The post was successfully deleted.',
        color: 'yellow',
        duration: 10000,
      })
    },
    onSettled: () => {
      // TODO: invalidate queries, feed? posts? profileLooks?
    },
    onError: (error, _variables, context) => {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown error.'

      setToast({
        message: `Oops, something went wrong when deleting the post: ${errorMessage}`,
        color: 'danger',
        duration: 10000,
      })
    },
  })
}

function useDenyPost() {
  const queryClient = useQueryClient()
  const setToast = useToast()

  const approvalFilters: PostFilters = {
    admin: true,
    requiresApproval: true,
  }

  return useMutation(
    (variables) => updatePost({ ...variables, status: 'canceled' }),
    {
      onMutate: async (variables: Omit<UpdatePostInput, 'status'>) => {
        await queryClient.cancelQueries(postQueryKeys.lists())

        const previousPostsForApproval =
          queryClient.getQueryData<PostsForApprovalCache>(
            postQueryKeys.list({
              filters: approvalFilters,
            })
          )

        if (previousPostsForApproval) {
          queryClient.setQueryData<PostsForApprovalCache>(
            postQueryKeys.list({
              filters: approvalFilters,
            }),
            (old) => {
              if (old) {
                return produce(old, (draft) =>
                  draft.pages.forEach((page) => {
                    page.data = page.data.filter(
                      (post) => post.objectId !== variables.postId
                    )
                  })
                )
              }

              return previousPostsForApproval
            }
          )
        }

        return { previousPostsForApproval }
      },
      onSettled: () => {
        queryClient.invalidateQueries(postQueryKeys.all)
      },
      onError: (error) => {
        const errorMessage =
          error instanceof Error ? error.message : 'Unknown error.'

        setToast({
          message: `Oops, something went wrong when approving the post: ${errorMessage}`,
          color: 'danger',
          duration: 10000,
        })
      },
    }
  )
}

function useApprovePost() {
  const queryClient = useQueryClient()
  const setToast = useToast()

  const approvalFilters: PostFilters = {
    admin: true,
    requiresApproval: true,
  }

  return useMutation(approvePost, {
    onMutate: async (variables: ApprovePostInput) => {
      await queryClient.cancelQueries(postQueryKeys.lists())

      const previousPostsForApproval =
        queryClient.getQueryData<PostsForApprovalCache>(
          postQueryKeys.list({
            filters: approvalFilters,
          })
        )

      if (previousPostsForApproval) {
        queryClient.setQueryData<PostsForApprovalCache>(
          postQueryKeys.list({
            filters: approvalFilters,
          }),
          (old) => {
            if (old) {
              return produce(old, (draft) =>
                draft.pages.forEach((page) => {
                  page.data = page.data.filter(
                    (post) => post.objectId !== variables.postId
                  )
                })
              )
            }

            return previousPostsForApproval
          }
        )
      }

      return { previousPostsForApproval }
    },
    onSettled: () => {
      queryClient.invalidateQueries(postQueryKeys.all)
    },
    onError: (error) => {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown error.'

      setToast({
        message: `Oops, something went wrong when approving the post: ${errorMessage}`,
        color: 'danger',
        duration: 10000,
      })
    },
  })
}

function useSchedulePost() {
  const queryClient = useQueryClient()
  const setToast = useToast()

  const approvalFilters: PostFilters = {
    admin: true,
    requiresApproval: true,
  }

  return useMutation(schedulePost, {
    onMutate: async (variables: SchedulePostInput) => {
      await queryClient.cancelQueries(postQueryKeys.lists())

      const previousPostsForApproval =
        queryClient.getQueryData<PostsForApprovalCache>(
          postQueryKeys.list({ filters: approvalFilters })
        )

      if (previousPostsForApproval) {
        queryClient.setQueryData<PostsForApprovalCache>(
          postQueryKeys.list({ filters: approvalFilters }),
          (old) => {
            if (old) {
              return produce(old, (draft) =>
                draft.pages.forEach((page) => {
                  page.data = page.data.filter(
                    (post) => post.objectId !== variables.postId
                  )
                })
              )
            }

            return previousPostsForApproval
          }
        )
      }

      return { previousPostsForApproval }
    },

    onError: (error, _variables, context) => {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown error.'

      if (context?.previousPostsForApproval)
        queryClient.setQueryData(
          postQueryKeys.list({ filters: approvalFilters }),
          context.previousPostsForApproval
        )
      setToast({
        message: `Oops, something went wrong when scheduling the post: ${errorMessage}`,
        color: 'danger',
        duration: 10000,
      })
    },

    onSettled: () => {
      queryClient.invalidateQueries(postQueryKeys.all)
    },
  })
}

// Share already created look
function useShareLook() {
  const queryClient = useQueryClient()
  const setToast = useToast()

  return useMutation(shareLook, {
    onSettled: () => {
      queryClient.invalidateQueries(profileLookQueryKeys.lists())
      queryClient.invalidateQueries(feedQueryKeys.lists())
      queryClient.invalidateQueries(feedQueryKeys.pendingCount())
    },
    onError: (error) => {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown Error.'

      setToast({
        message: `Something went wrong trying to share your asset. ${errorMessage}`,
        color: 'danger',
      })
    },
  })
}

export type { ShareLookInput }
export {
  useShareLook,
  useCreateVideoPost,
  useDeletePost,
  useDenyPost,
  useApprovePost,
  useSchedulePost,
}

import * as React from 'react'
import type { QueryContextFromKeys } from '../../utils/react-query'
import { client } from '../../services/client'
import { fetchUserRoleSocialsSchema } from './userRoleSocial.schema'
import { useQuery } from '@tanstack/react-query'

type UserRoleSocialFilters = {
  page: number
  pageSize: number
  filter?: {
    search?: string
  }
}

export const userRoleSocialKeys = {
  all: [{ entity: 'roleSocial' }] as const,
  lists: () => [{ ...userRoleSocialKeys.all[0], scope: 'list' }] as const,
  list: (filters: UserRoleSocialFilters) =>
    [{ ...userRoleSocialKeys.lists()[0], ...filters }] as const,
}

type RoleSocialQueryContexts = QueryContextFromKeys<typeof userRoleSocialKeys>

async function fetchUserRoleSocials({
  queryKey: [{ page, pageSize, filter }],
}: RoleSocialQueryContexts['list']) {
  const response = await client
    .post('functions/getUserRoleSocials', {
      json: { page, pageSize, filter },
    })
    .json()

  return fetchUserRoleSocialsSchema.parse(response).result
}

function useUserRoleSocials() {
  const [search, setSearch] = React.useState('')
  const [{ pageIndex, pageSize }, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10,
  })

  const userRoleSocialQuery = useQuery({
    queryKey: userRoleSocialKeys.list({
      page: pageIndex,
      pageSize,
      filter: { search },
    }),
    queryFn: fetchUserRoleSocials,
    useErrorBoundary: true,
    keepPreviousData: true,
  })

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  )

  return {
    data: userRoleSocialQuery.data?.data ?? [],
    isPreviousData: userRoleSocialQuery.isPreviousData,
    isLoading: userRoleSocialQuery.isLoading,
    count: userRoleSocialQuery.data?.count ?? 0,
    pagination,
    setPagination,
    search,
    setSearch,
  }
}

export { useUserRoleSocials }

import { IconButton, Tooltip } from '@mui/material'
import { EnvelopeIcon } from '@heroicons/react/24/outline'

import { useSendWelcomeEmail } from '../mutations'

import type { UserSetup } from '../user-setup.schema'

type Props = {
  status: UserSetup['onboardingStatus']
  userId: string
}

function OnboardingStatus({ status, userId }: Props) {
  const sendWelcomeEmail = useSendWelcomeEmail()
  return (
    <div className="flex items-center gap-x-2">
      <Tooltip title="Send Welcome Email">
        <span className="flex">
          <IconButton
            color={status === 'accepted' ? 'info' : 'default'}
            onClick={() => {
              sendWelcomeEmail.mutate(userId)
            }}
            disabled={sendWelcomeEmail.isLoading || status === 'deactivated'}
          >
            <EnvelopeIcon className="size-6" />
          </IconButton>
        </span>
      </Tooltip>
      <span className="capitalize">
        {status === 'deactivated' ? 'inactive' : status}
      </span>
    </div>
  )
}

export { OnboardingStatus }

import { IonContent, IonPage } from '@ionic/react'

import { OptInSuccessContent } from '../../integrations/lightspeed/components/optin/opt-in-success-content'
import { useLightspeedParams } from '../../integrations/lightspeed/utils'

function LightspeedOptInSuccessPage() {
  const { returnURL } = useLightspeedParams()

  function handleClick() {
    if (returnURL) window.location.replace(returnURL)
  }

  return (
    <IonPage>
      <IonContent>
        <OptInSuccessContent handleClick={handleClick} />
      </IonContent>
    </IonPage>
  )
}

export default LightspeedOptInSuccessPage

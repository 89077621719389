import { IonContent, IonHeader, IonLoading, IonPage } from '@ionic/react'

import {
  useApplyHeartlandRewards,
  useRemoveHeartlandRewards,
} from '../../integrations/heartland/mutations'
import { useHeartlandClientLoyalty } from '../../integrations/heartland/queries'
import { useHeartlandParams } from '../../integrations/heartland/utils'
import { LoyaltyRedemptionDetail } from '../../loyalty/components/loyalty-redemption-detail'
import { Header } from '../../loyalty/components/header'
import { MissingItemsModal } from '../../loyalty/components/missing-items-modal'

// http://localhost:3000/heartland/LIAPZUXNbo/redeem-loyalty?account_uuid=599d089f-0e25-43e0-8e31-94f24e20047b&customer_id=100002&pos_url=https%3A%2F%2Fclientelier.retail.heartland.us%2F%23pos%2F160347&subdomain=clientelier&ticket_id=160347&transaction_status=incomplete
function HeartlandRedemptionPage() {
  const { groupId, clientId, ticketId, posUrl } = useHeartlandParams()

  const { data, isInitialLoading } = useHeartlandClientLoyalty({
    groupId,
    clientId,
    ticketId,
  })

  const isTicketEmpty = Boolean(data) && !data?.ticket.lines.length

  const applyRewards = useApplyHeartlandRewards()
  const removeRewards = useRemoveHeartlandRewards()

  return (
    <IonPage>
      <IonHeader>
        <Header returnUrl={posUrl} title="Redeem Discount" />
      </IonHeader>
      <IonContent>
        {data ? (
          <LoyaltyRedemptionDetail
            data={data}
            disabled={isInitialLoading}
            onApplyRewards={(rewards) => applyRewards.mutate(rewards)}
            onRemoveRewards={() => removeRewards.mutate()}
          />
        ) : (
          <IonLoading isOpen message="Getting Rewards..." />
        )}
      </IonContent>

      <MissingItemsModal
        isOpen={isTicketEmpty && !isInitialLoading}
        returnUrl={posUrl}
      />

      <IonLoading
        isOpen={applyRewards.isLoading || removeRewards.isLoading}
        message={'Applying Discount...'}
      />
    </IonPage>
  )
}

export default HeartlandRedemptionPage

import * as React from 'react'
import { IonButton, IonIcon, IonModal } from '@ionic/react'
import { paperPlaneOutline } from 'ionicons/icons'

import { useUser } from '../../contexts/authContext'
import { useCreateProfileProducts } from '../../hooks/profile/profile-products/mutations'
import { useShareShopWith } from '../../hooks/shopwith/use-share-shop-with'
import ShareShopWithModal from './share-shop-with-modal'

type Props = {
  disabled: boolean
  products: Array<string>
}

function ShareProductButton({ disabled, products }: Props) {
  const [isOpen, setIsOpen] = React.useState(false)

  const [present] = useShareShopWith()

  const user = useUser()

  const createProfileProducts = useCreateProfileProducts()

  const openSendModal = () => setIsOpen(true)
  const closeSendModal = () => setIsOpen(false)

  const link =
    products.length > 1
      ? `https://oneshop.me/look?products=${products.join(',')}&stylist=${
          user.objectId
        }`
      : `https://oneshop.me/product/${products}?stylist=${user.objectId}`

  function handleShareToProfile() {
    createProfileProducts.mutate({ productIds: products })
  }

  return (
    <>
      <IonButton
        onClick={() =>
          present({
            onSend: openSendModal,
            onShare: handleShareToProfile,
          })
        }
        size="small"
        expand="block"
        disabled={disabled}
        color="yellow"
        className="uppercase tracking-wide"
      >
        Share
        <IonIcon slot="end" icon={paperPlaneOutline} />
      </IonButton>

      <IonModal isOpen={isOpen} onDidDismiss={closeSendModal}>
        <ShareShopWithModal
          title={`Send Product${products.length > 1 ? 's' : ''}`}
          items={products}
          link={link}
          pointers={{ productIds: products }}
          onClose={closeSendModal}
          onSend={closeSendModal}
        />
      </IonModal>
    </>
  )
}

export default ShareProductButton

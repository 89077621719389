import { client } from '../services/client'
import { OneShopSms } from '@oneshop-inc/sms'

import { useToast } from '../contexts/toastContext'

type Attachment = {
  name: string
  content: string
  fileUri: string
}

type SendMessageProps = {
  user: { hasSendGrid: boolean }
  clientId: string
  to: string
  subject: string
  body: string
  attachments?: Array<Attachment>
  note?: string
  log?: boolean
  pointers?: {
    smsTemplateId?: string
    emailTemplateId?: string
    callListId?: string
    collectionIds?: Array<string>
    lookIds?: Array<string>
    productIds?: Array<string>
  }
}

type SendMessagePostProps = {
  clientId: string
  to: string
  subject: string
  data: string
  attachments?: Array<Pick<Attachment, 'name' | 'content'>> | Array<string>
  note?: string
  log?: boolean
  pointers?: {
    smsTemplateId?: string
    emailTemplateId?: string
    callListId?: string
    collectionIds?: Array<string>
    lookIds?: Array<string>
    productIds?: Array<string>
  }
}

type SendMessageResult = {
  messageQueued: boolean
  type: string
}

function useSendCommunication() {
  const setToast = useToast()

  function sendSMS(
    phoneNumber: string,
    {
      message,
      attachments,
    }: { message?: string; attachments?: Array<string> } = {}
  ) {
    return OneShopSms.openMessenger({
      number: phoneNumber,
      body: message ?? '',
      attachments: attachments?.length ? attachments : [],
    }).catch((e: Error) => {
      return Promise.reject(new Error(e.message))
    })
  }

  // TODO: REVISIT - possibly rename to sendEmail for clarity of intent
  async function sendMessage({
    user,
    clientId,
    to,
    subject,
    body,
    attachments,
    note,
    pointers,
  }: SendMessageProps) {
    // TODO: Put these checks in consuming components since they are required here -
    // TODO: perhaps message to inform empty strings are not allowed
    if (!user || !clientId || !to || !subject || !body) {
      return
    }

    if (!user.hasSendGrid) {
      return setToast({
        message:
          'Your account is not set up to send emails. Please contact your OneShop rep to set up this feature.',
      })
    }

    try {
      await sendMessagePost({
        clientId,
        to,
        subject,
        data: body,
        attachments,
        note,
        pointers,
      })
    } catch (e) {
      console.error(e)
    }
  }

  async function sendMessagePost(props: SendMessagePostProps) {
    const data: { result: SendMessageResult } = await client
      .post('functions/sendMessage', { json: props })
      .json()
    return data.result
  }

  return { sendSMS, sendMessage }
}

export default useSendCommunication

import * as React from 'react'
import {
  IonButton,
  IonCol,
  IonContent,
  IonPage,
  IonRow,
  useIonRouter,
} from '@ionic/react'
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { zodResolver } from '@hookform/resolvers/zod'

import { cn } from '../../utils/cn'
import { useAccount } from '../../contexts/authContext'
import { useToast } from '../../contexts/toastContext'
import { CheckInButton } from '../../components/ui/buttons/check-in-button'
import { StyledError } from '../../components/ui/form/errors'
import { TextInput } from '../../components/ui/form/input'
import { useCheckInActions } from '../../check-in/check-in-store'
import { emailCheckIn } from '../../rotate/api'
import { RotateInfoContainer } from '../../rotate/components/rotate-info-container'
import { RotateInfoIntro } from '../../rotate/components/rotate-info-intro'
import { emailCheckInSchema } from '../../rotate/rotate.schema'

import type { EmailCheckIn } from '../../rotate/rotate.schema'

function PairedEmailCheckInPage() {
  return (
    <IonPage>
      <IonContent>
        <EmailCheckInContent />
      </IonContent>
    </IonPage>
  )
}

function EmailCheckInContent() {
  const [isLoading, setIsLoading] = React.useState(false)
  const { resetClientInfo, setCheckInMethod, setClientInfo } =
    useCheckInActions()

  const account = useAccount()
  const checkInSettings = account.account.checkInSettings

  const router = useIonRouter()

  const setToast = useToast()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<EmailCheckIn>({
    defaultValues: { email: '' },
    resolver: zodResolver(emailCheckInSchema),
  })

  async function handleCheckIn({ email }: { email: string }) {
    setIsLoading(true)
    setCheckInMethod({ email })

    try {
      const { client } = await emailCheckIn(email)
      setClientInfo({ email })

      if (!client)
        return router.push(`/check-in/paired/add-customer`, 'forward', 'pop')

      const phoneNumber = client.phoneNumbers?.length
        ? client.phoneNumbers[0].phoneNumber
        : ''

      setClientInfo({
        ...client,
        dobObject: {
          month: client.dobObject.month ?? null,
          day: client.dobObject.day ?? null,
        },
        phoneNumber,
      })

      return router.push(
        `/check-in/paired/loyalty-info?clientId=${client.objectId}`,
        'none',
        'replace'
      )
    } catch (e) {
      console.error(e)
      resetClientInfo()
      const errorMessage = e instanceof Error ? e.message : 'Unknown Error.'

      setToast({ color: 'danger', message: errorMessage })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div
      style={{
        '--image-url': `url(${checkInSettings?.background?.image})`,
      }}
      className={cn('ion-padding h-full', {
        'bg-[image:var(--image-url)] bg-cover':
          checkInSettings?.background?.image,
      })}
    >
      <IonRow className="h-full">
        <IonCol
          size="6"
          className="flex h-full flex-1 flex-col items-center justify-center space-y-6"
        >
          <RotateInfoContainer>
            <IonButton
              disabled={isLoading}
              fill="solid"
              color="secondary"
              onClick={() =>
                router.push(
                  `/check-in/paired/phone-check-in`,
                  'none',
                  'replace'
                )
              }
            >
              Check-In With Phone
            </IonButton>
            <div className="space-y-3 self-center text-center">
              {checkInSettings?.body?.text ? (
                <RotateInfoIntro>{checkInSettings?.body?.text}</RotateInfoIntro>
              ) : (
                <>
                  <RotateInfoIntro>
                    Welcome to {account.account.companyName}.
                  </RotateInfoIntro>
                  <RotateInfoIntro>
                    Enter your email to check in!
                  </RotateInfoIntro>
                </>
              )}
            </div>
          </RotateInfoContainer>
        </IonCol>
        <IonCol
          size="6"
          className="flex h-full flex-1 flex-col items-center justify-center gap-6"
        >
          <div className="bg-ion-color-secondary w-96 rounded-2xl p-2">
            <form
              onSubmit={handleSubmit(handleCheckIn)}
              className="w-full space-y-4"
            >
              <TextInput
                control={control}
                name="email"
                disabled={isLoading}
                placeholder="Enter Your Email"
              />
              <ErrorMessage as={StyledError} name="email" errors={errors} />
              <div className="text-center">
                <CheckInButton
                  disabled={isLoading}
                  label="Check In"
                  type="submit"
                />
              </div>
            </form>
          </div>
        </IonCol>
      </IonRow>
    </div>
  )
}

export { PairedEmailCheckInPage }

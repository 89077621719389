import { v4 as uuidv4 } from 'uuid'

const springboard = {
  integration: 'springboard',
  clientId: 'b052e055818d37ffa3eb5841e32842f7',
  redirectUri: 'https://app.oneshopretail.com/authorization/callback',
  scope:
    'webhooks.manage customer.read customer.manage location.read inventory.values.read sales.ticket.manage reporting.read ui_extensions.manage sales.coupons.manage sales.coupons.read payment_type.manage gift_card.manage custom_field.manage',
  state: uuidv4(),
  href: () =>
    `https://retail.heartland.us/oauth/authorize?client_id=${springboard.clientId}&redirect_uri=${springboard.redirectUri}&state=${springboard.state}&scope=${springboard.scope}`,
}

const intuit = {
  integration: 'intuit',
  id: 'ABLliEd8aVyoO4LgfJKUR7jXrh8kmMuykvbqnzBl3XG5NuFNbS',
  // TODO update the redirect to oneshopretail in developer portal
  redirectUri: 'https://app.clientelier.com/authorization/callback',
  scope: 'com.intuit.quickbooks.accounting',
  state: uuidv4(),
  href: () =>
    `https://appcenter.intuit.com/connect/oauth2?client_id=${intuit.id}&redirect_uri=${intuit.redirectUri}&response_type=code&scope=${intuit.scope}&state=${intuit.state}`,
}

const lightspeed = {
  integration: 'lightspeed',
  clientId: '241b48f4b59766e6b4205a015db71f439fa8c1c3ba5a48a63ea7bb69665d0219',
  scope: 'employee:all',
  href: () =>
    `https://cloud.lightspeedapp.com/oauth/authorize.php?response_type=code&client_id=${lightspeed.clientId}&scope=${lightspeed.scope}`,
}

const mailchimp = {
  integration: 'mailchimp',
  clientId: 128774689610,
  redirectUri: 'https://app.oneshopretail.com/authorization/callback',
  href: () =>
    `https://login.mailchimp.com/oauth2/authorize?response_type=code&client_id=${mailchimp.clientId}&redirect_uri=${mailchimp.redirectUri}`,
}

export { springboard, intuit, mailchimp, lightspeed }

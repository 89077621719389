import React, { useEffect, useState } from 'react'
import { IonInput, IonItem, IonList, IonLabel } from '@ionic/react'

import * as commonStrings from '../utils/common-strings'
import { searchService } from '../services/searchService'

const VenderList = ({ handleVendorUpdate }) => {
  const [vendorData, setVendorData] = useState([])
  const [vendor, setVendor] = useState()

  useEffect(() => {
    searchService.getVendors().then((result) => {
      setVendorData(result)
    })
  }, [])

  const venderFilter = (vendorItem) => {
    if (!vendor) return 0
    if (vendorItem.name === vendor) return 0
    return vendorItem.name.toLowerCase().indexOf(vendor.toLowerCase()) > -1
  }

  const updateVendor = (newVendor) => {
    setVendor(newVendor)
    if (handleVendorUpdate) handleVendorUpdate(newVendor)
  }

  return (
    <>
      <IonItem color="secondary">
        <IonLabel position="floating">{commonStrings.vendor}</IonLabel>
        <IonInput
          value={vendor}
          onIonChange={(e) => updateVendor(e.target.value)}
        />
      </IonItem>
      <IonList>
        {vendorData.filter(venderFilter).map((vendor, index) => (
          <IonItem
            color="secondary"
            key={index}
            onClick={() => updateVendor(vendor.name)}
          >
            {vendor.name}
          </IonItem>
        ))}
      </IonList>
    </>
  )
}

export default VenderList

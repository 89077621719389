import * as React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import { ErrorBoundary } from 'react-error-boundary'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'
/* Tailwind */
import './theme/tailwind.css'

import { AuthContext } from './contexts/authContext'
import { CurrentClientProvider } from './contexts/clientContext'
import { MetaProvider } from './contexts/cardMetaContext'
import ErrorFallback from './components/error-fallback'
import CreateAccountPage from './pages/create-account-page'
import Login from './pages/login'
import Recovery from './pages/recovery'
import AuthenticatedApp from './authenticated-app'
import { Capacitor } from '@capacitor/core'
import { useStatus } from './hooks/status'
import AppStatus from './pages/app-status'
import AppUrlListener from './pages/app-url-listener'
import HeartlandLoyaltyUiExtension from './pages/heartland/heartland-loyalty-ui-extension'
import HeartlandClientStatusUiExtension from './pages/heartland/heartland-client-status-ui-extension'
import { initializeApp } from 'firebase/app'

setupIonicReact({
  mode: 'ios',
  swipeBackEnabled: false,
})

function useInitDesktopPush() {
  const { isInitialized } = React.useContext(AuthContext)

  React.useEffect(() => {
    if (Capacitor.isNativePlatform() || !isInitialized) return

    initializeApp({
      apiKey: 'AIzaSyBF7TyxPqqc8I5HKN1hfRP0erT3-itzQ4E',
      authDomain: 'oneshop-push.firebaseapp.com',
      databaseURL: 'https://oneshop-push-default-rtdb.firebaseio.com',
      projectId: 'oneshop-push',
      storageBucket: 'oneshop-push.appspot.com',
      messagingSenderId: '103795536818',
      appId: '1:103795536818:web:721261058020e3319feaab',
      measurementId: 'G-LRWKVZFG8S',
    })
  }, [isInitialized])
}

function AppErrorFallback({ error }: { error: unknown }) {
  return <ErrorFallback error={error}>Something went wrong.</ErrorFallback>
}

const App = () => {
  const { isLoggedIn, isInitialized, checkAuthState } =
    React.useContext(AuthContext)

  const statusQuery = useStatus()
  useInitDesktopPush()

  React.useEffect(() => {
    checkAuthState()
  }, [checkAuthState])

  React.useEffect(() => {
    async function init() {
      if (Capacitor.isNativePlatform() || !isInitialized) return

      FirebaseAnalytics.initializeFirebase({
        apiKey: 'AIzaSyBF7TyxPqqc8I5HKN1hfRP0erT3-itzQ4E',
        authDomain: 'oneshop-push.firebaseapp.com',
        databaseURL: 'https://oneshop-push-default-rtdb.firebaseio.com',
        projectId: 'oneshop-push',
        storageBucket: 'oneshop-push.appspot.com',
        messagingSenderId: '103795536818',
        appId: '1:103795536818:web:721261058020e3319feaab',
        measurementId: 'G-LRWKVZFG8S',
      })
    }

    init()
  }, [isInitialized])

  function logError(error: Error, info: { componentStack: string }) {
    console.error('Error Log: ')
    console.log(error, info.componentStack)
    console.error('End Error Log')
  }

  if (!isInitialized) {
    return null
  }

  if (statusQuery.data?.isBeforeMinimum) {
    return (
      <IonApp>
        <AppStatus />
      </IonApp>
    )
  }

  return (
    <IonApp>
      <CurrentClientProvider>
        <MetaProvider>
          {isLoggedIn ? (
            <IonReactRouter>
              <ErrorBoundary
                FallbackComponent={AppErrorFallback}
                onError={logError}
              >
                <AuthenticatedApp />
              </ErrorBoundary>
            </IonReactRouter>
          ) : (
            <IonReactRouter>
              <AppUrlListener />
              <IonRouterOutlet>
                <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                  onError={logError}
                >
                  {/* Heartland UI Extensions */}
                  <Route exact path="/heartland/ui_extension/:groupId/loyalty">
                    <HeartlandLoyaltyUiExtension />
                  </Route>
                  <Route
                    exact
                    path="/heartland/ui_extension/:groupId/client_status"
                  >
                    <HeartlandClientStatusUiExtension />
                  </Route>

                  {/* Auth Routes */}
                  <Route path="/create-account" exact>
                    <CreateAccountPage />
                  </Route>
                  <Route path="/login" exact>
                    <Login />
                  </Route>
                  <Route path="/recovery" exact>
                    <Recovery />
                  </Route>
                  {/* Catch All to LOGIN */}
                  <Route
                    render={() => (
                      <Redirect
                        to={{
                          ...window.location,
                          pathname: '/login',
                        }}
                      />
                    )}
                  />
                </ErrorBoundary>
              </IonRouterOutlet>
            </IonReactRouter>
          )}
        </MetaProvider>
      </CurrentClientProvider>
    </IonApp>
  )
}

export default App

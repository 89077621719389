import { useIonRouter } from '@ionic/react'
import { useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'
import { isPlainObject, isArray } from 'remeda'
import { chatQueryKeys } from '../hooks/chat/queries'

type NotificationData = { notification: string }

function useComposeNotificationPath() {
  const queryClient = useQueryClient()

  const router = useIonRouter()

  return useMemo(
    () => ({
      composeNotificationPath: (n: unknown) => {
        let path: string = ''

        if (isNotificationData(n)) {
          const notificationDetail = JSON.parse(n.notification)

          const likesClientId = notificationDetail.likes?.[0]?.client?.objectId
          if (likesClientId) {
            path = `/tabs/clients/client/${likesClientId}?currentview=wishlist`
          }
        } else if (isConversationNotification(n)) {
          // invalidate conversation and refetch
          queryClient.invalidateQueries(
            chatQueryKeys.conversationDetail(n.conversationId)
          )
          path = `/chat/c/${n.conversationId}`
          // checkIn must come before client since client always passes if there is a clientId
        } else if (isCheckInNotification(n)) {
          path = composeCheckInNotificationPath({
            clientId: n.clientId,
            pathname: router.routeInfo.pathname,
          })
        } else if (isClientNotification(n)) {
          path = `/tabs/clients/client/${n.clientId}`
        }

        return path
      },
    }),
    [queryClient, router.routeInfo.pathname]
  )
}

function composeCheckInNotificationPath({
  clientId,
  pathname,
}: {
  clientId: string
  pathname: string
}) {
  return pathname.includes('tabs')
    ? `/tabs/clients/client/${clientId}/redeem-loyalty`
    : pathname.includes('paired')
    ? `/check-in/paired/admin/redeem-loyalty?clientId=${clientId}`
    : `/clients/client/${clientId}/redeem-loyalty`
}

function isClient(value: unknown): value is Client {
  if (!isPlainObject(value)) return false
  return typeof value.objectId === 'string'
}

function isLikes(value: unknown): value is Array<Client> {
  if (!isArray(value)) {
    return false
  }

  return value.every((item) => isClient(item))
}

function isCheckInNotification(
  d: unknown
): d is { stationId: string; clientId: string } {
  if (!isPlainObject(d)) {
    return false
  }

  return typeof d.clientId === 'string' && typeof d.stationId === 'string'
}

function isClientNotification(d: unknown): d is { clientId: string } {
  if (!isPlainObject(d)) {
    return false
  }

  return typeof d.clientId === 'string'
}

function isConversationNotification(
  d: unknown
): d is { conversationId: string } {
  if (!isPlainObject(d)) {
    return false
  }

  return typeof d.conversationId === 'string'
}

function isNotificationData(value: unknown): value is NotificationData {
  if (!isPlainObject(value)) return false
  return 'notification' in value
}

function isWishlistNotification(
  d: unknown
): d is { likes: Array<{ client: { objectId: string } }> } {
  if (!isPlainObject(d)) {
    return false
  }

  return isLikes(d.likes)
}

export {
  composeCheckInNotificationPath,
  isCheckInNotification,
  isClientNotification,
  isConversationNotification,
  isNotificationData,
  useComposeNotificationPath,
}

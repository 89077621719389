import { z } from 'zod'

import {
  nullableNumber,
  nullableString,
  serverItemSchema,
} from '../../utils/schema'

type GroupMember = z.infer<typeof groupMemberSchema>
type UserInput = z.input<typeof addUserSchema>
type UpdateUserInput = z.input<typeof updateUserSchema>
type AddGroupMember = z.infer<typeof addGroupMemberSchema>
type AddUser = z.infer<typeof addUserSchema>
type UserSetup = z.infer<typeof userSetupSchema>

const groupMemberSchema = serverItemSchema.extend({
  group: serverItemSchema.extend({
    name: nullableString,
    companyName: nullableString,
    logo: nullableString,
    parent: z.any(), // nullable pointer?
    type: nullableString,
  }),
  posId: nullableString,
  role: z.enum(['admin', 'associate']),
  user: serverItemSchema.extend({
    firstName: nullableString,
    lastName: nullableString,
    photo: nullableString,
    displayName: z.string(),
  }),
})

const userSetupSchema = z.object({
  user: serverItemSchema.extend({
    firstName: nullableString,
    lastName: nullableString,
    photo: nullableString,
    displayName: nullableString,
    email: z.string().email(),
    goal: nullableNumber,
    twilioProxyAddress: nullableString,
    twilioProxyEmail: nullableString,
    username: z.string(),
    workDays: nullableNumber,
    deactivated: z.boolean(),
  }),
  clientCount: nullableNumber,
  groupMembers: z.array(groupMemberSchema),
  onboardingStatus: z.enum(['accepted', 'deactivated', 'not sent', 'sent']),
})

const addGroupMemberSchema = z
  .object({
    userId: z.string(),
    groupId: z.string({ required_error: 'You must select a store.' }),
  })
  .merge(groupMemberSchema.pick({ posId: true, role: true }))

const addUserSchema = z.object({
  firstName: z.string().min(1, { message: 'First Name is required' }),
  lastName: z.string().min(1, { message: 'Last Name is required' }),
  email: z
    .string()
    .email({ message: 'You must provide a valid email.' })
    .min(1, { message: 'Email is required' }),
  goal: z
    .string()
    .transform((data) => (data.trim() === '' ? null : parseFloat(data)))
    .nullable()
    .or(z.number().nullable()),
  workDays: z
    .string()
    .transform((data) => (data.trim() === '' ? null : parseFloat(data)))
    .nullable()
    .or(z.number().nullable()),
  displayName: z
    .string()
    .optional()
    .transform((x) => x || undefined),
  twilioProxyEmail: z
    .string()
    .optional()
    .transform((x) => x || undefined),
  groupMemberRoles: z
    .array(
      z.object({
        role: z.literal('associate').or(z.literal('admin')),
        groupIds: z.array(z.string()),
        posId: z
          .string()
          .optional()
          .transform((x) => x || undefined),
      })
    )
    .nonempty('You must select a Role for this user.')
    .refine((value) => value.every((val) => val.groupIds.length > 0), {
      message: 'Each role must have at least one store selected',
    }),
})

const updateUserSchema = serverItemSchema.merge(
  addUserSchema
    .omit({
      groupMemberRoles: true,
      posId: true,
      email: true,
    })
    .merge(
      z.object({
        deactivated: z
          .enum(['active', 'inactive'])
          .transform((v) => (v === 'inactive' ? true : false)),
      })
    )
)

const assignUserClientsReturn = z.object({
  count: z.number(),
})

export type {
  AddGroupMember,
  AddUser,
  GroupMember,
  UpdateUserInput,
  UserInput,
  UserSetup,
}
export {
  addGroupMemberSchema,
  addUserSchema,
  assignUserClientsReturn,
  groupMemberSchema,
  updateUserSchema,
  userSetupSchema,
}

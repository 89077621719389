import { useSmsCredits } from '../../../hooks/account/queries'

type Props = {
  creditsUsed: number
  isAdmin?: boolean
  isLoading?: boolean
}

function CreditsUsed({ creditsUsed, isLoading, isAdmin }: Props) {
  const smsCredits = useSmsCredits(isAdmin ? 'admin' : 'associate')

  return (
    <div className="text-center">
      <p className="text-ion-color-yellow">Credits Used: {creditsUsed}</p>
      <p className="text-ion-color-secondary">
        Credits Left After:{' '}
        {!smsCredits.data || isLoading
          ? '...'
          : `${(
              smsCredits.data?.smsCreditsRemaining - creditsUsed
            ).toLocaleString()}/${smsCredits.data?.smsCredits.toLocaleString()}`}
      </p>
    </div>
  )
}

export { CreditsUsed }

import { formatCurrency } from '../../utils/format-helpers'
import { composePosId } from '../utils'
import { EditManualBox } from './edit-manual-box'
import { EditReceiptBox } from './edit-receipt-box'

import type { RewardSet } from '../loyalty.schema'

type Props = {
  isAdmin?: boolean
  reward: RewardSet
  totalBoxes: number
}

function SixBoxCard({ isAdmin, reward, totalBoxes }: Props) {
  return (
    <div className="grid w-full grid-cols-3 gap-0.5 bg-white p-0.5">
      {Array.from(Array(totalBoxes).keys()).map((boxItem, index) => {
        const box = reward.rewardBoxes[index]

        return (
          <div key={boxItem} className="min-h-32 bg-slate-300">
            {box ? (
              <>
                {box.kind === 'receipt' ? (
                  <div className="text-ion-color-primary flex min-h-32 flex-col items-center justify-center gap-1">
                    <p className="text-xl font-semibold">
                      {formatCurrency(
                        box.receipts.reduce((a, c) => a + (c.value ?? 0), 0),
                        { abbreviate: true }
                      )}
                    </p>
                    <p className="text-sm">{box.dateString}</p>

                    <p className="text-sm">
                      {box.receipts.length > 1
                        ? `${box.receipts.length} transactions`
                        : composePosId(box.receipts[0].posId)}
                    </p>

                    {isAdmin && box.receipts.some((r) => r.canEdit) ? (
                      <div className="flex items-center justify-center">
                        <EditReceiptBox
                          id={box.receipts.map((r) => r.objectId).join(',')}
                          receipts={box.receipts}
                        />
                      </div>
                    ) : null}
                  </div>
                ) : box.kind === 'manual' ? (
                  <div className="text-ion-color-primary flex min-h-32 flex-col items-center justify-center">
                    <p className="text-xl font-semibold">
                      ${box.loyaltyPoint.value}
                    </p>
                    <p className="text-sm">{box.dateString}</p>
                    {isAdmin ? (
                      <div className="flex items-center justify-center">
                        <EditManualBox
                          id={box.loyaltyPoint.objectId}
                          loyaltyPoint={box.loyaltyPoint}
                        />
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </>
            ) : null}
          </div>
        )
      })}
    </div>
  )
}

export { SixBoxCard }

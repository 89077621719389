import * as React from 'react'
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react'
import { barcodeOutline } from 'ionicons/icons'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { ResourceType } from '@capawesome/capacitor-cloudinary'

import placeholder from '../../../resources/no-image-product.png'
import {
  usePostContent,
  updatePost,
  resetPost,
} from '../../../social-sharing/posts/post-store'
import {
  useCreateBaseLook,
  useCreatePhotoLook,
  useUpdateVideoLook,
} from '../../../hooks/shopwith/looks/mutations'
import { usePostProducts } from '../../../hooks/shopwith/products/queries'
import { useInfiniteData } from '../../../hooks/use-infinite-data'
import VideoPreview from '../../../components/cloudinary/video-preview'
import ProductItem from '../../../components/shopwith/product-item'
import CloseButton from '../../../components/ui/buttons/close'
import { TextInput } from '../../../components/ui/form/input'

import type { Product } from '../../../types/shopwith'
import { canScanSku, scanSku } from '../../../hooks/shopwith/utils'
import { useToast } from '../../../contexts/toastContext'

type FormState = {
  title: string
  isPrivate: boolean
  selectedProducts: Array<Product>
}

function CreateAssetPage() {
  const [searchTerm, setSearchTerm] = React.useState('')

  const { postMedia, products } = usePostContent()

  const productsQuery = usePostProducts(searchTerm)
  const [ionInfiniteScrollRef, loadMoreProducts] = useInfiniteData(
    productsQuery.fetchNextPage
  )
  const flatProducts = productsQuery.data?.pages.flatMap((x) => x.data)

  const createBaseLook = useCreateBaseLook()
  const createPhotoLook = useCreatePhotoLook()
  const updateVideoLook = useUpdateVideoLook()

  const [animateRef] = useAutoAnimate<HTMLUListElement>()
  const router = useIonRouter()

  const searchForm = useForm<{ search: string }>({
    defaultValues: { search: '' },
  })

  const postForm = useForm<FormState>({
    defaultValues: {
      title: '',
      isPrivate: false,
      selectedProducts: [],
    },
  })
  const { fields, prepend, remove } = useFieldArray({
    control: postForm.control,
    name: 'selectedProducts',
  })

  const isItemSelected = React.useCallback(
    function isItemSelected(item: string) {
      return postForm
        .getValues('selectedProducts')
        .map((p) => p.objectId)
        .includes(item)
    },
    [postForm]
  )

  const setToast = useToast()
  async function handleSkuScan() {
    const scan = await scanSku()
    if (!scan)
      return setToast({
        message: 'Something went wrong while scanning.',
        color: 'danger',
      })

    setSearchTerm(scan)
    searchForm.setValue('search', scan)
  }

  function handleProductClick(_: string, id: string) {
    const foundProduct = flatProducts?.find((p) => p.objectId === id)

    if (foundProduct && isItemSelected(foundProduct.objectId)) {
      const index = postForm
        .getValues()
        .selectedProducts.findIndex((p) => p.objectId === id)
      if (index > -1) remove(index)

      return
    }

    if (foundProduct) {
      prepend(foundProduct)

      updatePost({
        products: [
          ...products,
          {
            objectId: foundProduct.objectId,
            name: foundProduct.title,
            photo: foundProduct.featuredImage,
            brand: foundProduct.brand,
          },
        ],
      })
    }
  }

  function handleProductRemove(id: number) {
    remove(id)
  }

  async function handleCreateLook(data: FormState) {
    const { selectedProducts, ...formData } = data
    const products = selectedProducts.map((p) => p.objectId)

    if (postMedia?.type === 'video') {
      createBaseLook.mutate(
        {
          title: formData.title,
          isPrivate: formData.isPrivate,
          products,
          cloudinary: { resource_type: ResourceType.Video },
        },
        {
          onSuccess: (data) => {
            updateVideoLook.mutate({
              objectId: data.objectId,
              video: postMedia.video,
            })

            router.push(`/tabs/shopwith`, 'back', 'pop')
          },
        }
      )
    } else if (postMedia?.type === 'photo') {
      const image = postMedia.coverPhoto
        ? Object.values(postMedia.coverPhoto).every((x) => !!x)
          ? postMedia.coverPhoto
          : null
        : null

      createPhotoLook.mutate(
        {
          ...formData,
          image,
          products,
        },
        {
          onSuccess: () => {
            router.push(`/tabs/shopwith`, 'back', 'pop')
          },
        }
      )
    }
  }

  function handleSearch(data: { search: string }) {
    setSearchTerm(data.search)
  }

  function handleSearchClear() {
    setSearchTerm('')
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Add Products</IonTitle>
          <IonButtons slot="start">
            <IonButton
              color="secondary"
              onClick={() => {
                resetPost()
                router.push('/tabs/shopwith', 'back', 'pop')
              }}
            >
              Cancel
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton
              disabled={createPhotoLook.isLoading || createBaseLook.isLoading}
              color="yellow"
              onClick={postForm.handleSubmit(handleCreateLook)}
            >
              Create
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRow className="ion-padding-horizontal">
          <IonCol size="5" sizeLg="3">
            {postMedia?.type === 'video' ? (
              <div className="aspect-h-16 aspect-w-9 w-full">
                <VideoPreview video={postMedia.video} />
              </div>
            ) : postMedia?.type === 'photo' ? (
              <div className="aspect-h-4 aspect-w-3 w-full">
                <IonImg
                  src={postMedia.coverPhoto.pic}
                  className="object-cover object-top"
                />
              </div>
            ) : null}
          </IonCol>
          <IonCol size="7" sizeLg="5">
            <TextInput control={postForm.control} name="title" label="Title" />

            <div>
              <IonItem lines="none" style={{ '--padding-start': 0 }}>
                <Controller
                  name="isPrivate"
                  control={postForm.control}
                  render={({ field: { value, onChange } }) => (
                    <IonCheckbox
                      checked={value}
                      onIonChange={(e) => onChange(e.detail.checked)}
                      style={{ '--size': '1rem' }}
                    />
                  )}
                />
                <IonLabel>Make Asset Private</IonLabel>
              </IonItem>
            </div>
          </IonCol>
        </IonRow>
        <ul
          ref={animateRef}
          className="ion-padding-horizontal flex flex-wrap gap-2"
        >
          {fields.map((field, index) => (
            <li key={field.id} className="relative h-28 w-20">
              <IonImg
                src={field.featuredImage ?? placeholder}
                className="h-full object-cover"
              />
              <CloseButton inner onClick={() => handleProductRemove(index)} />
            </li>
          ))}
        </ul>

        <div>
          <IonRow className="ion-align-items-center ion-justify-content-center">
            <form
              className="flex-1"
              onSubmit={searchForm.handleSubmit(handleSearch)}
            >
              <Controller
                name="search"
                control={searchForm.control}
                render={({ field: { value, onChange } }) => (
                  <IonSearchbar
                    value={value}
                    onIonChange={onChange}
                    onIonClear={handleSearchClear}
                    debounce={0}
                  />
                )}
              />
            </form>
            {canScanSku ? (
              <div className="ion-padding-end">
                <IonButton
                  size="small"
                  fill="solid"
                  color="secondary"
                  onClick={handleSkuScan}
                >
                  <IonIcon icon={barcodeOutline} />
                </IonButton>
              </div>
            ) : null}
          </IonRow>

          <IonRow className="ion-padding-horizontal">
            {flatProducts?.length ? (
              flatProducts.map((product) => (
                <IonCol
                  key={product.objectId}
                  className="flex flex-col"
                  sizeXs="6"
                  sizeSm="4"
                  sizeMd="3"
                >
                  <ProductItem
                    product={product}
                    shouldHideDetail
                    onProductSelect={handleProductClick}
                    isSelected={postForm
                      .getValues('selectedProducts')
                      .map((p) => p.objectId)
                      .includes(product.objectId)}
                  />
                </IonCol>
              ))
            ) : flatProducts?.length === 0 ? (
              <p>Sorry. There were no products matching that search.</p>
            ) : productsQuery.isLoading ? (
              <IonLoading isOpen />
            ) : null}
          </IonRow>
          <IonInfiniteScroll
            ref={ionInfiniteScrollRef}
            onIonInfinite={loadMoreProducts}
            threshold="100px"
            disabled={!productsQuery.hasNextPage}
          >
            <IonInfiniteScrollContent
              loadingSpinner="bubbles"
              loadingText="loading more products..."
            />
          </IonInfiniteScroll>
        </div>
      </IonContent>
      <IonLoading
        isOpen={createPhotoLook.isLoading || createBaseLook.isLoading}
        message="Creating Asset..."
      />
    </IonPage>
  )
}

export default CreateAssetPage

import {
  IonButton,
  IonContent,
  IonLabel,
  IonPage,
  IonHeader,
  IonLoading,
} from '@ionic/react'
import { useRouteMatch } from 'react-router-dom'

import { useDynamicCallLists } from '../../hooks/outreach/queries'
import Avatar from '../../components/avatar'
import AdminHeader from '../../components/header/admin-header'
import { Card, CardContent } from '../../components/ui/card'
import { ListItem, ListItemHeading } from '../../components/ui/list-item'
import { StickyHeader } from '../../components/ui/sticky-header'

function DynamicListsPage() {
  const { url } = useRouteMatch()

  const dynamicCallListQuery = useDynamicCallLists()

  return (
    <IonPage>
      <IonHeader>
        <AdminHeader title="Dynamic Lists" showMenu />
      </IonHeader>
      <IonContent>
        {dynamicCallListQuery.data ? (
          <>
            {dynamicCallListQuery.data.length === 0 ? (
              <div className="flex h-full flex-col items-center justify-center">
                <div className="w-full max-w-lg">
                  <Card>
                    <CardContent>
                      <p>
                        You have not created any Dynamic Lists yet. Click the
                        button below to get started!
                      </p>
                      <IonButton
                        color="yellow"
                        fill="solid"
                        size="small"
                        routerLink="/admin/dynamic-lists/create"
                      >
                        Create
                      </IonButton>
                    </CardContent>
                  </Card>
                </div>
              </div>
            ) : (
              <>
                <StickyHeader>
                  <div className="ion-padding">
                    <IonButton
                      color="yellow"
                      fill="solid"
                      size="small"
                      routerLink="/admin/dynamic-lists/create"
                    >
                      Create
                    </IonButton>
                  </div>
                </StickyHeader>
                {dynamicCallListQuery.data.map((dynamicCallList) => (
                  <ListItem
                    key={dynamicCallList.objectId}
                    routerLink={`${url}/detail/${dynamicCallList.objectId}`}
                  >
                    <Avatar
                      user={{ firstName: 'One', lastName: 'Shop', photo: '' }}
                    />
                    <IonLabel className="ml-4">
                      <ListItemHeading>
                        <span>{dynamicCallList.displayName}</span>
                      </ListItemHeading>
                    </IonLabel>
                  </ListItem>
                ))}
              </>
            )}
          </>
        ) : (
          <IonLoading message="Loading Dynamic Lists..." isOpen />
        )}
      </IonContent>
    </IonPage>
  )
}

export { DynamicListsPage }

import {
  Camera,
  CameraResultType,
  CameraSource,
  Photo as CameraPhoto,
} from '@capacitor/camera'
import { isPlatform } from '@ionic/react'
import {
  Filesystem,
  Directory as FilesystemDirectory,
} from '@capacitor/filesystem'
import { z } from 'zod'

import { base64FromPath } from '../utils/base64-from-path'
import { useUser } from '../contexts/authContext'
import { useToast } from '../contexts/toastContext'

// TODO: POSSIBLY MOVE INTO SCHEMA FILE LATER?
export const photoDataSchema = z.object({
  contentType: z.string(),
  name: z.string(),
  base64String: z.string(),
  fileUri: z.string(),
  pic: z.string().optional(),
})

export type PhotoData = {
  contentType: string
  name: string
  base64String: string
  fileUri: string
  pic?: string
}

export function usePhoto() {
  const setToast = useToast()
  const user = useUser()

  function getImageName(format: string) {
    return `${user.objectId}_${new Date().getTime()}.${format}`
  }

  async function getBase64StringFromUrl(s3Uri: string) {
    let base64Data: string
    base64Data = await base64FromPath(s3Uri)
    return base64Data
  }

  async function getPhotoFromUrl(s3Uri: string) {
    const base64String = await getBase64StringFromUrl(s3Uri)
    const splitUri = s3Uri.split('.')
    const contentType = splitUri[splitUri.length - 1]

    const savedFile = await Filesystem.writeFile({
      path: getImageName(contentType), // grab last of uri
      data: base64String,
      directory: FilesystemDirectory.Data,
    })

    const trimmedBase64 = base64String.split(';base64,')[1] ?? base64String

    return {
      name: getImageName(contentType),
      pic: s3Uri,
      contentType,
      fileUri: savedFile.uri,
      base64String: trimmedBase64,
    }
  }

  async function getBase64String(image: CameraPhoto) {
    let base64Data: string | Blob

    if (isPlatform('hybrid')) {
      const file = await Filesystem.readFile({
        path: image.path!,
      })
      base64Data = file.data
    } else {
      base64Data = await base64FromPath(image.webPath!)
    }

    return base64Data
  }

  async function getPhotoFromDevice(source: CameraSource) {
    const image = await Camera.getPhoto({
      source,
      quality: 100,
      width: 640,
      resultType: CameraResultType.Uri,
      saveToGallery: true,
    })

    const base64String = await getBase64String(image)

    const savedFile = await Filesystem.writeFile({
      path: getImageName(image.format),
      data: base64String,
      directory: FilesystemDirectory.Data,
    })

    // exit early and warn user if image > 5MB (twilio will reject those)
    const file = await Filesystem.stat({ path: savedFile.uri })
    if (file.size > 4999999)
      return setToast({
        message: 'Sorry, but that image is too large to send via text.',
        duration: 0,
      })

    const trimmedBase64 =
      typeof base64String === 'string'
        ? base64String.includes(';base64')
          ? base64String.split(';base64,')[1]
          : base64String
        : base64String

    return {
      name: getImageName(image.format),
      pic: image.webPath,
      contentType: image.format,
      fileUri: savedFile.uri,
      base64String: trimmedBase64,
    }
  }

  async function selectPhotos(onSelectPhoto: (data: PhotoData) => void) {
    if (isPlatform('capacitor')) {
      try {
        const hasPermission = await Camera.checkPermissions()

        if (hasPermission.photos === 'denied') {
          setToast({
            message:
              'You must enable access to your Photos to enable this feature',
          })
          return
        }
      } catch (err) {
        console.error(err)
      }
    }

    try {
      const photoData = await getPhotoFromDevice(CameraSource.Photos)
      onSelectPhoto(photoData)
    } catch (err) {
      console.log(err)
    }
  }

  async function takePhoto(onTakePhoto: (data: PhotoData) => void) {
    if (isPlatform('capacitor')) {
      try {
        const hasPermission = await Camera.checkPermissions()

        if (hasPermission.camera === 'denied') {
          setToast({
            message:
              'You must enable access to your Camera to enable this feature',
          })
          return
        }
      } catch (err) {
        console.error(err)
      }
    }
    try {
      const photoData = await getPhotoFromDevice(CameraSource.Camera)
      onTakePhoto(photoData)
    } catch (err) {
      console.log(err)
    }
  }

  return {
    selectPhotos,
    takePhoto,
    getPhotoFromUrl,
  }
}

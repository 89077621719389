import { IonCol, IonContent, IonPage, IonRow } from '@ionic/react'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

import { useToast } from '../../contexts/toastContext'
import { useCreateHeartlandOptIn } from '../../integrations/heartland/mutations'
import { PhoneKeypadInput } from '../../components/ui/phone-keypad-input'

import type { CountryCode } from 'libphonenumber-js'

function HeartlandOptInPage() {
  const setToast = useToast()

  const optInClient = useCreateHeartlandOptIn()

  function handleSignUp({
    phoneNumber,
    country,
  }: {
    phoneNumber: string
    country: CountryCode
  }) {
    const parsedNumber = parsePhoneNumberFromString(phoneNumber, country)

    if (!parsedNumber?.number)
      return setToast({
        message: 'You have entered an invalid phone number.',
        color: 'danger',
      })

    optInClient.mutate(parsedNumber.number)
  }

  return (
    <IonPage>
      <IonContent className="ion-padding-horizontal">
        <IonRow className="h-full">
          <IonCol
            size="6"
            className="flex h-full flex-1 flex-col items-center justify-center space-y-6"
          >
            <p className="text-center text-3xl">
              Enter your phone number to join our VIP text list!
            </p>
          </IonCol>
          <IonCol
            size="6"
            className="flex h-full flex-1 flex-col items-center justify-center gap-6"
          >
            <PhoneKeypadInput onConfirm={handleSignUp} />
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  )
}

export default HeartlandOptInPage

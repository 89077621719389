import { IonContent, IonPage } from '@ionic/react'

import { useHeartlandParams } from '../../integrations/heartland/utils'

function HeartlandOptInSuccessPage() {
  const { posUrl } = useHeartlandParams()

  function redirectToPos() {
    if (posUrl) window.location.replace(posUrl)
  }

  return (
    <IonPage>
      <IonContent>
        <div className="relative h-full">
          <div className="size-full mx-auto flex max-w-xl flex-col items-center justify-center">
            <div className="flex flex-col items-center justify-center gap-y-20">
              <p className="text-5xl">You're Good To Go!</p>
              <p className="text-5xl">Return to Cashier</p>
            </div>
          </div>
          <button
            onClick={redirectToPos}
            color="secondary"
            className="border-ion-color-secondary text-ion-color-secondary fixed bottom-6 right-6 rounded-full border px-10 py-3 text-xl"
          >
            Back To POS
          </button>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default HeartlandOptInSuccessPage

import * as React from 'react'
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonLabel,
  useIonLoading,
} from '@ionic/react'
import { useForm, Controller } from 'react-hook-form'

import { getDefaultPhoneNumber } from '../../utils/client-helpers'
import { formatName } from '../../utils/format-helpers'
import { useToast } from '../../contexts/toastContext'
import { useSendOptInMessage } from '../../hooks/chat/mutations'
import { usePrimaryClientConversation } from '../../hooks/chat/queries'
import { TextArea } from '../ui/form/textarea'

function replacePlaceholders(str, client) {
  if (str === null || str === undefined) {
    return ''
  }
  var mapObj = {
    '<First Name>': formatName(client.firstName),
    '<Last Name>': formatName(client.lastName),
  }

  return str.replace(/<First Name>|<Last Name>/gi, function (matched) {
    return mapObj[matched]
  })
}

function SequenceSmsWithOptinForm({ currentClient, template, onMessageSend }) {
  const [presentLoading, dismissLoading] = useIonLoading()

  const { getConversationId } = usePrimaryClientConversation()
  const sendOptInMessageMutation = useSendOptInMessage()

  const { control, reset, handleSubmit, setValue, formState } = useForm({
    defaultValues: {
      optInMessage: replacePlaceholders(template.optInMessage, currentClient),
    },
    mode: 'onChange',
  })
  // const isDisabled = !Boolean(watch('optInMessage'))
  const { isValid } = formState

  const setToast = useToast()
  const defaultPhoneNumber = getDefaultPhoneNumber(currentClient.phoneNumbers)

  // reset the form when current client changes.
  React.useEffect(() => {
    reset({
      optInMessage: replacePlaceholders(template.optInMessage, currentClient),
    })
  }, [currentClient, reset, template.optInMessage])

  async function sendSequenceOneShopMessage(formState) {
    // Just in case - tho technically button should be disabled
    if (!defaultPhoneNumber || !defaultPhoneNumber.e164) {
      return setToast({
        message:
          'This client does not have a default phone number that can be messaged.',
      })
    }
    presentLoading({
      message: 'Sending Opt-In Link...',
    })

    try {
      const conversationId = await getConversationId(currentClient.objectId)
      if (!conversationId) {
        throw new Error(
          'Something went wrong setting up the conversation for this client.'
        )
      }

      await sendOptInMessageMutation.mutateAsync({
        conversationId,
        optInMessage: formState.optInMessage,
        client: currentClient,
      })
    } catch (error) {
      const errorMsg = error instanceof Error ? error.message : 'Unknown Error.'

      setToast({
        message: `Something went wrong while sending the opt-in message. ${errorMsg}`,
        color: 'danger',
      })
    } finally {
      dismissLoading()
    }
  }

  async function handleSendMessage(data) {
    const formState = { ...data, photos: template.photos }

    await sendSequenceOneShopMessage(formState)
    onMessageSend()
  }

  return (
    <>
      <IonCard key={currentClient} color="secondary">
        <IonCardContent>
          <div className="ion-margin-top">
            <div className="flex items-center justify-between">
              <IonLabel className="ion-align-self-auto" position="stacked">
                Opt-In Message (Link included):
              </IonLabel>
              <IonButton
                size="small"
                fill="outline"
                onClick={() => setValue('optInMessage', '')}
              >
                Clear
              </IonButton>
            </div>

            <Controller
              control={control}
              name="optInMessage"
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <TextArea
                  value={value}
                  onIonChange={onChange}
                  autoGrow
                  autocapitalize="on"
                />
              )}
            />
          </div>

          <div className="ion-margin-top ion-text-center">
            <IonButton
              disabled={!isValid || sendOptInMessageMutation.isLoading}
              color="yellow"
              onClick={handleSubmit(handleSendMessage)}
            >
              Send Message
            </IonButton>
          </div>
        </IonCardContent>
      </IonCard>
    </>
  )
}

export default SequenceSmsWithOptinForm

import { createAnimation, useIonAlert, useIonRouter } from '@ionic/react'

import { useCurrentSequence, useSequence } from '../stores/useSequence'
import type { Client } from './clients/queries'
import type { Template } from './templates/queries'

type Params = {
  backRef: string
  list: Array<Client>
  template?: Template | null
  callListId?: string
  callListDynamicId?: string
}

function useInitSequence() {
  const initialize = useSequence((s) => s.initializeSequence)
  const currentSequence = useCurrentSequence()

  const [present] = useIonAlert()
  const router = useIonRouter()

  const isInitialized = currentSequence.status === 'isInitialized'

  function initializeSequence({
    backRef,
    list,
    template,
    callListId,
    callListDynamicId,
  }: Params) {
    if (isInitialized) {
      present({
        cssClass: 'my-css',
        header: 'Replace exisitng outreach?',
        message:
          'You have not finished your last Outreach. Beginning a new one will erase your progress. Are you sure you want to continue?',
        buttons: [
          {
            text: 'Erase and Start New Outreach',
            handler: () => {
              initialize('currentSequence', {
                list,
                backRef,
                template: template?.smsTemplate,
                callListId,
                callListDynamicId,
              })
              // WORKAROUND - if just using "none" animation the history stack isn't respected - https://github.com/ionic-team/ionic-framework/issues/24260#issuecomment-1078960780
              router.push('/sequence', 'forward', 'push', {}, createAnimation)
            },
          },
          {
            text: 'Continue Last Outreach',
            handler: () => {
              // WORKAROUND - if just using "none" animation the history stack isn't respected - https://github.com/ionic-team/ionic-framework/issues/24260#issuecomment-1078960780
              router.push('/sequence', 'forward', 'push', {}, createAnimation)
            },
          },
          'Cancel',
        ],
      })
    } else {
      initialize('currentSequence', {
        list,
        backRef,
        template: template?.smsTemplate,
        callListDynamicId,
        callListId,
      })
      // WORKAROUND - if just using "none" animation the history stack isn't respected - https://github.com/ionic-team/ionic-framework/issues/24260#issuecomment-1078960780
      router.push('/sequence', 'forward', 'push', {}, createAnimation)
    }
  }

  return { initializeSequence }
}

export { useInitSequence }

import { IonContent, IonHeader, IonPage } from '@ionic/react'

import PostList from '../../social-sharing/posts/components/post-list'
import Header from '../../components/header'

function PostListPage() {
  return (
    <IonPage>
      <IonHeader>
        <Header title="All Posts" showMenu btn={null} />
      </IonHeader>
      <IonContent>
        <PostList />
      </IonContent>
    </IonPage>
  )
}

export default PostListPage

import produce from 'immer'

import { constants } from '../constants/authConstants'
import { searchService } from '../services/searchService'
import { authService } from '../services/authService'

export const authReducer = produce((draft, action) => {
  switch (action.type) {
    case 'SET_AUTH_USER':
      draft.auth.user = {
        ...action.user,
        displayName:
          action.user.displayName ||
          `${action.user.firstName} ${action.user.lastName}`,
      }
      draft.isLoggedIn = true
      break
    case 'CHECK_AUTH_STATE':
      const lsUser = window.localStorage.getItem('authUser')
      if (lsUser) {
        searchService.updateObject('_User', authService.getUserId(), {
          version: process.env.REACT_APP_VERSION,
        })

        const user = JSON.parse(lsUser)
        draft.auth.user = {
          ...user,
          displayName: user.displayName || `${user.firstName} ${user.lastName}`,
        }
        draft.isLoggedIn = true
        draft.isInitialized = true
      } else {
        draft.isLoggedIn = false
        draft.isInitialized = true
      }
      break
    case constants.AUTH_LOGIN_REQUEST:
      draft.login.loading = true
      draft.login.loggedIn = false
      draft.auth.user = action.user
      draft.message = ''
      break

    case constants.AUTH_LOGIN_SUCCESS:
      draft.isInitialized = true
      draft.isLoggedIn = true
      draft.login.loading = false
      draft.login.loggedIn = true
      draft.auth.user = {
        ...action.user,
        displayName:
          action.user.displayName ||
          `${action.user.firstName} ${action.user.lastName}`,
      }
      break

    case constants.AUTH_LOGIN_FAILURE:
      draft.login.loading = false
      draft.login.loggedIn = false
      draft.auth.user = {}
      draft.message = action.error
      break

    case constants.AUTH_LOGOUT:
      draft.isLoggedIn = false
      draft.isInitialized = true
      draft.login.loading = false
      draft.login.loggedIn = false
      draft.auth.user = {}
      break

    default:
      return
  }
})

import { useRef } from 'react'

import { useIsStore } from '../auth/utils'
import { ServerStatus } from '../schema/server-status.schema'

const SMS_MAX_CHARACTERS = 160
const SMS_WITH_EMOJI_MAX_CHARACTERS = 70

const FABRICBASH_MAX_CHARACTERS = 480
const FABRICBASH_WITH_EMOJI_MAX_CHARACTERS = 210

const FAHERTY_MAX_CHARACTERS = 640
const FAHERTY_WITH_EMOJI_MAX_CHARACTERS = 280

const GUYS_MAX_CHARACTERS = 640
const GUYS_WITH_EMOJI_MAX_CHARACTERS = 280

const SMPLANO_MAX_CHARACTERS = 480
const SMPLANO_EMOJI_MAX_CHARACTERS = 210

const UNO_MAX_CHARACTERS = 450
const UNO_EMOJI_MAX_CHARACTERS = 500

const LADDER_MAX_CHARACTERS = 800
const LADDER_EMOJI_MAX_CHARACTERS = 350

const MORLEY_MAX_CHARACTERS = 1600
const MORLEY_EMOJI_MAX_CHARACTERS = 1600

const PERIWINKLE_MAX_CHARACTERS = 1600
const PERIWINKLE_EMOJI_MAX_CHARACTERS = 1600

const PENELOPET_MAX_CHARACTERS = 480
const PENELOPET_EMOJI_MAX_CHARACTERS = 210

const SKIRT_MAX_CHARACTERS = 320
const SKIRT_EMOJI_MAX_CHARACTERS = 140

const TWB_MAX_CHARACTERS = 480
const TWB_EMOJI_MAX_CHARACTERS = 210

const SMS_BYPASS = 1000

function canEdit(status?: ServerStatus | null) {
  return status === 'draft' || status === 'pending'
}

async function insertPlaceholder(
  {
    placeholder,
    textAreaRef,
  }: {
    placeholder: string
    textAreaRef: React.RefObject<HTMLIonTextareaElement>
  },
  callback: (newValue: string) => void
) {
  if (!textAreaRef.current)
    throw new Error('The textarea was not initialized properly.')

  const textArea = await textAreaRef.current.getInputElement()

  const cursorPosition = textArea.selectionStart || 0
  const newPosition = cursorPosition + placeholder.length

  // split current value
  const start = textArea.value.slice(0, cursorPosition)
  const end = textArea.value.slice(cursorPosition)

  const newString = `${start}${placeholder}${end}`

  // callback to update textarea in ui
  callback(newString)

  await textAreaRef.current.setFocus()

  // reset cursor position after placeholder
  textArea.setSelectionRange(newPosition, newPosition)
}

const smartCharacters = `
\u00AB\u00BB\u201C\u201D\u02BA\u02EE\u201F\u275D\u275E\u301D\u301E\uFF02\u2018\u2019\u02BB\u02C8\u02BC\u02BD\u02B9\u201B
\uFF07\u00B4\u02CA\u0060\u02CB\u275B\u275C\u0313\u0314\uFE10\uFE11\u00F7\u00BC\u00BD\u00BE\u29F8\u0337\u0338\u2044\u2215
\uFF0F\u29F9\u29F5\u20E5\uFE68\uFF3C\u0332\uFF3F\u20D2\u20D3\u2223\uFF5C\u23B8\u23B9\u23D0\u239C\u239F\u23BC\u23BD\u2015
\uFE63\uFF0D\u2010\u2022\u2043\uFE6B\uFF20\uFE69\uFF04\u01C3\uFE15\uFE57\uFF01\uFE5F\uFF03\uFE6A\uFF05\uFE60\uFF06\u201A
\u0326\uFE50\u3001\uFE51\uFF0C\uFF64\u2768\u276A\uFE59\uFF08\u27EE\u2985\u2769\u276B\uFE5A\uFF09\u27EF\u2986\u204E\u2217
\u229B\u2722\u2723\u2724\u2725\u2731\u2732\u2733\u273A\u273B\u273C\u273D\u2743\u2749\u274A\u274B\u29C6\uFE61\uFF0A\u02D6
\uFE62\uFF0B\u3002\uFE52\uFF0E\uFF61\uFF10\uFF11\uFF12\uFF13\uFF14\uFF15\uFF16\uFF17\uFF18\uFF19\u02D0\u02F8\u2982\uA789
\uFE13\uFF1A\u204F\uFE14\uFE54\uFF1B\uFE64\uFF1C\u0347\uA78A\uFE66\uFF1D\uFE65\uFF1E\uFE16\uFE56\uFF1F\uFF21\u1D00\uFF22
\u0299\uFF23\u1D04\uFF24\u1D05\uFF25\u1D07\uFF26\uA730\uFF27\u0262\uFF28\u029C\uFF29\u026A\uFF2A\u1D0A\uFF2B\u1D0B\uFF2C
\u029F\uFF2D\u1D0D\uFF2E\u0274\uFF2F\u1D0F\uFF30\u1D18\uFF31\uFF32\u0280\uFF33\uA731\uFF34\u1D1B\uFF35\u1D1C\uFF36\u1D20
\uFF37\u1D21\uFF38\uFF39\u028F\uFF3A\u1D22\u02C6\u0302\uFF3E\u1DCD\u2774\uFE5B\uFF5B\u2775\uFE5C\uFF5D\uFF3B\uFF3D\u02DC
\u02F7\u0303\u0330\u0334\u223C\uFF5E\u00A0\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u200B\u202F
\u205F\u3000\uFEFF\u008D\u009F\u0080\u0090\u009B\u0010\u0009\u0000\u0003\u0004\u0017\u0019\u0011\u0012\u0013\u0014\u2017
\u2014\u2013\u201A\u202F\u2039\u203A\u203C\u201E\u201D\u201C\u201B\u2026\u2028\u2029\u205F\u2060
`

const gsmRegex = new RegExp(
  `^[A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!"#$%&'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC${smartCharacters}]*$`
)

function useSmsMaxCharacterCount() {
  const {
    isFabricBash,
    isFaherty,
    isGuysAndCo,
    isUno,
    isQ,
    isLadder,
    isMorley,
    isPenelopeT,
    isPeriwinkle,
    isScoutMollysPlano,
    isSharlas,
    isSkirt,
    isTwb,
  } = useIsStore()

  function getSmsMaxCharacterCount(text: string) {
    if (isFabricBash()) {
      return gsmRegex.test(text)
        ? FABRICBASH_MAX_CHARACTERS
        : FABRICBASH_WITH_EMOJI_MAX_CHARACTERS
    }

    if (isFaherty()) {
      return gsmRegex.test(text)
        ? FAHERTY_MAX_CHARACTERS
        : FAHERTY_WITH_EMOJI_MAX_CHARACTERS
    }

    if (isGuysAndCo())
      return gsmRegex.test(text)
        ? GUYS_MAX_CHARACTERS
        : GUYS_WITH_EMOJI_MAX_CHARACTERS

    if (isUno()) {
      return gsmRegex.test(text) ? UNO_MAX_CHARACTERS : UNO_EMOJI_MAX_CHARACTERS
    }

    if (isLadder()) {
      return gsmRegex.test(text)
        ? LADDER_MAX_CHARACTERS
        : LADDER_EMOJI_MAX_CHARACTERS
    }

    if (isMorley()) {
      return gsmRegex.test(text)
        ? MORLEY_MAX_CHARACTERS
        : MORLEY_EMOJI_MAX_CHARACTERS
    }

    if (isPenelopeT()) {
      return gsmRegex.test(text)
        ? PENELOPET_MAX_CHARACTERS
        : PENELOPET_EMOJI_MAX_CHARACTERS
    }

    if (isPeriwinkle())
      return gsmRegex.test(text)
        ? PERIWINKLE_MAX_CHARACTERS
        : PERIWINKLE_EMOJI_MAX_CHARACTERS

    if (isScoutMollysPlano()) {
      return gsmRegex.test(text)
        ? SMPLANO_MAX_CHARACTERS
        : SMPLANO_EMOJI_MAX_CHARACTERS
    }

    if (isSkirt()) {
      return gsmRegex.test(text)
        ? SKIRT_MAX_CHARACTERS
        : SKIRT_EMOJI_MAX_CHARACTERS
    }

    if (isTwb())
      return gsmRegex.test(text) ? TWB_MAX_CHARACTERS : TWB_EMOJI_MAX_CHARACTERS

    if (isQ() || isSharlas()) {
      return SMS_BYPASS
    }

    return gsmRegex.test(text)
      ? SMS_MAX_CHARACTERS
      : SMS_WITH_EMOJI_MAX_CHARACTERS
  }
  return { getSmsMaxCharacterCount }
}

function useTextAreaRef() {
  return useRef<HTMLIonTextareaElement>(null)
}

export { canEdit, insertPlaceholder, useSmsMaxCharacterCount, useTextAreaRef }

import * as React from 'react'

import { useUser } from '../../contexts/authContext'
import type { ShopWithIds } from '../../hooks/templates/mutations'

function useShopWithLinks() {
  const user = useUser()

  const generateShopWithLinkPreview = React.useCallback(
    (type: keyof ShopWithIds, ids: Array<string>) => {
      let link: string | undefined

      switch (type) {
        case 'collectionIds':
          link = `https://oneshop.me/collection/${ids[0]}?stylist=${user.objectId}`
          break
        case 'lookIds':
          link =
            ids.length > 1
              ? `https://oneshop.me/collection?looks=${ids.join()}&stylist=${
                  user.objectId
                }`
              : `https://oneshop.me/look/${ids[0]}?stylist=${user.objectId}`
          break
        case 'productIds':
          link =
            ids.length > 1
              ? `https://oneshop.me/look?products=${ids.join()}&stylist=${
                  user.objectId
                }`
              : `https://oneshop.me/product/${ids[0]}?stylist=${user.objectId}`
          break
      }

      return link
    },
    [user.objectId]
  )

  return { generateShopWithLinkPreview }
}

export { useShopWithLinks }

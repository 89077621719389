import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSearchbar,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { addCircle } from 'ionicons/icons'

import { useToast } from '../../contexts/toastContext'
import { useUpdateClientWishlist } from '../../hooks/clients/mutations'
import { useClientSearch } from '../../hooks/clients/queries'
import { useBoolean } from '../../hooks/use-boolean'
import { useGlobalSearch } from '../../hooks/use-global-search'
import { MessageCard } from './message-card'
import { useWishlistSearch } from '../utils'

import type { SearchClient } from '../../hooks/clients/queries'

function WishlistButton({
  disabled,
  products,
}: {
  disabled: boolean
  products: Array<string>
}) {
  const [isOpen, { off: closeModal, on: openModal }] = useBoolean()

  return (
    <>
      <IonButton
        fill="outline"
        size="small"
        expand="block"
        disabled={disabled}
        color="yellow"
        onClick={openModal}
        className="uppercase tracking-tight"
      >
        Add To Wishlist
        <IonIcon icon={addCircle} slot="end" />
      </IonButton>

      <IonModal isOpen={isOpen} onDidDismiss={closeModal}>
        <WishlistModal onClose={closeModal} products={products} />
      </IonModal>
    </>
  )
}

function WishlistModal({
  products,
  onClose,
}: {
  products: Array<string>
  onClose: () => void
}) {
  const {
    searchString,
    selectedClient,
    handlers: { updateWishlistSearch, resetSearch },
  } = useWishlistSearch()
  const foundClientName =
    selectedClient && `${selectedClient.firstName} ${selectedClient.lastName}`

  const canSearchAll = useGlobalSearch()

  const { data: clients, isInitialLoading } = useClientSearch(searchString, {
    canSearchAll,
    enabled:
      !!searchString && (!foundClientName || foundClientName !== searchString),
  })
  const updateWishlist = useUpdateClientWishlist()

  const setToast = useToast()

  function addToWishlist() {
    if (!selectedClient?.objectId)
      return setToast({
        message: 'You have not selected a client.',
        color: 'danger',
      })
    updateWishlist.mutate(
      {
        clientId: selectedClient?.objectId,
        productIds: products,
      },
      {
        onSuccess: onClose,
      }
    )
  }

  function handleSelectSearchResult(id: string) {
    // nab that client
    let client: SearchClient | undefined

    if (clients) {
      client = clients.find((client) => client.objectId === id)
      if (!client) {
        return setToast({
          message: 'Something went wrong loading the selected client.',
          color: 'danger',
        })
      }
      updateWishlistSearch({
        searchString: `${client?.firstName} ${client?.lastName}`,
        client,
      })
    }
  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Add Products To Wishlist</IonTitle>
          <IonButtons slot="start">
            <IonButton color="secondary" onClick={onClose}>
              CANCEL
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonSearchbar
          debounce={500}
          className="rounded-full"
          color="dark"
          type="search"
          placeholder="Find A Client"
          showCancelButton="never"
          value={searchString}
          onIonChange={(e) =>
            updateWishlistSearch({ searchString: e.detail.value })
          }
          onIonClear={resetSearch}
        />
        {isInitialLoading ? (
          <div className="ion-text-center ion-padding">
            <IonSpinner />
          </div>
        ) : clients?.length ? (
          <IonList inset>
            {clients.map((selectedClient) => (
              <IonItem
                button
                color="secondary"
                key={selectedClient.objectId}
                onClick={() =>
                  handleSelectSearchResult(selectedClient.objectId)
                }
              >
                <IonLabel>
                  {selectedClient.firstName} {selectedClient.lastName}
                </IonLabel>
              </IonItem>
            ))}
          </IonList>
        ) : null}

        <div className="ion-padding">
          {!selectedClient ? (
            <MessageCard>
              Please search for a client to send this to.
            </MessageCard>
          ) : null}
        </div>
      </IonContent>
      <IonFooter className="ion-padding">
        <div className="ion-margin-top ion-text-center">
          <IonButton
            type="submit"
            expand="block"
            color="yellow"
            disabled={!selectedClient}
            onClick={addToWishlist}
          >
            Add To Wishlist
          </IonButton>
        </div>
      </IonFooter>
    </>
  )
}

export { WishlistButton }

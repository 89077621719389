type Props = {
  lifetimeLoyaltyVisits: number
  pointsBalance: number
  visitsUntilReward: number
}

function LoyaltyDetailInfo({
  lifetimeLoyaltyVisits,
  pointsBalance,
  visitsUntilReward,
}: Props) {
  return (
    <div className="text-center">
      <h2 className="text-3xl font-semibold">{`${pointsBalance} Points`}</h2>
      <p>{lifetimeLoyaltyVisits} Lifetime Visits</p>
      <p className="text-xl">
        {visitsUntilReward > 0
          ? `Visits Until Reward: ${visitsUntilReward}`
          : null}
      </p>
    </div>
  )
}

export { LoyaltyDetailInfo }

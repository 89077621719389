import { IonContent, IonHeader, IonLoading, IonPage } from '@ionic/react'
import { useParams } from 'react-router-dom'

import Header from '../../components/header'
import { useUpdateLoyaltyProgram } from '../../loyalty/mutations'
import { useLoyaltyProgram } from '../../loyalty/queries'
import { LoyaltyProgramsForm } from '../../loyalty/components/loyalty-programs-form'

import type { LoyaltyProgramFormState } from '../../loyalty/loyalty.schema'
import CurrentStatus from '../../automations/components/current-status'

function LoyaltyProgramsDetailPage() {
  const { loyaltyId } = useParams<{ loyaltyId: string }>()

  const loyaltyDetail = useLoyaltyProgram(loyaltyId)

  const { publishLoyaltyProgram, saveLoyaltyProgramDraft, stopLoyaltyProgram } =
    useUpdateLoyaltyProgram()

  const isMutating =
    publishLoyaltyProgram.isLoading ||
    saveLoyaltyProgramDraft.isLoading ||
    stopLoyaltyProgram.isLoading
  const isDisabled = loyaltyDetail.data?.state === 'active' || isMutating

  function handlePublish(data: LoyaltyProgramFormState) {
    if (!loyaltyDetail.data?.objectId) return
    publishLoyaltyProgram.mutate({
      objectId: loyaltyDetail.data.objectId,
      ...data,
    })
  }
  function saveDraft(data: LoyaltyProgramFormState) {
    if (!loyaltyDetail.data?.objectId) return

    saveLoyaltyProgramDraft.mutate({
      objectId: loyaltyDetail.data.objectId,
      ...data,
    })
  }
  return (
    <IonPage>
      <IonHeader>
        <Header
          btn={null}
          title="Loyalty Program"
          backRef="/admin/loyalty-programs"
        />
      </IonHeader>
      <IonContent className="ion-padding">
        {loyaltyDetail.data ? (
          <>
            <div className="flex justify-end">
              <CurrentStatus status={loyaltyDetail.data.state} />
            </div>
            <LoyaltyProgramsForm
              currentState={loyaltyDetail.data.state}
              defaultValues={{
                ...loyaltyDetail.data,
                programStyle:
                  loyaltyDetail.data.settings?.visits !== 0 ? 'box' : 'points',
              }}
              isDisabled={isDisabled}
              onSave={saveDraft}
              onStop={() =>
                stopLoyaltyProgram.mutate(loyaltyDetail.data.objectId)
              }
              onPublish={handlePublish}
            />

            <IonLoading
              isOpen={isMutating}
              message="Updating Loyalty Program"
            />
          </>
        ) : (
          <IonLoading isOpen message="Loading Loyalty Program..." />
        )}
      </IonContent>
    </IonPage>
  )
}

export default LoyaltyProgramsDetailPage

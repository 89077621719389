import { IonButton, IonIcon } from '@ionic/react'
import { chatbubbleEllipses } from 'ionicons/icons'

import { useInboxUnreadCount } from '../../hooks/chat/queries'
import Badge from '../Badge'

function ChatBubble() {
  const unreadCountQuery = useInboxUnreadCount()

  return (
    <IonButton
      style={{
        '--padding-start': 0,
        '--padding-end': 0,
        '--overflow': 'initial',
      }}
      className="relative"
      routerLink="/chat/inbox"
      color="secondary"
    >
      <IonIcon slot="icon-only" icon={chatbubbleEllipses} />
      {unreadCountQuery.data?.unreadConversationCount ? (
        <Badge>{unreadCountQuery.data.unreadConversationCount}</Badge>
      ) : null}
    </IonButton>
  )
}

export default ChatBubble

import { IonSkeletonText, IonText } from '@ionic/react'
import { Link, useRouteMatch } from 'react-router-dom'

import { isNewClient } from '../../utils/client-helpers'
import { getFullName } from '../../utils/format-helpers'
import { Client } from '../../hooks/clients/queries'
import ScrollingList from '../../components/ui/scrolling-list'
import Avatar from '../avatar'
import Badge from '../Badge'

function ScrollingClientList({ list }: { list: Array<Client> }) {
  const { url } = useRouteMatch()

  return (
    <ScrollingList asChild gap="lg">
      <ul>
        {list.map((client) => {
          const fullName = getFullName(client)
          return (
            <li key={client.objectId}>
              <div className="relative flex w-16 flex-col items-center justify-center">
                <Link to={`${url}/client/${client.objectId}`}>
                  <Avatar user={client} size="lg" />
                  <p className="mt-1 text-center text-sm leading-tight">
                    <IonText color="secondary">{fullName}</IonText>
                  </p>
                  {isNewClient(client) && <Badge>New</Badge>}
                </Link>
              </div>
            </li>
          )
        })}
      </ul>
    </ScrollingList>
  )
}

function ScrollingClientListSkeleton() {
  return (
    <div className="ml-3 flex">
      {Array(10)
        .fill(null)
        .map((_i, idx) => (
          <div
            key={idx}
            className="mr-4 flex flex-col items-center justify-center"
          >
            <div className="border-ion-color-secondary size-16 flex flex-col items-center justify-center rounded-full border">
              <IonSkeletonText animated className="size-full m-0" />
            </div>
            <p className="mx-auto mt-1 w-3/4 text-center text-xs">
              <IonSkeletonText className="m-0 w-full" />
            </p>
          </div>
        ))}
    </div>
  )
}

export { ScrollingClientList, ScrollingClientListSkeleton }

import { forwardRef } from 'react'
import { IonModal, createAnimation } from '@ionic/react'

type Props = React.PropsWithChildren<React.ComponentProps<typeof IonModal>>

function enterAnimation(baseEl: HTMLElement) {
  const root = baseEl.shadowRoot

  const backdropAnimation = createAnimation()
    .addElement(root?.querySelector('ion-backdrop')!)
    .fromTo('opacity', '0.01', 'var(--backdrop-opacity)')
    .duration(200)

  const wrapperAnimation = createAnimation()
    .addElement(root?.querySelector('.modal-wrapper')!)
    .keyframes([
      { offset: 0, opacity: '0', transform: 'scale(1)' },
      { offset: 1, opacity: '0.99', transform: 'scale(1)' },
    ])
    .duration(300)

  return createAnimation()
    .addElement(baseEl)
    .easing('ease-out')
    .duration(500)
    .addAnimation([backdropAnimation, wrapperAnimation])
}

function leaveAnimation(baseEl: HTMLElement) {
  return enterAnimation(baseEl).direction('reverse')
}

const LoyaltyModal = forwardRef<HTMLIonModalElement, Props>(
  function LoyaltyModal({ children, ...props }, ref) {
    return (
      <IonModal
        style={{
          '--width': 'fit-content',
          '--min-width': '250px',
          '--height': 'fit-content',
          '--border-radius': '6px',
          '--box-shadow': '0 28px 48px rgba(0, 0, 0, 0.4)',
        }}
        id="example-modal"
        ref={ref}
        {...props}
        enterAnimation={enterAnimation}
        leaveAnimation={leaveAnimation}
      >
        {children}
      </IonModal>
    )
  }
)

export { LoyaltyModal }

import { IonButton } from '@ionic/react'
import * as React from 'react'

import { authService } from '../../services/authService'

export default function IntegrationButton({
  children,
  href,
  integration,
  authState,
  groupId,
}: {
  children: React.ReactNode
  href: string
  integration: string
  groupId: string
  authState?: string
}) {
  return (
    <IonButton
      fill="outline"
      color="secondary"
      href={encodeURI(href)}
      onClick={() => {
        authService.saveOAuthParams({
          integration,
          authState,
          groupId,
        })
      }}
    >
      {children}
    </IonButton>
  )
}

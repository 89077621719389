import * as React from 'react'
import { useToggle } from 'rooks'

type Props = {
  children: string
  characterLimit?: number
}

const TRIM_LENGTH = 120

function formatText(str: string) {
  return str?.split('\n').map((item, idx) => {
    const l = str.split('\n').length ?? 0

    if (idx === l - 1) return <React.Fragment key={idx}>{item}</React.Fragment>
    return (
      <React.Fragment key={idx}>
        {item}
        <br />
      </React.Fragment>
    )
  })
}

function composeText(str: string, limit: number) {
  let temp = str

  const newLineIndex = str?.indexOf('\n')

  if (newLineIndex > -1) temp = temp.substring(0, str.indexOf('\n'))

  return temp.substring(0, (temp + ' ').lastIndexOf(' ', limit))
}

function ExpandableText({ children, characterLimit = TRIM_LENGTH }: Props) {
  const [showMore, toggleShowMore] = useToggle(false)

  const shortText = composeText(children, characterLimit)

  return (
    <div className="leading-tight">
      <div className="inline leading-[14px] leading-tight">
        {showMore ? formatText(children) : formatText(shortText)}
      </div>
      {shortText.length === children.length ||
      (children?.indexOf('\n') < -1 &&
        characterLimit >= children.length) ? null : (
        <div
          role="button"
          className="ml-1.5 inline cursor-pointer leading-tight text-gray-500"
          onClick={toggleShowMore}
        >
          {showMore ? 'less' : '...more'}
        </div>
      )}
    </div>
  )
}

export default ExpandableText

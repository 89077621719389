import { IonContent, IonHeader, IonPage } from '@ionic/react'

import CreateOneTimeOutreachForm from '../../../components/outreach/create-onetime-outreach-form'
import AdminHeader from '../../../components/header/admin-header'

function CreateOutreach() {
  return (
    <IonPage>
      <IonHeader>
        <AdminHeader title="Create Outreach List" backRef="/admin/campaigns" />
      </IonHeader>
      <IonContent className="ion-padding">
        <CreateOneTimeOutreachForm />
      </IonContent>
    </IonPage>
  )
}

export default CreateOutreach

import { IonContent, IonHeader, IonLoading, IonPage } from '@ionic/react'
import { useParams } from 'react-router-dom'

import { useDynamicCallListDetail } from '../../../hooks/outreach/queries'
import Header from '../../../components/header'
import UpdateDynamicOutreachForm from '../../../components/outreach/update-dynamic-outreach-form'

type Props = {
  backRef: string
}

function AdminDynamicOutreachDetail({ backRef }: Props) {
  const { outreachId } = useParams<{ outreachId: string }>()

  const dynamicCallListDetailQuery = useDynamicCallListDetail(outreachId)
  const dynamicDetail = dynamicCallListDetailQuery.data

  return (
    <IonPage>
      <IonHeader>
        <Header title="Outreach" backRef={backRef} />
      </IonHeader>
      <IonContent>
        {dynamicDetail ? (
          <UpdateDynamicOutreachForm dynamicCallList={dynamicDetail} />
        ) : dynamicCallListDetailQuery.error ? (
          <div className="ion-padding">
            <p>
              {dynamicCallListDetailQuery.error instanceof Error
                ? dynamicCallListDetailQuery.error.message
                : 'An unknown error occurred.'}
            </p>
          </div>
        ) : (
          <IonLoading isOpen message={'Loading outreach...'} />
        )}
      </IonContent>
    </IonPage>
  )
}

export default AdminDynamicOutreachDetail

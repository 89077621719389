import * as React from 'react'
import {
  IonButton,
  IonItem,
  IonLabel,
  IonModal,
  IonSearchbar,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonContent,
  IonTitle,
  IonNote,
  IonSpinner,
} from '@ionic/react'
import { useVirtualizer } from '@tanstack/react-virtual'
import { useImmerReducer } from 'use-immer'
import { UseQueryResult } from '@tanstack/react-query'

type State = {
  checkedItems: Array<string>
  searchTerm: string
}

type Actions =
  | { type: 'cancel'; payload: Array<string> }
  | { type: 'select all'; payload: Array<string> }
  | { type: 'deselect all'; payload: Array<string> }
  | { type: 'update search'; payload: { text: string } }
  | {
      type: 'update checked items'
      payload: { checked: boolean; value: string }
    }
  | { type: 'reset' }

function reducer(draft: State, action: Actions) {
  switch (action.type) {
    case 'cancel':
      draft.checkedItems = action.payload
      draft.searchTerm = ''
      break

    case 'update search':
      draft.searchTerm = action.payload.text
      break

    case 'update checked items':
      const { checked, value } = action.payload

      if (checked) {
        draft.checkedItems.push(value)
      } else {
        let index
        index = draft.checkedItems.findIndex((item) => item === value)

        if (index !== -1) draft.checkedItems.splice(index, 1)
      }
      break

    case 'deselect all':
      const f = draft.checkedItems.filter((i) => !action.payload.includes(i))
      draft.checkedItems = f
      break

    case 'select all':
      const arr = draft.checkedItems.map((i) => i)
      arr.push(...action.payload)

      draft.checkedItems = Array.from(new Set(arr))

      break

    case 'reset':
      draft.checkedItems = []
      draft.searchTerm = ''
      break
    default:
      throw new Error()
  }
}

function AsyncSearchSelect({
  label,
  selectedItems,
  setSelectedItems,
  isAdmin = false,
  useSearchQuery,
}: {
  label: string
  selectedItems: Array<string>
  setSelectedItems: (items: Array<string>) => void
  isAdmin?: boolean
  useSearchQuery: (
    searchTerm: string,
    { admin }: { admin?: boolean }
  ) => UseQueryResult<Array<string>, unknown>
}) {
  const parentRef = React.useRef<HTMLDivElement>(null)

  const [showModal, setShowModal] = React.useState(false)

  const [{ checkedItems, searchTerm }, dispatch] = useImmerReducer<
    State,
    Actions
  >(reducer, {
    checkedItems: selectedItems,
    searchTerm: '',
  })

  const searchQuery = useSearchQuery(searchTerm, { admin: isAdmin })
  const items = searchQuery.data ?? []

  const rowVirtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 35,
    overscan: 5,
  })

  React.useEffect(() => {
    if (selectedItems.length === 0) {
      dispatch({ type: 'reset' })
    }
  }, [dispatch, selectedItems.length])

  function handleItemsSelect(e: React.FormEvent<HTMLInputElement>) {
    const { checked, value } = e.currentTarget

    dispatch({ type: 'update checked items', payload: { checked, value } })
  }

  function handleModalCancel() {
    dispatch({ type: 'cancel', payload: selectedItems })
    setShowModal(false)
  }

  function handleModalConfirm() {
    dispatch({ type: 'update search', payload: { text: '' } })
    setSelectedItems(checkedItems)
    setShowModal(false)
  }

  function handleSelectAll() {
    dispatch({ type: 'select all', payload: items })
  }

  function handleDeselectAll() {
    dispatch({ type: 'deselect all', payload: items })
  }

  function getItemNames() {
    const names: Array<string> = []
    selectedItems.forEach((item: any) => {
      names.push(item.name ?? item)
    })
    return [...names]
      .sort((a, b) =>
        a.localeCompare(b, undefined, {
          numeric: true,
          ignorePunctuation: true,
        })
      )
      .join(', ')
  }

  return (
    <>
      <IonItem button color="secondary" onClick={() => setShowModal(true)}>
        <div tabIndex={0} />
        <IonLabel>{label}</IonLabel>
        <IonNote>{selectedItems.length ? getItemNames() : ''}</IonNote>
      </IonItem>

      <IonModal isOpen={showModal} onDidDismiss={handleModalCancel}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Search {label}</IonTitle>
          </IonToolbar>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                fill="outline"
                color="secondary"
                onClick={handleModalCancel}
              >
                Cancel
              </IonButton>
            </IonButtons>
            <IonSearchbar
              placeholder="Start typing to see options..."
              value={searchTerm}
              debounce={500}
              onIonChange={(e) =>
                dispatch({
                  type: 'update search',
                  payload: { text: e.detail.value! },
                })
              }
            />
            <IonButtons slot="end">
              <IonButton
                fill="outline"
                color="secondary"
                onClick={() => dispatch({ type: 'reset' })}
              >
                Reset
              </IonButton>

              <IonButton
                fill="solid"
                color="yellow"
                onClick={handleModalConfirm}
              >
                OK
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <div ref={parentRef} className="h-full overflow-auto">
            <div
              className="relative w-full"
              style={{ height: `${rowVirtualizer.getTotalSize()}px` }}
            >
              {searchQuery.isInitialLoading ? (
                <div className="mt-5 flex items-center justify-center">
                  <IonSpinner />
                </div>
              ) : (
                <>
                  {Boolean(searchQuery.data?.length) ? (
                    <div className="ion-padding flex justify-end gap-x-3">
                      <IonButton
                        size="small"
                        color="yellow"
                        fill="outline"
                        onClick={handleSelectAll}
                      >
                        Select All
                      </IonButton>
                      <IonButton
                        size="small"
                        color="secondary"
                        fill="outline"
                        onClick={handleDeselectAll}
                      >
                        Deselect All
                      </IonButton>
                    </div>
                  ) : null}
                  <div className="relative">
                    {rowVirtualizer.getVirtualItems().map((virtualRow) => (
                      <div
                        key={virtualRow.key}
                        data-index={virtualRow.index}
                        className="ion-padding-horizontal border-ion-color-tertiary absolute left-0 top-0 flex w-full items-center justify-between border-b"
                        style={{
                          transform: `translateY(${virtualRow.start}px)`,
                        }}
                      >
                        <p>{items[virtualRow.index]}</p>
                        {/* <IonCheckbox
                          // key={}
                          name="checkbox"
                          value={items[virtualRow.index]}
                          checked={checkedItems.includes(
                            items[virtualRow.index]
                          )}
                          onIonChange={(e) => {
                            console.log(
                              JSON.stringify(e.detail.value) ===
                                JSON.stringify(items[virtualRow.index])
                            )
                            handleItemsSelect(e)
                          }}
                        /> */}
                        <div className="flex h-6 items-center">
                          <input
                            type="checkbox"
                            className="text-ion-color-primary size-6 form-checkbox rounded-full border-gray-300"
                            name="checkbox"
                            value={items[virtualRow.index]}
                            checked={checkedItems.includes(
                              items[virtualRow.index]
                            )}
                            onChange={(e) => {
                              handleItemsSelect(e)
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </IonContent>
      </IonModal>
    </>
  )
}

export { AsyncSearchSelect }

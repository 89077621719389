import { z } from 'zod'

import {
  isoDateSchema,
  makePaginatedServerResponseSchema,
  makeServerResponseSchema,
  nullableArray,
  nullableString,
  serverItemSchema,
} from '../../utils/schema'
import { profileBaseSchema } from '../../schema/profile.schema'
import {
  ayrshareOverviewSchema,
  ayrshareErrorSchema,
  ayrsharePostIdSchema,
  ayrshareSocials,
} from '../../ayrshare/ayrshare.schema'
import { lookOverviewSchema } from '../../shopwith/schema/look.schema'
import { oneshopStatuses } from '../../constants/misc'

type PostOverview = z.infer<typeof postOverviewSchema>
type PostAnalytics = z.infer<typeof postAnalyticsSchema>
type PostPlatforms = z.infer<typeof platformsSchema>
type CreatePostInput = z.infer<typeof createPostInputSchema>
type ShareAssetInput = z.infer<typeof shareAssetInputSchema>

const postPlatforms = [...ayrshareSocials, 'oneshop'] as const
const platformsSchema = z.enum(postPlatforms)

const createPostInputSchema = z.object({
  description: z.string().min(1),
  date: z.string(),
  selectedChannels: z.record(z.string(), z.array(platformsSchema)),
  isPublic: z.boolean(),
  selectedProducts: z.array(z.any()), // TODO: SPECIFY PRODUCT SCHEMA
})

const shareAssetInputSchema = createPostInputSchema.omit({
  isPublic: true,
  selectedProducts: true,
})

const analyticsSchema = z.object({
  created: z.string(),
  name: z.string(),
  nonPublicMetrics: z.object({
    userProfileClicks: z.number(),
    impressionCount: z.number(),
    video: z
      .object({
        playback100Count: z.number(),
        playback25Count: z.number(),
        playback0Count: z.number(),
        playback50Count: z.number(),
        playback75Count: z.number(),
      })
      .optional(),
  }),
  organicMetrics: z.object({
    likeCount: z.number(), //
    impressionCount: z.number(),
    replyCount: z.number(),
    retweetCount: z.number(),
    userProfileClicks: z.number(),
    video: z
      .object({
        playback0Count: z.number(),
        playback50Count: z.number(),
        playback100Count: z.number(),
        viewCount: z.number(),
        playback25Count: z.number(),
        playback75Count: z.number(),
      })
      .optional(),
  }),
  post: z.string(),
  publicMetrics: z.object({
    retweetCount: z.number(),
    quoteCount: z.number(),
    likeCount: z.number(),
    replyCount: z.number(),
  }),
  username: z.string(),
})

const postAnalyticsSchema = z.object({
  twitter: z
    .object({
      id: z.string(),
      postUrl: z.string(),
      analytics: analyticsSchema,
      lastUpdated: z.string(),
      nextUpdate: z.string(),
    })
    .optional(),
  status: z.string(),
  id: z.string(),
})

const postOverviewSchema = serverItemSchema.extend({
  ayrshare: ayrshareOverviewSchema.nullable(),
  ayrshareErrors: nullableArray(ayrshareErrorSchema),
  ayrshareId: nullableString,
  ayrsharePostIds: nullableArray(ayrsharePostIdSchema),
  createdAt: isoDateSchema,
  date: isoDateSchema.nullable(),
  look: lookOverviewSchema,
  platforms: z.array(platformsSchema),
  post: z.string(),
  profileLook: serverItemSchema.nullable(), // TODO: SPECIFIC PROFILE LOOK SCHEMA
  requiresApproval: z.boolean(),
  status: z.enum(oneshopStatuses),
  user: profileBaseSchema,
})

const fetchPostResponseSchema = makeServerResponseSchema(
  z.object({
    post: postOverviewSchema, // Null?
    analytics: z.nullable(postAnalyticsSchema),
  })
)

const fetchPostsResponseSchema = makePaginatedServerResponseSchema(
  z.array(postOverviewSchema)
)

export type {
  CreatePostInput,
  PostAnalytics,
  PostOverview,
  PostPlatforms,
  ShareAssetInput,
}
export {
  createPostInputSchema,
  fetchPostResponseSchema,
  fetchPostsResponseSchema,
  postPlatforms,
  shareAssetInputSchema,
}

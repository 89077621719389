/* eslint-disable react/jsx-pascal-case */
import * as React from 'react'
import { IonButton } from '@ionic/react'
import {
  MaterialReactTable,
  MRT_EditActionButtons,
  MRT_ToggleGlobalFilterButton,
} from 'material-react-table'
import { IconButton, Tooltip } from '@mui/material'
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material'
import { ArrowDownTrayIcon, PlusSmallIcon } from '@heroicons/react/24/solid'
import { utils, writeFile } from 'xlsx'
import { format } from 'date-fns'

import { columns } from './columns'
import { AddClientModal } from './add-client-modal'

import type { MaterialReactTableProps } from 'material-react-table'
import type { ClientImport } from './columns'

type Props = {
  data: Array<ClientImport>
  disabled: boolean
  onClientAdd: (values: ClientImport) => void
  onRowDelete: (id: number) => void
  onRowSave: MaterialReactTableProps<ClientImport>['onEditingRowSave']
  onTableSave: () => void
}

const DEFAULT_DATA: Array<ClientImport> = [
  {
    'First Name': '',
    'Last Name': '',
    Email: '',
    Phone: '',
    Tag: '',
    Note: '',
    'Loyalty Points': '',
    'Loyalty Visits': '',
    'Loyalty Points Lifetime': '',
    'Loyalty Visits Lifetime': '',
  },
]

const exportFileName = `OneShop_clients_${format(
  new Date(),
  'yyyy-dd-MM'
)}.xlsx`

function ClientImportTable({
  data,
  disabled,
  onClientAdd,
  onRowDelete,
  onRowSave,
  onTableSave,
}: Props) {
  const [isCreateModalOpen, setIsCreateModalOpen] = React.useState(false)

  async function handleExport() {
    const exportData = data.length ? data : DEFAULT_DATA

    const wb = utils.book_new()
    const ws = utils.json_to_sheet(exportData)

    utils.book_append_sheet(wb, ws, 'Sheet 1')
    writeFile(wb, exportFileName)
  }

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data}
        initialState={{ density: 'comfortable' }}
        defaultColumn={{ size: 0 }}
        autoResetPageIndex={false}
        enableColumnActions={false}
        enableColumnFilters={false}
        enableColumnOrdering
        enableDensityToggle={false}
        enableEditing // not all tables have
        editingMode="row" // not all tables have
        enableRowActions
        enableFullScreenToggle={false}
        enableHiding={false} // not all tables have
        onEditingRowSave={onRowSave} // not all tables have
        renderRowActions={({ row, table }) => (
          <div className="nowrap flex gap-2">
            {table.getState().editingRow?.id === row.id ? (
              <MRT_EditActionButtons table={table} row={row} />
            ) : (
              <Tooltip title="Edit">
                <IconButton
                  onClick={() => {
                    table.setEditingRow(row)
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}

            {table.getState().editingRow?.id === row.id ? null : (
              <Tooltip title="delete client">
                <IconButton
                  color="error"
                  onClick={() => onRowDelete(row.index)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        )}
        renderEmptyRowsFallback={() => (
          <div className="flex flex-col items-center justify-center p-20">
            <p>
              Select a .xlsx file with matching headers to import clients or add
              a new row. Download export to for template file.{' '}
            </p>
          </div>
        )}
        renderToolbarInternalActions={({ table }) => (
          <div className="flex items-center gap-4">
            <MRT_ToggleGlobalFilterButton table={table} />
            <Tooltip title="Export">
              <span className="flex">
                <button
                  disabled={Boolean(table.getState().editingRow)}
                  className="text-ion-color-step-500 disabled:text-ion-color-step-700"
                  onClick={handleExport}
                >
                  <ArrowDownTrayIcon className=" size-6" />
                </button>
              </span>
            </Tooltip>
            <Tooltip title="Add Client">
              <span className="flex">
                <button
                  disabled={Boolean(table.getState().editingRow)}
                  className="bg-ion-color-yellow rounded p-1 text-slate-900 disabled:opacity-50"
                  onClick={() => setIsCreateModalOpen(true)}
                >
                  <PlusSmallIcon className=" size-4" />
                </button>
              </span>
            </Tooltip>
            <IonButton
              color="yellow"
              size="small"
              disabled={disabled || Boolean(table.getState().editingRow)}
              onClick={onTableSave}
            >
              Save
            </IonButton>
          </div>
        )}
      />

      <AddClientModal
        columns={columns}
        open={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        onSubmit={onClientAdd}
      />
    </>
  )
}

export { ClientImportTable }

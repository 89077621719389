import { Button } from '@mui/material'

import { useBoolean } from '../../../hooks/use-boolean'
import { AddGroupMemberModal } from './add-group-member-modal'
import { GroupMemberItem } from './group-member-item'

import type { GroupMember, AddGroupMember } from '../user-setup.schema'

function DetailPanel({
  detail,
}: {
  detail: {
    groupMembers: Array<GroupMember>
    rootInfo: Pick<AddGroupMember, 'posId' | 'userId'>
  }
}) {
  const { groupMembers, rootInfo } = detail
  const [
    isAddGroupMemberOpen,
    { on: openAddGroupMember, off: closeAddGroupMember },
  ] = useBoolean()

  return (
    <>
      <div className="pl-4">
        <Button
          color="primary"
          variant="outlined"
          size="small"
          onClick={openAddGroupMember}
        >
          Add User Role
        </Button>
        <div className="grid items-center [grid-template-columns:min-content_min-content_max-content_max-content]">
          {groupMembers.length > 0 ? (
            groupMembers.map((gm) => (
              <GroupMemberItem
                key={gm.objectId}
                id={gm.objectId}
                posId={gm.posId}
                role={gm.role}
                storeName={gm.group.name ?? 'Unnamed Store'}
              />
            ))
          ) : (
            <p>This user currently has no roles.</p>
          )}
        </div>
      </div>
      <AddGroupMemberModal
        data={rootInfo}
        onClose={closeAddGroupMember}
        open={isAddGroupMemberOpen}
      />
    </>
  )
}

export { DetailPanel }

import * as React from 'react'
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonToolbar,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonRadioGroup,
  IonRadio,
  IonList,
  IonListHeader,
  IonSearchbar,
} from '@ionic/react'
import { barcodeOutline, copyOutline } from 'ionicons/icons'
import { useImmer } from 'use-immer'
import { Browser } from '@capacitor/browser'
import { useForm, Controller } from 'react-hook-form'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'

import { getRadioBoolean, getRadioValue } from '../../utils/shopwith-helpers'
import {
  ProductListFilters,
  useProductFilters,
  useProducts,
} from '../../hooks/shopwith/products/queries'
import { useLooks } from '../../hooks/shopwith/looks/queries'
import { useCollections } from '../../hooks/shopwith/collections/queries'
import {
  productShortcuts,
  lookShortcuts,
  collectionShortcuts,
  useCollectionShortcuts,
  useLookShortcuts,
  useProductShortcuts,
} from '../../hooks/shopwith/shopwith-shortcuts'
import { useShopWithLinks } from '../../hooks/shopwith/use-shopwith-links'
import { useClipboard } from '../../hooks/use-clipboard'
import { useInfiniteData } from '../../hooks/use-infinite-data'
import CreateAssetFAB from '../../components/fab/create-asset-fab'
import Header from '../../components/header'
import ProductItem from '../../components/shopwith/product-item'
import LookItem from '../../components/shopwith/look-item'
import CollectionItem from '../../components/shopwith/collection-item'
import ShareProductButton from '../../components/shopwith/share-product-button'
import ShareLookButton from '../../components/shopwith/share-look-button'
import ShareCollectionButton from '../../components/shopwith/share-collection-button'
import ShopWithShortcuts from '../../components/shopwith/shopwith-shortcuts'
import { HeaderWrapper, SelectionsWrapper } from '../../components/shopwith/ui'
import { FilterButton } from '../../components/ui/buttons/filter-button'
import SearchableSelect from '../../components/ui/form/searchable-select'
import { StickyHeader } from '../../components/ui/sticky-header'
import { WishlistButton } from '../../shopwith/components/wishlist-button'

import type { ProductShortcut } from '../../hooks/shopwith/shopwith-shortcuts'
import type { RadioFilters } from '../../utils/shopwith-helpers'
import { canScanSku, scanSku } from '../../hooks/shopwith/utils'
import { useToast } from '../../contexts/toastContext'

const shopWithSections = [
  { label: 'Products', value: 'products' },
  { label: 'Assets', value: 'looks' },
  { label: 'Collections', value: 'collections' },
] as const
type ShopWithSection = typeof shopWithSections[number]['label']
type SelectedItems = Record<
  typeof shopWithSections[number]['value'],
  Array<string>
>

type SearchFilterState = {
  brands: Filters
  categories: Filters
  hasImages: RadioFilters
  hasInventory: RadioFilters
  isDiscounted: RadioFilters
}

type Filters = Array<string>

const initialSearchFilters: SearchFilterState = {
  brands: [],
  categories: [],
  hasImages: undefined,
  hasInventory: undefined,
  isDiscounted: undefined,
}

//TODO: TYPE THIS
const defaultQueryFilters = {
  brands: [],
  categories: [],
}

function PreviewButton({
  disabled,
  onClick,
}: Pick<React.ComponentPropsWithoutRef<'button'>, 'onClick' | 'disabled'>) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className="border-ion-color-yellow text-ion-color-yellow inline-flex items-center gap-x-1.5 rounded-md border px-3 text-sm uppercase tracking-tight disabled:opacity-50"
    >
      Preview
      <ArrowTopRightOnSquareIcon className="mb-0.5 size-4" />
    </button>
  )
}

function CopyLinkButton({
  disabled,
  onClick,
}: {
  disabled: boolean
  onClick?: React.MouseEventHandler<HTMLIonButtonElement>
}) {
  return (
    <IonButton
      fill="clear"
      size="small"
      color="secondary"
      disabled={disabled}
      onClick={onClick}
    >
      <IonIcon icon={copyOutline} slot="icon-only" />
    </IonButton>
  )
}

function ShopWith() {
  const [section, setSection] = React.useState<ShopWithSection>('Products')

  const [isFilterModalOpen, setIsFilterModalOpen] = React.useState(false)

  const [selectedItems, updateSelectedItems] = useImmer<SelectedItems>({
    products: [],
    looks: [],
    collections: [],
  })

  const [filters, updateFilters] = useImmer<Required<ProductListFilters>>({
    filters: {
      brands: [],
      categories: [],
      hasImage: undefined,
      hasInventory: undefined,
      isDiscounted: undefined,
    },
    search: '',
  })

  const filtersForm = useForm<SearchFilterState>({
    defaultValues: initialSearchFilters,
  })
  const searchForm = useForm<{ search: string }>({
    defaultValues: { search: '' },
  })

  const { copyToClipboard } = useClipboard()

  const [productShortcut, setProductShortcut] = useProductShortcuts()
  const [lookShortcut, setLookShortcut] = useLookShortcuts()
  const [collectionShortcut, setCollectionShortcut] = useCollectionShortcuts()

  const { generateShopWithLinkPreview } = useShopWithLinks()

  const productsQuery = useProducts({
    filters: filters.filters,
    search: filters.search,
  })
  const looksInfo = useLooks({ enabled: !!productsQuery.data })
  const collectionsInfo = useCollections({ enabled: !!looksInfo.data })
  const productFiltersQuery = useProductFilters()
  const [ionInfiniteScrollRef, loadMoreProducts] = useInfiniteData(
    productsQuery.fetchNextPage
  )

  const shopwithIsLoading = [
    productsQuery,
    looksInfo,
    collectionsInfo,
    productFiltersQuery,
  ].some((query) => query.isLoading)

  function isItemSelected(key: keyof SelectedItems, item: string) {
    return selectedItems[key].includes(item)
  }

  function createShopWithLink() {
    let link = ''

    if (section === 'Products') {
      link = generateShopWithLinkPreview('productIds', selectedItems.products)
    }

    if (section === 'Assets') {
      link = generateShopWithLinkPreview('lookIds', selectedItems.looks)
    }

    if (section === 'Collections') {
      link = generateShopWithLinkPreview(
        'collectionIds',
        selectedItems.collections
      )
    }
    return link
  }

  function handleCopyLink() {
    copyToClipboard(createShopWithLink(), 'Link copied to clipboard.')
  }

  async function handlePreviewClick() {
    const url = createShopWithLink()
    if (url) {
      await Browser.open({ url })
    }
  }

  function handleItemClick(key: keyof SelectedItems, id: string) {
    if (isItemSelected(key, id)) {
      // remove item
      updateSelectedItems((draft) => {
        const index = draft[key].findIndex((item) => item === id)
        if (index !== -1) draft[key].splice(index, 1)
      })
    } else {
      updateSelectedItems((draft) => {
        if (key === 'collections') {
          draft.collections = [id]
          return
        }
        draft[key].push(id)
      })
    }
  }

  /* SEARCH AND FILTER */

  function resetFilters() {
    filtersForm.reset()
    setProductShortcut('all')
  }

  function handleSelectProductShortcut(shortcut: ProductShortcut) {
    setProductShortcut(shortcut)
    const { shoppableOnly, createdByMe, ...searchFilters } = filters.filters

    if (shortcut === 'all') {
      updateFilters({
        filters: searchFilters,
        search: filters.search,
      })

      return
    }
    updateFilters({
      filters: { ...searchFilters, [shortcut]: true },
      search: filters.search,
    })
  }

  function handleProductSearch(data: SearchFilterState) {
    setIsFilterModalOpen(false)
    updateFilters((draft) => {
      draft.filters.brands = data.brands
      draft.filters.categories = data.categories
      draft.filters.hasImage = getRadioBoolean(data.hasImages)
      draft.filters.hasInventory = getRadioBoolean(data.hasInventory)
      draft.filters.isDiscounted = getRadioBoolean(data.isDiscounted)
    })
  }

  function handleSearch(data: { search: string }) {
    resetFilters()

    updateFilters({ search: data.search, filters: defaultQueryFilters })
  }

  function handleSearchClear() {
    resetFilters()

    updateFilters({ search: '', filters: defaultQueryFilters })
  }

  const setToast = useToast()
  async function handleSkuScan() {
    const scan = await scanSku()
    if (!scan)
      return setToast({
        message: 'Something went wrong while scanning.',
        color: 'danger',
      })

    searchForm.setValue('search', scan)

    resetFilters()
    updateFilters({ search: scan, filters: defaultQueryFilters })
  }

  return (
    <IonPage>
      <IonHeader>
        <Header showMenu title="ShopWith" />
      </IonHeader>
      <IonContent>
        <StickyHeader>
          <div className="ion-padding-top ion-padding-horizontal">
            <IonSegment
              onIonChange={(e) => setSection(e.detail.value as ShopWithSection)}
              value={section}
            >
              {shopWithSections.map((section) => (
                <IonSegmentButton value={section.label} key={section.value}>
                  <IonLabel>{section.label.toUpperCase()}</IonLabel>
                </IonSegmentButton>
              ))}
            </IonSegment>
          </div>

          {section === 'Products' ? (
            <>
              <div className="flex items-center justify-center">
                <form
                  className="flex-1"
                  onSubmit={searchForm.handleSubmit(handleSearch)}
                >
                  <Controller
                    name="search"
                    control={searchForm.control}
                    render={({ field: { value, onChange } }) => (
                      <IonSearchbar
                        value={value}
                        onIonChange={onChange}
                        onIonClear={handleSearchClear}
                        debounce={0}
                      />
                    )}
                  />
                </form>
                {canScanSku ? (
                  <div className="ion-padding-end">
                    <IonButton
                      size="small"
                      fill="solid"
                      color="secondary"
                      onClick={handleSkuScan}
                    >
                      <IonIcon icon={barcodeOutline} />
                    </IonButton>
                  </div>
                ) : null}
              </div>
              <HeaderWrapper disabled={productsQuery.isLoading}>
                <ShopWithShortcuts
                  shortcuts={productShortcuts}
                  currentShortcut={productShortcut}
                  onChange={handleSelectProductShortcut}
                />
              </HeaderWrapper>

              <IonRow className="ion-nowrap ion-align-items-center ion-justify-content-between ion-padding-end ion-margin-top">
                <IonCol className="ion-no-padding">
                  <SelectionsWrapper>
                    <CopyLinkButton
                      disabled={selectedItems.products.length === 0}
                      onClick={handleCopyLink}
                    />
                    <div className="flex space-x-2">
                      <PreviewButton
                        disabled={selectedItems.products.length === 0}
                        onClick={handlePreviewClick}
                      />
                      <WishlistButton
                        disabled={!selectedItems.products.length}
                        products={selectedItems.products}
                      />
                      <ShareProductButton
                        disabled={selectedItems.products.length === 0}
                        products={selectedItems.products}
                      />
                    </div>
                  </SelectionsWrapper>
                </IonCol>
                <IonCol size="auto" className="ion-no-padding">
                  <FilterButton onClick={() => setIsFilterModalOpen(true)} />
                </IonCol>
              </IonRow>

              <IonRow className="ion-justify-content-between ion-padding-horizontal">
                <IonCol size="auto" className="ion-no-padding">
                  <h2 className="text-ion-color-yellow text-base">
                    Selected: {selectedItems.products.length}
                  </h2>
                </IonCol>
                <IonCol className="ion-no-padding" size="auto">
                  <h2 className="text-base">
                    {productsQuery.data?.pages[0].totalCount} Product
                    {productsQuery.data?.pages[0].totalCount === 1 ? '' : 's'}
                  </h2>
                </IonCol>
              </IonRow>
            </>
          ) : section === 'Assets' ? (
            <>
              <HeaderWrapper disabled={looksInfo.isLoading}>
                <ShopWithShortcuts
                  shortcuts={lookShortcuts}
                  currentShortcut={lookShortcut}
                  onChange={setLookShortcut}
                />
              </HeaderWrapper>

              <IonRow className="ion-nowrap ion-align-items-center ion-justify-content-between ion-padding-end ion-margin-top">
                <IonCol className="ion-no-padding">
                  <SelectionsWrapper>
                    <CopyLinkButton
                      disabled={selectedItems.looks.length === 0}
                      onClick={handleCopyLink}
                    />
                    <div className="flex space-x-2">
                      <PreviewButton
                        disabled={selectedItems.looks.length === 0}
                        onClick={handlePreviewClick}
                      />
                      <ShareLookButton
                        disabled={!selectedItems.looks.length}
                        looks={selectedItems.looks}
                      />
                    </div>
                  </SelectionsWrapper>
                </IonCol>
              </IonRow>

              <IonRow className="ion-justify-content-between ion-padding-horizontal">
                <IonCol size="auto" className="ion-no-padding">
                  <h2 className="text-ion-color-yellow text-base">
                    Selected: {selectedItems.looks.length}
                  </h2>
                </IonCol>
                <IonCol className="ion-no-padding" size="auto">
                  <h2 className="text-base">
                    {looksInfo.data?.length} Asset
                    {looksInfo.data?.length === 1 ? '' : 's'}
                  </h2>
                </IonCol>
              </IonRow>
            </>
          ) : section === 'Collections' ? (
            <>
              <HeaderWrapper disabled={collectionsInfo.isLoading}>
                <ShopWithShortcuts
                  shortcuts={collectionShortcuts}
                  currentShortcut={collectionShortcut}
                  onChange={setCollectionShortcut}
                />
              </HeaderWrapper>

              <IonRow className="ion-nowrap ion-align-items-center ion-justify-content-between ion-padding-end ion-margin-top">
                <IonCol className="ion-no-padding">
                  <SelectionsWrapper>
                    <CopyLinkButton
                      disabled={selectedItems.collections.length === 0}
                      onClick={handleCopyLink}
                    />
                    <div className="flex space-x-2">
                      <PreviewButton
                        disabled={selectedItems.collections.length === 0}
                        onClick={handlePreviewClick}
                      />
                      <ShareCollectionButton
                        disabled={!selectedItems.collections.length}
                        collections={selectedItems.collections}
                      />
                    </div>
                  </SelectionsWrapper>
                </IonCol>
              </IonRow>

              <IonRow className="ion-justify-content-between ion-padding-horizontal">
                <IonCol size="auto" className="ion-no-padding">
                  <h2 className="text-ion-color-yellow text-base">
                    Selected: {selectedItems.collections.length}
                  </h2>
                </IonCol>
                <IonCol className="ion-no-padding" size="auto">
                  <h2 className="text-base">
                    {collectionsInfo.data?.length} Collection
                    {collectionsInfo.data?.length === 1 ? '' : 's'}
                  </h2>
                </IonCol>
              </IonRow>
            </>
          ) : null}
        </StickyHeader>
        {shopwithIsLoading ? (
          <IonLoading isOpen message={'Loading your SHOPWITH products'} />
        ) : (
          <>
            {/* Product Search Inicator */}
            <IonLoading
              isOpen={productsQuery.isFetching && productsQuery.isPreviousData}
            />
            <section className={section === 'Products' ? 'block' : 'hidden'}>
              <IonGrid>
                <IonRow>
                  {productsQuery.data?.pages.map((productPage, index) => (
                    <React.Fragment key={index}>
                      {productPage.data.map((product) => (
                        <IonCol
                          key={product.objectId}
                          className="flex flex-col"
                          sizeXs="6"
                          sizeSm="4"
                          sizeMd="3"
                        >
                          <ProductItem
                            product={product}
                            onProductSelect={handleItemClick}
                            isSelected={isItemSelected(
                              'products',
                              product.objectId
                            )}
                          />
                        </IonCol>
                      ))}
                    </React.Fragment>
                  ))}
                </IonRow>
              </IonGrid>
              <IonInfiniteScroll
                ref={ionInfiniteScrollRef}
                onIonInfinite={loadMoreProducts}
                threshold="100px"
                disabled={!productsQuery.hasNextPage}
              >
                <IonInfiniteScrollContent
                  loadingSpinner="bubbles"
                  loadingText="loading more products..."
                />
              </IonInfiniteScroll>
            </section>
            <section className={section === 'Assets' ? 'block' : 'hidden'}>
              <IonGrid>
                <IonRow>
                  {looksInfo.data?.map((look) => {
                    return (
                      <IonCol
                        key={look.objectId}
                        className="flex flex-col"
                        sizeXs="6"
                        sizeSm="4"
                        sizeMd="3"
                      >
                        <LookItem
                          look={look}
                          onLookSelect={handleItemClick}
                          isSelected={isItemSelected('looks', look.objectId)}
                        />
                      </IonCol>
                    )
                  })}
                </IonRow>
              </IonGrid>
            </section>
            <section className={section === 'Collections' ? 'block' : 'hidden'}>
              <IonGrid>
                <IonRow>
                  {collectionsInfo.data?.map((collection) => {
                    return (
                      <IonCol
                        key={collection.objectId}
                        className="flex flex-col"
                        sizeXs="6"
                        sizeSm="4"
                        sizeMd="3"
                      >
                        <CollectionItem
                          collection={collection}
                          onCollectionSelect={handleItemClick}
                          isSelected={isItemSelected(
                            'collections',
                            collection.objectId
                          )}
                        />
                      </IonCol>
                    )
                  })}
                </IonRow>
              </IonGrid>
            </section>
          </>
        )}

        <CreateAssetFAB />
      </IonContent>

      {/* OPEN PRODUCT FILTER */}
      <IonModal
        isOpen={isFilterModalOpen}
        onDidDismiss={() => setIsFilterModalOpen(false)}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons>
              <IonButton
                color="secondary"
                onClick={() => {
                  filtersForm.reset(
                    {
                      brands: filters.filters.brands,
                      categories: filters.filters.categories,
                      hasImages: getRadioValue(filters.filters.hasImage),
                      hasInventory: getRadioValue(filters.filters.hasInventory),
                      isDiscounted: getRadioValue(filters.filters.isDiscounted),
                    },
                    { keepDefaultValues: true }
                  )

                  setIsFilterModalOpen(false)
                }}
              >
                CANCEL
              </IonButton>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton
                fill="outline"
                color="secondary"
                onClick={() => filtersForm.reset()}
              >
                RESET
              </IonButton>
              <IonButton
                fill="solid"
                color="yellow"
                onClick={filtersForm.handleSubmit(handleProductSearch)}
              >
                SEARCH
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonCard color="secondary">
            <IonCardContent>
              <Controller
                name="brands"
                control={filtersForm.control}
                render={({ field: { value, onChange } }) => (
                  <SearchableSelect
                    items={productFiltersQuery.data?.brands ?? []}
                    label="Brands"
                    selectedItems={value ?? []}
                    setSelectedItems={onChange}
                  />
                )}
              />
              <Controller
                name="categories"
                control={filtersForm.control}
                render={({ field: { value, onChange } }) => (
                  <SearchableSelect
                    items={productFiltersQuery.data?.categories ?? []}
                    label="Categories"
                    selectedItems={value ?? []}
                    setSelectedItems={onChange}
                  />
                )}
              />

              <IonList>
                <Controller
                  name="hasImages"
                  control={filtersForm.control}
                  render={({ field: { value, onChange } }) => (
                    <IonRadioGroup
                      value={value}
                      onIonChange={onChange}
                      allowEmptySelection
                    >
                      <IonListHeader
                        color="secondary"
                        className="ion-margin-top"
                      >
                        Has Photos
                      </IonListHeader>

                      <IonItem color="secondary">
                        <IonLabel>Yes</IonLabel>

                        <IonRadio mode="md" slot="end" value="yes" />
                      </IonItem>
                      <IonItem color="secondary">
                        <IonLabel>No</IonLabel>

                        <IonRadio mode="md" slot="end" value="no" />
                      </IonItem>
                    </IonRadioGroup>
                  )}
                />
              </IonList>

              <IonList>
                <Controller
                  name="hasInventory"
                  control={filtersForm.control}
                  render={({ field: { value, onChange } }) => (
                    <IonRadioGroup value={value} onIonChange={onChange}>
                      <IonListHeader
                        color="secondary"
                        className="ion-margin-top"
                      >
                        Has Inventory
                      </IonListHeader>
                      <IonItem color="secondary">
                        <IonLabel>Yes</IonLabel>
                        <IonRadio mode="md" slot="end" value="yes" />
                      </IonItem>
                      <IonItem color="secondary">
                        <IonLabel>No</IonLabel>
                        <IonRadio mode="md" slot="end" value="no" />
                      </IonItem>
                    </IonRadioGroup>
                  )}
                />
              </IonList>

              <IonList>
                <Controller
                  name="isDiscounted"
                  control={filtersForm.control}
                  render={({ field: { value, onChange } }) => (
                    <IonRadioGroup
                      value={value}
                      onIonChange={onChange}
                      allowEmptySelection
                    >
                      <IonListHeader
                        color="secondary"
                        className="ion-margin-top"
                      >
                        Is Discounted
                      </IonListHeader>

                      <IonItem color="secondary">
                        <IonLabel>Yes</IonLabel>
                        <IonRadio mode="md" slot="end" value="yes" />
                      </IonItem>
                      <IonItem color="secondary">
                        <IonLabel>No</IonLabel>
                        <IonRadio mode="md" slot="end" value="no" />
                      </IonItem>
                    </IonRadioGroup>
                  )}
                />
              </IonList>
            </IonCardContent>
          </IonCard>
        </IonContent>
      </IonModal>
    </IonPage>
  )
}

export default ShopWith

import { format, parseISO } from 'date-fns'

import { cn } from '../../utils/cn'
import {
  ShopWithCard,
  ShopWithCardContent,
  ShopWithCardLineItem,
  ShopWithCardMedia,
} from './card'
import { ItemButton } from './ui'

import type { Collection } from '../../types/shopwith'

type CollectionItemProps = {
  collection: Collection
  onCollectionSelect: (key: 'collections', id: string) => void
  isSelected: boolean
}

function CollectionItem({
  collection,
  onCollectionSelect,
  isSelected,
}: CollectionItemProps) {
  const createdAt = format(parseISO(collection.createdAt), 'MM/dd/yy')

  function handleCollectionSelect() {
    onCollectionSelect('collections', collection.objectId)
  }

  return (
    <ShopWithCard isSelected={isSelected}>
      <ItemButton onClick={handleCollectionSelect}>
        <ShopWithCardContent>
          <ShopWithCardMedia
            src={
              collection.looks[0]?.coverPhoto
                ? collection.looks[0]?.coverPhoto.originalSrc
                : collection.looks[0]?.products[0]?.featuredImage
            }
            ratio
          />
          <div className="mt-2">
            {collection.title && (
              <ShopWithCardLineItem bold>
                {collection.title}
              </ShopWithCardLineItem>
            )}
            <ShopWithCardLineItem>
              Date Created: {createdAt}
            </ShopWithCardLineItem>
            <ShopWithCardLineItem bold>
              Assets: {collection.looks.length}
            </ShopWithCardLineItem>
          </div>
        </ShopWithCardContent>
      </ItemButton>
    </ShopWithCard>
  )
}

export default CollectionItem

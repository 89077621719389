import { z } from 'zod'

import { nullableString } from '../utils/schema'

// NOTE: the start filter is "End" in the UI; the end filter is "Start" in the UI;
const lastSaleSchema = z.object({
  name: z.string().min(1, { message: 'You must provide an outreach name.' }),
  description: z.string(),
  filter: z
    .object({
      start: z.number().nonnegative({
        message: 'The starting point must be a positive number.',
      }),
      end: z.number().nonnegative({
        message: 'The ending point must be a positive number.',
      }),
    })
    .superRefine((val, ctx) => {
      if (val.start === val.end) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'The starting and ending points cannot be the same.',
        })
      }

      if (val.end > val.start) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'The starting point cannot be after the ending point.',
        })
      }
    }),
  templateId: nullableString,
  trigger: z.literal('LAST_SALE'),
})

const birthdaySchema = z.object({
  name: z.string().min(1, { message: 'You must provide an outreach name.' }),
  description: z.string(),
  filter: z.object({
    daysBefore: z
      .number({ required_error: 'The days before the birthday is required.' })
      .nonnegative({
        message: 'The days before the birthday must be a positive number.',
      })
      .gte(0, {
        message: 'The days before the birthday must be between 0 and 120.',
      })
      .lte(120, {
        message: `The days before the birthday must be between 0 and 120.`,
      }),
  }),
  templateId: nullableString,
  trigger: z.literal('BIRTHDAY'),
})

const dynamicCallListSchema = z.discriminatedUnion('trigger', [
  lastSaleSchema,
  birthdaySchema,
])

export { dynamicCallListSchema }
export type DynamicCallListSchemaState = z.infer<typeof dynamicCallListSchema>

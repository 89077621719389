import * as React from 'react'
import {
  IonContent,
  IonPage,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonHeader,
  IonCardSubtitle,
  IonSegment,
  IonSegmentButton,
} from '@ionic/react'
import {
  endOfDay,
  endOfWeek,
  format,
  parseISO,
  startOfWeek,
  subWeeks as subtractWeeks,
} from 'date-fns'

import { useStats } from '../../hooks/use-stats'
import AdminHeader from '../../components/header/admin-header'

import type { SegmentChangeEventDetail } from '@ionic/core'
import { StylistStatsTable } from '../../admin/stylist-stats/stylist-stats-table'

const StatsStylist = () => {
  const [currentView, setCurrentView] = React.useState('week to date')

  const [startDate, setStartDate] = React.useState(
    startOfWeek(new Date()).toISOString()
  )
  const [endDate, setEndDate] = React.useState(
    endOfDay(new Date()).toISOString()
  )

  const [search, setSearch] = React.useState('')

  const {
    isLoading,
    isPreviousData,
    pagination,
    setPagination,
    totalCount,
    ...statsQuery
  } = useStats({
    startDate,
    endDate,
    search,
  })

  const isStatsFetching = isLoading || isPreviousData

  function handleChangeDateRange(e: CustomEvent<SegmentChangeEventDetail>) {
    if (!e.detail.value) return

    setCurrentView(e.detail.value)
    if (e.detail.value === 'last week') {
      setStartDate(startOfWeek(subtractWeeks(new Date(), 1)).toISOString())
      setEndDate(endOfWeek(subtractWeeks(new Date(), 1)).toISOString())
    } else if (e.detail.value === 'week to date') {
      setStartDate(startOfWeek(new Date()).toISOString())
      setEndDate(endOfDay(new Date()).toISOString())
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <AdminHeader showMenu title="Stylist Stats" />
      </IonHeader>
      <IonContent className="ion-padding">
        <IonCard color="secondary" className="mx-0 mt-0">
          <IonCardHeader className="text-center">
            <IonCardTitle>SETTINGS</IonCardTitle>
            <IonCardSubtitle>
              {format(parseISO(startDate), 'MMM dd, yyyy')} -{' '}
              {format(parseISO(endDate), 'MMM dd, yyyy')}
            </IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonSegment
              style={{ '--background': 'var(--ion-color-primary)' }}
              onIonChange={handleChangeDateRange}
              value={currentView}
            >
              <IonSegmentButton
                style={{
                  '--indicator-color': 'var(--ion-color-yellow)',
                  '--color': 'white',
                }}
                value={'last week'}
                disabled={isStatsFetching}
              >
                LAST WEEK
              </IonSegmentButton>
              <IonSegmentButton
                style={{
                  '--indicator-color': 'var(--ion-color-yellow',
                  '--color': 'white',
                }}
                value={'week to date'}
                disabled={isStatsFetching}
              >
                WEEK TO DATE
              </IonSegmentButton>
            </IonSegment>
          </IonCardContent>
        </IonCard>
        <StylistStatsTable
          data={statsQuery.data}
          rowCount={totalCount}
          state={{
            pagination,
            isLoading,
            showProgressBars: isStatsFetching,
          }}
          onSearchChange={setSearch}
          onPaginationChange={setPagination}
        />
      </IonContent>
    </IonPage>
  )
}

export default StatsStylist

import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react'

import { LoyaltyRedemptionDetail } from '../../loyalty/components/loyalty-redemption-detail'
import { useClientLoyalty } from '../../loyalty/queries'
import { useApplyClientRewards } from '../../marketing/customer-opt-in/mutations'

import type { ApplyLoyaltyReward } from '../../loyalty/loyalty.schema'

function CashierLoyaltyInfoPage() {
  const router = useIonRouter()

  const { data, isLoading, error } = useClientLoyalty()
  const applyRewards = useApplyClientRewards()

  function handleApplyRewards(
    rewards: Array<ApplyLoyaltyReward>,
    options?: { redeemEarly: boolean }
  ) {
    applyRewards.mutate({ rewards, options })
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Redeem Rewards</IonTitle>
          <IonButtons slot="end">
            <IonButton
              color="yellow"
              fill="solid"
              onClick={() =>
                router.push('/rotate/cashier/check-in', 'back', 'pop')
              }
            >
              Finish
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding-horizontal">
        <>
          {data ? (
            <LoyaltyRedemptionDetail
              data={data}
              disabled={isLoading}
              onApplyRewards={handleApplyRewards}
            />
          ) : error ? (
            <p>
              Error: {error instanceof Error ? error.message : 'Unknown Error.'}
            </p>
          ) : (
            <IonLoading message="Loading Rewards..." isOpen />
          )}
        </>
      </IonContent>
    </IonPage>
  )
}

export { CashierLoyaltyInfoPage }

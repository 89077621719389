import React, { useState, useEffect } from 'react'
import {
  IonCard,
  IonCardContent,
  IonInput,
  IonItem,
  IonLabel,
  IonTextarea,
  IonButton,
} from '@ionic/react'

import '../../assets/css/FollowUpCard.css'
import * as commonStrings from '../../utils/common-strings'
import { useToast } from '../../contexts/toastContext'
import useSendCommunication from '../../hooks/use-send-communication'
import { useUser } from '../../contexts/authContext'

const NewEmailComponent = ({
  to,
  subject,
  body,
  note,
  clientId,
  onEmailSend,
  setNote,
  onCancel,
}) => {
  const [toValue, setToValue] = useState(to)
  const [subjectValue, setSubectValue] = useState(subject)
  const [bodyValue, setBodyValue] = useState(body)
  const [clientIdValue, setClientIdValue] = useState(clientId)
  const [loading, setLoading] = useState(false)
  const setToast = useToast()
  const [noteValue, setNoteValue] = useState()

  const { sendMessage } = useSendCommunication()
  const user = useUser()

  useEffect(() => {
    setNoteValue(note)
  }, [note])

  useEffect(() => {
    setToValue(to)
  }, [to])

  useEffect(() => {
    setSubectValue(subject)
  }, [subject])

  useEffect(() => {
    setBodyValue(body)
  }, [body])

  useEffect(() => {
    setClientIdValue(clientId)
  }, [clientId])

  const onSubmit = async (log) => {
    setLoading(true)
    try {
      const sendMessageProps = {
        user,
        clientId: clientIdValue,
        to: toValue,
        subject: subjectValue,
        body: bodyValue,
        note: noteValue,
        log,
      }
      await sendMessage(sendMessageProps)
      setBodyValue(null)
      setSubectValue(null)
      if (onEmailSend) onEmailSend()
    } catch (error) {
      setLoading(false)
      setToast({ message: 'Oops something went wrong', color: 'danger' })
    }
  }

  const SendButton = () => {
    const enabled = toValue && subjectValue && bodyValue && !loading

    return (
      <IonButton disabled={!enabled} onClick={(e) => onSubmit(false)}>
        SEND
      </IonButton>
    )
  }

  return (
    <IonCard className="followup-card" color={commonStrings.secondary}>
      <IonCardContent>
        <IonItem color={commonStrings.secondary}>
          <IonLabel position={commonStrings.floating}>Subject:</IonLabel>
          <IonInput
            value={subjectValue}
            onIonChange={(e) => setSubectValue(e.target.value)}
          />
        </IonItem>
        <IonItem color={commonStrings.secondary}>
          <IonLabel position={commonStrings.floating}>
            Type Your Message Here:
          </IonLabel>
          {/* setting autoGrow as body is a hack */}
          <IonTextarea
            value={bodyValue}
            onIonChange={(e) => setBodyValue(e.target.value)}
            autoGrow
            autocapitalize="on"
            rows={Math.max((bodyValue || '').split(/\r\n|\r|\n/).length, 5)}
          />
        </IonItem>
        <IonItem color={commonStrings.secondary}>
          <IonLabel position={commonStrings.floating}>
            Add Notes For Yourself Here:
          </IonLabel>
          <IonTextarea
            value={noteValue}
            onIonChange={(e) => {
              setNoteValue(e.target.value)
              if (setNote) setNote(e.target.value)
            }}
            autoGrow
            autocapitalize="on"
            rows={Math.max((note || '').split(/\r\n|\r|\n/).length, 3)}
          />
        </IonItem>
        <div className="buttons" style={{ marginTop: '6px' }}>
          <div>
            <IonButton fill="outline" onClick={onCancel}>
              CANCEL
            </IonButton>
          </div>
          <div>
            <IonButton fill="outline" onClick={(e) => onSubmit(true)}>
              LOG
            </IonButton>
            <SendButton />
          </div>
        </div>
      </IonCardContent>
    </IonCard>
  )
}

export default NewEmailComponent

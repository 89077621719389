import { removeNulls } from './typescript-helpers'

type LastPurchase = {
  amount: number
  stylist: Array<string>
  store: Array<string>
}

function getStylists(sales: Array<{ posSellerName: string | null }>) {
  return [
    ...Array.from(
      new Set(sales.map((sale) => sale.posSellerName).filter(removeNulls))
    ),
  ]
}
function getStores(sales: Array<{ group: { name: string } | null }>) {
  return [
    ...Array.from(
      new Set(sales.map((sale) => sale.group?.name).filter(removeNulls))
    ),
  ]
}

function getLastPurchase(purchase: {
  totalSaleAmount: number
  allSales: Array<{
    posSellerName: string | null
    group: { name: string } | null
  }>
}): LastPurchase {
  if (!purchase) return { amount: 0, stylist: [], store: [] }

  return {
    amount: purchase.totalSaleAmount,
    stylist: getStylists(purchase.allSales),
    store: getStores(purchase.allSales),
  }
}

export { getStylists, getStores, getLastPurchase }

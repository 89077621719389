import { IonItem, IonSelect, IonSelectOption } from '@ionic/react'
import { Controller, useForm } from 'react-hook-form'
import { XCircleIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/solid'
import { Save } from '@mui/icons-material'

import { GroupMember } from '../user-setup.schema'
import { useBoolean } from '../../../hooks/use-boolean'
import { useDeleteGroupMember, useUpdateGroupMember } from '../mutations'
import { TextInput } from '../../../components/ui/form/input'

type Props = { id: string; storeName: string } & Pick<
  GroupMember,
  'posId' | 'role'
>

type FormData = { role: GroupMember['role']; posId: string | null }

function GroupMemberItem({ id, posId, role, storeName }: Props) {
  const [isEditing, { on: startEditing, off: stopEditing }] = useBoolean()

  const { mutate: deleteGroupMember, isLoading: isDeleting } =
    useDeleteGroupMember()
  const { mutate: updateGroupMember, isLoading: isUpdating } =
    useUpdateGroupMember()

  const { control, getValues, reset } = useForm<FormData>({
    defaultValues: {
      role,
      posId,
    },
  })

  const isPending = isDeleting || isUpdating

  function handleCancel() {
    reset()
    stopEditing()
  }

  function handleDeleteGroupMember() {
    deleteGroupMember(id, {
      onSettled: () => stopEditing(),
    })
  }

  function handleSaveGroupMember() {
    const { role, posId } = getValues()
    updateGroupMember(
      { objectId: id, posId, role },
      {
        onSettled: () => stopEditing(),
      }
    )
  }

  return (
    <>
      <span className="flex items-center">
        {isEditing ? (
          <>
            <button
              className="size-8 p-0.5 text-green-600 disabled:text-green-300"
              onClick={handleSaveGroupMember}
              disabled={isPending}
            >
              <Save
                className="size-full
            "
              />
            </button>
            <button
              className="size-8 p-0.5 text-slate-500 disabled:text-slate-300"
              onClick={handleCancel}
              disabled={isPending}
            >
              <XCircleIcon
                className="size-full
            "
              />
            </button>
          </>
        ) : (
          <>
            <button
              className="p-2 disabled:opacity-40"
              disabled={isPending}
              onClick={startEditing}
            >
              <PencilIcon className="size-4" />
            </button>
            <button
              className="text-ion-color-danger p-2 disabled:opacity-40"
              disabled={isPending}
              onClick={handleDeleteGroupMember}
            >
              <TrashIcon className="size-4" />
            </button>
          </>
        )}
      </span>

      <div className="pl-3">
        <IonItem
          color="secondary"
          lines={isEditing ? 'full' : 'none'}
          style={{ '--padding-start': 0 }}
        >
          <Controller
            name="role"
            control={control}
            render={({ field: { value, onChange } }) => (
              <IonSelect
                className="setup-edit-role-select"
                disabled={!isEditing}
                color="secondary"
                value={value}
                onIonChange={(e) => {
                  // WORKAROUND: Look into why using onChange directly is setting then unsetting the value
                  if (!e.detail.value) return
                  onChange(e.detail.value)
                }}
              >
                <IonSelectOption value="admin">Admin</IonSelectOption>
                <IonSelectOption value="associate">Associate</IonSelectOption>
              </IonSelect>
            )}
          />
        </IonItem>
      </div>
      <div className="flex gap-x-2">
        <span className="text-base">at</span>
        <span className="flex-grow text-base">{storeName}</span>
      </div>
      {isEditing && role !== 'admin' ? (
        <TextInput
          disabled={!isEditing}
          control={control}
          color="light"
          name="posId"
          placeholder="POS ID"
        />
      ) : (
        <>
          {posId ? (
            <span className="pl-2">(POS ID {posId})</span>
          ) : (
            <span></span>
          )}
        </>
      )}
    </>
  )
}

export { GroupMemberItem }

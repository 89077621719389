import { IonText } from '@ionic/react'

import { formatCurrency } from '../utils/format-helpers'

function getNumber(value?: string | number | null) {
  if (typeof value === 'string') return +value
  if (typeof value === 'number') return value
  return 0
}

function Currency({
  value,
  abbreviate = false,
}: {
  value?: number | string | null
  abbreviate?: boolean
}) {
  const amount = getNumber(value)

  return (
    <IonText color={amount < 0 ? 'danger' : undefined}>
      {!isNaN(amount) ? formatCurrency(amount, { abbreviate }) : value}
    </IonText>
  )
}

export default Currency

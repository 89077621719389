import { IonContent, IonPage } from '@ionic/react'

import { composeDollarAmount, composeNumber } from '../../utils/format-helpers'
import { LoyaltyButton } from '../../loyalty/components/loyalty-button'
import { HeartlandCard } from '../../integrations/heartland/components/ui-extension/heartland-card'
import {
  ButtonWrapper,
  CardWrapper,
  Divider,
  Wrapper,
} from '../../integrations/heartland/components/ui-extension/layout'
import { useRemoveHeartlandRewards } from '../../integrations/heartland/mutations'
import { useHeartlandClientLoyalty } from '../../integrations/heartland/queries'
import { useShowExtension } from '../../integrations/heartland/use-show-extension'
import { useHeartlandParams } from '../../integrations/heartland/utils'

// https://dev.retail.heartland.us/beta.html#ui_extensions
// https://app.oneshopretail.com/heartland/ui_extension/LIAPZUXNbo?account_uuid=599d089f-0e25-43e0-8e31-94f24e20047b&customer_id=100009&pos_url=https%3A%2F%2Fclientelier.retail.heartland.us%2F%23pos%2F160341&subdomain=clientelier&ticket_id=160341&transaction_status=incomplete
// https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage

function HeartlandLoyaltyUiExtension() {
  const { groupId, clientId, posUrl, ticketId, querystring } =
    useHeartlandParams()

  const { data: loyaltyStats } = useHeartlandClientLoyalty({
    groupId,
    clientId,
    ticketId,
  })
  const { mutate: removeRewards, isLoading } = useRemoveHeartlandRewards()

  useShowExtension(posUrl, loyaltyStats?.show)

  function openRedemptionPage() {
    const url = new URL(
      `https://app.oneshopretail.com/heartland/${groupId}/redeem-loyalty${querystring}`
    )

    if (posUrl) {
      window.parent?.postMessage(
        JSON.stringify({
          command: 'redirect',
          params: {
            url,
          },
        }),
        {
          targetOrigin: posUrl,
        }
      )
    }
  }

  // The height at the default zoom level is 66px.
  // The width stretches to fill the viewport. We recommend using responsive design for the iframe contents.
  // The minimum width in production use is 1024px.

  return (
    <IonPage>
      <IonContent>
        <Wrapper>
          <CardWrapper>
            <HeartlandCard title="Visits Until Reward">
              {loyaltyStats
                ? composeNumber(loyaltyStats.visitsUntilReward)
                : '...'}
            </HeartlandCard>
          </CardWrapper>

          <CardWrapper>
            <HeartlandCard title="Points Balance">
              {loyaltyStats ? composeNumber(loyaltyStats.pointsBalance) : '...'}
            </HeartlandCard>
          </CardWrapper>

          <CardWrapper>
            <HeartlandCard title="Rewards">
              {loyaltyStats
                ? composeDollarAmount(loyaltyStats.rewardsAvailable)
                : '...'}
            </HeartlandCard>
          </CardWrapper>

          <ButtonWrapper>
            {loyaltyStats?.rewardApplied ? (
              <LoyaltyButton
                disabled={!loyaltyStats || isLoading}
                onClick={() => removeRewards()}
              >
                Remove Rewards
              </LoyaltyButton>
            ) : (
              <LoyaltyButton
                disabled={!loyaltyStats}
                onClick={openRedemptionPage}
              >
                Claim Rewards
              </LoyaltyButton>
            )}
          </ButtonWrapper>
        </Wrapper>

        <Divider />
      </IonContent>
    </IonPage>
  )
}

export default HeartlandLoyaltyUiExtension

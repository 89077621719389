import {
  IonLabel,
  IonRouterLink,
  IonNote,
  IonButton,
  IonIcon,
} from '@ionic/react'
import clsx from 'clsx'
import { format, parseISO } from 'date-fns'
import { trash } from 'ionicons/icons'

import {
  ListItem,
  ListItemHeading,
  ListItemSubHeading,
} from '../../../components/ui/list-item'
import { useDeleteBroadcast } from '../mutations'

import type { BroadcastBase } from '../broadcast.schema'

type Variant = Record<Exclude<BroadcastBase['status'], null>, string>
const variantClasses: Variant = {
  canceled: 'text-red-500',
  draft: 'text-slate-300',
  failed: 'text-red-500',
  pending: 'text-yellow-400',
  queued: 'text-yellow-400',
  success: 'text-green-500',
}

function BroadcastItem({
  broadcast,
  routerLink,
  showType = false,
}: {
  broadcast: BroadcastBase
  routerLink: string
  showType?: boolean
}) {
  const deleteBroadcast = useDeleteBroadcast()

  const status = broadcast.status ?? 'draft'

  return (
    <ListItem>
      <IonLabel className="ml-4">
        <IonRouterLink routerLink={routerLink}>
          <ListItemHeading secondary>{broadcast.title}</ListItemHeading>
          <ListItemSubHeading>
            Total Text Messages Sent: {broadcast.smsCount}
          </ListItemSubHeading>
          <ListItemSubHeading>
            Total Emails Sent: {broadcast.emailCount}
          </ListItemSubHeading>
        </IonRouterLink>
      </IonLabel>

      <IonNote color="secondary" className="text-sm" slot="end">
        <div className="flex">
          <div className="flex flex-col">
            {showType ? <p className="font-semibold">Type: Broadcast</p> : null}
            <p>
              Status:{' '}
              <span className={clsx(variantClasses[status])}>
                {broadcast.status === 'success'
                  ? 'Sent'
                  : broadcast.status === 'pending' && broadcast.requiresApproval
                  ? 'Waiting on approval'
                  : broadcast.status === 'pending'
                  ? 'Scheduled'
                  : broadcast.status ?? 'Unknown'}
              </span>
            </p>
            <span>
              Created: {format(parseISO(broadcast.createdAt.iso), 'MM/dd/yy')}
            </span>
            {broadcast.status === 'pending' && broadcast.date ? (
              <span>
                Scheduled for:{' '}
                {format(parseISO(broadcast.date.iso), 'MM/dd/yy')}
              </span>
            ) : null}
            {broadcast.status === 'success' && broadcast.date ? (
              <span>
                Sent on: {format(parseISO(broadcast.date.iso), 'MM/dd/yy')}
              </span>
            ) : null}
          </div>
          {/* Workaround (Campaigns): to get the Notes for outreach to line up with broadcast (which has a delete button) */}
          {broadcast.status !== 'draft' ? <div className="w-9" /> : null}
        </div>
      </IonNote>
      {broadcast.status === 'draft' ? (
        <IonButton
          slot="end"
          color="danger"
          fill="clear"
          onClick={() => {
            deleteBroadcast.mutate(broadcast.objectId)
          }}
        >
          <IonIcon slot="icon-only" icon={trash} />
        </IonButton>
      ) : null}
    </ListItem>
  )
}

export { BroadcastItem }

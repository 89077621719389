import { IonRow, IonImg } from '@ionic/react'
import { format, parseISO } from 'date-fns'

import { AvatarHeader } from '../../../components/ui/avatar-header'
import { Card, CardContent } from '../../../components/ui/card'
import { BroadcastBase } from '../../../marketing/broadcasts/broadcast.schema'
import {
  useSendBroadcast,
  useUpdateBroadcast,
} from '../../../marketing/broadcasts/mutations'
import { useScheduleApproval } from '../utils'
import { ApprovalButtons } from './approval-buttons'
import { ApprovalItemWrapper } from './approval-item-wrapper'

function BroadcastApprovalItem({
  broadcast,
}: {
  broadcast: NonNullable<BroadcastBase>
}) {
  const sendBroadcast = useSendBroadcast()
  const updateBroadcast = useUpdateBroadcast()

  const { presentModal } = useScheduleApproval((date) =>
    updateBroadcast.mutate(
      {
        objectId: broadcast.objectId,
        broadcast: { date, requiresApproval: false },
        isPartial: true,
      },
      {
        onSuccess: () => {
          sendBroadcast.mutate(broadcast.objectId)
        },
      }
    )
  )

  function handleApprove() {
    updateBroadcast.mutate(
      {
        objectId: broadcast.objectId,
        broadcast: {
          requiresApproval: false,
        },
        isPartial: true,
      },
      {
        onSuccess: () => {
          sendBroadcast.mutate(broadcast.objectId)
        },
      }
    )
  }

  function handleDeny(note: string) {
    updateBroadcast.mutate({
      objectId: broadcast.objectId,
      broadcast: {
        requiresApproval: true,
        note,
      },
      isPartial: true,
    })
  }

  function handleSchedule() {
    presentModal()
  }

  return (
    <ApprovalItemWrapper>
      <AvatarHeader user={broadcast.user} />

      <div className="space-y-5 px-3">
        <IonRow className="justify-between">
          <p className="text-xl">
            {broadcast.date
              ? `Request to send on
            ${format(
              parseISO(broadcast.date.iso),
              "MMM do, yyyy 'at' h:mm aaa"
            )}`
              : 'Request to send after approval.'}
          </p>
          {/* <p className="text-lg">
            {broadcast.smsCount + broadcast.emailCount} total clients
          </p> */}
        </IonRow>
        {broadcast.smsMessage ? (
          <div className="space-y-1">
            <p>Text Message: </p>
            {/* <p className="text-right text-lg">{broadcast.smsCount} clients</p> */}
            <Card>
              <CardContent>
                <div className="whitespace-pre-line">
                  {broadcast.smsMessage}
                </div>
              </CardContent>
            </Card>
          </div>
        ) : null}
        {/* <p className="text-right text-lg">{broadcast.emailCount} clients</p> */}
        {broadcast.unlayerImageFull ? (
          <>
            <p>Email: </p>
            <Card>
              <CardContent>
                <p className="text-lg">
                  <span className="font-semibold">Subject:</span>{' '}
                  {broadcast.emailSubject}
                </p>
                <div className="flex justify-center">
                  <IonImg src={broadcast.unlayerImageFull} />
                </div>
              </CardContent>
            </Card>
          </>
        ) : broadcast.emailMessage ? (
          <div className="space-y-1">
            <p>Email: </p>
            <Card>
              <CardContent>
                <p className="text-lg">
                  <span className="font-semibold">Subject:</span>{' '}
                  {broadcast.emailSubject}
                </p>
                <div className="ion-padding-top whitespace-pre-line">
                  {broadcast.emailMessage}
                </div>
              </CardContent>
            </Card>
          </div>
        ) : null}
        {/* buttons */}
        <ApprovalButtons
          disabled={false}
          onApprove={handleApprove}
          onDeny={handleDeny}
          onSchedule={handleSchedule}
        />
      </div>
    </ApprovalItemWrapper>
  )
}

export { BroadcastApprovalItem }

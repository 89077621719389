import { useQueryClient, useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { ZodSchema } from 'zod'

import { useStylistGroup } from '../contexts/authContext'
import { useToast } from '../contexts/toastContext'
import { heartlandQueryKeys } from '../integrations/heartland/queries'
import { lightspeedQueryKeys } from '../integrations/lightspeed/queries'
import { useLoyalty } from './components/loyalty-redemption-detail'
import { loyaltyQueryKeys } from './queries'
import { logPoints } from './actions'

import type { FormData } from './actions'

function useLogForm<Z extends ZodSchema>(
  schema: Z,
  { onSuccess }: { onSuccess: () => void }
) {
  const { loyaltyType, clientId } = useLoyalty()

  const { groupId } = useStylistGroup()
  const setToast = useToast()
  const queryClient = useQueryClient()
  const { mutate } = useMutation({
    mutationFn: logPoints,
    onSettled: () => {
      loyaltyType === 'heartland'
        ? queryClient.invalidateQueries(heartlandQueryKeys.all)
        : loyaltyType === 'lightspeed'
        ? queryClient.invalidateQueries(lightspeedQueryKeys.all)
        : queryClient.invalidateQueries({
            queryKey: loyaltyQueryKeys.clientLoyalty(clientId),
          })
    },
  })

  const formMethods = useForm<FormData>({
    defaultValues: {
      value: '',
      posId: '',
      visits: '1',
    },
    resolver: zodResolver(schema),
  })

  function submitForm(data: FormData) {
    if (!clientId)
      return setToast({
        message: 'There is no customer present to log points for.',
      })
    mutate(
      {
        ...data,
        clientId,
        groupId,
        type: data.posId ? 'receiptInput' : 'loyaltyPointInput',
      },
      {
        onError: (error) =>
          setToast({
            message:
              error instanceof Error
                ? error.message
                : 'Something went wrong: Unknown Error.',
            color: 'danger',
          }),
        onSuccess: () => {
          setToast({
            message: 'The points have been logged.',
            color: 'yellow',
          })
          onSuccess()
        },
      }
    )
  }

  return { formMethods, submitForm }
}

export { useLogForm }

import { IonPage, IonContent, IonHeader } from '@ionic/react'
import { ErrorBoundary } from 'react-error-boundary'

import ErrorFallback from '../../components/error-fallback'
import AdminHeader from '../../components/header/admin-header'
import { SocialRolesTable } from '../../admin/roles/social/social-roles-table'

function RoleSocialsPage() {
  return (
    <IonPage>
      <IonHeader>
        <AdminHeader title="Roles" showMenu />
      </IonHeader>
      <IonContent className="ion-padding">
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <SocialRolesTable />
        </ErrorBoundary>
      </IonContent>
    </IonPage>
  )
}

export default RoleSocialsPage

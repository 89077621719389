import { IonImg, IonButton, IonIcon } from '@ionic/react'
import { close } from 'ionicons/icons'

import type { PhotoData } from '../../../hooks/use-photo'

function BroadcastPhoto({
  photo,
  onDelete,
}: {
  photo: PhotoData | string
  onDelete: () => void
}) {
  return (
    <div className="flex items-center justify-between">
      <IonImg
        src={typeof photo === 'string' ? photo : photo.pic}
        className="h-20"
      />
      <IonButton fill="clear" size="small" onClick={onDelete}>
        <IonIcon icon={close} />
      </IonButton>
    </div>
  )
}

export { BroadcastPhoto }

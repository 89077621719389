import { IonInput, IonItem, IonLabel } from '@ionic/react'
import { useController } from 'react-hook-form'

import { cn } from '../../utils/cn'

import type { FieldValues, FieldPath, Control } from 'react-hook-form'

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Omit<React.ComponentProps<typeof IonInput>, 'size'> {
  name: TFieldName
  control: Control<TFieldValues>
  label?: string
  size?: 'default' | 'lg'
}

// TODO: CONSOLIDATE WITH FORMINPUT USING VARIANTS
function LoyaltyInput<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  label,
  size = 'default',
  ...props
}: Props<TFieldValues, TFieldName>) {
  const {
    field,
    fieldState: { error },
  } = useController<TFieldValues>({ name, control })

  return (
    <IonItem
      style={{
        '--padding-start': 0,
        '--padding-end': 0,
        '--min-height': 44,
        '--inner-padding-end': 0,
        '--background': 'transparent',
      }}
      lines="none"
    >
      <IonLabel
        className={cn('m-0 flex-initial font-semibold', {
          'text-2xl': size === 'lg',
        })}
        position="stacked"
      >
        {label}
      </IonLabel>

      <div className="flex w-full flex-col">
        <IonInput
          style={{
            '--background': 'var(--ion-background-color)',
            '--padding-start': '.5rem',
          }}
          className={cn('rounded', { 'text-2xl': size === 'lg' })}
          type="text"
          autocapitalize="on"
          {...props}
          value={field.value}
          onIonChange={field.onChange}
          onIonBlur={field.onBlur}
        />
        {error ? (
          <p className="text-ion-color-danger">{error.message}</p>
        ) : null}
      </div>
    </IonItem>
  )
}

export { LoyaltyInput }

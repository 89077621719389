import {
  IonContent,
  IonHeader,
  IonLoading,
  IonPage,
  IonRow,
} from '@ionic/react'
import { useParams } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'

import { useUsersSocialConnections } from '../../../ayrshare/queries'
import AyrshareConnectionItem from '../../../ayrshare/components/ayrshare-connection-item'
import AyrshareUninitConnections from '../../../ayrshare/components/ayrshare-uninit-connections'
import ErrorFallback from '../../../components/error-fallback'
import Header from '../../../components/header'

type UsersConnectionsProps = {
  groupId: string
}

function UsersConnectionsPage() {
  const { groupId } = useParams<{ groupId: string }>()

  return (
    <IonPage>
      <IonHeader>
        <Header
          title="Social Setup"
          backRef="/account/connections/groups"
          btn={null}
        />
      </IonHeader>
      <IonContent>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <UsersConnections groupId={groupId} />
        </ErrorBoundary>
      </IonContent>
    </IonPage>
  )
}

function UsersConnections({ groupId }: UsersConnectionsProps) {
  const usersSocialConnectionsQuery = useUsersSocialConnections(groupId)

  if (usersSocialConnectionsQuery.data) {
    const userConnections = usersSocialConnectionsQuery.data

    return (
      <>
        {userConnections.map((userConnection) => (
          <div
            key={userConnection.objectId}
            className="ion-padding-top ion-padding-horizontal"
          >
            <IonRow className="items-center justify-between">
              <p className="text-xl">{userConnection.displayName}</p>
            </IonRow>
            <div className="space-y-1">
              <p>Business Channels</p>
              {userConnection.ayrshares.length > 0 ? (
                <>
                  {userConnection.ayrshares.map((ayrshare) => (
                    <AyrshareConnectionItem
                      key={ayrshare.refId}
                      id={userConnection.objectId}
                      ayrshare={ayrshare}
                    />
                  ))}
                </>
              ) : (
                <AyrshareUninitConnections
                  type="user"
                  id={userConnection.objectId}
                />
              )}
            </div>
          </div>
        ))}
      </>
    )
  }

  return <IonLoading isOpen message="Loading social connections..." />
}

export default UsersConnectionsPage

import {
  IonItem,
  IonLabel,
  IonCheckbox,
  IonInput,
  IonSelect,
  IonSelectOption,
  SelectChangeEventDetail,
  IonTextarea,
  IonRow,
  IonCol,
  IonImg,
  useIonAlert,
  IonButton,
} from '@ionic/react'

import { addYears, subYears } from 'date-fns'
import { dequal } from 'dequal/lite'
import { Controller, useForm } from 'react-hook-form'

import { getFullName } from '../../utils/format-helpers'
import { typedKeys } from '../../utils/typescript-helpers'
import {
  hasTitleArray,
  hasVendorArray,
  hasTrackingNumberArray,
} from '../../constants/classConstants'
import { useUser } from '../../contexts/authContext'
import { useFlatAccountGroups } from '../../hooks/queries/use-groups'
import { useAssociates } from '../../hooks/queries/use-associate'
import { useUpdateTask } from '../../hooks/tasks/mutations'
import DatePicker from '../../components/ui/ionic/date-picker'
import { alterationSteps } from '../constants'

import type { TaskDetail } from '../tasks.schema'
import type { UpdateTaskInput } from '../../hooks/tasks/mutations'

type Props = { task: TaskDetail }
const today = new Date()

function TaskDetailForm({ task }: Props) {
  const user = useUser()

  const associates = useAssociates()
  const flatAssociates = associates.data?.pages
    .flatMap((d) => d.data)
    .filter((u) => !u.deactivated)

  const groups = useFlatAccountGroups()

  const [presentAutomationAlert] = useIonAlert()

  const updateTask = useUpdateTask()

  const {
    control,
    resetField,
    formState: { dirtyFields },
    handleSubmit,
  } = useForm<UpdateTaskInput>({
    defaultValues: {
      className: task.className,
      completedAt: Boolean(task.completedAt?.iso),
      due: task.due?.iso ?? null,
      groupId: task.group?.objectId,
      isPublic: task.isPublic,
      note: task.note,
      objectId: task.objectId,
      ticket: task.ticket ?? '',
      title: task.title,
      trackingNumber: task.trackingNumber,
      trigger: task.trigger,
      vendor: task.vendor,
      items: task.items?.map((i) => ({
        objectId: i.objectId,
        groupId: i.group?.objectId,
        step: i.step ?? undefined,
        title: i.title ?? undefined,
      })),
      step: task.step ?? undefined,
      userSaleId: task.userSale?.objectId,
      usersIds: task.users?.map((u) => u.objectId),
    },
  })

  const isFormDirty = Object.keys(dirtyFields).length > 0

  function handleUpdateTask(data: UpdateTaskInput) {
    const updatedFields = typedKeys(dirtyFields).reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: data[curr],
      }),
      {} as Partial<UpdateTaskInput>
    )

    if (updatedFields.completedAt && task.className === 'Alteration') {
      presentAutomationAlert({
        header: 'What would you like to do?',
        buttons: [
          {
            text: 'Cancel',
            cssClass: 'px-3 text-ion-color-secondary',
            role: 'cancel',
            handler: () =>
              resetField('completedAt', {
                keepDirty: false,
              }),
          },
          {
            text: 'Complete without starting automation',
            cssClass: 'px-3 text-ion-color-secondary',
            role: 'default',
            handler: () => {
              updateTask.mutate({
                ...updatedFields,
                completedAt: true,
                objectId: task.objectId,
                className: task.className,
              })
            },
          },
          {
            text: 'Complete and start automation',
            cssClass: 'px-3',
            role: 'confirm',
            handler: () => {
              updateTask.mutate({
                ...updatedFields,
                completedAt: true,
                trigger: true,
                objectId: task.objectId,
                className: task.className,
              })
            },
          },
        ],
      })
    } else if (task.trigger && updatedFields.completedAt === false) {
      presentAutomationAlert({
        header:
          'You are about to uncomplete and stop any automations. Do you want to proceed?',
        buttons: [
          {
            text: 'Cancel',
            cssClass: 'px-3 text-ion-color-secondary',
            role: 'cancel',
            handler: () =>
              resetField('completedAt', {
                defaultValue: true,
                keepDirty: false,
              }),
          },
          {
            text: 'OK',
            cssClass: 'px-3',
            role: 'confirm',
            handler: () => {
              updateTask.mutate({
                ...updatedFields,
                completedAt: false,
                trigger: false,
                objectId: task.objectId,
                className: task.className,
              })
            },
          },
        ],
      })
    } else {
      updateTask.mutate({
        ...updatedFields,
        objectId: task.objectId,
        className: task.className,
      })
    }
  }

  return (
    <form onSubmit={handleSubmit(handleUpdateTask)}>
      <IonItem
        color="secondary"
        disabled={task.user.objectId !== user.objectId}
      >
        <IonLabel>Private</IonLabel>
        <Controller
          control={control}
          name="isPublic"
          render={({ field: { value, onChange } }) => (
            <IonCheckbox
              value={!value}
              onIonChange={(e) => onChange(e.detail.checked)}
            />
          )}
        />
      </IonItem>

      {task.className === 'Alteration' ? (
        <>
          <IonItem color="secondary">
            <IonLabel>Ticket #</IonLabel>
            <Controller
              name="ticket"
              control={control}
              render={({ field: { value, onChange } }) => (
                <IonInput
                  value={value}
                  onIonChange={(e) => onChange(e.detail.value)}
                />
              )}
            />
          </IonItem>

          <IonItem color="secondary">
            <IonLabel>Current Location</IonLabel>
            <Controller
              name="groupId"
              control={control}
              render={({ field: { value, onChange } }) => (
                <IonSelect
                  color="secondary"
                  value={value}
                  onIonChange={(e) => {
                    // HACK
                    if (!e.detail.value) return
                    onChange(e.detail.value)
                  }}
                >
                  {groups.data?.map((group) => (
                    <IonSelectOption
                      key={group.objectId}
                      value={group.objectId}
                    >
                      {group.name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              )}
            />
          </IonItem>

          <IonItem color="secondary">
            <IonLabel>Current Step</IonLabel>
            <Controller
              name="step"
              control={control}
              render={({ field: { value, onChange } }) => (
                <IonSelect
                  color="secondary"
                  value={value}
                  onIonChange={(e) => onChange(e.detail.value)}
                >
                  {alterationSteps.map((step) => (
                    <IonSelectOption key={step} value={step}>
                      {step}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              )}
            />
          </IonItem>
          <IonItem color="secondary">
            <IonLabel>Sales Person</IonLabel>
            <Controller
              name="userSaleId"
              control={control}
              render={({ field: { value, onChange } }) => (
                <IonSelect
                  color="secondary"
                  value={value}
                  onIonChange={(e) => onChange(e.detail.value)}
                >
                  {flatAssociates?.map((associate) => (
                    <IonSelectOption
                      key={associate.objectId}
                      value={associate.objectId}
                    >
                      {getFullName(associate)}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              )}
            />
          </IonItem>

          <IonItem color="secondary">
            <IonLabel>Team Members</IonLabel>
            <Controller
              name="usersIds"
              control={control}
              render={({ field: { value, onChange } }) => (
                <IonSelect
                  color="secondary"
                  multiple
                  value={value}
                  onIonChange={(
                    e: CustomEvent<SelectChangeEventDetail<Array<string>>>
                  ) => {
                    // EARLY RETURN IF VALUE WASN'T CHANGED
                    if (
                      dequal(
                        JSON.stringify(e.detail.value),
                        JSON.stringify(value)
                      )
                    ) {
                      return
                    }
                    onChange(e.detail.value)
                  }}
                >
                  {flatAssociates?.map((associate) => (
                    <IonSelectOption
                      key={associate.objectId}
                      value={associate.objectId}
                    >
                      {getFullName(associate)}
                    </IonSelectOption>
                  ))}
                  <IonSelectOption value="123">Rene</IonSelectOption>
                </IonSelect>
              )}
            />
          </IonItem>
        </>
      ) : null}

      {hasTitleArray.includes(task.className) && (
        <IonItem color="secondary">
          <IonLabel position="floating">Title</IonLabel>
          <Controller
            control={control}
            name="title"
            render={({ field: { value, onChange } }) => (
              <IonInput
                color="primary"
                value={value}
                onIonChange={(e) => onChange(e.detail.value)}
              />
            )}
          />
        </IonItem>
      )}

      {hasVendorArray.includes(task.className) && (
        <IonItem color="secondary">
          <IonLabel position="floating">Vendor</IonLabel>
          <Controller
            control={control}
            name="vendor"
            render={({ field: { value, onChange } }) => (
              <IonInput
                color="primary"
                value={value}
                onIonChange={(e) => onChange(e.detail.value)}
              />
            )}
          />
        </IonItem>
      )}
      {hasTrackingNumberArray.includes(task.className) && (
        <IonItem color="secondary">
          <IonLabel position="floating">Tracking</IonLabel>
          <Controller
            control={control}
            name="trackingNumber"
            render={({ field: { value, onChange } }) => (
              <IonInput
                value={value}
                onIonChange={(e) => onChange(e.detail.value)}
              />
            )}
          />
        </IonItem>
      )}

      <IonItem color="secondary">
        <IonLabel position="floating">Note</IonLabel>
        <Controller
          control={control}
          name="note"
          render={({ field: { value, onChange } }) => (
            <IonTextarea
              autoGrow
              autocapitalize="on"
              value={value}
              onIonChange={(e) => onChange(e.detail.value)}
            />
          )}
        />
      </IonItem>
      <IonItem color="secondary">
        <IonLabel>Complete</IonLabel>
        <Controller
          control={control}
          name="completedAt"
          render={({ field: { value, onChange } }) => (
            <IonCheckbox
              disabled={updateTask.isLoading}
              checked={value ?? false}
              onIonChange={(e) => onChange(e.detail.checked)}
            />
          )}
        />
      </IonItem>

      <Controller
        control={control}
        name="due"
        render={({ field: { value, onChange } }) => (
          <DatePicker
            label="Due Date"
            fallback="No Due Date set."
            value={value}
            min={subYears(today, 1)}
            max={addYears(today, 1)}
            onChange={(e) => onChange(e.detail.value)}
          />
        )}
      />

      <IonRow class="ion-justify-content-center">
        {task.photos
          ? task.photos.map((photo, index) => (
              <IonCol size="6" key={index} class="ion-justify-content-center">
                <IonImg src={photo} alt="user added photo" />
              </IonCol>
            ))
          : null}
      </IonRow>

      {/* TODO: use alteration inputs but there are two different control (create v update) */}
      {task.items ? (
        <div className="space-y-5">
          {task.items.map((item, index) => (
            <div key={item.objectId} className="rounded bg-gray-200 p-3">
              <h2 className="text-ion-color-primary font-semibold">
                Alteration Item:
              </h2>
              <div className="flex-1">
                <IonItem color="secondary">
                  <IonLabel>Title</IonLabel>
                  <Controller
                    name={`items.${index}.title`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <IonInput
                        value={value}
                        onIonChange={(e) => onChange(e.detail.value)}
                      />
                    )}
                  />
                </IonItem>
                <IonItem color="secondary">
                  <IonLabel>Current Step</IonLabel>
                  <Controller
                    name={`items.${index}.step`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <IonSelect
                        color="secondary"
                        value={value}
                        onIonChange={(e) => onChange(e.detail.value)}
                      >
                        {alterationSteps.map((step) => (
                          <IonSelectOption key={step} value={step}>
                            {step}
                          </IonSelectOption>
                        ))}
                      </IonSelect>
                    )}
                  />
                </IonItem>
                <IonItem color="secondary" lines="none">
                  <IonLabel>Current Location</IonLabel>
                  <Controller
                    name={`items.${index}.groupId`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <IonSelect
                        color="secondary"
                        value={value}
                        onIonChange={(e) => {
                          // HACK some reason revalidation causes reset to empty string
                          if (!e.detail.value) return
                          onChange(e.detail.value)
                        }}
                      >
                        {groups.data?.map((group) => (
                          <IonSelectOption
                            key={group.objectId}
                            value={group.objectId}
                          >
                            {group.name}
                          </IonSelectOption>
                        ))}
                      </IonSelect>
                    )}
                  />
                </IonItem>
              </div>
            </div>
          ))}
        </div>
      ) : null}
      <IonRow className="ion-padding-vertical justify-center">
        <IonButton type="submit" color="yellow" disabled={!isFormDirty}>
          Save
        </IonButton>
      </IonRow>
    </form>
  )
}

export { TaskDetailForm }

import { IonImg } from '@ionic/react'
import { Listbox } from '@headlessui/react'
import { ResourceType } from '@capawesome/capacitor-cloudinary'
import { formatDistanceToNow, parseISO } from 'date-fns'

import { useToast } from '../../../contexts/toastContext'
import { AvatarHeader } from '../../../components/ui/avatar-header'
import CldVideoPlayer from '../../../components/cloudinary/cld-video-player'
import ExpandableText from '../../../components/ui/expandable-text'
import { ayrsharePlatforms } from '../../../ayrshare/ayrshare-platforms'
import { useDefaultAdminSocialConnections } from '../../../ayrshare/queries'
import { OneshopLogo } from '../../../ayrshare/social-logos'
import {
  useApprovePost,
  useDenyPost,
  useSchedulePost,
} from '../../../social-sharing/posts/mutations'
import { useSocialChannels } from '../../../social-sharing/use-social-channels'
import { useScheduleApproval } from '../utils'
import { ApprovalButtons } from './approval-buttons'
import { ApprovalItemWrapper } from './approval-item-wrapper'

import type { PostOverview } from '../../../social-sharing/posts/posts.schema'

function PostApprovalItem({ post }: { post: PostOverview }) {
  const adminConnectionsQuery = useDefaultAdminSocialConnections()
  const adminConnections = adminConnectionsQuery.data

  const [selectedAdminChannels, updateSelectedAdminChannels] =
    useSocialChannels(adminConnections)

  const setToast = useToast()

  const approvePost = useApprovePost()
  const denyPost = useDenyPost()
  const schedulePost = useSchedulePost()

  const { presentModal } = useScheduleApproval((date) =>
    schedulePost.mutate({
      postId: post.objectId,
      date,
      accountShare: {
        lookId: post.look.objectId,
        post: post.post,
        accountAyrshares: selectedAdminChannels,
      },
    })
  )

  function validatePost() {
    if (
      post.look.cloudinary?.data?.resource_type !== ResourceType.Video &&
      Object.values(selectedAdminChannels).some((platforms) =>
        platforms.includes('tiktok')
      )
    ) {
      setToast({
        message:
          'You cannot share an image to TikTok. Please deselct that channel.',
      })
      return false
    }

    return true
  }

  function handleSchedulePost() {
    if (validatePost()) presentModal()
  }

  function handleApprovePost() {
    if (validatePost())
      approvePost.mutate({
        postId: post.objectId,
        accountShare: {
          lookId: post.look.objectId,
          post: post.post,
          accountAyrshares: selectedAdminChannels,
        },
      })
  }

  function handleDenyPost(note: string) {
    denyPost.mutate({
      postId: post.objectId,
      note,
    })
  }

  return (
    <ApprovalItemWrapper>
      <AvatarHeader user={post.user} />

      {/* media */}
      {post.look.cloudinary.data.resource_type === ResourceType.Video ? (
        <div className="aspect-h-16 aspect-w-9">
          <CldVideoPlayer
            id={post.look.objectId}
            publicId={post.look.cloudinary.data.public_id}
            autoplayMode="on-scroll"
            playsInline
            loop
            muted
          />
        </div>
      ) : (
        <div className="aspect-h-4 aspect-w-3">
          <IonImg
            src={post.look.cloudinary.data.secure_url}
            className="bg-ion-background-color size-full object-cover object-top"
          />
        </div>
      )}
      {/* info */}
      <div className="space-y-3 p-2">
        {/* meta info */}
        <div>
          <div className="leading-tight">
            <ExpandableText characterLimit={60}>{post.post}</ExpandableText>
          </div>
          <div className="text-sm text-gray-400">
            {`${formatDistanceToNow(
              parseISO(post.createdAt.iso)
            )} ago`.toUpperCase()}
          </div>
        </div>

        {/* Social Channels */}
        <h2>Post to Main Accounts</h2>
        {adminConnections
          ?.filter((ayrshare) => ayrshare.type === 'business')
          .map((ayrshareConnection) => (
            <div key={ayrshareConnection.objectId} className="flex space-x-4">
              <Listbox
                multiple
                value={selectedAdminChannels[ayrshareConnection.objectId] ?? []}
                onChange={(e) =>
                  updateSelectedAdminChannels((draft) => {
                    draft[ayrshareConnection.objectId] = e
                  })
                }
              >
                <Listbox.Options static className="flex items-start gap-4">
                  {ayrsharePlatforms.map((connection) => {
                    const Icon = connection.icon
                    const disabledSocialAccount =
                      !ayrshareConnection.activeSocialAccounts?.includes(
                        connection.name
                      )

                    return (
                      <Listbox.Option
                        key={connection.name}
                        value={connection.name}
                        className="ui-selected:opacity-100 ui-not-selected:opacity-30"
                        disabled={disabledSocialAccount}
                      >
                        <Icon />
                      </Listbox.Option>
                    )
                  })}
                </Listbox.Options>
              </Listbox>
            </div>
          ))}

        <h2>Post to Creator's Accounts</h2>
        <div className="flex space-x-4">
          <ul className="flex items-start gap-4">
            {ayrsharePlatforms.map((connection) => {
              const Icon = connection.icon
              return (
                <li
                  key={connection.name}
                  className={
                    post.platforms.includes(connection.name)
                      ? 'opacity-100'
                      : 'opacity-30'
                  }
                >
                  <Icon />
                </li>
              )
            })}
            <li
              className={
                post.platforms.includes('oneshop')
                  ? 'opacity-100'
                  : 'opacity-30'
              }
            >
              <OneshopLogo />
            </li>
          </ul>
        </div>

        {/* buttons */}
        <ApprovalButtons
          disabled={!adminConnections}
          onApprove={handleApprovePost}
          onDeny={handleDenyPost}
          onSchedule={handleSchedulePost}
        />
      </div>
    </ApprovalItemWrapper>
  )
}

export { PostApprovalItem }

import * as React from 'react'
import {
  IonCard,
  IonCardContent,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonTextarea,
  IonButton,
  isPlatform,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
} from '@ionic/react'
import produce from 'immer'

import { Dismiss, Okay } from '../../utils/common-strings'
import { formatName } from '../../utils/format-helpers'
import saveManualCommunication from '../../utils/save-communication'
import { useStylistGroup, useUser } from '../../contexts/authContext'
import { useToast } from '../../contexts/toastContext'
import useNextContact from '../../hooks/use-next-contact'
import { useQueryParams } from '../../hooks/use-query-params'
import useSendCommunication from '../../hooks/use-send-communication'
import CloseButton from '../ui/buttons/close'
import SimpleSelectPhoto from './SimpleSelectPhoto'
import TakePhoto from './TakePhoto'

const reducer = produce((draft, action) => {
  const { type } = action

  switch (type) {
    case 'UPDATE_FIELD':
      const { name, value } = action
      draft[name] = value
      break
    case 'SELECT_TEMPLATE':
      const { id, template } = action

      if (template) {
        draft.template = id
        draft.subject = template.subject
        draft.message = template.body
      }
      break
    case 'SUBMIT':
      draft.loading = true
      break
    case 'SUCCESS':
      draft.message = ''
      draft.subject = ''
      draft.loading = false
      draft.template = null
      break
    case 'FAILURE':
      draft.loading = false
      break
    default:
      break
  }
})

function EmailCard({
  to,
  templates: emailTemplates,
  clientId,
  handleCommunicationCancel,
  onEmailSend,
}) {
  const user = useUser()
  const stylistGroup = useStylistGroup()

  const URLquery = useQueryParams()
  const callListId = URLquery.get('callListId')

  const [photos, setPhotos] = React.useState([])
  const [state, dispatch] = React.useReducer(reducer, {
    template: null,
    subject: '',
    message: '',
    loading: false,
  })
  const { template, subject, message, loading } = state

  const setToast = useToast()
  const { nextContactDate, setNextContactDate, dates, saveNextContactDate } =
    useNextContact()
  const { sendMessage } = useSendCommunication()

  // TODO: workaround to filter out empty templates
  const templates = emailTemplates.filter((template) => template.body)

  const isBrowser = !isPlatform('capacitor')
  const canSendApiPhotos = user.hasSendGrid

  function composeAttachments() {
    return photos.map((photo) => ({
      name: photo.name,
      content: photo.base64String,
      fileUri: photo.fileUri,
    }))
  }

  async function sendEmail() {
    dispatch({ type: 'SUBMIT' })

    if (!subject || !message) {
      dispatch({ type: 'FAILURE' })
      return setToast({
        message: 'Please fill out required subject and message fields',
      })
    }

    try {
      await sendMessage({
        user,
        clientId,
        to,
        subject,
        body: message,
        attachments: canSendApiPhotos ? photos : composeAttachments(),
        pointers: {
          emailTemplateId: state.template,
          callListId,
        },
      })
      // sendMessage needs to be called before saveNextContactDate
      await saveNextContactDate(clientId)
      dispatch({ type: 'SUCCESS' })
      handleCommunicationCancel()
      if (onEmailSend) onEmailSend()
    } catch (err) {
      dispatch({ type: 'FAILURE' })
      const errorMessage = err instanceof Error ? err.message : 'Unknown error.'

      setToast({
        message: `Oops, something went wrong while sending the email. ${errorMessage}`,
        color: 'danger',
      })
    }
  }

  async function logEmail() {
    dispatch({ type: 'SUBMIT' })
    try {
      await saveManualCommunication('email', {
        user,
        clientId,
        to,
        subject,
        body: message,
        attachments: canSendApiPhotos ? photos : composeAttachments(),
        log: true,
        pointers: {
          emailTemplateId: state.template,
          callListId,
        },
      })
      // sendMessage needs to be called before saveNextContactDate
      await saveNextContactDate(clientId)
      dispatch({ type: 'SUCCESS' })
      handleCommunicationCancel()
      if (onEmailSend) onEmailSend()
    } catch (err) {
      dispatch({ type: 'FAILURE' })
      const errorMessage = err instanceof Error ? err.message : 'Unknown error.'

      setToast({
        message: `Oops, something went wrong while logging the email. ${errorMessage}`,
        color: 'danger',
      })
    }
  }

  function handleFormUpdate(e) {
    const { name, value } = e.target
    dispatch({ type: 'UPDATE_FIELD', name, value })
  }

  async function handleTemplateSelect(e) {
    const id = e.target.value
    const template = templates.find((t) => t.objectId === id)

    if (template) {
      template.body =
        template.body
          ?.replaceAll('<Stylist>', formatName(user.firstName))
          ?.replaceAll('<Store>', stylistGroup.name)
          ?.replaceAll('<StylistId>', user.objectId) ?? ''

      dispatch({ type: 'SELECT_TEMPLATE', id, template })
      // reset photos and exit early if no template attachments
      if (!template.attachments) {
        setPhotos([])
        return
      }

      // add template attachments if user has sendgrid
      if (canSendApiPhotos) {
        setPhotos(template.attachments)
      }
    }
  }

  return (
    <IonCard className="followup-card" color="secondary">
      <IonCardContent>
        <IonItem color="secondary">
          <div tabIndex={0} />
          <IonLabel>Select a Template</IonLabel>
          <IonSelect
            id="email"
            interfaceOptions={{ cssClass: 'dark-ionic-filter' }}
            value={template}
            onIonChange={handleTemplateSelect}
            okText={Okay}
            cancelText={Dismiss}
          >
            {templates.map((template, index) => (
              <IonSelectOption key={index} value={template.objectId}>
                {template.name}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
        <IonItem color="secondary">
          <IonLabel position="floating">Subject:</IonLabel>
          <IonInput
            name="subject"
            value={subject}
            onIonChange={handleFormUpdate}
          />
        </IonItem>
        <IonItem color="secondary">
          <IonLabel position="floating">Type Your Message Here:</IonLabel>
          <IonTextarea
            name="message"
            value={message}
            onIonChange={handleFormUpdate}
            autoGrow
            autocapitalize="on"
            rows={Math.max((message || '').split(/\r\n|\r|\n/).length, 5)}
          />
        </IonItem>
        <IonItem className="ion-margin-top p-0" color="secondary">
          <IonLabel>Next Contact:</IonLabel>
          <IonSelect
            id="next-contact-select"
            value={nextContactDate}
            onIonChange={(e) => setNextContactDate(e.target.value)}
            okText={Okay}
            cancelText={Dismiss}
            interfaceOptions={{ cssClass: 'dark-ionic-filter' }}
          >
            {dates.map(({ label, value }) => (
              <IonSelectOption key={value} value={value}>
                {label}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
        {canSendApiPhotos ? (
          <>
            <div className="flex">
              <TakePhoto
                onTakePhoto={({
                  name,
                  pic,
                  ios,
                  contentType,
                  fileUri,
                  base64String,
                }) =>
                  setPhotos((photos) => [
                    ...photos,
                    { name, pic, ios, contentType, fileUri, base64String },
                  ])
                }
                mode="standalone"
              />
              {/* For browser this button behaves the same as above, hide it */}
              {!isBrowser ? (
                <SimpleSelectPhoto
                  onSelectPhoto={({
                    name,
                    pic,
                    ios,
                    contentType,
                    fileUri,
                    base64String,
                  }) =>
                    setPhotos((photos) => [
                      ...photos,
                      { name, pic, ios, contentType, fileUri, base64String },
                    ])
                  }
                  dark
                />
              ) : null}
            </div>
            {photos.length > 0 ? (
              <IonGrid>
                <IonRow>
                  {photos.map((photo) => (
                    <IonCol size="4" sizeSm="3" key={photo.name ?? photo}>
                      <div className="ion-margin-vertical relative">
                        <IonImg src={photo.pic ?? photo} />
                        <CloseButton
                          inner
                          onClick={() =>
                            setPhotos((photos) =>
                              photos.filter((p) => {
                                return typeof p === 'string'
                                  ? p !== photo
                                  : p.name !== photo.name
                              })
                            )
                          }
                        />
                      </div>
                    </IonCol>
                  ))}
                </IonRow>
              </IonGrid>
            ) : null}
          </>
        ) : null}
        <div className="buttons mt-2">
          <div>
            <IonButton
              fill="outline"
              disabled={loading}
              onClick={handleCommunicationCancel}
            >
              CANCEL
            </IonButton>
          </div>
          <div className="space-x-2">
            <IonButton fill="outline" disabled={loading} onClick={logEmail}>
              LOG
            </IonButton>
            <IonButton disabled={loading} onClick={sendEmail}>
              SEND
            </IonButton>
          </div>
        </div>
      </IonCardContent>
    </IonCard>
  )
}

export default EmailCard

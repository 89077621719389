function OneshopLogo() {
  return (
    <div className="flex flex-col items-center space-y-1">
      <span className="size-10 bg-ion-color-yellow flex rounded-full">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlSpace="preserve"
          viewBox="0 0 321.8 321.8"
        >
          <g id="Capa_1">
            <path
              d="M59.3 238.3c0 19.8 16.2 36 36 36h131.3c19.8 0 36-16.2 36-36v-4.4H59.3v4.4zM231.4 103.2c0 38.9-31.6 70.4-70.5 70.4s-70.5-31.5-70.5-70.4c-17.5 2.4-31.1 17.5-31.1 35.6v68.5h203.3v-68.5c-.1-18.1-13.7-33.2-31.2-35.6z"
              className="st0"
            />
            <path
              d="m122.6 83.7 76.6 39c-10.8 21.2-36.6 29.6-57.8 18.8-14.9-7.6-23.5-22.7-23.5-38.3H90.4c0-38.9 31.6-70.5 70.5-70.5s70.5 31.6 70.5 70.5h-27.5c0-23.7-19.2-43-43-43-16.7 0-31.2 9.6-38.3 23.5z"
              className="st0"
            />
          </g>
        </svg>
      </span>
      <span className="text-xs">OneShop</span>
    </div>
  )
}

function FacebookPagesLogo() {
  return (
    <div className="flex flex-col items-center space-y-1">
      <span className="size-10 flex">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlSpace="preserve"
          viewBox="0 0 360 360"
        >
          <circle cx="50%" cy="50%" r="50%" fill="#4478bc" />
          <path
            d="M327.54 180.61c0-81.48-66.05-147.54-147.54-147.54S32.46 99.12 32.46 180.61c0 73.64 53.95 134.68 124.49 145.75v-103.1h-37.46v-42.65h37.46v-32.5c0-36.98 22.03-57.4 55.73-57.4 16.14 0 33.03 2.88 33.03 2.88v36.31H227.1c-18.33 0-24.04 11.37-24.04 23.04v27.68h40.92l-6.54 42.65h-34.38v103.1c70.53-11.09 124.48-72.12 124.48-145.76z"
            style={{ fill: '#4478bc' }}
          />
          <path
            d="m237.43 223.25 6.54-42.65h-40.92v-27.68c0-11.67 5.72-23.04 24.04-23.04h18.61v-36.3s-16.88-2.88-33.03-2.88c-33.7 0-55.73 20.42-55.73 57.4v32.5h-37.46v42.65h37.46v103.1c7.51 1.18 15.21 1.79 23.05 1.79s15.54-.61 23.05-1.79v-103.1h34.39z"
            style={{ fill: '#fff' }}
          />
        </svg>
      </span>
      <span className="text-xs">Pages</span>
    </div>
  )
}

function FacebookGroupsLogo() {
  return (
    <div className="flex flex-col items-center space-y-1">
      <span className="size-10 flex">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlSpace="preserve"
          viewBox="0 0 360 360"
        >
          <circle cx="50%" cy="50%" r="50%" fill="#4478bc" />
          <path
            d="M327.54 180.61c0-81.48-66.05-147.54-147.54-147.54S32.46 99.12 32.46 180.61c0 73.64 53.95 134.68 124.49 145.75v-103.1h-37.46v-42.65h37.46v-32.5c0-36.98 22.03-57.4 55.73-57.4 16.14 0 33.03 2.88 33.03 2.88v36.31H227.1c-18.33 0-24.04 11.37-24.04 23.04v27.68h40.92l-6.54 42.65h-34.38v103.1c70.53-11.09 124.48-72.12 124.48-145.76z"
            style={{ fill: '#4478bc' }}
          />
          <path
            d="m237.43 223.25 6.54-42.65h-40.92v-27.68c0-11.67 5.72-23.04 24.04-23.04h18.61v-36.3s-16.88-2.88-33.03-2.88c-33.7 0-55.73 20.42-55.73 57.4v32.5h-37.46v42.65h37.46v103.1c7.51 1.18 15.21 1.79 23.05 1.79s15.54-.61 23.05-1.79v-103.1h34.39z"
            style={{ fill: '#fff' }}
          />
        </svg>
      </span>
      <span className="text-xs">Groups</span>
    </div>
  )
}

function GoogleLogo() {
  return (
    <span className="size-10 flex">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        viewBox="0 0 360 360"
      >
        <path
          d="M326.62 173.49c0 81.11-66.36 147.48-147.48 147.48-81.11 0-147.48-66.36-147.48-147.48 0-81.11 66.36-147.48 147.48-147.48 81.11 0 147.48 66.36 147.48 147.48z"
          style={{ fill: '#f3f3f3' }}
        />
        <path
          d="M123.71 173.49c0-6.33 1.05-12.39 2.93-18.09L93.8 130.33c-6.4 12.99-10 27.63-10 43.16 0 15.51 3.6 30.14 9.99 43.13l32.82-25.12a57.392 57.392 0 0 1-2.9-18.01"
          style={{ fill: '#f2ba45' }}
        />
        <path
          d="M181.36 115.92c13.75 0 26.16 4.87 35.92 12.84l28.38-28.34c-17.3-15.06-39.47-24.36-64.3-24.36-38.55 0-71.69 22.05-87.56 54.26l32.83 25.07c7.57-22.95 29.13-39.47 54.73-39.47"
          style={{ fill: '#db493c' }}
        />
        <path
          d="M181.36 231.06c-25.59 0-47.16-16.52-54.72-39.48L93.8 216.64c15.87 32.22 49 54.27 87.56 54.27 23.8 0 46.51-8.45 63.57-24.28l-31.17-24.09c-8.8 5.54-19.87 8.52-32.4 8.52"
          style={{ fill: '#37a759' }}
        />
        <path
          d="M274.48 173.49c0-5.76-.89-11.96-2.22-17.71h-90.91v37.64h52.33c-2.62 12.83-9.74 22.7-19.93 29.12l31.17 24.09c17.91-16.62 29.56-41.39 29.56-73.14"
          style={{ fill: '#567bb8' }}
        />
      </svg>
    </span>
  )
}

function InstagramLogo() {
  return (
    <span className="size-10 flex rounded-full bg-instagram">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        viewBox="0 0 128 128"
      >
        <path
          fill="#FFF"
          d="M82.333 104H45.667C33.72 104 24 94.281 24 82.333V45.667C24 33.719 33.72 24 45.667 24h36.666C94.281 24 104 33.719 104 45.667v36.667C104 94.281 94.281 104 82.333 104zM45.667 30.667c-8.271 0-15 6.729-15 15v36.667c0 8.271 6.729 15 15 15h36.666c8.271 0 15-6.729 15-15V45.667c0-8.271-6.729-15-15-15H45.667z"
        />
        <path
          fill="#FFF"
          d="M64 84c-11.028 0-20-8.973-20-20 0-11.029 8.972-20 20-20s20 8.971 20 20c0 11.027-8.972 20-20 20zm0-33.333c-7.352 0-13.333 5.981-13.333 13.333 0 7.353 5.981 13.333 13.333 13.333S77.333 71.353 77.333 64c0-7.352-5.98-13.333-13.333-13.333z"
        />
        <circle cx="85.25" cy="42.75" r="4.583" fill="#FFF" />
      </svg>
    </span>
  )
}

function LinkedinLogo() {
  return (
    <span className="size-10 flex">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        id="Layer_1"
        x="0"
        y="0"
        version="1.1"
        viewBox="0 0 360 360"
      >
        <circle cx="50%" cy="50%" r="50%" fill="#3075ac" />
        <path
          d="M327.84 180c0 81.15-66.53 147.54-147.84 147.54S32.16 261.15 32.16 180C32.16 98.85 98.69 32.46 180 32.46S327.84 98.85 327.84 180z"
          style={{ fill: '#3075ac' }}
        />
        <path
          d="M101.95 152.99h33.45v107.4h-33.45v-107.4zm16.73-53.39c10.69 0 19.38 8.68 19.38 19.37 0 10.68-8.69 19.34-19.38 19.34-10.72 0-19.39-8.66-19.39-19.34.01-10.69 8.67-19.37 19.39-19.37M156.38 152.99h32.09v14.69h.46c4.46-8.45 15.37-17.35 31.64-17.35 33.87 0 40.13 22.24 40.13 51.15v58.91h-33.44v-52.23c0-12.45-.22-28.48-17.39-28.48-17.4 0-20.06 13.58-20.06 27.59v53.12h-33.44v-107.4z"
          style={{ fill: '#fff' }}
        />
      </svg>
    </span>
  )
}

function TiktokLogo() {
  return (
    <span className="size-10 flex">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        viewBox="0 0 360 360"
      >
        <circle cx="50%" cy="50%" r="50%" fill="#010101" />
        <path
          d="M218.75 154.61c11.96 8.54 26.61 13.57 42.43 13.57v-30.43c-2.99 0-5.98-.31-8.91-.93v23.95c-15.82 0-30.47-5.03-42.43-13.57v62.1c0 31.07-25.2 56.25-56.28 56.25-11.6 0-22.38-3.5-31.33-9.51 10.22 10.44 24.47 16.92 40.24 16.92 31.08 0 56.28-25.18 56.28-56.25v-62.1zm10.99-30.7a42.42 42.42 0 0 1-10.99-24.83v-3.91h-8.44c2.12 12.11 9.37 22.46 19.43 28.74zM141.89 232.2a25.626 25.626 0 0 1-5.25-15.58c0-14.21 11.52-25.73 25.74-25.73 2.65 0 5.28.4 7.81 1.2v-31.11c-2.95-.4-5.93-.57-8.91-.51v24.22a25.57 25.57 0 0 0-7.81-1.2c-14.22 0-25.74 11.52-25.74 25.73 0 10.04 5.76 18.74 14.16 22.98z"
          style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#ee2857' }}
        />
        <path
          d="M209.84 147.2c11.96 8.54 26.61 13.57 42.43 13.57v-23.95a42.587 42.587 0 0 1-22.53-12.91c-10.06-6.27-17.31-16.63-19.44-28.75h-22.18v121.55c-.05 14.17-11.56 25.64-25.74 25.64-8.36 0-15.79-3.98-20.49-10.15-8.4-4.24-14.16-12.94-14.16-22.98 0-14.21 11.52-25.73 25.74-25.73 2.72 0 5.35.42 7.81 1.2v-24.22c-30.53.63-55.09 25.57-55.09 56.24 0 15.31 6.12 29.19 16.04 39.33 8.95 6.01 19.73 9.51 31.33 9.51 31.08 0 56.28-25.18 56.28-56.25v-62.1z"
          style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#fff' }}
        />
        <path
          d="M252.27 136.82v-6.48a42.53 42.53 0 0 1-22.53-6.43 42.621 42.621 0 0 0 22.53 12.91zm-41.96-41.66c-.2-1.16-.36-2.32-.47-3.49v-3.91h-30.63V209.3c-.05 14.17-11.55 25.64-25.74 25.64-4.17 0-8.1-.99-11.58-2.75 4.7 6.17 12.13 10.15 20.49 10.15 14.19 0 25.69-11.47 25.74-25.64V95.16h22.19zm-49.02 65.31v-6.9a55.42 55.42 0 0 0-7.72-.52c-31.08 0-56.28 25.18-56.28 56.25 0 19.48 9.9 36.64 24.95 46.74-9.92-10.14-16.04-24.02-16.04-39.33-.01-30.67 24.55-55.61 55.09-56.24z"
          style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#34c0c9' }}
        />
      </svg>
    </span>
  )
}

function TwitterLogo() {
  return (
    <span className="size-10 flex">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        viewBox="0 0 360 360"
      >
        <circle cx="50%" cy="50%" r="50%" style={{ fill: '#35c6f4' }} />
        <path
          d="M261.52 129.45c-5.11 2.31-10.76 3.71-16.27 4.64 2.62-.44 6.46-5.18 8-7.09 2.35-2.92 4.3-6.25 5.4-9.85.14-.28.25-.63-.04-.85-.35-.14-.58-.07-.86.07-6.15 3.3-12.52 5.69-19.29 7.38-.56.14-.98 0-1.33-.35-.56-.63-1.09-1.23-1.69-1.72-2.85-2.42-5.9-4.32-9.35-5.73-4.52-1.84-9.46-2.62-14.33-2.31-4.74.31-9.39 1.64-13.6 3.84s-7.98 5.27-10.97 8.96c-3.1 3.82-5.38 8.34-6.55 13.12-1.13 4.62-1.04 9.19-.35 13.85.1.77.04.88-.67.77-26.5-3.94-48.47-13.43-66.3-33.74-.77-.88-1.19-.88-1.83.07-7.8 11.7-4.02 30.49 5.73 39.72 1.3 1.23 2.64 2.46 4.07 3.59-.52.11-7.01-.6-12.79-3.59-.77-.49-1.16-.21-1.23.67-.07 1.26.04 2.43.21 3.8 1.5 11.86 9.71 22.82 20.95 27.1 1.34.56 2.81 1.06 4.25 1.3-2.56.56-5.2.96-12.55.39-.91-.18-1.26.28-.91 1.16 5.52 15.05 17.44 19.53 26.36 22.08 1.2.21 2.39.21 3.59.49-.07.11-.14.11-.21.21-2.96 4.51-13.22 7.87-18 9.54-8.69 3.04-18.16 4.4-27.34 3.5-1.47-.22-1.78-.2-2.18 0-.4.25-.05.6.42.98 1.86 1.23 3.76 2.32 5.7 3.38 5.83 3.09 11.92 5.56 18.28 7.31 32.85 9.07 69.85 2.41 94.51-22.1 19.37-19.24 26.16-45.78 26.16-72.35 0-1.04 1.23-1.61 1.96-2.16 5.04-3.79 9.1-8.32 12.86-13.38.84-1.14.85-2.15.85-2.56v-.14c0-.43-.01-.3-.66 0z"
          style={{ fill: '#fff' }}
        />
      </svg>
    </span>
  )
}

function YoutubeLogo() {
  return (
    <span className="size-10 flex">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        viewBox="0 0 360 360"
      >
        <circle cx="50%" cy="50%" r="50%" style={{ fill: '#ed2224' }} />
        <path
          d="M258.99 158.92c0-19.57-15.86-35.43-35.43-35.43h-90.59c-19.57 0-35.43 15.86-35.43 35.43v42.16c0 19.57 15.86 35.43 35.43 35.43h90.59c19.57 0 35.43-15.86 35.43-35.43v-42.16zm-53.28 24.24-40.62 20.1c-1.59.86-7-.29-7-2.1V159.9c0-1.83 5.46-2.99 7.05-2.08l38.89 21.16c1.62.93 3.33 3.29 1.68 4.18z"
          style={{ fill: '#fff' }}
        />
      </svg>
    </span>
  )
}

export {
  FacebookPagesLogo,
  FacebookGroupsLogo,
  GoogleLogo,
  InstagramLogo,
  LinkedinLogo,
  OneshopLogo,
  TiktokLogo,
  TwitterLogo,
  YoutubeLogo,
}

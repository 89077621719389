import * as React from 'react'
import { IonIcon, IonModal } from '@ionic/react'
import { bag } from 'ionicons/icons'

import SelectShopWithModal from '../../../components/shopwith/select-shop-with-modal'

function BroadcastShopWithSelect({
  onConfirm,
}: {
  onConfirm: (
    type: 'productIds' | 'lookIds' | 'collectionIds',
    value: Array<string>
  ) => void
}) {
  const ref = React.useRef<HTMLIonModalElement | null>(null)

  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <>
      <button
        className="ml-1 flex size-8 items-center justify-center bg-transparent disabled:opacity-50"
        onClick={() => setIsOpen(true)}
      >
        <IonIcon className="size-full" color="secondary" icon={bag} />
      </button>

      <IonModal ref={ref} isOpen={isOpen} onDidDismiss={() => setIsOpen(false)}>
        <SelectShopWithModal
          onClose={() => setIsOpen(false)}
          onConfirm={(type, ids) => {
            onConfirm(type, ids)
            setIsOpen(false)
          }}
        />
      </IonModal>
    </>
  )
}

export { BroadcastShopWithSelect }

import * as React from 'react'

import useBrands from '../../../hooks/queries/use-brands'
import SearchableSelect from '../../ui/form/searchable-select'

import type { SelectProps } from './types'

function BrandSelect({
  isAdmin,
  selectedItems,
  setSelectedItems,
}: SelectProps) {
  const [isOpen, setIsOpen] = React.useState(false)

  const brandsQuery = useBrands({ admin: isAdmin, enabled: isOpen })

  return (
    <SearchableSelect
      onOpen={() => setIsOpen(true)}
      label="Brands"
      items={brandsQuery.data ?? []}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
    />
  )
}

export default BrandSelect

import * as React from 'react'
import { IonLabel, IonSkeletonText } from '@ionic/react'
import { useRouteMatch } from 'react-router-dom'
import { format, parseISO } from 'date-fns'

import { isNewClient } from '../../utils/client-helpers'
import { Client } from '../../hooks/clients/queries'
import { getFullName } from '../../utils/format-helpers'
import Avatar from '../avatar'
import Currency from '../Currency'
import { ListItem, ListItemHeading, ListItemSubHeading } from '../ui/list-item'

type ClientItemProps = {
  client: Client
  actions?: React.ReactNode
}

function ClientItem({ client, actions }: ClientItemProps) {
  const { url } = useRouteMatch()

  const fullName = getFullName(client)

  return (
    <ListItem routerLink={`${url}/client/${client.objectId}`}>
      <Avatar user={client} />

      <IonLabel className="ml-4">
        <ListItemHeading>
          <div className="flex items-center gap-x-2">
            <span>{fullName}</span>
            {isNewClient(client) ? (
              <span className="bg-ion-color-yellow text-ion-color-primary inline-flex items-center rounded-full px-1.5 py-0.5 text-xs font-medium">
                New
              </span>
            ) : null}
            <span className="text-sm font-normal">
              <Currency abbreviate value={client.salesTotal} />
            </span>
          </div>
        </ListItemHeading>
        {client.lastSale ? (
          <ListItemSubHeading>
            {`Last Shopped: ${format(
              parseISO(client.lastSale.iso),
              'MM/dd/yyyy'
            )}`}
          </ListItemSubHeading>
        ) : null}
        {client.lastContact ? (
          <ListItemSubHeading>
            {`Last Contacted: ${format(
              parseISO(client.lastContact.iso),
              'MM/dd/yyyy'
            )}`}
          </ListItemSubHeading>
        ) : null}
      </IonLabel>
      {actions && <div slot="end">{actions}</div>}
    </ListItem>
  )
}

function ClientItemSkeleton() {
  return (
    <ListItem>
      <div className="border-ion-color-secondary size-9 flex flex-col items-center justify-center overflow-hidden rounded-2xl border">
        <IonSkeletonText animated className="size-full m-0"></IonSkeletonText>
      </div>

      <IonLabel className="ml-2">
        <h3 className="w-[65%]">
          <IonSkeletonText className="m-0 w-full leading-normal"></IonSkeletonText>
        </h3>

        <p className="w-2/5">
          <IonSkeletonText className="m-0 w-full leading-normal"></IonSkeletonText>
        </p>

        <p className="w-2/5">
          <IonSkeletonText className="m-0 w-full leading-normal"></IonSkeletonText>
        </p>
      </IonLabel>
    </ListItem>
  )
}

export default ClientItem
export { ClientItemSkeleton }

import * as React from 'react'
import clsx from 'clsx'

import type { ReactNode } from 'react'

type CardContext = {
  narrow: boolean
}

const cardContext = React.createContext<CardContext | null>(null)

function useCardSettings() {
  const context = React.useContext(cardContext)
  if (!context) {
    throw new Error(
      'useCardSettings has to be used within <cardContext.Provider>'
    )
  }

  return context
}

function Card({
  children,
  narrow = false,
}: {
  children: ReactNode
  narrow?: boolean
}) {
  return (
    <cardContext.Provider value={{ narrow }}>
      <div className="relative flex w-full flex-col rounded-lg bg-ion-color-secondary text-ion-color-primary">
        {children}
      </div>
    </cardContext.Provider>
  )
}

function CardHeader({ children }: { children: ReactNode }) {
  const { narrow } = useCardSettings()

  return (
    <div
      className={clsx(
        'flex flex-col items-center space-y-0.5 pb-0',
        narrow ? 'p-1' : 'p-4'
      )}
    >
      {children}
    </div>
  )
}

function CardTitle({ children }: { children: ReactNode }) {
  return (
    <h2 className="line-clamp-2 font-semibold leading-none tracking-tight text-ion-color-primary">
      {children}
    </h2>
  )
}

function CardContent({ children }: { children: ReactNode }) {
  const { narrow } = useCardSettings()

  return (
    <div
      className={clsx('flex flex-1 flex-col', narrow ? 'p-0 pb-0.5' : 'p-4')}
    >
      {children}
    </div>
  )
}

export { Card, CardHeader, CardTitle, CardContent }

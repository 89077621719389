import {
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  SelectChangeEventDetail,
} from '@ionic/react'

import { MONTHS } from '../../../constants/misc'

type Props = {
  label: string
  color?: 'dark' | 'light'
  value?: number | null
  placeholder?: string
  disabled?: boolean
  onChange?: (e: CustomEvent<SelectChangeEventDetail<number>>) => void
}

const pickerMonths = MONTHS.map((month, index) => ({
  text: month,
  value: index,
}))

function MonthPicker({
  label,
  value,
  color = 'light',
  placeholder = 'Choose a date...',
  disabled = false,
  onChange,
}: Props) {
  return (
    <IonItem
      color={color === 'light' ? 'secondary' : undefined}
      style={{
        '--background': 'var(--ion-color-step-100)',
        '--border-radius': '4px',
      }}
    >
      <IonLabel>{label}</IonLabel>
      <IonSelect
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onIonChange={(e) => onChange && onChange(e)}
        interfaceOptions={{ cssClass: 'dark-ionic-filter' }}
        className="bg-transparent"
      >
        {pickerMonths.map((month) => (
          <IonSelectOption key={month.value} value={month.value}>
            {month.text}
          </IonSelectOption>
        ))}
      </IonSelect>
    </IonItem>
  )
}

export default MonthPicker

import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonToolbar,
} from '@ionic/react'
import { Card, CardContent } from '../../../../components/ui/card'

function CheckInHome() {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton color="secondary" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="mx-auto max-w-md pt-32">
          <Card>
            <CardContent>Waiting for a customer to check-in.</CardContent>
          </Card>
        </div>
      </IonContent>
    </IonPage>
  )
}

export { CheckInHome }

import { useIonRouter } from '@ionic/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { z } from 'zod'

import { isoDateSchema } from '../../utils/schema'
import { client } from '../../services/client'
import { fileService } from '../../services/fileService'
import { useToast } from '../../contexts/toastContext'
import { alterationSteps, taskParseClasses } from '../../tasks/constants'
import { photoDataSchema } from '../use-photo'
import { taskQueryKeys } from './queries'

import type { TaskDetail } from '../../tasks/tasks.schema'

type CreateAlteration = z.infer<typeof alterationInputSchema>
export type CreateAlterationInput = z.input<typeof alterationInputSchema>

export const alterationStepSchema = z
  .enum(alterationSteps)
  .transform((data) => (data.trim() === '' ? undefined : data))

const alterationInputItemSchema = z.object({
  groupId: z
    .string()
    .transform((data) => (data.trim() === '' ? undefined : data)),
  step: alterationStepSchema,
  title: z
    .string()
    .transform((data) => (data.trim() === '' ? undefined : data)),
})

export const alterationInputSchema = z.object({
  clientId: z.string().min(1, { message: 'Please select a customer.' }),
  className: z.enum(taskParseClasses),
  groupId: z
    .string()
    .transform((data) => (data.trim() === '' ? undefined : data)),

  isPublic: z.boolean(),
  note: z.string().transform((data) => (data.trim() === '' ? undefined : data)),
  photos: z
    .array(photoDataSchema)
    .transform((data) => (data.length === 0 ? undefined : data)),
  due: isoDateSchema.transform((data) =>
    data.iso.trim() === '' ? undefined : data
  ),
  items: z.array(alterationInputItemSchema),
  step: alterationStepSchema,
  ticket: z
    .string()
    .transform((data) => (data.trim() === '' ? undefined : data)),
  usersIds: z
    .array(z.string())
    .transform((data) => (data.length === 0 ? undefined : data)),
  userSaleId: z
    .string()
    .transform((data) => (data.trim() === '' ? undefined : data)),
})

type UpdateTaskInput = Pick<
  TaskDetail,
  | 'objectId'
  | 'className'
  | 'isPublic'
  | 'note'
  | 'title'
  | 'trackingNumber'
  | 'trigger'
  | 'vendor'
> &
  Required<
    Pick<
      CreateAlteration,
      'groupId' | 'items' | 'step' | 'ticket' | 'userSaleId' | 'usersIds'
    >
  > & {
    completedAt: boolean | null
    due: string | null
  }

// TODO: currently only implemented for alterations, should update thru app for all tasks
async function createTask({ photos, ...data }: CreateAlterationInput) {
  let processedPhotos: Array<{ name: string; url: string; __type: string }> = []
  if (photos) {
    processedPhotos = await Promise.all(
      photos.map(
        async (p) => await fileService.saveBase64Image(p.name, p.base64String)
      )
    )
  }

  return client
    .post('functions/createTask', {
      json: {
        ...data,
        due: data.due ? { __type: 'Date', iso: data.due.iso } : null,
        photos: processedPhotos,
      },
    })
    .json()
}

function useCreateTask({ onSuccess }: { onSuccess?: () => void }) {
  const queryClient = useQueryClient()
  const setToast = useToast()
  const router = useIonRouter()

  return useMutation(createTask, {
    onSettled: () => {
      queryClient.invalidateQueries(taskQueryKeys.lists())
    },
    onSuccess: onSuccess
      ? onSuccess
      : () => {
          router.canGoBack() ? router.goBack() : router.push('/tasks', 'back')
        },
    onError: () => {
      setToast({
        message: 'Something went wrong while updating the Task',
        color: 'danger',
      })
    },
  })
}

function useUpdateTask() {
  const queryClient = useQueryClient()
  const setToast = useToast()

  return useMutation(
    (
      task: Pick<UpdateTaskInput, 'objectId' | 'className'> &
        Partial<Omit<UpdateTaskInput, 'objectId' | 'className'>>
    ) =>
      client.post('functions/updateTask', {
        json: {
          ...task,
          isPublic: !task.isPublic, // opposite since update form uses "private" for the UI
          completedAt: task.completedAt
            ? {
                __type: 'Date',
                iso: new Date().toISOString(),
              }
            : { __op: 'Delete' },
          due: task.due
            ? {
                __type: 'Date',
                iso: task.due,
              }
            : null,
          objectId: task.objectId,
          className: task.className,
        },
      }),
    {
      onSettled: (_data, _error, variables) => {
        queryClient.invalidateQueries(
          taskQueryKeys.detail({
            id: variables.objectId,
            type: variables.className,
          })
        )
        queryClient.invalidateQueries(taskQueryKeys.lists())
      },
      onError: () => {
        setToast({
          message: 'Something went wrong while updating the Task',
          color: 'danger',
        })
      },
    }
  )
}

export type { UpdateTaskInput }
export { useCreateTask, useUpdateTask }

import Parse from 'parse'

import * as store from '../stores'

Parse.initialize(store.getAppId())
Parse.serverURL = store.getEndpoint()

async function saveBase64Image(fileName, base64) {
  const newPhoto = new Parse.File(fileName, { base64 }, 'image/jpeg')
  const response = await newPhoto.save({
    sessionToken: window.localStorage.getItem('token'),
  })

  return {
    name: response._name,
    url: response._url,
    __type: 'File',
  }
}

async function saveBlob(name, blob) {
  const newPhoto = new Parse.File(name, blob)
  const response = await newPhoto.save()

  return {
    name: response._name,
    url: response._url,
    __type: 'File',
  }
}

export const fileService = {
  saveBase64Image,
  saveBlob,
}

import * as React from 'react'
import {
  IonLabel,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonCard,
  IonCardContent,
} from '@ionic/react'
import { klona } from 'klona/json'
import { v4 as uuidv4 } from 'uuid'

import './ClientDetailFeed.css'

import { dateCompare } from '../../utils/date-helpers'
import { classConstants } from '../../constants/classConstants'
import ClientDetailFeedItem from '../card/ClientDetailFeedItem'

function getFilteredFeed(filter, feed) {
  switch (filter) {
    case 'all':
      return feed
    case classConstants.CLASSNAME_SALE:
    case classConstants.CLASSNAME_NOTE:
    case classConstants.CLASSNAME_MESSAGE:
      return feed.filter((item) => item.className === filter)
    default:
      return feed
  }
}

const ClientDetailFeed = ({
  purchases = [],
  messages = [],
  notes = [],
  tasks = [],
  posNotes = [],
}) => {
  const [filter, setFilter] = React.useState('all')
  const hasPosNotes = Boolean(posNotes.length)

  const adjustedTasks = klona(tasks)
  adjustedTasks.forEach((task) => {
    return (task.date = task.completedAt || task.due)
  })

  const adjustedMessages = messages
    .map((message) => ({
      ...message,
      className: classConstants.CLASSNAME_MESSAGE,
    }))
    .sort(dateCompare)

  const adjustedNotes = notes
    .map((note) => ({ ...note, className: classConstants.CLASSNAME_NOTE }))
    .sort(dateCompare)

  const feed = purchases
    .concat(adjustedMessages)
    .concat(adjustedNotes)
    .concat(adjustedTasks)
    .sort(dateCompare)

  const filteredFeed = React.useMemo(
    () => getFilteredFeed(filter, feed),
    [feed, filter]
  )

  return (
    <section className="ion-padding-vertical">
      <IonItem lines="none" className="ion-margin-horizontal">
        <div tabIndex={0} />
        <IonLabel>FILTER BY:</IonLabel>
        <IonSelect
          className="dark-ionic-select"
          interfaceOptions={{ cssClass: 'dark-ionic-filter' }}
          value={filter}
          placeholder="Select One"
          onIonChange={(e) => setFilter(e.detail.value)}
        >
          <IonSelectOption value="all">ALL</IonSelectOption>
          <IonSelectOption value={classConstants.CLASSNAME_SALE}>
            PURCHASES
          </IonSelectOption>
          <IonSelectOption value={classConstants.CLASSNAME_MESSAGE}>
            CONVERSATIONS
          </IonSelectOption>
          <IonSelectOption value={classConstants.CLASSNAME_NOTE}>
            NOTES
          </IonSelectOption>
        </IonSelect>
      </IonItem>

      {hasPosNotes &&
      (filter === 'all' || filter === classConstants.CLASSNAME_NOTE) ? (
        <IonCard color="secondary">
          <IonCardContent>
            <h2 className="font-semibold text-ion-color-primary">
              NOTES FROM THE POS:
            </h2>
            {posNotes.map((note) => (
              <p key={uuidv4()}>{note}</p>
            ))}
          </IonCardContent>
        </IonCard>
      ) : null}

      {filteredFeed.map((feedItem) => {
        return (
          <ClientDetailFeedItem feedItem={feedItem} key={feedItem.objectId} />
        )
      })}
    </section>
  )
}

export default ClientDetailFeed

import { IonButton, IonIcon, IonRouterLink } from '@ionic/react'
import { close } from 'ionicons/icons'

function BroadcastLink({
  shopWithLink,
  onDelete,
}: {
  shopWithLink: string
  onDelete: () => void
}) {
  return (
    <>
      <div className="flex items-center gap-x-2">
        <IonRouterLink
          className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap leading-none"
          href={shopWithLink}
          target="_blank"
        >
          {shopWithLink}
        </IonRouterLink>

        <IonButton fill="clear" size="small" onClick={onDelete}>
          <IonIcon icon={close} />
        </IonButton>
      </div>
    </>
  )
}

export { BroadcastLink }

import * as React from 'react'
import {
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  useIonActionSheet,
  IonIcon,
  IonModal,
  IonFabButton,
  IonFab,
  IonRow,
  IonCol,
  IonText,
  IonImg,
  isPlatform,
} from '@ionic/react'
import { Controller, useFormContext } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import CheckboxTree, { Node, OnCheckNode } from 'react-checkbox-tree'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import { add, close } from 'ionicons/icons'

import { useSmsMaxCharacterCount } from '../../utils/forms'
import { typedKeys } from '../../utils/typescript-helpers'
import { useGroupsTree } from '../../hooks/queries/use-groups'
import { usePhoto } from '../../hooks/use-photo'
import { useShopWithLinks } from '../../hooks/shopwith/use-shopwith-links'
import { Card, CardContent } from '../../components/ui/card'
import { TextArea } from '../../components/ui/form/textarea'
import {
  CheckBoxChecked,
  CheckBoxUnchecked,
  CheckBoxHalfChecked,
  ChevronDown,
  ChevronRight,
} from '../../components/ui/icons'
import SelectShopWithModal from '../shopwith/select-shop-with-modal'
import {
  MergeFieldButtons,
  MergeFieldButton,
} from '../ui/form/merge-field-buttons'

import type {
  CreateTemplateInput,
  ShopWithIds,
} from '../../hooks/templates/mutations'
import type { PhotoData } from '../../hooks/use-photo'

function AdminTemplateForm() {
  const smsMessageRef = React.useRef<HTMLIonTextareaElement>(null)
  const emailMessageRef = React.useRef<HTMLIonTextareaElement>(null)
  const letterMessageRef = React.useRef<HTMLIonTextareaElement>(null)

  const [expanded, setExpanded] = React.useState<Array<string>>([])
  const [isShopWithModalOpen, setIsShopWithModalOpen] = React.useState(false)

  const [present] = useIonActionSheet()

  const { selectPhotos, takePhoto } = usePhoto()
  const groupsTreeQuery = useGroupsTree()
  const { generateShopWithLinkPreview } = useShopWithLinks()
  const { getSmsMaxCharacterCount } = useSmsMaxCharacterCount()

  const {
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useFormContext<CreateTemplateInput>()
  const [watchType, watchShopWithIds, watchSmsMessage] = watch([
    'type',
    'shopWithIds',
    'smsTemplate.body',
  ])

  const maxCharacterCount = getSmsMaxCharacterCount(watchSmsMessage ?? '')

  const link = generateShopWithLinkPreview(
    typedKeys(watchShopWithIds)[0],
    Object.values(watchShopWithIds)[0]
  )

  function handleShopWithModalClose() {
    setIsShopWithModalOpen(false)
  }

  function handleShopWithModalConfirm(
    type: keyof ShopWithIds,
    values: Array<string>
  ) {
    setValue('shopWithIds', { [type]: values }, { shouldDirty: true })
    setIsShopWithModalOpen(false)
  }

  function handleGetPhoto(photo: PhotoData) {
    setValue('smsTemplate.attachments', [photo], { shouldDirty: true })
    setValue('emailTemplate.attachments', [photo], { shouldDirty: true })
  }

  function handleCheckboxTreeLabelClick(e: OnCheckNode) {
    if (e.children) {
      return
    }

    if (e.checked) {
      setValue(
        'groupIds',
        getValues('groupIds')?.filter((group) => group !== e.value)
      )
    } else {
      setValue('groupIds', [...(getValues('groupIds') ?? []), e.value])
    }
  }

  return (
    <form>
      <div className="ion-padding-vertical">
        <IonItem
          style={{
            '--padding-start': '.5rem',
            '--background': 'var(--ion-color-step-100)',
            '--border-radius': '4px',
          }}
          lines="none"
        >
          <IonLabel className="font-semibold">Title: </IonLabel>

          <Controller
            name="name"
            control={control}
            render={({ field: { value, onChange } }) => (
              <IonInput value={value} onIonChange={onChange}></IonInput>
            )}
          />
        </IonItem>
      </div>
      <div className="ion-padding-vertical">
        <IonItem
          style={{
            '--padding-start': '.5rem',
            '--background': 'var(--ion-color-step-100)',
            '--border-radius': '4px',
          }}
          lines="none"
        >
          <IonLabel className="font-semibold">Description: </IonLabel>

          <Controller
            name="description"
            control={control}
            render={({ field: { value, onChange } }) => (
              <IonInput value={value} onIonChange={onChange}></IonInput>
            )}
          />
        </IonItem>
      </div>
      <div className="ion-padding-vertical">
        <div className="flex">
          <h2>Stores:</h2>
          {groupsTreeQuery.data && (
            <Controller
              rules={{
                validate: () =>
                  getValues('groupIds').length
                    ? true
                    : 'You must select at least one store.',
              }}
              name="groupIds"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <div>
                    <CheckboxTree
                      expandOnClick
                      onClick={handleCheckboxTreeLabelClick}
                      checkModel="all"
                      nodes={groupsTreeQuery.data as Array<Node>}
                      checked={value}
                      expanded={expanded}
                      showNodeIcon={false}
                      onCheck={onChange}
                      onExpand={(expanded) => setExpanded(expanded)}
                      icons={{
                        check: <CheckBoxChecked />,
                        uncheck: <CheckBoxUnchecked />,
                        halfCheck: <CheckBoxHalfChecked />,
                        expandClose: <ChevronRight />,
                        expandOpen: <ChevronDown />,
                      }}
                    />
                  </div>
                )
              }}
            />
          )}
        </div>
        <ErrorMessage
          errors={errors}
          name="groupIds"
          render={({ message }) => (
            <p className="text-ion-color-danger">{message}</p>
          )}
        />
      </div>
      <div className="ion-padding-vertical">
        <h2 className="flex justify-between">
          <span>Type:</span>
          <span className="capitalize">{watchType ?? 'general'}</span>
          <Controller
            name="type"
            control={control}
            render={({ field: { value, onChange } }) => (
              <IonInput class="hidden" value={value} onChange={onChange} />
            )}
          />
        </h2>
      </div>
      <div className="ion-padding-vertical">
        <h2>Text Message</h2>
        <div className="ion-margin-top">
          <Card>
            <CardContent>
              <Controller
                name="smsTemplate.body"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <TextArea
                    ref={smsMessageRef}
                    className="min-h-16.5"
                    placeholder="Write your text message template here..."
                    autoGrow
                    autocapitalize="on"
                    value={value}
                    onIonChange={onChange}
                  />
                )}
              />
              <IonRow className="ion-align-items-center ion-justify-content-between">
                <IonCol size="auto">
                  <MergeFieldButtons
                    mergeRef={smsMessageRef}
                    onInsert={(value) => setValue('smsTemplate.body', value)}
                  >
                    <MergeFieldButton placeholder="<First Name>">
                      First Name
                    </MergeFieldButton>
                    <MergeFieldButton placeholder="<Last Name>">
                      Last Name
                    </MergeFieldButton>
                    <MergeFieldButton placeholder="<Stylist>">
                      Stylist
                    </MergeFieldButton>
                    <MergeFieldButton placeholder="<Store>">
                      Store Location
                    </MergeFieldButton>
                  </MergeFieldButtons>
                </IonCol>
                <IonCol size="auto">
                  <p>
                    <IonText
                      color={
                        watchSmsMessage &&
                        watchSmsMessage.length > maxCharacterCount
                          ? 'danger'
                          : 'dark'
                      }
                    >
                      {watchSmsMessage ? watchSmsMessage.length : 0}
                    </IonText>
                    /{maxCharacterCount}
                  </p>
                </IonCol>
              </IonRow>

              {Object.keys(watchShopWithIds).length ? (
                <div className="mt-4 flex justify-between">
                  <span>{link}</span>
                  <IonButton
                    color="secondary"
                    size="small"
                    onClick={() => {
                      setValue('shopWithIds', {}, { shouldDirty: true })
                    }}
                  >
                    <IonIcon icon={close} />
                  </IonButton>
                </div>
              ) : null}

              <Controller
                name="smsTemplate.attachments"
                control={control}
                render={({ field: { value } }) => (
                  <>
                    {value?.map((photo) => (
                      <div
                        key={typeof photo === 'string' ? photo : photo.pic}
                        className="flex items-center justify-between"
                      >
                        <IonImg
                          src={typeof photo === 'string' ? photo : photo.pic}
                          style={{ height: '5rem' }}
                        />
                        <IonButton
                          fill="clear"
                          size="small"
                          onClick={() => {
                            setValue('smsTemplate.attachments', null, {
                              shouldDirty: true,
                            })
                            setValue('emailTemplate.attachments', null, {
                              shouldDirty: true,
                            })
                          }}
                        >
                          <IonIcon icon={close} />
                        </IonButton>
                      </div>
                    ))}
                  </>
                )}
              />
            </CardContent>
          </Card>
        </div>
      </div>

      <div className="ion-padding-vertical">
        <h2>Email</h2>
        <div className="ion-margin-top">
          <Card>
            <CardContent>
              <IonItem style={{ '--padding-start': 0 }} color="secondary">
                <IonLabel>Subject: </IonLabel>
                <Controller
                  name="emailTemplate.subject"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <IonInput value={value} onIonChange={onChange} />
                  )}
                />
              </IonItem>
              <Controller
                name="emailTemplate.body"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <TextArea
                    ref={emailMessageRef}
                    className="min-h-16.5"
                    placeholder="Write your email message template here..."
                    autoGrow
                    autocapitalize="on"
                    value={value}
                    onIonChange={onChange}
                  />
                )}
              />
              <MergeFieldButtons
                mergeRef={emailMessageRef}
                onInsert={(newValue) =>
                  setValue('emailTemplate.body', newValue)
                }
              >
                <MergeFieldButton placeholder="<First Name>">
                  First Name
                </MergeFieldButton>
                <MergeFieldButton placeholder="<Last Name>">
                  Last Name
                </MergeFieldButton>
                <MergeFieldButton placeholder="<Stylist>">
                  Stylist
                </MergeFieldButton>
                <MergeFieldButton placeholder="<Store>">
                  Store Location
                </MergeFieldButton>
              </MergeFieldButtons>

              {Object.keys(watchShopWithIds).length ? (
                <div className="mt-4 flex justify-between">
                  <span>{link}</span>
                  <IonButton
                    color="secondary"
                    size="small"
                    onClick={() => {
                      setValue('shopWithIds', {}, { shouldDirty: true })
                    }}
                  >
                    <IonIcon icon={close} />
                  </IonButton>
                </div>
              ) : null}

              <Controller
                name="emailTemplate.attachments"
                control={control}
                render={({ field: { value } }) => (
                  <>
                    {value?.map((photo) => (
                      <div
                        key={typeof photo === 'string' ? photo : photo.pic}
                        className="flex items-center justify-between"
                      >
                        <IonImg
                          src={typeof photo === 'string' ? photo : photo.pic}
                          style={{ height: '5rem' }}
                        />
                        <IonButton
                          fill="clear"
                          size="small"
                          onClick={() => {
                            setValue('smsTemplate.attachments', null, {
                              shouldDirty: true,
                            })
                            setValue('emailTemplate.attachments', null, {
                              shouldDirty: true,
                            })
                          }}
                        >
                          <IonIcon icon={close} />
                        </IonButton>
                      </div>
                    ))}
                  </>
                )}
              />
            </CardContent>
          </Card>
        </div>
      </div>
      <div className="ion-padding-vertical">
        <h2>Letter</h2>
        <div className="ion-margin-top">
          <Card>
            <CardContent>
              <Controller
                name="letterTemplate.body"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <TextArea
                    ref={letterMessageRef}
                    className="min-h-16.5"
                    placeholder="Write your letter template here..."
                    autoGrow
                    autocapitalize="on"
                    value={value}
                    onIonChange={onChange}
                  />
                )}
              />
              <MergeFieldButtons
                mergeRef={letterMessageRef}
                onInsert={(newValue) =>
                  setValue('letterTemplate.body', newValue)
                }
              >
                <MergeFieldButton placeholder="<First Name>">
                  First Name
                </MergeFieldButton>
                <MergeFieldButton placeholder="<Last Name>">
                  Last Name
                </MergeFieldButton>
                <MergeFieldButton placeholder="<Stylist>">
                  Stylist
                </MergeFieldButton>
                <MergeFieldButton placeholder="<Store>">
                  Store Location
                </MergeFieldButton>
              </MergeFieldButtons>
            </CardContent>
          </Card>
        </div>
      </div>
      <IonFab vertical="bottom" horizontal="end" slot="fixed" className="fixed">
        <IonFabButton
          size="small"
          color="yellow"
          onClick={() => {
            present({
              cssClass: 'action-sheet',
              buttons: isPlatform('desktop')
                ? [
                    {
                      text: 'Add Product',
                      cssClass: '*:justify-center',
                      handler: () => setIsShopWithModalOpen(true),
                    },
                    {
                      text: 'Select Photo',
                      handler: () => selectPhotos(handleGetPhoto),
                    },
                    {
                      cssClass: '*:justify-center',
                      text: 'Cancel',
                      role: 'cancel',
                    },
                  ]
                : [
                    {
                      text: 'Add Product',
                      handler: () => setIsShopWithModalOpen(true),
                    },
                    {
                      text: 'Take Photo',
                      handler: () => takePhoto(handleGetPhoto),
                    },
                    {
                      text: 'Select Photo',
                      handler: () => selectPhotos(handleGetPhoto),
                    },
                    {
                      text: 'Cancel',
                    },
                  ],
            })
          }}
        >
          <IonIcon icon={add} />
        </IonFabButton>
      </IonFab>
      <IonModal
        isOpen={isShopWithModalOpen}
        onDidDismiss={handleShopWithModalClose}
      >
        <SelectShopWithModal
          onClose={handleShopWithModalClose}
          onConfirm={handleShopWithModalConfirm}
        />
      </IonModal>
    </form>
  )
}
export default AdminTemplateForm

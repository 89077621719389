import { useQuery } from '@tanstack/react-query'

import { client } from '../services/client'
import type { IsoDate } from '../types/general'
import type { QueryContextFromKeys } from '../utils/react-query'

type FetchAllClientStatsResponse = { result: AllClientItem }
type FetchSingleVsMultiStatsResponse = {
  result: SingleVsMultiPurchaseClientItem
}
type FetchLostStatsResponse = { result: LostClientItem }
type FetchNewClientStatsResponse = { result: NewClientItem }
type FetchRoiStatsResponse = { result: RoiStatsItem }
type FetchTopClientsResponse = { result: TopClientsItem }
type DashboardProps = {
  startDate: string
  endDate: string
  groupId: string
}

type DashboardGroup = {
  groupId: string
  groupName: string
}

type AllClientItem = DashboardGroup & {
  salesAll: number
  salesAllChange: number
  clientsAll: number
  clientsAllChange: number
}

type SingleVsMultiPurchaseClientItem = DashboardGroup & {
  singlePurchaseClientCount: number
  singlePurchaseClientCountPercent: number
  singlePurchaseClientCountChange: number
  singlePurchaseClientSales: number
  singlePurchaseClientSalesPercent: number
  singlePurchaseClientSalesChange: number
  multiPurchaseClientCount: number
  multiPurchaseClientCountPercent: number
  multiPurchaseClientCountChange: number
  multiPurchaseClientSales: number
  multiPurchaseClientSalesPercent: number
  multiPurchaseClientSalesChange: number
}

type LostClientItem = DashboardGroup & {
  lostClientCount: number
  lostClientsChange: number
  lostClientSales: number
  lostClientSalesChange: number
}

type NewClientItem = DashboardGroup & {
  newClientCount: number
  newClientsChange: number
  newClientSales: number
  newClientSalesChange: number
}

type RoiStatsItem = DashboardGroup & {
  roi: {
    roiTypes: {
      labels: Array<string>
      datasets: Array<{
        label: string
        data: Array<number>
        total: number
        messageCount: number
        fill: boolean
      }>
    }
  }
}

type TopClientsItem = DashboardGroup & {
  topClients: Array<{
    firstName: string | null
    lastName: string | null
    photo: string | null
    finalValueSum: number
    posCustomerId: string | null
    firstSale: IsoDate
    lastSale: IsoDate
  }>
}

type AnalyticsDetailKey = {
  name: string
  groupId: string
  startDate: string
  endDate: string
}

type DashboardQueryContexts = QueryContextFromKeys<typeof dashboardQueryKeys>

const dashboardQueryKeys = {
  all: [{ entity: 'dashboard' }] as const,
  analytics: () =>
    [{ ...dashboardQueryKeys.all[0], scope: 'analytics' }] as const,
  analyticsDetail: ({
    name,
    groupId,
    startDate,
    endDate,
  }: AnalyticsDetailKey) =>
    [
      {
        ...dashboardQueryKeys.analytics()[0],
        name,
        groupId,
        startDate,
        endDate,
      },
    ] as const,
}

async function fetchAllClientStats({
  queryKey: [{ groupId, startDate, endDate }],
}: DashboardQueryContexts['analyticsDetail']) {
  const response: FetchAllClientStatsResponse = await client
    .post('functions/getAllClientStats', {
      json: { groupId, startDate, endDate },
    })
    .json()

  return response.result
}

async function fetchSingleVsMultiPurchaseClientStats({
  queryKey: [{ groupId, startDate, endDate }],
}: DashboardQueryContexts['analyticsDetail']) {
  const response: FetchSingleVsMultiStatsResponse = await client
    .post('functions/getSingleVsMultiPurchaseClientStats', {
      json: { groupId, startDate, endDate },
    })
    .json()

  return response.result
}

async function fetchLostClientStats({
  queryKey: [{ groupId, startDate, endDate }],
}: DashboardQueryContexts['analyticsDetail']) {
  const response: FetchLostStatsResponse = await client
    .post('functions/getLostClientStats', {
      json: { groupId, startDate, endDate },
    })
    .json()

  return response.result
}

async function fetchNewClientStats({
  queryKey: [{ groupId, startDate, endDate }],
}: DashboardQueryContexts['analyticsDetail']) {
  const response: FetchNewClientStatsResponse = await client
    .post('functions/getNewClientStats', {
      json: { groupId, startDate, endDate },
    })
    .json()

  return response.result
}

async function fetchRoiStats({
  queryKey: [{ groupId, startDate, endDate }],
}: DashboardQueryContexts['analyticsDetail']) {
  const response: FetchRoiStatsResponse = await client
    .post('functions/getRoiStats', { json: { groupId, startDate, endDate } })
    .json()

  return response.result
}

async function fetchTopClients({
  queryKey: [{ groupId, startDate, endDate }],
}: DashboardQueryContexts['analyticsDetail']) {
  const response: FetchTopClientsResponse = await client
    .post('functions/getTopClients', { json: { groupId, startDate, endDate } })
    .json()

  return response.result
}

function useAllClientStats({ startDate, endDate, groupId }: DashboardProps) {
  return useQuery({
    queryKey: dashboardQueryKeys.analyticsDetail({
      name: 'allClientStats',
      groupId,
      startDate,
      endDate,
    }),
    queryFn: fetchAllClientStats,
    enabled: Boolean(groupId),
  })
}

function useSingleVsMultiPurchaseClientStats({
  startDate,
  endDate,
  groupId,
}: DashboardProps) {
  return useQuery({
    queryKey: dashboardQueryKeys.analyticsDetail({
      name: 'singleVsmultiPurchaseClientStats',
      groupId,
      startDate,
      endDate,
    }),
    queryFn: fetchSingleVsMultiPurchaseClientStats,
    enabled: Boolean(groupId),
  })
}

function useLostClientStats({ startDate, endDate, groupId }: DashboardProps) {
  return useQuery({
    queryKey: dashboardQueryKeys.analyticsDetail({
      name: 'lostClientStats',
      groupId,
      startDate,
      endDate,
    }),
    queryFn: fetchLostClientStats,
    enabled: Boolean(groupId),
  })
}

function useNewClientStats({ startDate, endDate, groupId }: DashboardProps) {
  return useQuery({
    queryKey: dashboardQueryKeys.analyticsDetail({
      name: 'newClientStats',
      groupId,
      startDate,
      endDate,
    }),
    queryFn: fetchNewClientStats,

    enabled: Boolean(groupId),
  })
}

function useRoiStats({ startDate, endDate, groupId }: DashboardProps) {
  return useQuery({
    queryKey: dashboardQueryKeys.analyticsDetail({
      name: 'roiStats',
      groupId,
      startDate,
      endDate,
    }),
    queryFn: fetchRoiStats,
    enabled: Boolean(groupId),
  })
}

function useTopClients({ startDate, endDate, groupId }: DashboardProps) {
  return useQuery({
    queryKey: dashboardQueryKeys.analyticsDetail({
      name: 'topClients',
      groupId,
      startDate,
      endDate,
    }),
    queryFn: fetchTopClients,
    enabled: Boolean(groupId),
  })
}

function useDashboard(props: DashboardProps) {
  const allClientStatsQuery = useAllClientStats(props)
  const singleVsMultiPurchaseClientStatsQuery =
    useSingleVsMultiPurchaseClientStats(props)
  const lostClientStatsQuery = useLostClientStats(props)
  const newClientStatsQuery = useNewClientStats(props)
  const roiStatsQuery = useRoiStats(props)
  const topClientsQuery = useTopClients(props)

  return {
    allClientStatsQuery,
    singleVsMultiPurchaseClientStatsQuery,
    lostClientStatsQuery,
    newClientStatsQuery,
    roiStatsQuery,
    topClientsQuery,
  }
}

export { useDashboard }

import * as React from 'react'
import { IonIcon, IonLabel, IonSegment, IonSegmentButton } from '@ionic/react'
import { call, chatbox, mail } from 'ionicons/icons'
import { useQueryClient } from '@tanstack/react-query'

import letterIcon from '../../assets/icons/create-24px.svg'
import {
  getDefaultPhoneNumber,
  useCanMessageClient,
} from '../../utils/client-helpers'
import { useUser } from '../../contexts/authContext'
import { clientQueryKeys } from '../../hooks/clients/queries'
import PhoneCard from './PhoneCard'
import TextCard from './TextCard'
import EmailCard from './EmailCard'
import LetterCard from './LetterCard'

import type { SelectClientDetail } from '../../hooks/clients/queries'

type Communication = 'phone' | 'sms' | 'email' | 'letter'

type Props = {
  client: NonNullable<SelectClientDetail>['ClientDetailsData']
  onCommunicationComplete?: () => void
}

function CommunicationLabel({ children }: { children: React.ReactNode }) {
  return <IonLabel className="mt-1 font-normal">{children}</IonLabel>
}

function ClientCommunication({ client, onCommunicationComplete }: Props) {
  const [selectedCommunication, setSelectedCommunication] =
    React.useState<Communication | null>(null)

  const queryClient = useQueryClient()
  const defaultPhone = getDefaultPhoneNumber(client.phoneNumbers)
  const { hasTwilio, smsManual } = useUser()

  const isEnabledByAccount = useCanMessageClient(client.users)

  const isPhoneDisabled = !isEnabledByAccount || !defaultPhone
  const isSmsDisabled =
    !isEnabledByAccount || defaultPhone?.isLandLine || !defaultPhone
  const isEmailDisabled = !isEnabledByAccount || !Boolean(client.email)
  const isLetterDisabled = !isEnabledByAccount || !client.hasAddress

  function handleCommunicationComplete() {
    queryClient.refetchQueries(clientQueryKeys.detail(client.objectId), {
      type: 'active',
      exact: true,
    })
    onCommunicationComplete && onCommunicationComplete()
  }

  return (
    <section className="ion-padding-horizontal">
      <IonSegment
        value={selectedCommunication}
        onIonChange={(e) =>
          setSelectedCommunication(e.detail.value as Communication)
        }
      >
        {!hasTwilio ? (
          <IonSegmentButton value="phone" disabled={isPhoneDisabled}>
            <CommunicationLabel>Call</CommunicationLabel>
            <IonIcon icon={call} />
          </IonSegmentButton>
        ) : null}

        {!hasTwilio || (hasTwilio && smsManual) ? (
          <IonSegmentButton value="sms" disabled={isSmsDisabled}>
            <CommunicationLabel>Message</CommunicationLabel>
            <IonIcon icon={chatbox} />
          </IonSegmentButton>
        ) : null}
        <IonSegmentButton value="email" disabled={isEmailDisabled}>
          <CommunicationLabel>Email</CommunicationLabel>
          <IonIcon icon={mail} />
        </IonSegmentButton>

        <IonSegmentButton value="letter" disabled={isLetterDisabled}>
          <CommunicationLabel>Letter</CommunicationLabel>
          <IonIcon src={letterIcon} />
        </IonSegmentButton>
      </IonSegment>

      {selectedCommunication === 'phone' && (
        <PhoneCard
          phoneNumber={defaultPhone?.phoneNumber}
          onPhoneCancel={() => setSelectedCommunication(null)}
          clientId={client.objectId}
        />
      )}

      {selectedCommunication === 'sms' && (
        <TextCard
          phoneNumber={defaultPhone?.phoneNumber}
          clientId={client.objectId}
          templates={client.smsTemplates}
          handleCommunicationCancel={() => setSelectedCommunication(null)}
          onTextSend={handleCommunicationComplete}
        />
      )}

      {selectedCommunication === 'email' && (
        <EmailCard
          to={client.email}
          clientId={client.objectId}
          templates={client.emailTemplates}
          onEmailSend={handleCommunicationComplete}
          handleCommunicationCancel={() => setSelectedCommunication(null)}
        />
      )}

      {selectedCommunication === 'letter' && (
        <LetterCard
          clientId={client.objectId}
          onLetterSend={handleCommunicationComplete}
          handleCommunicationCancel={() => setSelectedCommunication(null)}
        />
      )}
    </section>
  )
}

export default ClientCommunication

import {
  isAfter,
  isSameDay,
  parseISO,
  subYears as subtractYears,
} from 'date-fns'
import parsePhoneNumber from 'libphonenumber-js'

import { useAccount, useUser } from '../contexts/authContext'

import type { Client } from '../hooks/clients/queries'
import type { PhoneNumber } from '../hooks/clients/queries'
import type { ProfileBase } from '../types/general'
import type { AmountOfTime } from './schema'
import type { UpdateClientInput } from '../marketing/customer-opt-in/customer-opt-in.schema'
import type { PartialNullable } from '../types'

function isNewClient(client: Client) {
  if (!client.lastSale || !client.firstSale) return false

  return Boolean(
    isSameDay(parseISO(client.lastSale.iso), parseISO(client.firstSale.iso)) &&
      isAfter(parseISO(client.lastSale.iso), subtractYears(new Date(), 1))
  )
}

function composeDobObject(dob?: Partial<UpdateClientInput['birthday']>) {
  return {
    day: dob?.day ?? undefined,
    month: dob?.month ? dob?.month - 1 : undefined,
    year: dob?.year ?? undefined,
  }
}

function parseDobObject(
  dob: PartialNullable<Pick<AmountOfTime, 'month' | 'day' | 'year'>> | null
) {
  const month = dob?.month
  const day = dob?.day
  const year = dob?.year

  return {
    month: month ? month + 1 : month,
    day,
    year,
  }
}

const findDefaultPhoneNumber = (phoneNumbers: Array<PhoneNumber>) =>
  phoneNumbers.find((phoneNumber) => phoneNumber.default === true)

function hasVerifiedCell(phoneNumbers: Array<PhoneNumber>) {
  const defaultPhoneNumber = findDefaultPhoneNumber(phoneNumbers)

  return Boolean(
    defaultPhoneNumber?.verified && defaultPhoneNumber?.type === 'mobile'
  )
}

function getOptInStatus(phoneNumbers: Array<PhoneNumber>) {
  const defaultPhoneNumber = findDefaultPhoneNumber(phoneNumbers)

  return defaultPhoneNumber ? defaultPhoneNumber.optInState : null
}

function getOptInStatusDisplay(phoneNumbers: Array<PhoneNumber>) {
  const defaultPhoneNumber = findDefaultPhoneNumber(phoneNumbers)

  return !defaultPhoneNumber
    ? 'No Phone Number'
    : defaultPhoneNumber.optInState === 'NOT_INITIALIZED'
    ? 'Needs Opt-In'
    : defaultPhoneNumber.optInState === 'PENDING'
    ? 'Opt-In Pending'
    : defaultPhoneNumber.optInState === 'OPTED_OUT'
    ? 'Opted Out'
    : defaultPhoneNumber.optInState === 'OPTED_IN'
    ? 'Opted In'
    : 'Unknown Subscription Status'
}

function getDefaultPhoneNumber(phoneNumbers: Array<PhoneNumber>) {
  if (phoneNumbers.length === 0) return null

  const defaultPhone = findDefaultPhoneNumber(phoneNumbers)

  if (!defaultPhone?.phoneNumber) return null

  const isLandLine =
    defaultPhone.type === 'voip' || defaultPhone.type === 'landline'

  // RETURN E164 FROM SERVER FIRST OTHERWISE MAKE ONE
  // TODO: LOOK INTO -- parsePhoneNumber does not return undefined if default country code is passed alongside an invalid phoneNumber (ie 0002525138)
  const parsedPhoneNumber = parsePhoneNumber(defaultPhone.phoneNumber, 'US')
  const e164 =
    defaultPhone.e164 ??
    (parsedPhoneNumber?.isValid() ? parsedPhoneNumber.number : null)

  return {
    ...defaultPhone,
    isLandLine,
    e164,
  }
}

function useCanMessageClient(clientUsers: Array<ProfileBase> | null) {
  const account = useAccount()
  const { objectId: userId } = useUser()

  const assignedTo = clientUsers?.map((u) => u.objectId)
  const isMyClient = assignedTo?.includes(userId)

  if (account?.hasAssignAll) return true
  if (account?.account.settings.canMessageAllClients) return true
  if (isMyClient) return true

  return false
}

export {
  composeDobObject,
  isNewClient,
  getDefaultPhoneNumber,
  getOptInStatus,
  hasVerifiedCell,
  getOptInStatusDisplay,
  parseDobObject,
  useCanMessageClient,
}

import { IonContent, IonPage, useIonRouter } from '@ionic/react'
import { useLocation } from 'react-router-dom'

import { useCreateClient } from '../../hooks/clients/mutations'
import { useCheckInValues } from '../../check-in/check-in-store'
import { CustomerCheckInForm } from '../../rotate/components/customer-check-in-form'
import { RotateInfoLegal } from '../../rotate/components/rotate-info-legal'
import { RotateInfoContainer } from '../../rotate/components/rotate-info-container'

import type { CheckInFormInputs } from '../../schema/add-client.schema'

function RotateAddCustomerPage() {
  const router = useIonRouter()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const initialValues = useCheckInValues()
  const createClient = useCreateClient()

  function handleSubmit(data: CheckInFormInputs) {
    createClient.mutate(
      { ...data },
      {
        onSuccess: (data) => {
          searchParams.append('clientId', data.result.client.objectId)
          router.push(
            `/rotate/opt-in/success?${searchParams}`,
            'none',
            'replace'
          )
        },
      }
    )
  }

  return (
    <IonPage>
      <IonContent className="ion-padding">
        <RotateInfoContainer>
          <div />

          <CustomerCheckInForm
            disabled={createClient.isLoading}
            onSubmit={handleSubmit}
            initialValues={initialValues}
          />
          <div className="mx-auto">
            <RotateInfoLegal />
          </div>
        </RotateInfoContainer>
      </IonContent>
    </IonPage>
  )
}

export { RotateAddCustomerPage }

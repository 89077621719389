import * as React from 'react'

import usePosClasses from '../../../hooks/queries/use-pos-classes'
import SearchableSelect from '../../ui/form/searchable-select'

import type { SelectProps } from './types'

function CategorySelect({
  isAdmin = false,
  selectedItems,
  setSelectedItems,
}: SelectProps) {
  const [isOpen, setIsOpen] = React.useState(false)

  const posClassesQuery = usePosClasses({ admin: isAdmin, enabled: isOpen })

  return (
    <SearchableSelect
      onOpen={() => setIsOpen(true)}
      label="Categories"
      items={posClassesQuery.data ?? []}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
    />
  )
}

export default CategorySelect

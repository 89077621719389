import { IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react'

import { useInfiniteList } from '../../hooks/use-infinite-list'
import AdminClientCard from './admin-client-card'
import type { AdminClient } from '../../hooks/clients/queries'

type Props = {
  clientList: Array<AdminClient>
}

const AdminClientList = ({ clientList }: Props) => {
  const { infiniteListCount, doInfiniteStuff } = useInfiniteList()

  return (
    <>
      {clientList.slice(0, infiniteListCount).map((client) => (
        <AdminClientCard
          key={client.objectId}
          client={client}
          path="tabs/clients/client"
        />
      ))}

      <IonInfiniteScroll
        onIonInfinite={doInfiniteStuff}
        disabled={infiniteListCount >= clientList.length}
      >
        <IonInfiniteScrollContent loadingSpinner="bubbles" />
      </IonInfiniteScroll>
    </>
  )
}

export default AdminClientList

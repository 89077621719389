import { IonContent, IonHeader, IonPage } from '@ionic/react'

import AdminHeader from '../../../components/header/admin-header'
import CreateDynamicOutreachForm from '../../../components/outreach/create-dynamic-outreach-form'

function CreateDynamicListPage() {
  return (
    <IonPage>
      <IonHeader>
        <AdminHeader
          title="Create Dynamic List"
          backRef="/admin/dynamic-lists"
        />
      </IonHeader>
      <IonContent className="ion-padding">
        <CreateDynamicOutreachForm />
      </IonContent>
    </IonPage>
  )
}

export { CreateDynamicListPage }

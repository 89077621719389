import * as React from 'react'
import {
  IonModal,
  IonContent,
  IonImg,
  IonHeader,
  IonButtons,
  IonButton,
  IonToolbar,
} from '@ionic/react'

import { unlayerTemplates } from '../custom/unlayer-templates'
import { Button } from './button'

type Props = {
  onClick: (id: number) => void
}
function SelectTemplate({ onClick }: Props) {
  const modalRef = React.useRef<HTMLIonModalElement>(null)

  return (
    <>
      <Button id="open-templates" variant="secondary">
        Select Template
      </Button>
      <IonModal
        style={{
          '--width': '100%',
          '--max-width': '1000px',
        }}
        ref={modalRef}
        trigger="open-templates"
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                onClick={() => modalRef.current?.dismiss()}
                color="secondary"
              >
                Cancel
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <div className="grid grid-cols-3 gap-3">
            {unlayerTemplates.map((template) => (
              <div key={template.id} className="flex flex-col">
                <div className="flex-grow">
                  <IonImg
                    key={template.id}
                    src={template.url}
                    className="h-full object-cover"
                    onClick={() => {
                      onClick(template.id)
                      modalRef.current?.dismiss()
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </IonContent>
      </IonModal>
    </>
  )
}

export { SelectTemplate }

import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { useParams } from 'react-router-dom'
import { trash } from 'ionicons/icons'
import { useFieldArray, useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { zodResolver } from '@hookform/resolvers/zod'

import { useBoolean } from '../../../hooks/use-boolean'
import { Card, CardContent } from '../../../components/ui/card'
import { StyledError } from '../../../components/ui/form/errors'
import { PhoneNumberInput, TextInput } from '../../../components/ui/form/input'
import { testBroadcastSchema } from '../broadcast.schema'
import { useTestBroadcast, useUpdateBroadcast } from '../mutations'

import type { TestBroadcastInputs } from '../broadcast.schema'

type Props = {
  disabled: boolean
  onSend: (data: TestBroadcastInputs) => void
}

const defaultValues: TestBroadcastInputs = {
  emailList: [{ email: '' }],
  smsList: [{ e164: '' }],
}

function TestBroadcastButton({ disabled, onSend }: Props) {
  const [isOpen, { on: openModal, off: closeModal }] = useBoolean()

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<TestBroadcastInputs>({
    defaultValues,
    resolver: zodResolver(testBroadcastSchema),
  })

  const emailFields = useFieldArray({ control, name: 'emailList' })
  const smsFields = useFieldArray({ control, name: 'smsList' })

  async function handleSendTest(data: TestBroadcastInputs) {
    onSend(data)
    closeModal()
  }

  return (
    <>
      <IonButton
        disabled={disabled}
        onClick={openModal}
        className="flex-grow"
        color="yellow"
        size="small"
        fill="solid"
      >
        Test
      </IonButton>

      <IonModal
        isOpen={isOpen}
        onWillDismiss={closeModal}
        onDidDismiss={() => reset()}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton fill="outline" color="secondary" onClick={closeModal}>
                Cancel
              </IonButton>
            </IonButtons>
            <IonTitle>Send Test Broadcast</IonTitle>
            <IonButtons slot="end">
              <IonButton
                fill="solid"
                color="yellow"
                onClick={handleSubmit(handleSendTest)}
              >
                Send Test
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className="ion-padding">
            <Card>
              <CardContent>
                Note: Sending a Test will first save your Broadcast, including
                any recent changes.
              </CardContent>
            </Card>
          </div>
          <form>
            <div className="ion-padding flex flex-col justify-center gap-6 sm:flex-row">
              {/* EMAIL */}
              <div>
                <p>Emails</p>
                <div className="space-y-4">
                  {emailFields.fields.map((field, index) => (
                    <div key={field.id} className="flex gap-x-2">
                      <div className="w-full">
                        <TextInput
                          control={control}
                          name={`emailList.${index}.email`}
                        />
                        <ErrorMessage
                          as={StyledError}
                          name={`emailList.${index}.email`}
                          errors={errors}
                        />
                      </div>
                      <IonButton
                        onClick={() => emailFields.remove(index)}
                        color="danger"
                      >
                        <IonIcon icon={trash} slot="icon-only" />
                      </IonButton>
                    </div>
                  ))}
                  <IonButton onClick={() => emailFields.append({ email: '' })}>
                    Add Email
                  </IonButton>
                </div>
              </div>

              {/* SMS */}
              <div>
                <p>Phone Numbers</p>
                <div className="space-y-4">
                  {smsFields.fields.map((field, index) => (
                    <div key={field.id} className="flex gap-x-2">
                      <div className="w-full">
                        <PhoneNumberInput
                          control={control}
                          name={`smsList.${index}.e164`}
                        />
                        <ErrorMessage
                          as={StyledError}
                          name={`smsList.${index}.e164`}
                          errors={errors}
                        />
                      </div>

                      <IonButton
                        onClick={() => smsFields.remove(index)}
                        color="danger"
                      >
                        <IonIcon icon={trash} slot="icon-only" />
                      </IonButton>
                    </div>
                  ))}
                  <IonButton onClick={() => smsFields.append({ e164: '' })}>
                    Add Phone Number
                  </IonButton>
                </div>
              </div>
            </div>
          </form>
        </IonContent>
      </IonModal>
    </>
  )
}

export { TestBroadcastButton }

import { IonContent, IonPage, useIonRouter } from '@ionic/react'
import { Redirect } from 'react-router-dom'

import { useCreateClient } from '../../hooks/clients/mutations'
import { useCheckInValues } from '../../check-in/check-in-store'
import { CustomerCheckInForm } from '../../rotate/components/customer-check-in-form'

import type { CheckInFormInputs } from '../../schema/add-client.schema'

function CustomerAddCustomerPage() {
  const router = useIonRouter()

  const { phoneNumber } = useCheckInValues()
  const createClient = useCreateClient()

  function handleSubmit(formData: CheckInFormInputs) {
    createClient.mutate(formData, {
      onSuccess: (data) =>
        router.push(
          `/rotate/customer/loyalty-info/${data.result.client.objectId}`,
          'none',
          'replace'
        ),
    })
  }

  if (!phoneNumber) return <Redirect exact to="/rotate/customer/check-in" />

  return (
    <IonPage>
      <IonContent>
        <div className="h-full">
          <div className="mx-auto flex size-full max-w-xl flex-col items-center justify-center">
            <h1 className="text-5xl">Welcome!</h1>
            <div className="py-3" />
            <p className="text-3xl">
              We just need a little more info from you.
            </p>
            <div className="py-6" />
            <CustomerCheckInForm
              disabled={createClient.isLoading}
              initialValues={{ phoneNumber }}
              onSubmit={handleSubmit}
            />
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}

export { CustomerAddCustomerPage }

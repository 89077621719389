import * as React from 'react'
import {
  IonItem,
  IonLabel,
  IonInput,
  IonChip,
  IonIcon,
  IonButton,
  IonSpinner,
} from '@ionic/react'
import { addCircleOutline, closeCircle } from 'ionicons/icons'
import { useController } from 'react-hook-form'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { cn } from '../../../utils/cn'

import { useSearchClientTags } from '../../../hooks/tags/queries'

import type { FieldValues, FieldPath, Control } from 'react-hook-form'

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends React.ComponentProps<typeof IonInput> {
  name: TFieldName
  control: Control<TFieldValues>
  label?: string
  color?: 'dark' | 'light'
}

interface PhoneInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Omit<React.ComponentProps<typeof PhoneInput>, 'onChange'> {
  name: TFieldName
  control: Control<TFieldValues>
  label?: string
}

function TextInput<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  label,
  color = 'dark',
  ...props
}: Props<TFieldValues, TFieldName>) {
  const { field } = useController<TFieldValues>({ name, control })

  const inputStyles = {
    '--background':
      color === 'dark'
        ? 'var(--ion-color-step-100)'
        : 'var(--ion-color-secondary)',
    '--padding-start': '.5rem',
    '--padding-top': '.5rem',
    '--padding-bottom': '.5rem',
  }

  return (
    <InputItem label={label} color={color}>
      <IonInput
        style={inputStyles}
        className={cn('rounded', color === 'light' && 'border border-gray-400')}
        type="text"
        autocapitalize="on"
        onIonChange={field.onChange}
        {...props}
        value={field.value}
        onIonBlur={field.onBlur}
      />
    </InputItem>
  )
}

function DecimalInput<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({ name, control, label, ...props }: Props<TFieldValues, TFieldName>) {
  const { field } = useController<TFieldValues>({ name, control })

  return (
    <InputItem label={label}>
      <IonInput
        style={{
          '--background': 'var(--ion-color-step-100)',
          '--padding-start': '.5rem',
          '--padding-top': '.5rem',
          '--padding-bottom': '.5rem',
        }}
        type="number"
        inputMode="decimal"
        {...props}
        value={field.value ?? 0}
        onIonChange={(e) => {
          if (!e.detail.value) return
          field.onChange(Number(parseFloat(e.detail.value).toFixed(2)))
        }}
        onIonBlur={field.onBlur}
      />
    </InputItem>
  )
}

function NumericInput<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({ name, control, color, label, ...props }: Props<TFieldValues, TFieldName>) {
  const { field } = useController<TFieldValues>({ name, control })

  const inputStyles = {
    '--background':
      color === 'dark'
        ? 'var(--ion-color-step-100)'
        : 'var(--ion-color-secondary)',
    '--padding-start': '.5rem',
    '--padding-top': '.5rem',
    '--padding-bottom': '.5rem',
  }

  return (
    <InputItem label={label} color={color}>
      <IonInput
        style={inputStyles}
        className={cn('rounded', color === 'light' && 'border border-gray-400')}
        type="number"
        inputMode="numeric"
        {...props}
        value={field.value ?? 0}
        onIonChange={(e) => {
          if (!e.detail.value) return
          field.onChange(parseInt(e.detail.value, 10))
        }}
        onIonBlur={field.onBlur}
      />
    </InputItem>
  )
}

function PhoneNumberInput<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  label,
  ...props
}: PhoneInputProps<TFieldValues, TFieldName>) {
  const { field } = useController<TFieldValues>({ name, control })

  return (
    <InputItem label={label}>
      <PhoneInput
        className="add-client-phone"
        {...props}
        value={field.value}
        onChange={(e) => {
          field.onChange(e ?? '')
        }}
        onBlur={field.onBlur}
        defaultCountry="US"
      />
    </InputItem>
  )
}

function TagsInput({
  tags,
  onChange,
}: {
  tags: Array<string>
  onChange: (tag: Array<string>) => void
}) {
  const [searchString, setSearchString] = React.useState('')

  // search tags return
  const clientTagsQuery = useSearchClientTags(searchString?.trim())

  function handleDeleteClientTag(tagToDelete: string) {
    onChange(tags.filter((tag) => tag !== tagToDelete))
  }

  function handleSelectClientTag(tag: string) {
    onChange([...tags, tag])
    setSearchString('')
  }

  function handleCreateTag() {
    onChange([...tags, searchString])
    setSearchString('')
  }

  return (
    <>
      <InputItem label="Tags">
        <div className="flex w-full items-center">
          <IonInput
            style={{
              '--background': 'var(--ion-color-step-100)',
              '--padding-start': '.5rem',
              '--padding-top': '.5rem',
              '--padding-bottom': '.5rem',
            }}
            className="min-w-[120px] flex-1"
            type="text"
            value={searchString}
            onIonChange={(e) => setSearchString(e.detail.value ?? '')}
          />
          <IonButton
            color="light"
            fill="clear"
            size="small"
            slot="end"
            onClick={() => handleCreateTag()}
            hidden={
              searchString === '' ||
              clientTagsQuery.data?.some(
                (tag) => tag.name?.trim() === searchString?.trim()
              )
            }
          >
            <IonIcon slot="icon-only" icon={addCircleOutline} />
          </IonButton>
        </div>
      </InputItem>

      {clientTagsQuery.isInitialLoading && (
        <div className="ion-text-center ion-padding">
          <IonSpinner color="light" />
        </div>
      )}
      {clientTagsQuery.data?.length ? (
        <div>
          {clientTagsQuery.data
            .filter(
              (tag) => !tags.some((existingTag) => existingTag === tag.name)
            )
            .map((clientTag) => (
              <IonItem
                color="secondary"
                key={clientTag.objectId}
                onClick={() => handleSelectClientTag(clientTag.name)}
              >
                <IonLabel>{clientTag.name}</IonLabel>
              </IonItem>
            ))}
        </div>
      ) : null}

      <div className="mt-2">
        {tags.map((tag) => (
          <span key={tag}>
            <IonChip key={tag} color="white" outline>
              <IonLabel>{tag}</IonLabel>
              <IonIcon
                icon={closeCircle}
                onClick={() => handleDeleteClientTag(tag)}
              />
            </IonChip>
          </span>
        ))}
      </div>
    </>
  )
}

/**
 * Common wrapper for styling.
 *
 * IonInput --min-height is for overall shorter inputs (helps with dynamic outreach numeric input spacing with text)
 * IonLabel flex: initial is for PhoneInput but should have no impact on others.
 * wrapping div for IonInput is related to input height and spacing and display: flex and alignItems:center is for PhoneInput but should have no impact on others
 */
function InputItem({
  children,
  color = 'dark',
  label,
}: {
  children: React.ReactNode
  color?: 'dark' | 'light'
  label?: string
}) {
  return (
    <IonItem
      style={{
        '--background': color === 'light' ? 'transparent' : undefined,
        '--color': color === 'light' ? 'text-zinc-800' : undefined,
        '--padding-start': 0,
        '--padding-end': 0,
        '--min-height': 44,
        '--inner-padding-end': 0,
        '--border-radius': '.25rem', // todo: revisit and move to input ionic v7
      }}
      lines="none"
    >
      {label ? (
        <IonLabel className="m-0 flex-initial font-semibold" position="stacked">
          {label}
        </IonLabel>
      ) : null}
      <div className="flex w-full items-center">{children}</div>
    </IonItem>
  )
}

export { TextInput, DecimalInput, NumericInput, PhoneNumberInput, TagsInput }

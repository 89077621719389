const conditions = [
  'sale',
  'noSales',
  'loyaltyPointsActive',
  'loyaltyPointsLifetime',
  'loyaltyVisitsActive',
] as const

const fromUserRules = ['assignedTo', 'fromUser', 'soldBy'] as const
const triggerTypes = ['firstSale', 'sale', 'clientCreated'] as const
const sendFrom = [
  {
    label: 'Assigned To',
    value: 'assignedTo',
  },
  // { label: 'Sold By', value: 'soldBy' }, // TODO: CURRENTLY NOT IMPLEMENTED
  { label: 'From User', value: 'fromUser' },
] as const

const automationStates = ['active', 'draft', 'stopped'] as const

// TODO: REVISIT
const triggers = [
  { label: 'First Sale', value: 'firstSale' },
  { label: 'Sale', value: 'sale' },
  { label: 'Sign Up', value: 'clientCreated' },
] as const

export {
  automationStates,
  conditions,
  fromUserRules,
  sendFrom,
  triggers,
  triggerTypes,
}

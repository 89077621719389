import { useInfiniteQuery } from '@tanstack/react-query'

import { fetchApprovals } from './api'

type Permission = 'admin' | 'associate'
type Filter = 'broadcast'

const approvalQueryKeys = {
  all: [{ entity: 'approvals' }] as const,
  lists: () => [{ ...approvalQueryKeys.all[0], scope: 'list' }] as const,
  list: ({ filter, permission }: { filter?: Filter; permission: Permission }) =>
    [{ ...approvalQueryKeys.lists()[0], filter, permission }] as const,
}

function useApprovals(filter: Parameters<typeof approvalQueryKeys['list']>[0]) {
  return useInfiniteQuery({
    queryKey: approvalQueryKeys.list(filter),
    queryFn: fetchApprovals,
    getNextPageParam: (lastPage) =>
      lastPage.hasNextPage ? lastPage.page + 1 : undefined,
  })
}

export { approvalQueryKeys, useApprovals }

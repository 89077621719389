import { useMutation, useQueryClient } from '@tanstack/react-query'

import { client } from '../services/client'
import { ayrshareUrlResponseSchema } from './ayrshare.schema'
import { ayrshareQueryKeys } from './queries'

import type { AyrshareConnectionInput } from './ayrshare.schema'

async function ayrshareLogin(ayrshareId: string) {
  const response = await client
    .post('functions/getAyrshareJWT', {
      json: { ayrshareId },
    })
    .json()

  return ayrshareUrlResponseSchema.parse(response).result
}

async function createAyrshareConnection({ id, type }: AyrshareConnectionInput) {
  const response = await client
    .post('functions/addAyrshare', {
      json: {
        [`${type}Id`]: id,
        type: 'business',
      },
    })
    .json()

  return ayrshareUrlResponseSchema.parse(response).result
}

function useAyrshareLogin() {
  const queryClient = useQueryClient()

  return useMutation(
    (variables: AyrshareConnectionInput & { ayrshareId: string }) =>
      ayrshareLogin(variables.ayrshareId),

    {
      onSuccess: (data) => {
        window.location.href = data.url
      },
      onSettled: (_data, _error, variables) => {
        queryClient.invalidateQueries(
          ayrshareQueryKeys[variables.type](variables.id),
          { refetchType: 'none' }
        )
        queryClient.invalidateQueries(ayrshareQueryKeys.aggregate(), {
          refetchType: 'none',
        })
      },
    }
  )
}

function useCreateAyrshareConnection() {
  const queryClient = useQueryClient()

  return useMutation(createAyrshareConnection, {
    onSuccess: (data) => {
      window.location.href = data.url
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(
        ayrshareQueryKeys[variables.type](variables.id),
        { refetchType: 'none' }
      )
      queryClient.invalidateQueries(ayrshareQueryKeys.aggregate(), {
        refetchType: 'none',
      })
    },
  })
}
export { useAyrshareLogin, useCreateAyrshareConnection }

import { useInfiniteQuery, useQuery } from '@tanstack/react-query'

import { client } from '../../services/client'

import type { IsoDate, ProfileBase } from '../../types/general'
import type { QueryContextFromKeys } from '../../utils/react-query'
import type { Client } from '../clients/queries'
import { useIsAdminRoute } from '../routing'

type FetchBroadcastsResponse = {
  result: {
    data: Array<BroadcastOverview>
    hasNextPage: boolean
    page: number
    pageSize: number
  }
}

// TODO: FULL CLIENT ISNT RETURNED - ONLY BASE PROFILE DATA
type FetchBroadcastResponse = {
  result: BroadcastDetail & {
    emailClients: Array<Client>
    smsClients: Array<Client>
  }
}

export type BroadcastOverview = {
  objectId: string
  attachments: Array<string> | null
  createdAt: IsoDate
  emailCount: number
  emailMessage: string | null
  emailSubject: string | null
  smsCount: number
  smsMessage: string | null
  title: string
  user: ProfileBase | null
}

export type BroadcastDetail = BroadcastOverview & {
  emailClients: Array<ProfileBase>
  smsClients: Array<ProfileBase>
}

type BroadcastQueryContexts = QueryContextFromKeys<typeof broadcastQueryKeys>

export const broadcastQueryKeys = {
  all: [{ entity: 'broadcast' }] as const,
  lists: () => [{ ...broadcastQueryKeys.all[0], scope: 'list' }] as const,
  list: (role: 'admin' | 'associate' = 'associate') =>
    [{ ...broadcastQueryKeys.lists()[0], role }] as const,
  details: () => [{ ...broadcastQueryKeys.all[0], scope: 'detail' }] as const,
  detail: (id: string) => [{ ...broadcastQueryKeys.details()[0], id }] as const,
}

async function fetchBroadcasts({
  queryKey: [{ role }],
  pageParam = 0,
}: BroadcastQueryContexts['list']) {
  const pageSize = 20

  const isAdmin = role === 'admin'

  const response: FetchBroadcastsResponse = await client
    .post('functions/getBroadcasts', {
      json: { page: pageParam, pageSize, admin: isAdmin },
    })
    .json()

  return response.result
}

async function fetchBroadcast({
  queryKey: [{ id }],
}: BroadcastQueryContexts['detail']) {
  const response: FetchBroadcastResponse = await client
    .post('functions/getBroadcast', { json: { objectId: id } })
    .json()

  return response.result
}

function useBroadcasts() {
  const isAdminRoute = useIsAdminRoute()

  return useInfiniteQuery({
    queryKey: broadcastQueryKeys.list(isAdminRoute ? 'admin' : 'associate'),
    queryFn: fetchBroadcasts,
    getNextPageParam: (lastPage) =>
      lastPage.hasNextPage ? lastPage.page + 1 : undefined,
  })
}

function useBroadcastDetail(id: string) {
  return useQuery({
    queryKey: broadcastQueryKeys.detail(id),
    queryFn: fetchBroadcast,
  })
}

export { useBroadcasts, useBroadcastDetail }

import * as React from 'react'

import { LoyaltyButton } from './loyalty-button'
import { LoyaltyModal } from './loyalty-modal'
import { useLoyalty } from './loyalty-redemption-detail'
import { LogSixBoxForm } from './log-six-box-form'
import { LogStandardLoyaltyForm } from './log-standard-loyalty-form'
import { useAccount } from '../../contexts/authContext'

function LogPoints() {
  const modalRef = React.useRef<HTMLIonModalElement>(null)

  const account = useAccount()
  const isNonIntegrated = account.account.settings.nonIntegrated
  const { rewardRules } = useLoyalty()

  return (
    <>
      <LoyaltyButton
        id="open-modal"
        fill="solid"
        color="primary"
        size={isNonIntegrated ? 'lg' : 'default'}
      >
        {isNonIntegrated ? 'Log Points Without Sale' : 'Log Points'}
      </LoyaltyButton>

      <LoyaltyModal ref={modalRef} trigger="open-modal">
        <div className="bg-ion-color-step-100 w-80 pt-4">
          {rewardRules.every((rule) => rule.loyaltyRule.settings.visits > 0) ? (
            <LogSixBoxForm
              onCancel={async () => await modalRef.current?.dismiss()}
              onSubmit={async () => await modalRef.current?.dismiss()}
            />
          ) : (
            <LogStandardLoyaltyForm
              onCancel={async () => await modalRef.current?.dismiss()}
              onSubmit={async () => await modalRef.current?.dismiss()}
            />
          )}
        </div>
      </LoyaltyModal>
    </>
  )
}

export { LogPoints }

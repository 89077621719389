import { IonContent, IonHeader, IonPage } from '@ionic/react'
import { useParams } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'

import PostDetail from '../../social-sharing/posts/components/post-detail'
import ErrorFallback from '../../components/error-fallback'
import Header from '../../components/header'

function PostDetailPage() {
  const { postId } = useParams<{ postId: string }>()

  return (
    <IonPage>
      <IonHeader>
        <Header title="Post" backRef="/posts" btn={null} />
      </IonHeader>
      <IonContent>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <PostDetail postId={postId} />
        </ErrorBoundary>
      </IonContent>
    </IonPage>
  )
}

export default PostDetailPage

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useToast } from '../../contexts/toastContext'

import { client } from '../../services/client'
import { accountQueryKeys } from './queries'

import type { User } from './queries'

type UpdateProfileInput = {
  addProductIds?: Array<string>
  addLookIds?: Array<string>
  removeProductIds?: Array<string>
  removeLookIds?: Array<string>
  displayName?: string
  profileId?: string
  photo?: { name: string; base64String: string }
  bio?: string
}

function updateProfile(variables: UpdateProfileInput) {
  const { photo, ...rest } = variables

  return client
    .post('functions/updateProfile', {
      json: {
        ...rest,
        ...(photo && {
          setPhoto: { name: photo.name, base64String: photo.base64String },
        }),
      },
    })
    .json<{ result: { user: User } }>()
}

function useUpdateProfile() {
  const queryClient = useQueryClient()
  const setToast = useToast()

  return useMutation(
    async (variables: UpdateProfileInput) => updateProfile(variables),
    {
      onSettled: () =>
        queryClient.invalidateQueries(accountQueryKeys.profile()),
      onSuccess: (data) => {
        const currentLS = JSON.parse(localStorage.getItem('authUser') ?? '')
        const newLS = { ...currentLS, ...data.result.user }
        localStorage.setItem('authUser', JSON.stringify(newLS))

        setToast({
          message: 'Your OneShop.me profile was successfully updated.',
          color: 'yellow',
        })
      },
      onError: (error) => {
        const errorMessage =
          error instanceof Error
            ? error.message ===
              'A duplicate value for a field with unique values was provided'
              ? 'A profile with that id is already taken. Please try another.'
              : error.message
            : 'Unknown error.'

        setToast({ message: errorMessage })
      },
    }
  )
}

export { useUpdateProfile }

import {
  IonPage,
  IonContent,
  IonRow,
  IonButton,
  IonHeader,
  IonCol,
  IonIcon,
  IonLoading,
  IonItem,
  IonLabel,
  IonAvatar,
} from '@ionic/react'
import { camera } from 'ionicons/icons'
import { Controller, useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { zodResolver } from '@hookform/resolvers/zod'

import placeholder from '../../resources/avatar-placeholder.png'
import { typedKeys } from '../../utils/typescript-helpers'
import { updateProfileSchema } from '../../schema/account.schema'
import { useUpdateProfile } from '../../hooks/account/mutations'
import { useChooseProfilePhoto } from '../../hooks/account/use-choose-profile-photo'
import Header from '../../components/header'
import { StyledError } from '../../components/ui/form/errors'
import { TextInput } from '../../components/ui/form/input'
import { TextArea } from '../../components/ui/form/textarea'

import type { UpdateProfileSchema } from '../../schema/account.schema'
import { useMyProfile } from '../../hooks/account/queries'

export default function EditProfilePage() {
  const profileQuery = useMyProfile()

  return (
    <IonPage>
      <IonHeader>
        <Header title="Edit Profile" backRef="/tabs/profile" />
      </IonHeader>
      {profileQuery.data ? (
        <IonContent className="ion-padding">
          <div className="mx-auto max-w-4xl">
            <ProfileForm profile={profileQuery.data} />
          </div>
        </IonContent>
      ) : (
        <IonLoading
          isOpen={profileQuery.isLoading}
          message="Loading your profile..."
        />
      )}
    </IonPage>
  )
}

function ProfileForm({
  profile,
}: {
  profile: NonNullable<ReturnType<typeof useMyProfile>['data']>
}) {
  const {
    control,
    handleSubmit,
    reset,
    formState: { dirtyFields, errors, isDirty },
  } = useForm<UpdateProfileSchema>({
    defaultValues: {
      profileId: profile.profileId ?? '',
      displayName: profile.displayName ?? '',
      bio: profile.bio ?? '',
      photo: {
        name: profile.photo ?? '',
        photo: profile.photo ?? placeholder,
        base64String: '',
      },
    },
    resolver: zodResolver(updateProfileSchema),
  })

  const showPhotoActionSheet = useChooseProfilePhoto()
  const updateProfile = useUpdateProfile()

  function handleSaveProfile(data: UpdateProfileSchema) {
    const df = typedKeys(dirtyFields).reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: data[curr],
      }),
      {} as Partial<UpdateProfileSchema>
    )

    updateProfile.mutate(df, { onSuccess: () => reset(data) })
  }

  return (
    <form onSubmit={handleSubmit(handleSaveProfile)}>
      <IonRow className="justify-center">
        <IonCol size="auto">
          <Controller
            name="photo"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <>
                  <IonAvatar className="size-20">
                    <img
                      className="size-full object-cover"
                      src={value.photo}
                      alt=""
                    />
                  </IonAvatar>
                  <IonButton
                    style={{ '--padding-end': 0, '--padding-start': 0 }}
                    size="large"
                    className="absolute right-0 top-0"
                    onClick={() => {
                      showPhotoActionSheet(onChange)
                    }}
                    fill="clear"
                    color="secondary"
                  >
                    <IonIcon slot="icon-only" icon={camera} />
                  </IonButton>
                </>
              )
            }}
          />
        </IonCol>
      </IonRow>
      <TextInput label="Display Name" control={control} name="displayName" />

      <IonItem lines="none" style={{ '--padding-start': 0 }}>
        <IonLabel className="font-semibold" position="stacked">
          Bio
        </IonLabel>
        <Controller
          name="bio"
          control={control}
          render={({ field: { value, onChange } }) => (
            <TextArea
              rows={3}
              style={{
                '--background': 'var(--ion-color-step-100)',
                '--padding-start': '.5rem',
              }}
              value={value}
              onIonChange={onChange}
            />
          )}
        />
      </IonItem>

      <IonRow className="items-center">
        <IonCol size="auto">oneshop.me/</IonCol>
        <IonCol className="flex-1">
          <TextInput control={control} name="profileId" />
        </IonCol>
      </IonRow>
      <IonRow>
        <ErrorMessage as={StyledError} errors={errors} name="profileId" />
      </IonRow>

      <IonRow className="ion-padding-vertical justify-center space-x-3">
        <IonButton
          color="secondary"
          fill="outline"
          disabled={!isDirty}
          onClick={() => reset()}
        >
          Cancel
        </IonButton>
        <IonButton type="submit" color="yellow" disabled={!isDirty}>
          Save
        </IonButton>
      </IonRow>
    </form>
  )
}
